import { Trade } from '../../../types/tradeData'
import React, { useState, useCallback, useMemo } from 'react'
import { useActiveWeb3React } from '../../../hooks'
import useDebounce from '../../../hooks/useDebounce'
import Column from '../../Orders/Column'
import { TableLoader } from '../../Loader'
import { isMobile, isTablet } from 'react-device-detect'
import { ExternalLink } from '../../../theme'
import { getEtherscanLink, shortenAddress } from '../../../utils'
import cleanCurrencySymbol from '../../../utils/cleanCurrencySymbol'
import { formatAmount } from '../../../utils/formatAmount'
import toDate from '../../../utils/toDate'
import { Arrow, Backward, Forward, PageSelect } from '../styled'
import { ColumnTitles, StatRows, StatTable } from '../Overview'
import styled from 'styled-components/macro'

const EmptySetWrapper = styled.div`
  color: ${({ theme }) => theme.text3};
  margin: 20px auto;
  width: 275px;
  text-align: center;
`

const TransactionRow = styled.div`
  width: 100%;
  height: 36px;
  display: flex;
  justify-content: space-between;
  font-size: 14px;

  a {
    text-decoration: none !important;

    :hover {
      color: ${({ theme }) => theme.text2} !important;
    }
  }

  @media screen and (max-width: 550px) {
    height: 45px;
  }
`

const ColumnWrapper = styled(Column)`
  cursor: pointer;
`

const tradeWidths = {
  widths: [10, 10, 10, 10, 10, 10],
  starts: [0, 20, 40, 60, 77, 90],
  mobileWidths: [25, 25, 15],
  mobileStarts: [0, 42.5, 85],
}

export default function TradeTable({ trades, isLoading }: { trades: Trade[]; isLoading: boolean }) {
  const [page, setPage] = useState(1)
  const lastPage = Math.max(1, Math.ceil(trades.length / 10))
  const { chainId } = useActiveWeb3React()
  const debouncedIsLoading = useDebounce(isLoading, 300)
  const [sortField, setSortField] = useState<string | undefined>(undefined)
  const [sortDirection, setSortDirection] = useState<boolean>(true)
  const handleSort = useCallback(
    (newField: string) => {
      setSortField(newField)
      setSortDirection(sortField !== newField ? true : !sortDirection)
    },
    [sortDirection, sortField],
  )

  const arrow = useCallback(
    (field: string) => {
      return sortField === field ? (!sortDirection ? '↑' : '↓') : ''
    },
    [sortDirection, sortField],
  )

  const sorted = useMemo(() => {
    return sortField
      ? [...trades].sort((a: Trade, b: Trade) => {
          const sort = sortDirection ? 1 : -1
          if (sortField === 'amount') {
            return (a.takerAmountUSD.greaterThan(b.takerAmountUSD) ? -1 : 1) * sort
          } else {
            return (a.transaction.timestamp > b.transaction.timestamp ? -1 : 1) * sort
          }
        })
      : trades
  }, [trades, sortDirection, sortField])

  return (
    <StatTable>
      <ColumnTitles>
        <Column
          width={16}
          tabletWidth={22}
          mobileWidth={34}
          textAlign={'left'}
          customTabletWidth={1080}
          customMobileWidth={880}
        >
          Trade
        </Column>
        <ColumnWrapper
          width={10}
          tabletWidth={12}
          mobileWidth={33}
          textAlign={'right'}
          customTabletWidth={1080}
          customMobileWidth={880}
          onClick={() => handleSort('amount')}
        >
          USD Value {arrow('amount')}
        </ColumnWrapper>
        <Column
          width={15}
          tabletWidth={20}
          hideOnMobile
          textAlign={'right'}
          customTabletWidth={1080}
          customMobileWidth={880}
        >
          Token Amount
        </Column>
        <Column
          width={15}
          tabletWidth={20}
          hideOnMobile
          mobileWidth={30}
          textAlign={'right'}
          customTabletWidth={1080}
          customMobileWidth={880}
        >
          Token Amount
        </Column>
        <Column
          width={12}
          tabletWidth={18}
          mobileWidth={30}
          textAlign={'right'}
          hideOnTablet
          hideOnMobile
          customTabletWidth={1080}
          customMobileWidth={880}
        >
          Account
        </Column>
        <ColumnWrapper
          width={10}
          tabletWidth={18}
          mobileWidth={33}
          textAlign={'right'}
          customTabletWidth={1080}
          customMobileWidth={880}
          onClick={() => handleSort('time')}
        >
          Time {arrow('time')}
        </ColumnWrapper>
      </ColumnTitles>
      <StatRows>
        {sorted.length === 0 && debouncedIsLoading ? (
          <TableLoader
            rows={isMobile ? 5 : isTablet ? 4 : 3}
            height={30}
            spacing={55}
            marginTop={20}
            isMobile={isMobile}
            isTablet={isTablet}
            widths={tradeWidths}
          />
        ) : sorted.length === 0 ? (
          <EmptySetWrapper>No trades have occurred yet</EmptySetWrapper>
        ) : (
          sorted.slice(10 * (page - 1), 10 * page).map((trade, index) => {
            const fromAsset = trade.takerDeltaWei.currency
            const toAsset =
              trade.primary.address === trade.takerDeltaWei.currency.address
                ? trade.secondaryDeltaWei
                : trade.primaryDeltaWei
            return (
              <TransactionRow key={index}>
                <Column
                  width={16}
                  tabletWidth={22}
                  mobileWidth={28}
                  textAlign={'left'}
                  customTabletWidth={1080}
                  customMobileWidth={880}
                >
                  <ExternalLink href={getEtherscanLink(chainId, trade.transaction.transactionHash, 'transaction')}>
                    {cleanCurrencySymbol(fromAsset)} for {cleanCurrencySymbol(toAsset.currency)}
                  </ExternalLink>
                </Column>
                <Column
                  width={10}
                  tabletWidth={12}
                  mobileWidth={37}
                  textAlign={'right'}
                  customTabletWidth={1080}
                  customMobileWidth={880}
                >
                  {formatAmount(trade.takerAmountUSD, 2, true, '$0.00', true)}
                </Column>
                <Column
                  width={15}
                  tabletWidth={20}
                  hideOnMobile
                  textAlign={'right'}
                  customTabletWidth={1080}
                  customMobileWidth={880}
                >
                  {formatAmount(trade.takerDeltaWei, undefined, true, '0.00')} {cleanCurrencySymbol(fromAsset)}
                </Column>
                <Column
                  width={15}
                  tabletWidth={20}
                  mobileWidth={30}
                  hideOnMobile
                  textAlign={'right'}
                  customTabletWidth={1080}
                  customMobileWidth={880}
                >
                  {formatAmount(toAsset, undefined, true, '0.00')} {cleanCurrencySymbol(toAsset.currency)}
                </Column>
                <Column
                  width={12}
                  tabletWidth={18}
                  mobileWidth={30}
                  textAlign={'right'}
                  hideOnTablet
                  hideOnMobile
                  customTabletWidth={1080}
                  customMobileWidth={880}
                >
                  <ExternalLink href={getEtherscanLink(chainId, trade.takerAccount.user, 'address')}>
                    {shortenAddress(trade.takerAccount.user)}
                  </ExternalLink>
                </Column>
                <Column
                  width={10}
                  tabletWidth={18}
                  mobileWidth={35}
                  textAlign={'right'}
                  customTabletWidth={1080}
                  customMobileWidth={880}
                >
                  {toDate(trade.transaction.timestamp).replace('a few seconds ago', '< 1 min ago')}
                </Column>
              </TransactionRow>
            )
          })
        )}
        <PageSelect>
          <Arrow onClick={() => setPage(page === 1 ? page : page - 1)} faded={page === 1}>
            <Backward />
          </Arrow>
          {page}
          <Arrow onClick={() => setPage(page === lastPage ? page : page + 1)} faded={page === lastPage}>
            <Forward />
          </Arrow>
        </PageSelect>
      </StatRows>
    </StatTable>
  )
}
