import { Token } from '@dolomite-exchange/sdk-core'
import { ChainId, ChainIdMap, initializeSingleValueChainIdMap } from '../chainId'

const decimals = 18
const symbol = 'HONEY'
const name = 'Honey'

function honey(chainId: ChainId, address: string) {
  return new Token(chainId, address, decimals, symbol, name)
}

export const HONEY: ChainIdMap<Token | undefined> = initializeSingleValueChainIdMap(
  ChainId.BERACHAIN,
  honey(ChainId.BERACHAIN, '0xFCBD14DC51f0A4d49d5E53C2E0950e0bC26d0Dce'),
)
