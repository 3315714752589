import { ChainId, GM_PENDLE_USD_ADDRESSES, GM_PENDLE_USD_ISOLATION_MODE_ADDRESSES } from '../../index'
import { Token } from '@dolomite-exchange/v2-sdk'
import { calculateGmPENDLEUSDProxyVaultAddress } from '../../../utils/isolation/calculateProxyVaultAddress'
import { getOptionally } from '../special-assets-util-functions'
import { SpecialAsset, SpecialAssetSymbol } from '../special-assets'
import {
  getGmCollateralTokens,
  getGmDebtTokens,
  getGmUsdValidInputTokens,
  getGmUsdValidOutputTokens,
} from './special-gm-tokens'
import { PENDLE } from '../../tokens/PENDLE'

const cleanSymbol = 'gmPENDLE-USD'
const cleanName = 'GM: PENDLE-USD'

export const GM_PENDLE_USD_SPECIAL_ASSET: () => SpecialAsset = () => ({
  cleanSymbol,
  cleanName,
  symbol: SpecialAssetSymbol.gmPENDLEUSD,
  safeSymbol: SpecialAssetSymbol.gmPENDLEUSD,
  hasExternalRewards: false,
  documentationUrl: 'https://docs.dolomite.io/integrations/gmx/gm',
  isIsolationMode: true,
  isBorrowingEnabled: false,
  chainIdToAddressMap: GM_PENDLE_USD_ISOLATION_MODE_ADDRESSES,
  isolationModeInfo: {
    marketId: 64,
    getWrappedToken: (chainId: ChainId) =>
      getOptionally(
        GM_PENDLE_USD_ISOLATION_MODE_ADDRESSES[chainId],
        address => new Token(chainId, address, 18, cleanSymbol, `Dolomite Isolation: ${cleanName}`),
      ),
    getUnderlyingToken: getGmToken,
    getUnderlyingSpenderToken: getGmToken,
    allowableCollateralTokens: (chainId: ChainId) =>
      getGmCollateralTokens(SpecialAssetSymbol.gmPENDLEUSD, chainId, PENDLE[chainId]),
    allowableDebtTokens: (chainId: ChainId) => getGmDebtTokens(chainId, PENDLE[chainId]),
    allowableInputTokensForZap: (chainId: ChainId) => getGmUsdValidInputTokens(chainId, PENDLE[chainId]),
    allowableOutputTokensForZap: (chainId: ChainId) => getGmUsdValidOutputTokens(chainId, PENDLE[chainId]),
    remapAccountAddress: calculateGmPENDLEUSDProxyVaultAddress,
    isAsync: true,
  },
})

function getGmToken(chainId: ChainId): Token | undefined {
  return getOptionally(
    GM_PENDLE_USD_ADDRESSES[chainId],
    address => new Token(chainId, address, 18, cleanSymbol, cleanSymbol),
  )
}
