import { Token } from '@dolomite-exchange/sdk-core'
import { ChainId, ChainIdMap } from '../chainId'

const decimals = 18
const symbol = 'rsETH'
const name = 'KelpDao Restaked ETH'

function getToken(chainId: ChainId, address: string) {
  return new Token(chainId, address, decimals, symbol, name)
}

export const RS_ETH: ChainIdMap<Token | undefined> = {
  [ChainId.MAINNET]: undefined,
  [ChainId.ARBITRUM_ONE]: getToken(ChainId.ARBITRUM_ONE, '0x4186BFC76E2E237523CBC30FD220FE055156b41F'),
  [ChainId.BASE]: undefined,
  [ChainId.BERACHAIN]: getToken(ChainId.BERACHAIN, '0x4186BFC76E2E237523CBC30FD220FE055156b41F'),
  [ChainId.MANTLE]: undefined,
  [ChainId.POLYGON_ZKEVM]: undefined,
  [ChainId.X_LAYER]: undefined,
}
