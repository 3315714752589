import React, { useRef, useState, useEffect } from 'react'
import { BookOpen, Code, GitHub, Info, Map, MessageCircle } from 'react-feather'
import styled from 'styled-components/macro'
import { ReactComponent as MenuIcon } from '../../assets/images/menu.svg'
import { useOnClickOutside } from '../../hooks/useOnClickOutside'
import { ApplicationModal } from '../../state/application/actions'
import { useModalOpen, useToggleModal } from '../../state/application/hooks'
import FirstVisitPopover from '../FirstVisitPopover/FirstVisitPopover'
import ChainalysisIcon from '../../assets/images/chainalysis-icon.png'

import { ExternalLink } from '../../theme'

const StyledMenuIcon = styled(MenuIcon)`
  path {
    stroke: ${({ theme }) => theme.text1};
  }
`

const StyledMenuButton = styled.button`
  width: 100%;
  border: none;
  margin: 0;
  height: 39.5px;
  background-color: ${({ theme }) => theme.bg2};
  box-shadow: 0 5px 5px -3px rgb(0 0 0 / 20%), 0 8px 10px 1px rgb(0 0 0 / 14%), 0 3px 14px 2px rgb(0 0 0 / 12%);

  padding: 0.15rem 0.5rem;
  border-radius: 5px;

  :hover,
  :focus {
    cursor: pointer;
    outline: none;
    background-color: #3a3a4f;
  }

  svg {
    margin-top: 4px;
  }

  ${({ theme }) => theme.mediaWidth.upToSmall`
    box-shadow: none;
  `};
`

const StyledMenu = styled.div`
  margin-left: 0.5rem;
  display: flex;
  justify-content: center;
  align-items: center;
  border: none;
  text-align: left;
  position: absolute;
  right: 16px;
  top: 16px;

  @media (max-width: 1300px) {
    right: 64px;
  }

  ${({ theme }) => theme.mediaWidth.upToSmall`
    position: relative;
    margin: 0;
    top: 0;
    right: 0;
  `};
`

const FlyoutWrapper = styled.div<{ isOpen: boolean }>`
  pointer-events: ${({ isOpen }) => (isOpen ? 'auto' : 'none')};

  > span {
    top: 50px;
    transition: opacity 0.3s ease-in-out;
    opacity: ${({ isOpen }) => (isOpen ? 1 : 0)};
    pointer-events: ${({ isOpen }) => (isOpen ? 'auto' : 'none')};
  }

  ${({ theme, isOpen }) => theme.mediaWidth.upToSmall`
    overflow: hidden;
    position: absolute;
    width: 180px;
    height: 251px;
    right: 0px;
    bottom: 56px;
  
    > span {
      opacity: 1;
      transition: top 0.3s ease-in-out;
      top: ${isOpen ? '0' : '261px'};
      pointer-events: ${isOpen ? 'auto' : 'none'};
    }
  `};
`

const MenuFlyout = styled.span`
  min-width: 180px;
  background-color: ${({ theme }) => theme.bg2};
  box-shadow: 0 5px 5px -3px rgb(0 0 0 / 20%), 0 8px 10px 1px rgb(0 0 0 / 14%), 0 3px 14px 2px rgb(0 0 0 / 12%);
  border-radius: 5px;
  padding: 0.5rem;
  display: flex;
  flex-direction: column;
  font-size: 1rem;
  position: absolute;
  top: 4rem;
  right: 0;
  z-index: 100;

  ${({ theme }) => theme.mediaWidth.upToSmall`
    top: -160px;
  `};
`

const MenuItem = styled(ExternalLink)`
  flex: 1;
  padding: 0.5rem 0.5rem;
  color: ${({ theme }) => theme.text2};

  :hover {
    color: ${({ theme }) => theme.text1};
    cursor: pointer;
    text-decoration: none;
  }

  > svg {
    margin-right: 8px;
  }
`

const MenuDiv = styled.div`
  flex: 1;
  padding: 0.5rem 0.5rem;
  color: ${({ theme }) => theme.text2};

  :hover {
    color: ${({ theme }) => theme.text1};
    cursor: pointer;
    text-decoration: none;
  }

  > svg {
    margin-right: 8px;
  }
`

export default function Menu() {
  const node = useRef<HTMLDivElement>()
  const open = useModalOpen(ApplicationModal.MENU)
  const toggle = useToggleModal(ApplicationModal.MENU)
  const [tour, setTour] = useState(false)
  const [size, setSize] = useState({
    x: window.innerWidth,
    y: window.innerHeight,
  })
  const updateSize = () =>
    setSize({
      x: window.innerWidth,
      y: window.innerHeight,
    })
  useEffect(() => {
    window.onresize = updateSize
    return () => {
      window.onresize = null
    }
  }, [])
  const setHasVisited = () => {
    setTour(false)
  }

  const smartContractsLink = 'https://docs.dolomite.io/smart-contract-addresses'
  useOnClickOutside(node, open ? toggle : undefined)
  // const openClaimModal = useToggleModal(ApplicationModal.ADDRESS_CLAIM)

  // TODO - re-enable tour when it's updated, and if it's supported on other pages
  return (
    // https://github.com/DefinitelyTyped/DefinitelyTyped/issues/30451
    <StyledMenu ref={node as any}>
      <StyledMenuButton onClick={toggle}>
        <StyledMenuIcon />
      </StyledMenuButton>
      {/*tour && size.x > 1000 && (
        <FirstVisitPopover
          width={size.x}
          height={size.y}
          approvedToS={true}
          setHasVisited={setHasVisited}
          setApprovedToS={setHasVisited}
        />
      )*/}
      <FlyoutWrapper isOpen={open}>
        <MenuFlyout>
          <MenuItem id='link' href={'https://dolomite.io'}>
            <Info size={14} />
            About
          </MenuItem>
          {/*size.x > 1000 && (
            <MenuDiv id='link' onClick={() => setTour(true)}>
              <Map size={14} />
              Tour
            </MenuDiv>
          )*/}
          <MenuItem id='link' href={'https://docs.dolomite.io'}>
            <BookOpen size={14} />
            Docs
          </MenuItem>
          <MenuItem id='link' href={'https://github.com/dolomite-exchange'}>
            <GitHub size={14} />
            GitHub
          </MenuItem>
          <MenuItem id='link' href={smartContractsLink}>
            <Code size={14} />
            Smart Contracts
          </MenuItem>
          <MenuItem
            id='link'
            href={'https://docs.dolomite.io/audits-and-security/chainalysis-crypto-incident-response'}
          >
            <img
              src={ChainalysisIcon}
              style={{
                filter: 'grayscale(1) contrast(0) brightness(400)',
                width: '14px',
                height: '14px',
                marginRight: '8px',
              }}
              alt={'Chainalysis logo'}
            />
            Chainalysis CIR
          </MenuItem>
          <MenuItem id='link' href={'https://discord.gg/uDRzrB2YgP'}>
            <MessageCircle size={14} />
            Discord
          </MenuItem>
          {/*{account && (*/}
          {/*  <ButtonPrimary onClick={openClaimModal} padding='8px 16px' width='100%' borderRadius='12px' mt='0.5rem'>*/}
          {/*    Claim UNI*/}
          {/*  </ButtonPrimary>*/}
          {/*)}*/}
        </MenuFlyout>
      </FlyoutWrapper>
    </StyledMenu>
  )
}
