import { SPECIAL_ASSET_MAP, SpecialAssetSymbol } from '../isolation/special-assets'
import { Currency } from '@dolomite-exchange/sdk-core'
import cleanCurrencySymbol from '../../utils/cleanCurrencySymbol'
import { ChainId, ChainIdMap } from '../chainId'
import { useActiveWeb3React } from '../../hooks'
import cleanCurrencyName from '../../utils/cleanCurrencyName'
import { TopTokenData } from '../../types/topTokenData'

const GLP_TOKENS = {
  [SpecialAssetSymbol.dfsGLP]: true,
  [SpecialAssetSymbol.dplvGLP]: true,
  [SpecialAssetSymbol.dPtGLPSeptember2024]: true,
  [SpecialAssetSymbol.magicGLP]: true,
}

const GMX_TOKENS = {
  [SpecialAssetSymbol.dfsGLP]: true,
  [SpecialAssetSymbol.dGMX]: true,
  [SpecialAssetSymbol.GLVBTC]: true,
  [SpecialAssetSymbol.GLVETH]: true,
  [SpecialAssetSymbol.gmAAVEUSD]: true,
  [SpecialAssetSymbol.gmARBUSD]: true,
  [SpecialAssetSymbol.gmBTC]: true,
  [SpecialAssetSymbol.gmBTCUSD]: true,
  [SpecialAssetSymbol.gmDOGEUSD]: true,
  [SpecialAssetSymbol.gmETH]: true,
  [SpecialAssetSymbol.gmETHUSD]: true,
  [SpecialAssetSymbol.gmGMX]: true,
  [SpecialAssetSymbol.gmGMXUSD]: true,
  [SpecialAssetSymbol.gmLINKUSD]: true,
  [SpecialAssetSymbol.gmPENDLEUSD]: true,
  [SpecialAssetSymbol.gmPEPEUSD]: true,
  [SpecialAssetSymbol.gmSOLUSD]: true,
  [SpecialAssetSymbol.gmUNIUSD]: true,
  [SpecialAssetSymbol.gmWIFUSD]: true,
  [SpecialAssetSymbol.gmWstETHUSD]: true,
  [SpecialAssetSymbol.GMX]: true,
}

const ISOLATION_TOKENS = Object.keys(SPECIAL_ASSET_MAP).reduce<Record<string, boolean>>((memo, key) => {
  memo[key] = !!SPECIAL_ASSET_MAP[key]?.isIsolationMode
  return memo
}, {})

const LST_TOKENS = {
  [SpecialAssetSymbol.dPtMethDecember2024]: true,
  [SpecialAssetSymbol.dPtREthJune2025]: true,
  [SpecialAssetSymbol.dPtWstEthJun2025]: true,
  [SpecialAssetSymbol.mETH]: true,
  [SpecialAssetSymbol.rETH]: true,
  [SpecialAssetSymbol.WOETH]: true,
  [SpecialAssetSymbol.wstETH]: true,
}

const LRT_TOKENS = {
  [SpecialAssetSymbol.dPtEzEthSeptember2024]: true,
  [SpecialAssetSymbol.dPtRsEthSeptember2024]: true,
  [SpecialAssetSymbol.dPtWeEthSeptember2024]: true,
  ezETH: true,
  rsETH: true,
  weETH: true,
}

const PENDLE_TOKENS = Object.entries(SpecialAssetSymbol).reduce((memo, [, v]) => {
  if (v.includes('dPT-') || v.includes('dYT-')) {
    memo[v] = true
  }
  return memo
}, {} as Record<string, boolean>)

export const STABLE_TOKENS = {
  DAI: true,
  [SpecialAssetSymbol.dPtUsdeDecember2024]: true,
  GRAI: true,
  HONEY: true,
  [SpecialAssetSymbol.djUSDC]: true,
  NECT: true,
  MIM: true,
  rUSD: true,
  sUSDe: true,
  srUSD: true,
  USDC: true,
  'USDC.e': true,
  USDe: true,
  USDM: true,
  USDT: true,
  wUSDM: true,
}

export enum FilterType {
  NONE = 'None',
  STABLE = 'Stable',
  GLP = 'GLP',
  GMX = 'GMX',
  LST = 'LST',
  LRT = 'LRT',
  PENDLE = 'Pendle',
  Isolation = 'Isolation',
}

const FILTER_OPTIONS: ChainIdMap<{ [key in FilterType]?: Record<string, boolean> | 'NONE' }> = {
  [ChainId.MAINNET]: {
    [FilterType.NONE]: 'NONE',
  },
  [ChainId.ARBITRUM_ONE]: {
    [FilterType.NONE]: 'NONE',
    [FilterType.STABLE]: STABLE_TOKENS,
    [FilterType.GLP]: GLP_TOKENS,
    [FilterType.GMX]: GMX_TOKENS,
    [FilterType.LST]: LST_TOKENS,
    [FilterType.LRT]: LRT_TOKENS,
    [FilterType.PENDLE]: PENDLE_TOKENS,
    [FilterType.Isolation]: ISOLATION_TOKENS,
  },
  [ChainId.BASE]: {
    [FilterType.NONE]: 'NONE',
    [FilterType.STABLE]: STABLE_TOKENS,
  },
  [ChainId.BERACHAIN]: {
    [FilterType.NONE]: 'NONE',
    [FilterType.STABLE]: STABLE_TOKENS,
  },
  [ChainId.MANTLE]: {
    [FilterType.NONE]: 'NONE',
    [FilterType.STABLE]: STABLE_TOKENS,
    [FilterType.PENDLE]: PENDLE_TOKENS,
  },
  [ChainId.POLYGON_ZKEVM]: {
    [FilterType.NONE]: 'NONE',
    [FilterType.STABLE]: STABLE_TOKENS,
  },
  [ChainId.X_LAYER]: {
    [FilterType.NONE]: 'NONE',
    [FilterType.STABLE]: STABLE_TOKENS,
  },
}

export function useFilterTypes(): FilterType[] {
  const { chainId } = useActiveWeb3React()
  return Object.keys(FILTER_OPTIONS[chainId]) as FilterType[]
}

export function filterTokens<T extends Currency>(
  tokens: T[],
  filterType: FilterType,
  searchTerm: string,
  chainId: ChainId,
  showWETH: boolean = false,
): T[] {
  return tokens.filter((currency: T) => {
    const filterOptions = FILTER_OPTIONS[chainId][filterType]
    if (!filterOptions || (filterOptions !== 'NONE' && !filterOptions[currency.symbol ?? ''])) {
      return false
    }

    if (searchTerm.length === 0) {
      return true
    }
    const value = searchTerm.toUpperCase()
    return (
      !!cleanCurrencySymbol(currency, !showWETH)
        ?.toUpperCase()
        .includes(value) ||
      !!cleanCurrencyName(currency, !showWETH)
        ?.toUpperCase()
        .includes(value)
    )
  })
}

export function filterTokenStats(
  tokens: TopTokenData[],
  filterType: FilterType,
  searchTerm: string,
  chainId: ChainId,
  showWETH: boolean = false,
): TopTokenData[] {
  return tokens.filter((tokenData: TopTokenData) => {
    const currency = tokenData.token
    const filterOptions = FILTER_OPTIONS[chainId][filterType]
    if (!filterOptions || (filterOptions !== 'NONE' && !filterOptions[currency.symbol ?? ''])) {
      return false
    }

    if (searchTerm.length === 0) {
      return true
    }
    const value = searchTerm.toUpperCase()
    return (
      !!cleanCurrencySymbol(currency, !showWETH)
        ?.toUpperCase()
        .includes(value) ||
      !!cleanCurrencyName(currency, !showWETH)
        ?.toUpperCase()
        .includes(value)
    )
  })
}
