import { Fraction, Percent } from '@dolomite-exchange/sdk-core'
import { CurrencyAmount, Token } from '@dolomite-exchange/v2-sdk'
import { createMarginAccount, MarginAccount } from './marginAccount'
import { useMemo, useRef } from 'react'
import { borrowPositionGql } from './queryObjects'
import {
  BorrowPosition as BorrowPositionGql,
  BorrowPositionAmount as BorrowPositionAmountGql,
  createCurrencyAmount,
  createDate,
  createDateOpt,
} from './gqlTypeHelpers'
import { gql } from '@apollo/client'
import { DEFAULT_MIN_COLLATERALIZATION, ZERO_FRACTION } from '../constants'
import { useAllTokens } from '../hooks/Tokens'
import { useMarketIndices } from '../hooks/useDolomiteMarginProtocol'
import { InterestIndex } from '../data/InterestIndex'
import parToWei from '../utils/parToWei'
import { useDefaultFiatValuesWithLoadingIndicator } from '../hooks/useFiatValue'
import { InterestRate, useInterestRateData } from './interestRateData'
import { MarketRiskInfo, useMarketRiskInfoData } from './marketRiskInfoData'
import { DolomiteMarginData, useDolomiteMarginData } from './dolomiteMarginData'
import calculateScaledValueForRiskParams from '../utils/calculateScaledValueForRiskParams'
import calculateBorrowPositionHealth from '../utils/calculateBorrowPositionHealth'
import {
  calculateBorrowInterestRatePercent,
  calculateNetInterestRatePercent,
  calculateSupplyInterestRatePercent,
} from '../utils/calculateBorrowPositionInterestRatePercent'
import { toChecksumAddress } from '../utils/toChecksumAddress'
import { SpecialAsset, useAllSpecialAssets } from '../constants/isolation/special-assets'
import { useGraphqlResult } from '../state/graphql/hooks'
import { GraphqlClientType } from '../state/graphql/actions'
import { useActiveWeb3React } from '../hooks'
import { RefreshFrequency } from '../state/chain/hooks'
import { getBorrowPositionEMode } from '../utils/emode'

const OPEN_BORROW_POSITIONS_GQL = gql`
    query borrowPositions($blockNumber: Int!, $walletAddress: String!) {
        borrowPositions(
            block: { number_gte: $blockNumber },
            where: { effectiveUser: $walletAddress, status_not: "CLOSED" marginAccount_: { accountNumber_not: 0 } },
            orderBy: openTimestamp,
            orderDirection: desc,
            first: 50
        ) {
            ${borrowPositionGql()}
        }
    }
`

const ALL_BORROW_POSITIONS_GQL = gql`
    query borrowPositions($blockNumber: Int!, $walletAddress: String!) {
        borrowPositions(
            block: { number_gte: $blockNumber },
            where: { effectiveUser: $walletAddress marginAccount_: { accountNumber_not: 0 } },
            orderBy: openTimestamp,
            orderDirection: desc,
            first: 50
        ) {
            ${borrowPositionGql()}
        }
    }
`

export interface BorrowPositionAmount {
  isPositive: boolean
  token: Token
  amountTokenPar: CurrencyAmount<Token>
  amountTokenWei: CurrencyAmount<Token>
  amountUSD: Fraction
  interestAccruedToken: CurrencyAmount<Token>
  interestAccruedUSD: Fraction
  expirationTimestamp: Date | undefined
}

export enum BorrowPositionStatus {
  Open = 'OPEN',
  Closed = 'CLOSED',
  Liquidated = 'LIQUIDATED',
  Unknown = 'UNKNOWN',
}

export interface BorrowPosition {
  id: string
  marginAccount: MarginAccount
  openTimestamp: Date
  closeTimestamp: Date | undefined
  status: BorrowPositionStatus
  totalSupplyUSD: Fraction
  totalBorrowUSD: Fraction
  // you can show liquidation point with these two variables by dividing totalSupplyUSD and totalBorrowUSD and
  // comparing that to the protocol's liquidation threshold (currently 115%, but you should get it dynamically)
  scaledTotalSupplyUSD: Fraction
  scaledTotalBorrowUSD: Fraction
  // Less than 1.00 results in liquidation. Undefined means there is nothing borrowed (infinity)
  positionHealth: Fraction | undefined
  supplyInterestRate: Percent
  borrowInterestRate: Percent
  // Spot interest rate calculated from all held assets and interest owed on all borrowed assets
  netInterestRate: Percent
  supplyAmounts: BorrowPositionAmount[]
  borrowAmounts: BorrowPositionAmount[]
  amounts: BorrowPositionAmount[]
  effectiveSupplyTokens: Token[]
  effectiveBorrowTokens: Token[]
  effectiveUser: string
  specialInfo: {
    /**
     * The address of the vault that the position is isolated to. Undefined if the position is not isolated.
     */
    isolationModeVaultAddress: string | undefined
    /**
     * The vault token that defines this isolation mode
     */
    specialAsset: SpecialAsset | undefined
  }
  expirationTimestamp: Date | undefined
}

interface BorrowPositionResponse {
  borrowPositions: BorrowPositionGql[]
}

const statusStringToStatus: Record<string, BorrowPositionStatus> = {
  [BorrowPositionStatus.Open]: BorrowPositionStatus.Open,
  [BorrowPositionStatus.Closed]: BorrowPositionStatus.Closed,
  [BorrowPositionStatus.Liquidated]: BorrowPositionStatus.Liquidated,
}

export function useOpenBorrowPositions(
  walletAddress?: string,
): {
  loading: boolean
  error: boolean
  data: BorrowPosition[]
} {
  return useBorrowPositions(walletAddress, true)
}

export function useAllBorrowPositions(
  walletAddress?: string,
): {
  loading: boolean
  error: boolean
  data: BorrowPosition[]
} {
  return useBorrowPositions(walletAddress, false)
}

export function serializeBorrowPositionAmounts(amounts: BorrowPositionAmount[]): string {
  return JSON.stringify(
    amounts.map(amount => ({
      isPositive: amount.isPositive,
      token: {
        chainId: amount.token.chainId,
        address: amount.token.address,
        decimals: amount.token.decimals,
        symbol: amount.token.symbol,
        name: amount.token.name,
        isActive: amount.token.isActive,
      },
      amountTokenPar: amount.amountTokenPar.toString(),
      amountTokenWei: amount.amountTokenWei.toString(),
      amountUSD: amount.amountUSD.toString(),
      interestAccruedToken: amount.interestAccruedToken.toString(),
      interestAccruedUSD: amount.interestAccruedUSD.toString(),
      expirationTimestamp: amount.expirationTimestamp?.getTime(),
    })),
  )
}

export function deserializeBorrowPositionAmounts(value: string): BorrowPositionAmount[] {
  const amounts: any[] = JSON.parse(value)
  return amounts.map(amount => {
    const token = new Token(
      amount.token.chainId,
      amount.token.address,
      amount.token.decimals,
      amount.token.symbol,
      amount.token.name,
      amount.token.isActive,
    )
    const amountTokenPar = Fraction.fromSplitString(amount.amountTokenPar)
    const amountTokenWei = Fraction.fromSplitString(amount.amountTokenWei)
    const amountUSD = Fraction.fromSplitString(amount.amountUSD)
    const interestAccruedToken = Fraction.fromSplitString(amount.interestAccruedToken)
    const interestAccruedUSD = Fraction.fromSplitString(amount.interestAccruedUSD)
    return {
      isPositive: amount.isPositive,
      token: token,
      amountTokenPar: CurrencyAmount.fromFractionalAmount(token, amountTokenPar.numerator, amountTokenPar.denominator),
      amountTokenWei: CurrencyAmount.fromFractionalAmount(token, amountTokenWei.numerator, amountTokenWei.denominator),
      amountUSD,
      interestAccruedToken: CurrencyAmount.fromFractionalAmount(
        token,
        interestAccruedToken.numerator,
        interestAccruedToken.denominator,
      ),
      interestAccruedUSD,
      expirationTimestamp: amount.expirationTimestamp ? new Date(amount.expirationTimestamp) : undefined,
    }
  })
}

function mapPositionAmountToBorrowAmount(
  amount: BorrowPositionAmountGql,
  tokenMap: Record<string, Token | undefined>,
  indexMap: Record<string, InterestIndex | undefined>,
  fiatMap: Record<string, Fraction | undefined>,
): BorrowPositionAmount | undefined {
  const token = tokenMap[toChecksumAddress(amount.token.id)]
  if (!token) {
    return undefined
  }

  const fiatValue = fiatMap[token.address]
  if (!fiatValue) {
    return undefined
  }

  const index = indexMap[token.address]
  if (!index) {
    return undefined
  }

  let amountPar = createCurrencyAmount(token, amount.amountPar)
  let cachedAmountWei = createCurrencyAmount(token, amount.amountWei)
  const isPositive = amountPar.greaterThanOrEqual(ZERO_FRACTION)
  if (!isPositive) {
    amountPar = amountPar.multiply(-1)
    cachedAmountWei = cachedAmountWei.multiply(-1)
  }

  const amountWei = parToWei(amountPar, index, isPositive)
  const interestAccruedWei = amountWei.subtract(cachedAmountWei)

  return {
    isPositive,
    token,
    amountTokenPar: amountPar,
    amountTokenWei: amountWei,
    amountUSD: amountWei.asFraction.multiply(fiatValue),
    interestAccruedToken: interestAccruedWei,
    interestAccruedUSD: interestAccruedWei.asFraction.multiply(fiatValue),
    expirationTimestamp: amount.expirationTimestamp ? createDate(amount.expirationTimestamp) : undefined,
  }
}

function partitionAmountsIntoSupplyAndBorrow(
  amounts: BorrowPositionAmount[],
): {
  supplyAmounts: BorrowPositionAmount[]
  borrowAmounts: BorrowPositionAmount[]
} {
  return amounts.reduce<{
    supplyAmounts: BorrowPositionAmount[]
    borrowAmounts: BorrowPositionAmount[]
  }>(
    (memo, amount) => {
      if (amount.isPositive) {
        memo.supplyAmounts.push(amount)
      } else {
        memo.borrowAmounts.push(amount)
      }
      return memo
    },
    {
      supplyAmounts: [],
      borrowAmounts: [],
    },
  )
}

function mapGqlPositionToPosition(
  position: BorrowPositionGql,
  account: string,
  specialToken: SpecialAsset | undefined,
  tokenMap: Record<string, Token | undefined>,
  marketIndexMap: Record<string, InterestIndex | undefined>,
  fiatValuesMap: Record<string, Fraction | undefined>,
  marketRiskInfoMap: Record<string, MarketRiskInfo | undefined>,
  riskParams: DolomiteMarginData,
  interestRateMap: Record<string, InterestRate | undefined>,
): BorrowPosition {
  const rawAmountOpts = position.amounts.map(amount => {
    return mapPositionAmountToBorrowAmount(amount, tokenMap, marketIndexMap, fiatValuesMap)
  })
  const rawAmounts = rawAmountOpts.reduce<BorrowPositionAmount[]>(
    (memo, amount) => (amount ? [...memo, amount] : memo),
    [],
  )
  const { supplyAmounts, borrowAmounts } = partitionAmountsIntoSupplyAndBorrow(rawAmounts)
  const riskOverride = getBorrowPositionEMode(
    supplyAmounts.map(s => s.token),
    borrowAmounts.map(b => b.token),
    marketRiskInfoMap,
  )

  const totalSupplyUSD = supplyAmounts.reduce<Fraction>((memo, amount) => {
    return memo.add(amount.amountUSD)
  }, ZERO_FRACTION)

  const scaledTotalSupplyUSD = supplyAmounts.reduce<Fraction>((memo, amount) => {
    const marketRiskInfo = marketRiskInfoMap[amount.token.address]
    if (!marketRiskInfo) {
      return memo
    }

    return memo.add(
      calculateScaledValueForRiskParams(amount.isPositive, amount.amountUSD, marketRiskInfo, riskOverride),
    )
  }, ZERO_FRACTION)

  const totalBorrowUSD = borrowAmounts.reduce<Fraction>((memo, amount) => {
    return memo.add(amount.amountUSD)
  }, ZERO_FRACTION)

  const scaledTotalBorrowUSD = borrowAmounts.reduce<Fraction>((memo, amount) => {
    const marketRiskInfo = marketRiskInfoMap[amount.token.address]
    if (!marketRiskInfo) {
      return memo
    }

    return memo.add(
      calculateScaledValueForRiskParams(amount.isPositive, amount.amountUSD, marketRiskInfo, riskOverride),
    )
  }, ZERO_FRACTION)

  const minCollateralization = riskParams.minCollateralization ?? DEFAULT_MIN_COLLATERALIZATION

  const positionHealth = calculateBorrowPositionHealth(
    supplyAmounts,
    borrowAmounts,
    marketRiskInfoMap,
    minCollateralization,
  )

  const positionAccountOwner = toChecksumAddress(position.marginAccount.user.id)
  // the position is in isolation mode if the owner is NOT the account
  const isolationModeVaultAddress = account === positionAccountOwner ? undefined : positionAccountOwner

  return {
    id: position.id,
    marginAccount: createMarginAccount(position.marginAccount),
    openTimestamp: createDate(position.openTimestamp),
    closeTimestamp: createDateOpt(position.closeTimestamp),
    status: statusStringToStatus[position.status] ?? BorrowPositionStatus.Unknown,
    totalSupplyUSD: totalSupplyUSD,
    totalBorrowUSD: totalBorrowUSD,
    scaledTotalSupplyUSD: scaledTotalSupplyUSD,
    scaledTotalBorrowUSD: scaledTotalBorrowUSD,
    positionHealth: positionHealth,
    supplyInterestRate: calculateSupplyInterestRatePercent(rawAmounts, interestRateMap),
    borrowInterestRate: calculateBorrowInterestRatePercent(rawAmounts, interestRateMap),
    netInterestRate: calculateNetInterestRatePercent(rawAmounts, interestRateMap),
    supplyAmounts: supplyAmounts,
    borrowAmounts: borrowAmounts,
    amounts: rawAmounts,
    effectiveUser: position.effectiveUser.id,
    effectiveSupplyTokens: position.effectiveSupplyTokens
      .map(t => tokenMap[toChecksumAddress(t.id)])
      .filter((t): t is Token => !!t),
    effectiveBorrowTokens: position.effectiveBorrowTokens
      .map(t => tokenMap[toChecksumAddress(t.id)])
      .filter((t): t is Token => !!t),
    specialInfo: {
      specialAsset: specialToken,
      isolationModeVaultAddress,
    },
    expirationTimestamp: borrowAmounts.sort((a, b) => {
      if (!a.expirationTimestamp) {
        return 1
      }
      if (!b.expirationTimestamp) {
        return -1
      }
      return a.expirationTimestamp.getTime() - b.expirationTimestamp.getTime()
    })[0]?.expirationTimestamp,
  }
}

function useBorrowPositions(
  walletAddress: string | undefined,
  onlyOpenPositions: boolean,
): {
  loading: boolean
  error: boolean
  data: BorrowPosition[]
} {
  const { chainId } = useActiveWeb3React()
  const variables = useMemo(() => {
    if (!walletAddress) {
      return undefined
    }
    return {
      walletAddress: walletAddress.toLowerCase(),
    }
  }, [walletAddress])

  const borrowPositionQueryState = useGraphqlResult<BorrowPositionResponse>(
    GraphqlClientType.Dolomite,
    (onlyOpenPositions ? OPEN_BORROW_POSITIONS_GQL : ALL_BORROW_POSITIONS_GQL).loc!.source.body,
    variables,
    RefreshFrequency.Fast,
  )

  const tokenMap = useAllTokens()
  const [marketIndexMap, isMarketIndexMapLoading] = useMarketIndices()
  const tokens = useMemo(() => Object.values(tokenMap) as Token[], [tokenMap])
  const specialAssets = useAllSpecialAssets()
  const [fiatValuesMap, isFiatValuesLoading] = useDefaultFiatValuesWithLoadingIndicator(tokens)
  const interestRateResult = useInterestRateData()
  const marketRiskInfosResult = useMarketRiskInfoData()
  const riskParamsResult = useDolomiteMarginData()

  const anyLoading = Boolean(
    borrowPositionQueryState.loading ||
      isMarketIndexMapLoading ||
      isFiatValuesLoading ||
      interestRateResult.loading ||
      marketRiskInfosResult.loading ||
      riskParamsResult.loading,
  )
  const anyError = Boolean(
    borrowPositionQueryState.error || interestRateResult.error || marketRiskInfosResult.error || riskParamsResult.error,
  )

  const riskParams = riskParamsResult.data
  const lastUpdate = useRef<number>(0)
  const storedData = useRef<
    | {
        loading: boolean
        error: boolean
        data: BorrowPosition[]
      }
    | undefined
  >(undefined)
  return useMemo(() => {
    const now = Date.now()
    if (now - lastUpdate.current < 3000 && storedData.current) {
      return storedData.current
    }
    lastUpdate.current = now
    let positions: BorrowPosition[]
    if (
      Object.keys(marketIndexMap).length === 0 ||
      Object.keys(fiatValuesMap).length === 0 ||
      Object.keys(marketRiskInfosResult).length === 0 ||
      Object.keys(interestRateResult).length === 0 ||
      !walletAddress ||
      !riskParams ||
      !walletAddress
    ) {
      positions = []
    } else {
      const vaultAddressToSpecialToken = specialAssets.reduce<Record<string, SpecialAsset | undefined>>(
        (memo, asset) => {
          const vaultAddress = asset.isolationModeInfo?.remapAccountAddress!(walletAddress, chainId)
          if (vaultAddress) {
            memo[vaultAddress] = asset
          }
          return memo
        },
        {},
      )
      positions = (borrowPositionQueryState.result?.borrowPositions ?? [])
        .map<BorrowPosition>(position => {
          const vaultAddress = position.marginAccount.user.id
          return mapGqlPositionToPosition(
            position,
            walletAddress,
            vaultAddressToSpecialToken[toChecksumAddress(vaultAddress)],
            tokenMap,
            marketIndexMap,
            fiatValuesMap,
            marketRiskInfosResult.data,
            riskParams,
            interestRateResult.data,
          )
        })
        .sort((a, b) => b.openTimestamp.getTime() - a.openTimestamp.getTime())
    }

    lastUpdate.current = Date.now()
    storedData.current = {
      loading: anyLoading,
      error: anyError,
      data: positions,
    }
    return {
      loading: anyLoading,
      error: anyError,
      data: positions,
    }
  }, [
    marketIndexMap,
    fiatValuesMap,
    marketRiskInfosResult,
    interestRateResult,
    walletAddress,
    riskParams,
    anyLoading,
    anyError,
    specialAssets,
    borrowPositionQueryState.result?.borrowPositions,
    chainId,
    tokenMap,
  ])
}
