import React, { useCallback, useMemo, useRef, useState } from 'react'
import { useTranslation } from 'react-i18next'
import styled from 'styled-components/macro'
import { Token } from '@dolomite-exchange/v2-sdk'
import Tooltip from '@material-ui/core/Tooltip'
import { useAllActiveTokensArray } from '../../hooks/Tokens'
import { useDefaultFiatValuesWithLoadingIndicator } from '../../hooks/useFiatValue'
import { useInterestRateData } from '../../types/interestRateData'
import { ZERO_FRACTION, ZERO_PERCENT } from '../../constants'
import { useDolomiteMarginTokenTvlData } from '../../types/dolomiteMarginTokenTvlData'
import useDebounce from '../../hooks/useDebounce'
import { useMarketRiskInfoData } from '../../types/marketRiskInfoData'
import { useDolomiteMarginData } from '../../types/dolomiteMarginData'
import InterestRateChangedProps from './InterestRateChangedProps'
import tokenEqualsOpt from '../../utils/tokenEqualsOpt'
import { percentEqualsOpt } from '../../utils/fractionEqualsOpt'
import BorrowRateRow from './BorrowRateRow'
import { useShowYieldAsApr } from '../../state/user/hooks'
import cleanCurrencyName from '../../utils/cleanCurrencyName'
import Search from '@material-ui/icons/Search'
import { X } from 'react-feather'
import Input from '@material-ui/core/Input'
import cleanCurrencySymbol from '../../utils/cleanCurrencySymbol'

const BorrowRatesWrapper = styled.div`
  background-color: #292938;
  -webkit-border-radius: 8px;
  -moz-border-radius: 8px;
  border-radius: 8px;
  display: inline-block;
  margin-bottom: 15px;
  padding: 20px 35px 25px;
  vertical-align: top;
  width: 100%;
  font-family: 'Open Sans', sans-serif;
  box-shadow: 0 5px 5px -3px rgb(0 0 0 / 20%), 0 8px 10px 1px rgb(0 0 0 / 14%), 0 3px 14px 2px rgb(0 0 0 / 12%);
  text-align: left;

  h3 {
    margin: 0 !important;
  }
`

const HeaderWrapper = styled.div`
  margin-bottom: 8px;
`

const HeaderInner = styled.div`
  margin-top: 5px;
  text-align: left;
  display: inline-block;
  width: 100%;
  font-size: 0.75rem;
`

const RateTextWrapper = styled.div`
  width: 48%;
  margin-left: 53%;
  position: relative;
  height: 14px;
`

const RateText = styled.div`
  color: ${({ theme }) => theme.text3};
  background: ${({ theme }) => theme.bg1};
  padding: 1px 5px;
  font-size: 11px;
  position: absolute;
  right: calc(50% - 15px);
  top: 0;
  z-index: 1;
`

const LineGraphic = styled.div`
  height: 1px;
  width: 100%;
  background-color: ${({ theme }) => theme.text3};
  position: absolute;
  right: 0;
  top: 8px;
  z-index: 0;
`

const StyledTooltip = styled(props => (
  <Tooltip classes={{ popper: props.className }} {...props}>
    {props.children}
  </Tooltip>
))`
  & .MuiTooltip-tooltip {
    font-family: 'Open Sans', sans-serif !important;
    font-size: 11px !important;
    background-color: #3d3e54 !important;
  }
`

const Column = styled.div`
  color: #d5d6e1;
  display: inline-block;
  font-weight: 100;
  vertical-align: top;
`

const TickerColumn = styled(Column)`
  text-align: left;
  width: 45%;
`

const TickerColumnTitle = styled(TickerColumn)`
  font-size: 14px;
  font-weight: 200;
  cursor: pointer;
`

const RateColumn = styled(Column)`
  text-align: right;
  width: 32.5%;
`

const SupplyColumn = styled(RateColumn)`
  width: 25%;
  font-size: 14px;
  line-height: 23px;
`

const BorrowColumn = styled(RateColumn)`
  width: 30%;
  font-size: 14px;
  line-height: 23px;
`

const SupplyColumnTitle = styled(SupplyColumn)`
  font-size: 14px;
  font-weight: 200;
  text-transform: capitalize;
  cursor: pointer;
  line-height: normal;
  user-select: none;
`

const BorrowColumnTitle = styled(BorrowColumn)`
  font-size: 14px;
  font-weight: 200;
  text-transform: capitalize;
  line-height: normal;
  cursor: pointer;
  user-select: none;
`

const LoadingBalancesText = styled.div`
  color: ${({ theme }) => theme.text3};
  font-size: 15px;
  margin-bottom: 18px;
`

const SearchIcon = styled(Search)`
  display: inline !important;
  width: 15px !important;
  height: 15px !important;
  margin-left: 1px;
  margin-bottom: -3px;
`

const FilterSection = styled.div`
  position: relative;
  width: 100%;
  display: inline-block;
  vertical-align: top;
`

const InputWrapper = styled.div`
  position: relative;
  vertical-align: top;
  width: calc(100% - 10px);
  margin-top: -10px;
  margin-bottom: -10px;
  z-index: 5;

  input {
    height: 14px !important;
    color: #f9f9f9 !important;
    display: inline-flex !important;
    position: relative !important;
    font-size: 13px !important;
    background: #1e1c29 !important;
    font-family: Open Sans, serif !important;
    line-height: 14px !important;
    font-weight: 300 !important;
    border-radius: 4px !important;
    padding-left: 10px !important;
    padding-right: 10px !important;
  }

  @media (max-width: 600px) {
    width: calc(100% - 20px);

    input {
      border-radius: 4px 0 0 4px !important;
    }
  }

  > div {
    margin-top: 3px;
    padding-top: 0;
    margin-bottom: 0 !important;
    padding-bottom: 0 !important;
  }

  > div > div {
    padding-top: 0 !important;
  }
`

const InputOverflowFix = styled.div`
  height: 33px;
  overflow: hidden;
`

const StyledInput = styled(({ ...props }) => <Input {...props} />)<{ multiline: boolean }>`
  overflow: hidden;
  margin-bottom: 0 !important;
  height: 28px !important;

  input {
    margin-bottom: 0 !important;
  }

  ${({ multiline }) =>
    multiline &&
    `
    margin-top: 2px;
    width: 100% !important;

    textarea {
      overflow: hidden !important;
      padding: 0 8px !important;
      width: calc(100% - 8px) !important;
    }
  `};
  @media (max-width: 1400px) {
    input {
      font-size: 0.9rem;
    }

    p {
      font-size: 0.8rem;
    }
  }

  @media (max-width: 400px) {
    input {
      font-size: 12px !important;
    }
  }
`

const Clear = styled.div<{ disabled: boolean }>`
  color: ${({ theme }) => theme.text3};
  font-weight: 500;
  position: absolute;
  right: 3px;
  top: 4px;
  display: inline-block;
  ${({ disabled }) => disabled && `display: none`};
`

const Close = styled(X)`
  cursor: pointer;
  height: 16px;
`

const RATE_SORT = {
  name: 'name',
  supply: 'supply',
  borrow: 'borrow',
}

function BorrowRatesComparator(prevProps: BorrowRatesProps, nextProps: BorrowRatesProps) {
  return (
    tokenEqualsOpt(prevProps.newBorrowRate?.token1, nextProps.newBorrowRate?.token1) &&
    percentEqualsOpt(prevProps.newBorrowRate?.rate1, nextProps.newBorrowRate?.rate1) &&
    tokenEqualsOpt(prevProps.newSupplyRate?.token1, nextProps.newSupplyRate?.token1) &&
    percentEqualsOpt(prevProps.newSupplyRate?.rate1, nextProps.newSupplyRate?.rate1) &&
    tokenEqualsOpt(prevProps.newBorrowRate?.token2, nextProps.newBorrowRate?.token2) &&
    percentEqualsOpt(prevProps.newBorrowRate?.rate2, nextProps.newBorrowRate?.rate2) &&
    tokenEqualsOpt(prevProps.newSupplyRate?.token2, nextProps.newSupplyRate?.token2) &&
    percentEqualsOpt(prevProps.newSupplyRate?.rate2, nextProps.newSupplyRate?.rate2)
  )
}

interface BorrowRatesProps {
  newBorrowRate: InterestRateChangedProps | undefined
  newSupplyRate: InterestRateChangedProps | undefined
}

function BorrowRates({ newBorrowRate, newSupplyRate }: BorrowRatesProps) {
  const { t } = useTranslation()
  const tokens = useAllActiveTokensArray()
  const [showYieldAsApr] = useShowYieldAsApr()
  const { data: interestRateMap } = useInterestRateData()
  const [oraclePriceMap] = useDefaultFiatValuesWithLoadingIndicator(tokens)
  const [selectedToken, setSelectedToken] = useState<Token | undefined>(undefined)
  const { data: dolomiteMargin } = useDolomiteMarginData()
  const tokenTvlMap = useDolomiteMarginTokenTvlData()
  const { data: marketRiskInfoMap } = useMarketRiskInfoData()
  const lastUpdateRef = useRef(Date.now())
  const sortedTokensRef = useRef<Token[]>([])
  const sortDirectionRef = useRef<boolean>(true)
  const sortFieldRef = useRef<string | undefined>(undefined)
  const searchValueRef = useRef<string>('')
  const [sortField, setSortField] = useState<string | undefined>(undefined)
  const [sortDirection, setSortDirection] = useState<boolean>(true)
  const [searchValue, setSearchValue] = useState<string>('')
  const [tokenSearch, setTokenSearch] = useState<boolean>(false)
  const filteredTokens = useMemo(() => {
    const tokenArray = Array.of(...tokens)
    if (!searchValue) return tokenArray
    const searchTerm = searchValue.toUpperCase()
    return tokenArray.filter(token => cleanCurrencySymbol(token)?.includes(searchTerm))
  }, [tokens, searchValue])
  const sortedTokens = useMemo(() => {
    const now = Date.now()
    if (
      sortDirection !== sortDirectionRef.current ||
      sortField !== sortFieldRef.current ||
      searchValue !== searchValueRef.current
    ) {
      sortDirectionRef.current = sortDirection
      sortFieldRef.current = sortField
      searchValueRef.current = searchValue
    } else if (now - lastUpdateRef.current < 5000 && sortedTokensRef.current.length > 5) {
      return sortedTokensRef.current // Return the previous result if within 5 seconds
    }
    lastUpdateRef.current = now

    const sorted = filteredTokens.sort((token1, token2) => {
      const token1OraclePrice = oraclePriceMap[token1.address] ?? ZERO_FRACTION
      const token1SupplyTvl = tokenTvlMap[token1.address]?.supplyLiquidity.asFraction ?? ZERO_FRACTION
      const token1SupplyTvlUSD = token1SupplyTvl.multiply(token1OraclePrice)
      const token2OraclePrice = oraclePriceMap[token2.address] ?? ZERO_FRACTION
      const token2SupplyTvl = tokenTvlMap[token2.address]?.supplyLiquidity.asFraction ?? ZERO_FRACTION
      const token2SupplyTvlUSD = token2SupplyTvl.multiply(token2OraclePrice)

      if (sortField === RATE_SORT.supply) {
        return interestRateMap[token1.address ?? '']?.totalSupplyInterestRate.greaterThan(
          interestRateMap[token2.address ?? '']?.totalSupplyInterestRate ?? ZERO_PERCENT,
        )
          ? (sortDirection ? -1 : 1) * 1
          : (sortDirection ? -1 : 1) * -1
      } else if (sortField === RATE_SORT.borrow) {
        console.log('\nDATA')
        if (interestRateMap[token1.address ?? '']?.totalBorrowInterestRate.equalTo(ZERO_PERCENT)) return 1
        if (interestRateMap[token2.address ?? '']?.totalBorrowInterestRate.equalTo(ZERO_PERCENT)) return -1
        return interestRateMap[token1.address ?? '']?.totalBorrowInterestRate.greaterThan(
          interestRateMap[token2.address ?? '']?.totalBorrowInterestRate ?? ZERO_PERCENT,
        )
          ? (sortDirection ? -1 : 1) * 1
          : (sortDirection ? -1 : 1) * -1
      } else if (sortField === RATE_SORT.name) {
        return sortDirection ? -1 : 1 * ((cleanCurrencyName(token1) ?? '') > (cleanCurrencyName(token2) ?? '') ? 1 : -1)
      }
      return token1SupplyTvlUSD.greaterThanOrEqual(token2SupplyTvlUSD) ? -1 : 1
    })

    sortedTokensRef.current = sorted // Cache the sorted result
    return sorted
  }, [sortDirection, sortField, searchValue, filteredTokens, oraclePriceMap, tokenTvlMap, interestRateMap])
  const rawIsLoading =
    !dolomiteMargin ||
    (!searchValue &&
      (Object.keys(oraclePriceMap).length === 0 ||
        Object.keys(interestRateMap).length === 0 ||
        Object.keys(tokenTvlMap).length === 0 ||
        Object.keys(marketRiskInfoMap).length === 0 ||
        sortedTokens.length === 0))
  const isLoading = useDebounce(rawIsLoading, 100)

  const handleSort = useCallback(
    (newField: string) => {
      setSortField(newField)
      setSortDirection(sortField !== newField ? true : !sortDirection)
    },
    [sortDirection, sortField],
  )

  const arrow = useCallback(
    (field: string) => {
      return sortField === field ? (!sortDirection ? '↑' : '↓') : ''
    },
    [sortDirection, sortField],
  )

  // TODO - make this a standard component to display balances so it can be reused on the pool page and the balances page
  return (
    <BorrowRatesWrapper>
      <HeaderWrapper>
        <HeaderInner>
          <RateTextWrapper>
            <RateText>{showYieldAsApr ? t('APR') : t('APY')}</RateText>
            <LineGraphic />
          </RateTextWrapper>
          <TickerColumnTitle>
            {tokenSearch ? (
              <FilterSection>
                <InputWrapper>
                  <InputOverflowFix>
                    <StyledInput
                      onChange={(e: any) => setSearchValue(e.target.value)}
                      multiline={false}
                      fullWidth
                      spellCheck={false}
                      placeholder={'Search'}
                      value={searchValue}
                      variant=''
                      disableUnderline={true}
                      endAdornment={''}
                    />
                  </InputOverflowFix>
                  <Clear onClick={() => setSearchValue('')} disabled={searchValue === ''}>
                    <Close />
                  </Clear>
                </InputWrapper>
              </FilterSection>
            ) : (
              <>
                <span onClick={() => (tokenSearch ? undefined : handleSort(RATE_SORT.name))}>
                  Asset {arrow(RATE_SORT.name)}
                </span>
                <SearchIcon onClick={() => setTokenSearch(true)} />
              </>
            )}
          </TickerColumnTitle>
          <StyledTooltip title={t('supplyRatesTooltip')} placement='right'>
            <SupplyColumnTitle onClick={() => handleSort(RATE_SORT.supply)}>
              {t('supply')} {arrow(RATE_SORT.supply)}
            </SupplyColumnTitle>
          </StyledTooltip>
          <StyledTooltip title={t('borrowRatesTooltip')} placement='right'>
            <BorrowColumnTitle onClick={() => handleSort(RATE_SORT.borrow)}>
              {t('borrow')} {arrow(RATE_SORT.borrow)}
            </BorrowColumnTitle>
          </StyledTooltip>
        </HeaderInner>
      </HeaderWrapper>
      {isLoading ? (
        <LoadingBalancesText>{t('loadingBorrowRates')}</LoadingBalancesText>
      ) : (
        <div>
          {sortedTokens.map((token: Token) => (
            <BorrowRateRow
              key={`${token.chainId}-${token.address}`}
              token={token}
              selectedToken={selectedToken}
              defaultMinCollateralization={dolomiteMargin?.minCollateralization}
              defaultLiquidationReward={dolomiteMargin?.liquidationReward}
              interestRate={interestRateMap[token.address]}
              oraclePrice={oraclePriceMap[token.address]}
              tvl={tokenTvlMap[token.address]}
              marketRiskInfo={marketRiskInfoMap[token.address]}
              newBorrowRate={newBorrowRate}
              newSupplyRate={newSupplyRate}
              setSelectedToken={setSelectedToken}
              showYieldAsApr={showYieldAsApr}
            />
          ))}
        </div>
      )}
    </BorrowRatesWrapper>
  )
}

export default React.memo(BorrowRates, BorrowRatesComparator)
