import React, { useCallback, useContext, useEffect, useState } from 'react'
import styled from 'styled-components/macro'
import PageTitle from '../../components/common/PageTitle'
import DepositedBalances from '../Pool/DepositedBalances'
import BorrowPositionsAndTotals from './BorrowPositionsAndTotals'
import BorrowRates from './BorrowRates'
import { useTranslation } from 'react-i18next'
import InterestRateChangedProps from './InterestRateChangedProps'
import useApprovedToS from '../../hooks/useApprovedToS'
import FirstVisitPopoverBorrow from '../../components/FirstVisitPopoverBorrow/FirstVisitPopoverBorrow'
import { PageSizeContext } from '../App'
import { isBerachain } from '../../constants/chainId'
import { useActiveWeb3React } from '../../hooks'

const BorrowWrapper = styled.div`
  margin: 75px auto 0;
  padding: 0 15px;
  max-width: 1380px;
  text-align: center;

  @media screen and (max-width: 1100px) {
    max-width: 973px;
    display: flex;
    flex-direction: column-reverse;
    margin-top: 15px;
    padding: 0 15px;
  }

  @media screen and (min-width: 5000px) {
    margin-top: 15px;
    padding-bottom: 40px;
  }
`

const BalancesPanel = styled.div`
  display: inline-block;
  margin-right: 30px;
  width: calc(25% - 30px);
  min-width: 307px;
  text-align: left;
  vertical-align: top;

  @media screen and (max-width: 1100px) {
    margin: 0 auto;
    width: 637px;
  }

  @media screen and (max-width: 680px) {
    display: flex;
    flex-direction: column-reverse;
    margin: 0 auto;
    width: 100%;
    max-width: 650px;

    > div {
      margin-bottom: 20px;
    }
  }

  @media screen and (max-width: 680px) {
    display: flex;
    flex-direction: column;
    width: 100%;

    > div {
      width: 100%;
    }
  }

  @media screen and (max-width: 660px) {
    margin-bottom: 60px;
  }

  @media screen and (min-width: 5000px) {
    display: none;
  }
`

const BorrowsPanel = styled.div`
  display: inline-block;
  vertical-align: top;
  min-width: 612px;
  width: 637px;

  @media screen and (max-width: 1100px) {
    margin: 0 auto;
  }

  @media screen and (max-width: 680px) {
    width: 100%;
    min-width: 0;
  }
`

const RatesPanel = styled.div`
  display: inline-block;
  margin-left: 30px;
  width: calc(25% - 30px);
  vertical-align: top;

  @media screen and (max-width: 1330px) {
    display: none;
  }
`

const TabletRates = styled.div`
  display: none;
  width: 100%;

  @media screen and (max-width: 1330px) {
    display: inline-block !important;
  }

  @media screen and (max-width: 1100px) {
    margin: 0;
    width: calc(50% - 10px);
    display: inline-block;
  }

  @media screen and (max-width: 680px) {
    width: 100%;
  }
`

export default function Borrow() {
  const { t } = useTranslation()
  const { chainId } = useActiveWeb3React()
  const [newBorrowRate, setNewBorrowRate] = useState<InterestRateChangedProps | undefined>(undefined)
  const [newSupplyRate, setNewSupplyRate] = useState<InterestRateChangedProps | undefined>(undefined)
  const [hasVisitedBorrow, setHasVisitedBorrow] = useState(true)
  const [hasApprovedToS, setHasApprovedToS] = useApprovedToS()

  useEffect(() => {
    const localStorageContents = window.localStorage.getItem('dolomite_has_visited_borrow')
    if (!localStorageContents || localStorageContents !== 'true') {
      setHasVisitedBorrow(false)
    }
  }, [])

  const setHasVisited = useCallback(() => {
    window.localStorage.setItem('dolomite_has_visited_borrow', 'true')
    setHasVisitedBorrow(true)
  }, [])

  const setHasCompletedApprovals = useCallback(() => {
    setHasApprovedToS()
    setHasVisited()
  }, [setHasApprovedToS, setHasVisited])

  // const [hasVisitedBorrow, setHasVisitedBorrow] = useState(true)
  const { x, y } = useContext(PageSizeContext)

  return (
    <div>
      <PageTitle title={isBerachain(chainId) ? 'Bolomite | Borrow' : t('borrowPageTitle')} />
      {(!hasVisitedBorrow || !hasApprovedToS) && (
        <FirstVisitPopoverBorrow approvedToS={hasApprovedToS} setHasCompletedApprovals={setHasCompletedApprovals} />
      )}
      <BorrowWrapper>
        <BalancesPanel>
          <DepositedBalances isBorrow={true} />
          {x <= 1330 && (
            <TabletRates>
              <BorrowRates newBorrowRate={newBorrowRate} newSupplyRate={newSupplyRate} />
            </TabletRates>
          )}
        </BalancesPanel>
        <BorrowsPanel>
          <BorrowPositionsAndTotals setNewBorrowRate={setNewBorrowRate} setNewSupplyRate={setNewSupplyRate} />
        </BorrowsPanel>
        <RatesPanel>
          <BorrowRates newBorrowRate={newBorrowRate} newSupplyRate={newSupplyRate} />
        </RatesPanel>
      </BorrowWrapper>
    </div>
  )
}
