import { Token } from '@dolomite-exchange/sdk-core'
import { ChainId, ChainIdMap, initializeSingleValueChainIdMap } from '../chainId'

const decimals = 18
const symbol = 'USDe'
const name = 'USDe'

function getToken(chainId: ChainId, address: string) {
  return new Token(chainId, address, decimals, symbol, name)
}

export const USDE: ChainIdMap<Token | undefined> = {
  [ChainId.MAINNET]: undefined,
  [ChainId.ARBITRUM_ONE]: getToken(ChainId.ARBITRUM_ONE, '0x5d3a1Ff2b6BAb83b63cd9AD0787074081a52ef34'),
  [ChainId.BASE]: undefined,
  [ChainId.BERACHAIN]: getToken(ChainId.BERACHAIN, '0x5d3a1Ff2b6BAb83b63cd9AD0787074081a52ef34'),
  [ChainId.MANTLE]: getToken(ChainId.MANTLE, '0x5d3a1Ff2b6BAb83b63cd9AD0787074081a52ef34'),
  [ChainId.POLYGON_ZKEVM]: undefined,
  [ChainId.X_LAYER]: undefined,
}
