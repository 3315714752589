import JSBI from 'jsbi'

import { MarginAccount as MarginAccountGql } from './gqlTypeHelpers'
import { useActiveWeb3React } from '../hooks'
import { useMemo } from 'react'
import { toChecksumAddress } from '../utils/toChecksumAddress'
import { ZERO_ADDRESS } from '../constants'
import { useBlockNumber } from '../state/chain/hooks'

export class MarginAccount {
  public user: string
  public effectiveUser: string
  public accountNumber: JSBI

  constructor(user: string, effectiveUser: string, accountNumber: JSBI) {
    this.user = toChecksumAddress(user)
    this.effectiveUser = toChecksumAddress(effectiveUser)
    this.accountNumber = accountNumber
  }

  public toString(): string {
    return `${this.user.toLowerCase()}-${this.accountNumber.toString()}`
  }
}

export function useDefaultMarginAccount(): MarginAccount {
  const { account, chainId } = useActiveWeb3React()
  return useMemo(() => {
    return {
      user: toChecksumAddress(account ?? ZERO_ADDRESS),
      effectiveUser: toChecksumAddress(account ?? ZERO_ADDRESS),
      accountNumber: JSBI.BigInt('0'),
    }
  }, [account])
}

export function createMarginAccount(marginAccount: MarginAccountGql): MarginAccount {
  return new MarginAccount(
    toChecksumAddress(marginAccount.user.id),
    toChecksumAddress(marginAccount.effectiveUser.id),
    JSBI.BigInt(marginAccount.accountNumber),
  )
}

export function createMarginAccountOpt(marginAccount?: MarginAccountGql): MarginAccount | undefined {
  return marginAccount ? createMarginAccount(marginAccount) : undefined
}
