import { SpecialAsset, SpecialAssetSymbol } from '../special-assets'
import {
  ChainId,
  DAI,
  PENDLE_YT_GLP_ADDRESSES,
  PENDLE_YT_GLP_MAR_2024_ISOLATION_MODE_ADDRESSES,
  USDC,
  USDT,
  WBTC,
} from '../../index'
import { Token } from '@dolomite-exchange/v2-sdk'
import { WETH } from '../../tokens/WETH'
import { MIM } from '../../tokens/MIM'
import { BRIDGED_USDC } from '../../tokens/USDC'
import { calculatePendleYtGLPProxyVaultAddress } from '../../../utils/isolation/calculateProxyVaultAddress'
import { getOptionally } from '../special-assets-util-functions'

export const YT_GLP_MAR_2024_SPECIAL_ASSET: () => SpecialAsset = () => ({
  symbol: SpecialAssetSymbol.dYtGLPMarch2024,
  safeSymbol: SpecialAssetSymbol.dYtGLPMarch2024.replace('-', '_'),
  cleanSymbol: 'YT-GLP',
  cleanName: 'YT GLP 2024-03-28',
  hasExternalRewards: true,
  documentationUrl: 'https://docs.dolomite.io/integrations/pendle-glp',
  isIsolationMode: true,
  isBorrowingEnabled: false,
  chainIdToAddressMap: PENDLE_YT_GLP_MAR_2024_ISOLATION_MODE_ADDRESSES,
  isolationModeInfo: {
    marketId: 16,
    getWrappedToken: (chainId: ChainId) =>
      getOptionally(
        PENDLE_YT_GLP_MAR_2024_ISOLATION_MODE_ADDRESSES[chainId],
        address =>
          new Token(chainId, address, 18, SpecialAssetSymbol.dYtGLPMarch2024, 'Dolomite Isolation: YT GLP 28MAR2024'),
      ),
    getUnderlyingToken: getPendleYtGLPUnderlyingToken,
    getUnderlyingSpenderToken: getPendleYtGLPUnderlyingToken,
    allowableDebtTokens: chainId => {
      return [
        WETH[chainId],
        DAI[chainId],
        USDC[chainId],
        WBTC[chainId],
        USDT[chainId],
        MIM[chainId],
        BRIDGED_USDC[chainId],
      ].filter(token => !!token) as Token[]
    },
    allowableCollateralTokens: () => [],
    remapAccountAddress: calculatePendleYtGLPProxyVaultAddress,
    calculateExpirationTimestamp: () => {
      const oneWeek = 86400 * 1000 * 7
      const expirationTimestamp = 1711584000000
      if (Date.now() >= expirationTimestamp - oneWeek) {
        return {
          isValid: false,
          expirationTimestamp: undefined,
        }
      }
      if (Date.now() >= expirationTimestamp - oneWeek * 5) {
        // The current timestamp is within 4 weeks of the week before expiration. Return the week before expiration.
        return {
          isValid: true,
          expirationTimestamp: new Date(expirationTimestamp - oneWeek),
        }
      }
      return {
        isValid: true,
        expirationTimestamp: new Date(Date.now() + oneWeek * 4),
      }
    },
    isAsync: false,
  },
})

function getPendleYtGLPUnderlyingToken(chainId: ChainId): Token | undefined {
  return getOptionally(
    PENDLE_YT_GLP_ADDRESSES[chainId],
    address => new Token(chainId, address, 18, 'YT-GLP', 'YT GLP 28MAR2024'),
  )
}
