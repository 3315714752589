import React, { useEffect, useState } from 'react'
import styled from 'styled-components/macro'
import { useTradeActionHandlers } from '../../state/trade/hooks'
import EarnPageImage from '../../assets/images/tilted-earn-page-image.png'
import PolygonsImage from '../../assets/images/polygons-image.png'

const FirstVisitWrapper = styled.div`
  width: 100%;
  height: 100%;
  position: fixed;
  left: 0;
  top: 0;
  background: rgba(0, 0, 0, 0.5);
  z-index: 999;
`

const FirstVisitInner = styled.div`
  width: 750px;
  height: 360px;
  border-radius: 8px;
  box-shadow: 0 5px 5px -3px rgb(0 0 0 / 20%), 0 8px 10px 1px rgb(0 0 0 / 14%), 0 3px 14px 2px rgb(0 0 0 / 12%);
  background: ${({ theme }) => theme.bg2};
  margin: calc(50vh - 300px) auto 0;
  overflow: hidden;

  @media screen and (max-height: 700px) {
    margin: calc(50vh - 280px) auto 0;
  }

  @media screen and (max-width: 850px) {
    margin-top: 20vh;
  }

  @media screen and (max-width: 420px) {
    margin-top: 8vh;
  }
`

const FirstVisitInnerMobile = styled(FirstVisitInner)`
  width: 80%;
  max-width: 400px;
  min-width: 280px;
  padding: 15px 20px;

  > div > button {
    width: 100%;
    cursor: pointer;
    display: inline-block;
    vertical-align: top;
    border: none;
    height: 49px;
    font-size: 18px;
    line-height: 22px;
    padding: 12px 28px;
    border-radius: 5px;
    background-color: ${({ theme }) => theme.green1} !important;
    color: ${({ theme }) => theme.text1} !important;
    margin: 0 auto 10px !important;
  }

  @media screen and (max-width: 500px) {
    width: 90%;
  }

  @media screen and (max-width: 400px) {
    width: 90%;
    max-height: 100%;
    overflow-y: auto;
  }
`

const Left = styled.div`
  display: inline-block;
  vertical-align: top;
  width: 100%;
  margin-right: -100%;
  height: 360px;
  overflow: hidden;
  background: black;

  img {
    height: 100%;
    width: 100%;
  }
`

const Right = styled.div`
  display: inline-block;
  vertical-align: top;
  width: 100%;
  height: fit-content;
  padding: 36px 48px;
`

const Title = styled.div`
  font-size: 48px;
  font-weight: 500;
  margin-bottom: 15px;

  @media screen and (max-width: 420px) {
    font-size: 28px;
  }
`

const Subtitle = styled.div`
  font-size: 16px;
  font-weight: 400;
  margin-bottom: 12px;
  color: #d5d6e1;
  letter-spacing: 0.1px;

  a {
    color: ${({ theme }) => theme.text1};
    font-weight: 700;
    text-decoration: none;
  }

  @media screen and (max-height: 700px) {
    font-size: 13px;
  }

  @media screen and (max-width: 420px) {
    font-size: 13px;
  }
`

const Subheader = styled.div`
  font-size: 20px;
  font-weight: 600;
  margin-bottom: 6px;
`

const InfoPoint = styled.div`
  font-size: 14px;
`

const StartButtonsWrapper = styled.div<{ disabled: boolean }>`
  width: 100%;
  text-align: right;
  margin-top: 20px;
  opacity: ${({ disabled }) => (disabled ? 0.6 : 1)};
  pointer-events: ${({ disabled }) => (disabled ? 'none' : 'auto')};
  cursor: ${({ disabled }) => (disabled ? 'default' : 'auto')};

  button {
    cursor: pointer;
    display: inline-block;
    vertical-align: top;
    border: none;
    height: 34px;
    font-size: 14px;
    line-height: 22px;
  }

  button:nth-of-type(1) {
    color: ${({ theme }) => theme.text3};
    background: none;
    margin-right: 8px;
  }

  button:nth-of-type(2) {
    padding: 6px 12px;
    border-radius: 5px;
    background-color: ${({ theme }) => theme.green1};
    color: ${({ theme }) => theme.text1};
  }
`

const GuideWrapper = styled.div`
  position: fixed;
  z-index: 9999;
  width: 100%;
  height: 100%;
  left: 0;
  top: 0;
  pointer-events: none;
`

const Guide = styled.div`
  transition: all 0.4s ease-in-out;
  background: rgba(0, 0, 0, 0.5);
  position: fixed;
  z-index: 99999;
  pointer-events: all;
`

const GuideTop = styled(Guide)`
  top: 0;
  left: 0;
`

const GuideRight = styled(Guide)`
  right: 0;
`

const GuideBottom = styled(Guide)`
  bottom: 0;
  left: 0;
`

const GuideLeft = styled(Guide)`
  left: 0;
`

const GuideBox = styled.div`
  width: 310px;
  height: auto;
  border-radius: 8px;
  box-shadow: 0 0 1px rgba(0, 0, 0, 0.01), 0 4px 8px rgba(0, 0, 0, 0.04), 0 16px 24px rgba(0, 0, 0, 0.04),
    0 24px 32px rgba(0, 0, 0, 0.01);
  background: ${({ theme }) => theme.bg2};
  padding: 20px 25px;
  color: ${({ theme }) => theme.text1};
  position: fixed;
  z-index: 999999;
  transition: all 0.4s ease-in-out;
  pointer-events: all;
`

const GuideTitle = styled.div`
  font-size: 18px;
  font-weight: 600;
  margin-bottom: 3px;
`

const GuideContent = styled.div`
  font-size: 14px;
  font-weight: 300;
  width: 100%;
  height: fit-content;
`

const GuideButtonWrapper = styled.div`
  width: 100%;
  margin-top: 10px;
  float: left;

  button {
    color: ${({ theme }) => theme.text3};
    font-size: 14px;
    background: none;
    border: none;
    cursor: pointer;
    padding-top: 6px;
    padding-left: 0;
  }
`

const GuideButton = styled.span`
  float: right;

  button:nth-of-type(1) {
    color: ${({ theme }) => theme.text3};
    font-size: 14px;
    background: none;
    border: none;
    cursor: pointer;
    margin-right: 7px;
    padding: 0;
  }

  button:nth-of-type(2) {
    padding: 6px 12px;
    border-radius: 5px;
    background-color: ${({ theme }) => theme.green1};
    color: ${({ theme }) => theme.text1};
    border: none;
    cursor: pointer;
  }
`

const TOS = styled.div<{ hidden: boolean }>`
  width: 100%;
  display: block;
  visibility: ${({ hidden }) => (hidden ? 'hidden' : 'visible')};
`

//TODO - move to general components file for reuse elsewhere
export const Checkbox = styled.div<{ disabled?: boolean }>`
  display: inline-block;
  vertical-align: top;
  height: 17px;
  width: 17px;
  border: 2px solid #f9f9f9;
  background: none;
  border-radius: 3px;
  cursor: pointer;
  margin-right: 5px;
  margin-top: 1px;
  pointer-events: ${({ disabled }) => (disabled ? 'none' : 'auto')};
  opacity: ${({ disabled }) => (disabled ? 0.6 : 1)};
`

//TODO - move to general components file for reuse elsewhere
export const Checked = styled.div<{ isChecked: boolean }>`
  height: 7px;
  width: 7px;
  background: ${({ theme, isChecked }) => (isChecked ? '#f9f9f9' : 'none')};
  border-radius: 1px;
  margin-left: 3px;
  margin-top: 3px;
`

const TOSText = styled.div`
  display: inline-block;
  vertical-align: top;
  font-size: 14px;

  a {
    color: ${({ theme }) => theme.text1};
    text-decoration: none;
    font-weight: 700;
  }

  @media screen and (max-width: 420px) {
    font-size: 12px;
  }
`

const Cookies = styled.div`
  width: 60%;
  display: inline-block;
  vertical-align: top;
  float: left;
  line-height: 14px;
  font-weight: 200;
  margin-top: 14px;
  font-size: 11px;
  letter-spacing: 0.2px;

  @media (max-width: 850px) {
    width: 100%;
    font-size: 12px;
    line-height: 15px;
    margin-bottom: 15px;
  }
`

const TOP_STEPS = [
  {
    width: '100%',
    height: '15px',
  },
  {
    width: '100%',
    height: '100px',
  },
  {
    width: '100%',
    height: '340px',
  },
  {
    width: '100%',
    height: '240px',
  },
  {
    width: '100%',
    height: '240px',
  },
  {
    width: '100%',
    height: '65px',
  },
  {
    width: '100%',
    height: '120px',
  },
  {
    width: '100%',
    height: '120px',
  },
  {
    width: '100%',
    height: 'calc(65% + 37px)',
  },
  {
    width: '100%',
    height: '12px',
  },
  {
    width: '100%',
    height: '0',
  },
]

const RIGHT_STEPS = [
  {
    width: 'calc(100% - 440px)',
    height: '42px',
    top: '15px',
  },
  {
    width: 'calc(50% - 700px)',
    height: '220px',
    top: '100px',
  },
  {
    width: 'calc(50% - 1200px)',
    height: '100%',
    top: '340px',
  },
  {
    width: 'calc(100% - 300px)',
    height: '290px',
    top: '240px',
  },
  {
    width: 'calc(100% - 300px)',
    height: '375px',
    top: '240px',
  },
  {
    width: '15px',
    height: '55px',
    top: '65px',
  },
  {
    width: 'calc(100% - 525px)',
    height: 'calc(100% - 130px)',
    top: '120px',
  },
  {
    width: '5px',
    height: 'calc(65% - 83px)',
    top: '120px',
  },
  {
    width: '15px',
    height: 'calc(35% - 37px)',
    top: 'calc(65% + 37px)',
  },
  {
    width: '12px',
    height: '48px',
    top: '12px',
  },
  {
    width: '0',
    height: '100%',
    top: '0',
  },
]

const BOTTOM_STEPS = [
  {
    width: '100%',
    height: 'calc(100% - 57px)',
  },
  {
    width: '100%',
    height: 'calc(100% - 320px)',
  },
  {
    width: '100%',
    height: '0',
  },
  {
    width: '100%',
    height: 'calc(100% - 530px)',
  },
  {
    width: '100%',
    height: 'calc(100% - 615px)',
  },
  {
    width: '100%',
    height: 'calc( 100% - 120px)',
  },
  {
    width: '100%',
    height: '10px',
  },
  {
    width: '100%',
    height: 'calc(35% - 37px)',
  },
  {
    width: '100%',
    height: '0px',
  },
  {
    width: '100%',
    height: 'calc(100% - 60px)',
  },
  {
    width: '100%',
    height: '0',
  },
]

const LEFT_STEPS = [
  {
    width: '60px',
    height: '42px',
    top: '15px',
  },
  {
    width: 'calc(50% - 700px)',
    height: '220px',
    top: '100px',
  },
  {
    width: '0',
    height: '117px',
    top: '125px',
  },
  {
    width: '0',
    height: '190px',
    top: '240px',
  },
  {
    width: '0',
    height: '375px',
    top: '240px',
  },
  {
    width: '315px',
    height: '55px',
    top: '65px',
  },
  {
    width: '315px',
    height: 'calc(100% - 130px)',
    top: '120px',
  },
  {
    width: '535px',
    height: 'calc(65% - 83px)',
    top: '120px',
  },
  {
    width: '535px',
    height: 'calc(35% - 37px)',
    top: 'calc(65% + 37px)',
  },
  {
    width: 'calc(100% - 60px)',
    height: '48px',
    top: '12px',
  },
  {
    width: '0',
    height: '100%',
    top: '0',
  },
]

const GUIDE_TEXT_TITLES = [
  'Navigate',
  'Summary',
  'Navigate',
  'Navigate',
  'Navigate',
  'Navigate',
  'Navigate',
  'Navigate',
  'Navigate',
  'Navigate',
  'Navigate',
]

const GUIDE_TEXT = [
  'Use the nav bar to access other Dolomite features, such as Borrowing and Strategies, or information such as your usage history.',
  // eslint-disable-next-line
  'With your wallet connected, you can see your available balance on Dolomite, the average yield it\'s earning, and an overview of all your open borrow positions and strategies.',
  'Here you can see information for the available assets on Dolomite on your selected chain.',
  'Here is where you can place spot trades on Dolomite, exchanging one asset for another. Selecting the "Margin" tab at the top switches this panel to margin trading.',
  'With margin trading you can gain more exposure to price movement of one asset against another with up to 5x leverage, turning a 5% increase in price into as much as a 25% increase in position value.',
  'Here you can find statistics about the currently selected trading pair. Hovering your mouse over each statistic will provide more information.',
  'This is a depth chart visualization of the liquidity in the currently in the market, as well as the current mid-market price. Hovering your mouse over this chart will let you know how much trade volume it would take to move the market price to a given new price. Clicking the market price will swap the assets on the chart, for example changing prices from ETH/USDC to USDC/ETH. Clicking the plus or minus in the bottom left allows you to change the price interval of each step in the chart. When you enter a trade into the Trade panel on the left, its impact on the market price will be displayed on this chart.',
  'This is a chart showing price movement and trade history for the currently selected trading pair.',
  'This panel shows your recent trades and positions. The "Trades" tab will show trades made on just this trading pair, including trades done to open a margin position. The "Positions" tab will display open and recently closed positions. For a comprehensive list of trades and positions, select the "History" tab at the top left of the page.',
  'If you want to see this guide again, you can find it as well as other information and links in the menu.',
  'That\'s all the basics you need to know to get started! Make sure you go to the "Balances" tab in the upper left and deposit to Dolomite from your wallet to begin trading and earning!',
]

const GUIDE_POSITION = [
  {
    left: '450px',
    top: '10px',
  },
  {
    left: 'calc(20% + 120px)',
    top: '350px',
  },
  {
    left: '320px',
    top: '100px',
  },
  {
    left: '320px',
    top: '280px',
  },
  {
    left: '320px',
    top: '300px',
  },
  {
    left: 'calc(50%)',
    top: '150px',
  },
  {
    left: '550px',
    top: 'calc(50vh - 200px)',
  },
  {
    left: '200px',
    top: '35%',
  },
  {
    left: '200px',
    top: 'calc(100% - 300px)',
  },
  {
    left: 'calc(100% - 380px)',
    top: '10px',
  },
  {
    left: 'calc(50% - 150px)',
    top: 'calc(50% - 140px)',
  },
]

const incrementStep = (
  currentStep: number,
  setCurrentStep: (newStep: number) => void,
  onChangeLeverage: (typedValue: string) => void,
) => {
  if (currentStep === 4) onChangeLeverage('2')
  if (currentStep === 5) onChangeLeverage('1')
  setCurrentStep(currentStep + 1)
}

const decrementStep = (
  currentStep: number,
  setCurrentStep: (newStep: number) => void,
  onChangeLeverage: (typedValue: string) => void,
) => {
  if (currentStep === 5) onChangeLeverage('1')
  if (currentStep === 6) onChangeLeverage('2')
  setCurrentStep(currentStep - 1)
}

export default function BalancesTour({
  width,
  height,
  approvedToS,
  setHasVisited,
  setApprovedToS,
}: {
  width: number
  height: number
  approvedToS: boolean
  setHasVisited: () => void
  setApprovedToS: () => void
}) {
  // get all popups
  const [currentStep, setCurrentStep] = useState(0)
  const [hasApprovedToS, setHasApprovedToS] = useState(approvedToS)

  const { onChangeLeverage } = useTradeActionHandlers()

  useEffect(() => {
    if (currentStep === 12) {
      setHasVisited()
      setApprovedToS()
    }
  }, [currentStep, setHasVisited, setApprovedToS])

  if (width <= 850 && currentStep < 12) {
    return (
      <FirstVisitWrapper>
        <FirstVisitInnerMobile>
          <Title>Welcome to Dolomite!</Title>
          <Subtitle>
            Dolomite is a next-gen lending protocol, where you can earn and borrow in more ways and with more assets
            than ever before!
          </Subtitle>
          <Subtitle>{`It's very quick and easy to get started trading and earning on Dolomite.`}</Subtitle>
          <Subtitle>
            {`You're free to jump right in, but if you'd like to learn how to start using Dolomite, check out the guides in our Docs `}
            <a href={'https://docs.dolomite.io/guide/depositing-to-dolomite'} target={'_blank'} rel={'noreferrer'}>
              here
            </a>
          </Subtitle>
          <TOS hidden={approvedToS}>
            <Checkbox onClick={() => setHasApprovedToS(!hasApprovedToS)}>
              <Checked isChecked={hasApprovedToS} />
            </Checkbox>
            <TOSText>
              I agree to the Dolomite{' '}
              <a target={'_blank'} rel={'noreferrer'} href={'https://dolomite.io/Dolomite-Terms-of-Service.pdf'}>
                Terms of Service
              </a>
            </TOSText>
          </TOS>
          <Cookies>
            By proceeding, you agree to the use of a small number of strictly necessary cookies that Dolomite uses to
            run properly.
          </Cookies>
          <StartButtonsWrapper disabled={!hasApprovedToS} style={{ textAlign: 'center' }}>
            <button onClick={() => hasApprovedToS && setCurrentStep(12)}>Jump in</button>
          </StartButtonsWrapper>
        </FirstVisitInnerMobile>
      </FirstVisitWrapper>
    )
  }

  if (currentStep === 0) {
    return (
      <FirstVisitWrapper>
        <FirstVisitInner>
          <Left>
            <img src={PolygonsImage} />
          </Left>
          <Right>
            <Title>Welcome to Dolomite!</Title>
            <Subtitle>
              Dolomite is a next-gen lending protocol, where you can earn and borrow in more ways and with more assets
              than ever before!
            </Subtitle>
            <Subtitle>{`It's very quick and easy to get started earning on Dolomite.`}</Subtitle>
            <Subtitle>{`If you're new to Dolomite, click "Start" to get a tour of the Earn page.`}</Subtitle>
            <TOS hidden={approvedToS}>
              <Checkbox onClick={() => setHasApprovedToS(!hasApprovedToS)}>
                <Checked isChecked={hasApprovedToS} />
              </Checkbox>
              <TOSText>
                I agree to the Dolomite{' '}
                <a target={'_blank'} rel={'noreferrer'} href={'https://dolomite.io/Dolomite-Terms-of-Service.pdf'}>
                  Terms of Service
                </a>
              </TOSText>
            </TOS>
            <Cookies>
              By proceeding, you agree to the use of a small number of strictly necessary cookies that Dolomite uses to
              run properly.
            </Cookies>
            <StartButtonsWrapper disabled={!hasApprovedToS}>
              <button onClick={() => hasApprovedToS && setCurrentStep(12)}>Skip tour</button>
              <button onClick={() => hasApprovedToS && setCurrentStep(1)}>Start</button>
            </StartButtonsWrapper>
          </Right>
        </FirstVisitInner>
      </FirstVisitWrapper>
    )
  } else if (currentStep > 0 && currentStep < 12) {
    return (
      <GuideWrapper>
        <GuideTop style={TOP_STEPS[currentStep - 1]} />
        <GuideRight
          style={
            width <= 1440 && currentStep === 7
              ? {
                  width: 'calc(100% - 525px)',
                  height: 'calc(65% - 88px)',
                  top: '120px',
                }
              : RIGHT_STEPS[currentStep - 1]
          }
        />
        <GuideBottom
          style={
            width <= 1440 && currentStep === 7
              ? {
                  width: '100%',
                  height: 'calc(35% - 32px)',
                }
              : BOTTOM_STEPS[currentStep - 1]
          }
        />
        <GuideLeft
          style={
            width <= 1440 && currentStep === 7
              ? {
                  width: '315px',
                  height: 'calc(65% - 88px)',
                  top: '120px',
                }
              : width <= 1440 && currentStep === 9
              ? {
                  width: '315px',
                  height: 'calc(35% - 37px)',
                  top: 'calc(65% + 37px)',
                }
              : LEFT_STEPS[currentStep - 1]
          }
        />
        <GuideBox
          style={
            width <= 1440 && currentStep === 7
              ? {
                  left: '550px',
                  top: 'calc(50vh - 250px)',
                }
              : width <= 1440 && currentStep === 9
              ? {
                  left: '420px',
                  top: 'calc(100% - 500px)',
                }
              : GUIDE_POSITION[currentStep - 1]
          }
        >
          <GuideTitle>{GUIDE_TEXT_TITLES[currentStep - 1]}</GuideTitle>
          <GuideContent>{GUIDE_TEXT[currentStep - 1]}</GuideContent>
          <GuideButtonWrapper>
            <button onClick={() => hasApprovedToS && setCurrentStep(12)}>Skip tour</button>
            <GuideButton>
              <button onClick={() => decrementStep(currentStep, setCurrentStep, onChangeLeverage)}>Back</button>
              <button onClick={() => incrementStep(currentStep, setCurrentStep, onChangeLeverage)}>
                {currentStep === 11 ? 'Done' : 'Next'}
              </button>
            </GuideButton>
          </GuideButtonWrapper>
        </GuideBox>
      </GuideWrapper>
    )
  } else {
    /*
     FINAL PANEL
     <InfoPoint>{`Here you can find a link to a quickstart guide to using Dolomite:`}</InfoPoint>
     <InfoPoint>{`QUICKSTART GUIDE LINK`}</InfoPoint>
     <InfoPoint>{`Here you can find Dolomite's documentation to learn more in-depth information about Dolomite and how everything works under the hood:`}</InfoPoint>
     <InfoPoint>{`DOCS LINK`}</InfoPoint>
     */
    return <></>
  }
}
