import { ChainId, GM_GMX_ADDRESSES, GM_GMX_ISOLATION_MODE_ADDRESSES } from '../../index'
import { Token } from '@dolomite-exchange/v2-sdk'
import { calculateGmGMXProxyVaultAddress } from '../../../utils/isolation/calculateProxyVaultAddress'
import { getOptionally } from '../special-assets-util-functions'
import { SpecialAsset, SpecialAssetSymbol } from '../special-assets'
import {
  getGmCollateralTokens,
  getGmDebtTokens,
  getGmValidInputTokens,
  getGmValidOutputTokens,
} from './special-gm-tokens'
import { GMX } from '../../tokens/GMX'

const cleanSymbol = 'gmGMX'
const cleanName = 'GM: GMX'

export const GM_GMX_SPECIAL_ASSET: () => SpecialAsset = () => ({
  cleanSymbol,
  cleanName,
  symbol: SpecialAssetSymbol.gmGMX,
  safeSymbol: SpecialAssetSymbol.gmGMX,
  hasExternalRewards: false,
  documentationUrl: 'https://docs.dolomite.io/integrations/gmx/gm',
  isIsolationMode: true,
  isBorrowingEnabled: false,
  chainIdToAddressMap: GM_GMX_ISOLATION_MODE_ADDRESSES,
  isolationModeInfo: {
    marketId: 63,
    getWrappedToken: (chainId: ChainId) =>
      getOptionally(
        GM_GMX_ISOLATION_MODE_ADDRESSES[chainId],
        address => new Token(chainId, address, 18, cleanSymbol, `Dolomite Isolation: ${cleanName}`),
      ),
    getUnderlyingToken: getGmToken,
    getUnderlyingSpenderToken: getGmToken,
    allowableCollateralTokens: (chainId: ChainId) =>
      getGmCollateralTokens(SpecialAssetSymbol.gmGMX, chainId, GMX[chainId]),
    allowableDebtTokens: (chainId: ChainId) => getGmDebtTokens(chainId, GMX[chainId]),
    allowableInputTokensForZap: (chainId: ChainId) => getGmValidInputTokens(GMX[chainId]),
    allowableOutputTokensForZap: (chainId: ChainId) => getGmValidOutputTokens(GMX[chainId]),
    remapAccountAddress: calculateGmGMXProxyVaultAddress,
    isAsync: true,
  },
})

function getGmToken(chainId: ChainId): Token | undefined {
  return getOptionally(GM_GMX_ADDRESSES[chainId], address => new Token(chainId, address, 18, cleanSymbol, cleanSymbol))
}
