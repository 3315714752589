import { Currency, Fraction, Percent, Price, Token, Trade, TradeType } from '@dolomite-exchange/v2-sdk'
import React, { useContext, useMemo, useState } from 'react'
import styled, { ThemeContext } from 'styled-components/macro'
import { Field } from '../../state/trade/actions'
import { useShowYieldAsApr, useUserSlippageTolerance } from '../../state/user/hooks'
import { TYPE } from '../../theme'
import { computeSlippageAdjustedAmounts, computeTradePriceBreakdown } from '../../utils/prices'
import { AutoColumn } from '../Column'
import { RowBetween, RowFixed } from '../Row'
import FormattedPriceImpact from './FormattedPriceImpact'
import SwapRoute from './TradeRoute'
import TradePrice from './TradePrice'
import cleanCurrencySymbol from '../../utils/cleanCurrencySymbol'
import KeyboardArrowDownIcon from '@material-ui/icons/KeyboardArrowDown'
import KeyboardArrowUpIcon from '@material-ui/icons/KeyboardArrowUp'
import { CurrencyAmount } from '@dolomite-exchange/sdk-core'
import { useMarketRiskInfoData } from '../../types/marketRiskInfoData'
import calculateLiquidationPrice from '../../utils/calculateLiquidationPrice'
import { reqParseAmount } from '../../state/trade/hooks'
import { IpErrorType, UNITED_STATE_EXPIRATION_DAYS } from '../../hooks/useIpGeolocation'
import TradeAmount from '../../pages/Trade/TokenAmount'
import { StyledTooltipWithIcon } from '../common/StyledTooltip'
import { useDolomiteMarginData } from '../../types/dolomiteMarginData'
import { useDolomiteMarginTokenAddressToIdMap } from '../../hooks/useDolomiteMarginProtocol'
import { deriveMarketFromTokensReq } from '../../utils/marketUtils'
import useFormattedPrice from '../../hooks/useFormattedPrice'
import getNewInterestRateWithSlippage from '../../utils/getNewInterestRateWithSlippage'
import { useInterestRateData } from '../../types/interestRateData'
import { useTranslation } from 'react-i18next'
import { useDolomiteMarginTokenTvlData } from '../../types/dolomiteMarginTokenTvlData'

const AdvancedWrapper = styled.div`
  height: auto;
`

const AdvancedInner = styled.div<{ expanded: boolean; isMargin: boolean }>`
  max-height: ${({ expanded, isMargin }) => (expanded ? (isMargin ? '118px' : '70px') : '0px')};
  height: auto;
  overflow: hidden;
  transition: max-height 0.2s ease-in-out;
`

const AdvancedTitle = styled.div`
  color: ${({ theme }) => theme.text3};
  cursor: pointer;
  width: 100%;
  text-align: center;
  font-size: 12px;
  margin: 5px 0;
  font-weight: 600;

  svg {
    font-size: 18px;
    line-height: 17px;
    height: 17px;
    vertical-align: bottom;
  }
`

const BalanceTooltip = styled.span`
  margin-left: 3px;
  margin-top: 0;
`

export const QuestionHelper = ({ text }: { text?: string }) => {
  return text ? (
    <BalanceTooltip>
      <StyledTooltipWithIcon tooltipText={text} placement='right' />
    </BalanceTooltip>
  ) : (
    <div />
  )
}

function TradeSummary({
  trade,
  marginDeposit,
  isMargin,
  allowedSlippage,
  isUnitedStatesIp,
  marketPrice,
  adjustedPriceImpactWithoutFee,
  showAdvanced,
  setShowAdvanced,
}: {
  trade: Trade<Currency, Currency, TradeType>
  marginDeposit?: CurrencyAmount<Token>
  isMargin: boolean
  allowedSlippage: number
  isUnitedStatesIp: boolean
  ipError: IpErrorType | undefined
  marketPrice: Fraction | undefined
  adjustedPriceImpactWithoutFee: Percent | undefined
  showAdvanced: boolean
  setShowAdvanced: (show: boolean) => void
}) {
  const { t } = useTranslation()
  const theme = useContext(ThemeContext)
  const { priceImpactWithoutFee, realizedLPFee } = computeTradePriceBreakdown(trade)
  const isExactIn = trade.tradeType === TradeType.EXACT_INPUT
  const [showInverted, setShowInverted] = useState<{
    priceInverted: boolean
    liquidationInverted: boolean
    amountInverted: boolean
  }>({
    priceInverted: false,
    liquidationInverted: false,
    amountInverted: false,
  })
  const { data: riskParams } = useDolomiteMarginData()
  const { data: interestRateMap } = useInterestRateData()
  const { data: marketRiskInfoMap } = useMarketRiskInfoData()

  const totalOutputAmount = useMemo(() => {
    return marginDeposit ? trade.outputAmount.add(marginDeposit) : trade.outputAmount
  }, [marginDeposit, trade])

  const market = useMemo(() => {
    return deriveMarketFromTokensReq(trade.inputAmount.currency.wrapped, trade.outputAmount.currency.wrapped)
  }, [trade])

  const openPrice = useFormattedPrice(trade.executionPrice, market)

  const liquidationPrice = useMemo(() => {
    const inputToken = trade.inputAmount.currency.wrapped
    const outputToken = trade.outputAmount.currency.wrapped

    const liquidationPriceFraction = calculateLiquidationPrice(
      totalOutputAmount,
      trade.inputAmount,
      riskParams?.minCollateralization,
      marketRiskInfoMap[outputToken.address],
      marketRiskInfoMap[inputToken.address],
      undefined,
    )
    return liquidationPriceFraction.greaterThan('0')
      ? new Price({
          baseAmount: reqParseAmount(liquidationPriceFraction.toFixed(inputToken.decimals), inputToken),
          quoteAmount: reqParseAmount('1', outputToken),
        })
      : undefined
  }, [trade, marketRiskInfoMap, riskParams, totalOutputAmount])
  const formattedLiquidationPrice = useFormattedPrice(liquidationPrice, market)

  const tokenAddressToMarketIdMap = useDolomiteMarginTokenAddressToIdMap()
  const totalWeiMap = useDolomiteMarginTokenTvlData()
  const [showYieldAsApr] = useShowYieldAsApr()
  const { borrowInterestRate } = useMemo(() => {
    return getNewInterestRateWithSlippage(
      trade.inputAmount.wrapped,
      CurrencyAmount.fromRawAmount(trade.inputAmount.currency.wrapped, '0'),
      interestRateMap,
      tokenAddressToMarketIdMap,
      totalWeiMap,
      riskParams,
      showYieldAsApr,
    )
  }, [interestRateMap, tokenAddressToMarketIdMap, totalWeiMap, trade, riskParams, showYieldAsApr])

  const slippageAdjustedAmounts = computeSlippageAdjustedAmounts(trade, allowedSlippage)
  //TODO - add translation support for the text here (titles and helpers). Not sure why Uniswap didn't do it
  return (
    <>
      <AutoColumn style={{ padding: '0' }}>
        {/* TODO - Show "position size" with the position size in terms of the "from" currency? */}
        {/* TODO - Hide trade amount in advanced? */}
        {isMargin && (
          <RowBetween>
            <RowFixed>
              <TYPE.black fontSize={12} fontWeight={400} color={theme.text2}>
                Position Size{' '}
              </TYPE.black>
              <QuestionHelper
                text={
                  'The total size of the position to be opened, including your deposited collateral and the amount purchased in the trade when the position is opened.'
                }
              />
            </RowFixed>
            <TradeAmount
              token0Amount={marginDeposit?.asFraction.add(trade.outputAmount.asFraction)}
              token1Amount={
                marketPrice
                  ? marginDeposit?.asFraction.add(trade.outputAmount.asFraction).divide(marketPrice)
                  : undefined
              }
              token0Symbol={cleanCurrencySymbol(trade.executionPrice.quoteCurrency)}
              token1Symbol={cleanCurrencySymbol(trade.executionPrice.baseCurrency)}
              marginDeposit={marginDeposit?.asFraction}
              tradeAmount={trade.outputAmount.asFraction}
              token1IsEstimate={true}
              showInverted={showInverted.amountInverted}
              setShowInverted={() => {
                setShowInverted({
                  priceInverted: showInverted.priceInverted,
                  liquidationInverted: showInverted.liquidationInverted,
                  amountInverted: !showInverted.amountInverted,
                })
              }}
            />
          </RowBetween>
        )}
        {isMargin && (
          <RowBetween>
            <RowFixed>
              <TYPE.black fontSize={12} fontWeight={400} color={theme.text2}>
                Trade Amount
              </TYPE.black>
              <QuestionHelper text='Total amount to be borrowed and traded to open your position.' />
            </RowFixed>
            <TYPE.black fontSize={12} color={theme.text1}>
              {trade.inputAmount.toSignificant(9)}
              &nbsp;
              {cleanCurrencySymbol(trade.inputAmount.currency)}
            </TYPE.black>
          </RowBetween>
        )}

        <RowBetween>
          <RowFixed>
            <TYPE.black fontSize={12} fontWeight={400} color={theme.text2}>
              {isMargin ? 'Open Price' : 'Price'}
            </TYPE.black>
            {isMargin && (
              <QuestionHelper text='The execution price of the trade, at the time the position was opened. Gains and losses are calculated relative to this price.' />
            )}
          </RowFixed>
          <RowFixed>
            <TradePrice
              price={openPrice}
              showInverted={showInverted.priceInverted}
              setShowInverted={() =>
                setShowInverted({
                  priceInverted: !showInverted.priceInverted,
                  liquidationInverted: showInverted.liquidationInverted,
                  amountInverted: showInverted.amountInverted,
                })
              }
            />
          </RowFixed>
        </RowBetween>

        {!isMargin && (
          <RowBetween>
            <RowFixed>
              <TYPE.black fontSize={12} fontWeight={400} color={theme.text2}>
                {isExactIn ? 'Minimum received' : 'Maximum sold'}
              </TYPE.black>
              <QuestionHelper text={t('minimumReceivedHelperText')} />
            </RowFixed>
            <RowFixed>
              <TYPE.black color={theme.text1} fontSize={12}>
                {isExactIn
                  ? `${slippageAdjustedAmounts[Field.OUTPUT]?.toFixed(4)}` ?? ''
                  : `${slippageAdjustedAmounts[Field.INPUT]?.toFixed(4)}` ?? ''}
              </TYPE.black>
              &nbsp;
              <TYPE.black color={theme.text2} fontSize={12}>
                {isExactIn
                  ? `${cleanCurrencySymbol(trade.outputAmount.currency)}` ?? '-'
                  : `${cleanCurrencySymbol(trade.inputAmount.currency)}` ?? '-'}
              </TYPE.black>
            </RowFixed>
          </RowBetween>
        )}

        {isMargin && (
          <RowBetween>
            <RowFixed>
              <TYPE.black fontSize={12} fontWeight={400} color={theme.text2}>
                Liquidation Price
              </TYPE.black>
              <QuestionHelper
                text={
                  'The price at which your position will be forcefully closed via liquidation, which includes a liquidation penalty.'
                }
              />
            </RowFixed>
            <TYPE.black fontSize={12} color={theme.text1}>
              <TradePrice
                price={formattedLiquidationPrice}
                showInverted={showInverted.liquidationInverted}
                setShowInverted={() =>
                  setShowInverted({
                    priceInverted: showInverted.priceInverted,
                    liquidationInverted: !showInverted.liquidationInverted,
                    amountInverted: showInverted.amountInverted,
                  })
                }
              />
            </TYPE.black>
          </RowBetween>
        )}
        <RowBetween>
          <RowFixed>
            <TYPE.black fontSize={12} fontWeight={400} color={theme.text2}>
              Price Impact
            </TYPE.black>
            <QuestionHelper text={t('priceImpactHelperText')} />
          </RowFixed>
          <FormattedPriceImpact
            priceImpact={priceImpactWithoutFee}
            adjustedPriceImpact={isMargin ? adjustedPriceImpactWithoutFee : undefined}
          />
        </RowBetween>
        <AdvancedWrapper>
          <AdvancedInner expanded={showAdvanced} isMargin={isMargin}>
            {/* TODO - hide everything from here down in an "advanced" dropdown */}
            {isMargin && (
              <RowBetween>
                <RowFixed>
                  <TYPE.black fontSize={12} fontWeight={400} color={theme.text2}>
                    Interest Rate (APR)
                  </TYPE.black>
                  <QuestionHelper text='The rate you pay for the assets you borrow to open your position.' />
                </RowFixed>
                <TYPE.black fontSize={12} color={theme.text1}>
                  {borrowInterestRate.toFixed(2)}%
                </TYPE.black>
              </RowBetween>
            )}

            <RowBetween>
              <RowFixed>
                <TYPE.black fontSize={12} fontWeight={400} color={theme.text2}>
                  Liquidity Provider Fee
                </TYPE.black>
                <QuestionHelper text={t('liquidityProviderFeeHelperText')} />
              </RowFixed>
              <TYPE.black fontSize={12} color={theme.text1}>
                {realizedLPFee
                  ? `${realizedLPFee.toSignificant(4)} ${cleanCurrencySymbol(trade.inputAmount.currency)}`
                  : '-'}
              </TYPE.black>
            </RowBetween>

            {/* TODO - only show this expiry if they're in the US and they actually have an expiry date, otherwise just hide the row in advanced or don't show it at all*/}
            {isMargin && (
              <RowBetween>
                <RowFixed>
                  <TYPE.black fontSize={12} fontWeight={400} color={theme.text2}>
                    Expiry
                  </TYPE.black>
                  <QuestionHelper
                    text={
                      // ipError === IpErrorType.BLOCKED
                      //   ? `Geolocating your IP address failed, so your position may only be kept open for
                      // ${UNITED_STATE_EXPIRATION_DAYS} days. To keep your position open longer, please whitelist
                      // ${IP_GEOLOCATION_BASE_URL} on your ad blocker`
                      //   : isUnitedStatesIp
                      //   ? `US users can only keep a position open for ${UNITED_STATE_EXPIRATION_DAYS} days. After that,
                      //   it will be automatically closed.`
                      //   : undefined
                      isUnitedStatesIp
                        ? `US users can only keep a position open for ${UNITED_STATE_EXPIRATION_DAYS} days. After that, 
                        it will be automatically closed.`
                        : `This position has no expiry, so you can keep it open as long as it maintains sufficient 
                        collateralization levels.`
                    }
                  />
                </RowFixed>
                <TYPE.black fontSize={12} color={theme.text1}>
                  {isUnitedStatesIp ? '28 DAYS' : 'No Expiry'}
                </TYPE.black>
              </RowBetween>
            )}

            <RowBetween style={{ padding: '0' }}>
              <span style={{ display: 'flex', alignItems: 'center' }}>
                <TYPE.black fontSize={12} fontWeight={400} color={theme.text2}>
                  Route
                </TYPE.black>
                <QuestionHelper text='Routing through these tokens resulted in the best price for your trade.' />
              </span>
              <SwapRoute trade={trade} />
            </RowBetween>
          </AdvancedInner>
          <AdvancedTitle onClick={() => setShowAdvanced(!showAdvanced)}>
            Advanced{' '}
            {showAdvanced ? <KeyboardArrowUpIcon style={{ marginBottom: '-1px' }} /> : <KeyboardArrowDownIcon />}
          </AdvancedTitle>
        </AdvancedWrapper>
      </AutoColumn>
    </>
  )
}

export interface AdvancedTradeDetailsProps {
  trade?: Trade<Currency, Currency, TradeType>
  isMargin: boolean
  marginDeposit?: CurrencyAmount<Token>
  isUnitedStatesIp: boolean
  ipError: IpErrorType | undefined
  marketPrice: Fraction | undefined
  adjustedPriceImpactWithoutFee: Percent | undefined
  showAdvanced: boolean
  setShowAdvanced: (showAdvanced: boolean) => void
}

export function AdvancedTradeDetails({
  trade,
  isMargin,
  marginDeposit,
  isUnitedStatesIp,
  ipError,
  marketPrice,
  adjustedPriceImpactWithoutFee,
  showAdvanced,
  setShowAdvanced,
}: AdvancedTradeDetailsProps) {
  const [allowedSlippage] = useUserSlippageTolerance()

  const showRoute = Boolean(trade && trade.route.path.length > 2)

  return (
    <AutoColumn gap='0px'>
      {trade && (
        <>
          <TradeSummary
            trade={trade}
            isMargin={isMargin}
            allowedSlippage={allowedSlippage}
            marginDeposit={marginDeposit}
            isUnitedStatesIp={isUnitedStatesIp}
            ipError={ipError}
            marketPrice={marketPrice}
            adjustedPriceImpactWithoutFee={adjustedPriceImpactWithoutFee}
            showAdvanced={showAdvanced}
            setShowAdvanced={setShowAdvanced}
          />
          {showRoute && <></>}
          {/* TODO show with analytics? */}
          {/*!showRoute && (
           <AutoColumn style={{ padding: '0' }}>
           <InfoLink
           href={'https://uniswap.info/pair/' + trade.route.pairs[0].liquidityToken.address}
           target="_blank"
           >
           View pair analytics ↗
           </InfoLink>
           </AutoColumn>
           )*/}
        </>
      )}
    </AutoColumn>
  )
}
