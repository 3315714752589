import React, { useRef } from 'react'
import styled from 'styled-components/macro'
import { NavLink } from 'react-router-dom'
import { darken } from 'polished'
import { useOnClickOutside } from '../../hooks/useOnClickOutside'
import { ApplicationModal } from '../../state/application/actions'
import { useModalOpen, useToggleModal } from '../../state/application/hooks'
import { useTranslation } from 'react-i18next'
import { useActiveWeb3React } from '../../hooks'
import { isBerachain } from '../../constants/chainId'
import { useUrlPathParams } from '../../state/trade/hooks'
import cleanCurrencySymbol from '../../utils/cleanCurrencySymbol'
import { USDC, WETH } from '../../constants'

const StyledMenuButton = styled.button`
  width: 100%;
  border: none;
  margin: 0;
  height: 35px;
  background-color: transparent;
  padding: 0;
  border-radius: 5px;
  color: ${({ theme }) => theme.text2};
  font-size: 1rem;
  font-weight: 500;

  :hover,
  :focus {
    cursor: pointer;
    outline: none;
    color: ${({ theme }) => darken(0.1, theme.text1)};
  }
`

const StyledMenu = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
  border: none;
  text-align: left;
  margin-left: 12px;
  height: 24px;

  @media screen and (max-width: 1300px) {
    margin: 0 8px 12px 8px;
    font-size: 18px;
    display: none;

    > button {
      font-size: 18px !important;
    }
  }
`

const MenuFlyout = styled.span<{ isOpen: boolean }>`
  min-width: 140px;
  background-color: ${({ theme }) => theme.bg2};
  box-shadow: 0px 0px 1px rgba(0, 0, 0, 0.01), 0px 4px 8px rgba(0, 0, 0, 0.04), 0px 16px 24px rgba(0, 0, 0, 0.04),
    0px 24px 32px rgba(0, 0, 0, 0.01);
  border-radius: 12px;
  padding: 0.5rem;
  display: ${({ isOpen }) => (isOpen ? 'flex' : 'none')};
  flex-direction: column;
  position: absolute;
  top: 3rem;
  left: 0;
  z-index: 100;
`

const StyledNavLink = styled(NavLink)`
  display: flex;
  flex: 1;
  padding: 0.5rem 0.5rem;
  color: ${({ theme }) => theme.text2};
  text-decoration: none;
  font-size: 1rem;
  font-weight: 500;
  width: 100%;

  :hover {
    color: ${({ theme }) => theme.text1};
    cursor: pointer;
    text-decoration: none;
  }
`

export default function MoreMenu({ isBeraMode }: { isBeraMode: boolean }) {
  const node = useRef<HTMLDivElement>()
  const open = useModalOpen(ApplicationModal.MORE)
  const toggle = useToggleModal(ApplicationModal.MORE)
  const { t } = useTranslation()
  const { chainId } = useActiveWeb3React()

  useOnClickOutside(node, open ? toggle : undefined)

  const { inputCurrency, outputCurrency } = useUrlPathParams()
  const tradePath =
    inputCurrency && outputCurrency
      ? `/trade/${inputCurrency}/${outputCurrency}`
      : `/trade/${cleanCurrencySymbol(USDC[chainId])}/${cleanCurrencySymbol(WETH[chainId])}`

  return (
    <StyledMenu ref={node as any}>
      <StyledMenuButton onClick={toggle}>More</StyledMenuButton>
      <MenuFlyout isOpen={open}>
        <StyledNavLink id={`swap-nav-link`} to={tradePath} onClick={toggle}>
          {isBerachain(chainId) && isBeraMode ? t('trade').replace(/^./, 'B') : t('trade')}
        </StyledNavLink>
        <StyledNavLink id='rewards-nav-link' to='/rewards' onClick={toggle}>
          {isBerachain(chainId) && isBeraMode ? t('rewards').replace(/^./, 'B') : t('rewards')}
        </StyledNavLink>
        <StyledNavLink id='minerals-nav-link' to='/minerals' onClick={toggle}>
          {isBerachain(chainId) && isBeraMode ? t('minerals').replace(/^./, 'B') : t('minerals')}
        </StyledNavLink>
        <StyledNavLink id='pool-nav-link' to='/pool' onClick={toggle}>
          {isBerachain(chainId) && isBeraMode ? t('pool').replace(/^./, 'B') : t('pool')}
        </StyledNavLink>
      </MenuFlyout>
    </StyledMenu>
  )
}
