import { useCallback, useEffect, useState } from 'react'
import { Fraction } from '@dolomite-exchange/sdk-core'
import { tryParseAmount } from '../state/trade/hooks'
import { createFraction } from '../types/gqlTypeHelpers'

export interface UsageAirdropData {
  amount: Fraction | undefined
  proofs: string[] | undefined
  userAddress: string | undefined
  remappedUserAddress: string | null
  isSmartContract: boolean
  levelSnapshot: number
  error?: string
  loading: boolean
}

const API_BASE_URL = 'https://api.dolomite.io'

export function useUsageAirdropData(account: string | null | undefined): UsageAirdropData {
  const [data, setData] = useState<UsageAirdropData>({
    amount: undefined,
    proofs: undefined,
    userAddress: undefined,
    remappedUserAddress: null,
    isSmartContract: false,
    levelSnapshot: 0,
    loading: false,
  })

  const fetchAirdropData = useCallback(async () => {
    if (!account) {
      return
    }

    setData(prevState => ({ ...prevState, loading: true }))

    try {
      const response = await fetch(`${API_BASE_URL}/airdrop/regular/${account}`)

      if (!response.ok) {
        throw new Error('Failed to fetch airdrop data')
      }

      const json = await response.json()
      const airdropData = json.airdrop

      // Parse the amount string into a Fraction
      const parsedAmount = createFraction(airdropData.amount)

      setData({
        amount: parsedAmount?.asFraction,
        proofs: airdropData.proofs,
        userAddress: airdropData.user_address,
        remappedUserAddress: airdropData.remapped_user_address,
        isSmartContract: airdropData.is_smart_contract,
        levelSnapshot: airdropData.level_snapshot,
        loading: false,
      })
    } catch (error) {
      console.error('Error fetching airdrop data:', error)
      setData(prevState => ({
        ...prevState,
        error: 'Failed to fetch airdrop data',
        loading: false,
      }))
    }
  }, [account])

  useEffect(() => {
    fetchAirdropData()
  }, [fetchAirdropData, account])

  return data
}
