import React, { SetStateAction, useCallback, useEffect, useMemo, useState } from 'react'
import styled from 'styled-components/macro'
import { useTranslation } from 'react-i18next'
import { Rounding, Token } from '@dolomite-exchange/v2-sdk'
import BorrowPositionRow from './BorrowPositionRow'
import OpenNewBorrow from './OpenNewBorrow'
import { useOpenBorrowPositions } from '../../types/borrowPositionData'
import { StyledTooltipWithIcon } from '../../components/common/StyledTooltip'
import { FORMATTER, ONE_BIPS, ZERO_FRACTION, ZERO_PERCENT } from '../../constants'
import TransactionConfirmationModal from '../../components/TransactionConfirmationModal'
import { useIsTransactionPending } from '../../state/transactions/hooks'
import { SimpleLoader } from '../../components/Loader'
import InterestRateChangedProps from './InterestRateChangedProps'
import { useActiveWeb3React } from '../../hooks'
import { formatAmount } from '../../utils/formatAmount'
import { useShowYieldAsApr } from '../../state/user/hooks'
import { useMarketRiskInfoData } from '../../types/marketRiskInfoData'
import { useDolomiteMarginData } from '../../types/dolomiteMarginData'
import { useModalOpen } from '../../state/application/hooks'
import { ApplicationModal } from '../../state/application/actions'
import { useActiveStrategies } from '../../hooks/useActiveStrategies'

const BorrowPositionsAndTotalsWrapper = styled.div`
  width: 100%;
  margin-bottom: 30px;
`

const TopStats = styled.div`
  text-align: center;
  margin-top: 25px;

  @media screen and (max-width: 600px) {
    margin-top: 10px;
  }
`

const Title = styled.div``

const Amount = styled.div``

const AmountStats = styled.div`
  display: flex;
  width: 100%;
  justify-content: space-evenly;
  margin-bottom: 10px;

  @media screen and (max-width: 600px) {
    flex-direction: column;
    align-items: center;
  }
`

const AmountStat = styled.div`
  width: fit-content;

  ${Title} {
    font-size: 15px;
    color: ${({ theme }) => theme.text2};
  }

  ${Amount} {
    font-size: 30px;
  }

  @media screen and (max-width: 600px) {
    margin-bottom: 10px;

    ${Amount} {
      font-size: 32px;
    }
  }
`

const CollateralAmountStat = styled(AmountStat)``

const BorrowAmountStat = styled(AmountStat)``

const InterestStats = styled.div`
  display: flex;
  width: 100%;
  justify-content: center;
`

const InterestStat = styled.div`
  width: 20%;
  min-width: 90px;

  ${Title} {
    font-size: 12px;
    color: ${({ theme }) => theme.text3};
    font-weight: 600;
  }

  ${Amount} {
    font-size: 18px;
  }

  svg {
    font-size: 12px !important;
  }
`

const EarnStat = styled(InterestStat)``

const OweStat = styled(InterestStat)``

const NetStat = styled(InterestStat)``

const OpenBorrowPositions = styled.div`
  width: 100%;
  margin-top: 30px;

  @media screen and (max-width: 600px) {
    margin-top: 50px;
  }
`

const OpenBorrowsTitle = styled.div`
  font-size: 18px;
  text-align: left;
  margin-bottom: 5px;
  margin-left: 2px;
  font-weight: 300;
`

const BorrowPositionsNumber = styled.div`
  display: inline-block;
  vertical-align: top;
  background: ${({ theme }) => theme.bg4};
  color: ${({ theme }) => theme.text1};
  font-weight: 700;
  font-size: 11px;
  padding: 1px 7px 1px 6px;
  border-radius: 5px;
  margin-left: 2px;
  margin-top: 4px;
`

const AprSelect = styled.div`
  display: inline-block;
  float: right;
  margin-top: -6px;
  border-radius: 5px;
  background-color: ${({ theme }) => theme.bg2};
  font-size: 14px;
  cursor: pointer;

  :hover {
    background-color: ${({ theme }) => theme.bg3};
  }
`

const AprSelector = styled.div<{ active: boolean }>`
  width: 50px;
  display: inline-block;
  padding: 4px 2px;
  border-radius: 5px;
  text-align: center;
  background-color: ${({ active, theme }) => active && theme.bg4};
`

const BorrowPositionList = styled.div`
  width: 100%;
`

const LoadingPosition = styled.div`
  width: 100%;
  background-color: #292938;
  border-radius: 8px;
  margin-bottom: 15px;
  padding: 25px 35px 10px;
  box-shadow: 0 5px 5px -3px rgb(0 0 0 / 20%), 0 8px 10px 1px rgb(0 0 0 / 14%), 0 3px 14px 2px rgb(0 0 0 / 12%);
  text-align: left;
  position: relative;
`

const LoadingRow = styled.div`
  display: flex;
  justify-content: space-between;
  margin-bottom: 20px;
`

const OpenNewBorrowPosition = styled.div`
  width: 100%;
`

function BorrowPositionsAndTotalsComparator() {
  return true
}

interface BorrowPositionsAndTotalsProps {
  setNewBorrowRate: React.Dispatch<SetStateAction<InterestRateChangedProps | undefined>>
  setNewSupplyRate: React.Dispatch<SetStateAction<InterestRateChangedProps | undefined>>
}

function BorrowPositionsAndTotals({ setNewBorrowRate, setNewSupplyRate }: BorrowPositionsAndTotalsProps) {
  const { t } = useTranslation()

  const { account } = useActiveWeb3React()
  const { data: positions } = useOpenBorrowPositions(account)
  const [isDialogOpen, setIsDialogOpen] = useState(false)
  const [txHash, setTxHash] = useState<string | undefined>(undefined)
  const [isAttemptingTx, setIsAttemptingTx] = useState(false)

  const [transactionPendingText, setTransactionPendingText] = useState('')
  const [selectedToken, setSelectedToken] = useState<Token | undefined>(undefined)
  const isTxPending = useIsTransactionPending(txHash)
  const [closingPositions, setClosingPositions] = useState<string[]>([])
  const [openingIdMap, setOpeningIdMap] = useState<Record<string, boolean>>({})
  const [showYieldAsApr, setShowYieldAsApr] = useShowYieldAsApr()
  const { data: marketRiskInfoMap } = useMarketRiskInfoData()
  const { data: dolomiteMargin } = useDolomiteMarginData()
  const activePositions = useActiveStrategies(positions)

  const onDismiss = useCallback(() => setIsDialogOpen(false), [])
  const createContent = useCallback(() => <div />, [])

  useEffect(() => {
    setOpeningIdMap(openingIdMap => {
      const positionIdMap = positions.reduce<Record<string, boolean>>((memo, position) => {
        memo[position.id] = true
        return memo
      }, {})
      // check if any of the positions are now open. If they're opened, they're not added to the new map that's created
      // below in the `reduce` function.
      return Object.keys(openingIdMap).reduce<Record<string, boolean>>((memo, positionId) => {
        if (!positionIdMap[positionId]) {
          memo[positionId] = true
        }
        return memo
      }, {})
    })
  }, [positions])

  const totalCollateralUSD = useMemo(() => {
    return positions.reduce((memo, position) => {
      return memo.add(position.totalSupplyUSD)
    }, ZERO_FRACTION)
  }, [positions])

  const totalBorrowUSD = useMemo(() => {
    return positions.reduce((memo, position) => {
      return memo.add(position.totalBorrowUSD)
    }, ZERO_FRACTION)
  }, [positions])

  const supplyInterestRatePercent = useMemo(() => {
    const totalInterestEarned = positions.reduce((memo, position) => {
      return memo.add(position.supplyInterestRate.multiply(position.totalSupplyUSD))
    }, ZERO_PERCENT)
    return totalCollateralUSD.greaterThan(ZERO_FRACTION) ? totalInterestEarned.divide(totalCollateralUSD) : ZERO_PERCENT
  }, [positions, totalCollateralUSD])

  const borrowInterestRatePercent = useMemo(() => {
    const totalInterestEarned = positions.reduce((memo, position) => {
      return memo.add(position.borrowInterestRate.multiply(position.totalSupplyUSD))
    }, ZERO_PERCENT)
    return totalCollateralUSD.greaterThan(ZERO_FRACTION) ? totalInterestEarned.divide(totalCollateralUSD) : ZERO_PERCENT
  }, [positions, totalCollateralUSD])

  const netInterestRatePercent = useMemo(() => {
    const totalInterestEarned = positions.reduce((memo, position) => {
      return memo.add(position.netInterestRate.multiply(position.totalSupplyUSD))
    }, ZERO_PERCENT)
    return totalCollateralUSD.greaterThan(ZERO_FRACTION) ? totalInterestEarned.divide(totalCollateralUSD) : ZERO_PERCENT
  }, [positions, totalCollateralUSD])

  const [expandedId, setExpandedId] = useState<string | undefined>(undefined)
  const isSwapModalOpen = useModalOpen(ApplicationModal.SWAP)
  useEffect(() => {
    if (isSwapModalOpen) setExpandedId(undefined)
  }, [isSwapModalOpen])
  const addOpeningPosition = useCallback((id: string) => {
    setOpeningIdMap(openingIdMap => {
      return {
        ...openingIdMap,
        [id]: true,
      }
    })
  }, [])

  const addClosing = useCallback((id: string) => {
    setClosingPositions(prev => [...prev, id])
  }, [])

  //TODO - extract borrow position into its own component with memoization
  return (
    <BorrowPositionsAndTotalsWrapper>
      <TopStats>
        <AmountStats>
          <CollateralAmountStat>
            <Title>
              {t('totalCollateral')}
              <StyledTooltipWithIcon tooltipText={t('totalBorrowCollateralTooltip')} placement={'top'} />
            </Title>
            <Amount>${totalCollateralUSD.toFixed(2, FORMATTER)}</Amount>
          </CollateralAmountStat>
          <BorrowAmountStat>
            <Title>
              {t('totalBorrowed')}
              <StyledTooltipWithIcon tooltipText={t('totalBorrowedTooltip')} placement={'top'} />
            </Title>
            <Amount>${totalBorrowUSD.toFixed(2, FORMATTER)}</Amount>
          </BorrowAmountStat>
        </AmountStats>
        <InterestStats>
          <EarnStat>
            <Title>
              {t('earning')}
              <StyledTooltipWithIcon tooltipText={t('averageBorrowInterestEarningTooltip')} placement={'top'} />
            </Title>
            <Amount>{formatAmount(supplyInterestRatePercent, 2, true, '0%')}</Amount>
          </EarnStat>

          <OweStat>
            <Title>
              {t('owe')}
              <StyledTooltipWithIcon tooltipText={t('averageBorrowInterestOwingTooltip')} placement={'top'} />
            </Title>
            <Amount>{formatAmount(borrowInterestRatePercent, 2, true, '0%')}</Amount>
          </OweStat>

          <NetStat>
            <Title>
              {t('net')}
              <StyledTooltipWithIcon tooltipText={t('netBorrowInterestTooltip')} placement={'top'} />
            </Title>
            <Amount>
              {netInterestRatePercent.equalTo(ZERO_FRACTION)
                ? '0.00%'
                : netInterestRatePercent.lessThan(ONE_BIPS) && netInterestRatePercent.greaterThan(ZERO_FRACTION)
                ? '+ <0.01%'
                : netInterestRatePercent.greaterThan(ONE_BIPS.multiply('-1')) &&
                  netInterestRatePercent.lessThan(ZERO_FRACTION)
                ? '~ -0.01%'
                : `${netInterestRatePercent.toFixed(2, FORMATTER, Rounding.ROUND_HALF_UP)}%`}
            </Amount>
          </NetStat>
        </InterestStats>
      </TopStats>
      <OpenBorrowPositions>
        <OpenBorrowsTitle>
          {t('openBorrowPositions')}
          {` `}
          {positions.length > 0 && <BorrowPositionsNumber>{positions.length}</BorrowPositionsNumber>}
          <AprSelect onClick={() => setShowYieldAsApr(!showYieldAsApr)}>
            <AprSelector active={showYieldAsApr}>APR</AprSelector>
            <AprSelector active={!showYieldAsApr}>APY</AprSelector>
          </AprSelect>
        </OpenBorrowsTitle>
        <BorrowPositionList>
          {[...Object.keys(openingIdMap)].map((v, i) => (
            <LoadingPosition key={i}>
              <LoadingRow>
                <SimpleLoader height={30} width={100} />
                <SimpleLoader height={30} width={70} />
              </LoadingRow>
              <LoadingRow>
                <SimpleLoader height={30} width={60} />
                <SimpleLoader height={30} width={140} />
              </LoadingRow>
            </LoadingPosition>
          ))}
          {positions.map(position => {
            const activeStrategy = activePositions.find(activePosition => activePosition.position.id === position.id)
            return (
              <BorrowPositionRow
                key={position.id}
                position={position}
                expandedId={expandedId}
                isAttemptingTx={isAttemptingTx}
                isTxPending={isTxPending}
                isClosing={closingPositions.includes(position.id)}
                addClosing={addClosing}
                setExpandedId={setExpandedId}
                setIsAttemptingTx={setIsAttemptingTx}
                setIsDialogOpen={setIsDialogOpen}
                setSelectedToken={setSelectedToken}
                setTransactionPendingText={setTransactionPendingText}
                setTxHash={setTxHash}
                setNewBorrowRate={setNewBorrowRate}
                setNewSupplyRate={setNewSupplyRate}
                marketRiskInfoMap={marketRiskInfoMap}
                dolomiteMargin={dolomiteMargin}
                activeStrategy={activeStrategy}
              />
            )
          })}
        </BorrowPositionList>
      </OpenBorrowPositions>
      <OpenNewBorrowPosition>
        <OpenNewBorrow
          isAttemptingTx={isAttemptingTx}
          isTxPending={isTxPending}
          txHash={txHash}
          setIsAttemptingTx={setIsAttemptingTx}
          setIsDialogOpen={setIsDialogOpen}
          setSelectedToken={setSelectedToken}
          setTransactionPendingText={setTransactionPendingText}
          setTxHash={setTxHash}
          addOpeningPosition={addOpeningPosition}
        />
      </OpenNewBorrowPosition>
      <TransactionConfirmationModal
        isOpen={isDialogOpen}
        memoizedOnDismiss={onDismiss}
        attemptingTxn={isAttemptingTx}
        hash={txHash}
        memoizedContentCreator={createContent}
        pendingText={transactionPendingText}
        currencyToAdd={selectedToken}
      />
    </BorrowPositionsAndTotalsWrapper>
  )
}

export default React.memo(BorrowPositionsAndTotals, BorrowPositionsAndTotalsComparator)
