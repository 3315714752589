import JSBI from 'jsbi'
import { Token } from '@dolomite-exchange/sdk-core'
import { BalanceCheckFlag, CurrencyAmount, WRAPPED_CURRENCY } from '@dolomite-exchange/v2-sdk'
import { Web3CallbackState } from './useTradeCallback'
import { estimateGasAsync, SuccessfulContractCall, useActiveWeb3React } from './index'
import {
  useBorrowPositionProxyV1Contract,
  useDepositWithdrawalRouterContract,
  useIsolationModeUserVaultContract,
} from './useContract'
import { useTransactionAdder } from '../state/transactions/hooks'
import { useMemo } from 'react'
import { calculateGasMargin } from '../utils'
import { useDolomiteMarginTokenAddressToIdMap } from './useDolomiteMarginProtocol'
import { USER_ERROR_CODES, USER_ERROR_MESSAGES } from '../constants'
import cleanCurrencySymbol from '../utils/cleanCurrencySymbol'
import { getSpecialAsset, useSpecialAsset } from '../constants/isolation/special-assets'
import { useActiveDolomiteZapClient } from '../apollo/client'
import { BigNumber } from '@dolomite-exchange/zap-sdk'
import { ethers } from 'ethers'
import { formatAmount } from '../utils/formatAmount'

export interface OpenBorrowPositionCallbackResult {
  transactionHash: string
  positionId: string
}

export function useOpenBorrowPosition(
  fromAccountIndex: JSBI | undefined,
  toAccountIndex: JSBI | undefined,
  amountWei: CurrencyAmount<Token> | undefined,
  balanceCheckFlag: BalanceCheckFlag,
): {
  state: Web3CallbackState
  callback: null | (() => Promise<OpenBorrowPositionCallbackResult>)
  error: string | null
} {
  const { account, chainId, library } = useActiveWeb3React()

  const borrowPositionProxyContract = useBorrowPositionProxyV1Contract()
  const specialAsset = useSpecialAsset(amountWei?.currency)
  const isolationVaultAddress = specialAsset?.isolationModeInfo?.remapAccountAddress?.(account, chainId)
  const isolationVaultContract = useIsolationModeUserVaultContract(isolationVaultAddress)
  const addTransaction = useTransactionAdder()
  const tokenAddressToMarketId = useDolomiteMarginTokenAddressToIdMap()
  const zapClient = useActiveDolomiteZapClient()

  return useMemo(() => {
    if (
      !borrowPositionProxyContract ||
      !library ||
      !account ||
      !chainId ||
      !fromAccountIndex ||
      !toAccountIndex ||
      !amountWei
    ) {
      return {
        state: Web3CallbackState.INVALID,
        callback: null,
        error: 'Missing dependencies',
      }
    }

    const marketId = tokenAddressToMarketId[amountWei.currency.address ?? '']
    if (!marketId) {
      return {
        state: Web3CallbackState.INVALID,
        callback: null,
        error: 'Could not map token to market ID for token: ' + amountWei.currency.address,
      }
    }

    let contract = borrowPositionProxyContract
    let methodName = 'openBorrowPosition'
    let args: any[] = [
      fromAccountIndex.toString(),
      toAccountIndex.toString(),
      marketId.toString(),
      amountWei.quotient.toString(),
      balanceCheckFlag.toString(),
    ]
    let positionId = `${account.toLowerCase()}-${toAccountIndex.toString(10)}`
    if (specialAsset?.isIsolationMode) {
      if (isolationVaultContract) {
        contract = isolationVaultContract
        methodName = 'openBorrowPosition'
        args = [fromAccountIndex.toString(), toAccountIndex.toString(), amountWei.quotient.toString()]
        positionId = `${isolationVaultContract.address.toLowerCase()}-${toAccountIndex.toString(10)}`
      } else {
        return {
          state: Web3CallbackState.INVALID,
          callback: null,
          error: 'Could not get isolation vault contract',
        }
      }
    }

    const executionFee = zapClient?.getIsAsyncAssetByMarketId(new BigNumber(marketId.toString()))
      ? ethers.utils.parseEther('0.001')
      : '0'

    return {
      state: Web3CallbackState.VALID,
      callback: async function onOpenBorrowPosition(): Promise<OpenBorrowPositionCallbackResult> {
        const options = { value: executionFee }
        const estimatedCall = await estimateGasAsync(contract, methodName, args, options)
        let successfulCall: SuccessfulContractCall
        if ('gasEstimate' in estimatedCall) {
          successfulCall = estimatedCall
        } else {
          throw estimatedCall.error
        }

        return contract[methodName](...args, {
          ...options,
          gasLimit: calculateGasMargin(successfulCall.gasEstimate),
          from: account,
        })
          .then((response: any) => {
            const summary = `Open borrow position with ${cleanCurrencySymbol(amountWei.currency)} collateral`
            addTransaction(response, { summary })
            return {
              transactionHash: response.hash,
              positionId,
            }
          })
          .catch((error: any) => {
            // if the user rejected the tx, pass this along
            if (error?.code === USER_ERROR_CODES.REJECTED) {
              throw new Error(USER_ERROR_MESSAGES.REJECTED)
            } else {
              // otherwise, the error was unexpected, and we need to convey that
              console.error(`Opening borrow position failed`, error, methodName, args)
              throw new Error(`Opening borrow position failed with message: ${error.message}`)
            }
          })
      },
      error: null,
    }
  }, [
    borrowPositionProxyContract,
    library,
    account,
    chainId,
    fromAccountIndex,
    toAccountIndex,
    amountWei,
    tokenAddressToMarketId,
    balanceCheckFlag,
    specialAsset?.isIsolationMode,
    isolationVaultContract,
    addTransaction,
    zapClient,
  ])
}

export function useCloseBorrowPosition(
  borrowAccountIndex: JSBI | undefined,
  toAccountIndex: JSBI | undefined,
  leftoverCollateralTokens: Token[] | undefined,
  isolationVaultAddress: string | undefined,
): { state: Web3CallbackState; callback: null | (() => Promise<string>); error: string | null } {
  const { account, chainId, library } = useActiveWeb3React()

  const borrowPositionProxyContract = useBorrowPositionProxyV1Contract()
  const isolationVaultContract = useIsolationModeUserVaultContract(isolationVaultAddress)
  const addTransaction = useTransactionAdder()
  const tokenAddressToMarketId = useDolomiteMarginTokenAddressToIdMap()

  return useMemo(() => {
    if (
      !borrowPositionProxyContract ||
      !library ||
      !account ||
      !chainId ||
      !borrowAccountIndex ||
      !toAccountIndex ||
      !leftoverCollateralTokens ||
      leftoverCollateralTokens.length === 0
    ) {
      return {
        state: Web3CallbackState.INVALID,
        callback: null,
        error: 'Missing dependencies',
      }
    }

    const marketIds = []
    for (const token of leftoverCollateralTokens) {
      const marketId = tokenAddressToMarketId[token.address]
      if (!marketId) {
        return {
          state: Web3CallbackState.INVALID,
          callback: null,
          error: `Could not map token to market ID for token: ${token.symbol}`,
        }
      }
      marketIds.push(marketId.toString())
    }

    let contract: ethers.Contract
    let methodName: string
    let args: any[]
    if (isolationVaultContract && JSBI.lessThan(borrowAccountIndex, JSBI.BigInt(10_000_000_000))) {
      contract = isolationVaultContract
      methodName = 'multicall'
      args = []

      const containsSpecialAsset = leftoverCollateralTokens.some(t => getSpecialAsset(t.chainId, t)?.isIsolationMode)
      if (containsSpecialAsset) {
        args.push(
          isolationVaultContract.interface.encodeFunctionData('closeBorrowPositionWithUnderlyingVaultToken', [
            borrowAccountIndex.toString(),
            toAccountIndex.toString(),
          ]),
        )
      }

      const regularTokens = leftoverCollateralTokens.filter(t => !getSpecialAsset(t.chainId, t)?.isIsolationMode)
      if (regularTokens.length > 0) {
        const regularMarketIds = regularTokens.map(t => tokenAddressToMarketId[t.address]!.toString())
        args.push(
          isolationVaultContract.interface.encodeFunctionData('closeBorrowPositionWithOtherTokens', [
            borrowAccountIndex.toString(),
            toAccountIndex.toString(),
            regularMarketIds,
          ]),
        )
      }

      args = [args]
    } else if (
      isolationVaultContract &&
      leftoverCollateralTokens.some(t => getSpecialAsset(t.chainId, t)?.isIsolationMode)
    ) {
      contract = isolationVaultContract
      methodName = 'closeBorrowPositionWithUnderlyingVaultToken'
      args = [borrowAccountIndex.toString(), toAccountIndex.toString()]
    } else if (isolationVaultContract) {
      contract = isolationVaultContract
      methodName = 'closeBorrowPositionWithOtherTokens'
      args = [
        borrowAccountIndex.toString(),
        toAccountIndex.toString(),
        leftoverCollateralTokens.map(t => tokenAddressToMarketId[t.address]!.toString()),
      ]
    } else {
      contract = borrowPositionProxyContract
      methodName = 'closeBorrowPosition'
      args = [borrowAccountIndex.toString(), toAccountIndex.toString(), marketIds]
    }

    return {
      state: Web3CallbackState.VALID,
      callback: async function onCloseBorrowPosition(): Promise<string> {
        const estimatedCall = await estimateGasAsync(contract, methodName, args, {})
        let successfulCall: SuccessfulContractCall
        if ('gasEstimate' in estimatedCall) {
          successfulCall = estimatedCall
        } else {
          throw estimatedCall.error
        }

        return contract[methodName](...args, {
          gasLimit: calculateGasMargin(successfulCall.gasEstimate),
          from: account,
        })
          .then((response: any) => {
            const tokensAsString = leftoverCollateralTokens.map(token => cleanCurrencySymbol(token)).join(', ')
            const summary = `Close borrow position and withdraw remaining ${tokensAsString} collateral`
            addTransaction(response, { summary })
            return response.hash
          })
          .catch((error: any) => {
            // if the user rejected the tx, pass this along
            if (error?.code === USER_ERROR_CODES.REJECTED) {
              throw new Error(USER_ERROR_MESSAGES.REJECTED)
            } else {
              // otherwise, the error was unexpected, and we need to convey that
              console.error(`Closing borrow position failed`, error, methodName, args)
              throw new Error(`Closing borrow position failed with message: ${error.message}`)
            }
          })
      },
      error: null,
    }
  }, [
    borrowPositionProxyContract,
    library,
    account,
    chainId,
    borrowAccountIndex,
    toAccountIndex,
    leftoverCollateralTokens,
    isolationVaultContract,
    tokenAddressToMarketId,
    addTransaction,
  ])
}

export function useTransferAmountForBorrowPosition(
  isolationModeMarketId: number | undefined,
  fromAccountNumber: JSBI | undefined,
  toAccountNumber: JSBI | undefined,
  amountWei: CurrencyAmount<Token> | undefined,
  balanceCheckFlag: BalanceCheckFlag,
  isTransferIntoPosition: boolean,
  isolationVaultAddress: string | undefined,
  isFromWallet?: boolean,
): { state: Web3CallbackState; callback: null | (() => Promise<string>); error: string | null } {
  const { account, chainId, library } = useActiveWeb3React()

  const borrowPositionProxyContract = useBorrowPositionProxyV1Contract()
  const isolationVaultContract = useIsolationModeUserVaultContract(isolationVaultAddress)
  const depositWithdrawalRouterContract = useDepositWithdrawalRouterContract()
  const addTransaction = useTransactionAdder()
  const tokenAddressToMarketId = useDolomiteMarginTokenAddressToIdMap()

  return useMemo(() => {
    if (
      !borrowPositionProxyContract ||
      !depositWithdrawalRouterContract ||
      !library ||
      !account ||
      !chainId ||
      !fromAccountNumber ||
      !toAccountNumber ||
      !amountWei
    ) {
      return {
        state: Web3CallbackState.INVALID,
        callback: null,
        error: 'Missing dependencies',
      }
    }

    const marketId = tokenAddressToMarketId[amountWei.currency.address ?? '']
    if (!marketId) {
      return {
        state: Web3CallbackState.INVALID,
        callback: null,
        error: 'Could not map token to market ID for token: ' + amountWei.currency.address,
      }
    }

    let contract = borrowPositionProxyContract
    let methodName = 'transferBetweenAccounts'
    let args: any[] = [
      fromAccountNumber.toString(),
      toAccountNumber.toString(),
      marketId.toString(),
      amountWei.quotient.toString(),
      balanceCheckFlag.toString(),
    ]
    let value = '0'
    if (isFromWallet) {
      contract = depositWithdrawalRouterContract
      const wrappedCurrency = WRAPPED_CURRENCY[chainId]
      const isPayable = amountWei.currency.equals(wrappedCurrency)
      if (isTransferIntoPosition && isPayable) {
        value = amountWei.quotient.toString()
        methodName = 'depositPayable'
        args = [
          isolationModeMarketId ?? '0',
          toAccountNumber.toString(),
          '0', // Event flag (None)
        ]
      } else if (isTransferIntoPosition) {
        methodName = 'depositWei'
        args = [
          isolationModeMarketId ?? '0',
          toAccountNumber.toString(),
          marketId.toString(),
          amountWei.quotient.toString(),
          '0', // Event flag (None)
        ]
      } else if (isPayable) {
        methodName = 'withdrawPayable'
        args = [
          isolationModeMarketId ?? '0',
          fromAccountNumber.toString(),
          amountWei.quotient.toString(),
          BalanceCheckFlag.None,
        ]
      } else {
        methodName = 'withdrawWei'
        args = [
          isolationModeMarketId ?? '0',
          fromAccountNumber.toString(),
          marketId.toString(),
          amountWei.quotient.toString(),
          BalanceCheckFlag.None,
        ]
      }
    } else if (isolationVaultContract) {
      contract = isolationVaultContract
      if (isTransferIntoPosition) {
        if (getSpecialAsset(chainId, amountWei.currency)?.isIsolationMode) {
          methodName = 'transferIntoPositionWithUnderlyingToken'
          args = [fromAccountNumber.toString(), toAccountNumber.toString(), amountWei.quotient.toString()]
        } else {
          methodName = 'transferIntoPositionWithOtherToken'
          args = [
            fromAccountNumber.toString(),
            toAccountNumber.toString(),
            marketId.toString(),
            amountWei.quotient.toString(),
            balanceCheckFlag.toString(),
          ]
        }
      } else {
        if (getSpecialAsset(chainId, amountWei.currency)?.isIsolationMode) {
          methodName = 'transferFromPositionWithUnderlyingToken'
          args = [fromAccountNumber.toString(), toAccountNumber.toString(), amountWei.quotient.toString()]
        } else {
          methodName = 'transferFromPositionWithOtherToken'
          args = [
            fromAccountNumber.toString(),
            toAccountNumber.toString(),
            marketId.toString(),
            amountWei.quotient.toString(),
            balanceCheckFlag.toString(),
          ]
        }
      }
    }

    return {
      state: Web3CallbackState.VALID,
      callback: async function onTransferPositionBetweenAccounts(): Promise<string> {
        const estimatedCall = await estimateGasAsync(contract, methodName, args, {})
        let successfulCall: SuccessfulContractCall
        if ('gasEstimate' in estimatedCall) {
          successfulCall = estimatedCall
        } else {
          throw estimatedCall.error
        }

        return contract[methodName](...args, {
          value,
          gasLimit: calculateGasMargin(successfulCall.gasEstimate),
          from: account,
        })
          .then((response: any) => {
            const summary = `Modify position for ${formatAmount(amountWei.asFraction)} ${cleanCurrencySymbol(
              amountWei.currency,
            )}`
            addTransaction(response, { summary })
            return response.hash
          })
          .catch((error: any) => {
            // if the user rejected the tx, pass this along
            if (error?.code === USER_ERROR_CODES.REJECTED) {
              throw new Error(USER_ERROR_MESSAGES.REJECTED)
            } else {
              // otherwise, the error was unexpected, and we need to convey that
              console.error(`Modifying borrow position failed`, error, methodName, args)
              throw new Error(`Modifying borrow position failed with message: ${error.message}`)
            }
          })
      },
      error: null,
    }
  }, [
    borrowPositionProxyContract,
    depositWithdrawalRouterContract,
    library,
    account,
    chainId,
    fromAccountNumber,
    toAccountNumber,
    amountWei,
    tokenAddressToMarketId,
    balanceCheckFlag,
    isFromWallet,
    isolationVaultContract,
    isTransferIntoPosition,
    isolationModeMarketId,
    addTransaction,
  ])
}

export function useRepayAllForBorrowPosition(
  isolationModeMarketId: number | undefined,
  fromAccountIndex: JSBI | undefined,
  borrowAccountIndex: JSBI | undefined,
  token: Token | undefined,
  balanceCheckFlag: BalanceCheckFlag,
  isolationVaultAddress: string | undefined,
  fromWallet?: boolean,
  amountPar?: CurrencyAmount<Token>,
): { state: Web3CallbackState; callback: null | (() => Promise<string>); error: string | null } {
  const { account, chainId, library } = useActiveWeb3React()

  const borrowPositionProxyContract = useBorrowPositionProxyV1Contract()
  const isolationVaultContract = useIsolationModeUserVaultContract(isolationVaultAddress)
  const fromWalletContract = useDepositWithdrawalRouterContract()
  const addTransaction = useTransactionAdder()
  const tokenAddressToMarketId = useDolomiteMarginTokenAddressToIdMap()

  return useMemo(() => {
    if (
      !borrowPositionProxyContract ||
      !fromWalletContract ||
      !library ||
      !account ||
      !chainId ||
      !fromAccountIndex ||
      !borrowAccountIndex ||
      !amountPar ||
      !token
    ) {
      return {
        state: Web3CallbackState.INVALID,
        callback: null,
        error: 'Missing dependencies',
      }
    }

    const marketId = tokenAddressToMarketId[token.address ?? '']
    if (!marketId) {
      return {
        state: Web3CallbackState.INVALID,
        callback: null,
        error: 'Could not map token to market ID for token: ' + token.address,
      }
    }

    let contract = borrowPositionProxyContract
    let methodName = 'repayAllForBorrowPosition'
    let args: any[] = [
      fromAccountIndex.toString(),
      borrowAccountIndex.toString(),
      marketId.toString(),
      balanceCheckFlag.toString(),
    ]
    if (fromWallet) {
      contract = fromWalletContract
      methodName = 'depositPar'
      args = [
        isolationModeMarketId ?? '0',
        borrowAccountIndex.toString(),
        marketId.toString(),
        amountPar.quotient.toString(),
        '0', // Event flag == None
      ]
    } else if (isolationVaultContract) {
      contract = isolationVaultContract
      methodName = 'repayAllForBorrowPosition'
      args = [
        fromAccountIndex.toString(),
        borrowAccountIndex.toString(),
        marketId.toString(),
        balanceCheckFlag.toString(),
      ]
    }

    return {
      state: Web3CallbackState.VALID,
      callback: async function onRepayAllForBorrowPosition(): Promise<string> {
        const estimatedCall = await estimateGasAsync(contract, methodName, args, {})
        let successfulCall: SuccessfulContractCall
        if ('gasEstimate' in estimatedCall) {
          successfulCall = estimatedCall
        } else {
          throw estimatedCall.error
        }

        return contract[methodName](...args, {
          gasLimit: calculateGasMargin(successfulCall.gasEstimate),
          from: account,
        })
          .then((response: any) => {
            const summary = `Repay all ${cleanCurrencySymbol(token)} for borrow position`
            addTransaction(response, { summary })
            return response.hash
          })
          .catch((error: any) => {
            // if the user rejected the tx, pass this along
            if (error?.code === USER_ERROR_CODES.REJECTED) {
              throw new Error(USER_ERROR_MESSAGES.REJECTED)
            } else {
              // otherwise, the error was unexpected, and we need to convey that
              console.error(`Repaying all collateral for borrow position failed`, error, methodName, args)
              throw new Error(`Repaying all collateral for borrow position failed with message: ${error.message}`)
            }
          })
      },
      error: null,
    }
  }, [
    isolationModeMarketId,
    amountPar,
    borrowPositionProxyContract,
    library,
    account,
    chainId,
    fromAccountIndex,
    borrowAccountIndex,
    token,
    tokenAddressToMarketId,
    fromWallet,
    fromWalletContract,
    balanceCheckFlag,
    isolationVaultContract,
    addTransaction,
  ])
}
