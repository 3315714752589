import { createFraction, InterestRate as InterestRateGql } from './gqlTypeHelpers'
import { Ether, Fraction, Percent } from '@dolomite-exchange/sdk-core'
import { useCallback, useMemo } from 'react'
import JSBI from 'jsbi'
import { toChecksumAddress } from '../utils/toChecksumAddress'
import { ChainId, ONE_ETH_IN_WEI, ZERO_PERCENT } from '../constants'
import { Token } from '@dolomite-exchange/v2-sdk'
import { useDailyBlockNumbersByTimestamps } from './useTimestampToBlockNumberData'
import { NO_VARIABLES, useGraphqlResult, useGraphqlResultList } from '../state/graphql/hooks'
import { GraphqlCall, GraphqlClientType } from '../state/graphql/actions'
import { deserializeInterestRatePartJson } from '../state/graphql/useAllOutsideInterestRates'
import { SpecialAsset, useAllSpecialAssets, useAllSpecialAssetsAllChains } from '../constants/isolation/special-assets'
import { address } from '@dolomite-exchange/dolomite-margin/dist/src/types'
import { useActiveWeb3React } from '../hooks'
import mergeMaps from '../utils/mergeMaps'
import { useShowYieldAsApr } from '../state/user/hooks'
import calculateAprToApy from '../utils/calculateAprToApy'
import { RefreshFrequency, useBlockTimestamp } from '../state/chain/hooks'
import cleanCurrencySymbol from '../utils/cleanCurrencySymbol'
import { useAllTokens } from '../hooks/Tokens'
import {
  InterestRatePart,
  InterestRatePartApi,
  InterestRatePartCategory,
  InterestRatePartSubcategory,
} from './InterestRatePart'
import { DOLOMITE_API_SERVER_URL } from '@dolomite-exchange/zap-sdk'
import { mapListedTokenToExternalToken } from '../utils/externalTokens'
import {
  CHAIN_ID_MAP,
  ACTIVE_CHAIN_IDS,
  ChainIdMap,
  initializeObjectChainIdMap,
  useChainIdsTransformer,
} from '../constants/chainId'
import { InterestRateRecord, InterestRateRecordResponse, InterestRateSeriesResponse } from '../hooks/useNetInterestRate'
import { useStoredInterestRateData } from '../state/data/hooks'
import { MarketRiskInfo } from './marketRiskInfoData'

interface InterestRateResponse {
  interestRates: InterestRateApi[]

  [key: string]: InterestRateApi[]
}

interface InterestRateApi {
  token: {
    tokenAddress: string
    marketId: string
  }
  interestSetter: string
  lowerOptimalRate: string
  upperOptimalRate: string
  optimalUtilizationRate: string
  supplyInterestRate: string
  borrowInterestRate: string
  totalSupplyInterestRate: string
  totalBorrowInterestRate: string
  outsideSupplyInterestRateParts: InterestRatePartApi[]
  outsideBorrowInterestRateParts: InterestRatePartApi[]
  historicalRates: {
    startTimestamp: string
    '7d': {
      supplyInterestRate: string
      borrowInterestRate: string
    }
    '14d': {
      supplyInterestRate: string
      borrowInterestRate: string
    }
    '30d': {
      supplyInterestRate: string
      borrowInterestRate: string
    }
  }
}

export interface InterestRate {
  marketId: JSBI
  blockTimestamp: number
  tokenAddress: string
  borrowInterestRate: Percent
  supplyInterestRate: Percent
  outsideSupplyInterestRateParts: InterestRatePart[] | undefined
  outsideBorrowInterestRateParts: InterestRatePart[] | undefined
  totalSupplyInterestRate: Percent
  totalBorrowInterestRate: Percent
  interestSetter: string
  optimalUtilizationRate: Fraction
  lowerOptimalRate: Fraction
  upperOptimalRate: Fraction
  historicalRates: {
    startTimestamp: Date
    '7d': {
      borrowInterestRate: Percent
      supplyInterestRate: Percent
    }
    '14d': {
      borrowInterestRate: Percent
      supplyInterestRate: Percent
    }
    '30d': {
      borrowInterestRate: Percent
      supplyInterestRate: Percent
    }
  }
}

export function useHistoricalInterestRateDataByToken(
  token?: Token,
  urlChain?: ChainId,
): {
  loading: boolean
  error: boolean
  data: InterestRate[] | undefined
} {
  const { data: blocks } = useDailyBlockNumbersByTimestamps()
  const query = useMemo(() => {
    return `
    query getHistoricalInterestRatesByToken(
      ${blocks.map(block => `$block_${block.blockNumber}: Int!,`).join('\n')}
      $tokenAddress: String!
    ) {
    ${blocks.map(block => {
      return `block_${block.blockNumber}:interestRate(block: { number: $block_${block.blockNumber}} id: $tokenAddress) {
      id
      borrowInterestRate
      supplyInterestRate
      token {
        id
        marketId
      }
      interestSetter
      optimalUtilizationRate
      lowerOptimalRate
      upperOptimalRate
    }`
    })}
  }
  `
  }, [blocks])

  const variables = useMemo(() => {
    if (!token || blocks.every(block => block.blockNumber === 0)) {
      return undefined
    }
    return {
      tokenAddress: token.address.toLowerCase(),
      ...blocks.reduce<Record<string, number>>((memo, block) => {
        memo[`block_${block.blockNumber}`] = block.blockNumber
        return memo
      }, {}),
    }
  }, [token, blocks])
  const queryState = useGraphqlResult<Record<string, InterestRateGql>>(
    GraphqlClientType.Dolomite,
    query,
    variables,
    RefreshFrequency.Slow,
    urlChain,
  )

  return useMemo(() => {
    const { loading, error, result } = queryState
    const anyLoading = Boolean(loading)
    const anyError = Boolean(error)

    const interestRates = blocks
      .map<InterestRate | undefined>(block => {
        const gql = result?.[`block_${block.blockNumber}`]
        if (!gql) {
          return undefined
        }
        const tokenAddress = toChecksumAddress(gql.token.id)
        const borrowRateFraction = createFraction(gql.borrowInterestRate)
        const supplyRateFunction = createFraction(gql.supplyInterestRate)
        return {
          marketId: JSBI.BigInt(gql.token.marketId),
          blockTimestamp: block.timestamp,
          tokenAddress: tokenAddress,
          interestSetter: toChecksumAddress(gql.interestSetter),
          borrowInterestRate: Percent.fromFraction(borrowRateFraction),
          supplyInterestRate: Percent.fromFraction(supplyRateFunction),
          outsideSupplyInterestRateParts: undefined,
          outsideBorrowInterestRateParts: undefined,
          totalSupplyInterestRate: Percent.fromFraction(supplyRateFunction),
          totalBorrowInterestRate: Percent.fromFraction(borrowRateFraction),
          optimalUtilizationRate: new Fraction(gql.optimalUtilizationRate, ONE_ETH_IN_WEI),
          lowerOptimalRate: new Fraction(gql.lowerOptimalRate, ONE_ETH_IN_WEI),
          upperOptimalRate: new Fraction(gql.upperOptimalRate, ONE_ETH_IN_WEI),
          historicalRates: {
            startTimestamp: new Date(block.timestamp * 1000),
            '7d': {
              borrowInterestRate: ZERO_PERCENT,
              supplyInterestRate: ZERO_PERCENT,
            },
            '14d': {
              borrowInterestRate: ZERO_PERCENT,
              supplyInterestRate: ZERO_PERCENT,
            },
            '30d': {
              borrowInterestRate: ZERO_PERCENT,
              supplyInterestRate: ZERO_PERCENT,
            },
          },
        }
      })
      .filter(value => value !== undefined) as InterestRate[]

    return {
      loading: anyLoading,
      error: anyError,
      data: interestRates,
    }
  }, [queryState, blocks])
}

function parseCategory(categoryString: string): InterestRatePartCategory {
  switch (categoryString) {
    case 'rewards':
      return InterestRatePartCategory.REWARDS
    case 'points':
      return InterestRatePartCategory.POINTS
    case 'nativeYield':
      return InterestRatePartCategory.NATIVE_YIELD
    case 'minerals':
      return InterestRatePartCategory.MINERALS
    default:
      return InterestRatePartCategory.NONE
  }
}

function parseSubcategory(subcategoryString: string | null): InterestRatePartSubcategory | undefined {
  switch (subcategoryString) {
    case 'claim':
      return InterestRatePartSubcategory.CLAIM
    default:
      return undefined
  }
}

export function useLoadInterestRateData(
  chainId?: ChainId,
): {
  data: Record<string, InterestRate | undefined>
  loading: boolean
  error: boolean
} {
  /*const chainIds = useMemo(() => Object.keys(CHAIN_ID_MAP).filter(c => c !== ChainId.MAINNET.toString()), [])
  const blockTimestamp = useBlockTimestamp().toNumber()
  const calls = useMemo(() => {
    return chainIds.map<GraphqlCall>(chainId => {
      return {
        chainId: parseInt(chainId) as ChainId,
        clientType: GraphqlClientType.Fetch,
        query: `${DOLOMITE_API_SERVER_URL}/tokens/${parseInt(chainId) as ChainId}/interest-rates`,
        variables: '{}',
      }
    })
  }, [chainIds])
  const interestRateInfoStates = useGraphqlResultList<InterestRateResponse>(calls, RefreshFrequency.Medium)*/

  const { chainId: selectedChainId } = useActiveWeb3React()
  const blockTimestamp = useBlockTimestamp().toNumber()
  const queryState = useGraphqlResult<InterestRateResponse>(
    GraphqlClientType.Fetch,
    `${DOLOMITE_API_SERVER_URL}/tokens/${chainId ?? selectedChainId}/interest-rates`,
    NO_VARIABLES,
    RefreshFrequency.Medium,
  )

  const outsideAprData = useAllOutsideInterestRates()
  const [showYieldAsApr] = useShowYieldAsApr()
  const tokenMap = useAllTokens()

  return useMemo(() => {
    const { loading, error, result } = queryState
    const anyLoading = Boolean(loading)
    const anyError = Boolean(error)

    const interestRates = (result?.interestRates ?? []).reduce<Record<string, InterestRate>>((memo, apiData) => {
      const tokenAddress = toChecksumAddress(apiData.token.tokenAddress)
      const borrowRateFraction = createFraction(apiData.borrowInterestRate)
      const supplyRateFunction = createFraction(apiData.supplyInterestRate)
      const borrowInterestRate = showYieldAsApr
        ? Percent.fromFraction(borrowRateFraction)
        : calculateAprToApy(Percent.fromFraction(borrowRateFraction))
      const supplyInterestRate = showYieldAsApr
        ? Percent.fromFraction(supplyRateFunction)
        : calculateAprToApy(Percent.fromFraction(supplyRateFunction))

      const initialSupplyInterestParts = apiData.outsideSupplyInterestRateParts.map<InterestRatePart>(p => ({
        interestRate: p.interestRate ? Percent.fromFraction(createFraction(p.interestRate)) : undefined,
        label: p.label,
        isBorrowRateImpacted: undefined,
        metadata: p.metadata,
        rewardToken: p.rewardToken ? tokenMap[p.rewardToken.id] : undefined,
        category: parseCategory(p.category),
        subcategory: parseSubcategory(p.subcategory),
        rewardClaimUrl: p.rewardClaimUrl ?? undefined,
      }))
      const outsideSupplyInterestRateParts = initialSupplyInterestParts.reduce((memo, part) => {
        return memo.concat({
          label: showYieldAsApr ? part.label : part.label.replace('APR', 'APY'),
          interestRate: showYieldAsApr || !part.interestRate ? part.interestRate : calculateAprToApy(part.interestRate),
          isBorrowRateImpacted: part.isBorrowRateImpacted,
          metadata: part.metadata,
          rewardToken: part.rewardToken,
          category: part.category,
          subcategory: part.subcategory,
          rewardClaimUrl: part.rewardClaimUrl,
        })
      }, outsideAprData[tokenAddress] ?? [])

      const initialBorrowInterestParts = apiData.outsideBorrowInterestRateParts.map<InterestRatePart>(p => ({
        interestRate: p.interestRate ? Percent.fromFraction(createFraction(p.interestRate)) : undefined,
        label: p.label,
        isBorrowRateImpacted: true,
        metadata: p.metadata,
        rewardToken: p.rewardToken ? tokenMap[p.rewardToken.id] : undefined,
        category: parseCategory(p.category),
        subcategory: parseSubcategory(p.subcategory),
        rewardClaimUrl: p.rewardClaimUrl ?? undefined,
      }))
      const outsideBorrowInterestRateParts = outsideSupplyInterestRateParts?.reduce((memo, part) => {
        if (!part.isBorrowRateImpacted) {
          return memo
        }

        return memo ? memo.concat(part) : [part]
      }, initialBorrowInterestParts)

      const totalSupplyRate =
        outsideSupplyInterestRateParts?.reduce((prev: Percent, interestRatePart) => {
          return prev.add(interestRatePart.interestRate ?? ZERO_PERCENT)
        }, supplyInterestRate) ?? supplyInterestRate

      const totalBorrowRate =
        outsideBorrowInterestRateParts?.reduce((prev: Percent, interestRatePart) => {
          return prev.add(interestRatePart.interestRate ?? ZERO_PERCENT)
        }, borrowInterestRate) ?? borrowInterestRate

      memo[tokenAddress] = {
        blockTimestamp,
        marketId: JSBI.BigInt(apiData.token.marketId),
        tokenAddress: tokenAddress,
        interestSetter: toChecksumAddress(apiData.interestSetter),
        borrowInterestRate: borrowInterestRate,
        supplyInterestRate: supplyInterestRate,
        outsideSupplyInterestRateParts: outsideSupplyInterestRateParts,
        outsideBorrowInterestRateParts: outsideBorrowInterestRateParts,
        totalSupplyInterestRate: totalSupplyRate,
        totalBorrowInterestRate: totalBorrowRate,
        optimalUtilizationRate: createFraction(apiData.optimalUtilizationRate),
        lowerOptimalRate: createFraction(apiData.lowerOptimalRate),
        upperOptimalRate: createFraction(apiData.upperOptimalRate),
        historicalRates: {
          startTimestamp: new Date(parseInt(apiData.historicalRates.startTimestamp) * 1000),
          '7d': {
            supplyInterestRate: Percent.fromFraction(createFraction(apiData.historicalRates['7d'].supplyInterestRate)),
            borrowInterestRate: Percent.fromFraction(createFraction(apiData.historicalRates['7d'].borrowInterestRate)),
          },
          '14d': {
            supplyInterestRate: Percent.fromFraction(createFraction(apiData.historicalRates['14d'].supplyInterestRate)),
            borrowInterestRate: Percent.fromFraction(createFraction(apiData.historicalRates['14d'].borrowInterestRate)),
          },
          '30d': {
            supplyInterestRate: Percent.fromFraction(createFraction(apiData.historicalRates['30d'].supplyInterestRate)),
            borrowInterestRate: Percent.fromFraction(createFraction(apiData.historicalRates['30d'].borrowInterestRate)),
          },
        },
      }

      const token = tokenMap[tokenAddress]
      const currencySymbol = token ? Ether.onChain(token.chainId).symbol : undefined
      if (currencySymbol && currencySymbol === cleanCurrencySymbol(token)) {
        memo[currencySymbol] = memo[tokenAddress]
      }
      if (token && !mapListedTokenToExternalToken(token).equals(token)) {
        memo[mapListedTokenToExternalToken(token).address] = memo[tokenAddress]
      }

      return memo
    }, {})

    return {
      loading: anyLoading,
      error: anyError,
      data: interestRates,
    }
  }, [queryState, showYieldAsApr, outsideAprData, blockTimestamp, tokenMap])
}

export function useInterestRateData(
  selectedChain?: ChainId,
): {
  loading: boolean
  error: boolean
  data: Record<string, InterestRate | undefined>
} {
  const { chainId } = useActiveWeb3React()
  return useStoredInterestRateData(selectedChain ?? chainId)
}

export function useChainIdToInterestRateMap(): {
  loading: boolean
  error: boolean
  data: ChainIdMap<Record<string, InterestRate | undefined>>
} {
  const blockTimestamp = useBlockTimestamp().toNumber()
  const callList = useChainIdsTransformer<GraphqlCall>(
    useCallback((chainId: ChainId) => {
      return {
        chainId,
        clientType: GraphqlClientType.Fetch,
        query: `${DOLOMITE_API_SERVER_URL}/tokens/${chainId}/interest-rates`,
        variables: 'null',
      }
    }, []),
  )
  const queryStates = useGraphqlResultList<InterestRateResponse>(callList, RefreshFrequency.Medium)
  const outsideAprData = useAllOutsideInterestRates()
  const [showYieldAsApr] = useShowYieldAsApr()
  const tokenMap = useAllTokens()

  return useMemo(() => {
    let anyError = false
    let anyLoading = false
    const chainIdToInterestRateMap = ACTIVE_CHAIN_IDS.reduce((outerMemo, chainId, i) => {
      const state = queryStates[i]
      if (!state) {
        return outerMemo
      }

      const { loading, error, result } = state
      anyError = anyError || error
      anyLoading = anyLoading || loading

      outerMemo[chainId] = (result?.interestRates ?? []).reduce<Record<string, InterestRate>>((memo, apiData) => {
        const tokenAddress = toChecksumAddress(apiData.token.tokenAddress)
        const borrowRateFraction = createFraction(apiData.borrowInterestRate)
        const supplyRateFunction = createFraction(apiData.supplyInterestRate)
        const borrowInterestRate = showYieldAsApr
          ? Percent.fromFraction(borrowRateFraction)
          : calculateAprToApy(Percent.fromFraction(borrowRateFraction))
        const supplyInterestRate = showYieldAsApr
          ? Percent.fromFraction(supplyRateFunction)
          : calculateAprToApy(Percent.fromFraction(supplyRateFunction))

        const initialSupplyInterestParts = apiData.outsideSupplyInterestRateParts.map<InterestRatePart>(p => ({
          interestRate: p.interestRate ? Percent.fromFraction(createFraction(p.interestRate)) : undefined,
          label: p.label,
          isBorrowRateImpacted: undefined,
          metadata: p.metadata,
          rewardToken: p.rewardToken ? tokenMap[p.rewardToken.id] : undefined,
          category: parseCategory(p.category),
          subcategory: parseSubcategory(p.subcategory),
          rewardClaimUrl: p.rewardClaimUrl ?? undefined,
        }))
        const outsideSupplyInterestRateParts = initialSupplyInterestParts.reduce((memo, part) => {
          return memo.concat({
            label: showYieldAsApr ? part.label : part.label.replace('APR', 'APY'),
            interestRate:
              showYieldAsApr || !part.interestRate ? part.interestRate : calculateAprToApy(part.interestRate),
            isBorrowRateImpacted: part.isBorrowRateImpacted,
            metadata: part.metadata,
            rewardToken: part.rewardToken,
            category: part.category,
            subcategory: part.subcategory,
            rewardClaimUrl: part.rewardClaimUrl,
          })
        }, outsideAprData[tokenAddress] ?? [])

        const initialBorrowInterestParts = apiData.outsideBorrowInterestRateParts.map<InterestRatePart>(p => ({
          interestRate: p.interestRate ? Percent.fromFraction(createFraction(p.interestRate)) : undefined,
          label: p.label,
          isBorrowRateImpacted: true,
          metadata: p.metadata,
          rewardToken: p.rewardToken ? tokenMap[p.rewardToken.id] : undefined,
          category: parseCategory(p.category),
          subcategory: parseSubcategory(p.subcategory),
          rewardClaimUrl: p.rewardClaimUrl ?? undefined,
        }))
        const outsideBorrowInterestRateParts = outsideSupplyInterestRateParts?.reduce((memo, part) => {
          if (!part.isBorrowRateImpacted) {
            return memo
          }

          return memo ? memo.concat(part) : [part]
        }, initialBorrowInterestParts)

        const totalSupplyRate =
          outsideSupplyInterestRateParts?.reduce((prev: Percent, interestRatePart) => {
            return prev.add(interestRatePart.interestRate ?? ZERO_PERCENT)
          }, supplyInterestRate) ?? supplyInterestRate

        const totalBorrowRate =
          outsideBorrowInterestRateParts?.reduce((prev: Percent, interestRatePart) => {
            return prev.add(interestRatePart.interestRate ?? ZERO_PERCENT)
          }, borrowInterestRate) ?? borrowInterestRate

        const historicalRates = apiData.historicalRates

        memo[tokenAddress] = {
          blockTimestamp,
          marketId: JSBI.BigInt(apiData.token.marketId),
          tokenAddress: tokenAddress,
          interestSetter: toChecksumAddress(apiData.interestSetter),
          borrowInterestRate: borrowInterestRate,
          supplyInterestRate: supplyInterestRate,
          outsideSupplyInterestRateParts: outsideSupplyInterestRateParts,
          outsideBorrowInterestRateParts: outsideBorrowInterestRateParts,
          totalSupplyInterestRate: totalSupplyRate,
          totalBorrowInterestRate: totalBorrowRate,
          optimalUtilizationRate: createFraction(apiData.optimalUtilizationRate),
          lowerOptimalRate: createFraction(apiData.lowerOptimalRate),
          upperOptimalRate: createFraction(apiData.upperOptimalRate),
          historicalRates: {
            startTimestamp: new Date(parseInt(historicalRates.startTimestamp) * 1000),
            '7d': {
              supplyInterestRate: Percent.fromFraction(createFraction(historicalRates['7d'].supplyInterestRate)),
              borrowInterestRate: Percent.fromFraction(createFraction(historicalRates['7d'].borrowInterestRate)),
            },
            '14d': {
              supplyInterestRate: Percent.fromFraction(createFraction(historicalRates['14d'].supplyInterestRate)),
              borrowInterestRate: Percent.fromFraction(createFraction(historicalRates['14d'].borrowInterestRate)),
            },
            '30d': {
              supplyInterestRate: Percent.fromFraction(createFraction(historicalRates['30d'].supplyInterestRate)),
              borrowInterestRate: Percent.fromFraction(createFraction(historicalRates['30d'].borrowInterestRate)),
            },
          },
        }

        const token = tokenMap[tokenAddress]
        const currencySymbol = token ? Ether.onChain(token.chainId).symbol : undefined
        if (currencySymbol && currencySymbol === cleanCurrencySymbol(token)) {
          memo[currencySymbol] = memo[tokenAddress]
        }
        if (token && !mapListedTokenToExternalToken(token).equals(token)) {
          memo[mapListedTokenToExternalToken(token).address] = memo[tokenAddress]
        }

        return memo
      }, {})
      return outerMemo
    }, initializeObjectChainIdMap() as ChainIdMap<Record<string, InterestRate | undefined>>)

    return {
      error: anyError,
      loading: anyLoading,
      data: chainIdToInterestRateMap,
    }
  }, [queryStates, showYieldAsApr, outsideAprData, blockTimestamp, tokenMap])
}

export interface InterestRateTimestampResponse {
  interestRate: InterestRateRecordResponse[]
}

export function useHistoricalInterestRateTimestamp(
  token: Token | undefined,
  timestamp: Date | undefined,
  chain: ChainId | undefined,
) {
  const queryState = useGraphqlResult<InterestRateTimestampResponse>(
    GraphqlClientType.Fetch,
    token
      ? `${DOLOMITE_API_SERVER_URL}/tokens/${chain}/interest-rates/${token.address.toLowerCase()}/timestamp/${
          timestamp ? timestamp.getTime() / 1000 : ''
        }`
      : undefined,
    NO_VARIABLES,
    RefreshFrequency.Medium,
  )

  const serverInterestRates = useMemo(() => {
    if (queryState.result && queryState.result.interestRate) {
      return queryState.result.interestRate.reduce((memo, t) => {
        const supplyRate = createFraction(t.supplyInterestRate)
        const borrowRate = t.borrowInterestRate ? createFraction(t.borrowInterestRate) : undefined
        const label = t.label
        const timestamp = new Date(t.timestamp * 1000)
        memo.push({
          supplyInterestRate: supplyRate,
          borrowInterestRate: borrowRate,
          label: label,
          timestamp: timestamp,
        })
        return memo
      }, [] as InterestRateRecord[])
    }
    return undefined
  }, [queryState.result])

  return useMemo(() => {
    if (serverInterestRates) {
      return serverInterestRates
    }
    return undefined
  }, [serverInterestRates])
}

interface SpecialGraphqlCalls {
  fetchSpecialAssets: SpecialAsset[]
  fetchCallDatas: GraphqlCall[]
  functionalSpecialAssets: SpecialAsset[]
  functionalCallDatas: GraphqlCall[]
  postSpecialAssets: SpecialAsset[]
  postCallDatas: GraphqlCall[]
}

function accumulateSpecialGraphqlCalls(
  accumulator: SpecialGraphqlCalls,
  asset: SpecialAsset,
  callData: GraphqlCall | undefined,
) {
  if (callData?.clientType === GraphqlClientType.Fetch) {
    accumulator.fetchSpecialAssets.push(asset)
    accumulator.fetchCallDatas.push(callData)
  } else if (callData?.clientType === GraphqlClientType.Functional) {
    accumulator.functionalSpecialAssets.push(asset)
    accumulator.functionalCallDatas.push(callData)
  } else if (callData?.clientType === GraphqlClientType.Post) {
    accumulator.postSpecialAssets.push(asset)
    accumulator.postCallDatas.push(callData)
  }
}

export function useAllOutsideInterestRates(): Record<address, InterestRatePart[] | undefined> {
  /*const chainIds = useMemo(() => Object.keys(CHAIN_ID_MAP).filter(c => c !== ChainId.MAINNET.toString()), [])
  const allSpecialAssetsAllChains = useAllSpecialAssetsAllChains()*/

  const { chainId } = useActiveWeb3React()
  const allSpecialAssets = useAllSpecialAssets()

  const {
    fetchSpecialAssets,
    fetchCallDatas,
    functionalSpecialAssets,
    functionalCallDatas,
    postSpecialAssets,
    postCallDatas,
  } = useMemo(() => {
    return allSpecialAssets.reduce<SpecialGraphqlCalls>(
      (acc, asset) => {
        accumulateSpecialGraphqlCalls(acc, asset, asset.rewardInfo?.getOutsideAprCallData(chainId))
        accumulateSpecialGraphqlCalls(acc, asset, asset.rewardInfo?.getExtraOutsideAprCallData?.(chainId))
        return acc
      },
      {
        fetchSpecialAssets: [],
        fetchCallDatas: [],
        functionalSpecialAssets: [],
        functionalCallDatas: [],
        postSpecialAssets: [],
        postCallDatas: [],
      },
    )
  }, [chainId, allSpecialAssets])

  const fetchStates = useGraphqlResultList<any>(fetchCallDatas, RefreshFrequency.Slowest)
  const functionalStates = useGraphqlResultList<any>(functionalCallDatas, RefreshFrequency.Fast)
  const postStates = useGraphqlResultList<any>(postCallDatas, RefreshFrequency.Slowest)

  /*console.log('fetched data')
  console.log(fetchStates)
  console.log(functionalStates)
  console.log(postStates)*/

  return useMemo(() => {
    const tokenAddressToFetchYields = fetchStates.reduce<Record<string, InterestRatePart[] | undefined>>(
      (memo, state, i) => {
        const asset = fetchSpecialAssets[i]
        if (state.result) {
          memo[asset.chainIdToAddressMap[chainId] ?? ''] = asset.rewardInfo?.parser?.(state.result)
        }
        return memo
      },
      {},
    )
    const tokenAddressToFunctionalYields = functionalStates.reduce<Record<string, InterestRatePart[] | undefined>>(
      (memo, state, i) => {
        const asset = functionalSpecialAssets[i]
        if (state.result) {
          memo[asset.chainIdToAddressMap[chainId] ?? ''] = deserializeInterestRatePartJson(state.result)
        }
        return memo
      },
      {},
    )
    const tokenAddressToPostYields = postStates.reduce<Record<string, InterestRatePart[] | undefined>>(
      (memo, state, i) => {
        const asset = postSpecialAssets[i]
        if (state.result) {
          memo[asset.chainIdToAddressMap[chainId] ?? ''] = asset.rewardInfo?.parser?.(state.result)
        }
        return memo
      },
      {},
    )

    return mergeMaps(
      interestRatePartsMergeFunction,
      tokenAddressToFetchYields,
      tokenAddressToFunctionalYields,
      tokenAddressToPostYields,
    )
  }, [
    chainId,
    fetchSpecialAssets,
    fetchStates,
    functionalSpecialAssets,
    functionalStates,
    postStates,
    postSpecialAssets,
  ])
}

export function interestRatePartsMergeFunction(
  a: InterestRatePart[] | undefined,
  b: InterestRatePart[] | undefined,
): InterestRatePart[] | undefined {
  if (a && b) {
    return a.concat(b)
  } else if (a) {
    return a
  } else if (b) {
    return b
  } else {
    return undefined
  }
}
