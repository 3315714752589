import { SpecialAsset, SpecialAssetSymbol } from '../special-assets'
import {
  ChainId,
  PENDLE_PT_USDE_DEC_2024_ADDRESSES,
  PENDLE_PT_USDE_DEC_2024_ISOLATION_MODE_ADDRESSES,
} from '../../index'
import { Token } from '@dolomite-exchange/v2-sdk'
import { getOptionally } from '../special-assets-util-functions'
import { calculatePendlePtUSDeDec2024ProxyVaultAddress } from '../../../utils/isolation/calculateProxyVaultAddress'
import { PENDLE_PT_DOCS_LINK } from './constants'

const decimals = 18

export const PT_USDE_DEC_2024_SPECIAL_ASSET: () => SpecialAsset = () => ({
  symbol: SpecialAssetSymbol.dPtUsdeDecember2024,
  safeSymbol: SpecialAssetSymbol.dPtUsdeDecember2024.replace('-', '_'),
  cleanSymbol: 'PT-USDe',
  cleanName: 'PT Ethena USDe 2024-12-25',
  hasExternalRewards: false,
  documentationUrl: PENDLE_PT_DOCS_LINK,
  isIsolationMode: true,
  isBorrowingEnabled: false,
  chainIdToAddressMap: PENDLE_PT_USDE_DEC_2024_ISOLATION_MODE_ADDRESSES,
  isolationModeInfo: {
    marketId: 10,
    getWrappedToken: (chainId: ChainId) =>
      getOptionally(
        PENDLE_PT_USDE_DEC_2024_ISOLATION_MODE_ADDRESSES[chainId],
        address =>
          new Token(
            chainId,
            address,
            decimals,
            SpecialAssetSymbol.dPtUsdeDecember2024,
            'Dolomite Isolation: PT USDe 26DEC2024',
          ),
      ),
    getUnderlyingToken: getPendlePtUsdeUnderlyingToken,
    getUnderlyingSpenderToken: getPendlePtUsdeUnderlyingToken,
    allowableDebtTokens: () => [],
    allowableCollateralTokens: () => [],
    remapAccountAddress: calculatePendlePtUSDeDec2024ProxyVaultAddress,
    isAsync: false,
  },
})

function getPendlePtUsdeUnderlyingToken(chainId: ChainId): Token | undefined {
  return getOptionally(
    PENDLE_PT_USDE_DEC_2024_ADDRESSES[chainId],
    address => new Token(chainId, address, decimals, 'PT-USDe', 'PT USDe 26DEC2024'),
  )
}
