import { DocumentNode } from '@apollo/client'
import gql from 'graphql-tag'
import {
  createBigFraction,
  createBigFractionOpt,
  createDate,
  createDateOpt,
  createFractionUSD,
  createFractionUSDOpt,
  createTransaction,
  MarginPosition as MarginPositionGql,
  truncateFractionToCurrencyAmount,
} from './gqlTypeHelpers'
import { marginPositionGql } from './queryObjects'
import { useMemo } from 'react'
import { CurrencyAmount, Fraction, Percent, Token } from '@dolomite-exchange/sdk-core'
import { createMarginAccount, MarginAccount } from './marginAccount'
import { Transaction } from './transaction'
import { useAllTokens } from '../hooks/Tokens'
import { toChecksumAddressOpt } from '../utils/toChecksumAddress'
import { BIG_INT_ZERO, ZERO_PERCENT } from '../constants'
import { deriveMarketFromTokensReq } from '../utils/marketUtils'
import calculateLiquidationPrice from '../utils/calculateLiquidationPrice'
import { MarketRiskInfo, useMarketRiskInfoData } from './marketRiskInfoData'
import { useMarketIndices } from '../hooks/useDolomiteMarginProtocol'
import derivePositionTypeFromTokens from '../utils/derivePositionTypeFromTokens'
import calculateLeverage from '../utils/calculateLeverage'
import calculateProfitPercentage from '../utils/calculateProfitPercentage'
import parToWei from '../utils/parToWei'
import { Market } from './Market'
import { useUSDCPrices } from '../utils/useUSDCPrice'
import { AssetDenomination, Currency, Price } from '@dolomite-exchange/v2-sdk'
import { reqParseAmount } from '../state/trade/hooks'
import { DolomiteMarginData, useDolomiteMarginData } from './dolomiteMarginData'
import { InterestIndex } from 'data/InterestIndex'
import { useTradesExactOut } from '../hooks/Trades'
import { InterestRate, useInterestRateData } from './interestRateData'
import { useGraphqlResult } from '../state/graphql/hooks'
import { GraphqlClientType } from '../state/graphql/actions'
import { RefreshFrequency } from '../state/chain/hooks'
import { getBorrowPositionEMode } from '../utils/emode'

const MARGIN_POSITIONS_BY_OPEN_TIMESTAMP_GQL = gql`
    query marginPositionsByOpenTimestamp($blockNumber: Int!, $openTimestamp: String!) {
        marginPositions(
            block: { number_gte: $blockNumber },
            where: { openTimestamp_gte: $openTimestamp },
            orderBy: openTimestamp,
            orderDirection: desc,
            first: 100
        ) {
            ${marginPositionGql()}
        }
    }
`

const MARGIN_POSITIONS_BY_STATUS_GQL = gql`
    query marginPositionsByStatus($blockNumber: Int!, $status: String!) {
        marginPositions(
            block: { number_gte: $blockNumber },
            where: { status: $status },
            orderBy: openTimestamp,
            orderDirection: desc,
            first: 100
        ) {
            ${marginPositionGql()}
        }
    }
`

const MARGIN_POSITIONS_BY_WALLET_ADDRESS_GQL = gql`
    query marginPositionsByWallet($blockNumber: Int!, $walletAddress: String!) {
        marginPositions(
            block: { number_gte: $blockNumber },
            where: { marginAccount_starts_with: $walletAddress },
            orderBy: openTimestamp,
            orderDirection: desc,
            first: 100
        ) {
            ${marginPositionGql()}
        }
    }
`

const MARGIN_POSITIONS_BY_WALLET_ADDRESS_AND_STATUS_GQL = gql`
    query marginPositionsByWalletAndStatus($blockNumber: Int!, $walletAddress: String!, $status: String!) {
        marginPositions(
            block: { number_gte: $blockNumber },
            where: { marginAccount_starts_with: $walletAddress, status: $status },
            orderBy: openTimestamp,
            orderDirection: desc,
            first: 100
        ) {
            ${marginPositionGql()}
        }
    }
`

const MARGIN_POSITIONS_BY_WALLET_ADDRESS_AND_NOT_STATUS_GQL = gql`
    query marginPositionsByWalletAndNotStatus($blockNumber: Int!, $walletAddress: String!, $status: String!) {
        marginPositions(
            block: { number_gte: $blockNumber },
            where: { marginAccount_starts_with: $walletAddress, status_not: $status },
            orderBy: openTimestamp,
            orderDirection: desc,
            first: 100
        ) {
            ${marginPositionGql()}
        }
    }
`

const MARGIN_POSITIONS_BY_ID_GQL = gql`
    query marginPositionsById($blockNumber: Int!, $id: String!) {
        marginPositions(
            block: { number_gte: $blockNumber },
            where: {
                id: $id,
            },
        ) {
            ${marginPositionGql()}
        }
    }
`

interface MarginPositionResponse {
  marginPositions: MarginPositionGql[]
}

export enum MarginPositionStatus {
  Open = 'OPEN',
  Closed = 'CLOSED',
  Expired = 'EXPIRED',
  Liquidated = 'LIQUIDATED',
  Unknown = 'UNKNOWN',
}

export class MarginPositionStatusUtil {
  public static toUiString(status: MarginPositionStatus): string {
    const splitStatus = status.split('_')
    let total = ''
    for (const part of splitStatus) {
      total = total.concat(part.substring(0, 1).concat(part.substring(1).toLowerCase()))
    }
    return total
  }
}

const statusStringToStatus: Record<string, MarginPositionStatus> = {
  [MarginPositionStatus.Open]: MarginPositionStatus.Open,
  [MarginPositionStatus.Closed]: MarginPositionStatus.Closed,
  [MarginPositionStatus.Expired]: MarginPositionStatus.Expired,
  [MarginPositionStatus.Liquidated]: MarginPositionStatus.Liquidated,
}

export interface MarginPosition {
  id: string
  marginAccount: MarginAccount
  openTimestamp: Date
  heldToken: Token
  marginDeposit: CurrencyAmount<Token>
  marginDepositUSD: Fraction
  initialHeldAmountPar: Fraction
  initialHeldAmountWei: CurrencyAmount<Token>
  initialHeldAmountUSD: Fraction
  initialHeldPriceUSD: Fraction
  closeHeldPriceUSD: Fraction | undefined
  closeHeldAmountWei: CurrencyAmount<Token> | undefined
  closeHeldAmountUSD: Fraction | undefined
  heldAmount: CurrencyAmount<Token>
  owedToken: Token | undefined
  initialOwedAmountPar: Fraction
  initialOwedAmountWei: Fraction
  initialOwedAmountUSD: Fraction
  initialOwedPriceUSD: Fraction
  closeOwedPriceUSD: Fraction | undefined
  closeOwedAmountWei: CurrencyAmount<Token> | undefined
  closeOwedAmountUSD: Fraction | undefined
  owedAmount: CurrencyAmount<Token>
  owedAmountPar: CurrencyAmount<Token>
  status: MarginPositionStatus
  isLiquidatedAndHasCollateral: boolean
  closeTimestamp: Date | undefined
  expirationTimestamp: Date | undefined
  openTransaction: Transaction | undefined
  closeTransaction: Transaction | undefined
  // derived fields
  market: Market
  profit: Fraction
  profitPercentage: Percent
  leverage: Fraction
  equityHeldAmount: CurrencyAmount<Token>
  openPrice: Fraction
  openPriceUSD: Fraction
  closePrice: Fraction | undefined
  closePriceUSD: Fraction | undefined
  liquidationPrice: Fraction
  positionType: MarginPositionType
  interestEarned: CurrencyAmount<Token>
  interestOwed: CurrencyAmount<Token>
  netInterest: Percent
  currentInterest: Percent
}

export enum MarginPositionType {
  LONG = 'LONG',
  SHORT = 'SHORT',
  CROSS = 'CROSS',
  UNKNOWN = 'UNKNOWN',
}

/**
 * @param openTimestamp  The timestamp to start retrieving positions from
 */
export function useMarginPositionsByOpenTimestampData(
  openTimestamp?: number,
): {
  loading: boolean
  error: boolean
  data: MarginPosition[]
} {
  const variables = useMemo(() => {
    return {
      openTimestamp,
    }
  }, [openTimestamp])

  return useMarginPositions(MARGIN_POSITIONS_BY_OPEN_TIMESTAMP_GQL, variables)
}

/**
 * Gets the 100 most-recent open or closed margin positions
 */
export function useMarginPositionsByStatusData(
  isOpen: boolean,
): {
  loading: boolean
  error: boolean
  data: MarginPosition[]
} {
  const variables = useMemo(() => ({ isOpen }), [isOpen])
  return useMarginPositions(MARGIN_POSITIONS_BY_STATUS_GQL, variables)
}

export function useMarginPositionsByWalletAddressData(
  walletAddress: string | undefined,
  isOpen?: boolean,
): {
  loading: boolean
  error: boolean
  data: MarginPosition[]
} {
  const gql =
    typeof isOpen === 'undefined'
      ? MARGIN_POSITIONS_BY_WALLET_ADDRESS_GQL
      : isOpen
      ? MARGIN_POSITIONS_BY_WALLET_ADDRESS_AND_STATUS_GQL
      : MARGIN_POSITIONS_BY_WALLET_ADDRESS_AND_NOT_STATUS_GQL

  const variables = useMemo(() => {
    if (!walletAddress) {
      return undefined
    }

    return {
      walletAddress: walletAddress.toLowerCase(),
      status: typeof isOpen === 'undefined' ? undefined : 'OPEN',
    }
  }, [isOpen, walletAddress])

  return useMarginPositions(gql, variables)
}

export function useMarginPositionByIdData(
  positionId?: string,
): {
  loading: boolean
  error: boolean
  data: MarginPosition[]
} {
  const variables = useMemo(() => {
    if (!positionId) {
      return undefined
    }
    return {
      positionId: positionId.toLowerCase(),
    }
  }, [positionId])

  return useMarginPositions(MARGIN_POSITIONS_BY_ID_GQL, variables)
}

function useMarginPositions(
  gql: DocumentNode,
  variables: Record<string, any> | undefined,
): {
  loading: boolean
  error: boolean
  data: MarginPosition[]
} {
  const queryState = useGraphqlResult<MarginPositionResponse>(
    GraphqlClientType.Dolomite,
    gql.loc!.source.body,
    variables,
    RefreshFrequency.Fast,
  )

  const tokenMap = useAllTokens()
  const indicesResult = useMarketIndices()
  const marketInfosResult = useMarketRiskInfoData()
  const { data: riskParams, loading: isLoadingRiskParams } = useDolomiteMarginData()
  const tokenList = useMemo(() => {
    return Object.values(tokenMap)
      .filter(token => !!token)
      .map<Token>(token => token!)
  }, [tokenMap])

  const fiatValueMap = useUSDCPrices(tokenList)
  const { data: interestRateMap } = useInterestRateData()

  const positionsResult = useMemo(() => {
    const { loading, error, result } = queryState
    const [indexMap, isLoadingIndices] = indicesResult
    const { data: marketInfoMap, loading: isLoadingMarketInfos, error: isMarketInfosError } = marketInfosResult

    const anyLoading = Boolean(loading || isLoadingIndices || isLoadingMarketInfos || isLoadingRiskParams)
    const anyError = Boolean(error || isMarketInfosError)

    const marginPositions =
      result?.marginPositions
        .map<MarginPosition | undefined>(position =>
          mapPositionGqlToPosition(
            position,
            riskParams,
            tokenMap,
            indexMap,
            marketInfoMap,
            interestRateMap,
            fiatValueMap,
          ),
        )
        .filter(position => !!position)
        .map(position => position!) ?? []

    return {
      loading: anyLoading,
      error: anyError,
      data: marginPositions,
    }
  }, [
    queryState,
    indicesResult,
    marketInfosResult,
    isLoadingRiskParams,
    riskParams,
    tokenMap,
    interestRateMap,
    fiatValueMap,
  ])

  const [currenciesIn, currencyAmountsOut] = useMemo(() => {
    const { data: positions } = positionsResult
    return [
      positions.map(position =>
        position.status === MarginPositionStatus.Open ? position.heldAmount.currency : undefined,
      ),
      positions.map(position => (position.status === MarginPositionStatus.Open ? position.owedAmountPar : undefined)),
    ]
  }, [positionsResult])

  const trades = useTradesExactOut(currenciesIn, currencyAmountsOut, AssetDenomination.Par)

  return useMemo(() => {
    const positions = positionsResult.data.map<MarginPosition>((position, i) => {
      const trade = trades[i]
      if (trade) {
        const wrappedInputAmount = trade.inputAmount.wrapped
        const profitPercentage = calculateProfitPercentage(
          position.marginDeposit,
          position.heldAmount.subtract(wrappedInputAmount),
        )
        return {
          ...position,
          equityHeldAmount: position.heldAmount.subtract(wrappedInputAmount),
          profitPercentage,
        }
      } else {
        return position
      }
    })
    return {
      loading: positionsResult.loading,
      error: positionsResult.error,
      data: positions,
    }
  }, [positionsResult, trades])
}

function createCurrencyAmount(token: Token, field: string): CurrencyAmount<Token> {
  return truncateFractionToCurrencyAmount(createBigFraction(field), token)
}

function createCurrencyAmountOpt(token?: Token, field?: string): CurrencyAmount<Token> | undefined {
  return token && field ? truncateFractionToCurrencyAmount(createBigFraction(field), token) : undefined
}

function mapPositionGqlToPosition(
  position: MarginPositionGql,
  riskParams: DolomiteMarginData | undefined,
  tokenMap: Record<string, Token | undefined>,
  indexMap: Record<string, InterestIndex | undefined>,
  marketInfoRiskMap: Record<string, MarketRiskInfo | undefined>,
  interestRateMap: Record<string, InterestRate | undefined>,
  fiatValueMap: Record<string, Price<Currency, Token> | undefined>,
): MarginPosition | undefined {
  const heldToken = tokenMap[toChecksumAddressOpt(position.heldToken?.id) ?? '']
  const heldTokenIndex = indexMap[heldToken?.address ?? '']
  const heldTokenFiatValue = fiatValueMap[heldToken?.address ?? '']
  const heldTokenMarketInfo = marketInfoRiskMap[heldToken?.address ?? '']

  const owedToken = tokenMap[toChecksumAddressOpt(position.owedToken?.id) ?? '']
  const owedTokenIndex = indexMap[owedToken?.address ?? '']
  const owedTokenFiatValue = fiatValueMap[owedToken?.address ?? '']
  const owedTokenMarketInfo = marketInfoRiskMap[owedToken?.address ?? '']

  if (!heldToken || !owedToken) {
    console.error('Could not find token for either of ', position.heldToken?.id, position.owedToken?.id)
    return undefined
  } else if (
    !heldTokenIndex ||
    !owedTokenIndex ||
    !heldTokenFiatValue ||
    !owedTokenFiatValue ||
    !heldTokenMarketInfo ||
    !owedTokenMarketInfo
  ) {
    return undefined
  } else {
    const status = statusStringToStatus[position.status]
    const market = deriveMarketFromTokensReq(heldToken, owedToken)

    const heldAmountPar = createCurrencyAmount(heldToken, position.heldAmountPar)
    const owedAmountPar = createCurrencyAmount(owedToken, position.owedAmountPar)

    const heldAmount = parToWei(heldAmountPar, heldTokenIndex, true)
    let closeHeldAmount = createCurrencyAmountOpt(heldToken, position.closeHeldAmountWei)
    const closeHeldAmountSeized = createCurrencyAmountOpt(heldToken, position.closeHeldAmountSeized)
    if (closeHeldAmount && closeHeldAmountSeized) {
      closeHeldAmount = closeHeldAmount.subtract(closeHeldAmountSeized)
    }
    const closeHeldPrice = createBigFractionOpt(position.closeHeldPrice)
    const closeHeldPriceUSD = createFractionUSDOpt(position.closeHeldPriceUSD)

    const owedAmount = parToWei(owedAmountPar, owedTokenIndex, false)
    const closeOwedAmount = createCurrencyAmountOpt(owedToken, position.closeOwedAmountWei)
    const closeOwedPrice = createBigFractionOpt(position.closeOwedPrice)
    const closeOwedPriceUSD = createFractionUSDOpt(position.closeOwedPriceUSD)

    let marginDeposit = createCurrencyAmount(heldToken, position.marginDeposit)
    let marginDepositUSD = createFractionUSD(position.marginDepositUSD)
    if (marginDeposit.equalTo('0')) {
      marginDeposit = createCurrencyAmount(heldToken, position.initialMarginDeposit)
      marginDepositUSD = createFractionUSD(position.initialMarginDepositUSD)
    }

    const isOpen = status === MarginPositionStatus.Open

    let heldPriceUSD: Fraction | undefined = heldTokenFiatValue
    heldPriceUSD = heldPriceUSD.asFraction.multiply((heldPriceUSD as Price<Token, Token>).scalar)

    let owedPriceUSD: Fraction | undefined = owedTokenFiatValue
    owedPriceUSD = owedPriceUSD.asFraction.multiply((owedPriceUSD as Price<Token, Token>).scalar)

    const owedPrice = owedPriceUSD.divide(heldPriceUSD!)

    const openTransaction = position.openTransaction ? createTransaction(position.openTransaction) : undefined
    const closeTransaction = position.closeTransaction ? createTransaction(position.closeTransaction) : undefined

    const emodeData = getBorrowPositionEMode([heldToken], [owedToken], marketInfoRiskMap)

    let liquidationPrice = calculateLiquidationPrice(
      heldAmount.asFraction,
      owedAmount.asFraction,
      riskParams?.minCollateralization,
      heldTokenMarketInfo,
      owedTokenMarketInfo,
      emodeData,
    )
    liquidationPrice =
      market.primaryToken.equals(owedToken) && !liquidationPrice.equalTo(BIG_INT_ZERO)
        ? liquidationPrice.invert()
        : liquidationPrice

    const initialHeldAmountWei = createCurrencyAmount(heldToken, position.initialHeldAmountWei)
    const initialHeldAmountUSD = createFractionUSD(position.initialHeldAmountUSD)
    const initialHeldPrice = createBigFraction(position.initialHeldPrice)
    const initialHeldPriceUSD = createFractionUSD(position.initialHeldPriceUSD)

    const initialOwedAmountWei = createCurrencyAmount(owedToken, position.initialOwedAmountWei)
    const initialOwedAmountUSD = createFractionUSD(position.initialOwedAmountUSD)
    const initialOwedPrice = createBigFraction(position.initialOwedPrice)
    const initialOwedPriceUSD = createFractionUSD(position.initialOwedPriceUSD)

    const actualHeldAmount = isOpen ? heldAmount : closeHeldAmount!
    const actualHeldPriceUSD = isOpen ? heldPriceUSD : closeHeldPriceUSD!
    const actualOwedAmount = isOpen ? owedAmount : closeOwedAmount!
    const actualOwedPrice = isOpen ? owedPrice : closeOwedPrice!
    const actualOwedPriceUSD = isOpen ? owedPriceUSD : closeOwedPriceUSD!

    const openPriceUSD = market.primaryToken.address === owedToken.address ? initialOwedPriceUSD : initialHeldPriceUSD
    const openPrice = market.primaryToken.address === owedToken.address ? initialOwedPrice : initialHeldPrice

    const closePriceUSD = market.primaryToken.address === owedToken.address ? closeOwedPriceUSD : closeHeldPriceUSD
    const closePrice = market.primaryToken.address === owedToken.address ? closeOwedPrice : closeHeldPrice

    const positionType = derivePositionTypeFromTokens(heldToken, owedToken)
    const equityHeldFraction = actualHeldAmount.asFraction.subtract(
      actualOwedAmount.asFraction.multiply(actualOwedPrice),
    )

    const equityHeldAmount = actualOwedPrice
      ? reqParseAmount(equityHeldFraction.toFixed(heldToken.decimals), heldToken)
      : CurrencyAmount.fromRawAmount(heldToken, BIG_INT_ZERO)
    const profit = equityHeldAmount.asFraction.greaterThan('0')
      ? equityHeldAmount.asFraction.subtract(marginDeposit.asFraction)
      : equityHeldAmount.asFraction
    const profitPercentage = calculateProfitPercentage(marginDeposit, equityHeldAmount)

    const openTimestamp = createDate(position.openTimestamp)
    const timeSinceOpen = new Date().getTime() - openTimestamp.getTime()
    const MILLIS_PER_YEAR = '31536000000'
    const interestEarned = isOpen
      ? actualHeldAmount.subtract(initialHeldAmountWei)
      : actualHeldAmount
          .subtract(initialHeldAmountWei)
          .add(closeHeldAmountSeized ?? CurrencyAmount.fromRawAmount(heldToken, '0'))
    const interestOwed = actualOwedAmount.subtract(initialOwedAmountWei)

    const heldInterestRate = interestRateMap[heldToken.address]
    const currentInterestEarnedPercent =
      heldInterestRate && isOpen
        ? actualHeldAmount.asFraction
            .multiply(actualHeldPriceUSD)
            .multiply(heldInterestRate.supplyInterestRate)
            .divide(marginDepositUSD)
        : undefined
    const netInterestEarnedPercent = interestEarned.asFraction
      .multiply(actualHeldPriceUSD)
      .multiply(MILLIS_PER_YEAR)
      .divide(timeSinceOpen)
      .divide(marginDepositUSD)

    const owedInterestRate = interestRateMap[owedToken.address]
    const currentInterestOwedPercent =
      owedInterestRate && isOpen
        ? actualOwedAmount.asFraction
            .multiply(actualOwedPriceUSD)
            .multiply(owedInterestRate.borrowInterestRate)
            .divide(marginDepositUSD)
        : undefined
    const netInterestOwedPercent = interestOwed.asFraction
      .multiply(actualOwedPriceUSD)
      .multiply(MILLIS_PER_YEAR)
      .divide(timeSinceOpen)
      .divide(marginDepositUSD)

    const currentInterestFraction =
      currentInterestEarnedPercent && currentInterestOwedPercent
        ? currentInterestEarnedPercent.subtract(currentInterestOwedPercent)
        : undefined
    const netInterestFraction = netInterestEarnedPercent.subtract(netInterestOwedPercent)
    const currentInterestPercent = currentInterestFraction
      ? Percent.fromFraction(currentInterestFraction)
      : ZERO_PERCENT
    const netInterestPercent = Percent.fromFraction(netInterestFraction)

    return {
      id: position.id,
      marginAccount: createMarginAccount(position.marginAccount),
      openTimestamp: openTimestamp,
      heldToken: heldToken,
      marginDeposit: marginDeposit,
      marginDepositUSD: marginDepositUSD,
      initialHeldAmountPar: createCurrencyAmount(heldToken, position.initialHeldAmountPar),
      initialHeldAmountWei: createCurrencyAmount(heldToken, position.initialHeldAmountWei),
      initialHeldAmountUSD: initialHeldAmountUSD,
      initialHeldPriceUSD: initialHeldPriceUSD,
      closeHeldPriceUSD: closeHeldPriceUSD,
      closeHeldAmountWei: closeHeldAmount,
      closeHeldAmountUSD: createFractionUSDOpt(position.closeHeldAmountUSD?.slice(0, 20)),
      heldAmount: actualHeldAmount,
      owedToken: owedToken,
      initialOwedAmountPar: createCurrencyAmount(owedToken, position.initialOwedAmountPar),
      initialOwedAmountWei: createCurrencyAmount(owedToken, position.initialOwedAmountWei),
      initialOwedAmountUSD: initialOwedAmountUSD,
      initialOwedPriceUSD: initialOwedPriceUSD,
      closeOwedPriceUSD: closeOwedPriceUSD,
      closeOwedAmountWei: closeOwedAmount,
      closeOwedAmountUSD: createFractionUSDOpt(position.closeOwedAmountUSD?.slice(0, 20)),
      owedAmount: actualOwedAmount,
      owedAmountPar: owedAmountPar,
      status: status,
      isLiquidatedAndHasCollateral:
        (status === MarginPositionStatus.Liquidated || status === MarginPositionStatus.Expired) &&
        heldAmountPar.greaterThan('0'),
      closeTimestamp: createDateOpt(position.closeTimestamp),
      expirationTimestamp: createDateOpt(position.expirationTimestamp),
      openTransaction: openTransaction,
      closeTransaction: closeTransaction,
      // derived info
      market: market,
      profit: profit,
      profitPercentage: isOpen ? ZERO_PERCENT : profitPercentage,
      leverage: calculateLeverage(
        actualHeldAmount.asFraction,
        actualOwedAmount.asFraction,
        actualOwedPrice.asFraction,
        positionType,
      ),
      equityHeldAmount: equityHeldAmount,
      liquidationPrice: liquidationPrice,
      openPrice: openPrice,
      openPriceUSD: openPriceUSD,
      closePrice: closePrice,
      closePriceUSD: closePriceUSD,
      positionType: positionType,
      interestEarned: interestEarned,
      interestOwed: interestOwed,
      netInterest: netInterestPercent,
      currentInterest: currentInterestPercent,
    }
  }
}
