import { useMemo } from 'react'
import JSBI from 'jsbi'
import { TokenInfo, TokenList } from '@uniswap/token-lists'
import getLogoOrDefault from '../components/common/TokenLogos'
import { toChecksumAddress } from '../utils/toChecksumAddress'
import useKeyByMapWithChainId from '../hooks/useKeyByMapWithChainId'
import { ChainId, ONE_ETH_IN_WEI } from '../constants'
import { getMockedSpecialAssets, NAME_OVERRIDE_MAP, SYMBOL_OVERRIDE_MAP } from '../constants/isolation/special-assets'
import { NO_VARIABLES, useGraphqlResult, useGraphqlResultList } from '../state/graphql/hooks'
import { GraphqlCall, GraphqlClientType } from '../state/graphql/actions'
import { RefreshFrequency } from '../state/chain/hooks'
import {
  ACTIVE_CHAIN_IDS,
  ChainIdMap,
  initializeObjectChainIdMap,
  initializeObjectUndefinedChainIdMap,
} from '../constants/chainId'
import { useActiveWeb3React } from '../hooks'
import { DOLOMITE_API_SERVER_URL } from '@dolomite-exchange/zap-sdk'
import { Fraction } from '@dolomite-exchange/sdk-core'
import { CurrencyAmount, Token } from '@dolomite-exchange/v2-sdk'
import { reqParseAmount, reqParseAmountWithNoCurrency, tryParseAmount } from '../state/trade/hooks'
import { parseUnits } from '@ethersproject/units'
import { MarketRiskInfo } from './marketRiskInfoData'
import {
  cleanRiskCategory,
  RiskCategory,
  RiskCategoryStruct,
  RiskFeature,
  SingleCollateralWithSpecificDebtParam,
} from '../utils/emode'

interface RiskCategoryApi {
  category: RiskCategory
  marginRatioOverride: string
  liquidationRewardOverride: string
}

interface RiskFeatureApi {
  feature: RiskFeature
  extraData: any | undefined
}

interface SingleCollateralApi {
  name: string
  marginRatioOverride: string
  liquidationRewardOverride: string
  tokens: DolomiteMarginTokenApi[]
}

interface DolomiteMarginTokenApi {
  id: string
  marketId: string
  symbol: string
  name: string
  decimals: string
  supplyLiquidity: string // decimal format
  borrowLiquidity: string // decimal format
  riskInfo: {
    marginPremium: string
    liquidationRewardPremium: string
    isBorrowingDisabled: boolean
    supplyMaxWei: string | null // decimal format
    borrowMaxWei: string | null // decimal format
    earningsRateOverride: string | null
    oracle: string
    oracleSummary: string | undefined | null
    oracleDescription: string[] | undefined | null
  }
  riskCategory: {
    category: RiskCategory
    marginRatioOverride: string
    liquidationRewardOverride: string
  } | null
  riskFeature: {
    feature: RiskFeature
    extraData: any | undefined
  } | null
}

export interface DolomiteMarginToken {
  chainId: number
  address: string
  marketId: JSBI
  symbol: string
  name: string
  decimals: number
  riskInfo: MarketRiskInfo
  supplyLiquidity: CurrencyAmount<Token>
  borrowLiquidity: CurrencyAmount<Token>
}

interface TokensResponse {
  tokens: DolomiteMarginTokenApi[]
}

export function useDolomiteMarginTokenDataAllChainsAsTokenList(): ChainIdMap<TokenList> {
  const dolomiteTokenDataAllChains = useDolomiteMarginTokenDataAllChains()
  const { chainId: currentChainId } = useActiveWeb3React()
  const key = useKeyByMapWithChainId(dolomiteTokenDataAllChains[currentChainId]?.data ?? {})
  return useMemo<ChainIdMap<TokenList>>(() => {
    return ACTIVE_CHAIN_IDS.reduce<ChainIdMap<TokenList>>((memo, chainId) => {
      const tokens = Object.values(dolomiteTokenDataAllChains[chainId]?.data ?? {}).reduce<TokenInfo[]>(
        (memo, token) => {
          memo.push({
            chainId: token.chainId,
            address: token.address,
            name: token.name,
            symbol: token.symbol,
            decimals: token.decimals,
            logoURI: getLogoOrDefault(token.symbol),
            tags: undefined,
          })
          return memo
        },
        [],
      )

      if (tokens.length > 0 && process.env.NODE_ENV !== 'production') {
        const tokenChainId = tokens[0].chainId
        const specialAsset = getMockedSpecialAssets()
        specialAsset.forEach(specialAsset => {
          const wrappedToken = specialAsset.isolationModeInfo?.getWrappedToken(tokenChainId)
          if (wrappedToken && !dolomiteTokenDataAllChains[chainId]?.data[wrappedToken.address]) {
            tokens.push({
              chainId: wrappedToken.chainId,
              address: wrappedToken.address,
              name: wrappedToken.name ?? '',
              symbol: wrappedToken.symbol ?? '',
              decimals: wrappedToken.decimals,
              logoURI: getLogoOrDefault(wrappedToken.symbol ?? ''),
              tags: undefined,
            })
          }
        })
      }

      memo[chainId] = {
        name: 'Dolomite',
        timestamp: '2022-10-12T00:00:00.000Z',
        version: {
          major: 1,
          minor: 0,
          patch: 0,
        },
        tokens,
      }
      return memo
    }, initializeObjectChainIdMap() as ChainIdMap<TokenList>)
    // eslint-disable-next-line
  }, [key])
}

export function useDolomiteMarginTokenDataAsTokenList(): TokenList {
  const { data: dolomiteTokenData } = useDolomiteMarginTokenData()
  const key = useKeyByMapWithChainId(dolomiteTokenData)
  return useMemo<TokenList>(() => {
    const tokens = Object.values(dolomiteTokenData).reduce<TokenInfo[]>((memo, token) => {
      memo.push({
        chainId: token.chainId,
        address: token.address,
        name: token.name,
        symbol: token.symbol,
        decimals: token.decimals,
        logoURI: getLogoOrDefault(token.symbol),
        tags: undefined,
      })
      return memo
    }, [])

    if (tokens.length > 0 && process.env.NODE_ENV !== 'production') {
      const chainId = tokens[0].chainId
      const specialAsset = getMockedSpecialAssets()
      specialAsset.forEach(specialAsset => {
        const wrappedToken = specialAsset.isolationModeInfo?.getWrappedToken(chainId)
        if (wrappedToken && !dolomiteTokenData[wrappedToken.address]) {
          tokens.push({
            chainId: wrappedToken.chainId,
            address: wrappedToken.address,
            name: wrappedToken.name ?? '',
            symbol: wrappedToken.symbol ?? '',
            decimals: wrappedToken.decimals,
            logoURI: getLogoOrDefault(wrappedToken.symbol ?? ''),
            tags: undefined,
          })
        }
      })
    }

    return {
      name: 'Dolomite',
      timestamp: '2022-10-12T00:00:00.000Z',
      version: {
        major: 1,
        minor: 0,
        patch: 0,
      },
      tokens,
    }
    // eslint-disable-next-line
  }, [key])
}

const toFraction = (field: string): Fraction => {
  return new Fraction(parseUnits(field, 18).toString(), ONE_ETH_IN_WEI)
}

function tryParseRiskCategory(riskCategoryApi: RiskCategoryApi | null): RiskCategoryStruct[] | undefined {
  if (!riskCategoryApi) {
    return undefined
  }

  return [
    {
      name: cleanRiskCategory(riskCategoryApi.category),
      riskCategory: riskCategoryApi.category,
      marginRatioOverride: reqParseAmountWithNoCurrency(riskCategoryApi.marginRatioOverride),
      liquidationRewardOverride: reqParseAmountWithNoCurrency(riskCategoryApi.liquidationRewardOverride),
      strict: false,
    },
  ]
}

function tryParseRiskFeature(
  riskFeatureApi: RiskFeatureApi | null,
  chainId: ChainId,
): SingleCollateralWithSpecificDebtParam[] | undefined {
  if (!riskFeatureApi) {
    return undefined
  }

  if (riskFeatureApi.feature === RiskFeature.BORROW_ONLY) {
    return undefined
  } else if (riskFeatureApi.feature === RiskFeature.SINGLE_COLLATERAL_WITH_SPECIFIC_DEBT) {
    return (riskFeatureApi.extraData as SingleCollateralApi[]).map(param => {
      return {
        name: param.name,
        debtTokens: param.tokens.map(t => parseTokenApiToToken(t, chainId)),
        marginRatioOverride: reqParseAmountWithNoCurrency(param.marginRatioOverride),
        liquidationRewardOverride: reqParseAmountWithNoCurrency(param.liquidationRewardOverride),
        strict: true,
        riskFeature: RiskFeature.SINGLE_COLLATERAL_WITH_SPECIFIC_DEBT,
      }
    })
  }

  console.warn('Invalid risk feature, found: ', riskFeatureApi.feature)
  return undefined
}

function parseTokenApiToToken(tokenApi: DolomiteMarginTokenApi, chainId: ChainId): Token {
  const tokenAddress = toChecksumAddress(tokenApi.id)
  const decimals = getDecimalOverride(chainId, tokenAddress, Number(tokenApi.decimals))
  const symbol = getSymbolOverride(chainId, tokenAddress, tokenApi.symbol)
  const name = getNameOverride(chainId, tokenAddress, tokenApi.name)
  return new Token(chainId, tokenAddress, decimals, symbol, name)
}

export function sanitizeTokenGql(tokenApi: DolomiteMarginTokenApi, chainId: ChainId): DolomiteMarginToken {
  const tokenAddress = toChecksumAddress(tokenApi.id)
  const marketId = JSBI.BigInt(tokenApi.marketId)
  const decimals = getDecimalOverride(chainId, tokenAddress, Number(tokenApi.decimals))
  const symbol = getSymbolOverride(chainId, tokenAddress, tokenApi.symbol)
  const name = getNameOverride(chainId, tokenAddress, tokenApi.name)
  const token = parseTokenApiToToken(tokenApi, chainId)
  return {
    chainId,
    symbol,
    name,
    decimals,
    marketId,
    address: tokenAddress,
    supplyLiquidity: reqParseAmount(tokenApi.supplyLiquidity, token),
    borrowLiquidity: reqParseAmount(tokenApi.borrowLiquidity, token),
    riskInfo: {
      token,
      marketId,
      tokenAddress,
      isBorrowingDisabled: tokenApi.riskInfo.isBorrowingDisabled,
      isBorrowOnly: tokenApi.riskFeature?.feature === RiskFeature.BORROW_ONLY,
      marginPremium: toFraction(tokenApi.riskInfo.marginPremium),
      liquidationRewardPremium: toFraction(tokenApi.riskInfo.liquidationRewardPremium),
      oracleAddress: toChecksumAddress(tokenApi.riskInfo.oracle),
      supplyMaxWei: tryParseAmount(tokenApi.riskInfo.supplyMaxWei ?? undefined, token),
      borrowMaxWei: tryParseAmount(tokenApi.riskInfo.borrowMaxWei ?? undefined, token),
      oracleSummary: tokenApi.riskInfo.oracleSummary,
      oracleDescription: tokenApi.riskInfo.oracleDescription,
      riskCategories: tryParseRiskFeature(tokenApi.riskFeature, chainId) ?? tryParseRiskCategory(tokenApi.riskCategory),
    },
  }
}

export function useDolomiteMarginTokenDataAllChains(): ChainIdMap<
  | {
      data: Record<string, DolomiteMarginToken>
      loading: boolean
      error: boolean
    }
  | undefined
> {
  const calls = useMemo(() => {
    return ACTIVE_CHAIN_IDS.map<GraphqlCall>(chainId => {
      return {
        chainId,
        clientType: GraphqlClientType.Fetch,
        query: `${DOLOMITE_API_SERVER_URL}/tokens/${chainId}`,
        variables: '{}',
      }
    })
  }, [])
  const tokensStates = useGraphqlResultList<TokensResponse>(calls, RefreshFrequency.Slowest)

  return useMemo(() => {
    let anyError = false
    let anyLoading = false
    return ACTIVE_CHAIN_IDS.reduce(
      (memo, chainId, i) => {
        const state = tokensStates?.[i]
        if (!state || !state.result) {
          memo[chainId] = {
            data: {},
            loading: state?.loading ?? true,
            error: state?.error ?? false,
          }
          return memo
        }

        anyError = anyError || state.error
        anyLoading = anyLoading || state.loading

        if (state.result) {
          const tokensFromApi = state.result?.tokens ?? []
          const tokenMap = tokensFromApi.reduce<Record<string, DolomiteMarginToken>>((innerMemo, t) => {
            const tokenAddress = toChecksumAddress(t.id)
            innerMemo[tokenAddress] = sanitizeTokenGql(t, chainId)
            return innerMemo
          }, {})

          memo[chainId] = {
            loading: anyLoading,
            error: anyError,
            data: tokenMap,
          }
        } else {
          memo[chainId] = {
            loading: anyLoading,
            error: anyError,
            data: {},
          }
        }
        return memo
      },
      initializeObjectUndefinedChainIdMap() as ChainIdMap<
        | {
            data: Record<string, DolomiteMarginToken>
            loading: boolean
            error: boolean
          }
        | undefined
      >,
    )
  }, [tokensStates])
}

export function useDolomiteMarginTokenData(): {
  loading: boolean
  error: boolean
  data: Record<string, DolomiteMarginToken>
} {
  const { chainId } = useActiveWeb3React()
  const queryState = useGraphqlResult<TokensResponse>(
    GraphqlClientType.Fetch,
    `${DOLOMITE_API_SERVER_URL}/tokens/${chainId}`,
    NO_VARIABLES,
    RefreshFrequency.Slowest,
  )

  return useMemo(() => {
    const { loading, error, result } = queryState
    const anyLoading = Boolean(loading)
    const anyError = Boolean(error)
    const tokenApis = result?.tokens ?? []

    const tokenMap = tokenApis.reduce<Record<string, DolomiteMarginToken>>((memo, t) => {
      const tokenAddress = toChecksumAddress(t.id)
      memo[tokenAddress] = sanitizeTokenGql(t, chainId)
      return memo
    }, {})

    return {
      loading: anyLoading,
      error: anyError,
      data: tokenMap,
    }
  }, [queryState, chainId])
}

function getSymbolOverride(chainId: ChainId, address: string, originalSymbol: string): string {
  return SYMBOL_OVERRIDE_MAP[chainId][address] ?? originalSymbol
}

function getNameOverride(chainId: ChainId, address: string, originalName: string): string {
  return NAME_OVERRIDE_MAP[chainId][address] ?? originalName
}

function getDecimalOverride(chainId: ChainId, address: string, originalDecimals: number): number {
  return originalDecimals
}
