import { WMNT, WMNT_ISOLATION_MODE_ADDRESSES, ChainId } from '../../index'
import { Token } from '@dolomite-exchange/v2-sdk'
import { calculateWMNTProxyVaultAddress } from '../../../utils/isolation/calculateProxyVaultAddress'
import { getOptionally } from '../special-assets-util-functions'
import { SpecialAsset, SpecialAssetSymbol } from '../special-assets'

export const D_WMNT_SPECIAL_ASSET: () => SpecialAsset = () => ({
  symbol: SpecialAssetSymbol.dWMNT,
  safeSymbol: SpecialAssetSymbol.dWMNT,
  cleanSymbol: 'sMNT',
  cleanName: 'Staked MNT',
  hasExternalRewards: false,
  documentationUrl: 'https://docs.dolomite.io/integrations/mantle-smnt',
  isIsolationMode: true,
  isBorrowingEnabled: false,
  chainIdToAddressMap: WMNT_ISOLATION_MODE_ADDRESSES,
  isolationModeInfo: {
    marketId: 9,
    getWrappedToken: (chainId: ChainId) =>
      getOptionally(
        WMNT_ISOLATION_MODE_ADDRESSES[chainId],
        address => new Token(chainId, address, 18, 'dWMNT', 'Dolomite Isolation: Mantle'),
      ),
    getUnderlyingToken: getWmntToken,
    getUnderlyingSpenderToken: getWmntToken,
    allowableCollateralTokens: () => [],
    allowableDebtTokens: () => [],
    remapAccountAddress: calculateWMNTProxyVaultAddress,
    isAsync: false,
  },
})

function getWmntToken(chainId: ChainId): Token | undefined {
  return WMNT[chainId]
}
