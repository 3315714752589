import { MarketRiskInfo } from 'types/marketRiskInfoData'
import { Token } from '@dolomite-exchange/v2-sdk'
import { Fraction } from '@dolomite-exchange/sdk-core'
import cleanCurrencySymbol from './cleanCurrencySymbol'

export enum RiskCategory {
  BERA = 'bera',
  BTC = 'btc',
  ETH = 'eth',
  STABLE = 'stable',
}

export function cleanRiskCategory(category: RiskCategory): string {
  return `${category === RiskCategory.STABLE ? 'Stable' : category?.toUpperCase()} correlated`
}

export interface RiskOverrideStruct {
  name: string
  marginRatioOverride: Fraction
  liquidationRewardOverride: Fraction
  /**
   * True if the user can transition out of this risk override (E-Mode) setting without reverting
   */
  strict: boolean
}

export type PositionOverrideSetting = RiskOverrideStruct | InvalidPositionOverride

export interface InvalidPositionOverride {
  /**
   * True if the position is in a valid state but the E-Mode status cannot be determined. `false` if the position breaks
   * a risk rule and therefore cannot transact.
   */
  isValid: boolean
  /**
   * The reason why the position is in violation of E-Mode
   */
  errorMessage: string | undefined
  /**
   * Used to determine which asset enabled the `SINGLE_COLLATERAL_WITH_SPECIFIC_DEBT` field
   */
  primaryRiskToken: Token
  struct: RiskOverrideStruct | undefined
}

export interface RiskCategoryStruct extends RiskOverrideStruct {
  riskCategory: RiskCategory
}

export enum RiskFeature {
  BORROW_ONLY = 'borrow_only',
  SINGLE_COLLATERAL_WITH_SPECIFIC_DEBT = 'single_collateral_with_specific_debt',
}

export interface SingleCollateralWithSpecificDebtParam extends RiskOverrideStruct {
  riskFeature: RiskFeature.SINGLE_COLLATERAL_WITH_SPECIFIC_DEBT
  debtTokens: Token[]
}

export function isInvalidRiskForPosition(value: PositionOverrideSetting | undefined): value is InvalidPositionOverride {
  return !!value && 'isValid' in value
}

export function isValidPositionOverride(value: PositionOverrideSetting | undefined): value is RiskOverrideStruct {
  return !!value && 'name' in value
}

export function isRiskCategoryStruct(value: RiskOverrideStruct | undefined): value is RiskCategoryStruct {
  return !!value && 'riskCategory' in value
}

export function isSingleCollateralWithSpecificDebtParam(
  value: RiskOverrideStruct | undefined,
): value is SingleCollateralWithSpecificDebtParam {
  return !!value && 'riskFeature' in value && value.riskFeature === RiskFeature.SINGLE_COLLATERAL_WITH_SPECIFIC_DEBT
}

export function isPositionEModeEqual(e1: PositionOverrideSetting | undefined, e2: PositionOverrideSetting | undefined) {
  if (e1 === e2) {
    return true
  }

  if (isValidPositionOverride(e1) && isValidPositionOverride(e2)) {
    return e1.name === e2.name
  }

  if (isInvalidRiskForPosition(e1) && isInvalidRiskForPosition(e2)) {
    return e1.isValid === e2.isValid && e1.primaryRiskToken.equals(e2.primaryRiskToken)
  }

  return false
}

export function isValidDebtTokenForSingleCollateralRiskCategory(
  token: Token,
  category: RiskOverrideStruct | undefined,
) {
  return isSingleCollateralWithSpecificDebtParam(category) && category.debtTokens.some(d => d.equals(token))
}

export function getBorrowPositionEMode(
  supplyTokens: Token[],
  borrowTokens: Token[],
  marketRiskInfoMap: Record<string, MarketRiskInfo | undefined>,
): PositionOverrideSetting | undefined {
  const allTokens = [...supplyTokens, ...borrowTokens]
  if (allTokens.length === 0) {
    return undefined
  }

  const riskOverride = getRiskFeatureOverride(supplyTokens, borrowTokens, marketRiskInfoMap)
  if (riskOverride) {
    return riskOverride
  }

  return getRiskCategoryOverride(allTokens, marketRiskInfoMap)
}

function getRiskFeatureOverride(
  supplyTokens: Token[],
  borrowTokens: Token[],
  marketRiskInfoMap: Record<string, MarketRiskInfo | undefined>,
): PositionOverrideSetting | undefined {
  if (borrowTokens.length !== 0 && supplyTokens.length !== 0) {
    const borrowOnlyViolation = supplyTokens.find(t => marketRiskInfoMap[t.address]?.isBorrowOnly)
    if (borrowOnlyViolation) {
      return {
        isValid: false,
        errorMessage: `${cleanCurrencySymbol(borrowOnlyViolation)} cannot be used as collateral`,
        primaryRiskToken: borrowOnlyViolation,
        struct: undefined,
      }
    }
  }

  let primaryRiskToken: Token | undefined
  let isValid = true
  let errorMessage: string | undefined
  const allTokens = [...supplyTokens, ...borrowTokens]
  for (const token of allTokens) {
    const riskInfo = marketRiskInfoMap[token.address]
    const riskCategories = riskInfo?.riskCategories ?? []
    for (const riskCategory of riskCategories) {
      if (isSingleCollateralWithSpecificDebtParam(riskCategory)) {
        if (supplyTokens.find(s => s.equals(token)) && borrowTokens.length === 0) {
          return {
            isValid: true,
            primaryRiskToken: token,
            errorMessage: undefined,
            struct: riskCategory, // return the first one
          }
        } else if (
          supplyTokens.length === 1 &&
          supplyTokens[0].equals(token) &&
          borrowTokens.every(b => riskCategory.debtTokens.some(d => b.equals(d)))
        ) {
          return riskCategory
        } else {
          errorMessage = `This position does not match any E-Mode categories for ${cleanCurrencySymbol(token)}`
          primaryRiskToken = token
          isValid = false
        }
      }
    }
  }

  if (isValid || !primaryRiskToken) {
    return undefined
  }

  return {
    isValid: false,
    errorMessage,
    primaryRiskToken,
    struct: undefined,
  }
}

function getRiskCategoryOverride(
  allTokens: Token[],
  marketRiskInfoMap: Record<string, MarketRiskInfo | undefined>,
): PositionOverrideSetting | undefined {
  let exclusiveCategory: RiskCategoryStruct | undefined
  for (const token of allTokens) {
    const param = marketRiskInfoMap[token.address]
    if (!param?.riskCategories || param.riskCategories.length === 0) {
      return undefined
    }

    const riskCategoryStructs = param.riskCategories.filter((c): c is RiskCategoryStruct => isRiskCategoryStruct(c))
    if (riskCategoryStructs.length >= 2) {
      console.warn(`Invalid risk categories found for ${token.address}`, riskCategoryStructs)
      return undefined
    }

    if (!exclusiveCategory) {
      exclusiveCategory = riskCategoryStructs[0]
    } else if (
      riskCategoryStructs.length >= 1 &&
      exclusiveCategory.riskCategory !== riskCategoryStructs[0].riskCategory
    ) {
      return undefined
    }
  }

  if (!exclusiveCategory) {
    return undefined
  }

  return exclusiveCategory
}
