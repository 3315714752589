import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react'
import Slider from 'react-slick'
import { useActiveWeb3React } from '../../hooks'
import { useTranslation } from 'react-i18next'
import ArrowForwardIcon from '@mui/icons-material/ArrowForwardIos'
import ArrowBackwardIcon from '@mui/icons-material/ArrowBackIos'
import styled from 'styled-components/macro'
import { StyledTooltip } from '../common/StyledTooltip'
import { BorrowPosition, useOpenBorrowPositions } from '../../types/borrowPositionData'
import { formatAmount } from '../../utils/formatAmount'
import {
  convertHealthToChartPosition,
  getPositionHealth,
  PositionHealthBar,
  PositionHealthBarWrapper,
  PositionHealthIndicator,
} from '../../pages/Borrow/BorrowPositionRow'
import AllInclusiveIcon from '@material-ui/icons/AllInclusive'
import getLogoOrDefault from '../common/TokenLogos'
import cleanCurrencySymbol from '../../utils/cleanCurrencySymbol'
import { useActiveStrategies } from '../../hooks/useActiveStrategies'
import StrategiesData, { StrategyInfo } from '../../pages/Strategies/StrategiesList'
import { ZERO_FRACTION } from '../../constants'

const BorrowPositionsWrapper = styled.div<{ noWallet: boolean }>`
  /*width: 38.2%;*/
  flex: 1;
  overflow: hidden;
  vertical-align: top;
  text-align: left;
  display: inline-block;
  padding-left: 5%;

  ${({ noWallet }) =>
    noWallet &&
    `
    /*height: 200px;*/
    margin-top: 40px;
  `};

  @media screen and (max-width: 900px) {
    padding-left: 0;
    max-width: 700px;
    margin: 0 auto;
    width: 100%;
  }

  @media screen and (max-width: 919px) {
    margin-bottom: 20px;
  }

  @media screen and (max-width: 500px) {
    margin-bottom: 15px;
  }

  @media screen and (max-width: 700px) {
    text-align: center;
    width: 100%;
  }
`

const BorrowPositionsInner = styled.div<{ hasAccount?: boolean }>`
  width: 100%;
  text-align: left;
  margin: 4px auto;
`

const TitleText = styled.div`
  font-size: 21px;
  font-weight: 100;
  letter-spacing: 0.1px;

  @media screen and (max-width: 900px) {
    text-align: center;
    font-size: 20px;
  }

  @media screen and (max-width: 600px) {
    font-size: 18px;
  }
`

const BorrowPositionsNumber = styled.div`
  display: inline-block;
  vertical-align: top;
  background: ${({ theme }) => theme.bg4};
  color: ${({ theme }) => theme.text1};
  font-weight: 700;
  font-size: 12px;
  padding: 2px 7px 2px 7px;
  border-radius: 5px;
  margin-left: 2px;
  margin-top: 4px;
`

const LearnMoreLink = styled.div`
  display: inline-block;
  vertical-align: top;
  margin-top: -5px;
  width: 100%;

  a {
    font-weight: 600;
    font-size: 14px;
    text-decoration: none !important;
    color: #606375 !important;
  }

  @media screen and (max-width: 900px) {
    text-align: center;
  }

  @media screen and (max-width: 600px) {
    a {
      font-size: 12px;
    }
  }
`

const CarouselWrapper = styled.div`
  width: 100%;
  height: 120px;
  margin-top: 10px;
  position: relative;

  .slick-slider {
    padding-bottom: 40px;
    mask-image: linear-gradient(
      to right,
      rgba(0, 0, 0, 0) 0%,
      rgba(0, 0, 0, 1) 10%,
      rgba(0, 0, 0, 1) 90%,
      rgba(0, 0, 0, 0) 100%
    );
  }

  .slick-dots {
    bottom: 20px;
    margin-left: 7px;

    li button:before {
      color: white !important;
    }
  }

  @media screen and (max-width: 900px) {
    margin-top: 0;

    .slick-dots {
      bottom: 20px;
      margin-left: 0;

      li button:before {
        color: white !important;
      }
    }
  }
`

const LeftButton = styled.div<{ disable: boolean }>`
  position: absolute;
  opacity: ${({ disable }) => (disable ? '0.5' : '1')};
  pointer-events: ${({ disable }) => (disable ? 'none' : 'default')};
  cursor: ${({ disable }) => (disable ? 'default' : 'pointer')};
  left: 0;
  top: 50%;
  transform: translateY(-50%);
  z-index: 2;
`

const RightButton = styled.div<{ disable: boolean }>`
  position: absolute;
  opacity: ${({ disable }) => (disable ? '0.5' : '1')};
  pointer-events: ${({ disable }) => (disable ? 'none' : 'default')};
  cursor: ${({ disable }) => (disable ? 'default' : 'pointer')};
  right: 0;
  top: 50%;
  transform: translateY(-50%);
  z-index: 2;
`

const CarouselPanel = styled.div`
  width: 100%;
  height: 110px;
  /*display: flex;
  justify-content: space-around;*/
  display: inline-block;
  vertical-align: top;
  text-align: center;
  padding: 15px 20px;
  position: relative;
`

const PositionAmountWrapper = styled.div`
  /*min-width: 25%;*/
  width: fit-content;
  display: inline-block;
  vertical-align: top;
  text-align: center;
  min-width: 21.5%;

  @media screen and (max-width: 1000px) {
    position: absolute;
    left: 10%;
    height: 50%;
    width: 60%;

    :nth-of-type(1) {
      top: 0;
      margin-bottom: 5px;
      text-align: left;
    }

    :nth-of-type(3) {
      bottom: 0;
      text-align: left;
    }
  }

  @media screen and (max-width: 900px) {
    position: relative;
    width: fit-content;
    display: inline-block;
    vertical-align: top;
    text-align: center;
    min-width: 21.5%;
    left: 0;
    margin-top: 10px;

    :nth-of-type(1) {
      top: 0;
      margin-bottom: 0;
      text-align: center;
    }

    :nth-of-type(3) {
      bottom: 0;
      text-align: center;
    }
  }

  @media screen and (max-width: 600px) {
    position: absolute;
    left: 10%;
    height: 50%;
    width: 60%;

    :nth-of-type(1) {
      top: 0;
      margin-bottom: 5px;
      text-align: left;
    }

    :nth-of-type(3) {
      bottom: 0;
      text-align: left;
    }
  }
`

const AmountTitle = styled.div`
  font-size: 16px;
  font-weight: 300;

  @media screen and (max-width: 1000px) {
    margin-bottom: -3px;
  }

  @media screen and (max-width: 900px) {
    margin-bottom: 0;
  }

  @media screen and (max-width: 700px) {
    font-size: 15px;
  }

  @media screen and (max-width: 600px) {
    margin-bottom: -3px;
    font-size: 14px;
  }
`

const AmountValue = styled.div`
  font-size: 28px;
  font-weight: 500;
  margin-top: -1px;

  @media screen and (max-width: 1200px) {
    font-size: 24px;
  }

  @media screen and (max-width: 1000px) {
    font-size: 21px;
  }

  @media screen and (max-width: 700px) {
    font-size: 20px;
  }

  @media screen and (max-width: 600px) {
    font-size: 18px;
  }
`

const AssetsWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 5px;

  img {
    width: 16px;
    height: 16px;
    margin: 0 2px;
  }

  @media screen and (max-width: 1000px) {
    display: none;
  }

  @media screen and (max-width: 900px) {
    display: flex;
  }

  @media screen and (max-width: 600px) {
    display: none;
  }
`

const AssetsWrapperSide = styled(AssetsWrapper)`
  width: fit-content;
  margin-left: 5px;
  margin-top: 2px;
  vertical-align: top;
  display: none;

  > img {
    display: inline-block !important;
  }

  @media screen and (max-width: 1000px) {
    display: inline-block;
  }

  @media screen and (max-width: 900px) {
    display: none;
  }

  @media screen and (max-width: 600px) {
    display: inline-block;
  }
`

const PositionHealthWrapper = styled.div`
  /*min-width: 20%;*/
  width: 160px;
  display: inline-block;
  vertical-align: top;
  text-align: center;
  font-size: 16px;
  font-weight: 300;
  /*margin: -8px -20px 0;*/
  margin: -30px 7% 0;

  ${PositionHealthBarWrapper} {
    width: calc(80% - 16px);
    margin-left: 10%;
  }

  @media screen and (max-width: 1280px) {
    margin: -30px 0 0;
  }

  @media screen and (max-width: 1000px) {
    position: absolute;
    right: 10%;
    height: 100%;
    width: 37%;
    top: 12px;
  }

  @media screen and (max-width: 900px) {
    position: relative;
    width: 160px;
    display: inline-block;
    vertical-align: top;
    text-align: center;
    font-size: 16px;
    font-weight: 300;
    /*margin: -8px -20px 0;*/
    margin: -30px 7% 0;
    right: 0;
  }

  @media screen and (max-width: 700px) {
    margin: -30px 4% 0;
  }

  @media screen and (max-width: 600px) {
    position: absolute;
    right: 10%;
    height: 100%;
    width: 32%;
    top: 10px;
  }

  @media screen and (max-width: 550px) {
    top: 15px;
  }

  @media screen and (max-width: 500px) {
    margin-right: 0;
    top: 18px;
    margin-top: -20px;
  }
`

const HealthTitle = styled.div`
  font-size: 16px;
  font-weight: 300;

  @media screen and (max-width: 600px) {
    font-size: 15px;
  }

  @media screen and (max-width: 500px) {
    font-size: 14px;
  }

  @media screen and (max-width: 450px) {
    font-size: 13px;
  }
`

const StrategyName = styled.div`
  color: ${({ theme }) => theme.text2};
  font-size: 11px;
  font-weight: 500;
  margin-top: 10px;
  font-style: italic;
`

const HealthValue = styled.div<{ health: number }>`
  font-size: 28px;
  font-weight: 400;
  margin: calc(-50% - 8px) 0 -6px;
  color: ${({ health, theme }) => (health === 3 ? theme.green1 : health === 2 ? theme.yellow1 : theme.red1)};

  svg {
    height: 32px;
    width: auto;
  }
`

const ArcWrapper = styled.div`
  position: relative;
  width: 100%;
  aspect-ratio: 1.2 / 1; /* Keeps arc proportional */
  display: flex;
  justify-content: center;
  align-items: center;
  overflow: visible;
`

const ArcSvg = styled.svg`
  width: 100%;
  height: 100%;
`

const HealthIndicator = styled.div<{ x: number; y: number }>`
  width: 8px;
  height: 8px;
  border-radius: 50%;
  background-color: ${({ theme }) => theme.text1};
  position: absolute;
  transform: translate(-50%, -50%);
  left: ${({ x }) => x}%;
  top: ${({ y }) => y}%;
`

interface ArcHealthBarProps {
  health: number // 0 to 100
}

const ArcHealthBar: React.FC<ArcHealthBarProps> = ({ health }) => {
  const width = 100
  const height = 100
  const radius = 39.5
  const centerX = 50
  const centerY = 60
  const startAngle = -225
  const endAngle = 45

  // Map health to an arc position
  const adjustedHealth = convertHealthToChartPosition(health)
  //const angle = (startAngle + (adjustedHealth / 100) * (endAngle - startAngle)) * (Math.PI / 180)
  const maxRadians = (2.7 * Math.PI) / 2
  const healthRadians = (adjustedHealth / 100) * ((2.7 * Math.PI) / 2)
  const radiansPreRotate = maxRadians - healthRadians
  const angle = -((0.7 * Math.PI) / 4) + radiansPreRotate

  // Calculate indicator position
  const xAdj = radius * Math.cos(angle) * 0.85
  const yAdj = radius * Math.sin(angle)
  const indicatorX = centerX + xAdj
  const indicatorY = centerY - yAdj

  return (
    <ArcWrapper>
      <ArcSvg viewBox='0 0 100 100' preserveAspectRatio='xMidYMid meet'>
        {/* Gradient */}
        <defs>
          <linearGradient id='healthGradient' x1='0%' y1='0%' x2='100%' y2='0%'>
            <stop offset='0%' stopColor='rgb(252, 59, 38)' />
            <stop offset='70%' stopColor='rgb(143, 201, 66)' />
          </linearGradient>
        </defs>

        {/* Corrected 270° Arc */}
        <path
          d='M 15 80 A 40 40 0 1 1 85 80'
          stroke='url(#healthGradient)'
          strokeWidth='2'
          fill='transparent'
          strokeLinecap='round'
        />
      </ArcSvg>

      {/* Correctly Positioned Health Indicator */}
      <HealthIndicator x={indicatorX} y={indicatorY} />
    </ArcWrapper>
  )
}

const PositionsTotalWrapper = styled.div`
  width: 160px;
  display: inline-block;
  vertical-align: top;
  text-align: center;
  font-size: 16px;
  font-weight: 300;
  margin: 0 7% 0;

  @media screen and (max-width: 1280px) {
    margin: 0;
  }

  @media screen and (max-width: 1000px) {
    position: absolute;
    right: 4%;
    height: 100%;
    width: 40%;
    top: 20px;
  }

  @media screen and (max-width: 900px) {
    position: relative;
    width: 160px;
    height: auto;
    right: 0;
    top: 0;
    margin-top: 8px;
  }

  @media screen and (max-width: 600px) {
    position: absolute;
    right: 4%;
    height: 100%;
    width: 40%;
    top: 20px;
    margin-top: 12px;
  }
`

const PositionsTotal = styled.div`
  font-size: 38px;
  margin-top: -10px;
  font-weight: 400;

  @media screen and (max-width: 700px) {
    font-size: 38px;
  }

  @media screen and (max-width: 600px) {
    font-size: 38px;
  }

  @media screen and (max-width: 500px) {
    font-size: 36px;
  }
`

const PositionsLabel = styled.div`
  font-size: 16px;
  font-weight: 400;

  @media screen and (max-width: 700px) {
    font-size: 15px;
    margin-top: -2px;
  }

  @media screen and (max-width: 600px) {
    font-size: 14px;
    margin-left: -3px;
  }

  @media screen and (max-width: 500px) {
    font-size: 13px;
    margin-top: -4px;
  }
`

const BorrowTotalsSlide = ({
  totalCollateralUSD,
  totalBorrowUSD,
  totalPositions,
}: {
  totalCollateralUSD: string
  totalBorrowUSD: string
  totalPositions: number
}) => {
  return (
    <CarouselPanel>
      <PositionAmountWrapper>
        <AmountTitle>Total Collateral</AmountTitle>
        <AmountValue>${totalCollateralUSD}</AmountValue>
      </PositionAmountWrapper>
      <PositionsTotalWrapper>
        <PositionsTotal>{totalPositions.toFixed(0)}</PositionsTotal>
        <PositionsLabel>Positions</PositionsLabel>
      </PositionsTotalWrapper>
      <PositionAmountWrapper>
        <AmountTitle>Total Debt</AmountTitle>
        <AmountValue>${totalBorrowUSD}</AmountValue>
      </PositionAmountWrapper>
    </CarouselPanel>
  )
}

const BorrowPositionSlide = ({ position, strategy }: { position: BorrowPosition; strategy?: StrategyInfo }) => {
  const positionHealth = position.positionHealth ? parseFloat(position.positionHealth.toFixed(2)) : undefined
  const healthFactor = getPositionHealth(position.positionHealth)
  return (
    <CarouselPanel>
      <PositionAmountWrapper>
        <AmountTitle>
          Collateral
          <AssetsWrapperSide>
            {position.supplyAmounts.map(amount => {
              const symbol = cleanCurrencySymbol(amount.token) ?? ''
              const logo = getLogoOrDefault(symbol)
              return <img src={logo} alt={`${symbol} token logo`} key={symbol} />
            })}
          </AssetsWrapperSide>
        </AmountTitle>
        <AmountValue>${formatAmount(position.totalSupplyUSD, 2, true, '-')}</AmountValue>
        <AssetsWrapper>
          {position.supplyAmounts.map(amount => {
            const symbol = cleanCurrencySymbol(amount.token) ?? ''
            const logo = getLogoOrDefault(symbol)
            return <img src={logo} alt={`${symbol} token logo`} key={symbol} />
          })}
        </AssetsWrapper>
      </PositionAmountWrapper>
      <PositionHealthWrapper>
        <ArcHealthBar health={positionHealth ? positionHealth : 100} />
        <HealthValue health={healthFactor}>
          {positionHealth ? (
            <span>{positionHealth > 10 ? '>10' : positionHealth.toFixed(2)}</span>
          ) : (
            <span>
              <AllInclusiveIcon
                style={{
                  marginBottom: '-7px',
                  marginLeft: '2px',
                }}
              />
            </span>
          )}
        </HealthValue>
        <HealthTitle>Health</HealthTitle>
        {strategy && (
          <StrategyName>
            Strategy: {strategy.leverage}x {strategy.title}
          </StrategyName>
        )}
        {/*<PositionHealthBarWrapper>
          <PositionHealthIndicator health={convertHealthToChartPosition(positionHealth ?? 100)} />
          <PositionHealthBar />
        </PositionHealthBarWrapper>*/}
      </PositionHealthWrapper>
      <PositionAmountWrapper>
        <AmountTitle>
          Debt
          <AssetsWrapperSide>
            {position.borrowAmounts.map(amount => {
              const symbol = cleanCurrencySymbol(amount.token) ?? ''
              const logo = getLogoOrDefault(symbol)
              return <img src={logo} alt={`${symbol} token logo`} key={symbol} />
            })}
          </AssetsWrapperSide>
        </AmountTitle>
        <AmountValue>${formatAmount(position.totalBorrowUSD, 2, true, '-')}</AmountValue>
        <AssetsWrapper>
          {position.borrowAmounts.map(amount => {
            const symbol = cleanCurrencySymbol(amount.token) ?? ''
            const logo = getLogoOrDefault(symbol)
            return <img src={logo} alt={`${symbol} token logo`} key={symbol} />
          })}
        </AssetsWrapper>
      </PositionAmountWrapper>
    </CarouselPanel>
  )
}

export default function BorrowPositionsCarousel() {
  const { t } = useTranslation()

  const { account } = useActiveWeb3React()
  const { data: positions } = useOpenBorrowPositions(account)
  const activePositions = useActiveStrategies(positions)
  const [currentSlideNumber, setCurrentSlideNumber] = useState(0)

  let sliderRef = useRef<Slider | null>(null)
  const onNextSlide = useCallback(() => {
    sliderRef.current?.slickNext()
  }, [])
  const onPreviousSlide = useCallback(() => {
    sliderRef.current?.slickPrev()
  }, [])

  const totalCollateralUSD = useMemo(() => {
    return positions.reduce((memo, position) => {
      return memo.add(position.totalSupplyUSD)
    }, ZERO_FRACTION)
  }, [positions])

  const totalBorrowUSD = useMemo(() => {
    return positions.reduce((memo, position) => {
      return memo.add(position.totalBorrowUSD)
    }, ZERO_FRACTION)
  }, [positions])

  return (
    <BorrowPositionsWrapper noWallet={!account}>
      {account ? (
        <BorrowPositionsInner>
          <TitleText>
            My Borrow Positions{` `}
            {positions.length > 0 && <BorrowPositionsNumber>{positions.length}</BorrowPositionsNumber>}
          </TitleText>
          <LearnMoreLink>
            <StyledTooltip
              title={`Your available balance consists of assets you have deposited to Dolomite that you are not using in a borrow position, strategy, or margin position. Available balances earn the interest displayed in their row below and can be freely withdrawn.\n\nClick Learn More to read more in our docs.`}
              placement={'top'}
            >
              <a href={'https://docs.dolomite.io/dolomite-balances'} target={'_blank'} rel={'noopener noreferrer'}>
                {t('learnMore')}
              </a>
            </StyledTooltip>
          </LearnMoreLink>
          <CarouselWrapper>
            <LeftButton disable={currentSlideNumber === 0}>
              <ArrowBackwardIcon onClick={onPreviousSlide} />
            </LeftButton>
            <Slider
              ref={slider => {
                sliderRef.current = slider
              }}
              {...{
                dots: true,
                arrows: false,
                infinite: false,
                speed: 500,
                slidesToShow: 1,
                slidesToScroll: 1,
                easing: 'ease-out',
              }}
              beforeChange={(currentSlide: number, nextSlide: number) => setCurrentSlideNumber(nextSlide)}
            >
              <BorrowTotalsSlide
                key={'0'}
                totalCollateralUSD={formatAmount(totalCollateralUSD, 2, true, '-')}
                totalBorrowUSD={formatAmount(totalBorrowUSD, 2, true, '-')}
                totalPositions={positions.length}
              />
              {positions.map((position, index) => {
                const activeStrategy = activePositions.find(
                  activePosition => activePosition.position.id === position.id,
                )
                return (
                  <BorrowPositionSlide
                    key={index}
                    position={position}
                    strategy={activeStrategy && StrategiesData[activeStrategy.strategy - 1]}
                  />
                )
              })}
            </Slider>
            <RightButton disable={currentSlideNumber === positions.length}>
              <ArrowForwardIcon onClick={onNextSlide} />
            </RightButton>
          </CarouselWrapper>
        </BorrowPositionsInner>
      ) : (
        <BorrowPositionsInner>
          <TitleText>
            My Borrow Positions{` `}
            {positions.length > 0 && <BorrowPositionsNumber>{positions.length}</BorrowPositionsNumber>}
          </TitleText>
          <br />
          Connect wallet
        </BorrowPositionsInner>
      )}
    </BorrowPositionsWrapper>
  )
}
