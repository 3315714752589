import React, { useState, useEffect } from 'react'
import styled from 'styled-components/macro'
import { useActiveWeb3React } from '../../hooks'
import { useGalxeAddressData } from '../../types/galxeAddressData'
import { StyledTooltip } from '../common/StyledTooltip'
import { lighten } from 'polished'
import { NavLink } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import AutoGraphIcon from '@mui/icons-material/AutoGraph'

const Level = styled(NavLink)`
  margin-right: 0;
  margin-left: 0 !important;
  margin-top: -1px;
  padding: 9px 12px;
  height: 39.5px;
  box-shadow: 0 5px 5px -3px rgb(0 0 0 / 20%), 0 8px 10px 1px rgb(0 0 0 / 14%), 0 3px 14px 2px rgb(0 0 0 / 12%);
  border-radius: 5px;
  color: ${({ theme }) => theme.text1};
  background-color: #2f2f40;
  cursor: pointer;
  font-weight: 500;
  font-size: 1rem;
  display: inline;
  text-decoration: none;
  min-width: 82px;

  :hover {
    background-color: ${({ theme }) => lighten(0.1, theme.bg2)};
  }

  svg {
    font-size: 16px;
    margin-bottom: -3px;
    margin-left: 2px;
    color: ${({ theme }) => theme.green1};
  }

  @media screen and (max-width: 660px) {
    box-shadow: none;
    margin-left: 8px !important;
  }

  @media screen and (max-width: 450px) {
    font-size: 0.7rem;
    line-height: 1.2rem;
    letter-spacing: -0.2px;
    min-width: auto;

    svg {
      font-size: 12px;
    }
  }
`

const LevelTooltip = styled.div`
  padding: 10px 15px;
  font-size: 12px;

  > div > span {
    font-weight: 600;
    margin-left: 5px;
  }

  > div > span > span {
    font-size: 8px;
  }
`

const LevelText = styled.div`
  height: 16px;
`

const LevelBar = styled.div<{ progress: number }>`
  width: 100%;
  height: 5px;
  margin-top: 7px;
  background-color: ${({ theme }) => theme.bg1} !important;
  border-radius: 2px;

  > div {
    background-color: ${({ theme }) => theme.blue1} !important;
    width: ${({ progress }) => progress}%;
    height: 100%;
    border-radius: 2px;
  }
`

export default function Header() {
  const { account } = useActiveWeb3React()
  const { levelData, loading } = useGalxeAddressData(account)
  const { level, start, end, total } = levelData
  const [isLoading, setIsLoading] = useState(true)
  useEffect(() => {
    isLoading && !loading && setIsLoading(false)
  }, [loading, isLoading])

  return isLoading ? (
    <></>
  ) : (
    <StyledTooltip
      title={
        <LevelTooltip>
          <LevelText>
            Current Level:<span>{level}</span>
          </LevelText>
          <LevelText>
            Total Experience:
            <span>
              {total}
              <span>XP</span>
            </span>
          </LevelText>
          <LevelText>
            Experience to Level {level + 1}:
            <span>
              {end - start}
              <span>XP</span>
            </span>
          </LevelText>
          <LevelBar progress={(start / end) * 100}>
            <div></div>
          </LevelBar>
        </LevelTooltip>
      }
      placement={'bottom'}
    >
      <Level
        id={`achievements-nav-link`}
        to={'/achievements'}
        onClick={e => {
          e.stopPropagation()
        }}
      >
        LVL {level ?? 0}
        <AutoGraphIcon />
      </Level>
    </StyledTooltip>
  )
}
