import { Fraction, Token } from '@dolomite-exchange/sdk-core'
import { MarketRiskInfo } from '../types/marketRiskInfoData'
import calculateScaledValueForRiskParams from './calculateScaledValueForRiskParams'
import { ZERO_FRACTION } from '../constants'
import { CurrencyAmount } from '@dolomite-exchange/v2-sdk'
import { getBorrowPositionEMode, isValidPositionOverride } from './emode'

interface TokenWithAmountUsd {
  token: Token
  amountUSD: Fraction
}

export function calculateStrategyHealth(
  supplyAmountWeis: CurrencyAmount<Token>[],
  borrowAmountWeis: CurrencyAmount<Token>[],
  marketRiskInfoMap: Record<string, MarketRiskInfo | undefined>,
  defaultMinCollateralization: Fraction,
  fiatValueMap: Record<string, Fraction | undefined>,
): Fraction | undefined {
  const supplyAmounts = supplyAmountWeis.map(a => {
    const fiatValue = fiatValueMap[a.currency.address] ?? ZERO_FRACTION
    return {
      token: a.currency,
      amountUSD: a.asFraction.multiply(fiatValue),
    }
  })
  const borrowAmounts = borrowAmountWeis.map(a => {
    const fiatValue = fiatValueMap[a.currency.address] ?? ZERO_FRACTION
    return {
      token: a.currency,
      amountUSD: a.asFraction.multiply(fiatValue),
    }
  })

  return calculateBorrowPositionHealth(supplyAmounts, borrowAmounts, marketRiskInfoMap, defaultMinCollateralization)
}

export default function calculateBorrowPositionHealth(
  supplyAmounts: TokenWithAmountUsd[],
  borrowAmounts: TokenWithAmountUsd[],
  marketRiskInfoMap: Record<string, MarketRiskInfo | undefined>,
  defaultMinCollateralization: Fraction,
): Fraction | undefined {
  if (Object.keys(marketRiskInfoMap).length === 0) {
    return undefined
  }

  const eMode = getBorrowPositionEMode(
    supplyAmounts.map(a => a.token),
    borrowAmounts.map(a => a.token),
    marketRiskInfoMap,
  )
  const minCollateralizationOverride = isValidPositionOverride(eMode) ? eMode.marginRatioOverride : undefined
  try {
    const scaledTotalSupplyAmount = supplyAmounts.reduce((acc, supplyAmount) => {
      const riskInfo = marketRiskInfoMap[supplyAmount.token.address]
      if (!riskInfo) {
        console.error('No risk info for supply token', supplyAmount.token.symbol, supplyAmount.token.address)
        throw new Error('No risk info for supply token')
      }

      if (minCollateralizationOverride) {
        return acc.add(supplyAmount.amountUSD)
      }
      return acc.add(calculateScaledValueForRiskParams(true, supplyAmount.amountUSD, riskInfo, eMode))
    }, ZERO_FRACTION)

    const scaledTotalBorrowAmount = borrowAmounts.reduce((acc, borrowAmount) => {
      const riskInfo = marketRiskInfoMap[borrowAmount.token.address]
      if (!riskInfo) {
        console.error('No risk info for borrow token', borrowAmount.token.symbol, borrowAmount.token.address)
        throw new Error('No risk info for borrow token')
      }

      if (minCollateralizationOverride) {
        return acc.add(borrowAmount.amountUSD)
      }
      return acc.add(calculateScaledValueForRiskParams(false, borrowAmount.amountUSD, riskInfo, eMode))
    }, ZERO_FRACTION)

    if (scaledTotalBorrowAmount.equalTo(ZERO_FRACTION)) {
      return undefined
    } else {
      return scaledTotalSupplyAmount.divide(
        scaledTotalBorrowAmount.multiply(minCollateralizationOverride ?? defaultMinCollateralization),
      )
    }
  } catch (e) {
    return undefined
  }
}
