import Modal from '../Modal'
import styled from 'styled-components/macro'
import React, { useCallback, useEffect, useMemo, useState } from 'react'
import { Percent, Token } from '@dolomite-exchange/v2-sdk'
import { useTranslation } from 'react-i18next'
import Column from '../Orders/Column'
import Checkmark from '@material-ui/icons/Check'
import { X } from 'react-feather'
import getLogoOrDefault from '../common/TokenLogos'
import { useMarketRiskInfoData } from '../../types/marketRiskInfoData'
import cleanCurrencySymbol from '../../utils/cleanCurrencySymbol'
import { formatAmount } from '../../utils/formatAmount'
import useAllEModeCategories from '../../hooks/useAllEModeCategories'
import {
  isSingleCollateralWithSpecificDebtParam,
  isValidDebtTokenForSingleCollateralRiskCategory,
  RiskOverrideStruct,
} from '../../utils/emode'
import { StyledTooltipWithIcon } from '../common/StyledTooltip'
import { ExternalLink } from '../../theme'
import { DOCS_E_MODE_HELP_URL } from '../../constants'

const ModalContent = styled.div`
  text-align: left;
  padding: 20px 30px;
  position: relative;
`

const Title = styled.div`
  font-size: 22px;
  font-weight: 100;
`

const Description = styled.div`
  color: ${({ theme }) => theme.text1};
  font-weight: 300;
  font-size: 12px;
  margin-top: 6px;
  margin-bottom: 16px;
`

const Sorting = styled.div`
  position: relative;
  display: inline-block;
  height: 65px;
  width: 220px;
  vertical-align: top;
  margin-top: 0;

  @media screen and (max-width: 500px) {
    width: 100%;
  }
`

const SortTitle = styled.div`
  font-size: 12px;
  color: ${({ theme }) => theme.text3};
  font-weight: 400;
  margin-right: 3px;
  margin-top: 10px;
  vertical-align: top;
`

const DataRows = styled.div`
  font-size: 13px;
  display: inline-block;
  color: ${({ theme }) => theme.text1};
  font-weight: 400;
  width: calc(100% - 240px);

  @media screen and (min-width: 615px) {
    margin-left: 10px;
    width: calc(100% - 230px);
  }
  @media screen and (max-width: 500px) {
    margin-left: 0;
    width: 100%;
  }
  vertical-align: bottom;
  margin-bottom: 8px;
`

const DataRow = styled.div`
  margin-bottom: 0;
  vertical-align: top;

  svg {
    transform: translate(-3px, 1px);
  }
`

const DataValue = styled.span`
  float: right;
`

const SortSelect = styled.div<{ expanded: boolean }>`
    background-color: ${({ theme }) => theme.bg7};
    border-radius: 4px;
    text-align: left;
    cursor: pointer;
    height: 28px;
    overflow: hidden;
    width: 200px;
    display: inline-block;
    color: ${({ theme }) => theme.text1};
    position: relative;
    left: 0;
    top: 0;
    ${({ expanded }) =>
      expanded &&
      `
    border-top-left-radius: 4px;
    height: fit-content;
    overflow-x: hidden;
  `} //overflow-y: scroll;
                    //max-height: 220px;scrollbar-color: ${({ theme }) => `${theme.bg4} ${theme.bg2}`};
    scrollbar-width: thin;

    ::-webkit-scrollbar {
        width: 10px;
        margin-left: 2px;
        background: ${({ theme }) => theme.bg1};
        left: 50px;
    }

    ::-webkit-scrollbar-track {
        background: ${({ theme }) => theme.bg1};
        width: 10px;
        box-shadow: inset 0 0 10px 10px ${({ theme }) => theme.bg1};
        border: solid 3px ${({ theme }) => theme.bg1};
    }

    ::-webkit-scrollbar-thumb {
        background: ${({ theme }) => theme.bg4};
        width: 4px;
        border-radius: 10px;
        box-shadow: inset 0 0 10px 10px ${({ theme }) => theme.bg4};
        border: solid 3px ${({ theme }) => theme.bg1};
    }

    ::-webkit-scrollbar-button {
        display: none;
    }

    @media screen and (max-width: 500px) {
        width: 100%;
    }
`

const SortSelectRow = styled.div`
  font-size: 14px;
  font-weight: 500;
  padding: 4px 8px;
  height: 27px;

  &:hover {
    background-color: #474956;
  }
`

const ArrowDown = styled.div`
  width: 0;
  height: 0;
  position: absolute;
  border-left: 5px solid transparent;
  border-right: 5px solid transparent;
  border-top: 6px solid #606375;
  top: 12px;
  right: 7px;
`

const TokenTable = styled.div`
  background-color: ${({ theme }) => theme.bg6};
  width: 100%;
  border-radius: 5px;
  padding: 6px 0;
  min-height: 315px;
`

const CheckmarkStyled = styled(Checkmark)`
  color: ${({ theme }) => theme.green1};
`

const XStyled = styled(X)`
  color: ${({ theme }) => theme.red1};
`

const ColumnTitles = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
  font-weight: 600;
  font-size: 14px;
  color: ${({ theme }) => theme.text3};
  padding: 0 20px;
`

const TokenRow = styled.div`
  width: 100%;
  font-size: 14px;
  display: flex;
  justify-content: space-between;
  margin-top: 13px;
  padding: 0 20px;
  color: ${({ theme }) => theme.text1};
`

const TokenInfo = styled.div`
  display: inline-block;
  vertical-align: top;
  width: 100%;
`

const TokenLogo = styled.div`
  display: inline-block;
  vertical-align: middle;
  margin-right: 5px;

  img {
    height: 18px;
    width: auto;
  }
`

const TokenSymbol = styled.div`
  display: inline-block;
  vertical-align: middle;
  margin-bottom: 5px;
  font-size: 16px;
`

const CloseWrapper = styled.div`
  position: absolute;
  top: 15px;
  right: 15px;
`

const Close = styled(X)`
  cursor: pointer;
  height: 18px;
`

interface EmodeWarningModalProps {
  open: boolean
  close: () => void
  initialCategory: RiskOverrideStruct | undefined
}

function AvailableModalComparator(prevProps: EmodeWarningModalProps, nextProps: EmodeWarningModalProps) {
  return prevProps.open === nextProps.open && prevProps.initialCategory?.name === nextProps.initialCategory?.name
}

const Available = React.memo(function AvailableModalComparator({ close, initialCategory }: EmodeWarningModalProps) {
  const { t } = useTranslation()
  const [sortSelectOpen, setSortSelectOpen] = useState<boolean>(false)
  const { data: riskInfoMap } = useMarketRiskInfoData()
  const riskCategories = useAllEModeCategories(riskInfoMap)
  const sortWrapperRef = React.useRef<HTMLDivElement>(null)
  const [selectedCategory, setSelectedCategory] = useState<RiskOverrideStruct | undefined>(
    initialCategory ?? riskCategories[0],
  )
  const handleClickOutside = useCallback((event: MouseEvent) => {
    if (sortWrapperRef?.current && !sortWrapperRef?.current.contains(event.target as Node)) {
      setSortSelectOpen(false)
    }
  }, [])
  const riskCategoryTokens = useMemo(() => {
    if (isSingleCollateralWithSpecificDebtParam(selectedCategory)) {
      const collateralToken = Object.values(riskInfoMap).find(r =>
        r?.riskCategories?.find(c => c.name === selectedCategory.name),
      )!.token

      return [collateralToken, ...selectedCategory.debtTokens]
    }

    return Object.values(riskInfoMap).reduce<Token[]>((acc, riskInfo) => {
      if (riskInfo?.riskCategories?.find(c => c.name === selectedCategory?.name)) {
        acc.push(riskInfo.token)
      }

      return acc
    }, [])
  }, [riskInfoMap, selectedCategory])

  useEffect(() => {
    document.addEventListener('mousedown', handleClickOutside)
    return () => document.removeEventListener('mousedown', handleClickOutside)
  }, [handleClickOutside])
  return (
    <ModalContent>
      <CloseWrapper onClick={() => close()}>
        <Close />
      </CloseWrapper>
      <Title>Available E-Mode</Title>
      <Description>
        E-Mode allows you to borrow with a higher LTV and decreased liquidation penalty, as long as all of the assets in
        your position are within the selected Asset Category.&nbsp;
        <ExternalLink href={DOCS_E_MODE_HELP_URL}>Learn more</ExternalLink>
      </Description>
      <Sorting>
        <SortTitle>Asset Category</SortTitle>
        <SortSelect
          expanded={sortSelectOpen}
          ref={sortWrapperRef}
          onClick={() => !sortSelectOpen && setSortSelectOpen(true)}
        >
          {!sortSelectOpen && (
            <SortSelectRow
              onClick={e => {
                e.stopPropagation()
                setSortSelectOpen(true)
              }}
            >
              {selectedCategory?.name}
            </SortSelectRow>
          )}
          {sortSelectOpen && (
            <SortSelectRow onClick={() => setSortSelectOpen(false)}>{selectedCategory?.name}</SortSelectRow>
          )}
          {riskCategories
            .filter(category => category.name !== selectedCategory?.name)
            .map((category, index) => {
              return (
                <SortSelectRow
                  key={`filter-dropdown-${index}`}
                  onClick={() => {
                    setSelectedCategory(category)
                    setSortSelectOpen(false)
                  }}
                >
                  {category.name}
                </SortSelectRow>
              )
            })}
          <ArrowDown />
        </SortSelect>
      </Sorting>
      <DataRows>
        <DataRow>
          Strict?{' '}
          <StyledTooltipWithIcon
            tooltipText={`Open positions that are using Strict E-Mode can't be modified by adding other assets. Positions using Non-Strict E-Mode can be modified by adding other assets, but will lose E-Mode advantages.`}
          />
          <DataValue>{selectedCategory?.strict ? 'YES' : 'NO'}</DataValue>
        </DataRow>
        <DataRow>
          MAX LTV
          <DataValue>
            {selectedCategory
              ? formatAmount(Percent.fromFraction(selectedCategory.marginRatioOverride.invert()))
              : null}
          </DataValue>
        </DataRow>
        <DataRow>
          Liq. Penalty
          <DataValue>
            {selectedCategory ? formatAmount(Percent.fromFraction(selectedCategory.liquidationRewardOverride)) : null}
          </DataValue>
        </DataRow>
      </DataRows>
      <TokenTable>
        <ColumnTitles>
          <Column width={38} tabletWidth={34} mobileWidth={34} textAlign={'left'}>
            {t('Asset')}
          </Column>
          <Column width={31} tabletWidth={33} mobileWidth={33} textAlign={'center'}>
            {t('Collateral')}
          </Column>
          <Column width={31} tabletWidth={33} mobileWidth={33} textAlign={'center'}>
            {t('Borrowable')}
          </Column>
        </ColumnTitles>
        {riskCategoryTokens.map(token => (
          <TokenRow key={token.address}>
            <Column width={38} tabletWidth={34} mobileWidth={34} textAlign={'left'}>
              <TokenInfo>
                <TokenLogo>
                  <img src={getLogoOrDefault(cleanCurrencySymbol(token) ?? '')} alt={`token logo`} />
                </TokenLogo>
                <TokenSymbol>{cleanCurrencySymbol(token)}</TokenSymbol>
              </TokenInfo>
            </Column>
            <Column width={31} tabletWidth={33} mobileWidth={33} textAlign={'center'}>
              {isValidDebtTokenForSingleCollateralRiskCategory(token, selectedCategory) ||
              riskInfoMap[token.address]?.isBorrowOnly ? (
                <XStyled />
              ) : (
                <CheckmarkStyled />
              )}
            </Column>
            <Column width={31} tabletWidth={33} mobileWidth={33} textAlign={'center'}>
              {riskInfoMap[token.address]?.isBorrowingDisabled ? <XStyled /> : <CheckmarkStyled />}
            </Column>
          </TokenRow>
        ))}
      </TokenTable>
    </ModalContent>
  )
}, AvailableModalComparator)

function EmodeAvailableModal({ open, close, initialCategory }: EmodeWarningModalProps) {
  return (
    <Modal isOpen={open} onDismiss={close} maxHeight={400} maxWidthPx={500}>
      {!open ? <></> : <Available open={open} close={close} initialCategory={initialCategory} />}
    </Modal>
  )
}

export default EmodeAvailableModal
