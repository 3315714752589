import { ChainId, GM_PEPE_USD_ADDRESSES, GM_PEPE_USD_ISOLATION_MODE_ADDRESSES } from '../../index'
import { Token } from '@dolomite-exchange/v2-sdk'
import { calculateGmPEPEUSDProxyVaultAddress } from '../../../utils/isolation/calculateProxyVaultAddress'
import { getOptionally } from '../special-assets-util-functions'
import { SpecialAsset, SpecialAssetSymbol } from '../special-assets'
import {
  getGmCollateralTokens,
  getGmDebtTokens,
  getGmUsdValidInputTokens,
  getGmUsdValidOutputTokens,
} from './special-gm-tokens'

const cleanSymbol = 'gmPEPE-USD'
const cleanName = 'GM: PEPE-USD'

export const GM_PEPE_USD_SPECIAL_ASSET: () => SpecialAsset = () => ({
  cleanSymbol,
  cleanName,
  symbol: SpecialAssetSymbol.gmPEPEUSD,
  safeSymbol: SpecialAssetSymbol.gmPEPEUSD,
  hasExternalRewards: false,
  documentationUrl: 'https://docs.dolomite.io/integrations/gmx/gm',
  isIsolationMode: true,
  isBorrowingEnabled: false,
  chainIdToAddressMap: GM_PEPE_USD_ISOLATION_MODE_ADDRESSES,
  isolationModeInfo: {
    marketId: 65,
    getWrappedToken: (chainId: ChainId) =>
      getOptionally(
        GM_PEPE_USD_ISOLATION_MODE_ADDRESSES[chainId],
        address => new Token(chainId, address, 18, cleanSymbol, `Dolomite Isolation: ${cleanName}`),
      ),
    getUnderlyingToken: getGmToken,
    getUnderlyingSpenderToken: getGmToken,
    allowableCollateralTokens: (chainId: ChainId) =>
      getGmCollateralTokens(SpecialAssetSymbol.gmPEPEUSD, chainId, undefined),
    allowableDebtTokens: (chainId: ChainId) => getGmDebtTokens(chainId, undefined),
    allowableInputTokensForZap: (chainId: ChainId) => getGmUsdValidInputTokens(chainId, undefined),
    allowableOutputTokensForZap: (chainId: ChainId) => getGmUsdValidOutputTokens(chainId, undefined),
    remapAccountAddress: calculateGmPEPEUSDProxyVaultAddress,
    isAsync: true,
  },
})

function getGmToken(chainId: ChainId): Token | undefined {
  return getOptionally(
    GM_PEPE_USD_ADDRESSES[chainId],
    address => new Token(chainId, address, 18, cleanSymbol, cleanSymbol),
  )
}
