import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react'
import Mult from '@material-ui/icons/Clear'
import styled, { keyframes } from 'styled-components/macro'
import { StyledTooltipWithIcon } from '../../components/common/StyledTooltip'
import Sparkle from 'react-sparkle'
import Add from '@mui/icons-material/Add'
import TaskAlt from '@mui/icons-material/TaskAlt'
import AutoAwesomeIcon from '@mui/icons-material/AutoAwesome'
import Diamond from '@mui/icons-material/Diamond'
import DownArrow from '@material-ui/icons/ArrowDownward'
import DolomiteLogo from '../../assets/dolomite-logo-white.svg'
import CircularProgress from '@material-ui/core/CircularProgress'
import CountUp from 'react-countup'
import ArbitrumLogo from '../../assets/svg/arbitrum_logo.svg'
import MantleLogo from '../../assets/svg/mantle_logo.svg'
import XLayerLogo from '../../assets/svg/xlayer_logo.svg'
import { formatAmount } from '../../utils/formatAmount'
import { CurrencyAmount, Fraction } from '@dolomite-exchange/sdk-core'
import { Separator } from '../../components/Trade/styleds'
import { Currency, Token } from '@dolomite-exchange/v2-sdk'
import { useAllActiveTokensArray } from '../../hooks/Tokens'
import { tryParseAmount, tryParseAmountWithNoCurrency } from '../../state/trade/hooks'
import Input from '@material-ui/core/Input'
import cleanCurrencySymbol from '../../utils/cleanCurrencySymbol'
import CurrencyModal from '../../components/CurrencyModal'
import { useIsTransactionPending, useTransactionAdder } from '../../state/transactions/hooks'
import { useDolomiteBalancesWithLoadingIndicator } from '../../state/wallet/hooks'
import { useActiveWeb3React } from '../../hooks'
import { useDefaultFiatValuesWithLoadingIndicator } from '../../hooks/useFiatValue'
import { useTranslation } from 'react-i18next'
import { ChainId } from '../../constants'
import Web3StatusConnectButton from '../../components/Web3StatusConnectButton'
import { useMineralsAirdropClaim } from '../../hooks/useAirdropClaim'
import { useMineralsAirdropContract } from '../../hooks/useContract'
import { calculateGasMargin } from '../../utils'
import { TransactionResponse } from '@ethersproject/providers'
import { BigNumber } from '@ethersproject/bignumber'
import { useMineralToken } from '../../hooks/Tokens'
import {
  useLiquidityMiningLeaderboardRankByAccount,
  useMineralsLiquidityMiningLeaderboardRankByAccount,
} from '../../types/liquidityMiningLeaderboard'

const UsageSection = styled.div`
  width: calc(50% - 20px);
  margin-right: 20px;
  display: inline-block;
  vertical-align: top;
  margin-top: 20px;

  @media screen and (max-width: 950px) {
    width: calc(100% - 40px);
    max-width: 600px;
    margin: 20px auto 0;
    display: block;
  }

  @media screen and (max-width: 500px) {
    width: 100%;
  }
`

const UsageTitle = styled.div`
  font-size: 24px;
  font-weight: 400;
`

const UsageSubtitle = styled.div`
  font-size: 15px;
  font-weight: 300;
  margin-bottom: 15px;
  color: ${({ theme }) => theme.text6};
  letter-spacing: 0.1px;
`

const UsageBreakdown = styled.div`
  width: 100%;
  background: ${({ theme }) => theme.bg1};
  border-radius: 8px;
  padding: 28px 38px;
  font-size: 15px;
  box-shadow: 0 5px 5px -3px rgb(0 0 0 / 20%), 0 8px 10px 1px rgb(0 0 0 / 14%), 0 3px 14px 2px rgb(0 0 0 / 12%);

  @media screen and (max-width: 500px) {
    padding: 22px 28px;
  }
`

const AirdropTotals = styled.div`
  width: 100%;
  margin-bottom: 0;
`

const fadeIn = keyframes`
  from {
    opacity: 0;
    transform: translateY(10px);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
`

const fadeInImpact = keyframes`
  0% {
    opacity: 0;
    transform: translateY(10px) scale(1) rotate(0deg);
  }
  50% {
    transform: translateY(5px) scale(1.4) rotate(0deg);
  }
  100% {
    opacity: 1;
    transform: translateY(0) scale(1) rotate(30deg);
  }
`

const fadeInImpactReverse = keyframes`
  0% {
    opacity: 0;
    transform: translateY(10px) scale(1) rotate(0deg);
  }
  50% {
    transform: translateY(5px) scale(1.4) rotate(0deg);
  }
  100% {
    opacity: 1;
    transform: translateY(0) scale(1) rotate(-30deg);
  }
`

const ChainTotalsWrapper = styled.div``

const ChainTotal = styled.div<{ delay: number }>`
  margin-bottom: 5px;
  vertical-align: top;
  width: 100%;
  font-size: 15px;
  font-weight: 500;
  display: flex;
  justify-content: space-between;
  opacity: 0;
  animation: ${fadeIn} 1s ease forwards;
  animation-delay: ${({ delay }) => delay}s;

  @media screen and (max-width: 500px) {
    font-size: 13px;
  }
`

const PointTotalTitle = styled.div``

const PointTotalAmount = styled.div``

const ChainTotalTitle = styled(PointTotalTitle)`
  img {
    height: 16px;
    width: 16px;
    margin-right: 6px;
    transform: translateY(2px);
  }

  svg {
    transform: translate(-3px, 1px);
  }

  @media screen and (max-width: 450px) {
    width: 50%;
    text-align: left;
  }
`

const ChainTotalPoints = styled(PointTotalAmount)`
  font-weight: 600;
`

const MultSymbol = styled.div`
  display: inline-block;
  width: 43px;
  padding-top: 32px;
  text-align: center;
  display: none;
`

const LevelMultiplier = styled.div`
  width: 100%;
  margin-bottom: 5px;
  display: inline-block;
  vertical-align: top;
  width: 77px;

  width: 100%;
  font-size: 15px;
  font-weight: 500;
  display: flex;
  justify-content: space-between;
  opacity: 0;
  animation: ${fadeIn} 1s ease forwards;
  animation-delay: 0.5s;
`

const LevelTitle = styled.div`
  svg {
    transform: translate(-3px, 0);
  }
`

const LevelNumber = styled.div`
  font-weight: 600;
`

const rainbowAnimation = keyframes`
  0% {
    background-position: 0 50%;
  }
  50% {
    background-position: 100% 50%;
  }
  100% {
    background-position: 0 50%;
  }
`

const BonusPoints = styled.div`
  width: 100%;
  margin-bottom: 10px;
  font-size: 15px;
  font-weight: 500;
  display: flex;
  justify-content: space-between;
  /*opacity: 0;
  animation: ${fadeIn} 1s ease forwards;
  animation-delay: 1s;*/
  background: linear-gradient(
    to right,
    #ef5350,
    #f48fb1,
    #7e57c2,
    #2196f3,
    #26c6da,
    #43a047,
    #eeff41,
    #f9a825,
    #ff5722
  );
  background-size: 300% 300%;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  opacity: 0;
  animation:
    ${fadeIn} 1s ease forwards 1s, /* Fade in with delay */
    ${rainbowAnimation} 5s ease infinite; /* Continuous rainbow animation */
`

const BonusPointsText = styled.div``

const BonusPointsAmount = styled.div`
  font-weight: 600;
`

const FinalTotal = styled.div`
  width: 100%;
  margin-bottom: 0;
  margin-top: 0;

  display: flex;
  align-items: center;
  justify-content: flex-start;
  width: 100%;
`

const FinalTotalTitle = styled(PointTotalTitle)`
  font-weight: 600;
  width: 100%;
  margin-top: 20px;
  margin-bottom: 4px;
  display: flex;
  justify-content: space-between;
  position: relative;

  @media screen and (max-width: 420px) {
    font-size: 13px;
    margin-bottom: 3px;
  }
`

const FinalTotalValue = styled(PointTotalAmount)`
  font-size: 32px;
  height: 52px;
  /*position: relative;
  display: inline-block;
  vertical-align: top;*/
  font-weight: 300;
  margin-top: -2px;
  /*width: calc(100% - 150px);
  text-align: center;
  justify-content: center;*/
  color: ${({ theme }) => theme.text1};
  background: ${({ theme }) => theme.bg6};
  border-radius: 8px;
  padding: 3px 18px;

  flex: 1; /* Expands to fill remaining space */
  text-align: right;
  overflow: hidden;
  transition: all 3s ease-in-out;

  @media screen and (max-width: 500px) {
    font-size: 26px;
    height: 44px;
  }

  @media screen and (max-width: 420px) {
    font-size: 21px;
    height: 36px;
  }
`

const MineralsRank = styled.div<{ width: number; is69: boolean; animationFinished: boolean }>`
  font-size: 32px;
  height: 52px;
  position: relative;
  display: inline-block;
  vertical-align: top;
  font-weight: 300;
  margin-top: -2px;
  margin-left: 10px;
  /*width: 140px;*/
  text-align: center;
  justify-content: center;
  color: ${({ theme }) => theme.text1};
  background: ${({ theme }) => theme.bg6};
  border-radius: 8px;
  padding: 3px 13px 3px 18px;
  color: ${({ theme, is69, animationFinished }) => (is69 && animationFinished ? theme.bg6 : theme.text1)};

  flex-shrink: 0;
  /*overflow: hidden;*/
  white-space: nowrap;
  width: ${({ width }) => `${width}px`}; /* Dynamic width */
  transition: all 0.3s ease; /* Smooth width adjustment */

  > div:nth-of-type(1) {
    position: absolute;
  }

  > div:nth-of-type(2) {
    ${({ is69, animationFinished }) => is69 && animationFinished && 'opacity: 0;'}
  }

  @media screen and (max-width: 500px) {
    font-size: 26px;
    height: 44px;
  }

  @media screen and (max-width: 420px) {
    font-size: 21px;
    height: 36px;
  }
`

const TopRank = styled.div`
  position: absolute;
  right: -6px;
  bottom: -60px;
  opacity: 0;
  animation: ${fadeInImpact} 1s ease-in forwards;
  animation-delay: 2.9s;
  z-index: 10;
  /*transform: rotate(30deg) !important;*/
  font-size: 28px;
`

const NiceRank = styled(TopRank)`
  bottom: -57px;
  animation: ${fadeInImpactReverse} 1s ease-in forwards;
  animation-delay: 2.8s;
  font-size: 18px;
`

const Plus = styled.div`
  display: inline-block;
  vertical-align: top;
  font-size: 32px;
  font-weight: 400;
  color: ${({ theme }) => theme.text2};
  margin-top: 0;
  opacity: 0;
  animation: ${fadeIn} 1s ease forwards;
  animation-delay: 1.5s;
  position: absolute;
  right: 13px;
`

const RankSuperscript = styled.div`
  display: inline-block;
  vertical-align: top;
  font-size: 16px;
  font-weight: 400;
  color: ${({ theme }) => theme.text2};
  margin-top: 4px;
  opacity: 0;
  animation: ${fadeIn} 1s ease forwards;
  animation-delay: 3s;
  position: absolute;
  right: 15px;

  @media screen and (max-width: 900px) {
    font-size: 14px;
  }

  @media screen and (max-width: 500px) {
    font-size: 12px;
  }
`

const SixNineOverlay = styled.div<{ animationFinished: boolean }>`
  position: absolute;
  opacity: ${({ animationFinished }) => (animationFinished ? 1 : 0)};
  transition: opacity 0.3s ease;
`

const Rank69 = styled.span`
  background-image: linear-gradient(to left, violet, green, yellow, orange, red);
  background-clip: text;
  color: transparent;
  background-size: 300% 300%;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  opacity: 0;
  animation: ${fadeIn} 1s ease forwards 1s, /* Fade in with delay */ ${rainbowAnimation} 5s ease infinite; /* Continuous rainbow animation */
`

const ClaimSection = styled.div`
  width: 100%;
  margin-top: 15px;
  background: ${({ theme }) => theme.bg1};
  border-radius: 8px;
  padding: 28px 38px;
  font-size: 15px;
  box-shadow: 0 5px 5px -3px rgb(0 0 0 / 20%), 0 8px 10px 1px rgb(0 0 0 / 14%), 0 3px 14px 2px rgb(0 0 0 / 12%);
  text-align: center;

  @media screen and (max-width: 500px) {
    padding: 22px 28px;
  }
`

const ClaimTitle = styled.div`
  font-size: 24px;
  font-weight: 400;
  text-align: left;
`

const ClaimSubtitle = styled.div`
  font-size: 14px;
  line-height: 18px;
  font-weight: 300;
  margin-bottom: 10px;
  color: ${({ theme }) => theme.text2};
  text-align: left;
  letter-spacing: 0.1px;
`

const MineralsUsageNumbers = styled.div`
  margin: 15px 0 20px;
  > div {
    font-size: 13px;
  }

  @media screen and (max-width: 500px) {
    > div {
      font-size: 11px;
    }
  }
`

const ExchangeRate = styled.div`
  font-size: 13px;
  font-weight: 400;
  width: fit-content;
  text-align: right;
  color: ${({ theme }) => theme.text2};

  @media screen and (max-width: 500px) {
    font-size: 11px;
  }
`

const MineralsFieldTopRow = styled.div`
  display: flex;
  justify-content: space-between;
  margin-bottom: 2px;
  font-size: 14px;

  @media screen and (max-width: 500px) {
    margin-bottom: 0;
  }

  @media screen and (max-width: 500px) {
    font-size: 12px;
  }
`

const FieldTitle = styled.div`
  font-size: 14px;
  font-weight: 400;
  color: ${({ theme }) => theme.text1};
  text-align: left;

  @media screen and (max-width: 500px) {
    font-size: 12px;
  }
`

const BalanceWrapper = styled.div`
  width: fit-content;
  font-weight: 500;
  cursor: pointer;
`

const AmountWrapper = styled.span`
  font-weight: 300;
`

const SymbolWrapper = styled.span`
  font-weight: 300;
  color: ${({ theme }) => theme.text3};
`

const MaxButton = styled.div`
  font-size: 14px;
  font-weight: 400;
  color: ${({ theme }) => theme.text3};
  cursor: pointer;

  :hover {
    color: ${({ theme }) => theme.text1};
  }

  @media screen and (max-width: 500px) {
    font-size: 12px;
  }
`

const Field = styled.div`
  /*margin-bottom: 10px;
  color: #f9f9f9 !important;
  display: inline-flex !important;
  position: relative !important;
  font-size: 1rem !important;
  background: #1e1c29 !important;
  font-family: Open Sans, serif !important;
  line-height: 1.1875em !important;
  font-weight: 300 !important;
  border-radius: 5px !important;
  justify-content: space-between !important;
  width: 100%;
  padding: 9px 12px;
  height: fit-content;
  text-align: right;*/

  height: 37px;
  overflow: hidden;
  position: relative;

  input {
    height: 19px !important;
    color: #f9f9f9 !important;
    display: inline-flex !important;
    position: relative !important;
    font-size: 1rem !important;
    background: #1e1c29 !important;
    font-family: Open Sans, serif !important;
    line-height: 1.1875em !important;
    font-weight: 300 !important;
    border-radius: 5px !important;
    padding: 9px 10px !important;
  }
`

const StyledInput = styled(({ ...props }) => <Input {...props} />)<{ multiline: boolean }>`
  overflow: hidden;
  margin-bottom: 0 !important;
  height: 37px !important;
  border-radius: 5px !important;

  input {
    margin-bottom: 0 !important;
  }

  ${({ disabled }) =>
    disabled &&
    `
    input {
      background: #1e1c29 !important;
    }
  `}

  ${({ multiline }) =>
    multiline &&
    `
    margin-top: 2px;
    width: 100% !important;

    textarea {
      overflow: hidden !important;
      padding: 0 8px !important;
      width: calc(100% - 8px) !important;
    }
  `};

  @media (max-width: 1400px) {
    input {
      font-size: 0.9rem;
    }

    p {
      font-size: 0.8rem;
    }
  }
`

const TokenSelector = styled.div<{ expanded: boolean; disabled?: boolean }>`
  background-color: #3a3a4f;
  border-bottom-right-radius: 4px;
  border-top-right-radius: 4px;
  cursor: ${({ disabled }) => (disabled ? 'unset' : 'pointer')};
  height: 37px;
  right: 0;
  bottom: 0;
  overflow: hidden;
  position: absolute;
  width: fit-content;
  z-index: 1;
  text-align: left;

  ${({ expanded }) =>
    expanded &&
    `
    /*border-top-left-radius: 4px;*/
    height: fit-content;
  `}
`

const TokenSelectRow = styled.div<{ disabled?: boolean }>`
  font-size: 15px;
  font-weight: 300;
  padding: 8px 25px 10px 10px;
  height: 37px;

  svg {
    height: 15px;
    width: auto;
    transform: translateY(2px);
  }

  img {
    height: 15px;
    width: auto;
    transform: translateY(2px);
  }

  &:hover {
    ${({ theme, disabled }) => !disabled && `background-color: ${theme.bg4};`}
  }
`

const ArrowDown = styled.div<{ flipped: boolean }>`
  width: 0;
  height: 0;
  position: absolute;
  border-left: 5px solid transparent;
  border-right: 5px solid transparent;
  border-top: 6px solid #606375;
  bottom: 14px;
  right: 7px;

  ${({ flipped }) =>
    flipped &&
    `
    transform: rotate(180deg);
  `}
`

const FieldAsset = styled.div`
  font-size: 15px;
  font-weight: 300;
  background: #3a3a4f;
  padding: 9px 12px;
  margin: -9px -12px -9px 0;
  border-top-right-radius: 5px;
  border-bottom-right-radius: 5px;

  svg {
    height: 15px;
    width: auto;
    transform: translateY(2px);
  }

  img {
    height: 15px;
    width: auto;
    transform: translateY(2px);
  }
`

const ExtraInfo = styled.div`
  font-size: 12px;
  color: ${({ theme }) => theme.text3};
  margin-top: 0;
  margin-bottom: 10px;
  width: 100%;
  text-align: left;
  line-height: 15px;
`

const DownArrowWrapper = styled.div`
  width: 100%;
  text-align: center;
  margin-top: 5px;
  margin-bottom: 9px;
  font-size: 18px;
`

const AddWrapper = styled.div`
  width: 100%;
  text-align: center;
  margin-top: -7px;
  margin-bottom: -3px;
`

const ChangeNetworkButton = styled.div`
  background-color: ${({ theme }) => theme.yellow2};
  color: ${({ theme }) => theme.text1};
  width: 100%;
  font-weight: 500;
  cursor: pointer;
  border-radius: 5px;
  padding: 8px 16px;
  margin: 25px auto 0;

  :hover {
    background-color: ${({ theme }) => theme.yellow1};
  }
`

const ConnectWalletButton = styled.div`
  margin-top: 15px;
  color: white !important;
  width: 100%;
`

const ConnectBtn = styled.div`
  display: inline-block;
  width: 100%;
`

const ClaimButton = styled.div<{ disabled: boolean }>`
  border-radius: 5px;
  background-color: ${({ theme, disabled }) => (!disabled ? theme.green2 : theme.bg4)};
  color: ${({ theme }) => theme.text1};
  font-size: 14px;
  font-weight: 600;
  padding: 8px 20px;
  cursor: ${({ disabled }) => (!disabled ? 'pointer' : 'default')};
  width: 100%;
  text-align: center;
  line-height: 22px;
  margin-top: 20px;
  margin-bottom: 4px;
  height: 38px;

  :hover {
    background-color: ${({ theme, disabled }) => (!disabled ? theme.green1 : theme.bg4)};
  }

  > svg,
  > div {
    color: ${({ theme }) => theme.text1} !important;
    font-size: 20px;
    height: 20px !important;
    width: 20px !important;
  }
`

const OptionsSection = styled.div`
  width: calc(50% - 20px);
  margin-left: 20px;
  display: inline-block;
  vertical-align: top;
  margin-top: 20px;
`

const StyledSeparator = styled(Separator)`
  height: 1px;
  background-color: #606375;
  margin-top: 20px;
  margin-bottom: -5px;
  width: 80%;
  margin-left: 10%;
`

const convertAmounts = (
  conversionRate: Fraction, // Amount of minerals per 1 DOLO
  fiatValueMap: Record<string, Fraction | undefined>, // Dollar value of assets, indexed by token address
  selectedPaymentToken: Token, // The selected payment token
  mineralsAmount?: Fraction, // Amount of minerals. If defined, return the amount and other two amounts scaled to it
  paymentAmount?: CurrencyAmount<Token>, // Amount of payment token. If defined, return the amount and the other two amounts scaled to it
  doloAmount?: Fraction, // Amount of DOLO. If defined, return the amount and the other two amounts scaled to it
) => {
  const paymentFiatValue = fiatValueMap[selectedPaymentToken.address]
  if (mineralsAmount && paymentFiatValue) {
    const minerals = mineralsAmount
    const dolo = minerals.divide(conversionRate)
    const payment = dolo.multiply(new Fraction(45, 1000)).divide(paymentFiatValue)
    return { minerals, dolo, payment }
  }
  if (paymentAmount && paymentFiatValue) {
    const payment = paymentAmount
    const dolo = payment.multiply(paymentFiatValue).divide(new Fraction(45, 1000))
    const minerals = dolo.multiply(conversionRate)
    return { minerals, dolo, payment }
  }
  if (doloAmount && paymentFiatValue) {
    const dolo = doloAmount
    const minerals = dolo.multiply(conversionRate)
    const payment = dolo.multiply(new Fraction(45, 1000)).divide(paymentFiatValue)
    return { minerals, dolo, payment }
  }
  return undefined
}

export default function MineralsAirdrop() {
  const { t } = useTranslation()
  const { account, chainId, setChain } = useActiveWeb3React()
  const { data: rankData } = useMineralsLiquidityMiningLeaderboardRankByAccount(account)
  const isOnWrongNetwork = chainId !== ChainId.BERACHAIN
  const wrapperRef = React.useRef<HTMLDivElement>(null)
  const [rankWidth, setRankWidth] = useState(131)
  const [isAnimationFinished, setIsAnimationFinished] = useState(false)
  const rankContentRef = useRef<HTMLDivElement>(null)
  const tokens = useAllActiveTokensArray()
  const paymentTokens = useMemo(
    () =>
      tokens.filter(
        token =>
          token.symbol === 'USDC' ||
          token.symbol === 'USDT' ||
          token.symbol === 'WETH' ||
          token.symbol === 'ETH' ||
          token.symbol === 'HONEY',
      ),
    [tokens],
  )
  const [selectedPaymentToken, setSelectedPaymentToken] = useState<Token>(paymentTokens[0])
  const [paymentValue, setPaymentValue] = useState('')
  const [isMaxPaymentValueSelected, setIsMaxPaymentValueSelected] = useState(false)
  const parsedPaymentValue = useMemo(
    () => tryParseAmount(paymentValue === '0.' ? '0.0' : paymentValue, selectedPaymentToken),
    [paymentValue, selectedPaymentToken],
  )
  const [mineralValue, setMineralValue] = useState('')
  const [isMaxMineralValueSelected, setIsMaxMineralValueSelected] = useState(false)
  const parsedMineralValue = useMemo(() => tryParseAmountWithNoCurrency(mineralValue === '0.' ? '0.0' : mineralValue), [
    mineralValue,
  ])
  const [doloValue, setDoloValue] = useState('')
  const parsedDoloValue = useMemo(() => tryParseAmountWithNoCurrency(doloValue === '0.' ? '0.0' : doloValue), [
    doloValue,
  ])
  const [paymentTokenSelectOpen, setPaymentTokenSelectOpen] = useState(false)
  const [isAttemptingTx, setIsAttemptingTx] = useState(false)
  const [txHash, setTxHash] = useState<string | undefined>(undefined)
  const isTxPending = useIsTransactionPending(txHash)
  const [, setTransactionPendingText] = useState('')
  const [dolomiteBalanceData] = useDolomiteBalancesWithLoadingIndicator(account, paymentTokens)
  const [fiatValueMap] = useDefaultFiatValuesWithLoadingIndicator(paymentTokens)
  const dolomiteBalanceUsdData = useMemo(() => {
    const map: Record<string, Fraction | undefined> = {}
    tokens.map(t => {
      const fiatValue = fiatValueMap[t.address]
      if (fiatValue) {
        map[t.address] = dolomiteBalanceData[t.address]?.asFraction.multiply(fiatValue)
      }
    })
    return map
  }, [tokens, fiatValueMap, dolomiteBalanceData])
  const isClaimingLive = false // TODO - change

  const {
    loading: airdropLoading,
    error: airdropError,
    data: airdropData,
    arbitrumMinerals,
    mantleMinerals,
    xLayerMinerals,
    totalMinerals,
    doloOptionsAmount,
    mineralsPerDolo,
  } = useMineralsAirdropClaim()

  const airdropContract = useMineralsAirdropContract()
  const addTransaction = useTransactionAdder()

  const isClaimable =
    !airdropLoading && !airdropError && !!airdropData && !!doloOptionsAmount && doloOptionsAmount.greaterThan(0)
  const isClaiming = isAttemptingTx || isTxPending

  const onClaim = useCallback(async () => {
    if (!airdropContract || !airdropData || !parsedPaymentValue || !account || !isClaimingLive) return

    setIsAttemptingTx(true)
    try {
      const args = [parsedPaymentValue.quotient.toString(), airdropData.proofs]

      const estimatedGas = await airdropContract.estimateGas.claim(...args)
      const response: TransactionResponse = await airdropContract.claim(...args, {
        gasLimit: calculateGasMargin(estimatedGas),
      })

      addTransaction(response, {
        summary: `Claim ${parsedPaymentValue.toSignificant(6)} ${selectedPaymentToken.symbol}`,
      })
      setTxHash(response.hash)
    } catch (error) {
      console.error('Failed to claim:', error)
      setIsAttemptingTx(false)
    }
  }, [
    airdropContract,
    airdropData,
    parsedPaymentValue,
    account,
    isClaimingLive,
    addTransaction,
    selectedPaymentToken.symbol,
  ])

  const receivedRank = account ? rankData : undefined
  const displayRank = parseInt(receivedRank?.toFixed(0) ?? '1000')
  const rankAsFraction = useMemo(() => receivedRank, [receivedRank])
  const totalUsedMinerals = account ? new Fraction(0, 100) : undefined // TODO: Get from contract
  const totalAvailableMinerals =
    totalMinerals && totalUsedMinerals ? totalMinerals.subtract(totalUsedMinerals) : undefined
  const totalCollectedMineralsString = totalMinerals?.toFixed(2)
  const totalCollectedMineralsNumber = parseFloat(totalCollectedMineralsString ?? '0')
  const isWrongChain = false

  const selectedAssetBalance = dolomiteBalanceData[selectedPaymentToken.address]?.asFraction
  const selectedAssetSymbol = cleanCurrencySymbol(selectedPaymentToken)

  useEffect(() => {
    const ref = rankContentRef.current
    // Use ResizeObserver to dynamically update width
    const observer = new ResizeObserver(entries => {
      for (let entry of entries) {
        if (entry.target === rankContentRef.current) {
          if (entry.contentRect.width + 51 !== rankWidth && entry.contentRect.width !== 0) {
            setRankWidth(entry.contentRect.width + 51) // Update the width dynamically
          }
        }
      }
    })

    if (ref) {
      observer.observe(ref)
    }

    return () => {
      if (ref) {
        observer.unobserve(ref)
      }
    }
  }, [rankWidth])

  const handleAnimationFinished = useCallback(() => {
    setIsAnimationFinished(true)
  }, [])

  useEffect(() => {
    setIsAnimationFinished(false)
  }, [account])

  const onUpdateMineralValue = useCallback(
    (e: React.ChangeEvent<HTMLInputElement>) => {
      const re = /^\d*(\.\d*)?$/ //Only allow numbers and a single decimal point

      if (e.target.value === '') {
        setPaymentValue('')
        setDoloValue('')
        setMineralValue(e.target.value)
        setIsMaxMineralValueSelected(false)
      } else if (re.test(e.target.value)) {
        const parsedMineralValue = tryParseAmountWithNoCurrency(
          e.target.value === '.' || e.target.value === '0.' ? '0.0' : e.target.value,
        )
        if (mineralsPerDolo) {
          const conversionData = convertAmounts(
            mineralsPerDolo,
            fiatValueMap,
            selectedPaymentToken,
            parsedMineralValue,
            undefined,
            undefined,
          )
          if (conversionData) {
            setPaymentValue(
              conversionData.payment
                .toFixed(selectedPaymentToken.decimals)
                .replace(/^0+(?!\.)|(?:\.|(\..*?))0+$/gm, '$1'),
            )
            setDoloValue(conversionData.dolo.toFixed(18).replace(/^0+(?!\.)|(?:\.|(\..*?))0+$/gm, '$1'))
          }
        }
        setMineralValue(e.target.value === '.' ? '0.' : e.target.value)
        setIsMaxMineralValueSelected(false)
      }
    },
    [fiatValueMap, mineralsPerDolo, selectedPaymentToken],
  )

  const onUpdatePaymentValue = useCallback(
    (e: React.ChangeEvent<HTMLInputElement>) => {
      const re = /^\d*(\.\d*)?$/ //Only allow numbers and a single decimal point

      if (e.target.value === '') {
        setMineralValue('')
        setDoloValue('')
        setPaymentValue(e.target.value)
        setIsMaxPaymentValueSelected(false)
      } else if (re.test(e.target.value)) {
        const parsedPaymentValue = tryParseAmount(
          e.target.value === '.' || e.target.value === '0.' ? '0.0' : e.target.value,
          selectedPaymentToken,
        )
        if (mineralsPerDolo) {
          const conversionData = convertAmounts(
            mineralsPerDolo,
            fiatValueMap,
            selectedPaymentToken,
            undefined,
            parsedPaymentValue,
            undefined,
          )
          if (conversionData) {
            setMineralValue(conversionData.minerals.toFixed(18).replace(/^0+(?!\.)|(?:\.|(\..*?))0+$/gm, '$1'))
            setDoloValue(conversionData.dolo.toFixed(18).replace(/^0+(?!\.)|(?:\.|(\..*?))0+$/gm, '$1'))
          }
        }
        setPaymentValue(e.target.value === '.' ? '0.' : e.target.value)
        setIsMaxPaymentValueSelected(false)
      }
    },
    [fiatValueMap, mineralsPerDolo, selectedPaymentToken],
  )

  const onUpdateDoloValue = useCallback(
    (e: React.ChangeEvent<HTMLInputElement>) => {
      const re = /^\d*(\.\d*)?$/ //Only allow numbers and a single decimal point

      if (e.target.value === '') {
        setMineralValue('')
        setPaymentValue('')
        setDoloValue(e.target.value)
        setIsMaxMineralValueSelected(false)
      } else if (re.test(e.target.value)) {
        const parsedDoloValue = tryParseAmountWithNoCurrency(
          e.target.value === '.' || e.target.value === '0.' ? '0.0' : e.target.value,
        )
        if (mineralsPerDolo) {
          const conversionData = convertAmounts(
            mineralsPerDolo,
            fiatValueMap,
            selectedPaymentToken,
            undefined,
            undefined,
            parsedDoloValue,
          )
          if (conversionData) {
            setMineralValue(conversionData.minerals.toFixed(18).replace(/^0+(?!\.)|(?:\.|(\..*?))0+$/gm, '$1'))
            setPaymentValue(
              conversionData.payment
                .toFixed(selectedPaymentToken.decimals)
                .replace(/^0+(?!\.)|(?:\.|(\..*?))0+$/gm, '$1'),
            )
          }
        }
        setDoloValue(e.target.value === '.' ? '0.' : e.target.value)
        setIsMaxMineralValueSelected(false)
      }
    },
    [fiatValueMap, mineralsPerDolo, selectedPaymentToken],
  )

  const selectToken = useCallback((currency: Currency) => {
    const token = currency.wrapped
    setSelectedPaymentToken(token)
    setPaymentTokenSelectOpen(false)
  }, [])

  const closeModal = useCallback(() => {
    setPaymentTokenSelectOpen(false)
  }, [])

  const onMaxPayment = useCallback(() => {
    const parsedPaymentValue = tryParseAmount(
      selectedAssetBalance?.toFixed(selectedPaymentToken.decimals) ?? '0',
      selectedPaymentToken,
    )
    if (mineralsPerDolo) {
      const conversionData = convertAmounts(
        mineralsPerDolo,
        fiatValueMap,
        selectedPaymentToken,
        undefined,
        parsedPaymentValue,
        undefined,
      )
      if (conversionData) {
        setMineralValue(conversionData.minerals.toFixed(18).replace(/^0+(?!\.)|(?:\.|(\..*?))0+$/gm, '$1'))
        setDoloValue(conversionData.dolo.toFixed(18).replace(/^0+(?!\.)|(?:\.|(\..*?))0+$/gm, '$1'))
      }
    }
    setPaymentValue(selectedAssetBalance?.toFixed(selectedPaymentToken.decimals) ?? '0')
    setIsMaxPaymentValueSelected(true)
  }, [fiatValueMap, mineralsPerDolo, selectedAssetBalance, selectedPaymentToken])

  const onMaxMinerals = useCallback(() => {
    if (!totalAvailableMinerals || !mineralsPerDolo) {
      return
    }
    const conversionData = convertAmounts(
      mineralsPerDolo,
      fiatValueMap,
      selectedPaymentToken,
      totalAvailableMinerals,
      undefined,
      undefined,
    )
    if (conversionData) {
      setPaymentValue(
        conversionData.payment.toFixed(selectedPaymentToken.decimals).replace(/^0+(?!\.)|(?:\.|(\..*?))0+$/gm, '$1'),
      )
      setDoloValue(conversionData.dolo.toFixed(18).replace(/^0+(?!\.)|(?:\.|(\..*?))0+$/gm, '$1'))
    }
    setMineralValue(totalAvailableMinerals.toFixed(2))
    setIsMaxMineralValueSelected(true)
  }, [fiatValueMap, mineralsPerDolo, selectedPaymentToken, totalAvailableMinerals])

  const buttonErrorText = useMemo(() => {
    if (!account) {
      'Connect Wallet'
    }
    if (isWrongChain) {
      return 'Switch Chain'
    }
    if (parsedMineralValue && totalAvailableMinerals && parsedMineralValue.greaterThan(totalAvailableMinerals)) {
      return 'Insufficient Minerals'
    }
    if (
      selectedAssetBalance &&
      parsedPaymentValue &&
      parsedPaymentValue.asFraction.greaterThan(selectedAssetBalance.asFraction)
    ) {
      return `Insufficient ${selectedAssetSymbol} Balance`
    }
    if (mineralValue === '' || paymentValue === '') {
      return 'Enter an Amount'
    }
    return undefined
  }, [
    account,
    isWrongChain,
    mineralValue,
    parsedMineralValue,
    parsedPaymentValue,
    paymentValue,
    selectedAssetBalance,
    selectedAssetSymbol,
    totalAvailableMinerals,
  ])

  // TODO - Add on click of Dolomite Balance, insert the full balance to the field (and use the full amount if that's an option from the contract)
  return (
    <UsageSection ref={wrapperRef}>
      <UsageTitle>Minerals Airdrop</UsageTitle>
      <UsageSubtitle>View your total minerals collected across all chains and claim your DOLO options.</UsageSubtitle>
      <UsageBreakdown>
        <AirdropTotals>
          <ChainTotalsWrapper key={account}>
            <ChainTotal delay={0} style={{ animationDelay: account ? '0s' : '2s' }}>
              <ChainTotalTitle>
                {/*<img src={ArbitrumLogo} alt={'Arbitrum logo'} />*/}
                Arbitrum Minerals
              </ChainTotalTitle>
              <ChainTotalPoints>{formatAmount(arbitrumMinerals, 2, true, '-')}</ChainTotalPoints>
            </ChainTotal>
            <ChainTotal delay={0.5} style={{ animationDelay: account ? '0.5s' : '2.5s' }}>
              <ChainTotalTitle>
                {/*<img src={MantleLogo} alt={'Mantle logo'} />*/}
                Mantle Minerals
              </ChainTotalTitle>
              <ChainTotalPoints>{formatAmount(mantleMinerals, 2, true, '-')}</ChainTotalPoints>
            </ChainTotal>
            <ChainTotal delay={1} style={{ animationDelay: account ? '1s' : '3s' }}>
              <ChainTotalTitle>{/*<img src={XLayerLogo} alt={'X Layer logo'} />*/}X Layer Minerals</ChainTotalTitle>
              <ChainTotalPoints>{formatAmount(xLayerMinerals, 2, true, '-')}</ChainTotalPoints>
            </ChainTotal>
          </ChainTotalsWrapper>
          <StyledSeparator />
          <FinalTotalTitle style={{ padding: '0 8px' }}>
            <div>Total Minerals</div>
            <div>
              Rank
              {receivedRank && receivedRank.lessThan(4) && (
                <TopRank>{receivedRank.equalTo(1) ? '🥇' : receivedRank.equalTo(2) ? '🥈' : '🥉'}</TopRank>
              )}
              {receivedRank && receivedRank.equalTo(69) && <NiceRank>NICE</NiceRank>}
            </div>
          </FinalTotalTitle>
          <FinalTotal>
            <FinalTotalValue>
              <CountUp
                end={totalCollectedMineralsNumber}
                delay={1}
                duration={3}
                separator={','}
                decimals={2}
                key={account}
              />
            </FinalTotalValue>
            <MineralsRank width={rankWidth} is69={displayRank === 69} animationFinished={isAnimationFinished}>
              {receivedRank && isAnimationFinished ? (
                receivedRank.equalTo(1) ? (
                  <Sparkle color={'#ffd055'} overflowPx={5} count={18} fadeOutSpeed={32} flickerSpeed={'slower'} />
                ) : receivedRank.equalTo(2) ? (
                  <Sparkle color={'#d0d0d0'} overflowPx={5} count={18} fadeOutSpeed={32} flickerSpeed={'slower'} />
                ) : receivedRank.equalTo(3) ? (
                  <Sparkle color={'#cd7f32'} overflowPx={5} count={18} fadeOutSpeed={32} flickerSpeed={'slower'} />
                ) : receivedRank.lessThanOrEqual(10) ? (
                  <Sparkle color={'#c0c0c0'} overflowPx={5} count={18} fadeOutSpeed={32} flickerSpeed={'slower'} />
                ) : receivedRank.lessThanOrEqual(69) ? (
                  <Sparkle color={'random'} overflowPx={5} count={18} fadeOutSpeed={32} flickerSpeed={'slower'} />
                ) : null
              ) : null}
              <div ref={rankContentRef}>
                <CountUp
                  end={rankAsFraction && rankAsFraction.greaterThan(1000) ? 1000 : displayRank}
                  start={1000}
                  delay={1}
                  duration={3}
                  separator={','}
                  decimals={0}
                  onEnd={handleAnimationFinished}
                />
              </div>
              {rankAsFraction && rankAsFraction.greaterThan(1000) ? (
                <Plus>+</Plus>
              ) : (
                <RankSuperscript>
                  {displayRank
                    ? displayRank === 69
                      ? 'th'
                      : (displayRank || 0).toFixed(0).endsWith('1') && !(displayRank || 0).toFixed(0).endsWith('11')
                      ? 'st'
                      : (displayRank || 0).toFixed(0).endsWith('2') && !(displayRank || 0).toFixed(0).endsWith('12')
                      ? 'nd'
                      : (displayRank || 0).toFixed(0).endsWith('3') && !(displayRank || 0).toFixed(0).endsWith('13')
                      ? 'rd'
                      : 'th'
                    : ''}
                </RankSuperscript>
              )}
              {displayRank === 69 && (
                <SixNineOverlay animationFinished={isAnimationFinished}>
                  <Rank69>69</Rank69>
                  <RankSuperscript style={{ right: '-18px' }}>
                    <Rank69>th</Rank69>
                  </RankSuperscript>
                </SixNineOverlay>
              )}
            </MineralsRank>
          </FinalTotal>
        </AirdropTotals>
      </UsageBreakdown>
      <ClaimSection>
        <ClaimTitle>Execute Your Option</ClaimTitle>
        <ClaimSubtitle>
          You are free to exercise your call option at any point in the 6 months following TGE, allowing you to purchase
          DOLO at $0.045 per DOLO.
        </ClaimSubtitle>
        <MineralsUsageNumbers>
          <ChainTotal delay={0}>
            <ChainTotalTitle>
              Total Minerals Collected{' '}
              <StyledTooltipWithIcon
                tooltipText={
                  'The total amount of Minerals you collected across all chains by the snapshot date of January 23rd at 00:00 UTC'
                }
              />
            </ChainTotalTitle>
            <ChainTotalPoints>{formatAmount(totalMinerals, 2, true, '-')}</ChainTotalPoints>
          </ChainTotal>
          <ChainTotal delay={0.1}>
            <ChainTotalTitle>
              Total Minerals Used to Claim Options{' '}
              <StyledTooltipWithIcon
                tooltipText={
                  'You receive an amount of call options on DOLO based on the amount of Minerals you have collected. As you exercise your options, the proportional amount of Minerals will be used from your collection. This amount is how many of your collected Minerals you have used to exercise options.'
                }
              />
            </ChainTotalTitle>
            <ChainTotalPoints>{formatAmount(totalUsedMinerals, 2, true, '-')}</ChainTotalPoints>
          </ChainTotal>
          <ChainTotal delay={0.2}>
            <ChainTotalTitle>
              Total Minerals Available for Use{' '}
              <StyledTooltipWithIcon
                tooltipText={
                  'The amount of your collected Minerals remaining to be used to exercise call options. Your access to call options is based on the amount of Minerals you have remaining, and as you exercise your options the Minerals will be used up.'
                }
              />
            </ChainTotalTitle>
            <ChainTotalPoints>{formatAmount(totalAvailableMinerals, 2, true, '-')}</ChainTotalPoints>
          </ChainTotal>
          <ChainTotal delay={0.3}>
            <ChainTotalTitle>
              DOLO Value of Total Minerals{' '}
              <StyledTooltipWithIcon
                tooltipText={'The amount of DOLO that your total collected Minerals is claimable for.'}
              />
            </ChainTotalTitle>
            <ChainTotalPoints>
              {formatAmount(mineralsPerDolo ? totalMinerals?.divide(mineralsPerDolo) : undefined, 2, true, '-')}
            </ChainTotalPoints>
          </ChainTotal>
          <ChainTotal delay={0.4}>
            <ChainTotalTitle>
              DOLO Value of Remaining Minerals{' '}
              <StyledTooltipWithIcon
                tooltipText={'The amount of DOLO that your remaining unused Minerals can be claimed for.'}
              />
            </ChainTotalTitle>
            <ChainTotalPoints>
              {formatAmount(
                mineralsPerDolo ? totalAvailableMinerals?.divide(mineralsPerDolo) : undefined,
                2,
                true,
                '-',
              )}
            </ChainTotalPoints>
          </ChainTotal>
        </MineralsUsageNumbers>
        <MineralsFieldTopRow>
          <FieldTitle>Minerals to Use</FieldTitle>
          <MaxButton onClick={onMaxMinerals}>MAX</MaxButton>
        </MineralsFieldTopRow>
        <Field>
          <StyledInput
            onChange={onUpdateMineralValue}
            multiline={false}
            fullWidth
            spellCheck={false}
            placeholder={'0.00'}
            value={mineralValue}
            variant={'amountInput'}
            disableUnderline={true}
            endAdornment={''}
            disabled={isWrongChain || !account}
          />
          <TokenSelector expanded={false} disabled>
            <TokenSelectRow style={{ paddingRight: '12px' }} disabled>
              <Diamond /> Minerals
            </TokenSelectRow>
          </TokenSelector>
        </Field>
        <ExtraInfo>Subtracted from your available Minerals</ExtraInfo>
        <AddWrapper>
          <Add />
        </AddWrapper>
        <MineralsFieldTopRow>
          <FieldTitle>Payment</FieldTitle>
          <BalanceWrapper onClick={onMaxPayment}>
            Dolomite Balance: <AmountWrapper>{formatAmount(selectedAssetBalance, undefined, true, '-')}</AmountWrapper>{' '}
            <SymbolWrapper>{selectedAssetSymbol}</SymbolWrapper>
          </BalanceWrapper>
        </MineralsFieldTopRow>
        <Field>
          <StyledInput
            onChange={onUpdatePaymentValue}
            multiline={false}
            fullWidth
            spellCheck={false}
            placeholder={'0.00'}
            value={paymentValue}
            variant={'amountInput'}
            disableUnderline={true}
            endAdornment={''}
            disabled={isWrongChain || !account}
          />
          <TokenSelector
            expanded={paymentTokenSelectOpen}
            onClick={() => !paymentTokenSelectOpen && !isAttemptingTx && setPaymentTokenSelectOpen(true)}
            ref={wrapperRef}
          >
            <TokenSelectRow onClick={() => selectToken(selectedPaymentToken)}>{selectedAssetSymbol}</TokenSelectRow>
            <ArrowDown flipped={paymentTokenSelectOpen} />
          </TokenSelector>
        </Field>
        <ExtraInfo>Paid from your Dolomite Balance</ExtraInfo>
        <DownArrowWrapper>
          <DownArrow />
        </DownArrowWrapper>
        <MineralsFieldTopRow>
          <FieldTitle>DOLO Received</FieldTitle>
          <ExchangeRate>
            {mineralsPerDolo ? `${mineralsPerDolo.toFixed(2)} minerals = 1 $0.045 DOLO` : '-'}
          </ExchangeRate>
        </MineralsFieldTopRow>
        <Field>
          <StyledInput
            onChange={onUpdateDoloValue}
            multiline={false}
            fullWidth
            spellCheck={false}
            placeholder={'0.00'}
            value={doloValue}
            variant={'amountInput'}
            disableUnderline={true}
            endAdornment={''}
            disabled={isWrongChain || !account}
          />
          <TokenSelector expanded={false} disabled>
            <TokenSelectRow style={{ paddingRight: '12px' }} disabled>
              <img src={DolomiteLogo} alt={'DOLO'} /> DOLO
            </TokenSelectRow>
          </TokenSelector>
        </Field>
        <ExtraInfo>Added to your wallet when executed</ExtraInfo>
        {account ? (
          isOnWrongNetwork ? (
            <ChangeNetworkButton
              onClick={() => {
                setChain(ChainId.BERACHAIN)
              }}
            >
              Change to Berachain
            </ChangeNetworkButton>
          ) : (
            <ClaimButton
              disabled={!!buttonErrorText || !isClaimable || !isClaimingLive}
              onClick={isClaimable && isClaimingLive ? onClaim : undefined}
            >
              {isClaiming ? (
                <CircularProgress />
              ) : isClaimingLive ? (
                buttonErrorText ?? (isClaimable ? 'Exercise Option' : 'Not Available')
              ) : (
                'Claiming Coming Soon'
              )}
            </ClaimButton>
          )
        ) : (
          <ConnectWalletButton>
            <ConnectBtn key={'accountItem-StartTrading'}>
              <Web3StatusConnectButton />
            </ConnectBtn>
          </ConnectWalletButton>
        )}
      </ClaimSection>
      {!paymentTokenSelectOpen ? null : (
        <CurrencyModal
          tokens={paymentTokens}
          balances={dolomiteBalanceData}
          fiatBalances={dolomiteBalanceUsdData}
          isOpen={paymentTokenSelectOpen}
          onDismiss={closeModal}
          currencySelect={selectToken}
          balanceTitle={t('dolomiteBalance')}
          borrow
        />
      )}
    </UsageSection>
  )
}
