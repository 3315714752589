import { Token } from '@dolomite-exchange/sdk-core'
import { ChainId, ChainIdMap, initializeSingleValueChainIdMap } from '../chainId'

const decimals = 18
const symbol = 'beraETH'
const name = 'Berachain Staked ETH'

function getToken(chainId: ChainId, address: string) {
  return new Token(chainId, address, decimals, symbol, name)
}

export const BERA_ETH: ChainIdMap<Token | undefined> = initializeSingleValueChainIdMap(
  ChainId.BERACHAIN,
  getToken(ChainId.BERACHAIN, '0x6fc6545d5cDE268D5C7f1e476D444F39c995120d'),
)
