import { DEFAULT_DEADLINE_FROM_NOW, INITIAL_ALLOWED_SLIPPAGE } from '../../constants'
import { createReducer } from '@reduxjs/toolkit'
import { updateVersion } from '../global/actions'
import {
  addSerializedPair,
  addSerializedToken,
  removeSerializedPair,
  removeSerializedToken,
  SerializedPair,
  SerializedToken,
  toggleURLWarning,
  updateMatchesDarkMode,
  updateShowInactiveTokens,
  updateShowYieldAsApr,
  updateUserDarkMode,
  updateUserDeadline,
  updateUserExpertMode,
  updateUserHideDustBalances,
  updateUserHideZeroBalances,
  updateUserSingleHopOnly,
  updateUserSlippageTolerance,
} from './actions'
import { ChainIdMap, initializeObjectChainIdMap } from '../../constants/chainId'

const currentTimestamp = () => new Date().getTime()

export interface UserState {
  // the timestamp of the last updateVersion action
  lastUpdateVersionTimestamp?: number

  userDarkMode: boolean | null // the user's choice for dark mode or light mode
  matchesDarkMode: boolean // whether the dark mode media query matches

  userExpertMode: boolean

  userSingleHopOnly: boolean // only allow swaps on direct pairs

  // user defined slippage tolerance in bips, used in all txns
  userSlippageTolerance: number

  showInactiveTokens: boolean

  // true to show yield as APR, false to show yield as APY
  showYieldAsApr: boolean

  // deadline set by user in minutes, used in all txns
  userDeadline: number

  // hide dust balances in the wallet and Dolomite balances panels on the Balances and Borrow pages respectively
  userHideDustBalances: boolean

  // hide zero balances in the wallet and Dolomite balances panels on the Balances and Borrow pages respectively
  userHideZeroBalances: boolean

  tokens: ChainIdMap<Record<string, SerializedToken>>

  pairs: ChainIdMap<Record<string, SerializedPair>>

  timestamp: number

  URLWarningVisible: boolean
}

function pairKey(token0Address: string, token1Address: string) {
  return `${token0Address};${token1Address}`
}

export const initialState: UserState = {
  userDarkMode: null,
  matchesDarkMode: false,
  userExpertMode: false,
  userSingleHopOnly: false,
  userSlippageTolerance: INITIAL_ALLOWED_SLIPPAGE,
  showInactiveTokens: false,
  showYieldAsApr: true,
  userDeadline: DEFAULT_DEADLINE_FROM_NOW,
  userHideDustBalances: false,
  userHideZeroBalances: false,
  tokens: initializeObjectChainIdMap(),
  pairs: initializeObjectChainIdMap(),
  timestamp: currentTimestamp(),
  URLWarningVisible: true,
}

export default createReducer(initialState, builder =>
  builder
    .addCase(updateVersion, state => {
      // slippage isn't being tracked in local storage, reset to default
      // noinspection SuspiciousTypeOfGuard
      if (typeof state.userSlippageTolerance !== 'number') {
        state.userSlippageTolerance = INITIAL_ALLOWED_SLIPPAGE
      }

      // noinspection SuspiciousTypeOfGuard
      if (typeof state.showYieldAsApr !== 'boolean') {
        state.showYieldAsApr = true
      }

      // deadline isn't being tracked in local storage, reset to default
      // noinspection SuspiciousTypeOfGuard
      if (typeof state.userDeadline !== 'number') {
        state.userDeadline = DEFAULT_DEADLINE_FROM_NOW
      }

      state.lastUpdateVersionTimestamp = currentTimestamp()
    })
    .addCase(updateUserDarkMode, (state, action) => {
      state.userDarkMode = action.payload.userDarkMode
      state.timestamp = currentTimestamp()
    })
    .addCase(updateMatchesDarkMode, (state, action) => {
      state.matchesDarkMode = action.payload.matchesDarkMode
      state.timestamp = currentTimestamp()
    })
    .addCase(updateUserExpertMode, (state, action) => {
      state.userExpertMode = action.payload.userExpertMode
      state.timestamp = currentTimestamp()
    })
    .addCase(updateUserSlippageTolerance, (state, action) => {
      state.userSlippageTolerance = action.payload.userSlippageTolerance
      state.timestamp = currentTimestamp()
    })
    .addCase(updateShowInactiveTokens, (state, action) => {
      state.showInactiveTokens = action.payload.showInactiveTokens
      state.timestamp = currentTimestamp()
    })
    .addCase(updateShowYieldAsApr, (state, action) => {
      state.showYieldAsApr = action.payload.showYieldAsApr
      state.timestamp = currentTimestamp()
    })
    .addCase(updateUserDeadline, (state, action) => {
      state.userDeadline = action.payload.userDeadline
      state.timestamp = currentTimestamp()
    })
    .addCase(updateUserHideDustBalances, (state, action) => {
      state.userHideDustBalances = action.payload.userHideDustBalances
      state.timestamp = currentTimestamp()
    })
    .addCase(updateUserHideZeroBalances, (state, action) => {
      state.userHideZeroBalances = action.payload.userHideZeroBalances
      state.timestamp = currentTimestamp()
    })
    .addCase(updateUserSingleHopOnly, (state, action) => {
      state.userSingleHopOnly = action.payload.userSingleHopOnly
    })
    .addCase(addSerializedToken, (state, { payload: { serializedToken } }) => {
      state.tokens[serializedToken.chainId] = state.tokens[serializedToken.chainId] || {}
      state.tokens[serializedToken.chainId][serializedToken.address] = serializedToken
      state.timestamp = currentTimestamp()
    })
    .addCase(removeSerializedToken, (state, { payload: { address, chainId } }) => {
      state.tokens[chainId] = state.tokens[chainId] || {}
      delete state.tokens[chainId][address]
      state.timestamp = currentTimestamp()
    })
    .addCase(addSerializedPair, (state, { payload: { serializedPair } }) => {
      if (
        serializedPair.token0.chainId === serializedPair.token1.chainId &&
        serializedPair.token0.address !== serializedPair.token1.address
      ) {
        const chainId = serializedPair.token0.chainId
        state.pairs[chainId] = state.pairs[chainId] || {}
        state.pairs[chainId][pairKey(serializedPair.token0.address, serializedPair.token1.address)] = serializedPair
      }
      state.timestamp = currentTimestamp()
    })
    .addCase(removeSerializedPair, (state, { payload: { chainId, tokenAAddress, tokenBAddress } }) => {
      if (state.pairs[chainId]) {
        // just delete both keys if either exists
        delete state.pairs[chainId][pairKey(tokenAAddress, tokenBAddress)]
        delete state.pairs[chainId][pairKey(tokenBAddress, tokenAAddress)]
      }
      state.timestamp = currentTimestamp()
    })
    .addCase(toggleURLWarning, state => {
      state.URLWarningVisible = !state.URLWarningVisible
    }),
)
