import React, { Attributes, useCallback, useMemo, useState } from 'react'
import { Namespace, UseTranslationOptions, UseTranslationResponse } from 'react-i18next'
import { Currency, Fraction } from '@dolomite-exchange/sdk-core'
import { CurrencyAmount, Rounding, Token } from '@dolomite-exchange/v2-sdk'
import { ChainId, FORMATTER, ONE_BIPS, ONE_FRACTION, WETH, ZERO_FRACTION, ZERO_PERCENT } from '../../constants'
import { InterestRate } from '../../types/interestRateData'
import { MarketRiskInfo } from '../../types/marketRiskInfoData'
import { TokenTvl } from '../../types/dolomiteMarginTokenTvlData'
import { useHistory } from 'react-router-dom'
import cleanCurrencySymbol from '../../utils/cleanCurrencySymbol'
import calculatePercentageChange from '../../utils/calculatePercentageChange'
import getLogoOrDefault from '../common/TokenLogos'
import { StyledTooltip, StyledTooltipWithIcon } from '../common/StyledTooltip'
import ImportContactsIcon from '@mui/icons-material/ImportContacts'
import cleanCurrencyName from '../../utils/cleanCurrencyName'
import { formatAmount } from '../../utils/formatAmount'
import AllInclusiveIcon from '@material-ui/icons/AllInclusive'
import AddCircleIcon from '@material-ui/icons/AddCircleOutline'
import RemoveCircleOutlineIcon from '@material-ui/icons/RemoveCircleOutline'
import styled from 'styled-components/macro'
import AutoAwesomeIcon from '@mui/icons-material/AutoAwesome'
import {
  BalanceColumn,
  BorrowColumn,
  ButtonsColumn,
  NoTradingIcon,
  SupplyColumn,
  TickerColumn,
  TickerIcons,
  WalletBalanceColumn,
  YieldTooltip,
} from './BalancesPanel'
import { YieldBreakdown } from '../../pages/Strategies/CreatePositionModal'
import CurrencyExchangeIcon from '@mui/icons-material/CurrencyExchange'
import calculateLiquidationThreshold from '../../utils/calculateLiquidationThreshold'
import calculateLiquidationReward from '../../utils/calculateLiquidationReward'
import { useDolomiteMarginData } from '../../types/dolomiteMarginData'
import { ChartButton, getInterestRateDataForUtilization } from '../../pages/Stats/TokenStats'
import calculateUtilization from '../../utils/calculateUtilization'
// import GLVLogo from '../../assets/markets/GLV.png'
import EmojiEventsIcon from '@mui/icons-material/EmojiEvents'
import ClimbingBearImg from '../../assets/images/climbing-bear.png'
import Checkmark from '@material-ui/icons/Check'
import { X } from 'react-feather'
import FlameIcon from '../../assets/images/flame-icon-colored.svg'
import EmodeAvailableModal from '../Emode/EmodeAvailableModal'
import { isValidDebtTokenForSingleCollateralRiskCategory, RiskOverrideStruct } from '../../utils/emode'
import { EmodeInfoSection } from './EmodeInfoSection'

const EarnRow = styled.div<{
  expanded: boolean
  hasExpandedContent: boolean | undefined
  isBera?: boolean
  isEmodeCompatible: boolean
}>`
  //padding: 8px 35px;
  //background: #292938;
  //width: calc(100% + 70px);
  height: ${({ expanded, hasExpandedContent }) => (expanded ? (hasExpandedContent ? '316px' : '296px') : '75px')};
  transition: height 0.2s ease-in-out;
  /*max-height: ${({ expanded, hasExpandedContent }) =>
    hasExpandedContent ? (expanded ? '420px' : '95px') : expanded ? '400px' : '75px'};
  transition: max-height 0.3s ease-in-out;*/
  /*overflow: hidden;*/
  border-radius: 8px;
  overflow: ${({ isBera }) => (isBera ? 'unset' : 'hidden')};
  //margin: 8px 0;
  /*border-bottom: 1px solid ${({ theme }) => theme.bg3};*/
  display: inline-block;
  /*padding: 15px 35px 10px 35px;*/
  padding: 0;
  margin: 5px 0 5px -35px;
  vertical-align: top;
  width: calc(100% + 70px);
  font-family: 'Open Sans', sans-serif;
  position: relative;
  background-color: #292938;
  box-shadow: 0 5px 5px -3px rgb(0 0 0 / 20%), 0 8px 10px 1px rgb(0 0 0 / 14%), 0 3px 14px 2px rgb(0 0 0 / 12%);
  //box-shadow: 0 5px 5px -3px rgb(0 0 0 / 20%), 0 8px 10px 1px rgb(0 0 0 / 14%), 0 3px 14px 2px rgb(0 0 0 / 12%);
  // :hover {
  //   background: ${({ theme }) => theme.bg2};
  // }

  > div:nth-child(1) {
    cursor: ${({ hasExpandedContent }) => (hasExpandedContent ? 'pointer' : 'default')};
  }

  @media screen and (max-width: 1200px) {
    height: ${({ expanded, isEmodeCompatible, hasExpandedContent }) =>
      expanded
        ? hasExpandedContent
          ? isEmodeCompatible
            ? '496px'
            : '309px'
          : isEmodeCompatible
          ? '476px'
          : '289px'
        : isEmodeCompatible
        ? '75px'
        : '66px'};
  }
  
  @media screen and (max-width: 1000px) {
    height: ${({ expanded, isEmodeCompatible, hasExpandedContent }) =>
      expanded
        ? hasExpandedContent
          ? isEmodeCompatible
            ? '496px'
            : '309px'
          : isEmodeCompatible
          ? '476px'
          : '289px'
        : '66px'};
  }
  
  @media screen and (max-width: 800px) {
    height: ${({ expanded, isEmodeCompatible, hasExpandedContent }) =>
      expanded
        ? hasExpandedContent
          ? isEmodeCompatible
            ? '500px'
            : '475px'
          : isEmodeCompatible
          ? '480px'
          : '455px'
        : '66px'};
  }

  @media screen and (max-width: 650px) {
    height: ${({ expanded, isEmodeCompatible, hasExpandedContent }) =>
      expanded
        ? hasExpandedContent
          ? isEmodeCompatible
            ? '840px'
            : '650px'
          : isEmodeCompatible
          ? '820px'
          : '630px'
        : '56px'};
  }

  @media screen and (max-width: 500px) {
    height: ${({ expanded, isEmodeCompatible, hasExpandedContent }) =>
      expanded
        ? hasExpandedContent
          ? isEmodeCompatible
            ? '840px'
            : '650px'
          : isEmodeCompatible
          ? '820px'
          : '630px'
        : '52px'};
  }
  
  /*@media screen and (max-width: 500px) {
    height: ${({ expanded, hasExpandedContent }) => (expanded ? '630px' : '52px')};
  }*/

  @media screen and (max-width: 400px) {
    height: ${({ expanded, isEmodeCompatible, hasExpandedContent }) =>
      expanded
        ? hasExpandedContent
          ? isEmodeCompatible
            ? '850px'
            : '650px'
          : isEmodeCompatible
          ? '830px'
          : '630px'
        : '64px'};
  }

  /*@media screen and (max-width: 415px) {
    padding: 15px 10px;
    height: ${({ expanded, hasExpandedContent }) => (expanded ? 'fit-content' : '60px')};
  }*/
`

const ClimbingBear = styled.img`
  width: 90px;
  position: absolute;
  right: -27px;
  z-index: 2;
  top: 5px;
  transform: rotate(6deg);
  transition: top 1s cubic-bezier(0.82, 0, 0.86, 1.01);

  @media screen and (max-width: 1000px) {
    width: 70px;
    right: -22px;
  }

  @media screen and (max-width: 650px) {
    width: 50px;
    right: -15px;
  }

  @media screen and (max-width: 600px) {
    width: 40px;
    right: -13px;
  }
`

const EarnTopRow = styled.div<{ isExpanded: boolean }>`
  cursor: pointer !important;
  padding: 18px 35px 19px 35px;
  border-radius: ${({ isExpanded }) => (isExpanded ? '8px 8px 0 0' : '8px')};
  transition: border-radius 0.2s ease-in-out;

  :hover {
    background: ${({ theme }) => theme.bg2};
  }

  @media screen and (max-width: 1000px) {
    padding: 15px 25px;
  }

  @media screen and (max-width: 650px) {
    padding: 10px 15px;
  }

  @media screen and (max-width: 500px) {
    padding: 8px 15px 8px 10px;
  }

  @media screen and (max-width: 400px) {
    display: flex;
    align-items: center;
    height: 64px;
  }
`

const BottomLine = styled.div`
  width: calc(100% - 70px);
  margin-left: 35px;
  height: 1px;
  background-color: ${({ theme }) => theme.bg3};
  position: absolute;
  bottom: 0;
  display: none;
`

const ExpandedSection = styled.div<{ expanded: boolean }>`
  width: 100%;
  display: ${({ expanded }) => (expanded ? 'block' : 'none')};
  /*margin-top: 10px;*/
  margin-top: 0;
  padding: 0 0px;
  position: relative;
  height: 100%;
`

const ExpandedWrapper = styled.div`
  width: 100%;
  height: 100%;
  position: relative;
`

const ChartSection = styled.div<{ narrow?: boolean }>`
  width: ${({ narrow }) => (narrow ? 28 : 36)}%;
  display: inline-block;
  vertical-align: top;

  ${({ narrow }) =>
    narrow
      ? `
  @media screen and (max-width: 1200px) {
    width: 40%;
    float: right;
    margin-right: 2%;
  }

  @media screen and (max-width: 800px) {
    width: 50%;
  }
  @media screen and (max-width: 650px) {
    float: unset;
    width: 90%;
    max-width: 400px;
    margin: 0;
    padding: 20px 0 0;
  }
  @media screen and (max-width: 480px) {
    transform: translateX(6px);
  }
  @media screen and (max-width: 360px) {
    padding: 0 20px;
  }
  @media screen and (max-width: 330px) {
    padding: 0 15px;
  }
  @media screen and (max-width: 280px) {
    padding: 0 10px;
  }
  `
      : `
  @media screen and (max-width: 800px) {
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    padding: 0 35px;
    margin: -10px auto 0;

    > div {
      border: none;
    }
  }
  @media screen and (max-width: 650px) {
    position: relative;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
  }
  @media screen and (max-width: 420px) {
    width: 100%;
    margin-right: 0;
    padding: 0 25px;
  }
  @media screen and (max-width: 360px) {
    padding: 0 20px;
  }
  @media screen and (max-width: 330px) {
    padding: 0 15px;
  }
  @media screen and (max-width: 280px) {
    padding: 0 10px;
  }
  `}
`

const DataSection = styled.div<{ narrow?: boolean }>`
  width: ${({ narrow }) => (narrow ? 20 : 25)}%;
  display: inline-block;
  vertical-align: top;
  padding-top: 30px;
  padding-right: 27px;

  ${({ narrow }) =>
    narrow
      ? `
    @media screen and (max-width: 1200px) {
    width: 40%;
    margin-right: 2%;
    padding-top: 15px;
  }

  @media screen and (max-width: 1050px) {
    padding-left: 0;
  }
  @media screen and (max-width: 900px) {
    padding-top: 15px;
  }
  @media screen and (max-width: 700px) {
    width: 35%
  }
  @media screen and (max-width: 650px) {
    width: 90%;
    max-width: 400px;
    margin: 0;
    padding: 20px 0 0;
  }
  @media screen and (max-width: 360px) {
    padding: 15px 20px 10px;
  }
  @media screen and (max-width: 330px) {
    padding: 10px 15px 8px;
  }
  @media screen and (max-width: 280px) {
    padding: 8px 10px 5px;
  }
    `
      : `
    @media screen and (max-width: 1050px) {
    padding-left: 0;
  }
  @media screen and (max-width: 950px) {
    width: 28%;
  }
  @media screen and (max-width: 900px) {
    padding-top: 15px;
  }
  @media screen and (max-width: 800px) {
    position: absolute;
    left: 30px;
    top: 175px;
    width: calc(50% - 50px);
  }
  @media screen and (max-width: 650px) {
    width: 100%;
    position: relative;
    padding: 15px 35px;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
  }
  @media screen and (max-width: 420px) {
    padding: 15px 25px 10px;
  }
  @media screen and (max-width: 360px) {
    padding: 15px 20px 10px;
  }
  @media screen and (max-width: 330px) {
    padding: 10px 15px 8px;
  }
  @media screen and (max-width: 280px) {
    padding: 8px 10px 5px;
  }
  `}
`

const DataSectionRight = styled(DataSection)`
  padding-left: 22px;
  padding-top: ${({ narrow }) => (narrow ? 30 : 15)}px !important;
  padding-right: 0;
  width: ${({ narrow }) => narrow && '18%'};

  ${({ narrow }) =>
    narrow
      ? `@media screen and (max-width: 1200px) {
    width: 40%;
    margin-right: 0;
    margin-left: 2%;
  }

  @media screen and (max-width: 800px) {
    width: 30%;
  }
  @media screen and (max-width: 650px) {
    width: 90%;
    max-width: 400px;
    margin: 0;
    padding: 20px 0 0;
  }`
      : `
  @media screen and (max-width: 800px) {
    position: absolute;
    right: 30px;
    left: unset;
    top: 175px;
    width: calc(50% - 50px);
    padding-top: 15px;
  }
  @media screen and (max-width: 650px) {
    width: 100%;
    position: relative;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
  }
  `}
`

const InfoIcons = styled.div`
  width: 100%;
  margin-top: 15px;
  text-align: right;
  display: inline-block;
  vertical-align: top;
`

const BottomData = styled.div`
  width: 100%;
  display: flex;
  margin-top: 25px;
  justify-content: space-around;
`

const ButtonWrapper = styled.div`
  /*width: 100%;
  height: 40px;
  margin-bottom: 15px;*/
  width: 20%;
  display: inline-block;
  vertical-align: top;

  @media screen and (max-width: 1000px) {
    width: 175px;
  }

  @media screen and (max-width: 750px) {
    display: none;
  }

  @media screen and (max-width: 620px) {
    display: none;
  }
`

const ButtonWrapperSmall = styled.div`
  display: none;
  /*@media screen and (max-width: 620px) {
    display: inline-block;
    width: 100%;
    text-align: left;
    margin-bottom: 5px;
  }
  @media screen and (max-width: 560px) {
    margin-top: 5px;
    margin-bottom: 10px;
  }*/
`

const TokenDataLeft = styled.div`
  text-align: left;
  width: 50%;
  display: inline-block;
  vertical-align: top;
`

const TokenDataRight = styled(TokenDataLeft)`
  text-align: right;
`

const WalletBalanceColumnSmall = styled.div`
  text-align: right;
  width: 30%;
  @media screen and (max-width: 620px) {
    display: inline-block;
    float: right;
  }
`

const BorrowColumnSmall = styled.div`
  text-align: right;
  display: none;
  @media screen and (max-width: 600px) {
    display: inline-block;
    float: right;
  }
`

const TokenData = styled.div`
  margin-bottom: 10px;
  width: 100%;
  height: 16px;

  @media screen and (max-width: 650px) {
    margin-bottom: 6px;
    height: 15px;
  }

  /*@media screen and (max-width: 560px) {
    margin-bottom: 4px;
    height: 14px;
  }

  @media screen and (max-width: 480px) {
    margin-bottom: 2px;
  }*/
`

const ChartWrapper = styled.div`
  width: 100%;
  margin-top: 10px;
    //background-color: ${({ theme }) => theme.bg2};
  padding: 0px 30px 10px 40px;
  position: relative;
  //border-radius: 8px;
  border-left: 2px solid ${({ theme }) => theme.bg2};
  border-right: 2px solid ${({ theme }) => theme.bg2};

  > div > div {
    height: 100px;
    margin-top: 60px;

    > div:nth-of-type(1) {
      font-size: 12px;
      font-weight: 600;
      top: -40px;
    }
  }

  @media screen and (max-width: 1200px) {
    border: none;
    padding-left: 20px;
    padding-right: 20px;
  }

  /*@media screen and (max-width: 850px) {
    
  }*/
  /*@media screen and (max-width: 800px) {
    padding: 0;
    margin: 0;
    border-bottom: 1px solid ${({ theme }) => theme.text3} !important;

    > div > div {
      width: 110% !important;
    }
  }*/
  /*@media screen and (max-width: 620px) {
    border-right: none !important;
    padding: 10px 0 5px 15px;
  }  */
  @media screen and (max-width: 650px) {
    border: none !important;
    /*padding: 10px 0 5px 15px;*/
    padding: 0;
    > div > div {
      width: 100% !important;
    }
  }

  @media screen and (max-width: 480px) {
    border: none !important;
    /*padding: 10px 0 5px 15px;*/
    padding: 0;
    > div > div {
      width: 95% !important;
    }
  }

  @media screen and (max-width: 420px) {
    border: none !important;
    /*padding: 10px 0 5px 15px;*/
    padding: 0;
    > div > div {
      width: 90% !important;
    }
  }
  /*@media screen and (max-width: 450px) {
    padding: 10px 0 5px 15px;
  }*/
`

const UtilChartWrapper = styled(ChartWrapper)`
  width: 100%;
  margin-top: 20px;
  padding: 20px 0 0 0;
  //padding-right: 25px;
  //margin-left: -30px;
  @media screen and (max-width: 450px) {
    padding: 15px 0 0 0;
  }
`

const ChartButtonsWrapper = styled.div`
  position: absolute;
  top: 15px;
  right: 20px;
  z-index: 5;

  > button {
    padding: 3px 6px;
  }

  @media screen and (max-width: 950px) {
    right: 0;
  }
`

const TokenLogo = styled.div<{ expanded?: boolean }>`
  display: inline-block;
  margin-right: 7px;
  margin-top: ${({ expanded }) => (expanded ? '8px' : '2px')};
  vertical-align: top;
  height: ${({ expanded }) => (expanded ? '30px' : '30px')};
  width: 40px;
  text-align: center;

  img {
    height: 100%;
  }

  @media screen and (max-width: 650px) {
    width: 30px;
    height: 25px;
    margin-right: 6px;
    margin-top: 5px;
  }

  /*@media screen and (max-width: 415px) {
    max-height: 22px;
  }*/
`

const TickerWrapper = styled.div<{ pointer?: boolean }>`
  display: inline-block;
  vertical-align: top;
  ${({ pointer }) => pointer && `cursor: pointer !important`};
  margin-top: 1px;

  @media screen and (max-width: 1000px) {
    margin-top: 1px;
  }

  @media screen and (max-width: 650px) {
    margin-top: 2px;
    margin-right: -80px;
  }

  @media screen and (max-width: 415px) {
    width: calc(80% - 30px);
  }
`

const Ticker = styled.div<{ expanded?: boolean }>`
  color: #f9f9f9;
  font-size: ${({ expanded }) => (expanded ? '24px' : '17px')};
  font-weight: 500;
  cursor: pointer;

  img {
    height: 15px;
    display: inline-block;
    vertical-align: top;
    margin-left: 3px;
    margin-top: 3px;
  }

  @media screen and (max-width: 1000px) {
    font-size: 15px;
  }

  a {
    color: #8f91ad;

    svg {
      font-size: 18px !important;
    }
  }

  @media screen and (max-width: 650px) {
    font-size: 13px;
  }

  /*@media screen and (max-width: 600px) {
    font-size: 11px;

    a {
      svg {
        font-size: 16px !important;
        margin-top: 1px;
      }
    }
  }*/

  @media screen and (max-width: 415px) {
    font-size: 12px;
  }
`

const Name = styled.div<{ expanded?: boolean }>`
  font-size: ${({ expanded }) => (expanded ? '13px' : '12px')};
  /*font-weight: 100;*/
  margin-top: -3px;
  font-weight: 400;
  color: ${({ theme }) => theme.text3};

  @media screen and (max-width: 1000px) {
    font-size: 11px;
  }

  @media screen and (max-width: 650px) {
    font-size: 10px;
    width: 100%;
    margin-top: -2px;
  }

  @media screen and (max-width: 500px) {
    letter-spacing: -0.3px;
  }

  @media screen and (max-width: 420px) {
    font-size: 11px;
    max-width: 90px;
    letter-spacing: -0.4px;
  }
`

const RateWrapper = styled.div<{ expanded?: boolean; isBorrow?: boolean }>`
  color: ${({ theme, isBorrow }) => (isBorrow ? theme.text1 : '#8fc942')};
  font-weight: ${({ isBorrow }) => (isBorrow ? 500 : 600)};
  /*margin-top: 2px;*/
  font-size: ${({ expanded }) => (expanded ? '18px' : '16px')};
  line-height: 36px;

  @media screen and (max-width: 1000px) {
    font-size: 15px;
  }

  @media screen and (max-width: 650px) {
    font-size: 14px;
  }

  @media screen and (max-width: 500px) {
    font-size: 13px;
  }
`

const BorrowData = styled(TokenData)`
  display: none;

  ${RateWrapper} {
    font-size: 13px !important;
    line-height: unset !important;
  }

  @media screen and (max-width: 900px) {
    display: block;
  }
`

const OutsideRate = styled.div`
  color: ${({ theme }) => theme.blue2};
`

const OutsideRateWrapper = styled(RateWrapper)`
  color: ${({ theme }) => theme.blue2};
  width: fit-content;
  display: inline-block;

  @media screen and (max-width: 1000px) {
    font-size: 15px;
  }

  @media screen and (max-width: 650px) {
    font-size: 14px;
  }

  @media screen and (max-width: 500px) {
    font-size: 13px;
  }

  @media screen and (max-width: 400px) {
    svg {
      width: 12px !important;
      height: 12px !important;
      margin-left: 2px !important;
    }
  }

  /*@media screen and (max-width: 415px) {
    transform: translateY(-4px);
    margin-left: -20px;
  }*/
`

const Balance = styled.div<{ expanded?: boolean }>`
  font-size: ${({ expanded }) => (expanded ? '18px' : '16px')};
  font-weight: 500;
  width: fit-content;
  display: inline-block;
  vertical-align: top;

  @media screen and (max-width: 1000px) {
    font-size: 15px;
    margin-top: 1px;
  }

  @media screen and (max-width: 650px) {
    font-size: 13px;
    margin-top: 2px;
  }

  @media screen and (max-width: 450px) {
    font-size: 12px;
    direction: ltr;
    margin-top: 3px;
  }
`

const DollarBalance = styled.div`
  font-weight: 400;
  font-size: 13px;
  color: ${({ theme }) => theme.text3};
  margin-top: -2px;

  @media screen and (max-width: 840px) {
    font-size: 12px;
    margin-top: -4px;
  }

  @media screen and (max-width: 650px) {
    font-size: 10px;
    margin-top: -4px;
  }

  @media screen and (max-width: 380px) {
    font-size: 9px;
    margin-top: -6px;
  }
`

const OracleIcon = styled.div`
  position: absolute;
  font-size: 11px !important;
  font-weight: 700;
  top: -6px;
  right: -14px;
  height: 16px;
  width: 16px;
  background-color: ${({ theme }) => theme.text2};
  color: ${({ theme }) => theme.bg1};
  border-radius: 50%;
  padding-left: 1px;
`

const OracleTooltipPath = styled.div`
  color: ${({ theme }) => theme.text2};
  text-align: left;
  font-size: 13px;
  font-weight: 500;
  margin-bottom: 2px;
`

const RewardsText = styled.div<{ longText?: boolean }>`
  color: ${({ theme }) => theme.text1};
  font-size: ${({ longText }) => (longText ? 11 : 12)}px;
  margin-top: -1px;

  @media screen and (max-width: 600px) {
    font-size: 11px;
  }

  @media screen and (max-width: 415px) {
    font-size: 10px;
    margin-top: -6px;
  }
`

const BottomSection = styled.div<{ isExpanded: boolean }>`
  width: calc(100% - 70px);
  margin-left: 35px;
  height: fit-content;
  text-align: right;
  display: inline-block;
  /*float: right;*/
  margin-right: 35px;
  transform: translateY(${({ isExpanded }) => (isExpanded ? 0 : 8)}px);
  transition: all 0.2s ease-in-out;

  @media screen and (max-width: 1000px) {
    width: calc(100% - 50px);
    margin-left: 25px;
  }

  @media screen and (max-width: 625px) {
    margin-top: 15px;
    height: auto;
  }

  @media screen and (max-width: 415px) {
    margin-top: 12px;
  }
`

const StyledAutoAwesomeIcon = styled(AutoAwesomeIcon)`
  display: inline !important;
  width: 15px !important;
  height: 15px !important;
  margin-left: 3px;
  margin-bottom: -1px;

  path {
    fill: ${({ theme }) => theme.blue2};
  }

  @media screen and (max-width: 415px) {
    margin-bottom: -2px;
  }
`

const SupplyCapBar = styled.div`
  position: relative;
  width: 100%;
  height: 5px;
  margin-top: 0px;
  background-color: ${({ theme }) => theme.bg6} !important;
  border-radius: 2.5px;
`

const SupplyBar = styled.div<{ progress: number }>`
  position: absolute;
  left: 0;
  background-color: ${({ theme }) => theme.text2} !important;
  width: ${({ progress }) => progress}%;
  height: 100%;
  border-radius: 2.5px;
`

const BorrowBar = styled(SupplyBar)`
  position: absolute;
  left: 0;
  background-color: ${({ theme }) => theme.blue2} !important;
`

const BorrowCapLine = styled.div<{ left: number }>`
  height: 10px;
  width: 1px;
  position: absolute;
  background-color: ${({ theme }) => theme.white} !important;
  top: -2px;
  left: ${({ left }) => left}%;
`

const BarWrapper = styled.div`
  width: 75%;
  margin-left: 25%;
  margin-top: 3px;
`

const BarValues = styled.div`
  width: fit-content;
  display: inline-block;
  vertical-align: top;
  margin-bottom: 4px;
  text-align: right;
  font-size: 16px;
  font-weight: 400;
  cursor: pointer;
`

const BarValue = styled.div<{ darken?: boolean }>`
  //width: 33%;
  display: inline-block;
  text-align: center;
  color: ${({ theme, darken }) => darken && theme.text3} !important;
`

const Supplied = styled.span<{ capped?: boolean }>`
  color: ${({ theme, capped }) => capped && theme.yellow1} !important;
`

const BarValueCap = styled.span`
  color: ${({ theme }) => theme.text3} !important;
`

const Separator = styled.span`
  //float: right;
  margin: 0 5px;
`

const Paranthesis = styled.span`
  margin-left: 3px;
`

const Lines = styled.div`
  width: 80%;
  margin-left: 20%;
  height: 6px;
  position: relative;
`

const ShortLine = styled.div<{ start: number }>`
  display: inline-block;
  position: absolute;
  left: ${({ start }) => start}%;
  width: 1px;
  height: 2px;
  background-color: ${({ theme }) => theme.white};
`

const TallLine = styled(ShortLine)`
  height: 3px;
`

const FlatLine = styled.div<{ start: number; width: number }>`
  display: inline-block;
  position: absolute;
  width: ${({ width }) => width}%;
  left: ${({ start }) => start}%;
  height: 1px;
  background-color: ${({ theme }) => theme.white};
`

const InfinityWrapper = styled.span`
  height: 12px;
  margin-left: 3px;

  svg {
    height: 0.6em;
    width: 0.6em;
    margin-bottom: -3px;
  }
`

const SupplyTooltipLine = styled.div`
  margin-bottom: 3px;
`

const SupplyTooltipTitle = styled.span`
  font-weight: 600;
  margin-right: 3px;
`

const CurrencySymbol = styled.span`
  color: ${({ theme }) => theme.text2};
  margin-left: 3px;
`

const Price = styled.div`
  font-size: 16px;
  font-weight: 400;
`

const ChangeColor = styled.div`
  font-size: 10px;
  font-weight: 400;
  color: ${({ theme }) => theme.green2};
  margin-top: -4px;
  margin-bottom: 2px;
  position: absolute;
  bottom: 1px;
  right: 0;
`

const Green = styled(ChangeColor)`
  color: ${({ theme }) => theme.green2};
`

const Red = styled(ChangeColor)`
  color: ${({ theme }) => theme.red1};
`

const Text = styled.span`
  font-size: 12px;
  font-weight: 500;
  color: ${({ theme }) => theme.text3};
  display: inline-block;
`

const WalletText = styled(Text)`
  margin-right: 20px;
`

const TvlText = styled(Text)`
  //margin-right: 20px;
`

const BorrowIcon = styled(CurrencyExchangeIcon)`
  color: ${({ theme }) => theme.text1};
  width: 24px !important;
  height: 24px !important;
  vertical-align: top;
  margin-top: 2px;
`

const RewardsIcon = styled(EmojiEventsIcon)`
  color: ${({ theme }) => theme.text1};
  margin-left: 5px;
  width: 28px !important;
  height: 28px !important;
  vertical-align: top;
`

const CategoryIcon = styled.div`
  display: inline-block;
  margin-left: 5px;
  vertical-align: top;
  margin-top: 2px;
  img {
    height: 23px;
    color: ${({ theme }) => theme.text1};
  }
`

const GMIcon = styled.div`
  display: inline-block;
  img {
    height: 24px;
    color: ${({ theme }) => theme.text1};
  }
`

const GLPIcon = styled.div`
  img {
    height: 24px;
    color: ${({ theme }) => theme.text1};
  }
`

const PendleIcon = styled.div`
  img {
    height: 24px;
    color: ${({ theme }) => theme.text1};
  }
`

const GLVIcon = styled.div`
  img {
    height: 24px;
    color: ${({ theme }) => theme.text1};
  }
`

const DepositWithdrawButtons = styled.div`
  width: 100%;
  vertical-align: top;
`

const DepositWithdrawButtonsSmall = styled(DepositWithdrawButtons)`
  width: auto;
  display: inline-block;
`

const Button = styled.div`
  width: fit-content;
  text-align: center;
  margin-top: 2px;
  padding: 7px 12px;
  height: 33px;
  border-radius: 5px;
  color: ${({ theme }) => theme.text1};
  cursor: pointer;
  font-weight: 500;
  font-size: 14px;
  display: inline-block;
  text-decoration: none;
  vertical-align: top;

  svg {
    color: ${({ theme }) => theme.text1};
    margin-right: 0px;
    margin-left: 3px;
    margin-top: 1px;
    font-size: 17px;
    @media screen and (max-width: 450px) {
      display: none;
    }
  }
  background-color: ${({ theme }) => '#3a3a4f'};
  :hover {
    background-color: ${({ theme }) => '#474956'};
  }
  @media screen and (max-width: 370px) {
    font-size: 12px;
  }
`

const StatsButton = styled(Button)`
  float: left;
`

const ActionButton = styled(Button)`
  margin-left: 10px;
  float: right;
  font-size: 13px;
  line-height: 19px;

  @media screen and (max-width: 620px) {
    float: left;
    margin-left: 0;
    margin-right: 10px;
  }
  @media screen and (max-width: 450px) {
    padding: 4px 8px;
    height: 24px;
    font-size: 12px;
  }
`

const TokenTitle = styled.div`
  color: ${({ theme }) => theme.text3};
  font-size: 13px;
  font-weight: 500;
  position: relative;
  display: inline-block;
  float: left;
  text-align: left;

  @media screen and (max-width: 380px) {
    font-size: 12px;
  }
`

const TokenTitleSmall = styled.div`
  color: ${({ theme }) => theme.text3};
  font-size: 11px;
`

const InfoTitle = styled(TokenTitle)`
  margin-bottom: 5px;
`

const TokenValue = styled.div`
  color: ${({ theme }) => theme.text1};
  font-size: 13px;
  font-weight: 400;
  display: inline-block;
  position: relative;
  float: right;

  @media screen and (max-width: 450px) {
    font-size: 12px;
  }
`

const TokenAmount = styled.div`
  color: ${({ theme }) => theme.text3};
  font-size: 14px;
  margin-top: -4px;
  margin-bottom: 2px;
`

const Change = ({ value }: { value: Fraction }) => {
  const positive = value.greaterThanOrEqual('0')
  if (positive) {
    return <Green>+{value.toFixed(2, FORMATTER)}%</Green>
  } else {
    return <Red>-{value.toFixed(2, FORMATTER).replace('-', '')}%</Red>
  }
}

const ChartLabel = styled.div`
  text-align: left;
  margin-left: 30px;
  margin-bottom: -60px;
  @media screen and (max-width: 450px) {
    margin-left: 15px;
  }
`

const ProgressCircleWrapper = styled.div`
  width: fit-content;
  display: inline-block;
  vertical-align: top;
  text-align: center;
  margin-top: 7px;
  margin-bottom: 5px;
`

const CircleWrapper = styled.div<{ large?: boolean }>`
  width: ${({ large }) => (large ? 100 : 50)}%;
  display: inline-block;
  vertical-align: top;
  text-align: center;
`

const CircleText = styled.div`
  width: 100%;
  font-size: 14px;
`
const ProgressCircle = styled.div<{ degrees: number }>`
  background: conic-gradient(
    ${({ theme }) => theme.blue1} ${({ degrees }) => degrees}deg,
    ${({ theme }) => theme.bg6} 0deg
  );
  background: ${({ theme, degrees }) => degrees === 0 && `${theme.bg6} !important`};
  border-radius: 50%;
  height: 80px;
  width: 80px;
  padding: 5px;
  margin: 4px auto;
`

const ProgressInner = styled.div`
  border-radius: 50%;
  height: 70px;
  width: 70px;
  background-color: #292938;
  text-align: center;
  padding-top: 25px;
  font-size: 16px;
  font-weight: 500;
  color: ${({ theme }) => theme.text1};
`

const TvlValue = styled.div`
  color: ${({ theme }) => theme.text1};
  font-size: 14px;
  display: block;
  vertical-align: bottom;
`

const CapValue = styled.div`
  color: ${({ theme }) => theme.text3};
  font-size: 11px;
  /*margin-left: 3px;*/
  display: block;
  vertical-align: top;
  margin-top: -1px;
  margin-left: -3px;
`

const ButtonText = styled.div`
  display: inline-block;
  vertical-align: top;
  font-weight: 500;
`

const ExpandedContentWrapper = styled.div`
  height: fit-content;

  @media screen and (max-width: 750px) {
    text-align: center;
  }
`

export enum ChartTypes {
  Supply,
  Borrow,
}

interface ExpandedRowProps extends Attributes {
  t: (ns?: Namespace, options?: UseTranslationOptions) => UseTranslationResponse
  currencyOrToken: Currency | Token
  chainId: ChainId
  interestRateData: InterestRate | undefined
  priceData: Fraction | undefined
  dolomiteBalanceData: CurrencyAmount<Token> | undefined
  dolomiteFiatData: Fraction | undefined
  walletBalanceData: CurrencyAmount<Currency> | undefined
  walletFiatData: Fraction | undefined
  marketRiskData: MarketRiskInfo | undefined
  tokenTvlData: TokenTvl | undefined
  previousPriceData: Fraction | undefined
  hasRewards: boolean
  hasPowder: boolean
  hasRewardStation: boolean
  hasMinerals: boolean
  hasPoints: boolean
  expanded: boolean
  isBorrowingEnabled: boolean
  docsLink: string | undefined
  expandedContent: JSX.Element | undefined
  setPopoverComponent: React.Dispatch<React.SetStateAction<JSX.Element | undefined>>
  setCustomPopoverWidth?: (width: number) => void
  showYieldAsApr: boolean
  depositModal: (presetCurrency?: Currency) => void
  withdrawModal: (presetCurrency?: Currency) => void
  isEmodeCompatible: boolean
  emodeInfo: RiskOverrideStruct[] | undefined
}

function ExpandedRowComparator(prevProps: ExpandedRowProps, nextProps: ExpandedRowProps) {
  return (
    prevProps.currencyOrToken.symbol === nextProps.currencyOrToken.symbol &&
    prevProps.chainId === nextProps.chainId &&
    prevProps.dolomiteBalanceData?.toFixed(6) === nextProps.dolomiteBalanceData?.toFixed(6) &&
    prevProps.dolomiteFiatData?.toFixed(2) === nextProps.dolomiteFiatData?.toFixed(2) &&
    prevProps.walletBalanceData?.toFixed(6) === nextProps.walletBalanceData?.toFixed(6) &&
    prevProps.walletFiatData?.toFixed(2) === nextProps.walletFiatData?.toFixed(2) &&
    prevProps.priceData?.toFixed(2) === nextProps.priceData?.toFixed(2) &&
    prevProps.dolomiteFiatData?.toFixed(2) === nextProps.dolomiteFiatData?.toFixed(2) &&
    prevProps.expanded === nextProps.expanded &&
    prevProps.showYieldAsApr === nextProps.showYieldAsApr
  )
}

const ExpandedRow = React.memo(function ExpandedRowInner({
  t,
  currencyOrToken,
  chainId,
  interestRateData,
  priceData,
  walletBalanceData,
  walletFiatData,
  marketRiskData,
  tokenTvlData,
  previousPriceData,
  hasRewards,
  hasPowder,
  hasRewardStation,
  hasMinerals,
  hasPoints,
  expanded,
  isBorrowingEnabled,
  docsLink,
  // setExpanded,
  expandedContent,
  setPopoverComponent,
  setCustomPopoverWidth,
  showYieldAsApr,
  depositModal,
  withdrawModal,
  isEmodeCompatible,
  emodeInfo,
}: ExpandedRowProps) {
  const { data: dolomiteMargin } = useDolomiteMarginData()
  const [emodeModalOpen, setEmodeModalOpen] = useState(false)
  const tokenAddress = currencyOrToken.wrapped.address
  const history = useHistory()
  const supplyInterestRate = interestRateData?.supplyInterestRate
  const totalSupplyInterestRate = interestRateData?.totalSupplyInterestRate
  const supplyInterestRateParts = interestRateData?.outsideSupplyInterestRateParts
  const borrowInterestRate = interestRateData?.borrowInterestRate
  const totalBorrowInterestRate = interestRateData?.totalBorrowInterestRate
  const borrowInterestRateParts = interestRateData?.outsideBorrowInterestRateParts
  const currencyKey = currencyOrToken.isNative ? currencyOrToken.symbol ?? '' : currencyOrToken.address
  // const historicalSupply = interestRateData?.historicalRates['30d'].supplyInterestRate
  // const historicalBorrow = interestRateData?.historicalRates['30d'].borrowInterestRate
  // const historicalPeriod = useMemo(() => {
  //   const date = interestRateData?.historicalRates.startTimestamp
  //   if (date === undefined) {
  //     return undefined
  //   }
  //   const today = new Date()
  //   const differenceInMs = today.getTime() - date.getTime()
  //   const differenceInDays = Math.floor(differenceInMs / (1000 * 60 * 60 * 24))
  //   return (differenceInDays < 30 ? differenceInDays : 30).toString()
  // }, [interestRateData])
  // const [loadedInterestRateData, setLoadedInterestRateData] = useState<any[] | undefined>(undefined)
  // const [interestValue, setInterestValue] = useState<number | undefined>()
  // const [interestLabel, setInterestLabel] = useState<string | undefined>()
  const [chartDataType, setChartDataType] = useState(ChartTypes.Supply)
  const liquidationThreshold = calculateLiquidationThreshold(
    dolomiteMargin?.minCollateralization,
    marketRiskData?.marginPremium,
  )
  //
  const liquidationReward = calculateLiquidationReward(
    dolomiteMargin?.liquidationReward,
    marketRiskData?.liquidationRewardPremium,
  )
  const percentageChange = useMemo(() => {
    if (!previousPriceData || !priceData) return ZERO_FRACTION
    return calculatePercentageChange(previousPriceData, priceData ?? ZERO_FRACTION)
  }, [previousPriceData, priceData])

  const borrowTvl = useMemo(() => tokenTvlData?.borrowLiquidity, [tokenTvlData?.borrowLiquidity])
  const supplyTvl = useMemo(() => tokenTvlData?.supplyLiquidity, [tokenTvlData?.supplyLiquidity])
  const supplyCap = useMemo(() => marketRiskData?.supplyMaxWei, [marketRiskData?.supplyMaxWei])
  const borrowCap = useMemo(() => marketRiskData?.borrowMaxWei, [marketRiskData?.borrowMaxWei])
  const oracleSummary = useMemo(() => marketRiskData?.oracleSummary ?? '', [marketRiskData?.oracleSummary])
  const oracleDescription = useMemo(() => marketRiskData?.oracleDescription ?? [''], [
    marketRiskData?.oracleDescription,
  ])
  const showBorrow = useMemo(() => borrowTvl && borrowTvl.greaterThan(0), [borrowTvl])
  const priceDecimals = useMemo(() => {
    if (!priceData) return 2
    if (priceData.greaterThan(ONE_FRACTION)) return 2
    else {
      if (priceData.greaterThan(new Fraction(1, 10))) return 3
      if (priceData.greaterThan(new Fraction(1, 100))) return 4
      else return 6
    }
  }, [priceData])
  // console.log(second - first)
  const handleOpenDeposit = useCallback(() => {
    depositModal(currencyOrToken)
  }, [depositModal, currencyOrToken])
  const handleOpenWithdraw = useCallback(() => {
    withdrawModal(currencyOrToken)
  }, [currencyOrToken, withdrawModal])
  //expanded content

  const interestRateChartData = useMemo(() => {
    return getInterestRateDataForUtilization(marketRiskData, interestRateData, chainId, dolomiteMargin)
  }, [chainId, marketRiskData, dolomiteMargin, interestRateData])
  // useEffect(() => {
  //   interestRateChartData && interestRateChartData.length > 1 && setLoadedInterestRateData(interestRateChartData)
  // }, [interestRateChartData])

  const supplyPercentage = useMemo(() => {
    if (!supplyCap || !supplyTvl) return 0
    return parseFloat(supplyTvl.asFraction.divide(supplyCap.asFraction).toFixed(5))
  }, [supplyTvl, supplyCap])
  const borrowPercentage = useMemo(() => {
    if (!borrowCap || !borrowTvl) return 0
    return parseFloat(borrowTvl.asFraction.divide(borrowCap.asFraction).toFixed(5))
  }, [borrowTvl, borrowCap])
  const utilization = useMemo(() => {
    return calculateUtilization(supplyTvl?.asFraction ?? ZERO_FRACTION, borrowTvl?.asFraction ?? ZERO_FRACTION)
  }, [supplyTvl, borrowTvl])
  const availableLiquidity = useMemo(() => {
    return supplyTvl?.asFraction.subtract(borrowTvl?.asFraction ?? ZERO_FRACTION)
  }, [supplyTvl, borrowTvl])
  // const currentInterestValue = useMemo(() => {
  //   return parseFloat(borrowInterestRate?.toFixed(2) ?? '0')
  // }, [borrowInterestRate])
  // const utilizationDisplay = useMemo(() => {
  //   return supplyTvl && borrowTvl ? calculateUtilization(supplyTvl.asFraction, borrowTvl.asFraction) : ZERO_PERCENT
  // }, [supplyTvl, borrowTvl])

  const handleOpenEmodeModal = useCallback(() => {
    setEmodeModalOpen(true)
  }, [])

  return (
    <ExpandedWrapper>
      <EmodeAvailableModal
        open={emodeModalOpen}
        close={() => setEmodeModalOpen(false)}
        initialCategory={emodeInfo ? emodeInfo[0] : undefined}
      />
      <ButtonWrapperSmall>
        <DepositWithdrawButtons>
          {/*<StatsButton onClick={handleOpenDeposit}>*/}
          {/*  <ButtonText>Stats</ButtonText> <TrendingUpIcon />*/}
          {/*</StatsButton>*/}
          <ActionButton
            onClick={e => {
              e.stopPropagation()
              handleOpenDeposit()
            }}
          >
            <ButtonText>Deposit </ButtonText>
            <AddCircleIcon />
          </ActionButton>
          <ActionButton
            onClick={e => {
              e.stopPropagation()
              handleOpenWithdraw()
            }}
          >
            <ButtonText>Withdraw </ButtonText>
            <RemoveCircleOutlineIcon />
          </ActionButton>
          <WalletBalanceColumnSmall>
            <TokenTitleSmall>In Wallet</TokenTitleSmall>
            {walletBalanceData && walletFiatData ? (
              <div>
                <StyledTooltip
                  title={walletBalanceData.toFixed(Math.min(currencyOrToken.decimals, 8), FORMATTER)}
                  placement={'right'}
                >
                  <Balance>
                    {walletBalanceData.greaterThan(ZERO_FRACTION) && formatAmount(walletBalanceData) === '0.000000'
                      ? '< 0.000001'
                      : formatAmount(walletBalanceData)}
                  </Balance>
                </StyledTooltip>
                <DollarBalance>{'$' + walletFiatData.toFixed(2, FORMATTER)}</DollarBalance>
              </div>
            ) : (
              <div>
                {/* TODO - if the balance is above a certain number of characters long (around 10), have it apply another css class that reduces the padding on the whole thing from 35px -> 20px so it will fit*/}
                <Balance>0.0000</Balance>
                <DollarBalance>$0.00</DollarBalance>
              </div>
            )}
          </WalletBalanceColumnSmall>
          <BorrowColumnSmall>
            <TokenTitleSmall>Borrow</TokenTitleSmall>
            {isBorrowingEnabled ? (
              borrowInterestRateParts && borrowInterestRateParts.length > 0 ? (
                <StyledTooltip
                  title={
                    <YieldTooltip
                      interestRateParts={borrowInterestRateParts}
                      lendingYield={borrowInterestRate}
                      totalSupplyYield={totalBorrowInterestRate}
                      showYieldAsApr={showYieldAsApr}
                      isBorrowRate={false}
                    />
                  }
                  placement={'top'}
                  arrow={true}
                >
                  <OutsideRateWrapper>
                    {totalBorrowInterestRate
                      ? totalBorrowInterestRate.greaterThan(ZERO_PERCENT) && totalBorrowInterestRate.lessThan(ONE_BIPS)
                        ? '< 0.01%'
                        : formatAmount(totalBorrowInterestRate)
                      : '-'}
                    <StyledAutoAwesomeIcon />
                  </OutsideRateWrapper>
                </StyledTooltip>
              ) : (
                <RateWrapper isBorrow>
                  {totalBorrowInterestRate
                    ? totalBorrowInterestRate.greaterThan(ZERO_PERCENT) && totalBorrowInterestRate.lessThan(ONE_BIPS)
                      ? '< 0.01'
                      : totalBorrowInterestRate.toFixed(2)
                    : '-'}
                  %
                </RateWrapper>
              )
            ) : (
              '-'
            )}
          </BorrowColumnSmall>
        </DepositWithdrawButtons>
      </ButtonWrapperSmall>
      <DataSection narrow={isEmodeCompatible}>
        <BorrowData>
          <TokenTitle>Borrow {showYieldAsApr ? t('apr') : t('apy')}</TokenTitle>
          <TokenValue>
            {isBorrowingEnabled ? (
              borrowInterestRateParts && borrowInterestRateParts.length > 0 ? (
                <StyledTooltip
                  title={
                    <YieldTooltip
                      interestRateParts={borrowInterestRateParts}
                      lendingYield={borrowInterestRate}
                      totalSupplyYield={totalBorrowInterestRate}
                      showYieldAsApr={showYieldAsApr}
                      isBorrowRate={false}
                    />
                  }
                  placement={'top'}
                  arrow={true}
                >
                  <OutsideRateWrapper>
                    {totalBorrowInterestRate
                      ? totalBorrowInterestRate.greaterThan(ZERO_PERCENT) && totalBorrowInterestRate.lessThan(ONE_BIPS)
                        ? '< 0.01%'
                        : formatAmount(totalBorrowInterestRate)
                      : '-'}
                    <StyledAutoAwesomeIcon />
                  </OutsideRateWrapper>
                </StyledTooltip>
              ) : (
                <RateWrapper isBorrow>
                  {totalBorrowInterestRate
                    ? totalBorrowInterestRate.greaterThan(ZERO_PERCENT) && totalBorrowInterestRate.lessThan(ONE_BIPS)
                      ? '< 0.01'
                      : totalBorrowInterestRate.toFixed(2)
                    : '-'}
                  %
                </RateWrapper>
              )
            ) : (
              '-'
            )}
          </TokenValue>
        </BorrowData>
        <TokenData>
          <TokenTitle>Oracle Price</TokenTitle>
          <TokenValue>{formatAmount(priceData, priceDecimals, true, '', true)}</TokenValue>
          {/*<Change value={percentageChange} />*/}
        </TokenData>
        <TokenData>
          <TokenTitle>Oracle</TokenTitle>
          <StyledTooltip
            title={
              <div>
                {oracleDescription.map(path => (
                  <OracleTooltipPath key={path}>{path}</OracleTooltipPath>
                ))}
              </div>
            }
            placement={'right'}
          >
            <TokenValue>
              {oracleSummary}
              <OracleIcon>{oracleDescription.length}</OracleIcon>
            </TokenValue>
          </StyledTooltip>
        </TokenData>
        <TokenData>
          <TokenTitle>{t('liquidationThreshold')}</TokenTitle>
          <TokenValue>
            {formatAmount(liquidationThreshold, 2, true, undefined, false, Rounding.ROUND_HALF_UP)}
          </TokenValue>
        </TokenData>
        <TokenData>
          <TokenTitle>{t('liquidationPenalty')}</TokenTitle>
          <TokenValue>{formatAmount(liquidationReward, 2, true, undefined, false, Rounding.ROUND_HALF_UP)}</TokenValue>
        </TokenData>
        <TokenData>
          <TokenTitle>Available Liquidity</TokenTitle>
          <TokenValue>{formatAmount(availableLiquidity, priceDecimals, true, '')}</TokenValue>
        </TokenData>
        {isBorrowingEnabled && (
          <TokenData>
            <TokenTitle>Utilization</TokenTitle>
            <TokenValue>{formatAmount(utilization, 2, false, '')}</TokenValue>
          </TokenData>
        )}
      </DataSection>
      {isEmodeCompatible && (
        <EmodeInfoSection
          chainId={chainId}
          currencyOrToken={currencyOrToken}
          marketRiskData={marketRiskData}
          setEmodeModalOpen={handleOpenEmodeModal}
        />
      )}
      <ChartSection narrow={isEmodeCompatible}>
        {/*{totalSupplyInterestRate?.greaterThan(ZERO_FRACTION) && (*/}
        <ChartWrapper>
          <YieldBreakdown
            collateralInterestRateParts={supplyInterestRateParts}
            collateralLendingYield={supplyInterestRate}
            collateralTotalYield={totalSupplyInterestRate}
            debtInterestRate={undefined}
            totalYield={true}
            debtToken={undefined}
            showYieldAsApr={showYieldAsApr}
            breakdownDisabled={true}
            strategy={undefined}
            leverage={1}
            token={currencyOrToken.wrapped}
            height={150}
            chartType={chartDataType}
          />
          {isBorrowingEnabled && (
            <ChartButtonsWrapper>
              <ChartButton
                active={(chartDataType as ChartTypes) === ChartTypes.Supply}
                onClick={(e: any) => {
                  e.stopPropagation()
                  setChartDataType(ChartTypes.Supply)
                }}
              >
                Supply
              </ChartButton>
              <ChartButton
                active={(chartDataType as ChartTypes) === ChartTypes.Borrow}
                onClick={(e: any) => {
                  e.stopPropagation()
                  setChartDataType(ChartTypes.Borrow)
                }}
              >
                Borrow
              </ChartButton>
            </ChartButtonsWrapper>
          )}
        </ChartWrapper>
      </ChartSection>
      <DataSectionRight narrow={isEmodeCompatible}>
        <CircleWrapper>
          <CircleText>Supply</CircleText>
          <ProgressCircleWrapper>
            <ProgressCircle degrees={supplyPercentage * 360}>
              <ProgressInner>{supplyPercentage >= 1 ? 100 : (supplyPercentage * 100).toFixed(1)}%</ProgressInner>
            </ProgressCircle>
          </ProgressCircleWrapper>
          <CircleText>
            <TvlValue>{formatAmount(supplyTvl, 2, true, '')}</TvlValue>
            <CapValue>
              /{' '}
              {supplyCap ? (
                formatAmount(supplyCap)
              ) : (
                <InfinityWrapper>
                  <AllInclusiveIcon />
                </InfinityWrapper>
              )}
            </CapValue>
          </CircleText>
        </CircleWrapper>
        {isBorrowingEnabled && (
          <CircleWrapper>
            <CircleText>Borrow</CircleText>
            <ProgressCircleWrapper>
              <ProgressCircle degrees={borrowPercentage * 360}>
                <ProgressInner>{(borrowPercentage * 100).toFixed(1)}%</ProgressInner>
              </ProgressCircle>
            </ProgressCircleWrapper>
            <CircleText>
              <TvlValue>{formatAmount(borrowTvl, 2, true, '')}</TvlValue>
              <CapValue>
                /
                {borrowCap ? (
                  formatAmount(borrowCap)
                ) : (
                  <InfinityWrapper>
                    <AllInclusiveIcon />
                  </InfinityWrapper>
                )}
              </CapValue>
            </CircleText>
          </CircleWrapper>
        )}
      </DataSectionRight>
      {/*<BottomData>*/}
      {/*  <div>*/}
      {/*    <TokenTitle>Supplied</TokenTitle>*/}
      {/*    <TokenValue>{formatAmount(supplyTvl, 2, true, '')}</TokenValue>*/}
      {/*  </div>*/}
      {/*  {isBorrowingEnabled && (*/}
      {/*    <div>*/}
      {/*      <TokenTitle>Borrowed</TokenTitle>*/}
      {/*      <TokenValue>{formatAmount(borrowTvl, 2, true, '')}</TokenValue>*/}
      {/*    </div>*/}
      {/*  )}*/}
      {/*  <div>*/}
      {/*    <TokenTitle>Supply Cap</TokenTitle>*/}
      {/*    <TokenValue>*/}
      {/*      {supplyCap ? (*/}
      {/*        formatAmount(supplyCap)*/}
      {/*      ) : (*/}
      {/*        <InfinityWrapperLarge>*/}
      {/*          <AllInclusiveIcon />*/}
      {/*        </InfinityWrapperLarge>*/}
      {/*      )}*/}
      {/*    </TokenValue>*/}
      {/*  </div>*/}
      {/*</BottomData>*/}
    </ExpandedWrapper>
  )
}, ExpandedRowComparator)

export interface TokenRowProps extends Attributes {
  t: (ns?: Namespace, options?: UseTranslationOptions) => UseTranslationResponse
  currencyOrToken: Currency | Token
  chainId: ChainId
  interestRateData: InterestRate | undefined
  priceData: Fraction | undefined
  dolomiteBalanceData: CurrencyAmount<Token> | undefined
  dolomiteFiatData: Fraction | undefined
  walletBalanceData: CurrencyAmount<Currency> | undefined
  walletFiatData: Fraction | undefined
  marketRiskData: MarketRiskInfo | undefined
  tokenTvlData: TokenTvl | undefined
  previousPriceData: Fraction | undefined
  hasRewards: boolean
  hasPowder: boolean
  hasRewardStation: boolean
  hasMinerals: boolean
  hasPoints: boolean
  expanded: boolean
  isBorrowingEnabled: boolean
  docsLink: string | undefined
  setExpanded: () => void
  expandedContent: JSX.Element | undefined
  setPopoverComponent: React.Dispatch<React.SetStateAction<JSX.Element | undefined>>
  setCustomPopoverWidth?: (width: number) => void
  showYieldAsApr: boolean
  depositModal: (presetCurrency?: Currency) => void
  withdrawModal: (presetCurrency?: Currency) => void
}

function TokenRowComparator(prevProps: TokenRowProps, nextProps: TokenRowProps) {
  return (
    prevProps.currencyOrToken.symbol === nextProps.currencyOrToken.symbol &&
    prevProps.chainId === nextProps.chainId &&
    prevProps.interestRateData?.supplyInterestRate?.toFixed(2) ===
      nextProps.interestRateData?.supplyInterestRate?.toFixed(2) &&
    prevProps.dolomiteBalanceData?.toFixed(6) === nextProps.dolomiteBalanceData?.toFixed(6) &&
    prevProps.dolomiteFiatData?.toFixed(2) === nextProps.dolomiteFiatData?.toFixed(2) &&
    prevProps.walletBalanceData?.toFixed(6) === nextProps.walletBalanceData?.toFixed(6) &&
    prevProps.walletFiatData?.toFixed(2) === nextProps.walletFiatData?.toFixed(2) &&
    prevProps.priceData?.toFixed(2) === nextProps.priceData?.toFixed(2) &&
    prevProps.dolomiteFiatData?.toFixed(2) === nextProps.dolomiteFiatData?.toFixed(2) &&
    prevProps.expanded === nextProps.expanded &&
    prevProps.showYieldAsApr === nextProps.showYieldAsApr
  )
}

function formatNumber(num: number) {
  return new Intl.NumberFormat().format(Math.round(num * 10) / 10)
}
function shortenNumber(value: CurrencyAmount<Token> | undefined) {
  if (value === undefined) return undefined
  const num = parseFloat(value.toFixed())
  if (num >= 1000000000) return formatNumber(num / 1000000000) + 'B'
  if (num >= 1000000) return formatNumber(num / 1000000) + 'M'
  if (num >= 1000) return formatNumber(num / 1000) + 'k'
  return formatNumber(num)
}

const nearOne = new Fraction(99, 100)

export const TokenRow = ({
  t,
  currencyOrToken,
  chainId,
  interestRateData,
  priceData,
  dolomiteBalanceData,
  dolomiteFiatData,
  walletBalanceData,
  walletFiatData,
  marketRiskData,
  tokenTvlData,
  previousPriceData,
  hasRewards,
  hasPowder,
  hasRewardStation,
  hasMinerals,
  hasPoints,
  expanded,
  isBorrowingEnabled,
  docsLink,
  setExpanded,
  expandedContent,
  setPopoverComponent,
  setCustomPopoverWidth,
  showYieldAsApr,
  depositModal,
  withdrawModal,
}: TokenRowProps) => {
  const tokenAddress = currencyOrToken.wrapped.address
  const history = useHistory()
  const supplyInterestRate = interestRateData?.supplyInterestRate
  const totalSupplyInterestRate = interestRateData?.totalSupplyInterestRate
  const supplyInterestRateParts = interestRateData?.outsideSupplyInterestRateParts
  const borrowInterestRate = interestRateData?.borrowInterestRate
  const totalBorrowInterestRate = interestRateData?.totalBorrowInterestRate
  const borrowInterestRateParts = interestRateData?.outsideBorrowInterestRateParts
  const currencyKey = currencyOrToken.isNative ? currencyOrToken.symbol ?? '' : currencyOrToken.address

  const handleOpenDeposit = useCallback(() => {
    depositModal(currencyOrToken)
  }, [depositModal, currencyOrToken])
  const handleOpenWithdraw = useCallback(() => {
    withdrawModal(currencyOrToken)
  }, [currencyOrToken, withdrawModal])

  const emodeInfo = marketRiskData?.riskCategories
  const isEmodeCompatible = !!emodeInfo

  return (
    <EarnRow
      key={`lending-${currencyKey}`}
      hasExpandedContent={!!expandedContent}
      expanded={expanded}
      isBera={cleanCurrencySymbol(currencyOrToken, false) === 'BERA'}
      isEmodeCompatible={isEmodeCompatible}
    >
      <EarnTopRow isExpanded={expanded} onClick={() => setExpanded()}>
        <TickerColumn>
          <div>
            <TokenLogo>
              <img
                src={getLogoOrDefault(cleanCurrencySymbol(currencyOrToken, false) ?? '')}
                alt={`${currencyOrToken.symbol} logo`}
              />
            </TokenLogo>
            <TickerWrapper>
              {/*onClick={() => history.push(`/stats/token/${chainId}/${tokenAddress}`)} pointer>*/}
              <Ticker>
                {cleanCurrencySymbol(currencyOrToken, false)}
                {/*<TickerIcons>
                  {!isBorrowingEnabled && <NoTradingIcon />}
                  {docsLink && (
                    <StyledTooltip title={'Link to the documentation on this asset'}>
                      <a href={docsLink} target={'_blank'} rel={'noreferrer'} onClick={e => e.stopPropagation()}>
                        <ImportContactsIcon fontSize={'small'} />
                      </a>
                    </StyledTooltip>
                  )}
                </TickerIcons>*/}
              </Ticker>
              <Name>{cleanCurrencyName(currencyOrToken, false)}</Name>
            </TickerWrapper>
          </div>
        </TickerColumn>
        <SupplyColumn>
          {supplyInterestRateParts && supplyInterestRateParts.length > 0 ? (
            <StyledTooltip
              title={
                <YieldTooltip
                  interestRateParts={supplyInterestRateParts}
                  lendingYield={supplyInterestRate}
                  totalSupplyYield={totalSupplyInterestRate}
                  showYieldAsApr={showYieldAsApr}
                  isBorrowRate={false}
                />
              }
              placement={'top'}
              arrow={true}
            >
              <OutsideRateWrapper>
                {totalSupplyInterestRate
                  ? totalSupplyInterestRate.greaterThan(ZERO_PERCENT) && totalSupplyInterestRate.lessThan(ONE_BIPS)
                    ? '< 0.01%'
                    : formatAmount(totalSupplyInterestRate)
                  : '-'}
                <StyledAutoAwesomeIcon />
              </OutsideRateWrapper>
            </StyledTooltip>
          ) : (
            <RateWrapper>
              {totalSupplyInterestRate
                ? totalSupplyInterestRate.greaterThan(ZERO_PERCENT) && totalSupplyInterestRate.lessThan(ONE_BIPS)
                  ? '< 0.01'
                  : totalSupplyInterestRate.toFixed(2)
                : '-'}
              %
            </RateWrapper>
          )}
        </SupplyColumn>
        <BorrowColumn>
          {isBorrowingEnabled ? (
            borrowInterestRateParts && borrowInterestRateParts.length > 0 ? (
              <StyledTooltip
                title={
                  <YieldTooltip
                    interestRateParts={borrowInterestRateParts}
                    lendingYield={borrowInterestRate}
                    totalSupplyYield={totalBorrowInterestRate}
                    showYieldAsApr={showYieldAsApr}
                    isBorrowRate={false}
                  />
                }
                placement={'top'}
                arrow={true}
              >
                <OutsideRateWrapper>
                  {totalBorrowInterestRate
                    ? totalBorrowInterestRate.greaterThan(ZERO_PERCENT) && totalBorrowInterestRate.lessThan(ONE_BIPS)
                      ? '< 0.01%'
                      : formatAmount(totalBorrowInterestRate)
                    : '-'}
                  <StyledAutoAwesomeIcon />
                </OutsideRateWrapper>
              </StyledTooltip>
            ) : (
              <RateWrapper isBorrow>
                {totalBorrowInterestRate
                  ? totalBorrowInterestRate.greaterThan(ZERO_PERCENT) && totalBorrowInterestRate.lessThan(ONE_BIPS)
                    ? '< 0.01'
                    : totalBorrowInterestRate.toFixed(2)
                  : '-'}
                %
              </RateWrapper>
            )
          ) : (
            '-'
          )}
        </BorrowColumn>
        {/*<SupplyCapColumn>*/}
        {/*  <StyledTooltip*/}
        {/*    title={*/}
        {/*      <div>*/}
        {/*        {showBorrow && (*/}
        {/*          <SupplyTooltipLine>*/}
        {/*            <SupplyTooltipTitle>{t('amountBorrowed')}:</SupplyTooltipTitle>*/}
        {/*            {formatAmount(borrowTvl)}*/}
        {/*            <CurrencySymbol>{symbol}</CurrencySymbol>*/}
        {/*          </SupplyTooltipLine>*/}
        {/*        )}*/}
        {/*        {borrowCap && (*/}
        {/*          <SupplyTooltipLine>*/}
        {/*            <SupplyTooltipTitle>{t('borrowCap')}:</SupplyTooltipTitle>*/}
        {/*            {formatAmount(borrowCap)}*/}
        {/*            <CurrencySymbol>{symbol}</CurrencySymbol>*/}
        {/*          </SupplyTooltipLine>*/}
        {/*        )}*/}
        {/*        {borrowCap && borrowTvl && (*/}
        {/*          <SupplyTooltipLine>*/}
        {/*            <SupplyTooltipTitle>{t('borrowCapRemaining')}:</SupplyTooltipTitle>*/}
        {/*            {formatAmount(borrowCap.subtract(borrowTvl))}*/}
        {/*            <CurrencySymbol>{symbol}</CurrencySymbol>*/}
        {/*          </SupplyTooltipLine>*/}
        {/*        )}*/}
        {/*        {showBorrow && supplyTvl && (*/}
        {/*          <SupplyTooltipLine>*/}
        {/*            <SupplyTooltipTitle>{t('availableToWithdraw')}:</SupplyTooltipTitle>*/}
        {/*            {formatAmount(borrowTvl ? supplyTvl.subtract(borrowTvl) : supplyTvl)}*/}
        {/*            <CurrencySymbol>{symbol}</CurrencySymbol>*/}
        {/*          </SupplyTooltipLine>*/}
        {/*        )}*/}
        {/*        <SupplyTooltipLine>*/}
        {/*          <SupplyTooltipTitle>{t('supplyLiquidity')}:</SupplyTooltipTitle>*/}
        {/*          {supplyTvl ? formatAmount(supplyTvl) : '-'}*/}
        {/*          <CurrencySymbol>{symbol}</CurrencySymbol>*/}
        {/*        </SupplyTooltipLine>*/}
        {/*        <SupplyTooltipLine>*/}
        {/*          <SupplyTooltipTitle>{t('supplyCap')}:</SupplyTooltipTitle>*/}
        {/*          {supplyCap ? (*/}
        {/*            formatAmount(supplyCap)*/}
        {/*          ) : (*/}
        {/*            <InfinityWrapperSmall>*/}
        {/*              <AllInclusiveIcon />*/}
        {/*            </InfinityWrapperSmall>*/}
        {/*          )}*/}
        {/*          <CurrencySymbol>{symbol}</CurrencySymbol>*/}
        {/*        </SupplyTooltipLine>*/}
        {/*        {supplyCap && supplyTvl && supplyCap.greaterThan(supplyTvl) && (*/}
        {/*          <SupplyTooltipLine>*/}
        {/*            <SupplyTooltipTitle>{t('supplyCapRemaining')}:</SupplyTooltipTitle>*/}
        {/*            {formatAmount(supplyCap.subtract(supplyTvl))}*/}
        {/*            <CurrencySymbol>{symbol}</CurrencySymbol>*/}
        {/*          </SupplyTooltipLine>*/}
        {/*        )}*/}
        {/*      </div>*/}
        {/*    }*/}
        {/*    placement={'top'}*/}
        {/*  >*/}
        {/*    <BarValues>*/}
        {/*      <BarValue>*/}
        {/*        {showBorrow ? shortenNumber(borrowTvl) : '-'}*/}
        {/*        <BarValueCap>{!isArb && borrowCap && ` (${shortenNumber(borrowCap)})`}</BarValueCap>*/}
        {/*        <Separator>/</Separator>*/}
        {/*      </BarValue>*/}
        {/*      <BarValue>*/}
        {/*        <Supplied*/}
        {/*          capped={*/}
        {/*            supplyCap && supplyCap.equalTo(ZERO_FRACTION)*/}
        {/*              ? true*/}
        {/*              : supplyCap && supplyTvl?.divide(supplyCap).greaterThan(nearOne)*/}
        {/*          }*/}
        {/*        >*/}
        {/*          {supplyTvl ? shortenNumber(supplyTvl) : '-'}*/}
        {/*        </Supplied>*/}
        {/*        {isArb && <Separator>/</Separator>}*/}
        {/*      </BarValue>*/}
        {/*      <BarValue darken={!isArb}>*/}
        {/*        {!isArb && <Paranthesis>(</Paranthesis>}*/}
        {/*        {supplyCap ? (*/}
        {/*          shortenNumber(supplyCap)*/}
        {/*        ) : (*/}
        {/*          <InfinityWrapper>*/}
        {/*            <AllInclusiveIcon />*/}
        {/*          </InfinityWrapper>*/}
        {/*        )}*/}
        {/*        {!isArb && ')'}*/}
        {/*      </BarValue>*/}
        {/*    </BarValues>*/}
        {/*  </StyledTooltip>*/}
        {/*  {supplyTvl && (*/}
        {/*    <BarWrapper>*/}
        {/*      <SupplyCapBar>*/}
        {/*        <SupplyBar progress={barValues.supply}></SupplyBar>*/}
        {/*        <BorrowBar progress={barValues.borrow}></BorrowBar>*/}
        {/*        {!!barValues.borrowCap && <BorrowCapLine left={barValues.borrowCap} />}*/}
        {/*      </SupplyCapBar>*/}
        {/*    </BarWrapper>*/}
        {/*  )}*/}
        {/*</SupplyCapColumn>*/}
        <BalanceColumn>
          {dolomiteBalanceData && dolomiteFiatData ? (
            <div>
              <StyledTooltip
                title={dolomiteBalanceData.toFixed(Math.min(currencyOrToken.decimals, 8), FORMATTER)}
                placement={'right'}
              >
                <Balance>
                  {dolomiteBalanceData.greaterThan(ZERO_FRACTION) && formatAmount(dolomiteBalanceData) === '0.000000'
                    ? '< 0.000001'
                    : formatAmount(dolomiteBalanceData)}
                </Balance>
              </StyledTooltip>
              <DollarBalance>{'$' + dolomiteFiatData.toFixed(2, FORMATTER)}</DollarBalance>
            </div>
          ) : (
            <div>
              {/* TODO - if the balance is above a certain number of characters long (around 10), have it apply another css class that reduces the padding on the whole thing from 35px -> 20px so it will fit*/}
              <Balance>0.0000</Balance>
              <DollarBalance>$0.00</DollarBalance>
            </div>
          )}
        </BalanceColumn>
        <WalletBalanceColumn>
          {walletBalanceData && walletFiatData ? (
            <div>
              <StyledTooltip
                title={walletBalanceData.toFixed(Math.min(currencyOrToken.decimals, 8), FORMATTER)}
                placement={'right'}
              >
                <Balance>
                  {walletBalanceData.greaterThan(ZERO_FRACTION) && formatAmount(walletBalanceData) === '0.000000'
                    ? '< 0.000001'
                    : formatAmount(walletBalanceData)}
                </Balance>
              </StyledTooltip>
              <DollarBalance>{'$' + walletFiatData.toFixed(2, FORMATTER)}</DollarBalance>
            </div>
          ) : (
            <div>
              {/* TODO - if the balance is above a certain number of characters long (around 10), have it apply another css class that reduces the padding on the whole thing from 35px -> 20px so it will fit*/}
              <Balance>0.0000</Balance>
              <DollarBalance>$0.00</DollarBalance>
            </div>
          )}
        </WalletBalanceColumn>
        <ButtonWrapper>
          <DepositWithdrawButtons>
            <ActionButton
              onClick={e => {
                e.stopPropagation()
                handleOpenWithdraw()
              }}
            >
              <ButtonText>Withdraw</ButtonText>
            </ActionButton>
            <ActionButton
              onClick={e => {
                e.stopPropagation()
                handleOpenDeposit()
              }}
            >
              <ButtonText>Deposit</ButtonText>
            </ActionButton>
          </DepositWithdrawButtons>
        </ButtonWrapper>
        {currencyOrToken.isNative && chainId === ChainId.BERACHAIN && <ClimbingBear src={ClimbingBearImg} />}
      </EarnTopRow>
      {!!expandedContent && (
        <BottomSection isExpanded={expanded}>
          <ExpandedContentWrapper>
            {React.cloneElement(expandedContent, {
              setPopoverComponent,
              setCustomPopoverWidth,
            })}
          </ExpandedContentWrapper>
        </BottomSection>
      )}
      <ExpandedSection expanded={expanded}>
        {expanded && (
          <ExpandedRow
            t={t}
            chainId={chainId}
            currencyOrToken={currencyOrToken}
            interestRateData={interestRateData}
            priceData={priceData}
            dolomiteBalanceData={dolomiteBalanceData}
            dolomiteFiatData={dolomiteFiatData}
            walletBalanceData={walletBalanceData}
            walletFiatData={walletFiatData}
            marketRiskData={marketRiskData}
            tokenTvlData={tokenTvlData}
            previousPriceData={previousPriceData}
            hasRewards={hasRewards}
            hasPowder={hasPowder}
            hasRewardStation={hasRewardStation}
            hasMinerals={hasMinerals}
            hasPoints={hasPoints}
            expanded={expanded} //expandedRowMap[currency.symbol ?? ''] ?? false
            isBorrowingEnabled={isBorrowingEnabled}
            docsLink={docsLink}
            // setExpanded={() => {}} //setExpandedCallback(currency.wrapped)
            expandedContent={expandedContent}
            setPopoverComponent={setPopoverComponent}
            setCustomPopoverWidth={setCustomPopoverWidth}
            showYieldAsApr={showYieldAsApr}
            depositModal={depositModal}
            withdrawModal={withdrawModal}
            isEmodeCompatible={isEmodeCompatible}
            emodeInfo={emodeInfo}
          />
        )}
      </ExpandedSection>
      <ButtonsColumn />
      {/*<BottomLine />*/}
    </EarnRow>
  )
}

export default React.memo(TokenRow, TokenRowComparator)
