import KeyboardArrowDownIcon from '@material-ui/icons/KeyboardArrowDown'
import AllInclusiveIcon from '@material-ui/icons/AllInclusive'
import React, { SetStateAction, useCallback, useEffect, useMemo, useState } from 'react'
import styled from 'styled-components/macro'
import { Fraction, Token } from '@dolomite-exchange/v2-sdk'
import { Namespace, UseTranslationOptions, UseTranslationResponse } from 'react-i18next'
import { BorrowPosition as BorrowPositionData } from '../../types/borrowPositionData'
import InterestRateChangedProps from './InterestRateChangedProps'
import { CollateralActionType, LoanActionType, ManageType } from './types'
import { useActiveWeb3React } from '../../hooks'
import BorrowTopRow from './BorrowPosition/BorrowTopRow'
import BorrowPositionAssets from './BorrowPosition/BorrowPositionAssets'
import BorrowActionButtons from './BorrowPosition/BorrowActionButtons'
import BorrowExpandedContent from './BorrowPosition/BorrowExpandedContent'
import { STABLE_TOKENS } from '../../constants/tokenLists/FilterTokens'
import { ONE_FRACTION, ZERO_FRACTION } from '../../constants'
import calculateLiquidationPrice from '../../utils/calculateLiquidationPrice'
import { MarketRiskInfo, useMarketRiskInfoData } from '../../types/marketRiskInfoData'
import { DolomiteMarginData } from '../../types/dolomiteMarginData'
import { reqParseFraction } from '../../state/trade/hooks'
import ShareBorrowPositionModal from './ShareBorrowPositionModal'
import { useHistory } from 'react-router-dom'
import { ActivePosition } from '../../hooks/useActiveStrategies'
import FlameIcon from '../../assets/images/flame-icon-colored.svg'
import EmodeAvailableModal from '../../components/Emode/EmodeAvailableModal'
import useBorrowPositionEMode from '../../hooks/useBorrowPositionEMode'
import { isValidPositionOverride } from '../../utils/emode'

const ExpandChevronIcon = styled.div<{ expanded?: boolean }>`
  position: absolute;
  bottom: 0;
  left: calc(50% - 16px);
  opacity: 0.5;
  ${({ expanded }) => (expanded ? 'transform: rotateX(180deg);' : '')}
  transform-origin: center;
  transition: all 0.3s ease-in-out;
  cursor: pointer;

  :hover {
    opacity: 1 !important;
    cursor: pointer;
  }

  svg {
    font-size: 32px;
  }
`

const BorrowPositionWrapper = styled.div<{ expanded?: boolean; isClosing: boolean }>`
  width: 100%;
  background: ${({ theme }) => theme.bg1};
  border-radius: 8px;
  margin-bottom: 15px;
  padding: 20px 35px 0;
  box-shadow: 0 5px 5px -3px rgb(0 0 0 / 20%), 0 8px 10px 1px rgb(0 0 0 / 14%), 0 3px 14px 2px rgb(0 0 0 / 12%);
  text-align: left;
  position: relative;
  cursor: ${({ expanded }) => (expanded ? 'auto' : 'pointer')};
  overflow: hidden;

  :hover {
    ${ExpandChevronIcon} {
      opacity: ${({ expanded }) => (expanded ? 0.5 : 1)};
      /*pointer-events: none;*/
    }
  }

  > div {
    opacity: ${({ isClosing }) => (isClosing ? 0.5 : 1)};
  }

  @media screen and (max-width: 615px) {
    padding: 18px 24px 0;
  }

  @media screen and (max-width: 480px) {
    padding: 24px 32px 0;
  }
`

const TooltipWrapper = styled.div`
  width: 280px;
  height: auto;
  padding: 10px;
`

const TooltipDescription = styled.div`
  font-size: 11px;
  font-weight: 100;
  margin-top: 7px;
  margin-bottom: 0;
  line-height: 13px;
`

const CurrentHealthWrapper = styled.div<{ health: number }>`
  width: 100%;
  font-size: 13px;
  margin-top: 0;
  margin-bottom: 8px;
  font-weight: 200;

  span {
    font-weight: 700;
    color: ${({ health, theme }) => (health === 3 ? theme.green1 : health === 2 ? theme.yellow1 : theme.red1)};
  }
`

export const PositionHealthBarWrapper = styled.div`
  width: calc(100% - 16px);
  position: relative;
`

export const PositionHealthBar = styled.div`
  width: calc(100% + 16px);
  height: 8px;
  border-radius: 4px;
  background: rgb(252, 59, 38);
  background: linear-gradient(90deg, rgba(252, 59, 38, 1) 0%, rgba(143, 201, 66, 1) 70%);
`

export const PositionHealthIndicator = styled.div<{ health: number }>`
  width: 8px;
  height: 8px;
  border-radius: 50%;
  background-color: ${({ theme }) => theme.text1};
  position: absolute;
  left: calc(${({ health }) => health}% + 8px);
  bottom: 0;
`

const StrategyHeader = styled.div<{ expanded: boolean }>`
  position: absolute;
  top: 0;
  left: 50%;
  transform: translateX(-50%);
  height: 16px;
  width: ${({ expanded }) => (expanded ? '100%' : '62px')};
  border-radius: ${({ expanded }) => (expanded ? '0px' : '0px 0px 3px 3px')};
  transition: all 0.3s ease-in-out;
  background-color: ${({ theme }) => theme.blue1};
  font-size: 11px;
  font-weight: 600;
  text-align: center;
  cursor: pointer;

  :hover {
    background-color: ${({ theme }) => theme.blue2};
  }
`

const EmodeIconWrapper = styled.div<{ emode: boolean; strategy: boolean }>`
  position: absolute;
  top: ${({ strategy }) => (strategy ? 22 : 8)}px;
  transition: all 0.3s ease-in-out;
  right: 9px;
  cursor: pointer;

  img {
    color: ${({ emode, theme }) => (emode ? theme.blue1 : theme.text3)} !important;
    height: 20px;
  }
`
//e

const ShareButton = styled.div<{ display?: 'true' }>`
  position: absolute;
  left: calc(50% - 30px);
  top: ${({ display }) => (display ? '0' : '-35px')};
  z-index: 10;
  font-weight: 600;
  font-size: 12px;
  padding: 6px 12px;
  border-radius: 0 0 5px 5px;
  background: ${({ theme }) => theme.bg3};
  cursor: pointer;
  opacity: ${({ display }) => (display ? 1 : 0)} !important;
  transition: all 0.3s ease-in-out;

  :hover {
    background: ${({ theme }) => theme.bg4};
  }
`

export function convertHealthToChartPosition(health: number) {
  return 100 * (1 - (1 / 2) ** Math.log2(health))
}

export const PositionHealthTooltip = ({
  positionHealth,
  positionHealthFactor,
  t,
  isNew,
}: {
  positionHealth: number | undefined
  positionHealthFactor: number
  t: (ns?: Namespace, options?: UseTranslationOptions) => UseTranslationResponse
  isNew: boolean
}) => (
  <TooltipWrapper>
    <CurrentHealthWrapper health={positionHealthFactor}>
      {isNew ? t('updatedPositionHealth') : t('currentPositionHealth')}{' '}
      {positionHealth ? (
        <span>{positionHealth.toFixed(2)}</span>
      ) : (
        <span>
          <AllInclusiveIcon
            style={{
              marginBottom: '-7px',
              marginLeft: '2px',
            }}
          />
        </span>
      )}
    </CurrentHealthWrapper>
    <PositionHealthBarWrapper>
      <PositionHealthIndicator health={convertHealthToChartPosition(positionHealth ?? 100)} />
      <PositionHealthBar />
    </PositionHealthBarWrapper>
    <TooltipDescription>{t('positionHealthLiquidationWarning')}</TooltipDescription>
  </TooltipWrapper>
)

export const getPositionHealth = (health: Fraction | undefined) => {
  return health
    ? health.greaterThan(new Fraction('125', '100'))
      ? 3
      : health.greaterThanOrEqual(new Fraction('115', '100'))
      ? 2
      : 1
    : 3
}

interface BorrowPositionRowProps {
  position: BorrowPositionData
  expandedId: string | undefined
  isAttemptingTx: boolean
  isTxPending: boolean
  isClosing: boolean
  addClosing: (id: string) => void
  setExpandedId: React.Dispatch<SetStateAction<string | undefined>>
  setIsAttemptingTx: React.Dispatch<SetStateAction<boolean>>
  setIsDialogOpen: React.Dispatch<SetStateAction<boolean>>
  setSelectedToken: React.Dispatch<SetStateAction<Token | undefined>>
  setTransactionPendingText: React.Dispatch<SetStateAction<string>>
  setTxHash: React.Dispatch<SetStateAction<string | undefined>>
  setNewBorrowRate: React.Dispatch<SetStateAction<InterestRateChangedProps | undefined>>
  setNewSupplyRate: React.Dispatch<SetStateAction<InterestRateChangedProps | undefined>>
  marketRiskInfoMap: Record<string, MarketRiskInfo | undefined>
  dolomiteMargin: DolomiteMarginData | undefined
  activeStrategy: ActivePosition | undefined
}

function BorrowPositionRowComparator(prevProps: BorrowPositionRowProps, nextProps: BorrowPositionRowProps) {
  return (
    JSON.stringify(prevProps.position) === JSON.stringify(nextProps.position) &&
    prevProps.expandedId === nextProps.expandedId &&
    prevProps.isAttemptingTx === nextProps.isAttemptingTx &&
    prevProps.isTxPending === nextProps.isTxPending &&
    prevProps.isClosing === nextProps.isClosing &&
    prevProps.addClosing === nextProps.addClosing
  )
}

function BorrowPositionRow({
  position,
  expandedId,
  setExpandedId,
  isAttemptingTx,
  isTxPending,
  isClosing,
  setIsAttemptingTx,
  setIsDialogOpen,
  setSelectedToken: setSelectedTokenForTransactionConfirmation,
  setTransactionPendingText,
  setTxHash,
  setNewBorrowRate,
  setNewSupplyRate,
  addClosing,
  marketRiskInfoMap,
  dolomiteMargin,
  activeStrategy,
}: BorrowPositionRowProps) {
  const { chainId } = useActiveWeb3React()
  const expanded = useMemo(() => expandedId === position.id, [expandedId, position.id])
  const [selectedTab, setSelectedTab] = useState<CollateralActionType | LoanActionType>(CollateralActionType.DEPOSIT)
  const [manageType, setManageType] = useState<ManageType>(ManageType.COLLATERAL)
  const [showShareModal, setShowShareModal] = useState(false)
  const [showEmodeWarningModal, setShowEmodeWarningModal] = useState(false)
  const [selectedRiskCategoryToken, setSelectedRiskCategoryToken] = useState<Token | undefined>(undefined)
  const { data: riskInfoMap } = useMarketRiskInfoData()
  const emodeData = useBorrowPositionEMode(position, riskInfoMap)

  const [expandedInputArea, setExpandedInputArea] = useState(false)
  const wrapperRef = React.useRef<HTMLDivElement>(null)
  const history = useHistory()

  const onDismiss = useCallback(() => setShowShareModal(false), [])

  const updateManageTypeAndSelectedInnerTab = useCallback((newManageType: ManageType, newSelectedTab: number) => {
    setManageType(newManageType)
    setSelectedTab(newSelectedTab)
  }, [])

  useEffect(() => {
    updateManageTypeAndSelectedInnerTab(manageType, selectedTab)
  }, [manageType, selectedTab, updateManageTypeAndSelectedInnerTab])

  const collapse = useCallback(() => {
    setExpandedId(expandedId => {
      if (expandedId === position.id) {
        return undefined
      }
      return expandedId
    })
    setExpandedInputArea(false)
  }, [position.id, setExpandedId])

  useEffect(() => {
    if (!expanded) {
      collapse()
    }
  }, [expanded, collapse])

  const handleCollapseCallback = useCallback(() => {
    setExpandedId(expandedId => {
      if (expandedId === position.id) {
        return undefined
      }
      return expandedId
    })
  }, [position.id, setExpandedId])
  const openEmodeFromAsset = useCallback(token => {
    setSelectedRiskCategoryToken(token)
  }, [])

  const positionHealthFactor = useMemo(() => {
    return getPositionHealth(position.positionHealth)
  }, [position.positionHealth])

  const isInIsolationMode = position.specialInfo.specialAsset?.isIsolationMode
  const specialAsset = position.specialInfo.specialAsset
  const supplyAmounts = position.supplyAmounts
  const borrowAmounts = position.borrowAmounts

  const [liquidationAsset, liquidationPrice] = useMemo(() => {
    const nonStableSupplyAmounts = supplyAmounts.filter(
      a => !(STABLE_TOKENS as Record<string, boolean>)[a.token.symbol ?? ''],
    )
    const nonStableBorrowAmounts = borrowAmounts.filter(
      a => !(STABLE_TOKENS as Record<string, boolean>)[a.token.symbol ?? ''],
    )
    if (
      (nonStableBorrowAmounts.length === 1 && nonStableBorrowAmounts.length < borrowAmounts.length) ||
      (nonStableSupplyAmounts.length === 1 && nonStableSupplyAmounts.length < supplyAmounts.length)
    ) {
      return [undefined, undefined]
    }
    if (
      nonStableSupplyAmounts.length + nonStableBorrowAmounts.length === 1 &&
      supplyAmounts.length > 0 &&
      borrowAmounts.length > 0
    ) {
      const defaultMinCollateralization = dolomiteMargin?.minCollateralization
      if (nonStableSupplyAmounts.length === 1) {
        const heldAmountWei = nonStableSupplyAmounts[0].amountTokenWei
        const owedAmountUSD = borrowAmounts.reduce((total, borrowAmount) => {
          const marginPremium = marketRiskInfoMap[borrowAmount.token.address]?.marginPremium ?? ZERO_FRACTION
          const appliedMarginPremium = marginPremium.add(ONE_FRACTION)
          const owedAmountUSD = borrowAmount.amountUSD.multiply(appliedMarginPremium)
          return total.add(reqParseFraction(owedAmountUSD.toFixed(36), 36))
        }, ZERO_FRACTION)
        const liq = calculateLiquidationPrice(
          heldAmountWei,
          owedAmountUSD,
          defaultMinCollateralization,
          marketRiskInfoMap[nonStableSupplyAmounts[0].token.address],
          undefined,
          emodeData,
        )
        return [nonStableSupplyAmounts[0].token, liq]
      }

      const heldAmountUSD = supplyAmounts.reduce((total, supplyAmount) => {
        const marginPremium = marketRiskInfoMap[supplyAmount.token.address]?.marginPremium ?? ZERO_FRACTION
        const appliedMarginPremium = ONE_FRACTION.add(marginPremium)
        const supplyAmountUSD = supplyAmount.amountUSD.divide(appliedMarginPremium)
        return total.add(reqParseFraction(supplyAmountUSD.toFixed(36), 36))
      }, ZERO_FRACTION)

      const owedAmount = nonStableBorrowAmounts[0].amountTokenWei.asFraction
      const liq = calculateLiquidationPrice(
        heldAmountUSD,
        owedAmount,
        defaultMinCollateralization,
        undefined,
        marketRiskInfoMap[nonStableBorrowAmounts[0].token.address],
        emodeData,
      )
      return [nonStableBorrowAmounts[0].token, ONE_FRACTION.divide(liq)]
    }
    return [undefined, undefined]
  }, [supplyAmounts, borrowAmounts, dolomiteMargin?.minCollateralization, marketRiskInfoMap, emodeData])

  const [riskToken, riskCategory] = useMemo(() => {
    if (emodeData) {
      return [position.supplyAmounts[0].token, emodeData]
    }

    if (position.supplyAmounts.length !== 1) {
      return [undefined, undefined]
    }
    if (position.borrowAmounts.length !== 0) {
      return [undefined, undefined]
    }

    const eModeToken = position.supplyAmounts[0].token
    const riskCategoryStruct = riskInfoMap[position.supplyAmounts[0].token.address]?.riskCategories
    if (riskCategoryStruct) {
      return [eModeToken, riskCategoryStruct]
    } else return [undefined, undefined]
  }, [emodeData, position, riskInfoMap])

  return (
    <BorrowPositionWrapper
      expanded={expanded}
      onClick={() => {
        if (!expanded) {
          setExpandedId(position.id)
          /*setExpandedInputArea(true)*/
        }
      }}
      ref={wrapperRef}
      isClosing={isClosing}
    >
      <ShareBorrowPositionModal isOpen={showShareModal} memoizedOnDismiss={onDismiss} position={position} />
      {activeStrategy === undefined && (
        <ShareButton display={expanded ? 'true' : undefined} onClick={() => setShowShareModal(true)}>
          SHARE
        </ShareButton>
      )}
      {activeStrategy !== undefined && !!activeStrategy.strategy && (
        <StrategyHeader
          expanded={expanded}
          onClick={() => history.push(`/strategies/${activeStrategy.strategy}/${position.id}`)}
        >
          Strategy
        </StrategyHeader>
      )}
      {riskToken && (
        <EmodeIconWrapper
          emode={false}
          onClick={e => {
            e.stopPropagation()
            openEmodeFromAsset(riskToken)
          }}
          strategy={activeStrategy !== undefined && !!activeStrategy.strategy && expanded}
        >
          <img src={FlameIcon} alt={'Flame emode icon'} />
          {/*<AutoFixHighIcon />*/}
        </EmodeIconWrapper>
      )}
      {selectedRiskCategoryToken && (
        <EmodeAvailableModal
          open={!!selectedRiskCategoryToken}
          close={() => openEmodeFromAsset(undefined)}
          initialCategory={isValidPositionOverride(emodeData) ? emodeData : emodeData?.struct}
        />
      )}
      <BorrowTopRow
        expanded={expanded}
        isInIsolationMode={isInIsolationMode}
        position={position}
        chainId={chainId}
        specialAsset={specialAsset}
        positionHealthFactor={positionHealthFactor}
      />
      <BorrowPositionAssets
        expanded={expanded}
        isInIsolationMode={isInIsolationMode}
        supplyAmounts={position.supplyAmounts}
        borrowAmounts={position.borrowAmounts}
        liquidationAsset={liquidationAsset}
        liquidationPrice={liquidationPrice}
        openEmodeModal={token => openEmodeFromAsset(token)}
      />
      <BorrowActionButtons
        expanded={expanded}
        expandedInputArea={expandedInputArea}
        positionHealthWarning={!position.positionHealth}
        manageType={manageType}
        setExpandedInputArea={setExpandedInputArea}
        updateManageTypeAndSelectedInnerTab={updateManageTypeAndSelectedInnerTab}
      />
      {expanded && (
        <BorrowExpandedContent
          wrapperRef={wrapperRef}
          position={position}
          selectedTab={selectedTab}
          manageType={manageType}
          isAttemptingTx={isAttemptingTx}
          isTxPending={isTxPending}
          expandedInputArea={expanded}
          expanded={expanded}
          addClosing={addClosing}
          setIsDialogOpen={setIsDialogOpen}
          setIsAttemptingTx={setIsAttemptingTx}
          setSelectedTokenForTransactionConfirmation={setSelectedTokenForTransactionConfirmation}
          setTransactionPendingText={setTransactionPendingText}
          setTxHash={setTxHash}
          setNewBorrowRate={setNewBorrowRate}
          setNewSupplyRate={setNewSupplyRate}
          activeStrategy={activeStrategy}
        />
      )}
      {(!expanded || (expanded && expandedInputArea)) && (
        <ExpandChevronIcon expanded={expanded} onClick={handleCollapseCallback}>
          <KeyboardArrowDownIcon />
        </ExpandChevronIcon>
      )}
    </BorrowPositionWrapper>
  )
}

export default React.memo(BorrowPositionRow, BorrowPositionRowComparator)
