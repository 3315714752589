import { Token } from '@dolomite-exchange/sdk-core'
import { ChainId, ChainIdMap, initializeSingleValueChainIdMap } from '../chainId'

const decimals = 18
const symbol = 'sUSDe'
const name = 'Staked USDe'

function getToken(chainId: ChainId, address: string) {
  return new Token(chainId, address, decimals, symbol, name)
}

export const SUSDE: ChainIdMap<Token | undefined> = initializeSingleValueChainIdMap(
  ChainId.BERACHAIN,
  getToken(ChainId.BERACHAIN, '0x211Cc4DD073734dA055fbF44a2b4667d5E5fE5d2'),
)
