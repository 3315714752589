import React, { useMemo, useState } from 'react'
import { useTranslation } from 'react-i18next'
import styled from 'styled-components/macro'
import PageTitle from '../../components/common/PageTitle'
import Table from '../../components/Leaderboard/Table'
import Tabs from '@material-ui/core/Tabs'
import Tab from '@material-ui/core/Tab'
import { useMarginPositionsByOpenTimestampData, useMarginPositionsByStatusData } from '../../types/marginPositionData'
import { BigNumber } from 'ethers'
import { useBlockTimestamp } from '../../state/chain/hooks'

const DAY = 0
const WEEK = 1
const MONTH = 2

const LeaderboardWrapper = styled.div`
  margin: 25px auto;
  width: 80vw;
  max-width: 1400px;

  @media screen and (max-width: 1219px) {
    width: 90vw;
  }

  @media screen and (max-width: 1000px) {
    width: 100%;
    max-width: 600px;
    margin: 5px auto;
  }

  @media screen and (max-width: 919px) {
    margin-top: 15px;
  }

  @media screen and (max-width: 730px) {
    width: 90vw;
  }

  @media screen and (max-width: 580px) {
    margin: 0 auto 15px;
    width: calc(100vw - 32px);
  }
`

const TableWrapper = styled.div`
  width: calc(50% - 40px);
  min-width: 340px;
  max-width: 600px;
  margin: 0 20px;
  display: inline-block;

  @media screen and (max-width: 1000px) {
    width: 100%;
    margin: 0;
  }
`

const Header = styled.div`
  width: 100%;
  height: 33px;
  margin-bottom: 0px;
  margin-top: 10px;
`

const TableTitle = styled.div`
  float: left;
  display: inline-block;
  font-size: 20px;
`

const StyledTabs = styled(({ ...rest }) => <Tabs classes={{ indicator: 'indicator' }} {...rest} />)`
  font-family: 'Open Sans', sans-serif !important;
  justify-content: normal !important;
  min-height: 0 !important;
  float: right;
  display: inline-block;
  margin-top: 1px;

  .indicator {
    background-color: #f9f9f9 !important;
    bottom: 0 !important;
    display: block !important;
    height: 1.4px !important;
    transform: scale(0.5, 1) !important;
  }
`

const StyledTab = styled(({ ...rest }) => (
  <Tab
    classes={{
      root: 'root',
      selected: 'selected',
    }}
    {...rest}
  />
))`
  font-family: 'Open Sans', sans-serif !important;
  font-size: 15px !important;
  font-weight: 400 !important;
  margin-left: 0 !important;
  margin-right: 3px !important;
  text-transform: capitalize !important;
  padding: 0 !important;
  padding-left: 4px !important;
  padding-right: 4px !important;
  max-width: 264px;
  min-width: 0 !important;
  color: #606375 !important;
  min-height: 0 !important;

  ${({ selected }) =>
    selected &&
    `
    color: #f9f9f9 !important;
  `}
  .selected {
    color: #f9f9f9 !important;
  }

  .root span {
    font-size: 18px !important;
  }
`

const FooterSpacing = styled.div`
  height: 30px;
  @media (max-width: 660px) {
    height: 100px;
  }
`

export default function Leaderboard() {
  const { t } = useTranslation()
  const [selectedTab, setSelectedTab] = useState<number>(DAY)
  const options = ['day', 'week', 'month']

  const timestamp = useBlockTimestamp() ?? BigNumber.from('0')
  const period = selectedTab === DAY ? '24' : selectedTab === WEEK ? '7' : '30'
  const span = selectedTab === DAY ? '3600' : '86400'
  const openTimestamp = useMemo(() => {
    return timestamp
      .div(span)
      .sub(period)
      .mul(span)
      .toNumber()
  }, [timestamp, period, span])

  const { data: positions, loading: isPositionLoading } = useMarginPositionsByOpenTimestampData(openTimestamp)
  const { data: open, loading: isOpenLoading } = useMarginPositionsByStatusData(true)
  const settled = useMemo(() => positions.filter(position => position.closeTransaction), [positions])

  return (
    <LeaderboardWrapper>
      <PageTitle title={'Dolomite | Leaderboard'} />
      <TableWrapper>
        <Header>
          <TableTitle>{t('topSettled')}</TableTitle>
          <StyledTabs
            value={selectedTab}
            onChange={(_: any, index: number) => setSelectedTab(index)}
            indicatorColor={'primary'}
            textColor={'primary'}
          >
            {(options ?? []).map((option: string, index: number) => (
              <StyledTab key={`settledTabs-${index}`} disableRipple label={option} />
            ))}
          </StyledTabs>
        </Header>
        <Table positions={settled} loading={isPositionLoading} />
      </TableWrapper>
      <TableWrapper>
        <Header>
          <TableTitle>{t('topOpen')}</TableTitle>
        </Header>
        <Table positions={open} loading={isOpenLoading} open={true} />
      </TableWrapper>
      <FooterSpacing />
    </LeaderboardWrapper>
  )
}
