import { ChainId, GM_WST_ETH_USD_ADDRESSES, GM_WST_ETH_USD_ISOLATION_MODE_ADDRESSES } from '../../index'
import { Token } from '@dolomite-exchange/v2-sdk'
import { calculateGmWstETHUSDProxyVaultAddress } from '../../../utils/isolation/calculateProxyVaultAddress'
import { getOptionally } from '../special-assets-util-functions'
import { SpecialAsset, SpecialAssetSymbol } from '../special-assets'
import {
  getGmCollateralTokens,
  getGmDebtTokens,
  getGmUsdValidInputTokens,
  getGmUsdValidOutputTokens,
} from './special-gm-tokens'
import { WST_ETH } from '../../tokens/WST_ETH'
import { USDE } from '../../tokens/USDE'

const cleanSymbol = 'gmWstETH-USD'
const cleanName = 'GM: wstETH-USD'

export const GM_WST_ETH_USD_SPECIAL_ASSET: () => SpecialAsset = () => ({
  cleanSymbol,
  cleanName,
  symbol: SpecialAssetSymbol.gmWstETHUSD,
  safeSymbol: SpecialAssetSymbol.gmWstETHUSD,
  hasExternalRewards: false,
  documentationUrl: 'https://docs.dolomite.io/integrations/gmx/gm',
  isIsolationMode: true,
  isBorrowingEnabled: false,
  chainIdToAddressMap: GM_WST_ETH_USD_ISOLATION_MODE_ADDRESSES,
  isolationModeInfo: {
    marketId: 59,
    getWrappedToken: (chainId: ChainId) =>
      getOptionally(
        GM_WST_ETH_USD_ISOLATION_MODE_ADDRESSES[chainId],
        address => new Token(chainId, address, 18, cleanSymbol, `Dolomite Isolation: ${cleanName}`),
      ),
    getUnderlyingToken: getGmToken,
    getUnderlyingSpenderToken: getGmToken,
    allowableCollateralTokens: (chainId: ChainId) =>
      getGmCollateralTokens(SpecialAssetSymbol.gmWstETHUSD, chainId, WST_ETH[chainId]),
    allowableDebtTokens: (chainId: ChainId) => getGmDebtTokens(chainId, WST_ETH[chainId]),
    allowableInputTokensForZap: (chainId: ChainId) =>
      getGmUsdValidInputTokens(chainId, WST_ETH[chainId], USDE[chainId]),
    allowableOutputTokensForZap: (chainId: ChainId) =>
      getGmUsdValidOutputTokens(chainId, WST_ETH[chainId], USDE[chainId]),
    remapAccountAddress: calculateGmWstETHUSDProxyVaultAddress,
    isAsync: true,
  },
})

function getGmToken(chainId: ChainId): Token | undefined {
  return getOptionally(
    GM_WST_ETH_USD_ADDRESSES[chainId],
    address => new Token(chainId, address, 18, cleanSymbol, cleanSymbol),
  )
}
