import { ONE_FRACTION, ZERO_FRACTION } from '../constants'
import { Fraction } from '@dolomite-exchange/sdk-core'
import { MarketRiskInfo } from '../types/marketRiskInfoData'
import { isValidPositionOverride, PositionOverrideSetting } from './emode'

export default function calculateScaledValueForRiskParams(
  isPositive: boolean,
  amount: Fraction,
  marketInfo: MarketRiskInfo,
  riskOverride: PositionOverrideSetting | undefined,
): Fraction {
  if (amount.equalTo('0')) {
    return ZERO_FRACTION
  }
  if (isValidPositionOverride(riskOverride)) {
    return amount
  }

  if (isPositive) {
    return amount.divide(marketInfo.marginPremium.add(ONE_FRACTION))
  } else {
    return amount.multiply(marketInfo.marginPremium.add(ONE_FRACTION))
  }
}
