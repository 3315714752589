import { Token } from '@dolomite-exchange/sdk-core'
import { ChainId, ChainIdMap } from '../chainId'

const decimals = 18
const symbol = 'USD0'
const name = 'Usual USD'

function getToken(chainId: ChainId, address: string) {
  return new Token(chainId, address, decimals, symbol, name)
}

export const USD0: ChainIdMap<Token | undefined> = {
  [ChainId.MAINNET]: undefined,
  [ChainId.ARBITRUM_ONE]: undefined,
  [ChainId.BASE]: undefined,
  [ChainId.BERACHAIN]: getToken(ChainId.BERACHAIN, '0x91a6aF4C8Cb1673AB0D776828b6B271AC3dA9DcB'),
  [ChainId.MANTLE]: undefined,
  [ChainId.POLYGON_ZKEVM]: undefined,
  [ChainId.X_LAYER]: undefined,
}
