import { Token } from '@dolomite-exchange/sdk-core'
import { ChainId, ChainIdMap } from '../chainId'

const decimals = 18
const symbol = 'WETH'
const name = 'Wrapped Ether'

function weth(chainId: ChainId, address: string) {
  return new Token(chainId, address, decimals, symbol, name)
}

export const WETH: ChainIdMap<Token> = {
  [ChainId.MAINNET]: weth(ChainId.MAINNET, '0xC02aaA39b223FE8D0A0e5C4F27eAD9083C756Cc2'),
  [ChainId.ARBITRUM_ONE]: weth(ChainId.ARBITRUM_ONE, '0x82aF49447D8a07e3bd95BD0d56f35241523fBab1'),
  [ChainId.BASE]: weth(ChainId.BASE, '0x4200000000000000000000000000000000000006'),
  [ChainId.BERACHAIN]: weth(ChainId.BERACHAIN, '0x2F6F07CDcf3588944Bf4C42aC74ff24bF56e7590'),
  [ChainId.MANTLE]: weth(ChainId.MANTLE, '0xdEAddEaDdeadDEadDEADDEAddEADDEAddead1111'),
  [ChainId.POLYGON_ZKEVM]: weth(ChainId.POLYGON_ZKEVM, '0x4F9A0e7FD2Bf6067db6994CF12E4495Df938E6e9'),
  [ChainId.X_LAYER]: weth(ChainId.X_LAYER, '0x5A77f1443D16ee5761d310e38b62f77f726bC71c'),
}
