import { Fraction } from '@dolomite-exchange/sdk-core'
import { MarketRiskInfo } from '../types/marketRiskInfoData'
import { BIG_INT_ONE, DEFAULT_MIN_COLLATERALIZATION, ZERO_FRACTION } from '../constants'
import { isValidPositionOverride, PositionOverrideSetting } from './emode'

export default function calculateLiquidationPrice(
  heldAmount: Fraction,
  owedAmount: Fraction,
  marginRatio: Fraction | undefined,
  heldMarketInfo: MarketRiskInfo | undefined,
  owedMarketInfo: MarketRiskInfo | undefined,
  positionOverride: PositionOverrideSetting | undefined,
): Fraction {
  if (heldAmount.equalTo(ZERO_FRACTION)) {
    return ZERO_FRACTION
  }
  if (owedAmount.equalTo(ZERO_FRACTION)) {
    return ZERO_FRACTION
  }

  const marginRatioOverride = isValidPositionOverride(positionOverride)
    ? positionOverride?.marginRatioOverride
    : undefined
  let finalMarginRatio = marginRatio ?? DEFAULT_MIN_COLLATERALIZATION
  if (marginRatioOverride) {
    finalMarginRatio = marginRatioOverride
  }

  let finalOwedAmount = owedAmount.asFraction
  const owedMarginPremium = owedMarketInfo?.marginPremium
  if (!marginRatioOverride && owedMarginPremium?.greaterThan(ZERO_FRACTION)) {
    finalOwedAmount = finalOwedAmount.multiply(owedMarginPremium.add(BIG_INT_ONE))
  }

  let finalHeldAmount = heldAmount.asFraction
  const heldMarginPremium = heldMarketInfo?.marginPremium
  if (!marginRatioOverride && heldMarginPremium?.greaterThan(ZERO_FRACTION)) {
    finalHeldAmount = finalHeldAmount.divide(heldMarginPremium.add(BIG_INT_ONE))
  }

  return finalOwedAmount.multiply(finalMarginRatio).divide(finalHeldAmount)
}
