import React, { useCallback, useState } from 'react'
import styled from 'styled-components/macro'
import { useActiveWeb3React } from '../../hooks'

const ToSModalWrapper = styled.div`
  width: 100%;
  height: 100%;
  position: fixed;
  left: 0;
  top: 0;
  background: rgba(0, 0, 0, 0.5);
  z-index: 999;
`

const ToSModalInner = styled.div`
  width: 600px;
  max-width: 90%;
  height: fit-content;
  border-radius: 8px;
  box-shadow: 0 5px 5px -3px rgb(0 0 0 / 20%), 0 8px 10px 1px rgb(0 0 0 / 14%), 0 3px 14px 2px rgb(0 0 0 / 12%);
  background: ${({ theme }) => theme.bg2};
  margin: 25vh auto 0;
  overflow: hidden;
  padding: 20px 30px;
`

const Title = styled.div`
  font-size: 24px;
  font-weight: 400;
  margin-bottom: 15px;
`

const Subtitle = styled.div`
  font-size: 16px;
  font-weight: 300;
  margin-bottom: 20px;
  color: ${({ theme }) => theme.text6};
`

const ToSContent = styled.div`
  width: 100%;
  display: block;
`

const Checkbox = styled.div`
  display: inline-block;
  vertical-align: top;
  height: 17px;
  width: 17px;
  border: 2px solid #f9f9f9;
  background: none;
  border-radius: 3px;
  cursor: pointer;
  margin-right: 5px;
  margin-top: 1px;
`

const Checked = styled.div<{ isChecked: boolean }>`
  height: 7px;
  width: 7px;
  background: ${({ theme, isChecked }) => (isChecked ? '#f9f9f9' : 'none')};
  border-radius: 1px;
  margin-left: 3px;
  margin-top: 3px;
`

const ToSText = styled.div`
  display: inline-block;
  vertical-align: top;
  font-size: 14px;
  width: calc(100% - 24px);

  a {
    color: ${({ theme }) => theme.text1};
    text-decoration: none;
    font-weight: 700;
  }

  @media screen and (max-width: 420px) {
    font-size: 12px;
  }
`

const Cookies = styled.div`
  width: 100%;
  font-size: 14px;
  font-weight: 200;
  float: left;
  width: 80%;
  margin-top: 18px;
`

const ButtonWrapper = styled.div<{ disabled: boolean }>`
  width: 100%;
  margin-top: 20px;
  opacity: ${({ disabled }) => (disabled ? 0.5 : 1)};
  pointer-events: ${({ disabled }) => (disabled ? 'none' : 'all')};

  button {
    width: 100%;
    cursor: pointer;
    display: inline-block;
    vertical-align: top;
    border: none;
    height: 49px;
    font-size: 18px;
    line-height: 22px;
    padding: 12px 28px;
    border-radius: 5px;
    background-color: ${({ theme }) => theme.green1} !important;
    color: ${({ theme }) => theme.text1} !important;
    margin: 0 auto 10px !important;
  }
`

interface AirdropToSModalProps {
  setHasCompletedApprovals: () => void
}

export default function AirdropToSModal({ setHasCompletedApprovals }: AirdropToSModalProps) {
  const { account } = useActiveWeb3React()
  const [hasApprovedToS, setHasApprovedToS] = useState(false)
  const [isAttemptingSignature, setIsAttemptingSignature] = useState(false)

  const handleApproveToS = useCallback(async () => {
    if (!hasApprovedToS) return

    if (!account) {
      // If no wallet is connected, just store the approval
      window.localStorage.setItem('dolomite_airdrop_tos_no_wallet', 'true')
      setHasCompletedApprovals()
      return
    }

    setIsAttemptingSignature(true)
    try {
      // Request signature from wallet
      const provider = window.ethereum
      // @ts-ignore
      if (provider && provider.request) {
        const message = 'I hereby confirm that I have read and agree to the Dolomite Token Distribution Agreement'
        // @ts-ignore
        const signature = await provider.request({
          method: 'personal_sign',
          params: [message, account],
        })

        // Get existing approvals or initialize empty array
        const existingApprovalsStr = window.localStorage.getItem('dolomite_airdrop_tos_approvals')
        const existingApprovals = existingApprovalsStr ? JSON.parse(existingApprovalsStr) : []

        // Add new approval
        const approvalData = {
          address: account,
          signature,
          timestamp: Date.now(),
        }

        // Remove any existing approval for this address
        const filteredApprovals = existingApprovals.filter(
          (approval: any) => approval.address.toLowerCase() !== account.toLowerCase(),
        )

        // Add new approval and store
        window.localStorage.setItem(
          'dolomite_airdrop_tos_approvals',
          JSON.stringify([...filteredApprovals, approvalData]),
        )

        setHasCompletedApprovals()
      }
    } catch (error) {
      console.error('Failed to sign ToS:', error)
    } finally {
      setIsAttemptingSignature(false)
    }
  }, [account, hasApprovedToS, setHasCompletedApprovals])

  return (
    <ToSModalWrapper>
      <ToSModalInner>
        <Title>Welcome to the Dolomite Airdrop</Title>
        <Subtitle>
          Before proceeding, please read and agree to our Token Distribution Agreement for the airdrop distribution.
        </Subtitle>
        <ToSContent>
          <Checkbox onClick={() => setHasApprovedToS(!hasApprovedToS)}>
            <Checked isChecked={hasApprovedToS} />
          </Checkbox>
          <ToSText>
            I have read and agree to the Dolomite Airdrop{' '}
            <a target={'_blank'} rel={'noreferrer'} href={'https://dolomite.io/DOLO-Token-Distribution-Agreement.pdf'}>
              Token Distribution Agreement
            </a>
          </ToSText>
        </ToSContent>
        <Cookies>
          By proceeding, you agree to the use of a small number of strictly necessary cookies that Dolomite uses to run
          properly.
        </Cookies>
        <ButtonWrapper disabled={!hasApprovedToS || isAttemptingSignature}>
          <button onClick={handleApproveToS}>
            {isAttemptingSignature ? 'Signing...' : account ? 'Sign & Continue' : 'Continue'}
          </button>
        </ButtonWrapper>
      </ToSModalInner>
    </ToSModalWrapper>
  )
}
