import { createAction } from '@reduxjs/toolkit'
import { ChainId } from '../../constants'

export interface SerializedToken {
  chainId: ChainId
  address: string
  decimals: number
  symbol?: string
  name?: string
}

export interface SerializedPair {
  token0: SerializedToken
  token1: SerializedToken
}

export const updateMatchesDarkMode = createAction<{ matchesDarkMode: boolean }>('user/updateMatchesDarkMode')
export const updateUserDarkMode = createAction<{ userDarkMode: boolean }>('user/updateUserDarkMode')
export const updateUserExpertMode = createAction<{ userExpertMode: boolean }>('user/updateUserExpertMode')
export const updateUserSingleHopOnly = createAction<{ userSingleHopOnly: boolean }>('user/updateUserSingleHopOnly')
export const updateUserSlippageTolerance = createAction<{ userSlippageTolerance: number }>(
  'user/updateUserSlippageTolerance',
)
export const updateShowInactiveTokens = createAction<{ showInactiveTokens: boolean }>('user/updateShowInactiveTokens')
export const updateShowYieldAsApr = createAction<{ showYieldAsApr: boolean }>('user/updateShowYieldAsApr')
export const updateUserDeadline = createAction<{ userDeadline: number }>('user/updateUserDeadline')
export const updateUserHideDustBalances = createAction<{ userHideDustBalances: boolean }>(
  'user/updateUserHideDustBalances',
)
export const updateUserHideZeroBalances = createAction<{ userHideZeroBalances: boolean }>(
  'user/updateUserHideZeroBalances',
)
export const addSerializedToken = createAction<{ serializedToken: SerializedToken }>('user/addSerializedToken')
export const removeSerializedToken = createAction<{ chainId: ChainId; address: string }>('user/removeSerializedToken')
export const addSerializedPair = createAction<{ serializedPair: SerializedPair }>('user/addSerializedPair')
export const removeSerializedPair = createAction<{ chainId: ChainId; tokenAAddress: string; tokenBAddress: string }>(
  'user/removeSerializedPair',
)
export const toggleURLWarning = createAction('app/toggleURLWarning')
