import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react'
import { useTranslation } from 'react-i18next'
import styled from 'styled-components/macro'
import { useActiveWeb3React } from '../../hooks'
import { StyledTooltip, StyledTooltipWithIcon } from '../common/StyledTooltip'
import { useAllActiveTokensArray, useAllActiveTokensArrayWithExternalTokens, useEther } from '../../hooks/Tokens'
import { useCurrencyBalance, useDolomiteBalancesWithLoadingIndicator, useTokenBalances } from '../../state/wallet/hooks'
import {
  useFiatPricesPreviousDay,
  useFiatPricesWithLoadingIndicator,
  useFiatValuesWithExternalAssetsMap,
  useFiatValuesWithLoadingIndicator,
} from '../../hooks/useFiatValue'
import { ChainId, ONE_FRACTION, ZERO_FRACTION, ZERO_PERCENT } from '../../constants'
import { useInterestRateData } from '../../types/interestRateData'
import cleanCurrencySymbol from '../../utils/cleanCurrencySymbol'
import { Token } from '@dolomite-exchange/v2-sdk'
import { Currency, Ether, Fraction, Percent } from '@dolomite-exchange/sdk-core'
import Modal from '../Modal'
import SyncAltIcon from '@mui/icons-material/Autorenew'
import DoNotDisturbIcon from '@mui/icons-material/DoNotDisturb'
import GLPBottomSection from '../ManageAssetRewardsPopover/assets/GLP/GLPBottomSection'
import { getSpecialAsset, SpecialAssetSymbol } from '../../constants/isolation/special-assets'
import PLVGLPBottomSection from '../ManageAssetRewardsPopover/assets/PLVGLP/PLVGLPBottomSection'
import YtGLPBottomSection from '../ManageAssetRewardsPopover/assets/YT-GLP/YtGLPBottomSection'
import { formatAmount } from '../../utils/formatAmount'
import Input from '@material-ui/core/Input'
import { Settings as SettingsIcon, X } from 'react-feather'
import { useShowYieldAsApr, useUserHideDustBalances, useUserHideZeroBalances } from '../../state/user/hooks'
import { filterTokens, FilterType, useFilterTypes } from '../../constants/tokenLists/FilterTokens'
import { useMarketRiskInfoData } from '../../types/marketRiskInfoData'
import JUSDCBottomSection from '../ManageAssetRewardsPopover/assets/JUSDC/JUSDCBottomSection'
import VArbBottomSection from '../ManageAssetRewardsPopover/assets/vARB/VArbBottomSection'
import { useModalOpen, useToggleModal } from '../../state/application/hooks'
import { ApplicationModal } from '../../state/application/actions'
import ZapModal from '../../pages/Borrow/ZapModal'
import BoltIcon from '@mui/icons-material/Bolt'
import DepositWithdrawModal from '../DepositWithdrawModal'
import { hasExternalRewards } from '../../state/graphql/useLiquidityMiningRewardsInterestRates'
import invariant from 'tiny-invariant'
import { InterestRatePart } from '../../types/InterestRatePart'
import { mapExternalTokenToListedAddress } from '../../utils'
import {
  isExternalToken,
  mapExternalCurrencyOptToListedToken,
  useAddEtherBalanceToWalletBalances,
  useAddExternalTokensToDolomiteData,
} from '../../utils/externalTokens'
import ClimbingBearImg from '../../assets/images/climbing-bear.png'
import WMNTBottomSection from '../ManageAssetRewardsPopover/assets/WMNT/WMNTBottomSection'
import { useJUsdcPendingRewards } from '../../hooks/jusdc/useJonesProtocol'
import { usePlvGlpPendingRewards } from '../../hooks/plutus/usePlutusProtocol'
import useGmxPendingRewards from '../../hooks/gmx/useGmxRewards'
import { useDolomiteMarginTokenTvlData } from '../../types/dolomiteMarginTokenTvlData'
import TokenRow from './TokenRow'
import { isBerachain } from '../../constants/chainId'
import AddCircleIcon from '@material-ui/icons/AddCircleOutline'
import RemoveCircleOutlineIcon from '@material-ui/icons/RemoveCircleOutline'
import { theme } from '../../theme'
import Search from '@material-ui/icons/Search'
import HelpOutlinedIcon from '@material-ui/icons/HelpOutline'
import InfoSection from '../Info'
import useCloseBalancesInfo from '../../hooks/useCloseBalancesInfo'

const CUSTOM_BOTTOM_SECTION_COMPONENT_MAP: Record<string, JSX.Element | undefined> = {
  [SpecialAssetSymbol.dARB]: <VArbBottomSection />,
  [SpecialAssetSymbol.dGMX]: <GLPBottomSection />,
  [SpecialAssetSymbol.dfsGLP]: <GLPBottomSection />,
  [SpecialAssetSymbol.djUSDC]: <JUSDCBottomSection />,
  [SpecialAssetSymbol.dplvGLP]: <PLVGLPBottomSection />,
  [SpecialAssetSymbol.dYtGLPMarch2024]: <YtGLPBottomSection />,
  [SpecialAssetSymbol.dWMNT]: <WMNTBottomSection />,
}

const LendingPanelWrapper = styled.div`
  -webkit-border-radius: 8px;
  -moz-border-radius: 8px;
  border-radius: 8px;
  display: inline-block;
  //margin-bottom: 30px;
  padding: 20px 35px 20px;
  //vertical-align: top;
  width: 100%;
  font-family: 'Open Sans', sans-serif;
  /*background-color: #292938;
  box-shadow: 0 5px 5px -3px rgb(0 0 0 / 20%), 0 8px 10px 1px rgb(0 0 0 / 14%), 0 3px 14px 2px rgb(0 0 0 / 12%);*/
  //text-align: left;
  position: relative;
  margin-bottom: 40px;

  h3 {
    margin: 0;
  }

  @media screen and (max-width: 1000px) {
    padding: 15px 25px 16px 25px;
  }

  @media screen and (max-width: 660px) {
    margin: 0 auto 55px;
    padding: 15px 35px 20px;
  }

  @media screen and (max-width: 600px) {
    margin-top: -40px;
  }

  /*@media screen and (max-width: 415px) {
    margin: 0 auto 85px;
    padding: 5px 10px 20px;
  }*/
`

const ColumnHeaders = styled.div`
  width: 100%;
  display: block;
  font-weight: 500 !important;
  color: ${({ theme }) => theme.text1} !important;
  margin-bottom: 7px;
  font-size: 14px;
  @media screen and (max-width: 1000px) {
    font-size: 13px;
  }

  @media screen and (max-width: 800px) {
    font-size: 12px;
  }

  @media screen and (max-width: 470px) {
    font-size: 12px;
  }
`

export const Column = styled.div`
  //color: #d5d6e1;
  display: inline-block;
  //font-weight: 100;
  vertical-align: top;
  cursor: pointer;
`

const LoadingRates = styled.div`
  color: #d5d6e1;
color(text, tertiary);
  font-weight: 100;
  margin-top: 100px;
  text-align: center;

  > div {
    margin: 0 auto;
  }
`

export const TickerColumn = styled(Column)`
  text-align: left;
  width: 20%;

  @media screen and (max-width: 1000px) {
    width: calc(30% - 35px);
  }

  @media screen and (max-width: 950px) {
    width: calc(30% - 35px);
  }

  @media screen and (max-width: 900px) {
    width: calc(35% - 43.75px);
  }

  @media screen and (max-width: 750px) {
    width: 35%;
  }
  @media screen and (max-width: 600px) {
    width: 38%;
  }

  @media screen and (max-width: 400px) {
    width: 33%;
  }
`

const TickerTitle = styled(TickerColumn)`
  position: relative;

  @media screen and (max-width: 500px) {
    margin-right: -50px;
  }
`

export const SupplyColumn = styled(Column)`
  text-align: right;
  //margin-left: -20px !important;
  width: 12%;

  @media screen and (max-width: 1000px) {
    width: calc(15% - 35px);
  }
  @media screen and (max-width: 900px) {
    width: calc(18% - 43.75px);
    margin-left: -3%;
  }
  @media screen and (max-width: 750px) {
    width: 17%;
  }
  @media screen and (max-width: 600px) {
    width: 17%;
  }
  @media screen and (max-width: 400px) {
    width: 20%;
  }
`

const SupplyTitle = styled(SupplyColumn)`
  @media screen and (max-width: 500px) {
    width: calc(17% + 50px);
  }
  @media screen and (max-width: 400px) {
    width: calc(20% + 50px);
  }
`

export const BorrowColumn = styled(Column)`
  text-align: right;
  //margin-left: -20px !important;
  width: 12%;

  @media screen and (max-width: 1000px) {
    width: calc(15% - 35px);
  }

  @media screen and (max-width: 900px) {
    display: none;
  }

  /*@media screen and (max-width: 820px) {
    width: 25%;
  }
  @media screen and (max-width: 600px) {
    display: none;
  }*/
`

export const BalanceColumn = styled(Column)`
  text-align: right;
  width: 18%;
  position: relative;

  @media screen and (max-width: 1000px) {
    width: calc(20% - 35px);
  }

  @media screen and (max-width: 900px) {
    width: calc(25% - 43.75px);
  }

  @media screen and (max-width: 750px) {
    width: 24%;
  }
  @media screen and (max-width: 600px) {
    width: 24%;
  }
  @media screen and (max-width: 400px) {
    width: 25%;
  }
`

export const WalletBalanceColumn = styled(BalanceColumn)`
  /*@media screen and (max-width: 720px) {
    display: none;
  }*/
`

export const SupplyCapColumn = styled(Column)`
  width: 35%;
  text-align: right;
  padding-left: 8%;

  @media screen and (max-width: 1250px) {
    display: none;
  }
`

export const PriceColumn = styled(Column)`
  text-align: left;
  width: 35%;
  padding-left: 40px;

  @media screen and (max-width: 1250px) {
    width: 12%;
  }

  @media screen and (max-width: 1000px) {
    display: none;
  }
`

export const InfoColumn = styled(Column)`
  width: 20%;
  text-align: right;

  @media screen and (max-width: 600px) {
    display: none;
  }
`

export const ButtonsColumn = styled(Column)`
  width: 20%;

  @media screen and (max-width: 1000px) {
    width: 175px;
  }
`

export const TickerIcons = styled.div`
  display: inline-block;
  @media screen and (max-width: 415px) {
    display: none;
  }
`

const NoTradingIconWrapper = styled.div`
  display: inline-block;
  vertical-align: top;
  transform: scale(0.7);
  color: #8f91ad;

  svg:nth-of-type(1) {
    transform: rotate(45deg);
    width: 25px;
    height: 25px;
    color: inherit;
  }

  svg:nth-of-type(2) {
    margin-left: -20px;
    margin-bottom: -5px;
    width: 25px;
    height: 25px;
    display: none;
  }
`

const Slash = styled.div`
  height: 24px;
  width: 2.5px;
  transform: rotate(-40deg);
  background-color: #8f91ad;
  margin-top: -30px;
  margin-left: 11px;
`

export const NoTradingIcon = () => {
  return (
    <StyledTooltip
      title={'This asset cannot be borrowed or added to a liquidity pool, it can only act as collateral for borrowing.'}
      position={'top'}
    >
      <NoTradingIconWrapper>
        <SyncAltIcon />
        <DoNotDisturbIcon />
        <Slash />
      </NoTradingIconWrapper>
    </StyledTooltip>
  )
}

const TooltipText = styled.div`
  font-family: 'Open Sans', sans-serif !important;
  font-size: 13px;
  color: #f9f9f9;
  padding: 12px 6px;
`

const TooltipLine = styled.div`
  margin: 2px 0;
  width: 100%;
`

const TooltipLineTitle = styled.span<{ disabled?: boolean }>`
  font-weight: 400;
  color: ${({ disabled, theme }) => disabled && theme.bg4};
`

const TooltipLineValue = styled.span<{ disabled?: boolean }>`
  font-weight: 600;
  color: ${({ disabled, theme }) => (disabled ? theme.bg4 : theme.green1)};
  float: right;
  margin-left: 10px;
`

const HeaderWrapper = styled.div`
  width: 100%;
  margin: 15px 0 -5px;

  @media screen and (max-width: 1000px) {
    width: calc(100% + 20px);
    margin-left: -10px;
  }
  @media screen and (max-width: 750px) {
    margin-bottom: -25px;
    margin-top: 60px;
  }
  @media screen and (max-width: 650px) {
    width: calc(100% + 40px);
    margin-left: -20px;
  }
  @media screen and (max-width: 415px) {
    padding: 0 10px;
  }
`

const ActionWrapper = styled.div`
  width: 100%;
  margin-bottom: 16px;
  height: 36px;
  position: relative;
  z-index: 4;

  @media screen and (max-width: 1100px) {
    margin-bottom: 26px;
  }

  @media screen and (max-width: 750px) {
    display: flex;
    flex-direction: column;
    height: 70px;
    width: 100%;
  }
`

const IconWrapper = styled.div`
  margin-top: 4px;
  display: inline-block;
  width: 100%;
`

const FilterSection = styled.div<{ expanded?: boolean }>`
  position: relative;
  width: fit-content;
  display: inline-block;
  vertical-align: top;
  text-align: left;
  float: left;
  height: 36px;
  opacity: ${({ expanded }) => (expanded ? 1 : 0)};
  transform: ${({ expanded }) => (expanded ? 'translateY(0)' : 'translateY(10px)')};
  pointer-events: ${({ expanded }) => (expanded ? 'auto' : 'none')};
  transition: opacity 0.2s, transform 0.2s;

  @media screen and (max-width: 1100px) {
    margin-left: -35px;
    margin-right: -150px;
  }

  @media screen and (max-width: 750px) {
    display: none;
  }

  /*@media screen and (max-width: 750px) {
    opacity: 1;
    transform: none;
    pointer-events: auto;
  }*/

  @media screen and (max-width: 380px) {
    width: calc(100% + 70px);
  }
`

const AprSelect = styled.div`
  display: inline-block;
  margin-top: 2px;
  margin-bottom: 4px;
  border-radius: 5px;
    /*background-color: ${({ theme }) => theme.bg6};*/
  background-color: #2d2c39;
  font-size: 14px;
  cursor: pointer;

  :hover {
    background-color: ${({ theme }) => theme.bg2};
  }

  @media screen and (max-width: 861px) {
    position: absolute;
    left: -288px;
    top: -42px;
  }

  @media screen and (max-width: 500px) {
    margin-bottom: 6px;
    font-size: 12px;
  }

  //@media screen and (max-width: 1500px) {
  //  margin-right: -215px;
  //}
  //
  //@media screen and (max-width: 1300px) {
  //  margin-right: -200px;
  //}
  //
  //@media screen and (max-width: 1250px) {
  //  margin-right: 15px;
  //}
  //
  //@media screen and (max-width: 780px) {
  //  margin-right: 0;
  //  vertical-align: top;
  //  margin-top: 10px;
  //  margin-left: 10px;
  //}
`

const AprSelector = styled.div<{ active: boolean }>`
  width: 50px;
  display: inline-block;
  padding: 5px 2px;
  border-radius: 5px;
  text-align: center;
  background-color: ${({ active, theme }) => active && theme.bg4};

  @media screen and (max-width: 350px) {
    width: 40px;
  }
`

const FilterSectionMobile = styled(FilterSection)`
  display: none;
  transition: none;
  opacity: 1;
  pointer-events: all;
  float: none;
  transform: none;
  height: 77px;
  margin-bottom: 2px;
  width: calc(100% + 70px);

  > div:nth-of-type(1) {
    width: 100%;
    margin-left: 0;
    padding-right: 0;
  }

  @media screen and (max-width: 750px) {
    display: block;

    ${AprSelect} {
      position: relative;
      left: 0;
      top: 0;
    }
  }
`

const InputWrapper = styled.div`
  position: relative;
  vertical-align: top;
  width: 250px;
  display: inline-block;

  input {
    height: 20px !important;
    color: #f9f9f9 !important;
    display: inline-flex !important;
    position: relative !important;
    font-size: 1rem !important;
    /*background: #1e1c29 !important;*/
    background: #2d2c39 !important;
    font-family: Open Sans, serif !important;
    line-height: 1.1875em !important;
    font-weight: 300 !important;
    border-radius: 5px !important;
    padding-left: 10px !important;
    padding-right: 10px !important;
  }

  > div {
    margin-top: 3px;
    padding-top: 0;
    margin-bottom: 0 !important;
    padding-bottom: 0 !important;
  }

  > div > div {
    padding-top: 0 !important;
  }
`

const InputOverflowFix = styled.div`
  height: 33px;
  overflow: hidden;
`

const StyledInput = styled(({ ...props }) => <Input {...props} />)<{ multiline: boolean }>`
  overflow: hidden;
  margin-bottom: 0 !important;
  height: 33px !important;

  input {
    margin-bottom: 0 !important;
    font-size: 15px !important;
  }

  &::placeholder {
    color: #f9f9f9 !important;
  }
  &::-webkit-input-placeholder {
    color: #f9f9f9 !important;
  }

  &::-moz-placeholder {
    color: #f9f9f9 !important;
  }

  ${({ multiline }) =>
    multiline &&
    `
    margin-top: 2px;
    width: 100% !important;

    textarea {
      overflow: hidden !important;
      padding: 0 8px !important;
      width: calc(100% - 8px) !important;
    }
  `};
  @media (max-width: 1400px) {
    input {
      font-size: 0.9rem;
    }

    p {
      font-size: 0.8rem;
    }
  }

  @media (max-width: 375px) {
    input {
      font-size: 12px !important;
    }
  }
`

const Clear = styled.div<{ disabled: boolean }>`
  color: ${({ theme }) => theme.text3};
  font-weight: 500;
  position: absolute;
  right: 3px;
  top: 7px;
  display: inline-block;
  ${({ disabled }) => disabled && `display: none`};
`

const Close = styled(X)`
  cursor: pointer;
  height: 18px;
`

const ZeroBalances = styled.div<{ visible: boolean }>`
  text-align: left;
  vertical-align: top;
  font-size: 14px;
  color: ${({ theme }) => theme.text2};
  margin-top: 7px;
  margin-bottom: 2px;
  cursor: pointer;
  display: ${({ visible }) => (visible ? 'inline-block' : 'none')};

  @media screen and (max-width: 500px) {
    font-size: 12px;

    :nth-of-type(3) {
      margin-right: 0;
    }
  }

  //@media screen and (max-width: 1500px) {
  //  margin-left: -215px;
  //}
  //
  //@media screen and (max-width: 1300px) {
  //  margin-left: -200px;
  //}
  //
  //@media screen and (max-width: 1250px) {
  //  margin-left: 15px;
  //}
  //
  //@media screen and (max-width: 780px) {
  //  /*text-align: left;*/
  //  margin-left: 0;
  //  margin-top: 18px;
  //}
  //
  //@media screen and (max-width: 375px) {
  //  font-size: 12px;
  //}
`

const Checkbox = styled.div`
  display: inline-block;
  vertical-align: top;
  height: 14px;
  width: 14px;
  border: 1px solid #f9f9f9;
  background: none;
  border-radius: 3px;
  cursor: pointer;
  margin-right: 5px;
  margin-top: 2px;
`

const Checked = styled.div<{ isChecked: boolean }>`
  height: 8px;
  width: 8px;
  background: ${({ isChecked }) => (isChecked ? '#f9f9f9' : 'none')};
  border-radius: 1px;
  margin-left: 2px;
  margin-top: 2px;
`

const FilterSelect = styled.div<{ expanded: boolean }>`
    /*background-color: ${({ theme }) => theme.bg6};*/
  background-color: #2d2c39;
  border-radius: 5px;
  cursor: pointer;
  height: 28px;
  margin-top: 3px;
  overflow: hidden;
  width: 150px;
  //left: calc(100% - 90px);
  top: 0;
  right: 0;
  position: absolute;
  z-index: 4;

  ${({ expanded }) =>
    expanded &&
    `
    border-top-left-radius: 5px;
    height: fit-content !important;
    box-shadow: 0 5px 5px -3px rgb(0 0 0 / 20%), 0 8px 10px 1px rgb(0 0 0 / 14%), 0 3px 14px 2px rgb(0 0 0 / 12%);
  `};

  @media screen and (max-width: 861px) {
    position: absolute;
    left: -178px;
    top: -42px;
    width: 140px;
  }

  @media screen and (max-width: 500px) {
    width: 90px;
  }
`

const AdvancedPopout = styled.div<{ open: boolean }>`
  width: 190px;
  height: 175px;
  position: absolute;
  left: 0;
  top: 40px;
  text-align: left;
  box-shadow: 0 5px 5px -3px rgb(0 0 0 / 20%), 0 8px 10px 1px rgb(0 0 0 / 14%), 0 3px 14px 2px rgb(0 0 0 / 12%);
  background-color: #3a3a4f;
  border-radius: 5px;
  z-index: 5;
  padding: 10px 20px;
  display: ${({ open }) => (open ? 'inline-block' : 'none')};
  @media screen and (max-width: 720px) {
    top: 50px;
  }
`

const FilterSelectRow = styled.div`
  font-size: 14px;
  font-weight: 300;
  padding: 5px 10px;
  height: 28px;
  line-height: 18px;

  &:hover {
    background-color: #474956;
  }
`

const FilterWrapper = styled.div`
  position: relative;
  width: 140px;
  height: 37px;

  ${FilterSelect} {
    top: 0;
    left: 0;
  }

  @media screen and (max-width: 500px) {
    width: 90px;
  }
`

const ArrowDown = styled.div`
  width: 0;
  height: 0;
  position: absolute;
  border-left: 5px solid transparent;
  border-right: 5px solid transparent;
  border-top: 6px solid #606375;
  top: 11px;
  right: 7px;
`

const BasicFilters = styled.div`
  width: 100%;
  position: relative;
  display: flex;

  > div {
    margin-right: 10px;

    div {
      height: 37px;
      flex: 1;

      div {
        height: 37px !important;
        font-size: 15px;
        line-height: 24px;

        input {
          height: 24px !important;
        }
      }

      ${FilterSelectRow} {
        padding: 6px 10px 7px;
      }

      ${ArrowDown} {
        top: 16px;
        right: 10px;
      }
    }
  }

  svg {
    margin-top: 12px;
  }

  @media screen and (max-width: 500px) {
    ${InputWrapper} {
      width: auto;
      flex: 1;
    }

    svg {
      margin-top: 9px;
    }

    > div {
      margin-right: 10px;

      div {
        height: 33px;
        flex: 1;

        div {
          height: 33px !important;
          font-size: 12px;
          line-height: 17px;

          input {
            height: 20px !important;
            font-size: 12px !important;
          }
        }

        ${FilterSelectRow} {
          padding: 8px 10px 7px;
        }

        ${ArrowDown} {
          top: 16px;
          right: 10px;
        }
      }
    }
  }
`

const HideOnSmall = styled.span`
  @media screen and (max-width: 640px) {
    display: none;
  }
`

const NoTokens = styled.div`
  height: 40px;
  margin: 25px 0 15px;
  width: 100%;
  text-align: center;
`

const HideBalances = styled(NoTokens)`
  margin: 15px 0 0;
`

const OArbRewardsBreakdown = styled.div<{ disabled?: boolean }>`
  font-size: 10px;
  color: ${({ disabled, theme }) => (disabled ? theme.bg4 : theme.text2)};

  > div > div:nth-of-type(1) {
    width: 60%;
    text-align: right;
    display: inline-block;
    vertical-align: top;
  }

  > div > div:nth-of-type(2) {
    width: 40%;
    text-align: right;
    display: inline-block;
    vertical-align: top;
  }
`

const ButtonsWrapper = styled.div`
  width: fit-content;
  float: right;
  position: relative;
  vertical-align: top;
  margin-top: 5px;
  display: flex;
  justify-content: space-between;
  transform: translateY(28px);

  > div {
    margin-left: 6px;
  }

  > div:nth-child(2),
  > div:nth-child(3) {
    display: none;
  }

  @media screen and (max-width: 1100px) {
    transform: translateY(38px);
  }

  @media screen and (max-width: 1000px) {
    margin-right: -35px;
  }

  @media screen and (max-width: 750px) {
    transform: none;
    width: calc(100% + 70px);
    margin-left: -35px;
    display: flex;
    justify-content: space-between;

    > div:nth-child(2),
    > div:nth-child(3) {
      display: inline-block;
    }
  }

  @media screen and (max-width: 380px) {
    margin-top: 0px;
  }
`

const Button = styled.div`
  width: fit-content;
  text-align: center;
  margin-top: 2px;
  padding: 7px 12px;
  height: 33px;
  border-radius: 5px;
  color: ${({ theme }) => theme.text1};
  background-color: ${({ theme }) => theme.blue1};
  cursor: pointer;
  font-weight: 500;
  font-size: 14px;
  display: inline-block;
  text-decoration: none;
  vertical-align: top;
  svg {
    color: ${({ theme }) => theme.text1};
    margin-right: -5px;
    margin-left: -5px;
    margin-top: -2px;
  }

  :hover {
    background-color: ${({ theme }) => theme.blue2};
  }

  @media screen and (max-width: 750px) {
    flex: 1;
    height: 39px;
    padding-top: 10px;
    background: ${({ theme }) => theme.bg1};
    /*box-shadow: 0 5px 5px -3px rgb(0 0 0 / 20%), 0 8px 10px 1px rgb(0 0 0 / 14%), 0 3px 14px 2px rgb(0 0 0 / 12%);*/
    margin-left: 0 !important;

    :nth-of-type(3) {
      margin-left: 10px !important;
      margin-right: 10px !important;
    }

    :nth-of-type(4) {
      background-color: ${({ theme }) => theme.blue1};
    }
  }

  @media screen and (max-width: 500px) {
    font-size: 12px;
    height: 33px;
    padding-top: 8px;

    svg {
      margin-right: -5px;
      margin-left: -7px;
      margin-top: -1px;
      height: 20px;
    }
  }

  @media screen and (max-width: 380px) {
    width: calc(33.33% - 10px);
    margin: 5px 0 15px 0;
  }
`

export const BalanceButton = styled(Button)`
  background-color: ${({ theme }) => '#3a3a4f'};
  :hover {
    background-color: ${({ theme }) => '#474956'};
  }

  svg {
    color: ${({ theme }) => theme.text1};
    margin-right: 0px;
    margin-left: 3px;
    margin-top: 1px;
    font-size: 17px;

    @media screen and (max-width: 450px) {
      display: none;
    }
  }

  @media screen and (max-width: 750px) {
    flex: 1;
  }
`

const OptionsButton = styled.div<{ rotate?: boolean }>`
  position: relative;
  display: inline-flex;
  vertical-align: top;
  margin-top: 4px;
  margin-right: -5px;
  margin-left: 8px;
  width: fit-content;

  svg {
    cursor: pointer;
    transform: ${({ rotate }) => (rotate ? 'rotate(60deg)' : 'rotate(0deg)')};
    transition: transform 0.2s;
  }

  @media screen and (max-width: 750px) {
    margin-left: 0 !important;
    display: none !important;

    svg {
      margin-left: 0 !important;
    }
  }
`

const InlineSettingsWrapper = styled.div<{ isVisible: boolean }>`
  display: inline-block;
  width: fit-content;
  position: relative;
  padding-right: 170px;
  margin-left: 10px;
  vertical-align: top;
  justify-content: space-between;
  pointer-events: ${({ isVisible }) => (isVisible ? 'auto' : 'none')};
  opacity: ${({ isVisible }) => (isVisible ? 1 : 0)};
  transform: translateX(${({ isVisible }) => (isVisible ? '0' : '-10px')});
  transition: opacity 0.2s, transform 0.2s;

  > div {
    margin-right: 20px;
  }

  @media screen and (max-width: 500px) {
    > div {
      margin-right: 10px;
    }
  }
`

const DepositWithdrawButton = styled(Button)`
  width: calc(50% - 5px) !important;
  background-color: ${({ theme }) => '#3a3a4f'};
  color: ${({ theme }) => theme.text1};

  :hover {
    background-color: ${({ theme }) => '#474956'};
  }
`

const DepositWithdrawButtons = styled.div`
  width: 100%;
  display: none;

  @media screen and (max-width: 720px) {
    display: flex;
    justify-content: space-between;
  }
`

export const ButtonText = styled.div`
  display: inline-block;
  vertical-align: top;
  font-weight: 500;
`

const Exclamation = styled.div`
  font-size: 14px;
  color: ${({ theme }) => theme.text1};
  position: absolute;
  right: 6px;
  top: 2px;
  font-weight: 800;
  font-family: 'Open Sans', sans-serif;
  display: none;
`

const ClimbingBear = styled.img<{ didSlide: boolean }>`
  cursor: ${({ didSlide }) => (didSlide ? 'default' : 'pointer')};
  width: 90px;
  position: absolute;
  right: -27px;
  z-index: 2;
  top: ${({ didSlide }) => (didSlide ? 'calc(100% - 75px)' : '20px')};
  transform: rotate(6deg);
  transition: top 1s cubic-bezier(0.82, 0, 0.86, 1.01);

  ${({ didSlide }) =>
    !didSlide &&
    `:active {
    transform: rotate(8deg) scale(0.96);
    
    + ${Exclamation} {
      display: inline-block;
    }
  }`}
`

const StyledMenuIcon = styled(SettingsIcon)`
  height: 20px;
  width: 20px;

  > * {
    stroke: ${({ theme }) => theme.text2};
  }
  margin-top: 5px;
`

const StyledHelpIcon = styled(HelpOutlinedIcon)`
  color: ${({ theme }) => theme.text2};
  margin: 3px 6px 0 8px;
  svg {
    height: 24px;
    width: 24px;
    font-size: 24px;
  }
`

const SearchIcon = styled(Search)`
  display: inline !important;
  width: 20px !important;
  height: 20px !important;
  margin-top: 0px;
  vertical-align: top;
  color: ${({ theme }) => theme.text2};

  @media screen and (max-width: 750px) {
    display: none !important;
  }
`

export const HideSelectedIcon = styled.div<{ expanded: boolean; search?: boolean }>`
  position: absolute;
  font-size: 11px !important;
  font-weight: 700;
  ${({ search }) =>
    !search &&
    `
    top: -6px;
    left: 11px;
  `}
  ${({ search }) =>
    search &&
    `
    margin-top: -7px;
    margin-left: -10px;
  `}
  z-index: 6;
  height: 16px;
  width: 16px;
  background-color: ${({ theme }) => theme.text2};
  color: ${({ theme }) => theme.bg1};
  border-radius: 50%;
  padding-left: 5px;
  display: ${({ expanded }) => (!expanded ? 'inline-block' : 'none')};
`

interface YieldTooltipProps {
  interestRateParts: InterestRatePart[]
  lendingYield: Percent | undefined
  totalSupplyYield: Percent | undefined
  showYieldAsApr: boolean
  isBorrowRate: boolean
  leverage?: number
  includeRates?: boolean
  rateParts?: string[]
  newRate?: Percent
  pendleFixedRate?: Fraction
}

export const YieldTooltip = ({
  interestRateParts,
  lendingYield,
  showYieldAsApr,
  isBorrowRate,
  leverage,
  includeRates,
  rateParts,
  newRate,
  pendleFixedRate,
}: YieldTooltipProps) => {
  return (
    <TooltipText>
      {lendingYield && !lendingYield.equalTo(ZERO_PERCENT) && (
        <TooltipLine>
          <TooltipLineTitle>
            {isBorrowRate ? 'Borrow' : 'Lending'} {showYieldAsApr ? 'APR' : 'APY'}:{' '}
          </TooltipLineTitle>
          <StyledTooltip
            title={
              leverage
                ? `Base rate of ${(newRate ?? lendingYield).toFixed(2)}% with a leverage of ${leverage}x`
                : undefined
            }
            placement={'top'}
          >
            <TooltipLineValue>
              {formatAmount((newRate ?? lendingYield).multiply(leverage ? leverage : 1))}
            </TooltipLineValue>
          </StyledTooltip>
        </TooltipLine>
      )}
      {interestRateParts.map((part, index) => {
        const disabled = includeRates && !rateParts?.includes(part.label)
        if (!part.interestRate) {
          return (
            <TooltipLine key={index}>
              <TooltipLineTitle disabled={disabled}>
                {leverage && part.metadata
                  ? `+${part.metadata.units * leverage} ${part.metadata.unitsLabel}`
                  : part.label}
              </TooltipLineTitle>
            </TooltipLine>
          )
        }
        return (
          <TooltipLine key={index}>
            <TooltipLineTitle disabled={disabled}>{part.label}: </TooltipLineTitle>
            <StyledTooltip
              title={
                leverage
                  ? `Base rate of ${
                      part.label.includes('Pendle Fixed') && pendleFixedRate
                        ? Percent.fromFraction(pendleFixedRate.asFraction).toFixed(2)
                        : part.interestRate.toFixed(2)
                    }% with a leverage of ${leverage}`
                  : undefined
              }
              placement={'top'}
            >
              <TooltipLineValue disabled={disabled}>
                {formatAmount(
                  part.label.includes('Pendle Fixed') && pendleFixedRate
                    ? Percent.fromFraction(pendleFixedRate.asFraction).multiply(leverage ? leverage : 1)
                    : part.interestRate.multiply(leverage ? leverage : 1),
                )}
              </TooltipLineValue>
            </StyledTooltip>
            {part.label.startsWith('oARB Rewards') && (
              <OArbRewardsBreakdown disabled={disabled}>
                <div
                  style={{
                    display: 'flex',
                    justifyContent: 'space-between',
                  }}
                >
                  <div>100% Discount:</div>
                  <div>{formatAmount(part.interestRate.multiply(leverage ? leverage : 1))}</div>
                </div>
                <div
                  style={{
                    display: 'flex',
                    justifyContent: 'space-between',
                  }}
                >
                  <div>50% Discount:</div>
                  <div>{formatAmount(part.interestRate.multiply(leverage ? leverage : 1).divide(2))}</div>
                </div>
                <div
                  style={{
                    display: 'flex',
                    justifyContent: 'space-between',
                  }}
                >
                  <div>25% Discount:</div>
                  <div>{formatAmount(part.interestRate.multiply(leverage ? leverage : 1).divide(4))}</div>
                </div>
              </OArbRewardsBreakdown>
            )}
            {part.label.startsWith('goARB Rewards') && (
              <OArbRewardsBreakdown disabled={disabled}>
                <div
                  style={{
                    display: 'flex',
                    justifyContent: 'space-between',
                  }}
                >
                  <div>100% Discount:</div>
                  <div>{formatAmount(part.interestRate.multiply(leverage ? leverage : 1))}</div>
                </div>
                <div
                  style={{
                    display: 'flex',
                    justifyContent: 'space-between',
                  }}
                >
                  <div>20% (instant vest):</div>
                  <div>{formatAmount(part.interestRate.multiply(leverage ? leverage : 1).divide(5))}</div>
                </div>
              </OArbRewardsBreakdown>
            )}
          </TooltipLine>
        )
      })}
    </TooltipText>
  )
}

const initialExpandedMap = Object.values(SpecialAssetSymbol).reduce<Record<string, boolean>>((memo, symbol) => {
  memo[symbol] = !!CUSTOM_BOTTOM_SECTION_COMPONENT_MAP[symbol]
  return memo
}, {})

enum SortField {
  DolomiteBalance = 'DolomiteBalance',
  WalletBalance = 'WalletBalance',
  InterestRate = 'InterestRate',
  Name = 'Name',
  Price = 'Price',
  Cap = 'Cap',
}

const ONE_CENT_FRACTION = new Fraction('1', '100')

export default function BalancesPanel() {
  const { t } = useTranslation()
  const { account, chainId } = useActiveWeb3React()
  const ether = useEther()
  const allTokenList = useAllActiveTokensArray()
  const allTokenListWithExternalTokens = useAllActiveTokensArrayWithExternalTokens()
  const allCurrencyList = useMemo(() => [ether, ...allTokenListWithExternalTokens], [
    allTokenListWithExternalTokens,
    ether,
  ])
  const [didSlide, setDidSlide] = useState(false)

  const [rawDolomiteBalanceData] = useDolomiteBalancesWithLoadingIndicator(account, allTokenList)
  const dolomiteBalanceData = useAddExternalTokensToDolomiteData(rawDolomiteBalanceData)

  const [rawDolomiteFiatBalanceData] = useFiatValuesWithLoadingIndicator(
    dolomiteBalanceData,
    allTokenListWithExternalTokens,
  )
  const dolomiteFiatBalanceData = useAddExternalTokensToDolomiteData(rawDolomiteFiatBalanceData)

  const ethBalance = useCurrencyBalance(account, ether)
  const rawWalletBalanceMap = useTokenBalances(account, allTokenListWithExternalTokens)
  const walletFiatBalanceMap = useFiatValuesWithExternalAssetsMap(
    rawWalletBalanceMap,
    allTokenListWithExternalTokens,
    ethBalance,
  )
  const [priceBalanceMap] = useFiatPricesWithLoadingIndicator(allTokenListWithExternalTokens)
  const walletBalanceMap = useAddEtherBalanceToWalletBalances(ethBalance, rawWalletBalanceMap)
  const [fiatPricePreviousDay] = useFiatPricesPreviousDay()

  const { data: marketRiskInfoMap } = useMarketRiskInfoData()
  const tokenTvlMap = useDolomiteMarginTokenTvlData()
  const [expandedRowMap, setExpandedRowMap] = useState<Record<string, boolean | undefined>>({})
  const [popoverComponent, setPopoverComponent] = useState<JSX.Element | undefined>(undefined)
  const [customPopoverWidth, setCustomPopoverWidth] = useState<number | undefined>(undefined)
  const [sortField, setSortField] = useState<SortField>(SortField.DolomiteBalance)
  const [sortDirection, setSortDirection] = useState<boolean>(true)
  const filterWrapperRef = React.useRef<HTMLDivElement>(null)
  const filterWrapperDesktopRef = React.useRef<HTMLDivElement>(null)
  const wrapperRef = React.useRef<HTMLDivElement>(null)
  const [inputValue, setInputValue] = useState<string>('')
  const [hideZeroBalances, setHideZeroBalances] = useUserHideZeroBalances()
  const [hideDustBalances, setHideDustBalances] = useUserHideDustBalances()
  const [tokenSearch, setTokenSearch] = useState<boolean>(false)
  const [showYieldAsApr, setShowYieldAsApr] = useShowYieldAsApr()
  const [filterSelectOpen, setFilterSelectOpen] = useState<boolean>(false)
  const [desktopFilterSelectOpen, setDesktopFilterSelectOpen] = useState<boolean>(false)
  const [optionsMenuOpen, setOptionsMenuOpen] = useState<boolean>(false)
  const filterTypes = useFilterTypes()
  const [tokenFilterType, setTokenFilterType] = useState<FilterType>(FilterType.NONE)
  const isDepositModalOpen = useModalOpen(ApplicationModal.DEPOSIT_WITHDRAW)
  const toggleShowDepositModal = useToggleModal(ApplicationModal.DEPOSIT_WITHDRAW)
  const [isWithdrawModal, setWithdrawModal] = useState<boolean>(false)
  const [depositModalCurrency, setDepositModalCurrency] = useState<Currency | undefined>(undefined)
  const node = useRef<HTMLDivElement>()
  const isSwapModalOpen = useModalOpen(ApplicationModal.SWAP)
  const toggleShowSwapModal = useToggleModal(ApplicationModal.SWAP)
  const [gmxRewards] = useGmxPendingRewards()
  const [jUSDCRewards] = useJUsdcPendingRewards()
  const [plvRewards] = usePlvGlpPendingRewards()
  const [closeInfo, setCloseInfo] = useState<boolean>(true)
  const totalGmxRewards = useMemo(
    () =>
      gmxRewards?.totalNativeTokenRewardsUsd
        .add(gmxRewards?.totalVesterRewardsUsd)
        .add(gmxRewards?.extendedGmxTokenRewards),
    [gmxRewards],
  )
  const RewardValues: Record<string, Fraction | undefined> = useMemo(() => {
    return {
      [SpecialAssetSymbol.dGMX]: totalGmxRewards,
      [SpecialAssetSymbol.dfsGLP]: totalGmxRewards,
      [SpecialAssetSymbol.djUSDC]: jUSDCRewards,
      [SpecialAssetSymbol.dplvGLP]: plvRewards,
    }
  }, [totalGmxRewards, jUSDCRewards, plvRewards])

  const { loading: isInterestRateMapLoading, data: interestRateMap } = useInterestRateData()

  /* TODO - split color in top half vs bottom half */

  /* TODO - update styling on tooltips */

  const setExpandedCallback = useCallback((currency: Currency) => {
    setExpandedRowMap(prevMap => {
      return {
        ...prevMap,
        [currency.isNative ? '0x' : currency.address ?? '']: !prevMap[
          currency.isNative ? '0x' : currency.address ?? ''
        ],
      }
    })
  }, [])

  const handleSort = useCallback(
    (newField: SortField) => {
      setSortField(newField)
      setSortDirection(oldSortDirection => {
        if (sortField !== newField) {
          // Reset it. For name fields, we want to default to ascending
          return newField !== SortField.Name
        }
        // Flip it
        return !oldSortDirection
      })
    },
    [sortField],
  )

  const getArrow = useCallback(
    (field: SortField) => {
      return sortField === field ? (!sortDirection ? '↑' : '↓') : ''
    },
    [sortDirection, sortField],
  )

  const selectFilter = (field: FilterType) => {
    setTokenFilterType(field)
    setFilterSelectOpen(false)
    setDesktopFilterSelectOpen(false)
  }

  const handleClickOutside = useCallback((event: MouseEvent) => {
    if (wrapperRef?.current && !wrapperRef?.current.contains(event.target as Node)) {
      setOptionsMenuOpen(false)
    }
    if (filterWrapperRef?.current && !filterWrapperRef?.current.contains(event.target as Node)) {
      setFilterSelectOpen(false)
    }
    if (filterWrapperDesktopRef?.current && !filterWrapperDesktopRef?.current.contains(event.target as Node)) {
      setDesktopFilterSelectOpen(false)
    }
  }, [])

  useEffect(() => {
    document.addEventListener('mousedown', handleClickOutside)
    return () => document.removeEventListener('mousedown', handleClickOutside)
  }, [handleClickOutside])

  useEffect(() => {
    setTokenFilterType(FilterType.NONE)
  }, [chainId])

  const filteredTokens = useMemo(() => {
    return filterTokens(allCurrencyList, tokenFilterType, inputValue, chainId)
  }, [inputValue, allCurrencyList, tokenFilterType, chainId])

  const depositModal = useCallback(
    (presetCurrency?: Currency) => {
      setDepositModalCurrency(presetCurrency)
      setWithdrawModal(false)
      toggleShowDepositModal()
    },
    [toggleShowDepositModal],
  )
  const withdrawModal = useCallback(
    (presetCurrency?: Currency) => {
      setDepositModalCurrency(presetCurrency)
      setWithdrawModal(true)
      toggleShowDepositModal()
    },
    [toggleShowDepositModal],
  )

  const handleAdvancedModal = useCallback(() => {
    setOptionsMenuOpen(!optionsMenuOpen)
  }, [optionsMenuOpen])

  const handleOpenDepositModal = useCallback(() => {
    depositModal()
  }, [depositModal])

  const handleOpenWithdrawModal = useCallback(() => {
    withdrawModal()
  }, [withdrawModal])

  const handleCloseDepositModal = useCallback(() => {
    toggleShowDepositModal()
    setDepositModalCurrency(undefined)
  }, [toggleShowDepositModal])

  const sortedCurrencies = useMemo(() => {
    const nativeSymbol = Ether.onChain(chainId).symbol ?? ''
    return [...filteredTokens].sort((a: Currency, b: Currency) => {
      const symbolA = cleanCurrencySymbol(a, false)?.toUpperCase() ?? ''
      const symbolB = cleanCurrencySymbol(b, false)?.toUpperCase() ?? ''
      const addressA = nativeSymbol === cleanCurrencySymbol(a, false) ? nativeSymbol : a.wrapped.address
      const addressB = nativeSymbol === cleanCurrencySymbol(b, false) ? nativeSymbol : b.wrapped.address
      const aRewards = RewardValues[a.symbol ?? ''] ?? ZERO_FRACTION
      const aDolomiteFiat = dolomiteFiatBalanceData[addressA] ?? ZERO_FRACTION
      const bDolomiteFiat = dolomiteFiatBalanceData[addressB] ?? ZERO_FRACTION
      const aWalletFiat = walletFiatBalanceMap[addressA] ?? ZERO_FRACTION
      const bWalletFiat = walletFiatBalanceMap[addressB] ?? ZERO_FRACTION
      const interestRateA = interestRateMap[addressA]?.totalSupplyInterestRate ?? ZERO_PERCENT
      const interestRateB = interestRateMap[addressB]?.totalSupplyInterestRate ?? ZERO_PERCENT
      const sort = sortDirection ? 1 : -1

      const symbolSorter = symbolA.localeCompare(symbolB) < 0 ? -1 : 1
      const interestRateSorter = interestRateA.lessThan(interestRateB) ? 1 : -1

      if (sortField === SortField.Name) {
        return symbolA === symbolB ? interestRateSorter : symbolSorter * -sort
      } else if (sortField === SortField.InterestRate) {
        return interestRateA.equalTo(interestRateB) ? symbolSorter : interestRateSorter * sort
      } else if (sortField === SortField.Price) {
        const remappedTokenAddressA = mapExternalTokenToListedAddress(a.wrapped)
        const remappedTokenAddressB = mapExternalTokenToListedAddress(b.wrapped)
        const priceA = priceBalanceMap[remappedTokenAddressA] ?? ZERO_PERCENT
        const priceB = priceBalanceMap[remappedTokenAddressB] ?? ZERO_PERCENT
        const priceSorter = priceA.lessThan(priceB) ? 1 : -1
        return priceA.equalTo(priceB) ? symbolSorter : priceSorter * sort
      } else if (sortField === SortField.DolomiteBalance) {
        if (aRewards.greaterThan(ZERO_FRACTION)) {
          const aPrice = priceBalanceMap[a.wrapped.address ?? ''] ?? ZERO_FRACTION
          const aRewardsFiat =
            SpecialAssetSymbol.dfsGLP === a.symbol || SpecialAssetSymbol.dGMX === a.symbol
              ? aRewards
              : aRewards.multiply(aPrice)
          if (aRewardsFiat.greaterThan(ONE_FRACTION)) return -1
        }
        if (aDolomiteFiat.equalTo(ZERO_FRACTION) && bDolomiteFiat.equalTo(ZERO_FRACTION)) {
          if (aRewards.greaterThan(ZERO_FRACTION)) return -sort
          return aWalletFiat.equalTo(bWalletFiat) ? symbolSorter : (aWalletFiat.lessThan(bWalletFiat) ? 1 : -1) * sort
        } else {
          return (aDolomiteFiat.lessThan(bDolomiteFiat) ? 1 : -1) * sort
        }
      } else if (sortField === SortField.Cap) {
        const remappedTokenAddressA = mapExternalTokenToListedAddress(a.wrapped)
        const remappedTokenAddressB = mapExternalTokenToListedAddress(b.wrapped)
        const aSupplyTvl = tokenTvlMap[remappedTokenAddressA]?.supplyLiquidity
        const aSupplyCap = marketRiskInfoMap[remappedTokenAddressA]?.supplyMaxWei
        const bSupplyTvl = tokenTvlMap[remappedTokenAddressB]?.supplyLiquidity
        const bSupplyCap = marketRiskInfoMap[remappedTokenAddressB]?.supplyMaxWei
        if (!aSupplyCap) return sort
        if (aSupplyCap && bSupplyCap && aSupplyTvl && bSupplyTvl) {
          return (aSupplyTvl.divide(aSupplyCap).lessThan(bSupplyTvl.divide(bSupplyCap)) ? 1 : -1) * sort
        }
        return sort * -1
      } else {
        invariant(sortField === SortField.WalletBalance, `Invalid sort field, found ${sortField}`)
        if (aWalletFiat.equalTo(ZERO_FRACTION) && bWalletFiat.equalTo(ZERO_FRACTION)) {
          return aDolomiteFiat.equalTo(bDolomiteFiat)
            ? symbolSorter
            : (aDolomiteFiat.lessThan(bDolomiteFiat) ? 1 : -1) * sort
        } else {
          return (aWalletFiat.lessThan(bWalletFiat) ? 1 : -1) * sort
        }
      }
    })
  }, [
    sortField,
    sortDirection,
    chainId,
    filteredTokens,
    dolomiteFiatBalanceData,
    walletFiatBalanceMap,
    interestRateMap,
    RewardValues,
    priceBalanceMap,
    tokenTvlMap,
    marketRiskInfoMap,
  ])

  const handleCloseInfo = useCallback(() => setCloseInfo(true), [])

  const isArb = chainId === ChainId.ARBITRUM_ONE
  // const supplyTitle = isArb
  //   ? `${t('borrowed')} / ${t('supplied')} / ${t('cap')}`
  //   : `${t('borrowed')} (${t('cap')}) / ${t('supplied')} (${t('cap')})`
  const settingsIndicator = useMemo(() => {
    return [hideZeroBalances, hideDustBalances, tokenFilterType !== FilterType.NONE].filter(value => value).length
  }, [hideZeroBalances, hideDustBalances, tokenFilterType])

  return (
    <LendingPanelWrapper ref={node as any}>
      {!closeInfo && <InfoSection setHasClosedInfo={handleCloseInfo} />}
      {/*isBerachain(chainId) && (
        <>
          <ClimbingBear didSlide={didSlide} onClick={() => setDidSlide(true)} src={ClimbingBearImg} />
          <Exclamation>!</Exclamation>
        </>
      )*/}
      <ZapModal open={isSwapModalOpen} closeZap={toggleShowSwapModal} />
      <DepositWithdrawModal
        open={isDepositModalOpen}
        close={handleCloseDepositModal}
        isUnwrapInitiallySelected={isExternalToken(depositModalCurrency?.wrapped)}
        isWithdraw={isWithdrawModal}
        presetCurrency={
          isWithdrawModal ? mapExternalCurrencyOptToListedToken(depositModalCurrency) : depositModalCurrency
        }
      />
      {popoverComponent && (
        <Modal
          isOpen={!!popoverComponent}
          onDismiss={() => {
            setPopoverComponent(undefined)
            setCustomPopoverWidth(undefined)
          }}
          maxHeight={90}
          maxWidthPx={customPopoverWidth ?? 400}
        >
          {popoverComponent}
        </Modal>
      )}
      <ActionWrapper>
        <FilterSection expanded={tokenSearch}>
          <IconWrapper>
            <InputWrapper>
              <InputOverflowFix>
                <StyledInput
                  onChange={(e: any) => setInputValue(e.target.value)}
                  multiline={false}
                  fullWidth
                  spellCheck={false}
                  placeholder={'Search'}
                  value={inputValue}
                  variant=''
                  disableUnderline={true}
                  endAdornment={''}
                />
              </InputOverflowFix>
              <Clear onClick={() => setInputValue('')} disabled={inputValue === ''}>
                <Close />
              </Clear>
            </InputWrapper>
            <OptionsButton rotate={optionsMenuOpen}>
              <StyledMenuIcon onClick={handleAdvancedModal} />
              {settingsIndicator > 0 && (
                <HideSelectedIcon expanded={optionsMenuOpen}>{settingsIndicator}</HideSelectedIcon>
              )}
              <InlineSettingsWrapper isVisible={optionsMenuOpen}>
                <StyledTooltip title={'Hides tokens with a value less than $0.01'} placement={'top'}>
                  <ZeroBalances visible={/*!!account*/ true} onClick={() => setHideDustBalances(!hideDustBalances)}>
                    <Checkbox>
                      <Checked isChecked={hideDustBalances} />
                    </Checkbox>
                    Hide Dust
                  </ZeroBalances>
                </StyledTooltip>
                <ZeroBalances visible={/*!!account*/ true} onClick={() => setHideZeroBalances(!hideZeroBalances)}>
                  <Checkbox>
                    <Checked isChecked={hideZeroBalances} />
                  </Checkbox>
                  Hide Zero Balances
                </ZeroBalances>
                {/*<ZeroBalances visible={true} onClick={() => setShowYieldAsApr(!showYieldAsApr)}>
                  <Checkbox>
                    <Checked isChecked={showYieldAsApr} />
                  </Checkbox>
                  Show APR
                </ZeroBalances>
                <ZeroBalances visible={true} onClick={() => setShowYieldAsApr(!showYieldAsApr)}>
                  <Checkbox>
                    <Checked isChecked={!showYieldAsApr} />
                  </Checkbox>
                  Show APY
                </ZeroBalances>*/}
                <AprSelect onClick={() => setShowYieldAsApr(!showYieldAsApr)}>
                  <AprSelector active={showYieldAsApr}>APR</AprSelector>
                  <AprSelector active={!showYieldAsApr}>APY</AprSelector>
                </AprSelect>
                <FilterSelect
                  expanded={desktopFilterSelectOpen}
                  ref={filterWrapperDesktopRef}
                  onClick={e => {
                    e.stopPropagation()
                    !desktopFilterSelectOpen && setDesktopFilterSelectOpen(true)
                  }}
                >
                  {!desktopFilterSelectOpen && (
                    <FilterSelectRow
                      onClick={e => {
                        e.stopPropagation()
                        setDesktopFilterSelectOpen(true)
                      }}
                    >
                      {tokenFilterType === FilterType.NONE ? 'Filter' : tokenFilterType}
                    </FilterSelectRow>
                  )}
                  {desktopFilterSelectOpen && (
                    <FilterSelectRow
                      onClick={e => {
                        e.stopPropagation()
                        setDesktopFilterSelectOpen(false)
                      }}
                    >
                      {tokenFilterType}
                    </FilterSelectRow>
                  )}
                  <ArrowDown />
                  {filterTypes
                    .filter(filter => filter !== tokenFilterType)
                    .map((filter, index) => {
                      return (
                        <FilterSelectRow
                          key={`filter-dropdown-${index}`}
                          onClick={e => {
                            e.stopPropagation()
                            selectFilter(filter as FilterType)
                          }}
                        >
                          {filter}
                        </FilterSelectRow>
                      )
                    })}
                </FilterSelect>
              </InlineSettingsWrapper>
              {/*<AdvancedPopout open={optionsMenuOpen} ref={wrapperRef}>
                <StyledTooltip title={'Hides tokens with a value less than $0.01'} placement={'top'}>
                  <ZeroBalances visible={true} onClick={() => setHideDustBalances(!hideDustBalances)}>
                    <Checkbox>
                      <Checked isChecked={hideDustBalances} />
                    </Checkbox>
                    Hide Dust
                  </ZeroBalances>
                </StyledTooltip>
                <ZeroBalances visible={true} onClick={() => setHideZeroBalances(!hideZeroBalances)}>
                  <Checkbox>
                    <Checked isChecked={hideZeroBalances} />
                  </Checkbox>
                  Hide Zero Balances
                </ZeroBalances>
                <ZeroBalances visible={true} onClick={() => setShowYieldAsApr(!showYieldAsApr)}>
                  <Checkbox>
                    <Checked isChecked={showYieldAsApr} />
                  </Checkbox>
                  Show APR
                </ZeroBalances>
                <ZeroBalances visible={true} onClick={() => setShowYieldAsApr(!showYieldAsApr)}>
                  <Checkbox>
                    <Checked isChecked={!showYieldAsApr} />
                  </Checkbox>
                  Show APY
                </ZeroBalances>
                {/*<AprSelect onClick={() => setShowYieldAsApr(!showYieldAsApr)}>
                  <AprSelector active={showYieldAsApr}>APR</AprSelector>
                  <AprSelector active={!showYieldAsApr}>APY</AprSelector>
                </AprSelect>*/}
              {/*<FilterSelect
                  expanded={filterSelectOpen}
                  ref={filterWrapperRef}
                  onClick={() => !filterSelectOpen && setFilterSelectOpen(true)}
                >
                  {!filterSelectOpen && (
                    <FilterSelectRow onClick={() => setFilterSelectOpen(false)}>
                      {tokenFilterType === FilterType.NONE ? 'Filter' : tokenFilterType}
                    </FilterSelectRow>
                  )}
                  {filterTypes
                    .filter(filter => filter !== tokenFilterType)
                    .map((filter, index) => {
                      return (
                        <FilterSelectRow
                          key={`filter-dropdown-${index}`}
                          onClick={() => selectFilter(filter as FilterType)}
                        >
                          {filter}
                        </FilterSelectRow>
                      )
                    })}
                  {filterSelectOpen && (
                    <FilterSelectRow onClick={() => setFilterSelectOpen(false)}>{tokenFilterType}</FilterSelectRow>
                  )}
                  <ArrowDown />
                </FilterSelect>
              </AdvancedPopout>*/}
            </OptionsButton>
            {/*<SearchIcon onClick={() => setTokenSearch(!tokenSearch)} />*/}
          </IconWrapper>
        </FilterSection>
        <FilterSectionMobile>
          <InlineSettingsWrapper isVisible={optionsMenuOpen}>
            <AprSelect onClick={() => setShowYieldAsApr(!showYieldAsApr)}>
              <AprSelector active={showYieldAsApr}>APR</AprSelector>
              <AprSelector active={!showYieldAsApr}>APY</AprSelector>
            </AprSelect>
            <StyledTooltip title={'Hides tokens with a value less than $0.01'} placement={'top'}>
              <ZeroBalances visible={/*!!account*/ true} onClick={() => setHideDustBalances(!hideDustBalances)}>
                <Checkbox>
                  <Checked isChecked={hideDustBalances} />
                </Checkbox>
                Hide Dust
              </ZeroBalances>
            </StyledTooltip>
            <ZeroBalances visible={/*!!account*/ true} onClick={() => setHideZeroBalances(!hideZeroBalances)}>
              <Checkbox>
                <Checked isChecked={hideZeroBalances} />
              </Checkbox>
              Hide Zero Balances
            </ZeroBalances>
          </InlineSettingsWrapper>
          <BasicFilters>
            <InputWrapper>
              <InputOverflowFix>
                <StyledInput
                  onChange={(e: any) => setInputValue(e.target.value)}
                  multiline={false}
                  fullWidth
                  spellCheck={false}
                  placeholder={'Search'}
                  value={inputValue}
                  variant=''
                  disableUnderline={true}
                  endAdornment={''}
                />
              </InputOverflowFix>
              <Clear onClick={() => setInputValue('')} disabled={inputValue === ''}>
                <Close />
              </Clear>
            </InputWrapper>
            <FilterWrapper>
              <FilterSelect
                expanded={filterSelectOpen}
                ref={filterWrapperRef}
                onClick={() => !filterSelectOpen && setFilterSelectOpen(true)}
              >
                {!filterSelectOpen && (
                  <FilterSelectRow onClick={() => setFilterSelectOpen(false)}>
                    {tokenFilterType === FilterType.NONE ? 'Filter' : tokenFilterType}
                  </FilterSelectRow>
                )}
                {filterSelectOpen && (
                  <FilterSelectRow onClick={() => setFilterSelectOpen(false)}>{tokenFilterType}</FilterSelectRow>
                )}
                <ArrowDown />
                {filterTypes
                  .filter(filter => filter !== tokenFilterType)
                  .map((filter, index) => {
                    return (
                      <FilterSelectRow
                        key={`filter-dropdown-${index}`}
                        onClick={() => selectFilter(filter as FilterType)}
                      >
                        {filter}
                      </FilterSelectRow>
                    )
                  })}
              </FilterSelect>
            </FilterWrapper>
            <StyledMenuIcon onClick={handleAdvancedModal} />
            {settingsIndicator > 0 && (
              <HideSelectedIcon expanded={optionsMenuOpen}>{settingsIndicator}</HideSelectedIcon>
            )}
          </BasicFilters>
        </FilterSectionMobile>
        <ButtonsWrapper>
          <OptionsButton onClick={() => setCloseInfo(false)}>
            <StyledHelpIcon />
          </OptionsButton>
          <BalanceButton onClick={handleOpenDepositModal}>
            <ButtonText>Deposit</ButtonText>
            {/*} <AddCircleIcon />*/}
          </BalanceButton>
          <BalanceButton onClick={handleOpenWithdrawModal}>
            <ButtonText>Withdraw</ButtonText>
            {/*} <RemoveCircleOutlineIcon />*/}
          </BalanceButton>
          <Button onClick={toggleShowSwapModal}>
            <ButtonText>{chainId === ChainId.BERACHAIN ? 'Bwap' : 'Swap'}</ButtonText> <BoltIcon />
          </Button>
        </ButtonsWrapper>
      </ActionWrapper>
      <HeaderWrapper>
        <ColumnHeaders>
          <TickerTitle onClick={() => handleSort(SortField.Name)}>
            <StyledTooltip
              title={
                'The assets supported on this network on Dolomite. Deposit to earn yield and use the asset as collateral for borrowing.'
              }
              placement={'top'}
            >
              <span>
                {t('token')} {getArrow(SortField.Name)}
              </span>
            </StyledTooltip>
            <SearchIcon
              onClick={e => {
                e.stopPropagation()
                setTokenSearch(!tokenSearch)
              }}
            />
            {settingsIndicator > 0 && (
              <HideSelectedIcon expanded={tokenSearch} search>
                {settingsIndicator}
              </HideSelectedIcon>
            )}
          </TickerTitle>
          <SupplyTitle onClick={() => handleSort(SortField.InterestRate)}>
            <StyledTooltip
              title={
                'The interest earned by supplying the asset on Dolomite. Green APRs show assets that earn interest from lending, which compounds every block and increases your balance. Blue APRs can be hovered over, and include rewards or yield from outside of Dolomite.'
              }
              placement={'top'}
            >
              <span>
                Supply {showYieldAsApr ? t('apr') : t('apy')} {getArrow(SortField.InterestRate)}
              </span>
            </StyledTooltip>
          </SupplyTitle>
          <BorrowColumn onClick={() => handleSort(SortField.InterestRate)}>
            <StyledTooltip title={'The interest rate to borrow the asset on Dolomite.'} placement={'top'}>
              <span>
                Borrow {showYieldAsApr ? t('apr') : t('apy')} {getArrow(SortField.InterestRate)}
              </span>
            </StyledTooltip>
          </BorrowColumn>
          {/*<SupplyCapColumn onClick={() => handleSort(SortField.Cap)}>*/}
          {/*  <span>{supplyTitle}</span>*/}
          {/*  <TickerIcons>*/}
          {/*    <StyledTooltipWithIcon tooltipText={t('supplyCapColumn')} placement={'top'} />*/}
          {/*  </TickerIcons>*/}
          {/*</SupplyCapColumn>*/}
          {/*<InfoColumn>*/}
          {/*  <StyledTooltip title={t('assetFromWallet')} placement={'top'}>*/}
          {/*    <span>{t('info')}</span>*/}
          {/*  </StyledTooltip>*/}
          {/*</InfoColumn>*/}
          <BalanceColumn onClick={() => handleSort(SortField.DolomiteBalance)}>
            <StyledTooltip title={t('availableBalance')} placement={'top'}>
              <span>
                <HideOnSmall>{t('dolomite')} </HideOnSmall>
                {t('balanceText')} {getArrow(SortField.DolomiteBalance)}
              </span>
            </StyledTooltip>
          </BalanceColumn>
          <WalletBalanceColumn onClick={() => handleSort(SortField.WalletBalance)}>
            <StyledTooltip
              title={'The amount you currently hold in your wallet, not deposited to Dolomite.'}
              placement={'top'}
            >
              <span>
                {/*t('walletBalance')*/ `In Wallet`} {getArrow(SortField.WalletBalance)}
              </span>
            </StyledTooltip>
          </WalletBalanceColumn>
          <ButtonsColumn />
        </ColumnHeaders>
      </HeaderWrapper>
      <div>
        {!sortedCurrencies || sortedCurrencies ? (
          sortedCurrencies.length > 0 ? (
            sortedCurrencies.map(currency => {
              const specialAsset = getSpecialAsset(currency.chainId, currency.wrapped)
              const tokenAddress = mapExternalTokenToListedAddress(currency.wrapped)
              const interestRateParts = interestRateMap[tokenAddress]?.outsideSupplyInterestRateParts
              const hasRewards =
                hasExternalRewards(chainId, interestRateParts) || (specialAsset?.hasExternalRewards ?? false)
              const hasPowder = interestRateParts?.some(part => /^.*Powder/.test(part.label)) ?? false
              const hasRewardStation =
                interestRateParts?.some(part => part.label.includes('Mantle Ecosystem Rewards')) ?? false
              const hasMinerals = interestRateParts?.some(part => /^.*Minerals/.test(part.label)) ?? false
              const hasPoints = interestRateParts?.some(part => /^\+.*Points/.test(part.label)) ?? false
              const isBorrowingEnabled = !marketRiskInfoMap || !marketRiskInfoMap[tokenAddress]?.isBorrowingDisabled
              const currencyKey = currency.isNative ? currency.symbol ?? '' : currency.address
              if (
                hideZeroBalances &&
                !dolomiteBalanceData[tokenAddress]?.greaterThan(ZERO_FRACTION) &&
                !RewardValues[currency.symbol ?? '']?.greaterThan(0)
              ) {
                return null
              }
              if (
                hideDustBalances &&
                !dolomiteFiatBalanceData[tokenAddress]?.greaterThan(ONE_CENT_FRACTION) &&
                !RewardValues[currency.symbol ?? '']?.greaterThan(ONE_CENT_FRACTION)
              ) {
                return null
              }
              return (
                <TokenRow
                  t={t}
                  key={currency.isNative ? 'NATIVE' : currency.address}
                  chainId={chainId}
                  currencyOrToken={currency}
                  interestRateData={interestRateMap[tokenAddress]}
                  priceData={priceBalanceMap[tokenAddress]}
                  dolomiteBalanceData={dolomiteBalanceData[tokenAddress]}
                  dolomiteFiatData={dolomiteFiatBalanceData[tokenAddress]}
                  walletBalanceData={walletBalanceMap[currencyKey]}
                  walletFiatData={walletFiatBalanceMap[currencyKey]}
                  marketRiskData={marketRiskInfoMap[tokenAddress]}
                  tokenTvlData={tokenTvlMap[tokenAddress]}
                  previousPriceData={fiatPricePreviousDay[tokenAddress]}
                  hasRewards={hasRewards}
                  hasPowder={hasPowder}
                  hasRewardStation={hasRewardStation}
                  hasMinerals={hasMinerals}
                  hasPoints={hasPoints}
                  expanded={expandedRowMap[currency.isNative ? '0x' : currency.address ?? ''] ?? false}
                  isBorrowingEnabled={specialAsset ? specialAsset.isBorrowingEnabled : isBorrowingEnabled ?? false}
                  docsLink={specialAsset?.documentationUrl}
                  setExpanded={() => setExpandedCallback(currency)}
                  expandedContent={CUSTOM_BOTTOM_SECTION_COMPONENT_MAP[currency.symbol ?? '']}
                  setPopoverComponent={setPopoverComponent}
                  setCustomPopoverWidth={setCustomPopoverWidth}
                  showYieldAsApr={showYieldAsApr}
                  depositModal={depositModal}
                  withdrawModal={withdrawModal}
                />
              )
            })
          ) : (
            <NoTokens>{t('noTokens')}</NoTokens>
          )
        ) : (
          <LoadingRates>{t('loadingTokens')}</LoadingRates>
        )}
        {hideZeroBalances ? (
          hideDustBalances ? (
            <HideBalances>{t('hideDustZeroBalancesSelected')}</HideBalances>
          ) : (
            <HideBalances>{t('hideZeroBalancesSelected')}</HideBalances>
          )
        ) : (
          hideDustBalances && <HideBalances>{t('hideDustBalancesSelected')}</HideBalances>
        )}
      </div>
    </LendingPanelWrapper>
  )
}
