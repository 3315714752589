import { Token } from '@dolomite-exchange/sdk-core'
import { ChainId, ChainIdMap } from '../chainId'

const decimals = 18
const symbol = 'USD0++'
const name = 'USD0 Liquid Bond'

function getToken(chainId: ChainId, address: string) {
  return new Token(chainId, address, decimals, symbol, name)
}

export const USD0PP: ChainIdMap<Token | undefined> = {
  [ChainId.MAINNET]: undefined,
  [ChainId.ARBITRUM_ONE]: undefined,
  [ChainId.BASE]: undefined,
  [ChainId.BERACHAIN]: getToken(ChainId.BERACHAIN, '0x30Ea2d525eF7C234F8AA6E3a8909B88f71244cB0'),
  [ChainId.MANTLE]: undefined,
  [ChainId.POLYGON_ZKEVM]: undefined,
  [ChainId.X_LAYER]: undefined,
}
