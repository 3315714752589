import React from 'react'
import { FORMATTER, ZERO_FRACTION } from '../../../constants'
import { BorrowPositionAmount, serializeBorrowPositionAmounts } from '../../../types/borrowPositionData'
import getLogoOrDefault from '../../../components/common/TokenLogos'
import styled from 'styled-components/macro'
import { useTranslation } from 'react-i18next'
import cleanCurrencySymbol from '../../../utils/cleanCurrencySymbol'
import cleanCurrencyName from '../../../utils/cleanCurrencyName'
import { formatAmount } from '../../../utils/formatAmount'
import { Token } from '@dolomite-exchange/v2-sdk'
import { Fraction } from '@dolomite-exchange/sdk-core'
import { StyledTooltip } from '../../../components/common/StyledTooltip'

const BorrowSectionTitle = styled.div`
  font-size: 11px;
  color: ${({ theme }) => theme.text3};
  font-weight: 600;
  transition: all 0.3s ease-in-out;
  opacity: 0 !important;
  pointer-events: none;

  @media screen and (max-width: 615px) {
    font-size: 10px;
  }

  @media screen and (max-width: 480px) {
    text-align: left;
    margin-bottom: 2px;
  }
`

const TokenLogo = styled.div`
  display: inline-block;
  margin-right: 3px;
  margin-top: 2px;
  vertical-align: top;
  width: 18px;

  img {
    width: 100%;
  }

  @media screen and (max-width: 615px) {
    margin-top: 0;
  }
`

const NoAssetsBorrowedText = styled.div`
  width: 100%;
  font-size: 14px;
  color: ${({ theme }) => theme.yellow1}; /* TODO - should this be white? Or blue? */

  @media screen and (max-width: 480px) {
    text-align: left;
  }
`

const TokenName = styled.div`
  font-size: 11px;
  color: ${({ theme }) => theme.text2};
  transform: translateX(22px);
  white-space: nowrap;
  margin-top: -6px;
  font-weight: 200;
  overflow: hidden;
  transition: all 0.3s ease-in-out;

  @media screen and (max-width: 615px) {
    font-size: 10px;
    margin-top: -4px;
  }
`

const MobileBorrowList = styled.div`
  width: 100%;
  text-align: right;
  transition: opacity 0.3s ease-in-out;
  display: none;

  ${BorrowSectionTitle} {
    opacity: 0 !important;
    text-align: right;
  }

  ${NoAssetsBorrowedText} {
    text-align: right;
  }

  @media screen and (max-width: 480px) {
    display: block;
  }
`

const CollateralAssetDescription = styled.div`
  position: absolute;
  text-align: left;
  width: fit-content;
  left: 0;
  transition: all 0.3s ease-in-out;
`

const CollateralAssetAmount = styled.div`
  width: 100%;
  position: absolute;
  left: 0;
  transition: all 0.3s ease-in-out;
  text-align: right;
`

const EmodeIconWrapper = styled.div`
  transition: all 0.3s ease-in-out;
  cursor: pointer;
  margin-left: 6px;
  margin-top: 1px;
  position: relative;
  z-index: 10;

  img {
    height: 16px;
  }
`

const CollateralAsset = styled.div<{ expanded?: boolean; hasLiquidationPrice?: boolean }>`
    font-size: 18px;
    width: 100%;
    display: inline-block;
    vertical-align: top;
    position: relative;
    height: ${({ expanded, hasLiquidationPrice }) => (expanded ? (hasLiquidationPrice ? '54px' : '40px') : '26px')};
    transition: height 0.3s ease-in-out;

    ${CollateralAssetDescription} {
            /*opacity: ${({ expanded }) => (expanded ? '1' : '0')};*/
    }

    ${CollateralAssetAmount} {
        transform: ${({ expanded }) => (expanded ? 'translateX(-100%)' : 'translateX(0)')};
        opacity: ${({ expanded }) => (expanded ? '1' : '0')};
        left: ${({ expanded }) => (expanded ? '100%' : '0')};
    }

    ${TokenName} {
        opacity: ${({ expanded }) => (expanded ? '1' : '0')};
    }

    ${EmodeIconWrapper} {
        display: ${({ expanded }) => (expanded ? 'inline-block' : 'none')};
    }
`

const BorrowRight = styled.div`
  width: 50%;
  display: inline-block;
  vertical-align: top;
  text-align: right;
  transition: all 0.3s ease-in-out;
  height: auto;

  ${CollateralAsset} {
    margin-right: 0;
  }

  @media screen and (max-width: 480px) {
    width: 100%;
  }
`

const BorrowPositionAssetsWrapper = styled.div<{ expanded?: boolean; isInIsolationMode?: boolean }>`
  width: 100%;
  margin-top: ${({ expanded, isInIsolationMode }) =>
    expanded ? (isInIsolationMode ? '35px' : '20px') : isInIsolationMode ? '25px' : '10px'};
  margin-bottom: ${({ expanded }) => (expanded ? 12 : 23)}px;
  transition: margin 0.3s ease-in-out;

  ${BorrowSectionTitle} {
    opacity: 0;
  }

  @media screen and (max-width: 615px) {
    margin-top: ${({ expanded, isInIsolationMode }) => (expanded ? (isInIsolationMode ? '21px' : '10px') : '10px')};
    margin-bottom: ${({ expanded }) => (expanded ? 8 : 20)}px;
  }

  @media screen and (max-width: 480px) {
    margin-top: ${({ expanded, isInIsolationMode }) => (expanded ? (isInIsolationMode ? '31px' : '20px') : '30px')};
    margin-bottom: ${({ expanded }) => (expanded ? 10 : 20)}px;

    ${MobileBorrowList} {
      opacity: ${({ expanded }) => (expanded ? '0' : '1')};
    }

    ${BorrowSectionTitle} {
      opacity: 1 !important;
      font-size: ${({ expanded }) => (expanded ? '13px' : '11px')};
      margin-bottom: 2px;
    }

    ${BorrowRight} {
      opacity: ${({ expanded }) => (expanded ? '1' : '0')};
      max-height: ${({ expanded }) => (expanded ? '360' : '0')}px;
      display: block;
    }
  }
`

const BorrowSectionWrapper = styled.div<{ height: number; expanded: boolean }>`
  @media screen and (max-width: 480px) {
    margin-top: -${({ height }) => height}px;
    margin-bottom: ${({ height, expanded }) => (expanded ? 0 : height)}px;
  }
`

const BorrowLeft = styled.div`
  width: 50%;
  display: inline-block;
  vertical-align: top;

  @media screen and (max-width: 480px) {
    width: 100%;
  }
`

const CollateralAssets = styled.div`
  width: 100%;
  padding-right: 30px;

  @media screen and (max-width: 615px) {
    padding-right: 10px;
  }

  @media screen and (max-width: 480px) {
    padding-right: 0;
  }
`

const BorrowedAssetDescription = styled.div`
  position: absolute;
  text-align: left;
  width: fit-content;
  right: 0;
  transition: all 0.3s ease-in-out;
`

const LogoTicker = styled.div`
  /*display: flex;*/
`

const LogoTickerInner = styled.div`
  display: flex;
`

const BorrowedAssetAmount = styled.div`
  width: 100%;
  position: absolute;
  transition: opacity 0.3s ease-in-out;
`

const AmountInAsset = styled.div`
  font-size: 15px;

  @media screen and (max-width: 615px) {
    font-size: 13px;
    margin-top: 2px;
  }
`

const AmountInUSD = styled.div`
  font-size: 12px;
  color: ${({ theme }) => theme.text2};
  font-weight: 200;
  margin-top: -1px;

  @media screen and (max-width: 615px) {
    font-size: 11px;
  }
`

const LiquidationPrice = styled.div<{ expanded: boolean }>`
  width: calc(100% - 22px);
  margin-left: 22px;
  display: flex;
  justify-content: space-between;
  font-size: 10px;
  opacity: ${({ expanded }) => (expanded ? '1' : '0')};
  transition: ${({ expanded }) => (expanded ? 'opacity 0.2s ease-in-out 0.3s' : 'opacity 0.2s ease-in-out')};

  @media screen and (max-width: 615px) {
    margin-top: 1px;
  }
`

const TokenTicker = styled.div`
  width: fit-content;
  display: inline-block;

  @media screen and (max-width: 615px) {
    font-size: 15px;
  }
`

const BorrowedAsset = styled.div<{ expanded?: boolean; hasLiquidationPrice?: boolean }>`
  font-size: 18px;
  width: 100%;
  margin-right: 10px;
  display: inline-block;
  vertical-align: top;
  position: relative;
  transition: height 0.3s ease-in-out;
  height: ${({ expanded, hasLiquidationPrice }) => (expanded ? (hasLiquidationPrice ? '54px' : '40px') : '26px')};

  ${BorrowedAssetDescription} {
    transform: ${({ expanded }) => (expanded ? 'translateX(100%)' : 'translateX(0)')};
    right: ${({ expanded }) => (expanded ? '100%' : '0')};
  }

  ${BorrowedAssetAmount} {
    opacity: ${({ expanded }) => (expanded ? '1' : '0')};
  }

  ${TokenName} {
    opacity: ${({ expanded }) => (expanded ? '1' : '0')};
    ${({ expanded }) => !expanded && 'margin-left: 0'};
    max-width: ${({ expanded }) => (expanded ? '200px' : '0px')};
  }

  ${EmodeIconWrapper} {
    display: ${({ expanded }) => (expanded ? 'inline-block' : 'none')};
  }

  @media screen and (max-width: 615px) {
    font-size: 15px;
  }
`

const BorrowedAssets = styled.div`
  width: 100%;
  padding-left: 30px;

  @media screen and (max-width: 615px) {
    padding-left: 10px;
  }

  @media screen and (max-width: 480px) {
    padding-left: 0;
  }
`

function BorrowPositionAssetsComparator(
  prevProps: BorrowPositionAssetsInterface,
  nextProps: BorrowPositionAssetsInterface,
) {
  return (
    prevProps.expanded === nextProps.expanded &&
    prevProps.isInIsolationMode === nextProps.isInIsolationMode &&
    serializeBorrowPositionAmounts(prevProps.supplyAmounts) ===
      serializeBorrowPositionAmounts(nextProps.supplyAmounts) &&
    serializeBorrowPositionAmounts(prevProps.borrowAmounts) === serializeBorrowPositionAmounts(nextProps.borrowAmounts)
  )
}

interface BorrowPositionAssetsInterface {
  expanded: boolean
  isInIsolationMode: boolean | undefined
  supplyAmounts: BorrowPositionAmount[]
  borrowAmounts: BorrowPositionAmount[]
  liquidationAsset: Token | undefined
  liquidationPrice: Fraction | undefined
  openEmodeModal: (token: Token) => void
}

function BorrowPositionAssets({
  expanded,
  isInIsolationMode,
  supplyAmounts,
  borrowAmounts,
  liquidationAsset,
  liquidationPrice,
}: BorrowPositionAssetsInterface) {
  const { t } = useTranslation()

  return (
    <BorrowPositionAssetsWrapper expanded={expanded} isInIsolationMode={isInIsolationMode}>
      <MobileBorrowList>
        <BorrowSectionTitle>{t('borrowing')}</BorrowSectionTitle>
        <BorrowedAssets>
          {borrowAmounts.length > 0 ? (
            borrowAmounts.map((borrowAsset, index) => (
              <BorrowedAsset
                expanded={false}
                hasLiquidationPrice={liquidationAsset && liquidationAsset.address === borrowAsset.token.address}
                key={index}
              >
                <BorrowedAssetDescription>
                  <LogoTicker>
                    <LogoTickerInner>
                      <TokenLogo>
                        <img
                          src={getLogoOrDefault(cleanCurrencySymbol(borrowAsset.token) ?? '')}
                          alt={`${cleanCurrencySymbol(borrowAsset.token)} logo`}
                        />
                      </TokenLogo>
                      <TokenTicker>{cleanCurrencySymbol(borrowAsset.token)}</TokenTicker>
                    </LogoTickerInner>
                    <TokenName>{cleanCurrencyName(borrowAsset.token)}</TokenName>
                  </LogoTicker>
                </BorrowedAssetDescription>
              </BorrowedAsset>
            ))
          ) : (
            <NoAssetsBorrowedText>{t('noAssetsBorrowed')}</NoAssetsBorrowedText>
          )}
        </BorrowedAssets>
      </MobileBorrowList>
      <BorrowSectionWrapper
        height={(borrowAmounts.length === 0 ? 19 : borrowAmounts.length * 26) + 17}
        expanded={expanded}
      >
        <BorrowLeft>
          <BorrowSectionTitle>{t('collateral')}</BorrowSectionTitle>
          <CollateralAssets>
            {supplyAmounts.map((supplyAmount, index) => {
              const hasLiquidationPrice = liquidationAsset && liquidationAsset.address === supplyAmount.token.address

              return (
                <CollateralAsset expanded={expanded} hasLiquidationPrice={hasLiquidationPrice} key={index}>
                  <CollateralAssetDescription>
                    <LogoTicker>
                      <LogoTickerInner>
                        <TokenLogo>
                          <img
                            src={getLogoOrDefault(cleanCurrencySymbol(supplyAmount.token) ?? '')}
                            alt={`${cleanCurrencySymbol(supplyAmount.token)} ${t('logo')}`}
                          />
                        </TokenLogo>
                        <TokenTicker>{cleanCurrencySymbol(supplyAmount.token)}</TokenTicker>
                      </LogoTickerInner>
                      <TokenName>{cleanCurrencyName(supplyAmount.token) ?? '-'}</TokenName>
                    </LogoTicker>
                  </CollateralAssetDescription>
                  <CollateralAssetAmount>
                    <StyledTooltip
                      title={formatAmount(supplyAmount.amountTokenWei, supplyAmount.token.decimals)}
                      placement={'right'}
                      style={{ cursor: 'pointer' }}
                    >
                      <AmountInAsset>{formatAmount(supplyAmount.amountTokenWei)}</AmountInAsset>
                    </StyledTooltip>
                    <AmountInUSD>${supplyAmount.amountUSD.toFixed(2, FORMATTER)}</AmountInUSD>
                    {hasLiquidationPrice && (
                      <LiquidationPrice expanded={expanded}>
                        <div>Liquidation Price:</div>
                        <div>${formatAmount(liquidationPrice ?? ZERO_FRACTION, 2, true)}</div>
                      </LiquidationPrice>
                    )}
                  </CollateralAssetAmount>
                </CollateralAsset>
              )
            })}
          </CollateralAssets>
        </BorrowLeft>
        <BorrowRight>
          <BorrowSectionTitle>{t('Borrowing')}</BorrowSectionTitle>
          <BorrowedAssets>
            {borrowAmounts.length > 0 ? (
              borrowAmounts.map((borrowAmount, index) => {
                const hasLiquidationPrice = liquidationAsset && liquidationAsset.address === borrowAmount.token.address
                return (
                  <BorrowedAsset expanded={expanded} hasLiquidationPrice={hasLiquidationPrice} key={index}>
                    <BorrowedAssetDescription>
                      <LogoTicker>
                        <LogoTickerInner>
                          <TokenLogo>
                            <img
                              src={getLogoOrDefault(cleanCurrencySymbol(borrowAmount.token) ?? '')}
                              alt={`${cleanCurrencySymbol(borrowAmount.token)} logo`}
                            />
                          </TokenLogo>
                          <TokenTicker>{cleanCurrencySymbol(borrowAmount.token)}</TokenTicker>
                        </LogoTickerInner>
                        <TokenName>{cleanCurrencyName(borrowAmount.token) ?? '-'}</TokenName>
                      </LogoTicker>
                    </BorrowedAssetDescription>
                    <BorrowedAssetAmount>
                      <StyledTooltip
                        title={formatAmount(borrowAmount.amountTokenWei, borrowAmount.token.decimals)}
                        placement={'right'}
                        style={{ cursor: 'pointer' }}
                      >
                        <AmountInAsset>{formatAmount(borrowAmount.amountTokenWei)}</AmountInAsset>
                      </StyledTooltip>
                      <AmountInUSD>${borrowAmount.amountUSD.toFixed(2, FORMATTER)}</AmountInUSD>
                      {hasLiquidationPrice && liquidationPrice?.denominator.toString() !== '0' && (
                        <LiquidationPrice expanded={expanded}>
                          <div>Liquidation Price:</div>
                          <div>${formatAmount(liquidationPrice ?? ZERO_FRACTION, 2, true)}</div>
                        </LiquidationPrice>
                      )}
                    </BorrowedAssetAmount>
                  </BorrowedAsset>
                )
              })
            ) : (
              <NoAssetsBorrowedText>{t('noAssetsBorrowed')}</NoAssetsBorrowedText>
            )}
          </BorrowedAssets>
        </BorrowRight>
      </BorrowSectionWrapper>
    </BorrowPositionAssetsWrapper>
  )
}

export default React.memo(BorrowPositionAssets, BorrowPositionAssetsComparator)
