import React, { SetStateAction, useCallback, useEffect, useMemo, useState } from 'react'
import styled from 'styled-components/macro'
import Modal from '../Modal'
import { BalanceCheckFlag, CurrencyAmount, Fraction, Percent, Token } from '@dolomite-exchange/v2-sdk'
import { MarginPosition, MarginPositionType } from '../../types/marginPositionData'
import { Button } from '@material-ui/core'
import cleanCurrencySymbol from '../../utils/cleanCurrencySymbol'
import { Input as NumericalInput } from '../NumericalInput'
import toDate from '../../utils/toDate'
import { useAllActiveTokensArray } from '../../hooks/Tokens'
import { useDolomiteBalancesWithLoadingIndicator } from '../../state/wallet/hooks'
import { useActiveWeb3React } from '../../hooks'
import ArrowForwardIcon from '@material-ui/icons/ArrowForward'
import { tryParseAmount } from '../../state/trade/hooks'
import calculateLeverage from '../../utils/calculateLeverage'
import calculateLiquidationPrice from '../../utils/calculateLiquidationPrice'
import { useDolomiteMarginData } from '../../types/dolomiteMarginData'
import { useMarketRiskInfoData } from '../../types/marketRiskInfoData'
import { useTranslation } from 'react-i18next'
import Tabs from '@material-ui/core/Tabs'
import Tab from '@material-ui/core/Tab'
import JSBI from 'jsbi'
import { useFiatValueWithLoadingIndicator } from '../../hooks/useFiatValue'
import ReactGA from 'react-ga'
import useDebounce from '../../hooks/useDebounce'
import WarningAmberIcon from '@material-ui/icons/Warning'
import { StyledTooltip, StyledTooltipWithIcon } from '../common/StyledTooltip'
import { useClosePositionCallback } from '../../hooks/useTradeCallback'
import { useDefaultMarginAccount } from '../../types/marginAccount'
import { getTradeVersion } from '../../hooks/useToggledVersion'
import { ClosePositionProps } from '../../pages/Trade/OrderTypes/Positions'
import { CloseIcon } from '../../theme'
import CircularProgress from '@material-ui/core/CircularProgress'
import { useIsTransactionPending } from '../../state/transactions/hooks'
import { useTransferAmountForBorrowPosition } from '../../hooks/useBorrowPositionProtocol'
import { formatAmount } from '../../utils/formatAmount'

const ManagePositionModalWrapper = styled.div`
  width: 400px;
  height: fit-content;
  padding: 20px 25px;
  font-size: 14px;
`

const Title = styled.div`
  font-size: 24px;
  font-weight: 500;
  margin-bottom: 10px;
`

const TopRow = styled.div`
  display: flex;
  justify-content: space-between;

  svg {
    cursor: pointer;
    margin-top: 4px;
  }
`

const Subsection = styled.div`
  width: 100%;
  display: inline-block;
`

const Left = styled(Subsection)``

const Middle = styled(Subsection)``

const Right = styled(Subsection)`
  text-align: right;
`

const SectionContent = styled.div<{ faded?: boolean }>`
  width: 100%;
  display: flex;
  font-weight: 300;
  ${({ theme, faded }) => (faded ? `color: ${theme.text2}` : '')};
`

const SplitSection = styled.div<{ left?: number; middle?: number; right?: number }>`
    justify-content: space-between;

    ${SectionContent} {
        ${({ left }) =>
          left &&
          `
    ${Left} {
      width: ${left}%;
    }
  `}

        ${({ middle }) =>
          middle &&
          `
    ${Middle} {
      width: ${middle}%;
    }
  `}

        ${({ right }) =>
          right &&
          `
    ${Right} {
      width: ${right}%;
    }
  `}
    }
`

const SectionTitle = styled.div`
  width: 100%;
  font-size: 16px;
  font-weight: 400;
  color: ${({ theme }) => theme.text1};
  margin: 10px 0 3px;
`

const LongShortRow = styled.div`
  width: 100%;
`

const LongShort = styled.span`
  font-weight: 700;
`

const Long = styled(LongShort)`
  color: ${({ theme }) => theme.green1};
`

const Short = styled(LongShort)`
  color: ${({ theme }) => theme.red1};
`

const ArrowWrapper = styled.div<{ isDecreasing?: boolean | undefined }>`
  display: inline-block;
  vertical-align: top;
  width: fit-content;
  color: ${({ theme, isDecreasing }) =>
    isDecreasing === undefined ? theme.text1 : isDecreasing ? theme.red1 : theme.green1};
  margin: 0 5px;
  height: 19px;

  svg {
    font-size: 16px;
    margin-top: 2px;
  }
`

const ProfitLossPercent = styled.div<{ isPositive: boolean }>`
  width: fit-content;
  text-align: right;
  font-size: 30px;
  font-weight: 500;
  color: ${({ theme, isPositive }) => (isPositive ? theme.green1 : theme.red1)};
  margin-top: -7px;
  display: inline-block;
`

const ProfitLossBase = styled.span<{ isPositive: boolean }>`
  margin-right: 4px;
  font-weight: 600;
  color: ${({ theme, isPositive }) => (isPositive ? theme.green1 : theme.red1)};
`

const ProfitLossAmount = styled.div`
  width: fit-content;
  text-align: right;
  font-size: 12px;
  color: ${({ theme }) => theme.text2};
  margin-top: -6px;
  display: inline-block;
  vertical-align: top;
`

const StatRow = styled.div`
  width: 100%;
  margin-bottom: 4px;
`

const SubTitle = styled.div`
  color: ${({ theme }) => theme.text3};
  font-size: 12px;
  font-weight: 500;
  margin-bottom: -2px;

  svg {
    font-size: 12px !important;
    margin-bottom: -2px !important;
    margin-left: 2px !important;
  }
`

const LineContent = styled.div`
  width: 100%;
`

const LeverageSpan = styled.span`
  color: ${({ theme }) => theme.text2};
`

const CollateralizationRateSpanWithWarning = styled.span<{ warningLevel: number }>`
  color: ${({ theme, warningLevel }) =>
    warningLevel === 0 ? theme.text1 : warningLevel === 1 ? theme.yellow1 : theme.red1};
  font-weight: ${({ warningLevel }) => (warningLevel === 0 ? 'inherit' : warningLevel == 1 ? '500' : '600')};
`

const NewCollateralizationRate = styled.span<{ showWarning?: boolean; showError?: boolean }>`
  ${({ theme, showWarning, showError }) =>
    showError
      ? `color: ${theme.red1}; font-weight: 600;`
      : showWarning
      ? `color: ${theme.yellow2}; font-weight: 600;`
      : ''}
`

const AddRemoveButton = styled(Button)`
  background-color: ${({ theme }) => theme.bg3} !important;
  color: ${({ theme }) => theme.text1} !important;
  width: fit-content !important;
  text-align: center !important;
  padding: 4px 12px !important;
  text-transform: uppercase !important;
  font-family: 'Open Sans', sans-serif !important;
  font-size: 11px !important;
  margin: -4px 3% 10px !important;
`

const SubmitCollateralButtonWrapper = styled.div`
  width: 100%;
  text-align: right;
  margin-top: 10px;
`

const WarningText = styled.div<{ showWarning?: boolean; isError?: boolean }>`
  display: ${({ showWarning }) => (showWarning ? 'inline-block' : 'none')};
  color: ${({ theme, isError }) => (isError ? theme.red1 : theme.yellow2)};
  font-size: 11px;
  font-weight: 600;
  width: 160px;
  text-align: left;
  margin-top: -2px;
  margin-left: 4px;
  float: left;

  svg {
    font-size: 18px;
    margin-bottom: -4px;
    margin-right: 3px;
  }
`

const SubmitNewCollateralButton = styled(AddRemoveButton)<{ isRisky: boolean }>`
  background-color: ${({ theme, isRisky, disabled }) => (isRisky && !disabled ? theme.yellow2 : theme.bg3)} !important;
  ${({ disabled }) => disabled && 'opacity: 0.7;'}
  margin: 0 0 0 5px !important;
  vertical-align: top !important;

  div {
    color: ${({ theme }) => theme.text1} !important;
    height: 19px !important;
    width: 19px !important;
  }
`

const EditCollateralWrapper = styled.div`
  width: calc(100% + 20px);
  background: ${({ theme }) => theme.bg2};
  border-radius: 5px;
  margin-left: -10px;
  margin-top: 5px;
  padding: 12px 20px 20px;
`

const CollateralTokenBalance = styled.div<{ isEditing: boolean }>`
  width: 100%;
  display: flex;
  justify-content: space-between;
  font-size: 12px;
  color: ${({ theme }) => theme.text1};
  font-weight: 300;
  padding: 0 2px;
  margin-top: 1px;

  > div:nth-of-type(1) {
    color: ${({ theme }) => theme.text2};
  }
`

const CollateralInputWrapper = styled.div`
  width: 100%;
  position: relative;

  input {
    width: 100%;
    padding: 4px 12px;
    border-radius: 5px;

    + div {
      position: absolute;
      top: 4px;
      right: 12px;
    }
  }
`

const StyledTabs = styled(({ ...rest }) => <Tabs classes={{ indicator: 'indicator' }} {...rest} />)`
  font-family: 'Open Sans', sans-serif !important;
  justify-content: normal !important;
  min-height: 0 !important;
  margin-bottom: 8px;

  .indicator {
    background-color: #f9f9f9 !important;
    bottom: 0 !important;
    display: block !important;
    height: 1.4px !important;
    transform: scale(0.5, 1) !important;
  }
`

const StyledTab = styled(({ ...rest }) => (
  <Tab
    classes={{
      root: 'root',
      selected: 'selected',
    }}
    {...rest}
  />
))`
  font-family: 'Open Sans', sans-serif !important;
  font-size: 14px !important;
  font-weight: 500 !important;
  margin-left: 0 !important;
  margin-right: 13px !important;
  text-transform: capitalize !important;
  padding: 0 !important;
  padding-bottom: 3.5px !important;
  padding-left: 4px !important;
  padding-right: 4px !important;
  max-width: 264px;
  min-width: 0 !important;
  color: #606375 !important;
  min-height: 0 !important;

  ${({ selected }) =>
    selected &&
    `
    color: #f9f9f9 !important;
  `}
  .selected {
    color: #f9f9f9 !important;
  }

  .root span {
    font-size: 18px !important;
  }
`

const ClosePositionButtonWrapper = styled.div`
  width: 100%;
  text-align: right;
  margin-top: 15px;
`

const CloseButton = styled(Button)`
  background-color: ${({ theme }) => theme.bg3} !important;
  color: ${({ theme }) => theme.text1} !important;
  width: fit-content !important;
  text-align: center !important;
  padding: 8px 24px !important;
  text-transform: uppercase !important;
  font-family: 'Open Sans', sans-serif !important;
  font-size: 14px !important;
`

const ProfitBreakdown = styled.div`
  margin-top: 8px;
  font-size: 12px;
`

const TooltipText = styled.div`
  margin: 2px 0;
`

export const FeesButton = styled.div<{ selected: boolean }>`
  color: ${({ theme }) => theme.text3};
  float: right;
  cursor: pointer;
  display: inline-block;
  font-size: 12px;
  font-weight: 300;
  vertical-align: top;
  margin-top: 5px;
  margin-bottom: -23px;

  &:hover {
    color: ${({ theme }) => theme.text1};
  }

  ${({ theme, selected }) => selected && `color: ${theme.text1} !important;`}
`

export const Slider = styled.div<{ isOn: boolean }>`
  width: 24px;
  height: 14px;
  border-radius: 7px;
  background-color: ${({ theme, isOn }) => (isOn ? theme.bg5 : theme.bg3)};
  display: inline-block;
  vertical-align: middle;
  margin-bottom: 2px;
`

export const SliderDot = styled.div<{ isOn: boolean }>`
    width: 10px;
    height: 10px;
    margin: 2px 0 2px 2px;
    transition: transform 0.2s ease-in-out;
    border-radius: 50%;
    display: inline-block;
    vertical-align: top;
    ${({ isOn }) => isOn && 'transform: translateX(10px);'}
    background: ${({ theme }) => theme.bg2};
`

const DEPOSIT = 0
const WITHDRAW = 1

interface Stats {
  collateralChangeAmount: CurrencyAmount<Token>
  newCollateralAmount: CurrencyAmount<Token>
  newPositionSize: CurrencyAmount<Token>
  newMarginDepositSize: CurrencyAmount<Token>
  newTokenBalance: CurrencyAmount<Token> | undefined
  newLeverageLong: Fraction | undefined
  newLeverageShort: Fraction | undefined
  newLiquidationPrice: Fraction
  newCollateralizationPercent: Fraction | undefined
}

interface ManagePositionModalProps {
  isOpen: boolean
  position: MarginPosition
  onDismissMemoized: () => void
  addClosingMemoized: (arg0: string) => void
  removeClosingMemoized: (arg0: string) => void
  setClosePositionState: React.Dispatch<SetStateAction<ClosePositionProps>>
}

function ManagePositionModalComparator(prevProps: ManagePositionModalProps, nextProps: ManagePositionModalProps) {
  return (
    prevProps.isOpen === nextProps.isOpen &&
    prevProps.position === nextProps.position &&
    prevProps.onDismissMemoized === nextProps.onDismissMemoized &&
    prevProps.addClosingMemoized === nextProps.addClosingMemoized &&
    prevProps.removeClosingMemoized === nextProps.removeClosingMemoized
  )
}

function ManagePositionModal({
  isOpen,
  position,
  onDismissMemoized,
  setClosePositionState,
  addClosingMemoized,
  removeClosingMemoized,
}: ManagePositionModalProps) {
  const { account } = useActiveWeb3React()
  const { t } = useTranslation()
  const [selectedTab, setSelectedTab] = useState<number>(DEPOSIT)
  const options = [t('deposit'), t('withdraw')]

  const tokenList = useAllActiveTokensArray()
  const [balanceData] = useDolomiteBalancesWithLoadingIndicator(account, tokenList)

  const heldTokenWalletBalance = useMemo(() => balanceData[position.heldToken.address], [balanceData, position])

  const [isEditingCollateral, setIsEditingCollateral] = useState(false)
  const [isSubmitting, setIsSubmitting] = useState(false)
  const [typedCollateral, setTypedCollateral] = useState('')

  const [collateralChange, setCollateralChange] = useState(false)
  const [parsedTypedCollateral, setParsedTypedCollateral] = useState<CurrencyAmount<Token> | undefined>(undefined)
  const [newStats, setNewStats] = useState<Stats | undefined>(undefined)
  const [showOverleveragedWarning, setShowOverleveragedWarning] = useState(false)
  const [showOverleveragedError, setShowOverleveragedError] = useState(false)
  const [pendingHash, setPendingHash] = useState<string | undefined>(undefined)
  const isHashPending = useIsTransactionPending(pendingHash)
  useEffect(() => {
    if (!isHashPending) {
      setPendingHash(undefined)
    }
  }, [isHashPending])

  const defaultMarginAccount = useDefaultMarginAccount()
  const { callback: tradeCallback, trade: tradeToConfirm } = useClosePositionCallback(position, defaultMarginAccount)
  const { callback: transferCallback } = useTransferAmountForBorrowPosition(
    undefined, // TODO: fix this when we enable margin trading for isolation mode
    selectedTab == DEPOSIT ? defaultMarginAccount.accountNumber : position.marginAccount.accountNumber,
    selectedTab == DEPOSIT ? position.marginAccount.accountNumber : defaultMarginAccount.accountNumber,
    parsedTypedCollateral,
    selectedTab == DEPOSIT ? BalanceCheckFlag.FromAccount : BalanceCheckFlag.ToAccount,
    selectedTab == DEPOSIT,
    undefined,
  )
  const lpFee = new Percent('3', '10000')
  const lpWithLev = lpFee.multiply(position.heldAmount.divide(position.marginDeposit))
  const profitPctWithoutFees = lpWithLev.multiply(new Fraction('2')).add(position.profitPercentage)
  const [showProfitWithFees, setShowProfitWithFees] = useState(false)
  const displayProfitPercentage = showProfitWithFees ? position.profitPercentage : profitPctWithoutFees
  const feeValue = position.equityHeldAmount.multiply(lpWithLev)

  const handleTrade = useCallback(() => {
    if (!tradeCallback) {
      return
    }
    setClosePositionState(prev => ({
      attemptingTxn: true,
      tradeToConfirm,
      showConfirm: true,
      tradeErrorMessage: undefined,
      txHash: undefined,
      closingPosition: position,
      handleTrade: prev.handleTrade,
    }))
    tradeCallback()
      .then(hash => {
        addClosingMemoized(position.id)
        setClosePositionState(prev => ({
          attemptingTxn: false,
          tradeToConfirm,
          showConfirm: true,
          tradeErrorMessage: undefined,
          txHash: hash,
          closingPosition: position,
          handleTrade: prev.handleTrade,
        }))

        ReactGA.event({
          category: 'Close Position',
          action: 'Close position w/o Send',
          label: [
            cleanCurrencySymbol(tradeToConfirm?.inputAmount.currency),
            cleanCurrencySymbol(tradeToConfirm?.outputAmount.currency),
            getTradeVersion(tradeToConfirm),
          ].join('/'),
        })

        ReactGA.event({
          category: 'Routing',
          action: 'Trade with multihop enabled',
        })
      })
      .catch(error => {
        removeClosingMemoized(position.id)
        setClosePositionState(prev => ({
          attemptingTxn: false,
          showConfirm: true,
          tradeToConfirm: tradeToConfirm,
          tradeErrorMessage: error.message,
          txHash: undefined,
          closingPosition: position,
          handleTrade: prev.handleTrade,
        }))
      })
  }, [tradeCallback, setClosePositionState, tradeToConfirm, position, addClosingMemoized, removeClosingMemoized])

  useEffect(() => {
    setClosePositionState(prev => ({
      attemptingTxn: prev.attemptingTxn,
      tradeToConfirm: prev.tradeToConfirm,
      showConfirm: prev.showConfirm,
      tradeErrorMessage: prev.tradeErrorMessage,
      txHash: prev.txHash,
      closingPosition: prev.closingPosition,
      handleTrade: handleTrade,
    }))
  }, [handleTrade, setClosePositionState])

  const onClosePosition = () => {
    setClosePositionState({
      tradeToConfirm: tradeToConfirm,
      attemptingTxn: false,
      tradeErrorMessage: undefined,
      showConfirm: true,
      txHash: undefined,
      handleTrade: handleTrade,
      closingPosition: position,
    })
  }

  const debouncedTypedCollateral = useDebounce(typedCollateral, 96)

  const updateSelectedTab = (selected: number) => {
    setNewStats(undefined)
    setTypedCollateral('')
    setSelectedTab(selected)
  }

  const depositCollateral = () => {
    if (!transferCallback) {
      return
    }
    setIsSubmitting(true)

    transferCallback()
      .then(hash => {
        setIsSubmitting(false)
        setPendingHash(hash)
        ReactGA.event({
          category: 'Deposit collateral into Position',
          action: 'Deposit collateral into position',
          label: [cleanCurrencySymbol(position.heldToken)].join('/'),
        })
      })
      .catch(error => {
        setIsSubmitting(false)
        console.error('error', error)
      })
  }

  const withdrawCollateral = () => {
    if (!transferCallback) {
      setIsSubmitting(false)
      return
    }
    setIsSubmitting(true)

    transferCallback()
      .then(hash => {
        setIsSubmitting(false)
        setPendingHash(hash)
        ReactGA.event({
          category: 'Withdraw collateral from Position',
          action: 'Withdraw collateral from position',
          label: [cleanCurrencySymbol(position.heldToken)].join('/'),
        })
      })
      .catch(error => {
        setIsSubmitting(false)
        console.error('error', error)
      })
  }

  const setMaxCollateral = () => {
    //TODO - let user set max
    console.log('set max')
  }

  const cancelEditCollateral = () => {
    setIsEditingCollateral(false)
    setCollateralChange(false)
    setParsedTypedCollateral(undefined)
    setNewStats(undefined)
    setTypedCollateral('')
  }

  const onDismissModal = () => {
    setIsEditingCollateral(false)
    onDismissMemoized()
  }

  const oneUnitOfHeldToken = useMemo(
    () =>
      CurrencyAmount.fromRawAmount(
        position.heldToken,
        JSBI.exponentiate(JSBI.BigInt(10), JSBI.BigInt(position.heldToken.decimals)),
      ),
    [position],
  )
  const [inputFiatValue] = useFiatValueWithLoadingIndicator(oneUnitOfHeldToken, position.heldToken)

  const outputToken = useMemo(() => {
    return position.heldToken.symbol === position.market.primaryToken.symbol
      ? position.market.secondaryToken
      : position.market.primaryToken
  }, [position])
  const outputAmount = useMemo(() => {
    return outputToken
      ? CurrencyAmount.fromRawAmount(outputToken, JSBI.exponentiate(JSBI.BigInt(10), JSBI.BigInt(outputToken.decimals)))
      : undefined
  }, [outputToken])
  const [outputFiatValue] = useFiatValueWithLoadingIndicator(outputAmount, outputToken)

  const calculatedMarketValue = useMemo(
    () => (inputFiatValue && outputFiatValue ? outputFiatValue.divide(inputFiatValue) : undefined),
    [inputFiatValue, outputFiatValue],
  )

  const { data: riskParams } = useDolomiteMarginData()
  const { data: marketRiskInfoMap } = useMarketRiskInfoData()
  const owedMarketInfo = useMemo(
    () => (position.owedToken ? marketRiskInfoMap[position.owedToken.address] : undefined),
    [position, marketRiskInfoMap],
  )
  const heldMarketInfo = useMemo(() => marketRiskInfoMap[position.heldToken.address], [marketRiskInfoMap, position])

  useEffect(() => {
    const didCollateralChange =
      debouncedTypedCollateral !== formatAmount(position.equityHeldAmount) && debouncedTypedCollateral !== ''
    setCollateralChange(didCollateralChange)

    const parsedTypedCollateralValue = tryParseAmount(debouncedTypedCollateral, position.heldToken)
    setParsedTypedCollateral(parsedTypedCollateralValue)

    const collateralChangeAmount = parsedTypedCollateralValue
      ? selectedTab === DEPOSIT
        ? parsedTypedCollateralValue
        : parsedTypedCollateralValue.multiply('-1')
      : CurrencyAmount.fromRawAmount(position.heldToken, '0')

    const newCollateralAmount = position.equityHeldAmount.add(collateralChangeAmount)
    const newPositionSize = position.heldAmount.add(collateralChangeAmount)
    const newMarginDepositSize = position.marginDeposit.add(collateralChangeAmount)
    const newTokenBalance = heldTokenWalletBalance?.subtract(collateralChangeAmount) ?? heldTokenWalletBalance

    const rawLiquidationPrice = calculateLiquidationPrice(
      newPositionSize,
      position.owedAmount,
      riskParams?.minCollateralization,
      heldMarketInfo,
      owedMarketInfo,
      undefined,
    )
    const newLiquidationPrice =
      position.owedToken && position.market.primaryToken.equals(position.owedToken) && !rawLiquidationPrice.equalTo('0')
        ? rawLiquidationPrice.invert()
        : rawLiquidationPrice

    const newCollateralizationPercent = calculatedMarketValue
      ? newPositionSize.asFraction
          .divide(position.owedAmount.multiply(calculatedMarketValue).asFraction)
          .multiply('100')
      : undefined

    const newLeverageLong = calculateLeverage(newPositionSize, position.owedAmount, calculatedMarketValue)
    const newLeverageShort = newLeverageLong.subtract('1')

    setShowOverleveragedWarning(!!newCollateralizationPercent?.lessThan('125'))
    setShowOverleveragedError(!!newCollateralizationPercent?.lessThan('115'))

    setNewStats({
      collateralChangeAmount: collateralChangeAmount,
      newCollateralAmount: newCollateralAmount,
      newPositionSize: newPositionSize,
      newMarginDepositSize: newMarginDepositSize,
      newTokenBalance: newTokenBalance,
      newLeverageLong: newLeverageLong,
      newLeverageShort: newLeverageShort,
      newLiquidationPrice: newLiquidationPrice,
      newCollateralizationPercent: newCollateralizationPercent,
    })
  }, [
    debouncedTypedCollateral,
    position,
    riskParams,
    selectedTab,
    heldMarketInfo,
    owedMarketInfo,
    heldTokenWalletBalance,
    calculatedMarketValue,
  ])

  const profitLossAmount = position.equityHeldAmount.greaterThan('0')
    ? position.equityHeldAmount.asFraction.subtract(position.marginDeposit.asFraction)
    : position.equityHeldAmount.asFraction
  const profitWithoutFees = feeValue.multiply(new Fraction('2')).asFraction.add(profitLossAmount)
  const displayProfit = showProfitWithFees ? profitLossAmount : profitWithoutFees
  const collateralizationPercent = calculatedMarketValue
    ? position.heldAmount.asFraction
        .divide(position.owedAmount.multiply(calculatedMarketValue).asFraction)
        .multiply('100')
    : undefined

  const insufficientBalance =
    newStats && selectedTab === DEPOSIT && heldTokenWalletBalance?.lessThan(newStats.collateralChangeAmount)

  const moreThanMarginDepositError =
    newStats && selectedTab === WITHDRAW && position.marginDeposit.add(newStats.collateralChangeAmount).lessThan('0')

  //TODO - LET USER SELECT MAX AMOUNT FOR DEPOSIT/WITHDRAW (do later, low priority)
  //TODO - ADD BACK MEMOIZATION WHERE IT MAKES SENSE TO FOR CALCULATED VALUES
  //TODO - ADD REACTIONS TO DEPOSIT AND WITHDRAW, SHOW RECENT DEPOSITS/WITHDRAWALS AND SHOW A SPINNER WHILE IT'S SUBMITTING
  //TODO - ADD WARNING WHEN POSITION IS CLOSE TO EXPIRATION
  //TODO - ADD INTERMEDIATE STATES, LIKE WHEN A DEPOSIT IS SUBMITTING
  //TODO - CHANGE DEPOSIT/WITHDRAW BUTTON TO GREEN
  //TODO - FIX COLOR OF BUTTON TO BE MORE DISTINCT
  //TODO - ADD OPEN PRICE!!

  return (
    <Modal isOpen={isOpen} onDismiss={onDismissModal} maxHeight={90} maxWidthPx={400}>
      <ManagePositionModalWrapper>
        <TopRow>
          <Title>Manage Position</Title>
          <CloseIcon onClick={onDismissModal} />
        </TopRow>
        <SplitSection left={60} right={40} style={{ marginBottom: '-13px' }}>
          <SectionContent>
            <Left>
              <LongShortRow>
                <Long>LONG{` `}</Long>
                {cleanCurrencySymbol(position.heldToken)}
                {` `}
                <LeverageSpan>
                  (
                  {position.positionType === MarginPositionType.LONG
                    ? position.leverage.toFixed(2)
                    : position.leverage.add('1').toFixed(2)}
                  {`x`}
                  {collateralChange && newStats ? (
                    <span>
                      <ArrowWrapper>
                        <ArrowForwardIcon />
                      </ArrowWrapper>
                      {newStats.newLeverageLong?.toFixed(2) ?? '-'}
                      {`x`}
                    </span>
                  ) : (
                    ``
                  )}
                  )
                </LeverageSpan>
              </LongShortRow>
              <LongShortRow>
                <Short>SHORT{` `}</Short>
                {cleanCurrencySymbol(
                  position.heldToken.symbol === position.market.primaryToken.symbol
                    ? position.market.secondaryToken
                    : position.market.primaryToken,
                )}
                {` `}
                <LeverageSpan>
                  (
                  {position.positionType === MarginPositionType.SHORT
                    ? position.leverage.toFixed(2)
                    : position.leverage.subtract('1').toFixed(2)}
                  {`x`}
                  {collateralChange && newStats ? (
                    <span>
                      <ArrowWrapper>
                        <ArrowForwardIcon />
                      </ArrowWrapper>
                      {newStats.newLeverageShort?.toFixed(2) ?? undefined}
                      {`x`}
                    </span>
                  ) : (
                    ``
                  )}
                  )
                </LeverageSpan>
              </LongShortRow>
            </Left>
            <Right>
              <StyledTooltip
                title={
                  <div>
                    {`The profit or loss on your position. It reflects the difference in value between the current
                    market price and the price at which the position was opened, as well as the expected price impact of closing the position.`}
                    <ProfitBreakdown>
                      PnL Breakdown:
                      <TooltipText>
                        <ProfitLossBase isPositive={profitPctWithoutFees.greaterThanOrEqual('0')}>
                          {profitPctWithoutFees.greaterThanOrEqual('0') ? '' : '-'}
                          {profitPctWithoutFees.toFixed(2)}%
                        </ProfitLossBase>
                        PnL before fees
                      </TooltipText>
                      <TooltipText>
                        <ProfitLossBase isPositive={false}>-{lpWithLev.toFixed(2)}%</ProfitLossBase>
                        Open Liquidity Pool Fee
                      </TooltipText>
                      <TooltipText>
                        <ProfitLossBase isPositive={false}>-{lpWithLev.toFixed(2)}%</ProfitLossBase>
                        Close Liquidity Pool Fee
                      </TooltipText>
                      <ProfitLossBase isPositive={position.profitPercentage.greaterThanOrEqual('0')}>
                        {position.profitPercentage.greaterThanOrEqual('0') ? '' : '-'}
                        {position.profitPercentage.toFixed(2)}%
                      </ProfitLossBase>
                      PnL after fees
                    </ProfitBreakdown>
                  </div>
                }
                placement={'right'}
              >
                <ProfitLossPercent isPositive={position.profitPercentage.greaterThanOrEqual('0')}>
                  {displayProfitPercentage.greaterThanOrEqual('0') ? '+' : ''}
                  {displayProfitPercentage.toFixed(2)}%
                </ProfitLossPercent>
              </StyledTooltip>
              <StyledTooltip
                title={
                  <div>
                    {`The profit or loss on your position. It reflects the difference in value between the current
                    market price and the price at which the position was opened, as well as the expected price impact of closing the position.`}
                    <ProfitBreakdown>
                      PnL Breakdown:
                      <TooltipText>
                        <ProfitLossBase isPositive={profitWithoutFees.greaterThanOrEqual('0')}>
                          {profitWithoutFees.greaterThanOrEqual('0') ? '' : '-'}
                          {formatAmount(profitWithoutFees)}
                        </ProfitLossBase>
                        {cleanCurrencySymbol(position.heldToken)} PnL before fees
                      </TooltipText>
                      <TooltipText>
                        <ProfitLossBase isPositive={false}>-{formatAmount(feeValue)}</ProfitLossBase>
                        {cleanCurrencySymbol(position.heldToken)} Open Liquidity Pool Fee
                      </TooltipText>
                      <TooltipText>
                        <ProfitLossBase isPositive={false}>-{formatAmount(feeValue)}</ProfitLossBase>
                        {cleanCurrencySymbol(position.heldToken)} Close Liquidity Pool Fee
                      </TooltipText>
                      <TooltipText>
                        <ProfitLossBase isPositive={profitLossAmount.greaterThanOrEqual('0')}>
                          {profitLossAmount.greaterThanOrEqual('0') ? '' : '-'}
                          {formatAmount(profitLossAmount)}
                        </ProfitLossBase>
                        {cleanCurrencySymbol(position.heldToken)} PnL after fees
                      </TooltipText>
                    </ProfitBreakdown>
                  </div>
                }
                placement={'right'}
              >
                <ProfitLossAmount>
                  {displayProfit.greaterThanOrEqual('0') ? '+' : ''}
                  {formatAmount(displayProfit)}
                  {` `}
                  {cleanCurrencySymbol(position.heldToken)}
                </ProfitLossAmount>
              </StyledTooltip>
            </Right>
          </SectionContent>
        </SplitSection>
        <SplitSection left={50} right={50} style={{ marginTop: '-5px' }}>
          <FeesButton selected={showProfitWithFees} onClick={() => setShowProfitWithFees(!showProfitWithFees)}>
            Include Fees{' '}
            <Slider isOn={showProfitWithFees}>
              <SliderDot isOn={showProfitWithFees} />
            </Slider>
          </FeesButton>
          <SectionContent
            faded
            style={{
              flexDirection: 'column',
              fontSize: '12px',
            }}
          >
            <Left style={{ width: 'fit-content' }}>Opened: {toDate(position.openTimestamp as Date)}</Left>
            <Right
              style={{
                width: 'fit-content',
              }}
            >
              Expires: {position.expirationTimestamp ? toDate(position.expirationTimestamp) : 'never'}
            </Right>
          </SectionContent>
          <SectionContent faded>
            <Left style={{ width: 'fit-content' }}>
              Open Price: {formatAmount(position.openPrice)} {cleanCurrencySymbol(position.market.secondaryToken)}
            </Left>
          </SectionContent>
        </SplitSection>
        <SplitSection left={40} right={60} style={{ marginTop: '15px' }}>
          <SectionContent style={{ display: 'block' }}>
            <StatRow>
              <SubTitle>
                Position Size
                <StyledTooltipWithIcon
                  tooltipText={`The total size of your position, a combination of your initial margin deposit and the ${cleanCurrencySymbol(
                    position.heldToken,
                  )} that was purchased with borrowed ${cleanCurrencySymbol(
                    position.owedAmount.currency,
                  )} when the position was opened`}
                  verticalAlign={'right'}
                />
              </SubTitle>
              <LineContent>
                {formatAmount(position.heldAmount)}
                {` `}
                {cleanCurrencySymbol(position.heldToken)}
                {collateralChange && newStats && (
                  <span>
                    <ArrowWrapper isDecreasing={selectedTab === WITHDRAW}>
                      <ArrowForwardIcon />
                    </ArrowWrapper>
                    {formatAmount(newStats.newPositionSize)}
                    {` `}
                    {cleanCurrencySymbol(position.heldToken)}
                  </span>
                )}
              </LineContent>
            </StatRow>
            <StatRow>
              <SubTitle>
                Margin Deposit
                <StyledTooltipWithIcon
                  tooltipText={`The amount of ${cleanCurrencySymbol(
                    position.heldToken,
                  )} that was initially deposited as collateral to open the position`}
                  verticalAlign={'right'}
                />
              </SubTitle>
              <LineContent>
                {formatAmount(position.marginDeposit)}
                {` `}
                {cleanCurrencySymbol(position.heldToken)}
                {collateralChange && newStats && (
                  <span>
                    <ArrowWrapper isDecreasing={selectedTab === WITHDRAW}>
                      <ArrowForwardIcon />
                    </ArrowWrapper>
                    {formatAmount(newStats.newMarginDepositSize)}
                    {` `}
                    {cleanCurrencySymbol(position.heldToken)}
                  </span>
                )}
              </LineContent>
            </StatRow>
            <StatRow>
              <SubTitle>
                Borrowed Amount
                <StyledTooltipWithIcon
                  tooltipText={`The amount of ${cleanCurrencySymbol(
                    position.owedAmount.currency,
                  )} that was borrowed when the position was opened, and has to be repaid to close the position`}
                  verticalAlign={'right'}
                />
              </SubTitle>
              <LineContent>
                {formatAmount(position.owedAmount)}
                {` `}
                {cleanCurrencySymbol(position.owedAmount.currency)}
              </LineContent>
            </StatRow>
            <StatRow>
              <SubTitle>
                Liquidation Price
                <StyledTooltipWithIcon
                  tooltipText={`The price at which the position will be automatically closed in order to assure that the borrowed amount can be repaid`}
                  verticalAlign={'right'}
                />
              </SubTitle>
              <LineContent>
                {formatAmount(position.liquidationPrice)}
                {` `}
                {cleanCurrencySymbol(position.market.secondaryToken)} per{' '}
                {cleanCurrencySymbol(position.market.primaryToken)}
                {collateralChange && newStats && (
                  <span>
                    <ArrowWrapper isDecreasing={selectedTab === WITHDRAW}>
                      <ArrowForwardIcon />
                    </ArrowWrapper>
                    {formatAmount(newStats.newLiquidationPrice)}
                    {` `}
                    {cleanCurrencySymbol(position.market.secondaryToken)} per{' '}
                    {cleanCurrencySymbol(position.market.primaryToken)}
                  </span>
                )}
              </LineContent>
            </StatRow>
            <StatRow>
              <SubTitle>
                Collateralization Rate
                <StyledTooltipWithIcon
                  tooltipText={`The ratio of collateral to borrowed amount. It must remain above 115% to prevent your position from being liquidated`}
                  verticalAlign={'right'}
                />
              </SubTitle>
              <LineContent>
                <CollateralizationRateSpanWithWarning
                  warningLevel={
                    collateralizationPercent?.lessThan('120') ? 2 : collateralizationPercent?.lessThan('125') ? 1 : 0
                  }
                >
                  {collateralizationPercent?.toFixed(2) ?? '-'}
                  {`% `}
                </CollateralizationRateSpanWithWarning>
                {collateralChange && newStats && (
                  <NewCollateralizationRate showWarning={showOverleveragedWarning} showError={showOverleveragedError}>
                    <ArrowWrapper isDecreasing={selectedTab === WITHDRAW}>
                      <ArrowForwardIcon />
                    </ArrowWrapper>
                    {newStats.newCollateralizationPercent?.toFixed(2) ?? '-'}
                    {`%`}
                  </NewCollateralizationRate>
                )}
              </LineContent>
            </StatRow>
            <StatRow>
              <div
                style={{
                  width: 'fit-content',
                  display: 'inline-block',
                }}
              >
                <SubTitle>
                  Equity
                  <StyledTooltipWithIcon
                    tooltipText={`The value of your equity in the position. It will rise and fall as the position gains or loses value. When you close your position, part of this will go be used to repay your borrowed amount, and everything remaining is returned to you. If your position is near liquidation you can add collateral to the position to raise your collateralization rate and lower your risk of liquidation. If there is excess collateral in the position, you can withdraw collateral, but currently without closing the position Dolomite only allows you to withdraw up to your initial margin deposit, any additional collateral can be received by closing the position.`}
                    verticalAlign={'right'}
                  />
                </SubTitle>
                <div>
                  {formatAmount(position.equityHeldAmount)}
                  {` `}
                  {cleanCurrencySymbol(position.heldToken)}
                  {collateralChange && newStats && (
                    <span>
                      <ArrowWrapper isDecreasing={selectedTab === WITHDRAW}>
                        <ArrowForwardIcon />
                      </ArrowWrapper>
                      {formatAmount(newStats.newCollateralAmount)}
                      {` `}
                      {cleanCurrencySymbol(position.heldToken)}
                    </span>
                  )}
                </div>
              </div>
              {!isEditingCollateral && (
                <AddRemoveButton onClick={() => setIsEditingCollateral(true)}>Add/Remove</AddRemoveButton>
              )}
              {isEditingCollateral ? (
                <EditCollateralWrapper>
                  <StyledTabs
                    value={selectedTab}
                    onChange={(_: any, index: number) => updateSelectedTab(index)}
                    indicatorColor={'primary'}
                    textColor={'primary'}
                  >
                    {(options ?? []).map((option: string, index: number) => (
                      <StyledTab key={`tradeHeader-${index}`} disableRipple label={option} />
                    ))}
                  </StyledTabs>
                  <CollateralTokenBalance isEditing={collateralChange}>
                    <div>Wallet Balance:</div>
                    <div
                      onClick={() => setMaxCollateral()}
                      style={{
                        width: 'fit-content',
                      }}
                    >
                      {formatAmount(heldTokenWalletBalance)}
                      {` `}
                      {cleanCurrencySymbol(position.heldToken)}
                      {collateralChange && newStats && (
                        <span>
                          <ArrowWrapper
                            isDecreasing={
                              newStats.newTokenBalance && heldTokenWalletBalance
                                ? newStats.newTokenBalance.lessThan(heldTokenWalletBalance)
                                : undefined
                            }
                            style={{ marginTop: '-2px' }}
                          >
                            <ArrowForwardIcon />
                          </ArrowWrapper>
                          {formatAmount(newStats.newTokenBalance)}
                          {` `}
                          {cleanCurrencySymbol(position.heldToken)}
                        </span>
                      )}
                    </div>
                  </CollateralTokenBalance>
                  <CollateralInputWrapper>
                    <NumericalInput
                      className={'token-amount-input'}
                      value={typedCollateral}
                      onUserInput={val => {
                        setTypedCollateral(val)
                      }}
                      fontSize={'inherit'}
                      unit={cleanCurrencySymbol(position.heldToken)}
                      maxDecimals={position.heldToken.decimals ?? 18}
                      style={{ cursor: 'text' }}
                    />
                    <SubmitCollateralButtonWrapper>
                      <WarningText
                        showWarning={
                          showOverleveragedWarning ??
                          showOverleveragedError ??
                          !!insufficientBalance ??
                          moreThanMarginDepositError
                        }
                        isError={showOverleveragedError || !!insufficientBalance || !!moreThanMarginDepositError}
                      >
                        {insufficientBalance ? (
                          'Insufficient balance'
                        ) : showOverleveragedError ? (
                          'Cannot withdraw, position will be undercollateralized'
                        ) : moreThanMarginDepositError ? (
                          'Cannot withdraw more than initial margin deposit'
                        ) : parsedTypedCollateral ? (
                          <span>
                            <WarningAmberIcon />
                            This will leave your position close to liquidation
                          </span>
                        ) : (
                          <span>
                            <WarningAmberIcon />
                            Your position is close to liquidation
                          </span>
                        )}
                      </WarningText>
                      <SubmitNewCollateralButton isRisky={false} onClick={() => cancelEditCollateral()}>
                        Cancel
                      </SubmitNewCollateralButton>
                      <SubmitNewCollateralButton
                        isRisky={showOverleveragedWarning}
                        disabled={
                          isSubmitting ??
                          !!pendingHash ??
                          showOverleveragedError ??
                          !!insufficientBalance ??
                          moreThanMarginDepositError
                        }
                        onClick={() => (selectedTab === DEPOSIT ? depositCollateral() : withdrawCollateral())}
                      >
                        {isSubmitting || !!pendingHash ? (
                          <CircularProgress />
                        ) : selectedTab === DEPOSIT ? (
                          'Deposit'
                        ) : (
                          'Withdraw'
                        )}
                      </SubmitNewCollateralButton>
                    </SubmitCollateralButtonWrapper>
                  </CollateralInputWrapper>
                </EditCollateralWrapper>
              ) : (
                <></>
              )}
            </StatRow>
            {/*<div style={{ width: '100%' }}>Margin Deposit: 2,600.00 USDC</div>
             <div style={{ width: '100%' }}>Position Size: 13,000.07 USDC</div>
             <div style={{ width: '100%' }}>Collateral: 9,443.07 USDC</div>
             <div style={{ width: '100%' }}>Liquidation Price: 3242.23 USDC</div>*/}
            {/*<div style={{ width: '100%' }}></div>*/}
          </SectionContent>
        </SplitSection>
        <SplitSection left={30} middle={30} right={30}>
          <SectionTitle>Interest</SectionTitle>
          <SectionContent style={{ justifyContent: 'space-between' }}>
            <Left style={{ width: 'fit-content' }}>
              <SubTitle>Earned</SubTitle>
              <div>
                {formatAmount(position.interestEarned)} {cleanCurrencySymbol(position.interestEarned.currency)}
              </div>
            </Left>
            <Middle style={{ width: 'fit-content' }}>
              <SubTitle /* Tooltip - interest owed on ETH borrowed to open the position */>Owed</SubTitle>
              <div>
                {formatAmount(position.interestOwed)} {cleanCurrencySymbol(position.interestOwed.currency)}
              </div>
            </Middle>
            <Right style={{ width: 'fit-content' }}>
              <SubTitle>Net Interest Rate</SubTitle>
              <div>
                {position.netInterest.greaterThanOrEqual(0) ? '+' : ''}
                {position.netInterest.toFixed(2)}% APR
              </div>
            </Right>
          </SectionContent>
        </SplitSection>
        <ClosePositionButtonWrapper>
          <CloseButton
            onClick={() => {
              onDismissMemoized()
              onClosePosition()
            }}
          >
            Close Position
          </CloseButton>
        </ClosePositionButtonWrapper>
      </ManagePositionModalWrapper>
    </Modal>
  )
}

export default React.memo(ManagePositionModal, ManagePositionModalComparator)
