import { Percent } from '@dolomite-exchange/sdk-core'
import { CurrencyAmount, Token } from '@dolomite-exchange/v2-sdk'
import { address } from '@dolomite-exchange/dolomite-margin/dist/src/types'
import JSBI from 'jsbi'
import { ONE_HUNDRED_PERCENT, ZERO_FRACTION, ZERO_PERCENT } from '../constants'
import invariant from 'tiny-invariant'
import getInterestRateAtUtilization from './getInterestRateAtUtilization'
import { InterestRate } from '../types/interestRateData'
import { DolomiteMarginData } from '../types/dolomiteMarginData'
import { TokenTvl } from '../types/dolomiteMarginTokenTvlData'

export default function getNewInterestRateWithSlippage(
  borrowedAmountDelta: CurrencyAmount<Token>,
  suppliedAmountDelta: CurrencyAmount<Token>,
  interestRateMap: Record<address, InterestRate | undefined>,
  tokenAddressToMarketIdMap: Record<address, JSBI | undefined>,
  marketTotalWeiMap: Record<address, TokenTvl | undefined>,
  dolomiteMarginData: DolomiteMarginData | undefined,
  showYieldAsApr: boolean,
): { borrowInterestRate: Percent; supplyInterestRate: Percent } {
  invariant(borrowedAmountDelta.currency.equals(suppliedAmountDelta.currency), 'Currencies must match')
  const token = suppliedAmountDelta.currency
  const tokenId = tokenAddressToMarketIdMap[token.address]
  const totalWei = marketTotalWeiMap[token.address]
  const interestRate = interestRateMap[token.address]
  if (!tokenId || !totalWei || !interestRate) {
    return {
      borrowInterestRate: ZERO_PERCENT,
      supplyInterestRate: ZERO_PERCENT,
    }
  }

  let utilization: Percent
  if (totalWei.borrowLiquidity.add(borrowedAmountDelta).asFraction.lessThanOrEqual(ZERO_FRACTION)) {
    utilization = ZERO_PERCENT
  } else if (totalWei.supplyLiquidity.add(suppliedAmountDelta).asFraction.lessThanOrEqual(ZERO_FRACTION)) {
    utilization = ONE_HUNDRED_PERCENT
  } else {
    utilization = new Percent(
      totalWei.borrowLiquidity.add(borrowedAmountDelta).quotient,
      totalWei.supplyLiquidity.add(suppliedAmountDelta).quotient,
    )
  }
  return getInterestRateAtUtilization(
    utilization,
    token.chainId,
    tokenId,
    interestRate,
    dolomiteMarginData,
    showYieldAsApr,
  )
}
