import { SpecialAsset, SpecialAssetSymbol } from '../special-assets'
import {
  ChainId,
  PENDLE_PT_WST_ETH_JUN_2025_ADDRESSES,
  PENDLE_PT_WST_ETH_JUN_2025_ISOLATION_MODE_ADDRESSES,
} from '../../index'
import { Token } from '@dolomite-exchange/v2-sdk'
import { getOptionally } from '../special-assets-util-functions'
import { calculatePendlePtWstEthJun2025ProxyVaultAddress } from '../../../utils/isolation/calculateProxyVaultAddress'
import { PENDLE_PT_DOCS_LINK } from './constants'

const decimals = 18

export const PT_WST_ETH_JUN_2025_SPECIAL_ASSET: () => SpecialAsset = () => ({
  symbol: SpecialAssetSymbol.dPtWstEthJun2025,
  safeSymbol: SpecialAssetSymbol.dPtWstEthJun2025.replace('-', '_'),
  cleanSymbol: 'PT-wstETH',
  cleanName: 'PT wstETH 2025-06-26',
  hasExternalRewards: false,
  documentationUrl: PENDLE_PT_DOCS_LINK,
  isIsolationMode: true,
  isBorrowingEnabled: false,
  chainIdToAddressMap: PENDLE_PT_WST_ETH_JUN_2025_ISOLATION_MODE_ADDRESSES,
  isolationModeInfo: {
    marketId: 24,
    getWrappedToken: (chainId: ChainId) =>
      getOptionally(
        PENDLE_PT_WST_ETH_JUN_2025_ISOLATION_MODE_ADDRESSES[chainId],
        address =>
          new Token(
            chainId,
            address,
            decimals,
            SpecialAssetSymbol.dPtWstEthJun2025,
            'Dolomite Isolation: PT wstETH 26JUN2025',
          ),
      ),
    getUnderlyingToken: getPendlePtREthUnderlyingToken,
    getUnderlyingSpenderToken: getPendlePtREthUnderlyingToken,
    allowableDebtTokens: () => [],
    allowableCollateralTokens: () => [],
    remapAccountAddress: calculatePendlePtWstEthJun2025ProxyVaultAddress,
    isAsync: false,
  },
})

function getPendlePtREthUnderlyingToken(chainId: ChainId): Token | undefined {
  return getOptionally(
    PENDLE_PT_WST_ETH_JUN_2025_ADDRESSES[chainId],
    address => new Token(chainId, address, decimals, 'PT-wstETH', 'PT wstETH 26JUN2025'),
  )
}
