import { useEffect, useState, useMemo } from 'react'
import { ChainId } from '../constants'
import { Fraction } from '@dolomite-exchange/sdk-core'
import { tryParseAmountWithNoCurrency } from '../state/trade/hooks'
import { useActiveWeb3React } from '../hooks'

export interface MineralsAirdropClaimData {
  minerals: {
    [chainId: string]: string | null
  }
  amount: string
  proofs: string[]
  user_address: string
  remapped_user_address: string | null
}

export interface MineralsAirdropClaimResponse {
  airdrop: MineralsAirdropClaimData
}

export interface MineralsLeaderboardResponse {
  rank: number
}

export function useMineralsAirdropClaim() {
  const { account } = useActiveWeb3React()
  const [loading, setLoading] = useState(false)
  const [error, setError] = useState<string | undefined>()
  const [data, setData] = useState<MineralsAirdropClaimData>()

  useEffect(() => {
    const fetchData = async () => {
      if (!account) {
        setData(undefined)
        return
      }

      setLoading(true)
      setError(undefined)

      try {
        const response = await fetch(`https://api.dolomite.io/airdrop/option/${account}`)
        if (!response.ok) {
          throw new Error('Failed to fetch airdrop data')
        }

        const result: MineralsAirdropClaimResponse = await response.json()
        setData(result.airdrop)
      } catch (err) {
        console.error('Error fetching airdrop data:', err)
        setError('Failed to fetch airdrop data')
      } finally {
        setLoading(false)
      }
    }

    fetchData()
  }, [account])

  const arbitrumMinerals = useMemo(() => {
    const mineralAmount = data?.minerals?.[ChainId.ARBITRUM_ONE]
    if (mineralAmount) {
      return tryParseAmountWithNoCurrency(mineralAmount)
    }
    return undefined
  }, [data])

  const mantleMinerals = useMemo(() => {
    const mineralAmount = data?.minerals?.[ChainId.MANTLE]
    if (mineralAmount) {
      return tryParseAmountWithNoCurrency(mineralAmount)
    }
    return undefined
  }, [data])

  const xLayerMinerals = useMemo(() => {
    const mineralAmount = data?.minerals?.[ChainId.X_LAYER]
    if (mineralAmount) {
      return tryParseAmountWithNoCurrency(mineralAmount)
    }
    return undefined
  }, [data])

  const totalMinerals = useMemo(() => {
    if (!arbitrumMinerals && !mantleMinerals && !xLayerMinerals) {
      return undefined
    }

    let total = new Fraction(0)
    if (arbitrumMinerals) total = total.add(arbitrumMinerals)
    if (mantleMinerals) total = total.add(mantleMinerals)
    if (xLayerMinerals) total = total.add(xLayerMinerals)
    return total
  }, [arbitrumMinerals, mantleMinerals, xLayerMinerals])

  const doloOptionsAmount = useMemo(() => {
    if (data?.amount) {
      return tryParseAmountWithNoCurrency(data.amount)
    }
    return undefined
  }, [data])

  const mineralsPerDolo = useMemo(() => {
    if (totalMinerals && doloOptionsAmount) {
      return totalMinerals.divide(doloOptionsAmount)
    }
    return undefined
  }, [totalMinerals, doloOptionsAmount])

  return {
    loading,
    error,
    data,
    arbitrumMinerals,
    mantleMinerals,
    xLayerMinerals,
    totalMinerals,
    doloOptionsAmount,
    mineralsPerDolo,
  }
}
