import { MarketRiskInfo } from '../types/marketRiskInfoData'
import { useMemo } from 'react'
import { RiskOverrideStruct } from '../utils/emode'

export default function useAllEModeCategories(
  marketRiskInfoMap: Record<string, MarketRiskInfo | undefined>,
): RiskOverrideStruct[] {
  return useMemo(() => {
    if (!marketRiskInfoMap || Object.keys(marketRiskInfoMap).length === 0) {
      return []
    }

    const set = Object.values(marketRiskInfoMap)
      .map(info => info?.riskCategories)
      .reduce((acc, categories) => {
        if (!categories) {
          return acc
        }

        categories.forEach(c => {
          acc[c.name] = c
        })

        return acc
      }, {} as Record<string, RiskOverrideStruct>)

    return Object.values(set)
  }, [marketRiskInfoMap])
}
