import React, { useCallback, useState } from 'react'
import styled, { keyframes } from 'styled-components/macro'
import { StyledTooltipWithIcon } from '../../components/common/StyledTooltip'
import Add from '@mui/icons-material/Add'
import DoubleArrow from '@mui/icons-material/DoubleArrow'
import ReportProblem from '@mui/icons-material/ReportProblem'
import Info from '@mui/icons-material/Info'
import DolomiteLogo from '../../assets/dolomite-logo-white.svg'
import CircularProgress from '@material-ui/core/CircularProgress'
import CountUp from 'react-countup'
import { Separator } from '../../components/Trade/styleds'
import { useActiveWeb3React } from '../../hooks'
import { Fraction } from '@dolomite-exchange/sdk-core'
import { useGalxeAddressData } from '../../types/galxeAddressData'
import { formatAmount } from '../../utils/formatAmount'
import Web3StatusConnectButton from '../../components/Web3StatusConnectButton'
import { ChainId, NETWORK_LABELS, NETWORK_LOGOS } from '../../constants/chainId'
import { ZERO_FRACTION } from '../../constants'
import { useUsageAirdropData } from '../../hooks/useUsageAirdropData'
import { ExposureZeroOutlined } from '@material-ui/icons'

const UsageSection = styled.div`
  width: calc(50% - 20px);
  margin-right: 20px;
  display: inline-block;
  vertical-align: top;
  margin-top: 20px;

  @media screen and (max-width: 950px) {
    width: calc(100% - 40px);
    max-width: 600px;
    margin: 20px auto 0;
    display: block;
  }

  @media screen and (max-width: 500px) {
    width: 100%;
  }
`

const UsageTitle = styled.div`
  font-size: 24px;
  font-weight: 400;
`

const UsageSubtitle = styled.div`
  font-size: 15px;
  font-weight: 300;
  margin-bottom: 15px;
  color: ${({ theme }) => theme.text6};
  letter-spacing: 0.1px;
`

const UsageBreakdown = styled.div`
  width: 100%;
  background: ${({ theme }) => theme.bg1};
  border-radius: 8px;
  padding: 28px 38px;
  font-size: 15px;
  box-shadow: 0 5px 5px -3px rgb(0 0 0 / 20%), 0 8px 10px 1px rgb(0 0 0 / 14%), 0 3px 14px 2px rgb(0 0 0 / 12%);

  @media screen and (max-width: 500px) {
    padding: 22px 28px;
  }
`

const SmartContractWarning = styled.div`
  width: 100%;
  background: ${({ theme }) => theme.bg1};
  border-radius: 8px;
  padding: 28px 38px;
  margin-top: 15px;
  font-size: 15px;
  box-shadow: 0 5px 5px -3px rgb(0 0 0 / 20%), 0 8px 10px 1px rgb(0 0 0 / 14%), 0 3px 14px 2px rgb(0 0 0 / 12%);

  @media screen and (max-width: 500px) {
    padding: 22px 28px;
  }
`

const SmartContractLeft = styled.div`
  display: inline-block;
  vertical-align: top;
  color: ${({ theme }) => theme.red1};
  font-size: 25px;
  width: 25px;
  margin-right: 15px;

  svg {
    font-size: 30px;
  }
`

const RemappedAddressWarning = styled(SmartContractWarning)`
  ${SmartContractLeft} {
    color: ${({ theme }) => theme.yellow2};
  }
`

const RemappedAddressInfo = styled(SmartContractWarning)`
  ${SmartContractLeft} {
    color: ${({ theme }) => theme.text3};
  }
`

const SmartContractRight = styled.div`
  width: calc(100% - 40px);
  display: inline-block;
  vertical-align: top;
  color: ${({ theme }) => theme.text1};
  font-size: 12px;
  font-weight: 500;
  letter-spacing: 0.2px;

  a {
    font-weight: 600;
    color: ${({ theme }) => theme.text1};
    text-decoration: underline;
  }

  div {
    display: block;
    font-weight: 600;
    font-size: 14px;
    margin-bottom: 5px;
  }
`

const AirdropTotals = styled.div`
  width: 100%;
  margin-bottom: 0;
`

const fadeIn = keyframes`
  from {
    opacity: 0;
    transform: translateY(10px);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
`

const InitialTotal = styled.div`
  width: 100%;
  margin-bottom: 5px;
  display: inline-block;
  vertical-align: top;
  width: calc(100% - 120px);

  width: 100%;
  font-size: 15px;
  font-weight: 500;
  display: flex;
  justify-content: space-between;
  opacity: 0;
  animation: ${fadeIn} 1s ease forwards;
  animation-delay: 0s;

  @media screen and (max-width: 500px) {
    font-size: 13px;
  }
`

const PointTotalTitle = styled.div``

const PointTotalAmount = styled.div``

const InitialTotalTitle = styled(PointTotalTitle)``

const InitialTotalPoints = styled(PointTotalAmount)`
  font-weight: 600;
`

const MultSymbol = styled.div`
  display: inline-block;
  width: 43px;
  padding-top: 32px;
  text-align: center;
  display: none;
`

const LevelMultiplier = styled.div`
  width: 100%;
  margin-bottom: 5px;
  display: inline-block;
  vertical-align: top;
  width: 77px;

  width: 100%;
  font-size: 15px;
  font-weight: 500;
  display: flex;
  justify-content: space-between;
  opacity: 0;
  animation: ${fadeIn} 1s ease forwards;
  animation-delay: 0.5s;
`

const LevelTitle = styled.div`
  svg {
    transform: translate(-3px, 0);
  }
`

const LevelNumber = styled.div`
  font-weight: 600;
`

const rainbowAnimation = keyframes`
  0% {
    background-position: 0 50%;
  }
  50% {
    background-position: 100% 50%;
  }
  100% {
    background-position: 0 50%;
  }
`

const BonusPoints = styled.div<{ isVisible: boolean }>`
  width: 100%;
  margin-bottom: 5px;
  font-size: 15px;
  font-weight: 500;
  display: ${({ isVisible }) => (isVisible ? 'flex' : 'none')};
  /*justify-content: space-between;*/
  flex-direction: column;
  position: relative;
  /*opacity: 0;
  animation: ${fadeIn} 1s ease forwards;
  animation-delay: 1s;*/
  background: linear-gradient(
    to right,
    #ef5350,
    #f48fb1,
    #7e57c2,
    #2196f3,
    #26c6da,
    #43a047,
    #eeff41,
    #f9a825,
    #ff5722
  );
  background-size: 300% 300%;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  opacity: 0;
  animation:
    ${fadeIn} 1s ease forwards 1s, /* Fade in with delay */
    ${rainbowAnimation} 5s ease infinite; /* Continuous rainbow animation */
`

const TopRow = styled.div`
  display: flex;
  justify-content: space-between;
`

const BottomRow = styled.div`
  display: flex;
  justify-content: flex-end;
  margin-top: -3px;
`

const BonusPointsText = styled.div``

const BonusPointsAmount = styled.div`
  font-weight: 600;
`

const DoloAmount = styled.div`
  font-size: 10px;
  font-weight: 400;
`

const FinalTotal = styled.div`
  width: 100%;
  margin-bottom: 0;
`

const FinalTotalTitle = styled(PointTotalTitle)`
  font-weight: 600;
  width: 100%;
  text-align: center;
  margin-bottom: 4px;
`

const FinalTotalValue = styled(PointTotalAmount)`
  font-size: 42px;
  height: 66px;
  position: relative;
  display: flex;
  font-weight: 300;
  margin-top: -2px;
  width: 100%;
  text-align: center;
  justify-content: center;
  color: ${({ theme }) => theme.text1};
  background: ${({ theme }) => theme.bg6};
  border-radius: 8px;
  padding: 3px 18px;

  @media screen and (max-width: 500px) {
    font-size: 36px;
    height: 56px;
  }

  @media screen and (max-width: 420px) {
    font-size: 32px;
    height: 50px;
  }

{/*span:nth-of-type(1) {
    opacity: 0;
    animation: ${fadeIn} 1s ease forwards;
    animation-delay: 3s;
  }*/}
`

const BoycoWrapper = styled.div`
  width: 100%;
  background: ${({ theme }) => theme.bg1};
  border-radius: 8px;
  padding: 28px 38px;
  margin-top: 15px;
  font-size: 15px;
  box-shadow: 0 5px 5px -3px rgb(0 0 0 / 20%), 0 8px 10px 1px rgb(0 0 0 / 14%), 0 3px 14px 2px rgb(0 0 0 / 12%);

  @media screen and (max-width: 500px) {
    padding: 22px 28px;
  }

  div:nth-of-type(1) {
    font-weight: 600;
    font-size: 17px;
    margin-bottom: 5px;
  }

  a {
    font-weight: 600;
    color: ${({ theme }) => theme.text1};
    text-decoration: underline;
  }

  @media screen and (max-width: 500px) {
    font-size: 13px;

    div:nth-of-type(1) {
      font-weight: 600;
      font-size: 13px;
      margin-bottom: 5px;
    }
  }
`

const BoycoLeft = styled.div`
  display: inline-block;
  vertical-align: top;
  width: 60px;
  margin-right: 30px;
  padding-top: 20px;

  img {
    width: 100%;
  }

  @media screen and (max-width: 500px) {
    padding-top: 15px;
  }
`

const BoycoRight = styled.div`
  display: inline-block;
  vertical-align: top;
  width: calc(100% - 90px);
`

const AirdropParams = styled.div<{ expanded: boolean }>`
  width: 100%;
  margin-top: 15px;
  background: ${({ theme }) => theme.bg1};
  border-radius: 8px;
  padding: 28px 38px;
  font-size: 15px;
  box-shadow: 0 5px 5px -3px rgb(0 0 0 / 20%), 0 8px 10px 1px rgb(0 0 0 / 14%), 0 3px 14px 2px rgb(0 0 0 / 12%);
  height: 100%;
  max-height: ${({ expanded }) => (expanded ? '1000px' : '78px')};
  transition: max-height 0.3s ease-in-out;
  overflow: hidden;

  @media screen and (max-width: 500px) {
    max-height: ${({ expanded }) => (expanded ? '1000px' : '74px')};
    padding: 22px 28px;
  }
`

const AirdropParamsTitle = styled.div<{ expanded: boolean }>`
  width: 100%;
  font-size: 18px;
  font-weight: 400;
  display: flex;
  justify-content: space-between;
  cursor: pointer;

  svg {
    transform: ${({ expanded }) => (expanded ? 'rotate(45deg)' : 'rotate(0)')};
    margin-top: -1px;
    transition: transform 0.3s ease-in-out;
  }

  @media screen and (max-width: 500px) {
    font-size: 15px;

    svg {
      font-size: 20px;
    }
  }
`

const AirdropParamsContent = styled.div<{ expanded: boolean }>`
  opacity: ${({ expanded }) => (expanded ? 1 : 0)};
  transition: opacity 0.3s ease-in-out;
`

const AirdropParamsDescription = styled.div`
  font-size: 12px;
  color: ${({ theme }) => theme.text2};
  font-weight: 400;
  margin-top: 2px;

  @media screen and (max-width: 500px) {
    font-size: 11px;
  }
`

const InfoTitle = styled.div`
  font-size: 18px;
  font-weight: 600;
  margin-top: 15px;
  margin-bottom: 10px;

  @media screen and (max-width: 500px) {
    font-size: 16px;
  }
`

const AirdropParamRow = styled.div`
  font-size: 14px;
  font-weight: 500;
  margin-bottom: 10px;

  svg {
    font-size: 14px;
    color: ${({ theme }) => theme.blue2};
    margin-right: 5px;
    margin-bottom: -1px;
  }

  @media screen and (max-width: 500px) {
    font-size: 12px;
    margin-bottom: 7px;
  }
`

const AirdropInfoRow = styled.div`
  margin-bottom: 10px;
`

const AirdropInfoLabel = styled.div`
  font-size: 14px;
  font-weight: 600;
  margin-bottom: 5px;

  @media screen and (max-width: 500px) {
    font-size: 12px;
    margin-bottom: 3px;
  }
`

const AirdropInfoValue = styled.div`
  font-size: 13px;
  font-weight: 500;
  color: ${({ theme }) => theme.text2};
  margin-bottom: 15px;

  @media screen and (max-width: 500px) {
    font-size: 11px;
    margin-bottom: 12px;
  }
`

const ClaimSection = styled.div`
  width: 100%;
  margin-top: 15px;
  background: ${({ theme }) => theme.bg1};
  border-radius: 8px;
  padding: 28px 38px;
  font-size: 15px;
  box-shadow: 0 5px 5px -3px rgb(0 0 0 / 20%), 0 8px 10px 1px rgb(0 0 0 / 14%), 0 3px 14px 2px rgb(0 0 0 / 12%);
  text-align: center;

  @media screen and (max-width: 500px) {
    padding: 22px 28px;
  }
`

const ClaimTitle = styled.div`
  font-size: 24px;
  font-weight: 400;
  margin-bottom: 10px;
  text-align: left;
`

const ClaimSubtitle = styled.div`
  font-size: 14px;
  line-height: 18px;
  font-weight: 300;
  margin-bottom: 15px;
  margin-top: -10px;
  color: ${({ theme }) => theme.text2};
  text-align: left;
  letter-spacing: 0.1px;
`

const ExchangeRate = styled.div`
  font-size: 13px;
  font-weight: 400;
  width: 100%;
  text-align: left;
  color: ${({ theme }) => theme.text2};

  @media screen and (max-width: 500px) {
    font-size: 11px;
  }
`

const Field = styled.div`
  margin-bottom: 10px;
  color: #f9f9f9 !important;
  display: inline-flex !important;
  position: relative !important;
  font-size: 1rem !important;
  background: #1e1c29 !important;
  font-family: Open Sans, serif !important;
  line-height: 1.1875em !important;
  font-weight: 300 !important;
  border-radius: 5px !important;
  justify-content: space-between !important;
  width: 100%;
  padding: 9px 12px;
  height: fit-content;
  text-align: right;
`

const FieldAsset = styled.div`
  font-size: 15px;
  font-weight: 300;
  background: #3a3a4f;
  padding: 9px 12px;
  margin: -9px -12px -9px 0;
  border-top-right-radius: 5px;
  border-bottom-right-radius: 5px;

  svg {
    height: 15px;
    width: auto;
    transform: translateY(2px);
  }

  img {
    height: 15px;
    width: auto;
    transform: translateY(2px);
  }
`

const ExtraInfo = styled.div`
  font-size: 12px;
  color: ${({ theme }) => theme.text3};
  margin-top: -9px;
  margin-bottom: 10px;
  width: 100%;
  text-align: left;
  line-height: 15px;
`

const NextStepsWrapper = styled.div<{ hasClaimed: boolean }>`
  height: auto;
  max-height: ${({ hasClaimed }) => (hasClaimed ? '500px' : '0')};
  width: 100%;
  overflow: hidden;
  transition: max-height 0.3s ease-in-out;
  background: #1e1c29;
  border-radius: 8px;
`

const NextStepsTitle = styled.div`
  width: 80%;
  text-align: center;
  font-size: 19px;
  font-weight: 600;
  margin: 18px auto 15px;
`

const NextStepsList = styled.div`
  width: 100%;
  font-size: 12px;
  margin-bottom: 25px;
`

const NextStepsItem = styled.div`
  width: 80%;
  margin: 0 auto 15px;
  text-align: center;
  color: ${({ theme }) => theme.text2};

  a {
    color: ${({ theme }) => theme.text1} !important;
    font-weight: 600;
  }
`

const AddDoloButton = styled.div`
  width: fit-content;
  text-wrap: nowrap;
  padding: 4px 12px;
  margin-top: 5px;
  background: ${({ theme }) => theme.blue1};
  color: ${({ theme }) => theme.text1};
  font-weight: 600;
  border-radius: 4px;
  cursor: pointer;
  display: inline-block;

  :hover {
    background: ${({ theme }) => theme.blue2};
  }
`

const DownArrowWrapper = styled.div`
  width: 100%;
  text-align: center;
  margin-top: 5px;
  margin-bottom: 9px;
  font-size: 18px;
`

const AddWrapper = styled.div`
  width: 100%;
  text-align: center;
  margin-top: -8px;
  margin-bottom: 0;
`

const ChangeNetworkButton = styled.div`
  background-color: ${({ theme }) => theme.yellow2};
  color: ${({ theme }) => theme.text1};
  width: 100%;
  font-weight: 500;
  cursor: pointer;
  border-radius: 5px;
  padding: 8px 16px;
  margin: 25px auto 0;

  :hover {
    background-color: ${({ theme }) => theme.yellow1};
  }
`

const ConnectWalletButton = styled.div`
  margin-top: 15px;
  color: white !important;
  width: 100%;
`

const ConnectBtn = styled.div`
  display: inline-block;
  width: 100%;
`

const ClaimButton = styled.div<{ isClaimable: boolean }>`
  border-radius: 5px;
  background-color: ${({ theme, isClaimable }) => (isClaimable ? theme.green2 : theme.bg4)};
  color: ${({ theme }) => theme.text1};
  font-size: 14px;
  font-weight: 600;
  padding: 8px 20px;
  cursor: ${({ isClaimable }) => (isClaimable ? 'pointer' : 'default')};
  width: 100%;
  text-align: center;
  line-height: 22px;
  margin-top: 20px;
  margin-bottom: 4px;
  height: 38px;

  :hover {
    background-color: ${({ theme, isClaimable }) => (isClaimable ? theme.green1 : theme.bg4)};
  }

  > svg,
  > div {
    color: ${({ theme }) => theme.text1} !important;
    font-size: 20px;
    height: 20px !important;
    width: 20px !important;
  }
`

const StyledSeparator = styled(Separator)`
  height: 1px;
  background-color: #606375;
  margin-top: 20px;
  margin-bottom: 18px;
  width: 80%;
  margin-left: 10%;
`

const LEVEL_5_BONUS_DOLO_AMOUNT = new Fraction(5000, 1)
const LEVEL_10_BONUS_DOLO_AMOUNT = new Fraction(20000, 1)

export default function UsageAirdrop() {
  const { chainId, account, setChain } = useActiveWeb3React()
  const [airdropParamsExpanded, setAirdropParamsExpanded] = useState(false)
  const [hasAddedToWallet, setHasAddedToWallet] = useState(false)
  const [hasClaimedDrop, setHasClaimedDrop] = useState(false)
  const isOnWrongNetwork = chainId !== ChainId.BERACHAIN
  const isClaimingLive = false

  const {
    amount: userDolo,
    userAddress: userAddress,
    levelSnapshot: level,
    isSmartContract,
    loading: isAirdropDataLoading,
    error: airdropDataError,
    remappedUserAddress: remappedUserAddress,
  } = useUsageAirdropData(account)

  const hasRemappedAddress = remappedUserAddress && remappedUserAddress.toLowerCase() !== account?.toLowerCase() // is the original wallet for an airdrop that has been remapped to a different address
  const isRemappedAddress = remappedUserAddress && remappedUserAddress.toLowerCase() === account?.toLowerCase() // is the remapped address that can claim for a different wallet that received the airdrop

  const userHasLevel5BonusDolo = level >= 5
  const userHasLevel10BonusDolo = level >= 10
  const preBonusTotal = userDolo?.subtract(
    userHasLevel10BonusDolo
      ? LEVEL_5_BONUS_DOLO_AMOUNT.add(LEVEL_10_BONUS_DOLO_AMOUNT)
      : userHasLevel5BonusDolo
      ? LEVEL_5_BONUS_DOLO_AMOUNT
      : 0,
  )
  let preMultiplierTotal: Fraction | undefined = undefined
  if (level > 1 && preBonusTotal && preBonusTotal.multiply(new Fraction(level - 1, level)).greaterThan(500_000)) {
    preMultiplierTotal = preBonusTotal?.subtract(500_000)
  } else {
    preMultiplierTotal = preBonusTotal?.divide(Math.max(level, 1))
  }
  const parsedUserTotalDolo = userDolo ? parseFloat(userDolo.toFixed(2)) : undefined
  const isClaimable = true
  const isClaiming = false
  const hasClaimed = hasClaimedDrop //userTotalPoints?.greaterThan(ZERO_FRACTION) && !isClaimable
  const onClaim = useCallback(() => {
    setHasClaimedDrop(true)
  }, [])

  const handleAddDolo = useCallback(async () => {
    if (window.ethereum) {
      try {
        // @ts-ignore
        const wasAdded = await window.ethereum.request({
          method: 'wallet_watchAsset',
          params: {
            type: 'ERC20',
            options: {
              address: 'TBD',
              symbol: 'DOLO',
              decimals: 18,
              image: 'https://dolomite.io/DOLO-logo.png', // Optional: URL to your token logo
            },
          },
        })

        if (wasAdded) {
          setHasAddedToWallet(true)
        } else {
          console.log('Token addition declined.')
        }
      } catch (error) {
        console.error('Error adding token:', error)
      }
    } else {
      console.log('Ethereum wallet not found.')
    }
  }, [])

  return (
    <UsageSection>
      <UsageTitle>Usage Airdrop</UsageTitle>
      <UsageSubtitle>
        Earned retroactively for supplying and borrowing on Dolomite. Can be used to claim a combination of DOLO and
        veDOLO.
      </UsageSubtitle>
      <UsageBreakdown>
        <AirdropTotals key={account}>
          <InitialTotal style={{ animationDelay: account ? '0s' : '2s' }}>
            <InitialTotalTitle>Your DOLO</InitialTotalTitle>
            <InitialTotalPoints>{formatAmount(preMultiplierTotal, 2, true, '-')}</InitialTotalPoints>
          </InitialTotal>
          {/*<MultSymbol>
            <Mult />
          </MultSymbol>*/}
          <LevelMultiplier style={{ animationDelay: account ? '0.5s' : '2.5s' }}>
            <LevelTitle>
              Your Level Multiplier{' '}
              <StyledTooltipWithIcon tooltipText={`Your level in the Dolomite Community XP Program`} />
            </LevelTitle>
            <LevelNumber>{account ? level : '-'}</LevelNumber>
          </LevelMultiplier>
          <BonusPoints isVisible={userHasLevel5BonusDolo} style={{ animationDelay: account ? '1s' : '3s' }}>
            <TopRow>
              <BonusPointsText>Level 5+ Bonus</BonusPointsText>
              <BonusPointsAmount>{formatAmount(LEVEL_5_BONUS_DOLO_AMOUNT, 2, true, '-')}</BonusPointsAmount>
            </TopRow>
          </BonusPoints>
          <BonusPoints isVisible={userHasLevel10BonusDolo} style={{ animationDelay: account ? '1s' : '3s' }}>
            <TopRow>
              <BonusPointsText>Level 10+ Bonus</BonusPointsText>
              <BonusPointsAmount>{formatAmount(LEVEL_10_BONUS_DOLO_AMOUNT, 2, true, '-')}</BonusPointsAmount>
            </TopRow>
          </BonusPoints>
          <StyledSeparator />
          <FinalTotal>
            <FinalTotalTitle>Total DOLO</FinalTotalTitle>
            <FinalTotalValue>
              {/* TODO - re-enable the span animation if the sparkle is uncommented */}
              {/*<Sparkle color={'#3a86f3'} />*/}
              {/*<Sparkle color={'#3a86f3'} overflowPx={5} count={18} fadeOutSpeed={32} flickerSpeed={'slower'} />*/}
              <CountUp
                end={parsedUserTotalDolo ?? 0}
                delay={account ? 1 : 3}
                duration={3}
                separator={','}
                decimals={2}
              />
            </FinalTotalValue>
          </FinalTotal>
        </AirdropTotals>
      </UsageBreakdown>
      {isSmartContract && !remappedUserAddress && (
        <SmartContractWarning>
          <SmartContractLeft>
            <ReportProblem />
          </SmartContractLeft>
          <SmartContractRight>
            <div>{`You won't be able to claim using this wallet.`}</div>
            {`This airdrop was earned using a smart contract wallet. Contact the team to arrange for a different claiming wallet by opening a ticket in the `}
            <a href={'https://discord.gg/uDRzrB2YgP'} target={'_blank'} rel={'noreferrer'}>
              Dolomite Discord
            </a>
            {`. In the ticket, choose the option for "I'm a smart contract wallet holder"`}
          </SmartContractRight>
        </SmartContractWarning>
      )}
      {hasRemappedAddress && (
        <RemappedAddressWarning>
          <SmartContractLeft>
            <Info />
          </SmartContractLeft>
          <SmartContractRight>
            <div>{`You won't be able to claim using this wallet.`}</div>
            {`This airdrop has been remapped to a different wallet, you'll need to claim with the remapped wallet:`}
            <br />
            {remappedUserAddress}
          </SmartContractRight>
        </RemappedAddressWarning>
      )}
      {isRemappedAddress && (
        <RemappedAddressInfo>
          <SmartContractLeft>
            <Info />
          </SmartContractLeft>
          <SmartContractRight>
            <div>{`This airdrop allocation has been remapped from another address.`}</div>
            {`You will be claiming the airdrop for the wallet:`}
            <br />
            {userAddress}
          </SmartContractRight>
        </RemappedAddressInfo>
      )}
      {(!userDolo || userDolo?.equalTo(ZERO_FRACTION)) && (
        <BoycoWrapper>
          <BoycoLeft>
            <img src={NETWORK_LOGOS[ChainId.BERACHAIN]} />
          </BoycoLeft>
          <BoycoRight>
            <div>Looking for veDOLO from Boyco or Boyco external vaults?</div>
            <div>
              Check our <a href={'https://app.dolomite.io/boyco'}>Boyco page</a>
            </div>
          </BoycoRight>
        </BoycoWrapper>
      )}
      <AirdropParams expanded={airdropParamsExpanded}>
        <AirdropParamsTitle
          expanded={airdropParamsExpanded}
          onClick={() => setAirdropParamsExpanded(!airdropParamsExpanded)}
        >
          <>Airdrop Parameters and Info</>
          <>
            <Add />
          </>
        </AirdropParamsTitle>
        <AirdropParamsContent expanded={airdropParamsExpanded}>
          <AirdropParamsDescription>
            This is a retroactive airdrop, with DOLO earned for actions performed on Dolomite up to the snapshot date.
            The actions that contributed to your airdrop allocation are outlined below. The DOLO claimable in the
            airdrop is split evenly between DOLO and 2 year locked veDOLO.
          </AirdropParamsDescription>
          <InfoTitle>Airdrop Parameters</InfoTitle>
          <AirdropParamRow>
            <DoubleArrow />
            Supplied or borrowed assets on Dolomite
          </AirdropParamRow>
          <AirdropParamRow>
            <DoubleArrow />
            Value of assets supplied or borrowed
          </AirdropParamRow>
          <AirdropParamRow>
            <DoubleArrow />
            Length of time supplying or borrowing
          </AirdropParamRow>
          <AirdropParamRow>
            <DoubleArrow />
            Your level in the Dolomite Community XP Program
          </AirdropParamRow>
          <InfoTitle>Airdrop Info</InfoTitle>
          <AirdropInfoRow>
            <AirdropInfoLabel>Snapshot Date</AirdropInfoLabel>
            <AirdropInfoValue>January 6, 2025 00:00 UTC</AirdropInfoValue>
          </AirdropInfoRow>
          <AirdropInfoRow>
            <AirdropInfoLabel>Total DOLO token supply</AirdropInfoLabel>
            <AirdropInfoValue>1,000,000,000 DOLO</AirdropInfoValue>
          </AirdropInfoRow>
          <AirdropInfoRow>
            <AirdropInfoLabel>Tokens allocated to usage airdrop</AirdropInfoLabel>
            <AirdropInfoValue>90,000,000 DOLO</AirdropInfoValue>
          </AirdropInfoRow>
          <AirdropInfoRow>
            <AirdropInfoLabel>How does my level impact my airdrop?</AirdropInfoLabel>
            <AirdropInfoValue>
              Your level multiplies your airdrop allocation, with a cap of 500k bonus DOLO from your level.
            </AirdropInfoValue>
          </AirdropInfoRow>
          <AirdropInfoRow>
            <AirdropInfoLabel>Does leveling up now increase my allocation?</AirdropInfoLabel>
            <AirdropInfoValue>
              Your level was snapshotted on January 6th, increasing it now will not change your allocation. There are,
              however, plans for your level to continue to play a role in the Dolomite ecosystem.
            </AirdropInfoValue>
          </AirdropInfoRow>
          <AirdropInfoRow>
            <AirdropInfoLabel>When can the airdrop be claimed?</AirdropInfoLabel>
            <AirdropInfoValue>
              You can claim after TGE occurs, follow the official Dolomite Twitter/𝕏 for updates. You will only be able
              to claim here on the Dolomite web app, and only after it has been announced from official channels.
            </AirdropInfoValue>
          </AirdropInfoRow>
          <AirdropInfoRow>
            <AirdropInfoLabel>Where can the airdrop be claimed?</AirdropInfoLabel>
            <AirdropInfoValue>
              The airdrop can be claimed only from this page, on Berachain. Be cautious of scam links or claims that
              there are other ways to claim.
            </AirdropInfoValue>
          </AirdropInfoRow>
        </AirdropParamsContent>
      </AirdropParams>
      <ClaimSection>
        <ClaimTitle>Claim Your Airdrop</ClaimTitle>
        <ClaimSubtitle>Your DOLO allocation is split between DOLO and 2 year locked veDOLO</ClaimSubtitle>
        <Field>
          {userDolo ? formatAmount(userDolo.divide(2), 9, true, '-') : '-'}
          <FieldAsset>
            <img src={DolomiteLogo} alt={'DOLO'} /> DOLO
          </FieldAsset>
        </Field>
        <ExtraInfo>Added to your wallet upon claiming</ExtraInfo>
        <AddWrapper>
          <Add />
        </AddWrapper>
        <Field>
          {userDolo ? formatAmount(userDolo.divide(2), 9, true, '-') : '-'}
          <FieldAsset>
            <img src={DolomiteLogo} alt={'DOLO'} /> veDOLO
          </FieldAsset>
        </Field>
        <ExtraInfo>Added to a 2 year veDOLO lock upon claiming, which can be managed from the Vest page</ExtraInfo>
        <NextStepsWrapper hasClaimed={!!hasClaimed}>
          <NextStepsTitle>{`Congratulations, you've successfully claimed your airdrop!`}</NextStepsTitle>
          <NextStepsList>
            <NextStepsItem>
              {`Your DOLO is now in your wallet, if you don't see it click here to add DOLO as a recognized token: `}
              <AddDoloButton onClick={handleAddDolo}>
                {hasAddedToWallet ? 'Added to Wallet ✓' : 'Add DOLO to Wallet'}
              </AddDoloButton>
            </NextStepsItem>
            <NextStepsItem>
              {`Your veDOLO can be seen on the `}
              <a href={'/vest'}>Vest Page</a>
            </NextStepsItem>
          </NextStepsList>
        </NextStepsWrapper>
        {account ? (
          isOnWrongNetwork ? (
            <ChangeNetworkButton
              onClick={() => {
                setChain(ChainId.BERACHAIN)
              }}
            >
              Change to Berachain
            </ChangeNetworkButton>
          ) : (
            <ClaimButton
              isClaimable={isClaimable && isClaimingLive}
              onClick={isClaimable && isClaimingLive ? onClaim : undefined}
            >
              {isClaimingLive ? (
                isClaiming ? (
                  <CircularProgress />
                ) : isClaimable ? (
                  'Claim Aidrop'
                ) : (
                  'No Claim Available'
                )
              ) : (
                'Claiming Coming Soon'
              )}
            </ClaimButton>
          )
        ) : (
          <ConnectWalletButton>
            <ConnectBtn key={'accountItem-StartTrading'}>
              <Web3StatusConnectButton />
            </ConnectBtn>
          </ConnectWalletButton>
        )}
      </ClaimSection>
    </UsageSection>
  )
}
