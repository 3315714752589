import { Token } from '@dolomite-exchange/sdk-core'
import { ChainId, ChainIdMap, initializeSingleValueChainIdMap } from '../chainId'

const decimals = 18
const symbol = 'tBTC'
const name = 'Threshold BTC'

function getToken(chainId: ChainId, address: string) {
  return new Token(chainId, address, decimals, symbol, name)
}

export const TBTC: ChainIdMap<Token | undefined> = initializeSingleValueChainIdMap(
  ChainId.ARBITRUM_ONE,
  getToken(ChainId.ARBITRUM_ONE, '0x6c84a8f1c29108F47a79964b5Fe888D4f4D0dE40'),
)
