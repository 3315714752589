import { interestRatePartsMergeFunction } from '../../types/interestRateData'
import { useMemo } from 'react'
import { Percent } from '@dolomite-exchange/sdk-core'
import { CurrencyAmount, Fraction, Token } from '@dolomite-exchange/v2-sdk'
import { ARB, ChainId, USDC, W_USDM, WBTC, WETH, ZERO_FRACTION } from '../../constants'
import { useLoadDolomiteBalancesWithLoadingIndicatorData, useTokenBalance } from '../wallet/hooks'
import { usePair } from '../../data/Reserves'
import { useTotalSupply } from '../../data/TotalSupply'
import { toChecksumAddress } from '../../utils/toChecksumAddress'

import {
  useTokensWithGravitaRewards,
  useTokensWithMineralRewards,
  useTokensWithOTokenRewards,
} from '../../hooks/useLiquidityMining'
import { useActiveWeb3React } from '../../hooks'
import { useDefaultFiatValuesWithLoadingIndicator } from '../../hooks/useFiatValue'
import { BRIDGED_USDC } from '../../constants/tokens/USDC'
import { getIsolationModeTokenBySymbolAndChainId, SpecialAssetSymbol } from '../../constants/isolation/special-assets'
import mergeMaps from '../../utils/mergeMaps'
import { ChainIdMap } from '../../constants/chainId'
import { InterestRatePart, InterestRatePartCategory, InterestRatePartSubcategory } from '../../types/InterestRatePart'
import { GRAI } from '../../constants/tokens/GRAI'
import { useAllActiveTokensArray } from '../../hooks/Tokens'
import { TokenTvl, useDolomiteMarginTokenTvlData } from '../../types/dolomiteMarginTokenTvlData'

const ARB_STIP_REWARDS_LABEL_MAP: ChainIdMap<string | undefined> = {
  [ChainId.MAINNET]: undefined,
  [ChainId.ARBITRUM_ONE]: 'ARB Rewards',
  [ChainId.BASE]: undefined,
  [ChainId.BERACHAIN]: undefined,
  [ChainId.MANTLE]: undefined,
  [ChainId.POLYGON_ZKEVM]: undefined,
  [ChainId.X_LAYER]: undefined,
}

const ARB_STIP_REWARDS_WEIGHTS_PER_WEEK_MAP: ChainIdMap<Record<string, Fraction | undefined>> = {
  [ChainId.MAINNET]: {},
  [ChainId.ARBITRUM_ONE]: {
    [SpecialAssetSymbol.dPtEzEthSeptember2024]: new Fraction(1_000),
    [SpecialAssetSymbol.dPtWeEthSeptember2024]: new Fraction(1_000),
    [SpecialAssetSymbol.dPtRsEthSeptember2024]: new Fraction(1_000),
    [SpecialAssetSymbol.wUSDM]: new Fraction(1_000),
  },
  [ChainId.BASE]: {},
  [ChainId.BERACHAIN]: {},
  [ChainId.MANTLE]: {},
  [ChainId.POLYGON_ZKEVM]: {},
  [ChainId.X_LAYER]: {},
}

const ARB_STIP_ELIGIBLE_TOKENS_MAP: ChainIdMap<Token[]> = {
  [ChainId.MAINNET]: [],
  [ChainId.ARBITRUM_ONE]: [
    W_USDM[ChainId.ARBITRUM_ONE]!,
    getIsolationModeTokenBySymbolAndChainId(ChainId.ARBITRUM_ONE, SpecialAssetSymbol.dPtEzEthSeptember2024)!,
    getIsolationModeTokenBySymbolAndChainId(ChainId.ARBITRUM_ONE, SpecialAssetSymbol.dPtRsEthSeptember2024)!,
    getIsolationModeTokenBySymbolAndChainId(ChainId.ARBITRUM_ONE, SpecialAssetSymbol.dPtWeEthSeptember2024)!,
  ],
  [ChainId.BASE]: [],
  [ChainId.BERACHAIN]: [],
  [ChainId.MANTLE]: [],
  [ChainId.POLYGON_ZKEVM]: [],
  [ChainId.X_LAYER]: [],
}

export const GO_ARB_REWARDS_LABEL_MAP: ChainIdMap<string | undefined> = {
  [ChainId.MAINNET]: undefined,
  [ChainId.ARBITRUM_ONE]: 'goARB Rewards',
  [ChainId.BASE]: undefined,
  [ChainId.BERACHAIN]: undefined,
  [ChainId.MANTLE]: undefined,
  [ChainId.POLYGON_ZKEVM]: undefined,
  [ChainId.X_LAYER]: undefined,
}

export const GO_ARB_REWARDS_WEIGHTS_PER_WEEK_MAP: ChainIdMap<Record<string, Fraction | undefined>> = {
  [ChainId.MAINNET]: {},
  [ChainId.ARBITRUM_ONE]: {
    GRAI: new Fraction(3_000),
  },
  [ChainId.BASE]: {},
  [ChainId.BERACHAIN]: {},
  [ChainId.MANTLE]: {},
  [ChainId.POLYGON_ZKEVM]: {},
  [ChainId.X_LAYER]: {},
}

const GO_ARB_ELIGIBLE_TOKENS_MAP: ChainIdMap<Token[]> = {
  [ChainId.MAINNET]: [],
  [ChainId.ARBITRUM_ONE]: [GRAI[ChainId.ARBITRUM_ONE]!],
  [ChainId.BASE]: [],
  [ChainId.BERACHAIN]: [],
  [ChainId.MANTLE]: [],
  [ChainId.POLYGON_ZKEVM]: [],
  [ChainId.X_LAYER]: [],
}

export const O_TOKEN_REWARDS_LABEL_MAP: ChainIdMap<string | undefined> = {
  [ChainId.MAINNET]: undefined,
  [ChainId.ARBITRUM_ONE]: 'oARB Rewards',
  [ChainId.BASE]: undefined,
  [ChainId.BERACHAIN]: undefined,
  [ChainId.MANTLE]: undefined,
  [ChainId.POLYGON_ZKEVM]: undefined,
  [ChainId.X_LAYER]: undefined,
}

export const O_ARB_REWARDS_PER_WEEK_MAP = 44_500
export const O_ARB_REWARDS_WEIGHTS_PER_WEEK_MAP: ChainIdMap<Record<string, Fraction | undefined>> = {
  [ChainId.MAINNET]: {},
  [ChainId.ARBITRUM_ONE]: {
    WETH: new Fraction(8_800),
    LINK: new Fraction(1_170),
    WBTC: new Fraction(6_800),
    ARB: new Fraction(1_730),
    rsETH: new Fraction(1_000),
    USDC: new Fraction(17_500),
    wUSDM: new Fraction(1_000),
    GRAI: new Fraction(1_000),
    wOETH: new Fraction(1_000),
    gmBTC: new Fraction(750),
    'gmBTC-USD': new Fraction(750),
    gmETH: new Fraction(750),
    'gmETH-USD': new Fraction(750),
    'gmARB-USD': new Fraction(500),
    'gmLINK-USD': new Fraction(500),
    'gmUNI-USD': new Fraction(500),
  },
  [ChainId.BASE]: {},
  [ChainId.BERACHAIN]: {},
  [ChainId.MANTLE]: {},
  [ChainId.POLYGON_ZKEVM]: {},
  [ChainId.X_LAYER]: {},
}

const dolomiteHolder = toChecksumAddress('0xa75c21C5BE284122a87A37a76cc6C4DD3E55a1D4')
const falconXHolder = toChecksumAddress('0xabde2f02fe84e083e1920471b54c3612456365ef')
const leavittHolder = toChecksumAddress('0xbDEf2b2051E2aE113297ee8301e011FD71A83738')
const multisigHolder = toChecksumAddress('0x52d7BcB650c591f6E8da90f797A1d0Bfd8fD05F9')
const allHolders = [dolomiteHolder, leavittHolder, falconXHolder, multisigHolder]

export const MINERALS_LABEL = '+Minerals'
const MINERAL_INTEREST_RATE_PART: InterestRatePart = {
  label: MINERALS_LABEL,
  interestRate: undefined,
  isBorrowRateImpacted: false,
  metadata: null,
  rewardToken: undefined,
  category: InterestRatePartCategory.MINERALS,
  subcategory: undefined,
  rewardClaimUrl: undefined,
}

export const GRAVITA_POINTS_LABEL = '+3x Gravita Ascend Points'
const GRAVITA_INTEREST_RATE_PART: InterestRatePart = {
  label: GRAVITA_POINTS_LABEL,
  interestRate: undefined,
  isBorrowRateImpacted: false,
  metadata: null,
  rewardToken: ARB[ChainId.ARBITRUM_ONE],
  category: InterestRatePartCategory.REWARDS,
  subcategory: InterestRatePartSubcategory.CLAIM,
  rewardClaimUrl: 'https://app.gravitaprotocol.com/goarb',
}

export const POWDER_LABEL = '+20x Mantle Powder'

const EMPTY_MAP = {}

export function hasExternalRewards(chainId: ChainId, interestRateParts: InterestRatePart[] | undefined): boolean {
  return (
    interestRateParts?.some(
      interestRatePart =>
        ARB_STIP_REWARDS_LABEL_MAP[chainId] === interestRatePart.label ||
        GO_ARB_REWARDS_LABEL_MAP[chainId] === interestRatePart.label ||
        O_TOKEN_REWARDS_LABEL_MAP[chainId] === interestRatePart.label,
    ) ?? false
  )
}

export function useLiquidityMiningRewardsInterestRates(): Record<string, InterestRatePart[] | undefined> {
  const { account, chainId } = useActiveWeb3React()
  const arbToken = useMemo(() => ARB[chainId], [chainId])
  const allTokens = useAllActiveTokensArray()
  const allTokensEligibleForOTokens = useTokensWithOTokenRewards()
  const [prices] = useDefaultFiatValuesWithLoadingIndicator(allTokens)
  const tvlData = useDolomiteMarginTokenTvlData()
  const amountWeisToDeductMap = useAmountsToDeduct()
  const arbInterestRatePartMap = useTokenRewards(
    account,
    arbToken,
    ARB_STIP_REWARDS_LABEL_MAP[chainId],
    prices,
    ARB_STIP_ELIGIBLE_TOKENS_MAP[chainId],
    tvlData,
    ARB_STIP_REWARDS_WEIGHTS_PER_WEEK_MAP[chainId],
    EMPTY_MAP,
  )
  const goArbInterestRatePartMap = useTokenRewards(
    account,
    arbToken,
    GO_ARB_REWARDS_LABEL_MAP[chainId],
    prices,
    GO_ARB_ELIGIBLE_TOKENS_MAP[chainId],
    tvlData,
    GO_ARB_REWARDS_WEIGHTS_PER_WEEK_MAP[chainId],
    EMPTY_MAP,
  )
  const oArbInterestRatePartMap = useTokenRewards(
    account,
    arbToken,
    O_TOKEN_REWARDS_LABEL_MAP[chainId],
    prices,
    allTokensEligibleForOTokens,
    tvlData,
    O_ARB_REWARDS_WEIGHTS_PER_WEEK_MAP[chainId],
    amountWeisToDeductMap,
  )
  const mineralTokens = useTokensWithMineralRewards()
  const gravitaTokens = useTokensWithGravitaRewards()
  return useMemo(() => {
    const mapWithMineralTokens = mineralTokens.reduce((acc, m) => {
      const existingParts = acc[m.address]
      if (existingParts) {
        acc[m.address] = existingParts.concat(MINERAL_INTEREST_RATE_PART)
      } else {
        acc[m.address] = [MINERAL_INTEREST_RATE_PART]
      }
      return acc
    }, mergeMaps(interestRatePartsMergeFunction, arbInterestRatePartMap, goArbInterestRatePartMap, oArbInterestRatePartMap))
    return gravitaTokens.reduce((acc, m) => {
      const existingParts = acc[m.address]
      if (existingParts) {
        acc[m.address] = existingParts.concat(GRAVITA_INTEREST_RATE_PART)
      } else {
        acc[m.address] = [GRAVITA_INTEREST_RATE_PART]
      }
      return acc
    }, mapWithMineralTokens)
  }, [mineralTokens, arbInterestRatePartMap, goArbInterestRatePartMap, oArbInterestRatePartMap, gravitaTokens])
}

function useTokenRewards(
  account: string | undefined,
  rewardToken: Token | undefined,
  interestLabel: string | undefined,
  prices: Record<string, Fraction | undefined>,
  eligibleTokensForRewards: Token[],
  tvlData: Record<string, TokenTvl | undefined>,
  eligibleTokenSymbolToRewardTokenWeightPerWeek: Record<string, Fraction | undefined>,
  amountWeisToDeduct: Record<string, Fraction | undefined>,
): Record<string, InterestRatePart[] | undefined> {
  return useMemo(() => {
    if (!rewardToken || !interestLabel) {
      return {}
    }
    const rewardPrice = prices[rewardToken.address]
    if (!rewardPrice) {
      return {}
    }

    return eligibleTokensForRewards.reduce<Record<string, InterestRatePart[] | undefined>>((memo, token) => {
      const distributionWeight = eligibleTokenSymbolToRewardTokenWeightPerWeek[token.symbol ?? '']?.multiply(52)
      if (!distributionWeight) {
        throw new Error(`Could not find distribution weight for ${interestLabel} for ${token.symbol}`)
      }

      const rewardUsdValueAnnualized = rewardPrice.multiply(distributionWeight)
      const totalValueWei = tvlData[token.address]?.supplyLiquidity.asFraction
      const tokenPrice = prices[token.address]
      const amountWeiToDeduct = amountWeisToDeduct[token.symbol ?? ''] ?? ZERO_FRACTION
      if (!totalValueWei || !tokenPrice) {
        return memo
      }

      const totalValueWeiUsd = totalValueWei.subtract(amountWeiToDeduct).multiply(tokenPrice)
      const interestRateFraction = totalValueWeiUsd.equalTo(ZERO_FRACTION)
        ? ZERO_FRACTION
        : rewardUsdValueAnnualized.divide(totalValueWeiUsd)
      if (!allHolders.some(h => h === account)) {
        memo[token.address] = [
          {
            label: interestLabel,
            interestRate: new Percent(interestRateFraction.numerator, interestRateFraction.denominator),
            isBorrowRateImpacted: false,
            metadata: null,
            rewardToken: undefined, // TODO - unsure if this data exists here
            category: InterestRatePartCategory.NONE,
            subcategory: undefined,
            rewardClaimUrl: undefined,
          },
        ]
      }
      return memo
    }, {})
  }, [
    rewardToken,
    interestLabel,
    prices,
    eligibleTokensForRewards,
    eligibleTokenSymbolToRewardTokenWeightPerWeek,
    tvlData,
    amountWeisToDeduct,
    account,
  ])
}

function useAmountsToDeduct(): Record<string, Fraction | undefined> {
  const { chainId } = useActiveWeb3React()
  const usdcToken = useMemo(() => USDC[chainId], [chainId])
  const arbToken = useMemo(() => ARB[chainId], [chainId])
  const wbtcToken = useMemo(() => WBTC[chainId], [chainId])
  const wethToken = useMemo(() => WETH[chainId], [chainId])
  const dolomiteBalanceTokens = useMemo(() => [usdcToken, arbToken, wbtcToken, wethToken], [
    usdcToken,
    arbToken,
    wbtcToken,
    wethToken,
  ])

  const [dolomiteDefaultBalances] = useLoadDolomiteBalancesWithLoadingIndicatorData(
    dolomiteHolder,
    dolomiteBalanceTokens,
  )
  const [leavittDefaultBalances] = useLoadDolomiteBalancesWithLoadingIndicatorData(leavittHolder, dolomiteBalanceTokens)
  const [leavittBorrowBalances] = useLoadDolomiteBalancesWithLoadingIndicatorData(
    leavittHolder,
    dolomiteBalanceTokens,
    '102721892973322978809585585246579638469026402407905068941071579241784916584592',
  )
  const [multisigDefaultBalances] = useLoadDolomiteBalancesWithLoadingIndicatorData(
    multisigHolder,
    dolomiteBalanceTokens,
  )

  const bridgedUsdc = useMemo(() => BRIDGED_USDC[chainId], [chainId])
  const [, lpPair] = usePair(bridgedUsdc, wethToken)
  const falconXBalance = useTokenBalance(falconXHolder, lpPair?.liquidityToken)
  const totalSupply = useTotalSupply(lpPair?.liquidityToken)

  return useMemo(() => {
    const wethReserves = lpPair?.reserveOf(wethToken)?.asFraction ?? ZERO_FRACTION
    const falconXWethAmount =
      totalSupply && falconXBalance
        ? falconXBalance.asFraction.divide(totalSupply.asFraction).multiply(wethReserves)
        : ZERO_FRACTION
    return {
      [arbToken?.symbol ?? '']: sumMap(arbToken, [
        dolomiteDefaultBalances,
        leavittDefaultBalances,
        leavittBorrowBalances,
        multisigDefaultBalances,
      ]),
      [usdcToken.symbol ?? '']: sumMap(usdcToken, [
        dolomiteDefaultBalances,
        leavittDefaultBalances,
        leavittBorrowBalances,
        multisigDefaultBalances,
      ]),
      [wbtcToken?.symbol ?? '']: sumMap(wbtcToken, [
        dolomiteDefaultBalances,
        leavittDefaultBalances,
        leavittBorrowBalances,
        multisigDefaultBalances,
      ]),
      [wethToken.symbol ?? '']: sumMap(wethToken, [
        dolomiteDefaultBalances,
        leavittDefaultBalances,
        leavittBorrowBalances,
        multisigDefaultBalances,
      ])?.add(falconXWethAmount),
    }
  }, [
    lpPair,
    wethToken,
    totalSupply,
    falconXBalance,
    arbToken,
    dolomiteDefaultBalances,
    leavittDefaultBalances,
    leavittBorrowBalances,
    multisigDefaultBalances,
    usdcToken,
    wbtcToken,
  ])
}

function sumMap(
  token: Token | undefined,
  maps: Record<string, CurrencyAmount<Token> | undefined>[],
): Fraction | undefined {
  if (!token) {
    return undefined
  }
  return maps.reduce((acc, map) => {
    const value = map[token.address]
    return value ? acc.add(value.asFraction) : acc
  }, ZERO_FRACTION)
}
