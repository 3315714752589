import { SpecialAsset, SpecialAssetSymbol } from '../special-assets'
import {
  ChainId,
  PENDLE_PT_CM_ETH_FEB_2025_ADDRESSES,
  PENDLE_PT_CM_ETH_FEB_2025_ISOLATION_MODE_ADDRESSES,
} from '../../index'
import { Token } from '@dolomite-exchange/v2-sdk'
import { getOptionally } from '../special-assets-util-functions'
import { calculatePendlePtCmEthFeb2025ProxyVaultAddress } from '../../../utils/isolation/calculateProxyVaultAddress'
import { PENDLE_PT_DOCS_LINK } from './constants'

const decimals = 18

export const PT_CM_ETH_FEB_2025_SPECIAL_ASSET: () => SpecialAsset = () => ({
  symbol: SpecialAssetSymbol.dPtCmEthFebruary2025,
  safeSymbol: SpecialAssetSymbol.dPtCmEthFebruary2025.replace('-', '_'),
  cleanSymbol: 'PT-cmETH',
  cleanName: 'PT cmETH 2025-02-13',
  hasExternalRewards: false,
  documentationUrl: PENDLE_PT_DOCS_LINK,
  isIsolationMode: true,
  isBorrowingEnabled: false,
  chainIdToAddressMap: PENDLE_PT_CM_ETH_FEB_2025_ISOLATION_MODE_ADDRESSES,
  isolationModeInfo: {
    marketId: 15,
    getWrappedToken: (chainId: ChainId) =>
      getOptionally(
        PENDLE_PT_CM_ETH_FEB_2025_ISOLATION_MODE_ADDRESSES[chainId],
        address =>
          new Token(
            chainId,
            address,
            decimals,
            SpecialAssetSymbol.dPtCmEthFebruary2025,
            'Dolomite Isolation: PT cmETH 13FEB2025',
          ),
      ),
    getUnderlyingToken: getPendlePtCmEthUnderlyingToken,
    getUnderlyingSpenderToken: getPendlePtCmEthUnderlyingToken,
    allowableDebtTokens: () => [],
    allowableCollateralTokens: () => [],
    remapAccountAddress: calculatePendlePtCmEthFeb2025ProxyVaultAddress,
    isAsync: false,
  },
})

function getPendlePtCmEthUnderlyingToken(chainId: ChainId): Token | undefined {
  return getOptionally(
    PENDLE_PT_CM_ETH_FEB_2025_ADDRESSES[chainId],
    address => new Token(chainId, address, decimals, 'PT-cmETH', 'PT cmETH 26DEC2025'),
  )
}
