import { useCallback, useEffect, useMemo } from 'react'
import {
  updateAllTokenData,
  updateAllTokenTvlData,
  updateDolomiteBalancesWithLoadingIndicator,
  updateFiatPricesWithLoadingIndicator,
  updateInterestRateData,
  updateMarketRiskData,
} from './actions'
import { useDispatch } from 'react-redux'
import { useLoadFiatPricesWithLoadingIndicatorData } from '../../hooks/useFiatValue'
import { Fraction } from '@dolomite-exchange/sdk-core'
import { MarketRiskInfo } from '../../types/marketRiskInfoData'
import { InterestRateDataResponse } from './reducer'
import { useLoadInterestRateData } from '../../types/interestRateData'
import { TokenBalancesWithLoadingIndicator, useLoadDolomiteBalancesWithLoadingIndicatorData } from '../wallet/hooks'
import { useActiveWeb3React } from '../../hooks'
import {
  useAllActiveTokensArrayWithExternalTokens,
  useEther,
  useLoadAllTokensAllChainsData,
  useSerializedTokens,
} from '../../hooks/Tokens'
import { Token } from '@dolomite-exchange/v2-sdk'
import { ChainId } from '../../constants'
import { ChainIdMap } from '../../constants/chainId'
import { useDolomiteMarginTokenDataAllChains } from '../../types/dolomiteMarginTokenData'
import { TokenTvl } from '../../types/dolomiteMarginTokenTvlData'

export default function Updater(): null {
  const dispatch = useDispatch()
  const { account, chainId } = useActiveWeb3React()
  const ether = useEther()
  const allTokenData = useLoadAllTokensAllChainsData()
  const allTokenListWithExternalTokens = useAllActiveTokensArrayWithExternalTokens()
  const currencyList = useMemo(() => [ether, ...allTokenListWithExternalTokens], [
    allTokenListWithExternalTokens,
    ether,
  ])
  const rawTokenList = useMemo(
    () =>
      currencyList.reduce<Token[]>((memo, currency) => {
        if (currency.isToken) {
          memo.push(currency.wrapped)
        }
        return memo
      }, []),
    [currencyList],
  )
  const tokenList = useSerializedTokens(rawTokenList)
  /*const [dolomiteBalanceData] = useDolomiteBalancesWithLoadingIndicator(account, tokens)*/
  const [fiatValueMapArbitrum, isLoadingFiatValuesArbitrum] = useLoadFiatPricesWithLoadingIndicatorData(
    undefined,
    ChainId.ARBITRUM_ONE,
  ) // Needs to aggregate across all chains
  const [fiatValueMapMantle, isLoadingFiatValuesMantle] = useLoadFiatPricesWithLoadingIndicatorData(
    undefined,
    ChainId.MANTLE,
  ) // Needs to aggregate across all chains
  const [fiatValueMapBerachain, isLoadingFiatValuesBerachain] = useLoadFiatPricesWithLoadingIndicatorData(
    undefined,
    ChainId.BERACHAIN,
  ) // Needs to aggregate across all chains
  const [fiatValueMapCurrent, isLoadingFiatValuesCurrent] = useLoadFiatPricesWithLoadingIndicatorData(
    undefined,
    chainId,
  ) // Needs to aggregate across all chains
  const dolomiteTokenApiData = useDolomiteMarginTokenDataAllChains()
  const interestRateDataArbitrum = useLoadInterestRateData(ChainId.ARBITRUM_ONE) // Needs to aggregate across chains (check if needed)
  const interestRateDataMantle = useLoadInterestRateData(ChainId.MANTLE) // Needs to aggregate across chains (check if needed)
  const interestRateDataBerachain = useLoadInterestRateData(ChainId.BERACHAIN) // Needs to aggregate across chains (check if needed)
  const interestRateDataCurrent = useLoadInterestRateData(chainId) // Needs to aggregate across chains (check if needed)
  const dolomiteBalancesWithLoadingIndicatorData = useLoadDolomiteBalancesWithLoadingIndicatorData(account, tokenList)

  const fiatPricesArbitrumCallback = useCallback(
    (fiatPrices: Record<string, Fraction | undefined>, isLoadingFiatPrices: boolean) => {
      dispatch(
        updateFiatPricesWithLoadingIndicator({
          chainId: ChainId.ARBITRUM_ONE,
          mapData: {
            fiatPriceMap: fiatPrices,
            isLoading: isLoadingFiatPrices,
          },
        }),
      )
    },
    [dispatch],
  )

  useEffect(() => {
    fiatPricesArbitrumCallback(fiatValueMapArbitrum, isLoadingFiatValuesArbitrum)
  }, [fiatPricesArbitrumCallback, fiatValueMapArbitrum, isLoadingFiatValuesArbitrum])

  const fiatPricesMantleCallback = useCallback(
    (fiatPrices: Record<string, Fraction | undefined>, isLoadingFiatPrices: boolean) => {
      dispatch(
        updateFiatPricesWithLoadingIndicator({
          chainId: ChainId.MANTLE,
          mapData: {
            fiatPriceMap: fiatPrices,
            isLoading: isLoadingFiatPrices,
          },
        }),
      )
    },
    [dispatch],
  )

  useEffect(() => {
    fiatPricesMantleCallback(fiatValueMapMantle, isLoadingFiatValuesMantle)
  }, [fiatPricesMantleCallback, fiatValueMapMantle, isLoadingFiatValuesMantle])

  const fiatPricesBerachainCallback = useCallback(
    (fiatPrices: Record<string, Fraction | undefined>, isLoadingFiatPrices: boolean) => {
      dispatch(
        updateFiatPricesWithLoadingIndicator({
          chainId: ChainId.BERACHAIN,
          mapData: {
            fiatPriceMap: fiatPrices,
            isLoading: isLoadingFiatPrices,
          },
        }),
      )
    },
    [dispatch],
  )

  useEffect(() => {
    fiatPricesBerachainCallback(fiatValueMapBerachain, isLoadingFiatValuesBerachain)
  }, [fiatPricesBerachainCallback, fiatValueMapBerachain, isLoadingFiatValuesBerachain])

  const fiatPricesCurrentCallback = useCallback(
    (fiatPrices: Record<string, Fraction | undefined>, isLoadingFiatPrices: boolean) => {
      dispatch(
        updateFiatPricesWithLoadingIndicator({
          chainId: chainId,
          mapData: {
            fiatPriceMap: fiatPrices,
            isLoading: isLoadingFiatPrices,
          },
        }),
      )
    },
    [chainId, dispatch],
  )

  useEffect(() => {
    fiatPricesCurrentCallback(fiatValueMapCurrent, isLoadingFiatValuesCurrent)
  }, [fiatPricesCurrentCallback, fiatValueMapCurrent, isLoadingFiatValuesCurrent])

  const marketRiskCallback = useCallback(
    (
      marketRiskData: ChainIdMap<{
        data: Record<string, MarketRiskInfo | undefined>
        loading: boolean
        error: boolean
      }>,
    ) => {
      dispatch(updateMarketRiskData(marketRiskData))
    },
    [dispatch],
  )

  useEffect(() => {
    const marketRiskInfoMap = Object.entries(dolomiteTokenApiData).reduce(
      (memo, [chainId, result]) => {
        if (result?.data) {
          memo[parseInt(chainId) as ChainId] = {
            data: Object.keys(result.data).reduce((acc, token) => {
              acc[token] = result.data[token].riskInfo
              return acc
            }, {} as Record<string, MarketRiskInfo>),
            error: result.error,
            loading: result.loading,
          }
        } else if (result) {
          memo[parseInt(chainId) as ChainId] = {
            data: {},
            error: result.error,
            loading: result.loading,
          }
        }
        return memo
      },
      {} as ChainIdMap<{
        data: Record<string, MarketRiskInfo>
        loading: boolean
        error: boolean
      }>,
    )

    marketRiskCallback(marketRiskInfoMap)
  }, [marketRiskCallback, dolomiteTokenApiData])

  const tokenTvlDataCallback = useCallback(
    (tokenTvlData: ChainIdMap<Record<string, TokenTvl | undefined>>) => {
      dispatch(updateAllTokenTvlData(tokenTvlData))
    },
    [dispatch],
  )

  useEffect(() => {
    const tokenTvlData = Object.entries(dolomiteTokenApiData).reduce((memo, [chainId, result]) => {
      if (result?.data) {
        memo[parseInt(chainId) as ChainId] = Object.keys(result.data).reduce((acc, token) => {
          acc[token] = {
            address: token,
            supplyLiquidity: result.data[token].supplyLiquidity,
            borrowLiquidity: result.data[token].borrowLiquidity,
          }
          return acc
        }, {} as Record<string, TokenTvl>)
      } else if (result) {
        memo[parseInt(chainId) as ChainId] = {}
      }
      return memo
    }, {} as ChainIdMap<Record<string, TokenTvl | undefined>>)

    tokenTvlDataCallback(tokenTvlData)
  }, [tokenTvlDataCallback, dolomiteTokenApiData])

  const interestRateCallbackArbitrum = useCallback(
    (InterestRateData: InterestRateDataResponse) => {
      dispatch(
        updateInterestRateData({
          chainId: ChainId.ARBITRUM_ONE,
          interestData: InterestRateData,
        }),
      )
    },
    [dispatch],
  )

  useEffect(() => {
    interestRateCallbackArbitrum(interestRateDataArbitrum)
  }, [interestRateCallbackArbitrum, interestRateDataArbitrum])

  const interestRateCallbackMantle = useCallback(
    (InterestRateData: InterestRateDataResponse) => {
      dispatch(
        updateInterestRateData({
          chainId: ChainId.MANTLE,
          interestData: InterestRateData,
        }),
      )
    },
    [dispatch],
  )

  useEffect(() => {
    interestRateCallbackMantle(interestRateDataMantle)
  }, [interestRateCallbackMantle, interestRateDataMantle])

  const interestRateCallbackBerachain = useCallback(
    (InterestRateData: InterestRateDataResponse) => {
      dispatch(
        updateInterestRateData({
          chainId: ChainId.BERACHAIN,
          interestData: InterestRateData,
        }),
      )
    },
    [dispatch],
  )

  useEffect(() => {
    interestRateCallbackBerachain(interestRateDataBerachain)
  }, [interestRateCallbackBerachain, interestRateDataBerachain])

  const interestRateCallbackCurrent = useCallback(
    (InterestRateData: InterestRateDataResponse) => {
      dispatch(
        updateInterestRateData({
          chainId: chainId,
          interestData: InterestRateData,
        }),
      )
    },
    [chainId, dispatch],
  )

  useEffect(() => {
    interestRateCallbackCurrent(interestRateDataCurrent)
  }, [interestRateCallbackCurrent, interestRateDataCurrent])

  const dolomiteBalancesCallback = useCallback(
    (dolomiteBalancesData: TokenBalancesWithLoadingIndicator) => {
      dispatch(updateDolomiteBalancesWithLoadingIndicator(dolomiteBalancesData))
    },
    [dispatch],
  )

  useEffect(() => {
    dolomiteBalancesCallback(dolomiteBalancesWithLoadingIndicatorData)
  }, [dolomiteBalancesCallback, dolomiteBalancesWithLoadingIndicatorData])

  const allTokenDataCallback = useCallback(
    (allTokenData: ChainIdMap<Record<string, Token | undefined>>) => {
      dispatch(updateAllTokenData(allTokenData))
    },
    [dispatch],
  )

  useEffect(() => {
    allTokenDataCallback(allTokenData)
  }, [allTokenData, allTokenDataCallback, dolomiteBalancesCallback, dolomiteBalancesWithLoadingIndicatorData])

  return null
}
