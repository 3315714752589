import { TOKEN_LIST as DEFAULT_TOKEN_LIST } from '@dolomite-exchange/v2-sdk'
import { Token } from '@dolomite-exchange/sdk-core'
import { Tags, TokenInfo, TokenList } from '@uniswap/token-lists'
import { useMemo } from 'react'
import { ChainId } from '../../constants'
import {
  useDolomiteMarginTokenDataAllChainsAsTokenList,
  useDolomiteMarginTokenDataAsTokenList,
} from '../../types/dolomiteMarginTokenData'
import { isTokenIgnored } from '../../constants/isolation/special-assets'
import { useShowInactiveTokens } from '../user/hooks'
import { ACTIVE_CHAIN_IDS, initializeObjectChainIdMap } from '../../constants/chainId'
import { useActiveWeb3React } from '../../hooks'

type TagDetails = Tags[keyof Tags]

export interface TagInfo extends TagDetails {
  id: string
}

/**
 * Token instances created from token info.
 */
export class WrappedTokenInfo extends Token {
  public readonly tokenInfo: TokenInfo
  public readonly tags: TagInfo[]

  constructor(tokenInfo: TokenInfo, tags: TagInfo[], showInactiveTokens: boolean) {
    super(
      tokenInfo.chainId,
      tokenInfo.address,
      tokenInfo.decimals,
      tokenInfo.symbol,
      tokenInfo.name,
      !isTokenIgnored(tokenInfo.chainId, tokenInfo.address, tokenInfo.symbol, showInactiveTokens),
    )
    this.tokenInfo = tokenInfo
    this.tags = tags
  }
}

export type TokenAddressMap = Readonly<
  {
    [chainId in ChainId]: Readonly<Record<string, { token: WrappedTokenInfo }>>
  }
>

/**
 * An empty result, useful as a default.
 */
const EMPTY_LIST: TokenAddressMap = initializeObjectChainIdMap()

const listCache: WeakMap<TokenList & { showInactiveTokens: boolean }, TokenAddressMap> | null =
  typeof WeakMap !== 'undefined' ? new WeakMap<TokenList, TokenAddressMap>() : null

export function listToTokenMap(list: TokenList, showInactiveTokens: boolean): TokenAddressMap {
  const result = listCache?.get({
    ...list,
    showInactiveTokens,
  })
  if (result) {
    return result
  }

  const map = list.tokens.reduce<TokenAddressMap>(
    (tokenMap, tokenInfo) => {
      const tags: TagInfo[] =
        tokenInfo.tags
          ?.map(tagId => {
            if (!list.tags?.[tagId]) {
              return undefined
            }
            return {
              ...list.tags[tagId],
              id: tagId,
            }
          })
          ?.filter((x): x is TagInfo => Boolean(x)) ?? []
      const token = new WrappedTokenInfo(tokenInfo, tags, showInactiveTokens)
      if (token.chainId in ChainId && tokenMap[token.chainId as ChainId][token.address] !== undefined) {
        throw Error('Duplicate tokens for list ' + list.name + ' ' + list.version.major + '-' + list.version.minor)
      }
      return {
        ...tokenMap,
        [token.chainId]: {
          ...tokenMap[token.chainId as ChainId],
          [token.address]: {
            token,
          },
        },
      }
    },
    { ...EMPTY_LIST },
  )
  listCache?.set(
    {
      ...list,
      showInactiveTokens,
    },
    map,
  )
  return map
}

function combineMaps(map1: TokenAddressMap, map2: TokenAddressMap): TokenAddressMap {
  return {
    [ChainId.MAINNET]: { ...map1[ChainId.MAINNET], ...map2[ChainId.MAINNET] },
    [ChainId.ARBITRUM_ONE]: { ...map1[ChainId.ARBITRUM_ONE], ...map2[ChainId.ARBITRUM_ONE] },
    [ChainId.BASE]: { ...map1[ChainId.BASE], ...map2[ChainId.BASE] },
    [ChainId.BERACHAIN]: { ...map1[ChainId.BERACHAIN], ...map2[ChainId.BERACHAIN] },
    [ChainId.MANTLE]: { ...map1[ChainId.MANTLE], ...map2[ChainId.MANTLE] },
    [ChainId.POLYGON_ZKEVM]: { ...map1[ChainId.POLYGON_ZKEVM], ...map2[ChainId.POLYGON_ZKEVM] },
    [ChainId.X_LAYER]: { ...map1[ChainId.X_LAYER], ...map2[ChainId.X_LAYER] },
  }
}

// get all the tokens from active lists across all chains, combine with local default tokens
export function useCombinedAllActiveListsAllChains(): TokenAddressMap {
  const { chainId } = useActiveWeb3React()
  const dolomiteTokenListByChain = useDolomiteMarginTokenDataAllChainsAsTokenList()
  const [showInactiveTokens] = useShowInactiveTokens()
  return useMemo(() => {
    const combinedTokenList = {
      name: dolomiteTokenListByChain[chainId].name,
      timestamp: dolomiteTokenListByChain[chainId].timestamp,
      version: dolomiteTokenListByChain[chainId].version,
      tokens: ACTIVE_CHAIN_IDS.reduce((acc, chainId) => {
        return [...acc, ...dolomiteTokenListByChain[chainId].tokens]
      }, [] as TokenInfo[]),
      keywords: dolomiteTokenListByChain[chainId].keywords,
      tags: dolomiteTokenListByChain[chainId].tags,
      logoURI: dolomiteTokenListByChain[chainId].logoURI,
    }

    return combineMaps(
      listToTokenMap(DEFAULT_TOKEN_LIST, showInactiveTokens),
      listToTokenMap(combinedTokenList, showInactiveTokens),
    )
  }, [chainId, dolomiteTokenListByChain, showInactiveTokens])
}

// get all the tokens from active lists, combine with local default tokens
export function useCombinedAllActiveLists(): TokenAddressMap {
  const dolomiteTokenList = useDolomiteMarginTokenDataAsTokenList()
  const [showInactiveTokens] = useShowInactiveTokens()
  return useMemo(() => {
    return combineMaps(
      listToTokenMap(DEFAULT_TOKEN_LIST, showInactiveTokens),
      listToTokenMap(dolomiteTokenList, showInactiveTokens),
    )
  }, [dolomiteTokenList, showInactiveTokens])
}

// all tokens from inactive lists
export function useTradingTokenList(): TokenAddressMap {
  const [showInactiveTokens] = useShowInactiveTokens()
  return useMemo(() => listToTokenMap(DEFAULT_TOKEN_LIST, showInactiveTokens), [showInactiveTokens])
}
