import React, { useCallback, useEffect, useMemo, useState } from 'react'
import styled from 'styled-components/macro'
import PageTitle from '../../components/common/PageTitle'
import { useActiveWeb3React } from '../../hooks'
import { ChainId } from '../../constants'
import UsageAirdrop from './UsageAirdrop'
import MineralsAirdrop from './MineralsAirdrop'
import InvestorSection from './InvestorSection'
import Countdown, { zeroPad } from 'react-countdown'
import AirdropToSModal from '../../components/AirdropToSModal'

const AirdropWrapper = styled.div`
  margin: 25px auto 35px;
  width: calc(100% - 80px);
  max-width: 1000px;
  padding-bottom: 200px;

  @media screen and (max-width: 900px) {
    margin-top: 15px;
    width: 100%;
    max-width: 600px;
  }

  @media screen and (max-width: 730px) {
    width: calc(100% - 40px);
  }

  @media screen and (max-width: 660px) {
    margin: 0 auto 15px;
    width: calc(100% - 30px);
    height: calc(100vh - 215px);
  }
`

const AirdropInfoPanel = styled.div`
  width: 100%;
  margin-bottom: 25px;
  letter-spacing: 0.2px;

  @media screen and (max-width: 950px) {
    width: calc(100% - 40px);
    max-width: 600px;
    margin: 20px auto 0;
    display: block;
  }

  @media screen and (max-width: 500px) {
    width: 100%;
  }
`

const InfoPanelTitle = styled.div`
  font-size: 52px;
  font-weight: 200;
  margin-bottom: 10px;

  @media screen and (max-width: 600px) {
    font-size: 46px;
  }

  @media screen and (max-width: 500px) {
    font-size: 38px;
  }

  @media screen and (max-width: 400px) {
    margin-bottom: 20px;
    line-height: 42px;
  }
`

const InfoPanelSubtitle = styled.div`
  font-size: 30px;
  color: ${({ theme }) => theme.text6};
  margin-top: -18px;
  margin-bottom: 10px;
  font-weight: 200;

  @media screen and (max-width: 600px) {
    font-size: 26px;
  }

  @media screen and (max-width: 500px) {
    font-size: 21px;
  }
`

const InfoPanelContent = styled.div`
  font-size: 17px;
  font-weight: 300;
  line-height: 26px;

  a {
    color: ${({ theme }) => theme.text1};
    font-weight: 600;
  }

  @media screen and (max-width: 600px) {
    font-size: 16px;
    line-height: 24px;
  }

  @media screen and (max-width: 500px) {
    font-size: 15px;
    line-height: 22px;
  }
`

const CountdownWrapper = styled.div`
  width: 100%;
  font-size: 17px;
  font-weight: 300;
  margin-top: 20px;

  a {
    font-weight: 600;
    color: ${({ theme }) => theme.text1};
    text-decoration: underline;
  }

  @media screen and (max-width: 600px) {
    font-size: 16px;
  }

  @media screen and (max-width: 500px) {
    font-size: 15px;
  }
`

const ClaimTimeWrapper = styled.div`
  background-color: ${({ theme }) => theme.bg4};
  font-weight: 500;
  padding: 2px 9px 2px 8px;
  border-radius: 5px;
  display: inline-block;
  white-space: nowrap;
`

const INVESTOR_ADDRESSES = ['0x186B4E576E34974c776aB8048970AefD9C71468e'.toUpperCase()]

export default function Airdrop() {
  const { chainId, account } = useActiveWeb3React()
  const [hasVisitedAirdrop, setHasVisitedAirdrop] = useState(() => {
    return window.localStorage.getItem('dolomite_has_visited_airdrop') === 'true'
  })
  const [hasApprovedToS, setHasApprovedToS] = useState(() => {
    if (account) {
      const storedApprovals = window.localStorage.getItem('dolomite_airdrop_tos_approvals')
      if (storedApprovals) {
        const approvals = JSON.parse(storedApprovals)
        return approvals.some((approval: any) => approval.address.toLowerCase() === account.toLowerCase())
      }
      return false
    }
    return window.localStorage.getItem('dolomite_airdrop_tos_no_wallet') === 'true'
  })
  const isInvestor = account && INVESTOR_ADDRESSES.includes(account.toUpperCase())
  let startDate: Date | undefined = useMemo(() => new Date(Date.UTC(2025, 2, 15, 0, 0, 0)), []) // Feb 1 00:00:00 UTC
  let endDate: Date | undefined = useMemo(
    () => (startDate ? new Date(startDate.getTime() + 6 * 30 * 24 * 60 * 60 * 1000) : undefined),
    [startDate],
  ) // 6 months from startDate
  if (new Date() > new Date(Date.UTC(2024, 1, 1, 0, 0, 0))) {
    startDate = undefined
    endDate = undefined
  }

  useEffect(() => {
    if (account) {
      const storedApprovals = window.localStorage.getItem('dolomite_airdrop_tos_approvals')
      if (storedApprovals) {
        const approvals = JSON.parse(storedApprovals)
        setHasApprovedToS(approvals.some((approval: any) => approval.address.toLowerCase() === account.toLowerCase()))
      } else {
        setHasApprovedToS(false)
      }
    } else {
      setHasApprovedToS(window.localStorage.getItem('dolomite_airdrop_tos_no_wallet') === 'true')
    }
  }, [account])

  const setHasCompletedApprovals = useCallback(() => {
    window.localStorage.setItem('dolomite_has_visited_airdrop', 'true')
    setHasVisitedAirdrop(true)
    setHasApprovedToS(true)
  }, [])

  const countdownRenderer = useCallback(
    ({
      days,
      hours,
      minutes,
      seconds,
      completed,
    }: {
      days: number
      hours: number
      minutes: number
      seconds: number
      completed: boolean
    }) => {
      if (completed) {
        // Render a completed state
        return <div>The claim period has ended, thank you to all those who participated!</div>
      } else {
        // Render a countdown
        if (!startDate) return <></>
        return (
          <div>
            {startDate > new Date() ? 'Claiming will become available in ' : 'Claiming will end in '}
            <ClaimTimeWrapper>
              {zeroPad(days)}d {zeroPad(hours)}h {zeroPad(minutes)}m {zeroPad(seconds)}s
            </ClaimTimeWrapper>
          </div>
        )
      }
    },
    [startDate],
  )

  // TODO - PRIOR TO LAUNCH ITEMS
  // TODO - Add the link to the vesting page (and make a change if you go with a different name. Also maybe handle "Best" on Berachain)
  // TODO - Add DOLO contract address for adding the token to your wallet, and also likely in other places rather than just a fraction
  // TODO - Add date/time when you have it for the countdown, and disable the fields if it's before the time
  // TODO - Update change chain to be for mainnet Berachain

  // TODO - Need a button that people can click that adds the DOLO token to their wallet, -> should also show the contract address <-
  // TODO - Maybe on claim there can be some confetti animation, make sure it shows something congratulatory as well as next steps (go check your veDOLO on the vest page?)
  // TODO - Add the veDOLO token logo
  return (
    <AirdropWrapper>
      <PageTitle title={chainId === ChainId.BERACHAIN ? 'Bolomite | Airdrop' : 'Dolomite | Airdrop'} />
      {(!hasVisitedAirdrop || !hasApprovedToS) && (
        <AirdropToSModal setHasCompletedApprovals={setHasCompletedApprovals} />
      )}
      <AirdropInfoPanel>
        <InfoPanelTitle>Welcome to $DOLO</InfoPanelTitle>
        <InfoPanelSubtitle>Check your eligibility for the Dolomite Retroactive Airdrop</InfoPanelSubtitle>
        <InfoPanelContent>
          With the launch of the Dolomite governance token DOLO, we want to kick things off by putting DOLO in the hands
          of the incredible community of users that have made Dolomite what it is today. Check below to see if you
          qualify.{' '}
          <a
            href={'https://medium.com/dolomite-official/dolomite-airdrop-checker-is-live-648f977e902e'}
            target={'_blank'}
            rel={'noreferrer'}
          >
            Learn more
          </a>
          .
        </InfoPanelContent>
        <CountdownWrapper>
          TGE coming soon! Follow the official{' '}
          <a href={'https://x.com/dolomite_io'} target={'blank'} rel={'noreferrer'}>
            𝕏/Twitter
          </a>{' '}
          and{' '}
          <a href={'https://discord.gg/uDRzrB2YgP'} target={'blank'} rel={'noreferrer'}>
            Discord
          </a>{' '}
          for updates.
        </CountdownWrapper>
        {startDate && (
          <CountdownWrapper>
            <Countdown date={startDate > new Date() ? startDate : endDate} renderer={countdownRenderer} />
          </CountdownWrapper>
        )}
      </AirdropInfoPanel>
      <UsageAirdrop />
      <MineralsAirdrop />
      {isInvestor && <InvestorSection />}
    </AirdropWrapper>
  )
}
