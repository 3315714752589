import { Web3CallbackState } from '../useTradeCallback'
import { estimateGasAsync, SuccessfulContractCall, useActiveWeb3React } from '../index'
import { useDfsGlpContract, useDfsGlpUserVaultContract, useDGmxUserVaultContract } from '../useContract'
import { useTransactionAdder } from '../../state/transactions/hooks'
import { useMemo } from 'react'
import { calculateGasMargin } from '../../utils'
import { USER_ERROR_CODES, ZERO_FRACTION } from '../../constants'
import useIsolationModeUserVaultAddressIfCreated from '../useIsolationModeUserVaultAddressIfCreated'
import { useAllTokens } from '../Tokens'
import { useDfsGLPSpecialAsset, useDGMXSpecialAsset } from '../../constants/isolation/special-assets'
import { ethers } from 'ethers'
import { CurrencyAmount, Token } from '@dolomite-exchange/v2-sdk'

export function useAcceptFullAccountTransfer(
  senderAddress?: string,
): { state: Web3CallbackState; callback: null | (() => Promise<string>); error: string | null } {
  const { account } = useActiveWeb3React()
  const dfsGlp = useDfsGlpToken()
  const vaultAddress = useIsolationModeUserVaultAddressIfCreated(dfsGlp)
  const vaultContract = useDfsGlpUserVaultContract(vaultAddress)
  const factoryContract = useDfsGlpContract()
  const addTransaction = useTransactionAdder()

  return useMemo(() => {
    if (!factoryContract || !dfsGlp || !account || !senderAddress || !ethers.utils.isAddress(senderAddress)) {
      return {
        state: Web3CallbackState.INVALID,
        callback: null,
        error: 'Missing dependencies',
      }
    }

    const contract = vaultContract ? vaultContract : factoryContract
    const methodName = vaultContract ? 'acceptFullAccountTransfer' : 'createVaultAndAcceptFullAccountTransfer'
    const params = [senderAddress]

    return {
      state: Web3CallbackState.VALID,
      callback: async function onAcceptFullAccountTransfer(): Promise<string> {
        const estimatedCall = await estimateGasAsync(contract, methodName, params)
        let successfulCall: SuccessfulContractCall
        if ('gasEstimate' in estimatedCall) {
          successfulCall = estimatedCall
        } else {
          throw estimatedCall.error
        }

        return contract[methodName](...params, {
          gasLimit: calculateGasMargin(successfulCall.gasEstimate),
          from: account,
        })
          .then((response: any) => {
            const summary = 'Perform GMX Full Account Transfer'
            addTransaction(response, { summary })
            return response.hash
          })
          .catch((error: any) => {
            // if the user rejected the tx, pass this along
            if (error?.code === USER_ERROR_CODES.REJECTED) {
              throw new Error('transaction-rejected')
            } else {
              // otherwise, the error was unexpected, and we need to convey that
              console.error('Accept full account transfer failed', error, methodName, params)
              throw new Error(`Accept full account transfer failed: ${error.message}`)
            }
          })
      },
      error: null,
    }
  }, [factoryContract, dfsGlp, account, senderAddress, vaultContract, addTransaction])
}

enum StakeOrUnstakeGmxOrEsGmxMethodName {
  STAKE_GMX = 'stakeGmx',
  STAKE_ES_GMX = 'stakeEsGmx',
  UNSTAKE_GMX = 'unstakeGmx',
  UNSTAKE_ES_GMX = 'unstakeEsGmx',
}

export function useStakeGmx(
  parsedInputAmount: CurrencyAmount<Token> | undefined,
): { state: Web3CallbackState; callback: null | (() => Promise<string>); error: string | null } {
  return useStakeOrUnstakeGmxOrEsGmx(parsedInputAmount, StakeOrUnstakeGmxOrEsGmxMethodName.STAKE_GMX)
}

export function useStakeEsGmx(
  parsedInputAmount: CurrencyAmount<Token> | undefined,
): { state: Web3CallbackState; callback: null | (() => Promise<string>); error: string | null } {
  return useStakeOrUnstakeGmxOrEsGmx(parsedInputAmount, StakeOrUnstakeGmxOrEsGmxMethodName.STAKE_ES_GMX)
}

export function useUnstakeGmx(
  parsedInputAmount: CurrencyAmount<Token> | undefined,
): { state: Web3CallbackState; callback: null | (() => Promise<string>); error: string | null } {
  return useStakeOrUnstakeGmxOrEsGmx(parsedInputAmount, StakeOrUnstakeGmxOrEsGmxMethodName.UNSTAKE_GMX)
}

export function useUnstakeEsGmx(
  parsedInputAmount: CurrencyAmount<Token> | undefined,
): { state: Web3CallbackState; callback: null | (() => Promise<string>); error: string | null } {
  return useStakeOrUnstakeGmxOrEsGmx(parsedInputAmount, StakeOrUnstakeGmxOrEsGmxMethodName.UNSTAKE_ES_GMX)
}

function useStakeOrUnstakeGmxOrEsGmx(
  parsedInputAmount: CurrencyAmount<Token> | undefined,
  methodName: StakeOrUnstakeGmxOrEsGmxMethodName,
): { state: Web3CallbackState; callback: null | (() => Promise<string>); error: string | null } {
  const { account } = useActiveWeb3React()
  const dfsGlp = useDfsGlpToken()
  const glpVaultAddress = useIsolationModeUserVaultAddressIfCreated(dfsGlp)
  const glpVaultContract = useDfsGlpUserVaultContract(glpVaultAddress)
  const dGmx = useDGmxToken()
  const gmxVaultAddress = useIsolationModeUserVaultAddressIfCreated(dGmx)
  const gmxVaultContract = useDGmxUserVaultContract(gmxVaultAddress)
  const vaultContract =
    methodName === StakeOrUnstakeGmxOrEsGmxMethodName.STAKE_GMX ||
    methodName === StakeOrUnstakeGmxOrEsGmxMethodName.UNSTAKE_GMX
      ? gmxVaultContract
      : glpVaultContract
  const addTransaction = useTransactionAdder()

  return useMemo(() => {
    if (!vaultContract || !dfsGlp || !account || !parsedInputAmount || parsedInputAmount.equalTo(ZERO_FRACTION)) {
      return {
        state: Web3CallbackState.INVALID,
        callback: null,
        error: 'Missing dependencies',
      }
    }

    const contract = vaultContract
    const params = [parsedInputAmount.quotient.toString()]

    return {
      state: Web3CallbackState.VALID,
      callback: async function onCallFunctions(): Promise<string> {
        const estimatedCall = await estimateGasAsync(contract, methodName, params)
        let successfulCall: SuccessfulContractCall
        if ('gasEstimate' in estimatedCall) {
          successfulCall = estimatedCall
        } else {
          throw estimatedCall.error
        }

        return contract[methodName](...params, {
          gasLimit: calculateGasMargin(successfulCall.gasEstimate),
          from: account,
        })
          .then((response: any) => {
            let summary = ''
            if (methodName === StakeOrUnstakeGmxOrEsGmxMethodName.STAKE_GMX) {
              summary = 'Stake GMX'
            } else if (methodName === StakeOrUnstakeGmxOrEsGmxMethodName.STAKE_ES_GMX) {
              summary = 'Stake esGMX'
            } else if (methodName === StakeOrUnstakeGmxOrEsGmxMethodName.UNSTAKE_GMX) {
              summary = 'Unstake GMX'
            } else if (methodName === StakeOrUnstakeGmxOrEsGmxMethodName.UNSTAKE_ES_GMX) {
              summary = 'Unstake esGMX'
            }
            addTransaction(response, { summary })
            return response.hash
          })
          .catch((error: any) => {
            // if the user rejected the tx, pass this along
            if (error?.code === USER_ERROR_CODES.REJECTED) {
              throw new Error('transaction-rejected')
            } else {
              // otherwise, the error was unexpected, and we need to convey that
              console.error('Call function failed:', error, methodName, params)
              throw new Error(`Call function failed: ${error.message}`)
            }
          })
      },
      error: null,
    }
  }, [vaultContract, dfsGlp, account, parsedInputAmount, methodName, addTransaction])
}

enum VestGmxOrGlpMethodName {
  VEST_GMX = 'vestGmx',
  VEST_GLP = 'vestGlp',
}

export function useVestEsGmxWithGmx(
  parsedInputAmount: CurrencyAmount<Token> | undefined,
): { state: Web3CallbackState; callback: null | (() => Promise<string>); error: string | null } {
  return useVestEsGmxWithGmxOrGlp(parsedInputAmount, VestGmxOrGlpMethodName.VEST_GMX)
}

export function useVestEsGmxWithGlp(
  parsedInputAmount: CurrencyAmount<Token> | undefined,
): { state: Web3CallbackState; callback: null | (() => Promise<string>); error: string | null } {
  return useVestEsGmxWithGmxOrGlp(parsedInputAmount, VestGmxOrGlpMethodName.VEST_GLP)
}

function useVestEsGmxWithGmxOrGlp(
  parsedInputAmount: CurrencyAmount<Token> | undefined,
  methodName: VestGmxOrGlpMethodName,
): { state: Web3CallbackState; callback: null | (() => Promise<string>); error: string | null } {
  const { account } = useActiveWeb3React()
  const dfsGlp = useDfsGlpToken()
  const dGmx = useDGmxToken()
  const glpVaultAddress = useIsolationModeUserVaultAddressIfCreated(dfsGlp)
  const gmxVaultAddress = useIsolationModeUserVaultAddressIfCreated(dGmx)
  const glpVaultContract = useDfsGlpUserVaultContract(glpVaultAddress)
  const gmxVaultContract = useDfsGlpUserVaultContract(gmxVaultAddress)
  const addTransaction = useTransactionAdder()

  return useMemo(() => {
    if (
      !glpVaultContract ||
      !gmxVaultContract ||
      !dfsGlp ||
      !account ||
      !parsedInputAmount ||
      parsedInputAmount.equalTo(ZERO_FRACTION)
    ) {
      return {
        state: Web3CallbackState.INVALID,
        callback: null,
        error: 'Missing dependencies',
      }
    }

    const contract = methodName === VestGmxOrGlpMethodName.VEST_GLP ? glpVaultContract : gmxVaultContract
    const params = [parsedInputAmount.quotient.toString()]

    return {
      state: Web3CallbackState.VALID,
      callback: async function onCallFunctions(): Promise<string> {
        const estimatedCall = await estimateGasAsync(contract, methodName, params)
        let successfulCall: SuccessfulContractCall
        if ('gasEstimate' in estimatedCall) {
          successfulCall = estimatedCall
        } else {
          throw estimatedCall.error
        }

        return contract[methodName](...params, {
          gasLimit: calculateGasMargin(successfulCall.gasEstimate),
          from: account,
        })
          .then((response: any) => {
            let summary = ''
            if (methodName === VestGmxOrGlpMethodName.VEST_GMX) {
              summary = 'Vest GMX'
            } else if (methodName === VestGmxOrGlpMethodName.VEST_GLP) {
              summary = 'Vest GLP'
            }
            addTransaction(response, { summary })
            return response.hash
          })
          .catch((error: any) => {
            // if the user rejected the tx, pass this along
            if (error?.code === USER_ERROR_CODES.REJECTED) {
              throw new Error('transaction-rejected')
            } else {
              // otherwise, the error was unexpected, and we need to convey that
              console.error('Call function failed:', error, methodName, params)
              throw new Error(`Call function failed: ${error.message}`)
            }
          })
      },
      error: null,
    }
  }, [glpVaultContract, gmxVaultContract, dfsGlp, account, parsedInputAmount, methodName, addTransaction])
}

enum UnvestGmxOrGlpMethodName {
  UNVEST_GMX = 'unvestGmx',
  UNVEST_GLP = 'unvestGlp',
}

export function useUnvestEsGmxWithGmx(): {
  state: Web3CallbackState
  callback: null | (() => Promise<string>)
  error: string | null
} {
  return useUnvestEsGmxWithGmxOrGlp(UnvestGmxOrGlpMethodName.UNVEST_GMX)
}

export function useUnvestEsGmxWithGlp(): {
  state: Web3CallbackState
  callback: null | (() => Promise<string>)
  error: string | null
} {
  return useUnvestEsGmxWithGmxOrGlp(UnvestGmxOrGlpMethodName.UNVEST_GLP)
}

function useUnvestEsGmxWithGmxOrGlp(
  methodName: UnvestGmxOrGlpMethodName,
): { state: Web3CallbackState; callback: null | (() => Promise<string>); error: string | null } {
  const { account } = useActiveWeb3React()
  const dfsGlp = useDfsGlpToken()
  const dGmx = useDGmxToken()
  const dfsGlpVaultAddress = useIsolationModeUserVaultAddressIfCreated(dfsGlp)
  const dGmxVaultAddress = useIsolationModeUserVaultAddressIfCreated(dGmx)
  const dfsGlpVaultContract = useDfsGlpUserVaultContract(dfsGlpVaultAddress)
  const dGmxVaultContract = useDGmxUserVaultContract(dGmxVaultAddress)
  const addTransaction = useTransactionAdder()

  return useMemo(() => {
    if (!dfsGlpVaultContract || !dGmxVaultContract || !account) {
      return {
        state: Web3CallbackState.INVALID,
        callback: null,
        error: 'Missing dependencies',
      }
    }

    const contract = methodName === UnvestGmxOrGlpMethodName.UNVEST_GLP ? dfsGlpVaultContract : dGmxVaultContract
    const params: any[] = [false]

    return {
      state: Web3CallbackState.VALID,
      callback: async function onCallFunctions(): Promise<string> {
        const estimatedCall = await estimateGasAsync(contract, methodName, params)
        let successfulCall: SuccessfulContractCall
        if ('gasEstimate' in estimatedCall) {
          successfulCall = estimatedCall
        } else {
          throw estimatedCall.error
        }
        return contract[methodName](...params, {
          gasLimit: calculateGasMargin(successfulCall.gasEstimate),
          from: account,
        })
          .then((response: any) => {
            let summary = ''
            if (methodName === UnvestGmxOrGlpMethodName.UNVEST_GMX) {
              summary = 'Unvest GMX'
            } else if (methodName === UnvestGmxOrGlpMethodName.UNVEST_GLP) {
              summary = 'Unvest GLP'
            }
            addTransaction(response, { summary })
            return response.hash
          })
          .catch((error: any) => {
            // if the user rejected the tx, pass this along
            if (error?.code === USER_ERROR_CODES.REJECTED) {
              throw new Error('transaction-rejected')
            } else {
              // otherwise, the error was unexpected, and we need to convey that
              console.error('Call function failed:', error, methodName, params)
              throw new Error(`Call function failed: ${error.message}`)
            }
          })
      },
      error: null,
    }
  }, [dfsGlpVaultContract, dGmxVaultContract, account, methodName, addTransaction])
}

export function useClaimCompoundRewards(
  claimGMXRewards: boolean,
  stakeGMXRewards: boolean,
  claimESGMXRewards: boolean,
  stakeESGMXRewards: boolean,
  stakeMultiplierPoints: boolean,
  claimWETHRewards: boolean,
  convertWETHToETH: boolean,
): { state: Web3CallbackState; callback: null | (() => Promise<string>); error: string | null } {
  const { library, account } = useActiveWeb3React()
  const dfsGlp = useDfsGlpToken()
  const vaultAddress = useIsolationModeUserVaultAddressIfCreated(dfsGlp)
  const vaultContract = useDfsGlpUserVaultContract(vaultAddress)

  const addTransaction = useTransactionAdder()

  return useMemo(() => {
    if (!vaultContract || !library || !account) {
      return {
        state: Web3CallbackState.INVALID,
        callback: null,
        error: 'Missing dependencies',
      }
    }

    const methodName = 'handleRewards'
    const params = [
      claimGMXRewards || stakeGMXRewards,
      stakeGMXRewards,
      claimESGMXRewards || stakeESGMXRewards,
      stakeESGMXRewards,
      stakeMultiplierPoints,
      claimWETHRewards || convertWETHToETH,
      convertWETHToETH,
    ]

    return {
      state: Web3CallbackState.VALID,
      callback: async function onHandleRewards(): Promise<string> {
        const estimatedCall = await estimateGasAsync(vaultContract, methodName, params)
        let successfulCall: SuccessfulContractCall
        if ('gasEstimate' in estimatedCall) {
          successfulCall = estimatedCall
        } else {
          throw estimatedCall.error
        }

        return vaultContract[methodName](...params, {
          gasLimit: calculateGasMargin(successfulCall.gasEstimate),
          from: account,
        })
          .then((response: any) => {
            let summary: string
            if (!stakeESGMXRewards && !stakeMultiplierPoints) {
              summary = 'Claim GMX Rewards'
            } else {
              summary = 'Compound GMX Rewards'
            }
            addTransaction(response, { summary })
            return response.hash
          })
          .catch((error: any) => {
            // if the user rejected the tx, pass this along
            if (error?.code === USER_ERROR_CODES.REJECTED) {
              throw new Error('transaction-rejected')
            } else {
              // otherwise, the error was unexpected, and we need to convey that
              console.error('Compound GMX rewards failed', error, error.message, methodName, params)
              throw new Error(`Compound GMX rewards failed: ${error.message}`)
            }
          })
      },
      error: null,
    }
  }, [
    vaultContract,
    library,
    account,
    claimGMXRewards,
    stakeGMXRewards,
    claimESGMXRewards,
    stakeESGMXRewards,
    stakeMultiplierPoints,
    claimWETHRewards,
    convertWETHToETH,
    addTransaction,
  ])
}

export function useDfsGlpToken(): Token | undefined {
  const { chainId } = useActiveWeb3React()
  const tokenMap = useAllTokens()
  const dfsGlpSpecialAsset = useDfsGLPSpecialAsset()
  return useMemo(() => tokenMap[dfsGlpSpecialAsset.chainIdToAddressMap[chainId] ?? ''], [
    chainId,
    dfsGlpSpecialAsset,
    tokenMap,
  ])
}

export function useDGmxToken(): Token | undefined {
  const { chainId } = useActiveWeb3React()
  const tokenMap = useAllTokens()
  const dGmxToken = useDGMXSpecialAsset()
  return useMemo(() => tokenMap[dGmxToken.chainIdToAddressMap[chainId] ?? ''], [chainId, dGmxToken, tokenMap])
}
