import React, { Dispatch, ReactNode, SetStateAction, useState, useEffect } from 'react'
import { Area, AreaChart, ResponsiveContainer, Tooltip, XAxis, ReferenceLine } from 'recharts'
import { DEFAULT_HEIGHT, DEFAULT_HEIGHT_BALANCES, RowBetween, Wrapper } from './styled'
import dayjs from 'dayjs'
import utc from 'dayjs/plugin/utc'
import useTheme from '../../../hooks/useTheme'
import { darken } from 'polished'

dayjs.extend(utc)

export const loadingData = [...Array(8)].map((val, i) => {
  const value = 8 + i * 2 - (i % 2 === 0 && i !== 0 ? (i - 1) * 2 + 1 : 0)
  return {
    time: i,
    value: value,
  }
})

export interface ChartData {
  time: string
  value: number
}

export type LineChartProps = {
  data: any[]
  loading: boolean
  color?: string | undefined
  height?: number | undefined
  minHeight?: number
  setValue?: Dispatch<number | undefined> // used for value on hover
  setLabel?: Dispatch<string | undefined> // used for label of value
  defaultValue?: number
  value?: number
  defaultLabel?: string
  label?: string
  disableAxis?: boolean
  customAxis?: string | undefined
  reference?: number | undefined
  topLeft?: ReactNode | undefined
  topCenter?: ReactNode | undefined
  topRight?: ReactNode | undefined
  bottomLeft?: ReactNode | undefined
  bottomRight?: ReactNode | undefined
  balances?: boolean | undefined
} & React.HTMLAttributes<HTMLDivElement>

const Chart = ({
  data,
  loading,
  color = '#56B2A4',
  defaultValue,
  value,
  defaultLabel,
  label,
  setValue,
  setLabel,
  disableAxis,
  customAxis,
  reference,
  topLeft,
  topCenter,
  topRight,
  bottomLeft,
  bottomRight,
  balances,
  minHeight = DEFAULT_HEIGHT,
  ...rest
}: LineChartProps) => {
  const theme = useTheme()
  const [chartData, setChartData] = useState<ChartData[] | []>([])
  const parsedValue = value
  color = chartData.length ? color : theme.text3

  useEffect(() => {
    data && setChartData(data)
  }, [chartData, data])

  return (
    <Wrapper minHeight={balances ? DEFAULT_HEIGHT_BALANCES : minHeight} {...rest} balances={balances}>
      <RowBetween style={{ alignItems: 'flex-start' }}>
        {topLeft ?? null}
        {topCenter ?? null}
        {topRight ?? null}
      </RowBetween>
      <ResponsiveContainer width='100%' height='100%'>
        <AreaChart
          width={500}
          height={300}
          data={chartData.length ? chartData : loadingData}
          margin={{
            top: 0,
            right: 15,
            left: 15,
            bottom: 0,
          }}
          onMouseMove={(chart: any) => {
            if (!chart.activePayload || chart.activePayload.length === 0) {
              return
            }

            const payload = chart.activePayload[0].payload

            if (setValue && parsedValue !== payload.value && chartData.length) {
              setValue(payload.value)
            }
            if (setLabel && customAxis) {
              setLabel(payload.time + customAxis)
            }
            const formattedTime = dayjs(payload.time).format('MMM D, YYYY')
            if (setLabel && label !== formattedTime && chartData.length && !customAxis) {
              setLabel(formattedTime)
            }
          }}
          onMouseLeave={() => {
            setLabel && chartData.length && setLabel(defaultLabel)
            setValue && chartData.length && setValue(defaultValue)
          }}
        >
          <defs>
            <linearGradient id='gradient' x1='0' y1='0' x2='0' y2='1'>
              <stop offset='5%' stopColor={darken(0.36, color)} stopOpacity={0.5} />
              <stop offset='100%' stopColor={color} stopOpacity={0} />
            </linearGradient>
          </defs>
          <XAxis
            dataKey='time'
            tick={{ fontSize: 12, fill: theme.text3 }}
            axisLine={false}
            tickLine={false}
            interval={customAxis === '%' ? 19 : undefined}
            tickFormatter={time => {
              if (customAxis === '%') {
                return time
              }
              const day = dayjs(time)
              if (day.isValid() && time !== 0 && chartData.length) {
                return day.format('M/DD')
              } else {
                return ''
              }
            }}
            minTickGap={10}
          />
          {reference ? (
            <ReferenceLine
              x={Math.round(reference)}
              stroke={theme.bg2}
              label={{ value: reference + '%', fill: theme.text1, fontSize: 12 }}
            />
          ) : null}
          <Tooltip cursor={{ stroke: theme.bg2 }} contentStyle={{ display: 'none' }} />
          <Area dataKey='value' type='monotone' stroke={color} fill='url(#gradient)' strokeWidth={2} />
        </AreaChart>
      </ResponsiveContainer>
      <RowBetween>
        {bottomLeft ?? null}
        {bottomRight ?? null}
      </RowBetween>
    </Wrapper>
  )
}

export default Chart
