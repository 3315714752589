import React, { useCallback, useEffect, useMemo, useState } from 'react'
import styled from 'styled-components/macro'
import { Fraction } from '@dolomite-exchange/sdk-core'
import ArrowForward from '@mui/icons-material/ArrowForward'
import { formatAmount } from '../../utils/formatAmount'
import { StyledTooltipWithIcon } from '../../components/common/StyledTooltip'

const ClaimWrapper = styled.div`
  width: 100%;
  max-width: 700px;
  background: ${({ theme }) => theme.bg1};
  border-radius: 8px;
  padding: 28px 38px;
  font-size: 15px;
  margin: 0 auto;
  box-shadow: 0 5px 5px -3px rgb(0 0 0 / 20%), 0 8px 10px 1px rgb(0 0 0 / 14%), 0 3px 14px 2px rgb(0 0 0 / 12%);

  @media screen and (max-width: 900px) {
    max-width: 600px;
    width: calc(100% - 40px);
    margin: 10px auto 0;
  }

  @media screen and (max-width: 800px) {
    font-size: 14px;
  }

  @media screen and (max-width: 550px) {
    font-size: 13px;
  }

  @media screen and (max-width: 500px) {
    font-size: 12px;
    width: calc(100% - 20px);
  }

  @media screen and (max-width: 450px) {
    font-size: 11px;
  }

  @media screen and (max-width: 400px) {
    font-size: 10px;
  }

  @media screen and (max-width: 350px) {
    font-size: 9px;
  }

  @media screen and (max-width: 300px) {
    font-size: 8px;
  }

  @media screen and (max-width: 250px) {
    font-size: 7px;
  }
`

const Left = styled.div`
  display: inline-block;
  vertical-align: top;
  width: 50%;

  @media screen and (max-width: 900px) {
    width: 100%;
    text-align: center;
  }
`

const Right = styled.div`
  display: inline-block;
  vertical-align: top;
  width: 50%;
  text-align: right;
  margin-top: -5px;

  @media screen and (max-width: 900px) {
    width: 100%;
    text-align: center;
    margin-top: 35px;
  }
`

const Title = styled.div`
  font-size: 25px;
  font-weight: 300;
  color: ${({ theme }) => theme.text1};

  @media screen and (max-width: 900px) {
    font-size: 260%;
  }

  @media screen and (max-width: 500px) {
    font-size: 220%;
  }
`

const Subtitle = styled.div`
  font-size: 14px;
  color: ${({ theme }) => theme.text3};
  font-weight: 200;
  margin-top: -2px;

  @media screen and (max-width: 900px) {
    font-size: 110%;
  }
`

const AllocationTitle = styled.div`
  font-size: 17px;
  font-weight: 300;
  margin-top: 20px;

  @media screen and (max-width: 900px) {
    font-size: 140%;
  }
`

const TotalAllocation = styled.div`
  font-size: 38px;
  font-weight: 300;
  color: ${({ theme }) => theme.text1};
  margin-top: -9px;
  margin-bottom: 15px;

  @media screen and (max-width: 900px) {
    font-size: 320%;
  }

  @media screen and (max-width: 500px) {
    font-size: 280%;
  }
`

const Ticker = styled.span`
  font-weight: 100;
  color: ${({ theme }) => theme.text2};
  font-size: 0.7em;
  margin-left: -3px;
`

const VestingTimelineWrapper = styled.div`
  width: fit-content;
  text-align: center;
  margin-top: -10px;

  @media screen and (max-width: 900px) {
    margin: -10px auto 0;
  }
`

const StyleLine = styled.div`
  width: 100%;
  height: 1px;
  background-color: ${({ theme }) => theme.text3};
  display: inline-block;
  vertical-align: bottom;
  margin-bottom: -10px;
  min-width: 30px;

  @media screen and (max-width: 900px) {
    margin-bottom: -4.6%;
  }
`

const VestingTimeTitle = styled.div`
  font-size: 14px;
  font-weight: 500;
  color: ${({ theme }) => theme.text1};
  width: fit-content;
  display: inline-block;
  vertical-align: top;
  padding: 0 10px;
  background: ${({ theme }) => theme.bg1};

  @media screen and (max-width: 900px) {
    font-size: 120%;
  }
`

const TimelineInner = styled.div`
  width: fit-content;
  margin: 0 auto;
`

const VestingLength = styled.span`
  font-size: 14px;
  font-weight: 300;
  color: ${({ theme }) => theme.text2};

  @media screen and (max-width: 900px) {
    font-size: 100%;
  }
`

const VestingTimeline = styled.div`
  width: fit-content;
  text-align: left;
  font-size: 13px;
  font-weight: 600;
  margin-top: 3px;

  svg {
    font-size: 14px;
    color: ${({ theme }) => theme.text2};
    margin: 0 6px;
    transform: translateY(2px);
  }

  @media screen and (max-width: 900px) {
    font-size: 100%;
    font-weight: 500;
  }
`

const CliffMarker = styled.div`
  width: 100%;
  text-align: center;
  font-size: 11px;
  font-weight: 600;
  color: ${({ theme }) => theme.text3};
  display: flex;
  justify-content: space-between;
  margin-bottom: -5px;
`

const AmountWrapper = styled.div`
  width: 100%;
  display: inline-block;
  vertical-align: top;
  margin-bottom: 8px;

  @media screen and (max-width: 900px) {
    margin-bottom: 15px;
  }

  @media screen and (max-width: 500px) {
    margin-bottom: 12px;
  }
`

const AmountTitle = styled.div`
  font-size: 12px;
  font-weight: 500;
  color: ${({ theme }) => theme.text2};

  svg {
    transform: translate(-3px, 1px);
  }

  @media screen and (max-width: 900px) {
    font-size: 110%;
  }
`

const AmountValue = styled.div<{ large?: boolean }>`
  font-size: ${({ large }) => (large ? 32 : 26)}px;
  font-weight: ${({ large }) => (large ? 500 : 300)};
  color: ${({ theme }) => theme.text1};
  margin-top: -3px;

  @media screen and (max-width: 900px) {
    font-size: 230%;
  }
`

const CliffWarning = styled.span`
  font-size: 10px;
  font-weight: 600;
  background-color: ${({ theme }) => theme.blue2};
  color: ${({ theme }) => theme.text1};
  border-radius: 5px;
  padding: 2px 5px 2px;
  display: inline-block;
  margin-left: 1px;
`

const ClaimButton = styled.div<{ isClaimable: boolean }>`
  border-radius: 5px;
  background-color: ${({ theme, isClaimable }) => (isClaimable ? theme.green2 : theme.bg4)};
  color: ${({ theme }) => theme.text1};
  font-size: 14px;
  font-weight: 600;
  padding: 8px 32px;
  cursor: ${({ isClaimable }) => (isClaimable ? 'pointer' : 'default')};
  width: fit-content;
  text-align: center;
  line-height: 22px;
  margin-top: 0;
  height: 38px;
  display: inline-block;
  vertical-align: top;

  :hover {
    background-color: ${({ theme, isClaimable }) => (isClaimable ? theme.green1 : theme.bg4)};
  }

  > svg,
  > div {
    color: ${({ theme }) => theme.text1} !important;
    font-size: 20px;
    height: 20px !important;
    width: 20px !important;
  }

  @media screen and (max-width: 900px) {
    width: 100%;
  }
`

export default function InvestorClaim({
  startDate,
  cliffDate,
  endDate,
  totalAllocation,
  totalClaimed,
  roundInfo,
}: {
  startDate: Date
  cliffDate?: Date
  endDate: Date
  totalAllocation: Fraction
  totalClaimed: Fraction
  roundInfo: string
}) {
  const vestingLength = endDate.getTime() - startDate.getTime()
  const vestingLengthString = useMemo(() => {
    const msInMonth = 1000 * 60 * 60 * 24 * 30
    const msInYear = msInMonth * 12
    const years = Math.floor(vestingLength / msInYear)
    const months = Math.round((vestingLength % msInYear) / msInMonth)
    return years > 0
      ? `${years} year${years > 1 ? 's' : ''}${months > 0 ? ` ${months} month${months > 1 ? 's' : ''}` : ''}`
      : `${months} month${months > 1 ? 's' : ''}`
  }, [vestingLength])
  const startDateString = useMemo(
    () => startDate.toLocaleDateString(undefined, { month: 'short', day: 'numeric', year: 'numeric' }),
    [startDate],
  )
  const cliffDateString = useMemo(
    () => cliffDate?.toLocaleDateString(undefined, { month: 'short', day: 'numeric', year: 'numeric' }),
    [cliffDate],
  )
  const endDateString = useMemo(
    () => endDate.toLocaleDateString(undefined, { month: 'short', day: 'numeric', year: 'numeric' }),
    [endDate],
  )
  const cliffWarningString = useMemo(() => {
    if (!cliffDate) return undefined
    const now = new Date()
    return now < cliffDate ? cliffDate.toLocaleDateString(undefined, { month: 'short', day: 'numeric' }) : undefined
  }, [cliffDate])
  const totalVested = useMemo(() => {
    const now = new Date()
    if (cliffDate && now < cliffDate) return new Fraction(0, 1)
    const elapsed = Math.min(now.getTime() - startDate.getTime(), vestingLength)
    return totalAllocation.multiply(new Fraction(elapsed, vestingLength))
  }, [startDate, cliffDate, totalAllocation, vestingLength])
  const availableToClaim = useMemo(() => totalVested.subtract(totalClaimed), [totalVested, totalClaimed])
  const isClaimable = availableToClaim.greaterThan(0)

  return (
    <ClaimWrapper>
      <Left>
        <Title>Vesting DOLO</Title>
        <Subtitle>{roundInfo}</Subtitle>
        <AllocationTitle>Total Allocation</AllocationTitle>
        <TotalAllocation>
          {formatAmount(totalAllocation, undefined, true, '-')} <Ticker>DOLO</Ticker>
        </TotalAllocation>
        <VestingTimelineWrapper>
          <StyleLine />
          <VestingTimeTitle>
            Vesting Period <VestingLength>({vestingLengthString})</VestingLength>
          </VestingTimeTitle>
          <TimelineInner>
            <VestingTimeline>
              {startDateString}
              <ArrowForward />
              {cliffDateString}
              {cliffDate && <ArrowForward />}
              {endDateString}
            </VestingTimeline>
            <CliffMarker>
              <div>START</div>
              {!!cliffDate && <div>CLIFF</div>}
              <div>END</div>
            </CliffMarker>
          </TimelineInner>
        </VestingTimelineWrapper>
      </Left>
      <Right>
        <AmountWrapper>
          <AmountTitle>
            <StyledTooltipWithIcon
              tooltipText={
                'The amount of DOLO that has vested out of your total allocation, based on the vesting period of your DOLO, any vesting cliff, and the amount of time that has passed'
              }
            />
            DOLO Vested {cliffWarningString && <CliffWarning>CLIFF ENDS {cliffWarningString}</CliffWarning>}
          </AmountTitle>
          <AmountValue>{formatAmount(totalVested, undefined, true, '-')}</AmountValue>
        </AmountWrapper>
        <AmountWrapper>
          <AmountTitle>
            <StyledTooltipWithIcon tooltipText={'The amount of DOLO that has been claimed so far from vested DOLO'} />
            DOLO Claimed
          </AmountTitle>
          <AmountValue>{formatAmount(totalClaimed, undefined, true, '-')}</AmountValue>
        </AmountWrapper>
        <AmountWrapper>
          <AmountTitle>
            <StyledTooltipWithIcon tooltipText={'The amount of DOLO that has vested and is available to be claimed'} />
            DOLO Available to Claim
          </AmountTitle>
          <AmountValue large>{formatAmount(availableToClaim, undefined, true, '-')}</AmountValue>
        </AmountWrapper>
        <ClaimButton isClaimable={isClaimable}>
          {isClaimable ? 'Claim Available DOLO' : 'No Claimable DOLO'}
        </ClaimButton>
      </Right>
    </ClaimWrapper>
  )
}
