import { Token } from '@dolomite-exchange/sdk-core'
import { ChainId, ChainIdMap, initializeSingleValueChainIdMap } from '../chainId'

const decimals = 6
const symbol = 'USDC'
const name = 'USD Coin'

function getUsdc(network: ChainId, address: string): Token {
  return new Token(network, address, decimals, symbol, name)
}

export const USDC: Readonly<ChainIdMap<Token>> = {
  [ChainId.MAINNET]: getUsdc(ChainId.MAINNET, '0xA0b86991c6218b36c1d19D4a2e9Eb0cE3606eB48'),
  [ChainId.ARBITRUM_ONE]: getUsdc(ChainId.ARBITRUM_ONE, '0xaf88d065e77c8cC2239327C5EDb3A432268e5831'),
  [ChainId.BASE]: getUsdc(ChainId.BASE, '0x833589fCD6eDb6E08f4c7C32D4f71b54bdA02913'),
  [ChainId.BERACHAIN]: getUsdc(ChainId.BERACHAIN, '0x549943e04f40284185054145c6E4e9568C1D3241'),
  [ChainId.MANTLE]: getUsdc(ChainId.MANTLE, '0x09Bc4E0D864854c6aFB6eB9A9cdF58aC190D0dF9'),
  [ChainId.POLYGON_ZKEVM]: getUsdc(ChainId.POLYGON_ZKEVM, '0xA8CE8aee21bC2A48a5EF670afCc9274C7bbbC035'),
  [ChainId.X_LAYER]: getUsdc(ChainId.X_LAYER, '0x74b7F16337b8972027F6196A17a631aC6dE26d22'),
}

export const BRIDGED_USDC: Readonly<ChainIdMap<Token | undefined>> = initializeSingleValueChainIdMap(
  ChainId.ARBITRUM_ONE,
  new Token(ChainId.ARBITRUM_ONE, '0xFF970A61A04b1cA14834A43f5dE4533eBDDB5CC8', decimals, 'USDC.e', 'Bridged USDC'),
)
