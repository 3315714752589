import { gql } from '@apollo/client'
import {
  createFractionUSD,
  createFractionUSDOpt,
  DolomiteHourData as DolomiteHourDataGql,
  DolomiteMargin as DolomiteMarginGql,
} from './gqlTypeHelpers'
import { dolomiteHourDataGql } from './queryObjects'
import { Fraction } from '@dolomite-exchange/sdk-core'
import { useEffect, useMemo } from 'react'
import { useGraphqlResult } from '../state/graphql/hooks'
import { GraphqlClientType } from '../state/graphql/actions'
import { RefreshFrequency } from '../state/chain/hooks'
import { ZERO_FRACTION } from '../constants'
import { EthereumBlock, useHourlyBlockNumbersByTimestamps } from './useTimestampToBlockNumberData'

const DOLOMITE_HOUR_DATA_DATA = gql`
    query dolomiteHourDataOverInterval($blockNumber: Int!, $endTimestamp: Int!, $startTimestamp: Int!) {
        dolomiteHourDatas(
            orderBy: hourStartUnix,
            orderDirection: desc,
            block: { number_gte: $blockNumber },
            where: {
                hourStartUnix_lte: $endTimestamp,
                hourStartUnix_gt: $startTimestamp
            }
        ) {
            ${dolomiteHourDataGql()}
        }
    }
`

interface DolomiteDayDataResponse {
  dolomiteHourDatas: DolomiteHourDataGql[] | undefined
}

export interface DolomiteHourData {
  id: string
  timestamp: number
  tradeVolumeUSD: Fraction
  ammLiquidityUSD: Fraction
  supplyLiquidityUSD: Fraction
  borrowLiquidityUSD: Fraction
}

export function useCurrentDayDolomiteHourData(): {
  loading: boolean
  error: boolean
  data: DolomiteHourData[] | undefined
} {
  const { data: blocks } = useHourlyBlockNumbersByTimestamps(false)
  const serializedBlocks = JSON.stringify(blocks)
  const query = useMemo(() => {
    const unserializedBlocks = JSON.parse(serializedBlocks) as EthereumBlock[]
    return `
    query getHistoricalDolomiteData(
      ${unserializedBlocks.map(block => `$block_${block.blockNumber}: Int!,`).join('\n')}
    ) {
    ${unserializedBlocks.map(block => {
      return `dolomiteMargin_${block.blockNumber}:dolomiteMargins(block: { number: $block_${block.blockNumber}}) {
        totalTradeVolumeUSD
        borrowLiquidityUSD
        supplyLiquidityUSD
    }
      ammFactory_${block.blockNumber}:ammFactories(block: { number: $block_${block.blockNumber}}) {
        ammLiquidityUSD
      }`
    })}
  }
  `
  }, [serializedBlocks])

  const variables = useMemo(() => {
    const unserializedBlocks = JSON.parse(serializedBlocks) as EthereumBlock[]
    if (unserializedBlocks.every(block => block.blockNumber === 0)) {
      return undefined
    }
    return {
      ...unserializedBlocks.reduce<Record<string, number>>((memo, block) => {
        memo[`block_${block.blockNumber}`] = block.blockNumber
        return memo
      }, {}),
    }
  }, [serializedBlocks])
  const queryState = useGraphqlResult<Record<string, DolomiteMarginGql>>(
    GraphqlClientType.Dolomite,
    query,
    variables,
    RefreshFrequency.Slow,
  )

  return useMemo(() => {
    const unserializedBlocks = JSON.parse(serializedBlocks) as EthereumBlock[]
    const { loading, error, result } = queryState
    const anyLoading = Boolean(loading)
    const anyError = Boolean(error)

    const datas = unserializedBlocks
      .map<DolomiteHourData | undefined>((block, i) => {
        const dolomiteGql = (result?.[`dolomiteMargin_${block.blockNumber}`] as any)?.[0]
        const ammFactoryGql = (result?.[`ammFactory_${block.blockNumber}`] as any)?.[0]
        const previousDolomiteGql = (result?.[`dolomiteMargin_${unserializedBlocks[i - 1]?.blockNumber}`] as any)?.[0]
        if (!dolomiteGql || !previousDolomiteGql) {
          return undefined
        }
        return {
          id: `${block.timestamp}`,
          timestamp: block.timestamp,
          tradeVolumeUSD: createFractionUSD(dolomiteGql.totalTradeVolumeUSD).subtract(
            createFractionUSD(previousDolomiteGql.totalTradeVolumeUSD),
          ),
          ammLiquidityUSD: createFractionUSDOpt((ammFactoryGql as any)?.ammLiquidityUSD) ?? ZERO_FRACTION,
          supplyLiquidityUSD: createFractionUSD(dolomiteGql.supplyLiquidityUSD),
          borrowLiquidityUSD: createFractionUSD(dolomiteGql.borrowLiquidityUSD),
        }
      })
      .filter(value => value !== undefined) as DolomiteHourData[]

    return {
      loading: anyLoading,
      error: anyError,
      data: datas,
    }
  }, [queryState, serializedBlocks])
}
