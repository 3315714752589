import { CurrencyAmount, Fraction } from '@dolomite-exchange/sdk-core'
import { ChainId } from '../constants'
import JSBI from 'jsbi'
import { address } from '@dolomite-exchange/dolomite-margin/dist/src/types'
import { Token } from '@dolomite-exchange/v2-sdk'
import { useStoredMarketRiskData } from '../state/data/hooks'
import { useActiveWeb3React } from '../hooks'
import { RiskOverrideStruct } from '../utils/emode'

// interface MarketRiskInfoResponse {
//   marketRiskInfos: MarketRiskInfoGql[]
// }

export interface MarketRiskInfo {
  token: Token
  marketId: JSBI
  tokenAddress: string
  /**
   * True if the asset can only be used as collateral in a position (collateral only).
   */
  isBorrowingDisabled: boolean
  /**
   * True if the asset cannot be used as collateral in a position (borrow only)
   */
  isBorrowOnly: boolean
  marginPremium: Fraction
  liquidationRewardPremium: Fraction
  oracleAddress: string
  supplyMaxWei: CurrencyAmount<Token> | undefined
  borrowMaxWei: CurrencyAmount<Token> | undefined
  oracleSummary: string | undefined | null
  oracleDescription: string[] | undefined | null
  riskCategories: RiskOverrideStruct[] | undefined
}

// const toFraction = (field: string): Fraction => {
//   return new Fraction(parseUnits(field, 18).toString(), ONE_ETH_IN_WEI)
// }

// export function useLoadMarketRiskInfoData(): ChainIdMap<
//   | {
//       data: Record<string, MarketRiskInfo | undefined>
//       loading: boolean
//       error: boolean
//     }
//   | undefined
// > {
//   const calls = useMemo(() => {
//     return ACTIVE_CHAIN_IDS.map<GraphqlCall>(chainId => {
//       return {
//         chainId: chainId,
//         clientType: GraphqlClientType.Dolomite,
//         query: `${DOLOMITE_API_SERVER_URL}/tokens/${chainId}`,
//         variables: '{}',
//       }
//     })
//   }, [])
//   const marketRiskInfoStates = useGraphqlResultList<MarketRiskInfoResponse>(calls, RefreshFrequency.Slow)
//
//   const tokenMap = useAllTokensAllChains()
//
//   return useMemo(() => {
//     let anyError = false
//     let anyLoading = false
//     return ACTIVE_CHAIN_IDS.reduce(
//       (memo, chainId, i) => {
//         const state = marketRiskInfoStates?.[i]
//         if (!state) {
//           memo[chainId] = {
//             data: {},
//             loading: false,
//             error: true,
//           }
//           return memo
//         }
//
//         anyError = anyError || state.error
//         anyLoading = anyLoading || state.loading
//
//         if (state.error) {
//           memo[chainId] = {
//             data: {},
//             loading: false,
//             error: true,
//           }
//           return memo
//         }
//         if (!state.result && state.loading) {
//           memo[chainId] = {
//             data: {},
//             loading: true,
//             error: false,
//           }
//           return memo
//         }
//         if (state.result) {
//           memo[chainId] = {
//             data: (state.result?.marketRiskInfos ?? []).reduce<Record<string, MarketRiskInfo>>((innerMemo, gql) => {
//               const tokenAddress = toChecksumAddress(gql.token.id)
//               const token = tokenMap[chainId][tokenAddress]
//               if (token) {
//                 innerMemo[tokenAddress] = {
//                   marketId: JSBI.BigInt(gql.token.marketId),
//                   tokenAddress: tokenAddress,
//                   isBorrowingDisabled: gql.isBorrowingDisabled,
//                   marginPremium: toFraction(gql.marginPremium),
//                   liquidationRewardPremium: toFraction(gql.liquidationRewardPremium),
//                   oracleAddress: toChecksumAddress(gql.oracle),
//                   supplyMaxWei: tryParseAmount(gql.supplyMaxWei, token),
//                   borrowMaxWei: tryParseAmount(gql.borrowMaxWei, token),
//                 }
//               }
//               return innerMemo
//             }, {}),
//             loading: state.loading,
//             error: state.error,
//           }
//         }
//         return memo
//       },
//       initializeObjectUndefinedChainIdMap() as ChainIdMap<
//         | {
//             data: Record<string, MarketRiskInfo | undefined>
//             loading: boolean
//             error: boolean
//           }
//         | undefined
//       >,
//     )
//   }, [marketRiskInfoStates, tokenMap])
// }

export function useMarketRiskInfoData(
  suppliedChainId?: ChainId,
): {
  loading: boolean
  error: boolean
  data: Record<address, MarketRiskInfo | undefined>
} {
  const { chainId } = useActiveWeb3React()
  return (
    useStoredMarketRiskData()[suppliedChainId ?? chainId] ?? {
      loading: true,
      error: false,
      data: {},
    }
  )
}
