import { useSelector } from 'react-redux'
import { AppState } from '../index'
import { ChainId } from '../../constants'
import { Fraction } from '@dolomite-exchange/sdk-core'
import { InterestRateDataResponse, MarketRiskDataResponse } from './reducer'
import { TokenBalancesWithLoadingIndicator } from '../wallet/hooks'
import { ChainIdMap } from '../../constants/chainId'
import { Token } from '@dolomite-exchange/v2-sdk'
import { TokenTvl } from '../../types/dolomiteMarginTokenTvlData'

/*export function useChainId(): ChainId {
 return useSelector((state: AppState) => state.chain.chainId)
 }

 export function useIsSettingChainId(): boolean {
 return useSelector((state: AppState) => state.chain.isSettingChainId)
 }

 export function useBlockNumberForSubgraph(): number {
 const { chainId } = useActiveWeb3React()
 return useSelector<AppState, number>(state => state.chain.subgraphBlockNumberMap[chainId])
 }*/

export function useStoredFiatPricesWithLoadingIndicatorData(
  chainId: ChainId,
):
  | {
      fiatPriceMap: Record<string, Fraction | undefined>
      isLoading: boolean
    }
  | undefined {
  return useSelector((state: AppState) => state.data.fiatPricesWithLoadingIndicator[chainId])
}

export function useStoredMarketRiskData(): MarketRiskDataResponse {
  return useSelector((state: AppState) => state.data.marketRiskData)
}

export function useStoredInterestRateData(chainId: ChainId): InterestRateDataResponse {
  return (
    useSelector((state: AppState) => state.data.interestRateData[chainId]) ?? {
      loading: true,
      error: false,
      data: {},
    }
  )
}

export function useStoredDolomiteBalancesData(): TokenBalancesWithLoadingIndicator {
  return useSelector((state: AppState) => state.data.dolomiteBalancesWithLoadingIndicator)
}

export function useStoredAllTokenData(): ChainIdMap<Record<string, Token | undefined>> {
  return useSelector((state: AppState) => state.data.allTokenData)
}

export function useStoredTokenTvlData(): ChainIdMap<Record<string, TokenTvl | undefined>> {
  return useSelector((state: AppState) => state.data.tokenTvlData)
}

/*export function useBlockTimestamp(): BigNumber {
 const { chainId } = useActiveWeb3React()

 const blockTimestamp = useSelector((state: AppState) => state.chain.blockTimestampMap[chainId])

 return useMemo(() => {
 return blockTimestamp ? BigNumber.from(blockTimestamp) : BigNumber.from('0')
 }, [blockTimestamp])
 }*/
