import React, { useCallback, useEffect, useState } from 'react'
import styled from 'styled-components/macro'
import PageTitle from '../../components/common/PageTitle'
import EarnPanel from './EarnPanel'
import ClaimPanel from './ClaimPanel'
import VestPanel from './VestPanel'
import VestingPositions from './VestingPositions'
import useApprovedToS from '../../hooks/useApprovedToS'
import FirstVisitPopoverVesting from '../../components/FirstVisitPopoverVesting/FirstVisitPopoverVesting'
import { useActiveWeb3React } from '../../hooks'
import { ChainId } from '../../constants'
import WarningRoundedIcon from '@material-ui/icons/WarningRounded'
import { isBerachain } from '../../constants/chainId'

export const Overlay = styled.div`
  width: 100%;
  height: 100%;
  background: ${({ theme }) => theme.bg2};
  opacity: 0.8;
  position: absolute;
  top: 0;
  left: 0;
  z-index: 1;
  pointer-events: none;
`

export const PanelTitle = styled.div`
  font-size: 21px;
  font-weight: 600;
  color: ${({ theme }) => theme.text1};
  margin-bottom: 10px;
`

export const PanelSubtitle = styled.div`
  font-size: 12px;
  font-weight: 400;
  color: ${({ theme }) => theme.text2};
  margin-bottom: 20px;
`

const VestingWrapper = styled.div`
  margin: 25px auto 35px;
  width: calc(100% - 80px);
  max-width: 1080px;
  padding-bottom: 200px;

  @media screen and (max-width: 900px) {
    margin-top: 15px;
    width: 100%;
    max-width: 600px;
  }

  @media screen and (max-width: 730px) {
    width: calc(100% - 40px);
  }

  @media screen and (max-width: 660px) {
    margin: 0 auto 15px;
    width: calc(100% - 30px);
    height: calc(100vh - 215px);
  }
`

const PolygonExplainer = styled.div`
  width: calc(100% - 40px);
  box-shadow: 0 5px 5px -3px rgb(0 0 0 / 20%), 0 8px 10px 1px rgb(0 0 0 / 14%), 0 3px 14px 2px rgb(0 0 0 / 12%);
  border-radius: 8px;
  padding: 25px 35px 30px;
  background-color: ${({ theme }) => theme.bg1};
  margin: 20px;
  font-size: 14px;

  svg {
    margin-right: 10px;
    margin-top: 0;
    font-size: 40px;
    color: ${({ theme }) => theme.text3};
    display: inline-block;
    vertical-align: top;
  }
`

const ContentWrapper = styled.div<{ enabled: boolean }>`
  opacity: ${({ enabled }) => (enabled ? 1 : 0.5)};
  pointer-events: ${({ enabled }) => (enabled ? 'auto' : 'none')};
  text-align: center;
`

const ProgramEndText = styled.div`
  width: calc(100% - 40px);
  margin: 20px 20px;
  padding: 25px 35px 20px;
  border-radius: 8px;
  background-color: ${({ theme }) => theme.bg1};
  display: inline-block;
  vertical-align: top;
  font-size: 14px;
  box-shadow: 0 5px 5px -3px rgb(0 0 0 / 20%), 0 8px 10px 1px rgb(0 0 0 / 14%), 0 3px 14px 2px rgb(0 0 0 / 12%);
  text-align: left;

  @media screen and (max-width: 1160px) {
    width: 500px;
  }

  @media screen and (max-width: 660px) {
    width: calc(100%);
    margin: 20px 0 0;
  }
`

const ProgramEndTitle = styled.div`
  font-size: 18px;
  font-weight: 600;
  color: ${({ theme }) => theme.text1};
  margin-bottom: 10px;
`

const ProgramEndContent = styled.div`
  font-size: 14px;
  font-weight: 400;
  color: ${({ theme }) => theme.text2};
  margin-bottom: 20px;
`

const DescriptionContent = styled.div`
  width: calc(100% - 60px);
  display: inline-block;
  vertical-align: top;
  margin-left: 10px;
`

export default function Vesting() {
  const { chainId } = useActiveWeb3React()
  const [hasVisitedVesting, setHasVisitedVesting] = useState(true)
  const [hasApprovedToS, setHasApprovedToS] = useApprovedToS()

  useEffect(() => {
    const localStorageContents = window.localStorage.getItem('dolomite_has_visited_vesting')
    if (!localStorageContents || localStorageContents !== 'true') {
      setHasVisitedVesting(false)
    }
  }, [])

  const setHasVisited = useCallback(() => {
    window.localStorage.setItem('dolomite_has_visited_vesting', 'true')
    setHasVisitedVesting(true)
  }, [])

  const setHasCompletedApprovals = useCallback(() => {
    setHasApprovedToS()
    setHasVisited()
  }, [setHasApprovedToS, setHasVisited])

  return (
    <VestingWrapper>
      <PageTitle title={isBerachain(chainId) ? 'Bolomite | Rewards' : 'Dolomite | Rewards'} />
      {(!hasVisitedVesting || !hasApprovedToS) && (
        <FirstVisitPopoverVesting approvedToS={hasApprovedToS} setHasCompletedApprovals={setHasCompletedApprovals} />
      )}
      {chainId !== ChainId.ARBITRUM_ONE && (
        <PolygonExplainer>
          <WarningRoundedIcon />
          <DescriptionContent style={{ marginTop: '4px' }}>
            oARB Rewards are not available on this chain!
            <br />
            <br />
            The Dolomite oARB Rewards Program is thanks to our STIP grant from Arbitrum, and as a result oARB rewards
            are only for assets deposited to Dolomite on Arbitrum. To earn oARB rewards, you will need to bridge your
            assets to Arbitrum and then deposit to Dolomite.
          </DescriptionContent>
        </PolygonExplainer>
      )}
      {/* ---- EARN PANEL ---- */}
      <ContentWrapper enabled={chainId === ChainId.ARBITRUM_ONE}>
        <ProgramEndText>
          <ProgramEndTitle>The oARB Rewards Program Has Concluded</ProgramEndTitle>
          <ProgramEndContent>
            {/*`The Arbitrum Short Term Incentive Program (STIP) has concluded, and as such the oARB rewards program has ended. Any currently vesting oARB will still continue vesting to completion. If you still have unused oARB, you will be able to use it if Arbitrum approves another round of STIP. Thank you to all participants for your support!`*/}
            {
              'With the last of the available ARB rewards claimed for vesting and the Arbitrum Short Term Incentive Program (STIP) grant period coming to an end, it is time to say goodbye to the oARB Rewards Program. You will no longer accumulate oARB, although you may still claim oARB earned in the past, as well as execute any vesting you have already begun once it has completed. If the Arbitrum DAO votes to extend the STIP grant program, vesting will become available again and you will resume earning oARB rewards.'
            }
          </ProgramEndContent>
        </ProgramEndText>
        <EarnPanel />
        {/* ---- CLAIM PANEL ---- */}
        <ClaimPanel />
        {/* ---- VEST PANEL ---- */}
        <VestPanel />
        {/* ---- VESTING POSITIONS ---- */}
        <VestingPositions />
      </ContentWrapper>
    </VestingWrapper>
  )
}
