import { useMemo } from 'react'
import { BorrowPosition } from '../types/borrowPositionData'
import { MarketRiskInfo } from '../types/marketRiskInfoData'
import { getBorrowPositionEMode, PositionOverrideSetting } from '../utils/emode'

export default function useBorrowPositionEMode(
  position: BorrowPosition,
  marketRiskInfoMap: Record<string, MarketRiskInfo | undefined>,
): PositionOverrideSetting | undefined {
  return useMemo(
    () =>
      getBorrowPositionEMode(
        position.supplyAmounts.map(a => a.token),
        position.borrowAmounts.map(a => a.token),
        marketRiskInfoMap,
      ),
    [position, marketRiskInfoMap],
  )
}
