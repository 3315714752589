import { Token } from '@dolomite-exchange/sdk-core'
import { ChainId, ChainIdMap } from '../chainId'

const decimals = 18
const symbol = 'weETH'
const name = 'Wrapped eETH'

function getToken(chainId: ChainId, address: string) {
  return new Token(chainId, address, decimals, symbol, name)
}

export const WE_ETH: ChainIdMap<Token | undefined> = {
  [ChainId.MAINNET]: undefined,
  [ChainId.ARBITRUM_ONE]: getToken(ChainId.ARBITRUM_ONE, '0x35751007a407ca6FEFfE80b3cB397736D2cf4dbe'),
  [ChainId.BASE]: undefined,
  [ChainId.BERACHAIN]: getToken(ChainId.BERACHAIN, '0x7DCC39B4d1C53CB31e1aBc0e358b43987FEF80f7'),
  [ChainId.MANTLE]: undefined,
  [ChainId.POLYGON_ZKEVM]: undefined,
  [ChainId.X_LAYER]: undefined,
}
