import { Token } from '@dolomite-exchange/v2-sdk'
import { USDM } from '../constants/tokens/USDM'
import { ChainId, FS_GLP_ISOLATION_MODE_ADDRESSES, USDM_ROUTER_ADDRESSES } from '../constants'
import invariant from 'tiny-invariant'
import { useMemo } from 'react'
import { useDepositWithdrawalRouterContract } from './useContract'
import remapSpenderForAllowance from '../utils/isolation/remapSpenderForAllowance'
import { useActiveWeb3React } from './index'

export default function useSpenderForDepositAllowance(token: Token): string | undefined {
  const { chainId, account } = useActiveWeb3React()
  const router = useDepositWithdrawalRouterContract()
  return useMemo(() => {
    const usdm = USDM[token.chainId as ChainId]
    if (usdm?.equals(token)) {
      const usdmRouterAddress = USDM_ROUTER_ADDRESSES[token.chainId as ChainId]
      invariant(!!usdmRouterAddress, `USDM Router address not set for chain ${token.chainId}`)
      return usdmRouterAddress
    } else if (account && FS_GLP_ISOLATION_MODE_ADDRESSES[chainId] === token.address) {
      return remapSpenderForAllowance(token, account, undefined)
    }

    return router?.address
  }, [token, account, chainId, router?.address])
}
