import FlameIcon from '../../assets/images/flame-icon-colored.svg'
import cleanCurrencySymbol from '../../utils/cleanCurrencySymbol'
import Checkmark from '@material-ui/icons/Check'
import { X } from 'react-feather'
import { StyledTooltipWithIcon } from '../common/StyledTooltip'
import React, { useMemo } from 'react'
import { ChainId, WETH } from '../../constants'
import { isValidDebtTokenForSingleCollateralRiskCategory } from '../../utils/emode'
import { MarketRiskInfo } from '../../types/marketRiskInfoData'
import { Currency } from '@dolomite-exchange/sdk-core'
import { Token } from '@dolomite-exchange/v2-sdk'
import styled from 'styled-components/macro'
import { formatAmount } from '../../utils/formatAmount'

const EmodeSection = styled.div<{ wide?: boolean }>`
  width: ${({ wide }) => (wide ? 100 : 26)}%;
  display: inline-block;
  vertical-align: top;
  padding: ${({ wide }) => (wide ? 'none' : '30px 22px 0')};
  ${({ wide }) => wide && 'margin-top: 30px'};
  border-left: 2px solid ${({ theme }) => theme.bg2};
  text-align: left;
  font-size: 12px;
  max-width: 320px;

  @media screen and (max-width: 1200px) {
    width: ${({ wide }) => (wide ? 100 : 40)}%;
    ${({ wide }) => wide && 'margin-left: 2%'};
    max-width: unset;
    border: none;
  }

  @media screen and (max-width: 1050px) {
    padding-left: 0;
  }
  /*@media screen and (max-width: 950px) {
    width: ${({ wide }) => (wide ? 100 : 20)}%;
  }*/
  @media screen and (max-width: 900px) {
    /*padding-top: 15px;*/
    // TODO - remember to keep all changes to position on Earn page to non-wide version
  }
  @media screen and (max-width: 800px) {
    /*position: absolute;
    left: 30px;
    top: 175px;*/
    /*width: ${({ wide }) => (wide ? 100 : 'calc(50% - 50px)')}%;*/
  }
  @media screen and (max-width: 700px) {
    width: 45%
  }
  @media screen and (max-width: 680px) {
    ${({ wide }) => wide && 'margin: 30px auto 0;'};
  }
  @media screen and (max-width: 650px) {
    width: 90%;
    max-width: 400px;
    margin: 0;
    padding: 40px 0 0;
    /*position: relative;
    padding: 15px 35px;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;*/
  }
  @media screen and (max-width: 420px) {
    /*padding: 15px 25px 10px;*/
  }
  @media screen and (max-width: 360px) {
    /*padding: 15px 20px 10px;*/
  }
  @media screen and (max-width: 330px) {
    /*padding: 10px 15px 8px;*/
  }
  @media screen and (max-width: 280px) {
    /*padding: 8px 10px 5px;*/
  }
`

const EmodeTitle = styled.div`
  font-size: 15px;
  margin-bottom: 8px;
  margin-top: -10px;

  img {
    height: 17px;
    margin-right: 2px;
    display: inline-block;
    vertical-align: top;
    margin-top: 1px;
  }
`

const EmodeCategory = styled.div`
  font-weight: 700;
  margin-bottom: 1px;
`

const EmodeDetailsWrapper = styled.div<{ wide?: boolean }>`
  background: ${({ theme, wide }) => (wide ? theme.bg1 : theme.bg2)};
  padding: 4px 6px;
  border-radius: 5px;
  width: calc(100% + 12px);
  margin-left: -6px;
`

const EmodeDetailsRow = styled.div`
  display: flex;
  justify-content: space-between;

  svg {
    transform: translate(-3px, 1px);
  }
`

const EmodeCorrelatedAssetsButton = styled.div`
  width: fit-content;
  text-align: center;
  background: ${({ theme }) => theme.bg3};
  padding: 3px 9px;
  border-radius: 5px;
  margin: 7px auto 0;
  font-weight: 600;
  font-size: 11px;
  cursor: pointer;

  :hover {
    background: ${({ theme }) => theme.bg4};
  }
`

const CollateralBorrowable = styled.div<{ isTrue: boolean }>`
  color: ${({ theme, isTrue }) => (isTrue ? theme.green1 : theme.red1)};
  font-weight: 500;

  svg {
    height: 16px;
    margin-bottom: -3px;
    margin-right: ${({ isTrue }) => (isTrue ? '-4px' : '-2px')};
    transform: unset;
  }
`

interface EmodeInfoSectionProps {
  marketRiskData: MarketRiskInfo | undefined
  chainId: number
  currencyOrToken: Currency | Token
  setEmodeModalOpen: (open: boolean) => void
  wide?: boolean
}

function EmodeInfoSectionComparator(prevProps: EmodeInfoSectionProps, nextProps: EmodeInfoSectionProps) {
  return (
    prevProps.marketRiskData?.riskCategories === nextProps.marketRiskData?.riskCategories &&
    prevProps.marketRiskData?.isBorrowingDisabled === nextProps.marketRiskData?.isBorrowingDisabled &&
    prevProps.marketRiskData?.isBorrowOnly === nextProps.marketRiskData?.isBorrowOnly &&
    prevProps.chainId === nextProps.chainId &&
    prevProps.setEmodeModalOpen === nextProps.setEmodeModalOpen
  )
}

export const EmodeInfoSection = React.memo(function EmodeInfoSectionInner({
  marketRiskData,
  chainId,
  currencyOrToken,
  setEmodeModalOpen,
  wide,
}: EmodeInfoSectionProps) {
  const weth = useMemo(() => WETH[chainId as ChainId], [chainId])
  const emodeInfo = marketRiskData?.riskCategories
  const isEmodeCompatible = !!emodeInfo
  const eModeCategory = emodeInfo ? emodeInfo[0].name : undefined
  const isEmodeCollateral = emodeInfo
    ? !(
        isValidDebtTokenForSingleCollateralRiskCategory(
          currencyOrToken.isNative ? weth : currencyOrToken,
          emodeInfo[0],
        ) || marketRiskData?.isBorrowOnly
      )
    : undefined
  const isEmodeBorrowable = !marketRiskData?.isBorrowingDisabled
  const isEmodeStrict = emodeInfo ? emodeInfo[0].strict : undefined
  const eModeLTV = emodeInfo ? emodeInfo[0].marginRatioOverride.invert().multiply(100) : undefined
  const eModeLiqPenalty = emodeInfo ? emodeInfo[0].liquidationRewardOverride.multiply(100) : undefined

  return (
    <EmodeSection wide={wide}>
      <EmodeTitle>
        <img src={FlameIcon} alt={''} /> E-Mode Info
      </EmodeTitle>
      <EmodeCategory>{eModeCategory}</EmodeCategory>
      <EmodeDetailsWrapper wide={wide}>
        <EmodeDetailsRow>
          <div>{cleanCurrencySymbol(currencyOrToken, false)}</div>
          <CollateralBorrowable isTrue={!!isEmodeCollateral}>
            {isEmodeCollateral ? <Checkmark /> : <X />} Collateral
          </CollateralBorrowable>
          <CollateralBorrowable isTrue={isEmodeBorrowable}>
            {isEmodeBorrowable ? <Checkmark /> : <X />}Borrowable
          </CollateralBorrowable>
        </EmodeDetailsRow>
        <EmodeDetailsRow>
          <div>
            Strict{' '}
            <StyledTooltipWithIcon
              tooltipText={`Open positions that are using Strict E-Mode can't be modified by adding other assets. Positions using Non-Strict E-Mode can be modified by adding other assets, but will lose e-mode advantages.`}
            />
          </div>
          <div>{isEmodeStrict ? 'YES' : 'NO'}</div>
        </EmodeDetailsRow>
        <EmodeDetailsRow>
          <div>Max LTV</div>
          <div>{formatAmount(eModeLTV, 2, true, '-')}%</div>
        </EmodeDetailsRow>
        <EmodeDetailsRow>
          <div>Liq. Penalty</div>
          <div>{formatAmount(eModeLiqPenalty, 2, true, '-')}%</div>
        </EmodeDetailsRow>
      </EmodeDetailsWrapper>
      <EmodeCorrelatedAssetsButton
        onClick={e => {
          e.stopPropagation()
          setEmodeModalOpen(true)
        }}
      >
        View Correlated Assets
      </EmodeCorrelatedAssetsButton>
    </EmodeSection>
  )
},
EmodeInfoSectionComparator)
