import React from 'react'
import styled from 'styled-components/macro'
import PageTitle from '../../components/common/PageTitle'
import DepositedBalances from './DepositedBalances'
import ManagePool from './ManagePool'
import TOSPopover from '../../components/TOSPopover'
import useApprovedToS from '../../hooks/useApprovedToS'
import { ChainId } from '../../constants'
import { useActiveWeb3React } from '../../hooks'
import { isBerachain } from '../../constants/chainId'

const PoolWrapper = styled.div<{ disabled?: boolean }>`
  margin: 25px auto 0;
  max-width: 900px;
  ${({ disabled }) => disabled && 'pointer-events: none;'}
  ${({ disabled }) => disabled && 'opacity: 0.5;'}
    text-align: center;

  @media screen and (max-width: 919px) {
    display: flex;
    flex-direction: column-reverse;
    margin-top: 15px;
  }
`

const BalancesPanel = styled.div`
  display: inline-block;
  margin-right: 15px;
  width: 36%;
  text-align: left;

  @media screen and (max-width: 919px) {
    display: flex;
    flex-direction: column-reverse;
    margin: 0 auto;
    width: calc(100% - 32px);

    > div {
      margin-bottom: 20px;
    }
  }

  @media screen and (max-width: 919px) {
    margin: 0 auto 70px;
    width: 70vw;
  }

  @media screen and (max-width: 730px) {
    width: 80vw;
    max-width: 1400px;
  }

  @media screen and (max-width: 580px) {
    width: 100%;
  }
`

const DeprecatedInfo = styled.div`
  width: 100%;
  max-width: 870px;
  background: ${({ theme }) => theme.bg1};
  border-radius: 8px;
  padding: 20px 30px;
  margin: 0 auto 20px;
  font-size: 15px;
  box-shadow: 0 5px 5px -3px rgb(0 0 0 / 20%), 0 8px 10px 1px rgb(0 0 0 / 14%), 0 3px 14px 2px rgb(0 0 0 / 12%);

  @media screen and (max-width: 919px) {
    max-width: 70vw;
  }

  @media screen and (max-width: 730px) {
    width: 80vw;
    max-width: 1400px;
  }

  @media screen and (max-width: 580px) {
    width: calc(100% - 40px);
  }
`

export default function Lending() {
  const { chainId } = useActiveWeb3React()
  const [hasApprovedToS, setHasApprovedToS] = useApprovedToS()
  return (
    <div>
      <PageTitle title={isBerachain(chainId) ? 'Bolomite | Pool' : 'Dolomite | Pool'} />
      {!hasApprovedToS && <TOSPopover setApprovedToS={setHasApprovedToS} />}
      {isBerachain(chainId) && (
        <DeprecatedInfo>
          {`Dolomite AMM pools have been deprecated in favor of Zap, which integrates with DEX aggregators and directly
          with protocols to give you the best possible price even with larger trades. You can use Zap by clicking the
          "Swap" button on the Balances page or the Borrow page, as well as by using it in open Borrow positions.`}
        </DeprecatedInfo>
      )}
      <PoolWrapper disabled={isBerachain(chainId)}>
        <BalancesPanel>
          <DepositedBalances />
        </BalancesPanel>
        <ManagePool />
      </PoolWrapper>
    </div>
  )
}
