import React, { useCallback, useContext, useEffect, useMemo, useState } from 'react'
import styled from 'styled-components/macro'
import { ColumnTitles, ConnectBtn, ConnectWalletButton, DataRows, NoTrades } from './styled'
import { useTranslation } from 'react-i18next'
import Web3Status from '../../components/Web3Status'
import Column from '../../components/Orders/Column'
import DataRow from '../../components/Orders/DataRow'
import { useActiveWeb3React } from '../../hooks'
import { Fraction, Rounding, Token } from '@dolomite-exchange/v2-sdk'
import AllInclusiveIcon from '@material-ui/icons/AllInclusive'
import { BorrowPosition, BorrowPositionStatus, useAllBorrowPositions } from '../../types/borrowPositionData'
import { ChainId, ZERO_ADDRESS } from '../../constants'
import { TableLoader } from '../Loader'
import { useCombinedTransfersByMarginAccount } from '../../types/transferData'
import { useTradeDataByMarginAccount } from '../../types/tradeData'
import { useLiquidationDataByMarginAccount } from '../../types/liquidationData'
import Modal from '../Modal'
import toDate from '../../utils/toDate'
import cleanCurrencySymbol from '../../utils/cleanCurrencySymbol'
import getLogoOrDefault from '../common/TokenLogos'
import ArrowUpwardIcon from '@mui/icons-material/ArrowUpward'
import ArrowDownwardIcon from '@mui/icons-material/ArrowDownward'
import { ExternalLink } from '../../theme'
import { getEtherscanLink } from '../../utils'
import { StyledTooltip } from '../common/StyledTooltip'
import KeyboardReturnIcon from '@mui/icons-material/KeyboardReturn'
import useSortedActionsWithLabels, { ActionWithLabel } from './hooks/useSortedActionsWithLabels'
import { formatAmount } from '../../utils/formatAmount'
import AmountWithFullTooltip from '../AmountWithFullTooltip'
import { PageSizeContext } from '../../pages/App'

const BorrowWrapper = styled.div`
  width: 100%;
  height: 100%;
`

const TokenLogo = styled.div<{ large?: boolean }>`
  display: inline-block;
  margin-right: 3px;
  margin-top: ${({ large }) => (large ? '2px' : '0')};
  vertical-align: top;
  width: ${({ large }) => (large ? '18px' : '14px')};

  img {
    width: 100%;
  }

  @media screen and (max-width: 515px) {
    display: none;
  }
`

const LogoHelper = styled.span`
  display: inline-block;
  height: 100%;
  vertical-align: middle;
`

const LoaderWrapper = styled.div`
  width: 100%;
  padding: 0 25px;

  ${({ theme }) => theme.mediaWidth.upToSmall`
    padding: 0 20px;
  `};
`

const ModalLoaderWrapper = styled.div`
  width: 100%;
  height: 100px;
`

const ModalRows = styled.div`
  font-size: 13px;
  @media screen and (max-width: 515px) {
    font-size: 11px;
  }
  @media screen and (max-width: 515px) {
    font-size: 10px;
  }
  width: calc(100% + 10px);
  max-height: 310px;
  overflow-y: scroll;
  overflow-x: hidden;
  scrollbar-color: ${({ theme }) => `${theme.bg4} ${theme.bg2}`};
  scrollbar-width: thin;

  ::-webkit-scrollbar {
    width: 10px;
    margin-left: 2px;
    background: ${({ theme }) => theme.bg1};
    left: 50px;
  }

  ::-webkit-scrollbar-track {
    background: ${({ theme }) => theme.bg1};
    width: 10px;
    box-shadow: inset 0 0 10px 10px ${({ theme }) => theme.bg1};
    border: solid 3px ${({ theme }) => theme.bg1};
  }

  ::-webkit-scrollbar-thumb {
    background: ${({ theme }) => theme.bg4};
    width: 4px;
    border-radius: 10px;
    box-shadow: inset 0 0 10px 10px ${({ theme }) => theme.bg4};
    border: solid 3px ${({ theme }) => theme.bg1};
  }

  ::-webkit-scrollbar-button {
    display: none;
  }
`

const LiqRow = styled.div`
  width: 100%;
  height: 45px;
  margin-bottom: 4px;
`

const ModalRow = styled.div`
  width: 100%;
  height: 25px;
  margin-bottom: 10px;
`

const ModalContent = styled.div`
  width: 100%;
  height: 100%;
  padding: 1.5rem 2rem;
`

const ModalWrapper = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
`

const PositionData = styled.div`
  width: 100%;
`

const ClosedTitle = styled.div`
  font-size: 20px;
  font-weight: 300;

  @media screen and (max-width: 515px) {
    font-size: 18px;
  }
`

const BorrowTitle = styled.div`
  font-size: 12px;
  font-weight: 600;
  width: fit-content;
  display: inline-block;
  color: ${({ theme }) => theme.text3};
  margin-bottom: 5px;

  @media screen and (max-width: 515px) {
    font-size: 11px;
  }
`

const BorrowAmount = styled.div`
  font-size: 30px;
  font-weight: 300;
  margin: -5px 0;

  @media screen and (max-width: 515px) {
    font-size: 24px;
  }
`

const HealthTitle = styled.span`
  color: ${({ theme }) => theme.text2};
`

const HealthWrapper = styled.div`
  font-size: 12px;

  @media screen and (max-width: 515px) {
    font-size: 11px;
  }
`

const Health = styled.span<{ health: number }>`
  color: ${({ theme, health }) => (health === 3 ? theme.green1 : health == 2 ? theme.yellow1 : theme.red1)};
`

const Totals = styled.div`
  width: 100%;
  position: relative;
  display: flex;
`

const Left = styled.div`
  width: 50%;
  text-align: left;
  position: relative;
`

const Right = styled.div`
  width: 50%;
  text-align: right;
  position: relative;
`

const Amounts = styled.div`
  margin-top: 10px;
  width: 100%;
  position: relative;
  display: flex;
`

const ArrowWrapper = styled.span<{ up: boolean }>`
  height: 12px;
  margin-left: 2px;

  svg {
    height: 0.6em;
    width: 0.6em;
    margin-bottom: -2px;
    color: ${({ theme, up }) => (up ? theme.green1 : theme.red1)};
  }
`

const LiqArrow = styled.span`
  margin-right: 2px;
  margin-left: -16px;

  svg {
    transform: scaleX(-1);
    height: 0.6em;
    width: 0.6em;
    margin-bottom: -3px;
    color: ${({ theme }) => theme.text3};
  }
`

const TradeRow = styled.div`
  width: calc(100% - 18px);
  color: ${({ theme }) => theme.text2};
  margin-left: 18px;
  font-size: 11px;

  @media screen and (max-width: 515px) {
    font-size: 10px;
  }
`

const DollarValue = styled.span`
  color: ${({ theme }) => theme.text3};
`

const InfinityWrapper = styled.span`
  height: 12px;

  svg {
    height: 0.8em;
    width: 0.8em;
    margin-bottom: -5px;
  }
`

const NetInterest = styled.div`
  font-size: 12px;
  color: ${({ theme }) => theme.text2};
`

const CollateralAsset = styled.div`
  font-size: 15px;
  width: 100%;
  display: inline-block;
  vertical-align: top;
  position: relative;
  height: 20px;
`

const BorrowedAsset = styled.div`
  font-size: 15px;
  width: 100%;
  margin-right: 10px;
  display: inline-block;
  vertical-align: top;
  position: relative;
  height: 20px;

  @media screen and (max-width: 615px) {
    font-size: 15px;
  }
`

const CollateralAssetDescription = styled.div`
  position: absolute;
  text-align: left;
  width: fit-content;
  left: 0;
`

const BorrowedAssetDescription = styled.div`
  position: absolute;
  text-align: left;
  width: fit-content;
  left: 15px;
`

const LogoTicker = styled.div`
  display: flex;
`

const TokenInfo = styled.div`
  width: 100%;
`

const TokenTicker = styled.div`
  width: fit-content;
  display: inline-block;
  font-size: 16px;

  @media screen and (max-width: 615px) {
    font-size: 15px;
  }
`

const CollateralAssetAmount = styled.div`
  width: fit-content;
  position: absolute;
  right: 15px;
  text-align: right;
`

const BorrowedAssetAmount = styled.div`
  width: fit-content;
  position: absolute;
  right: 0;
  text-align: left;
`

const AmountInAsset = styled.div`
  font-size: 16px;
`

const ClosedTicker = styled.span`
  margin-right: 7px;
  text-transform: none;
`

const getPositionHealth = (health: Fraction | undefined) => {
  return health
    ? health.greaterThan(new Fraction('125', '100'))
      ? 3
      : health.greaterThanOrEqual(new Fraction('115', '100'))
      ? 2
      : 1
    : 3
}

function isLiquidationOrExpiration(action: ActionWithLabel): boolean {
  if (!action) {
    return false
  }
  return action.actionType === 'Liquidation' || action.actionType === 'Expiration'
}

interface BorrowModalProps {
  position: BorrowPosition | undefined
  open: boolean
  close: () => void
  chainId: ChainId
  isMobile: boolean
  isTablet: boolean
}

function BorrowModalComparator(prevProps: BorrowModalProps, nextProps: BorrowModalProps) {
  return (
    prevProps.open === nextProps.open &&
    prevProps.chainId === nextProps.chainId &&
    prevProps.position?.status === nextProps.position?.status &&
    prevProps.position?.totalBorrowUSD === nextProps.position?.totalBorrowUSD &&
    prevProps.position?.totalSupplyUSD === nextProps.position?.totalSupplyUSD
  )
}

const BorrowModal = React.memo(function BorrowModalInner({ position, open, close, chainId }: BorrowModalProps) {
  const { t } = useTranslation()
  const positionId = useMemo(() => {
    return position?.id === '' ? ZERO_ADDRESS : position?.id
  }, [position])
  const { data: transfers, loading: isTransfersLoading } = useCombinedTransfersByMarginAccount(position?.marginAccount)
  const { data: liquidations } = useLiquidationDataByMarginAccount(position?.marginAccount)
  const { data: trades } = useTradeDataByMarginAccount(position?.marginAccount)
  const [isLoading, setIsLoading] = useState(true)
  useEffect(() => {
    isLoading && !isTransfersLoading && setIsLoading(false)
  }, [isTransfersLoading, isLoading])

  const actions = useSortedActionsWithLabels(transfers, liquidations, trades, position)
  const positionHealthFactor = useMemo(() => {
    return getPositionHealth(position?.positionHealth)
  }, [position?.positionHealth])

  const widths = {
    widths: [14, 18, 18, 10],
    starts: [0, 25, 62, 90],
  }

  return (
    <Modal isOpen={open} onDismiss={close} maxHeight={200} maxWidthPx={480}>
      <ModalContent>
        {position && position.status === BorrowPositionStatus.Open ? (
          <PositionData>
            <Totals>
              <Left>
                <BorrowTitle>{t('collateral')}</BorrowTitle>
                <BorrowAmount>{formatAmount(position.totalSupplyUSD, 2, true, '$0.00', true)}</BorrowAmount>
                <HealthWrapper>
                  <HealthTitle>
                    {t('positionHealth')}
                    {`: `}
                  </HealthTitle>
                  <Health health={positionHealthFactor}>
                    <span>
                      {!position.positionHealth ? (
                        <InfinityWrapper>
                          <AllInclusiveIcon />
                        </InfinityWrapper>
                      ) : position.positionHealth.greaterThan('10') ? (
                        '>10'
                      ) : (
                        formatAmount(position.positionHealth, 2, true, '-', false, Rounding.ROUND_DOWN)
                      )}
                    </span>
                  </Health>
                </HealthWrapper>
              </Left>
              <Right>
                <BorrowTitle>{t('borrowing')}</BorrowTitle>
                <BorrowAmount>{formatAmount(position.totalBorrowUSD, 2, true, '$0.00', true)}</BorrowAmount>
                <NetInterest>
                  {t('netInterest')}
                  {` `}
                  {position.netInterestRate.greaterThanOrEqual('0') ? '+' : ''}
                  {formatAmount(position.netInterestRate, 2, true, '0%')} APR
                </NetInterest>
              </Right>
            </Totals>
            <Amounts>
              <Left>
                <BorrowTitle>{t('collateral')}</BorrowTitle>
                {position.supplyAmounts.map((supplyAmount, index) => (
                  <CollateralAsset key={`${positionId}-supplyAmount-${index}`}>
                    <CollateralAssetDescription>
                      <TokenInfo>
                        <LogoTicker>
                          <TokenLogo>
                            <LogoHelper>
                              <img
                                src={getLogoOrDefault(cleanCurrencySymbol(supplyAmount.token) ?? '')}
                                alt={`${cleanCurrencySymbol(supplyAmount.token)} logo`}
                              />
                            </LogoHelper>
                          </TokenLogo>
                          <TokenTicker>{cleanCurrencySymbol(supplyAmount.token)}</TokenTicker>
                        </LogoTicker>
                      </TokenInfo>
                    </CollateralAssetDescription>
                    <CollateralAssetAmount>
                      <AmountInAsset>
                        <AmountWithFullTooltip amount={supplyAmount.amountTokenWei} ignoreTicker={true} />
                      </AmountInAsset>
                    </CollateralAssetAmount>
                  </CollateralAsset>
                ))}
              </Left>
              <Right>
                <BorrowTitle>{t('borrowing')}</BorrowTitle>
                {position.borrowAmounts.map((borrowAmount, index) => (
                  <BorrowedAsset key={`${positionId}-borrowAmount-${index}`}>
                    <BorrowedAssetDescription>
                      <TokenInfo>
                        <LogoTicker>
                          <TokenLogo>
                            <LogoHelper>
                              <img
                                src={getLogoOrDefault(cleanCurrencySymbol(borrowAmount.token) ?? '')}
                                alt={`${cleanCurrencySymbol(borrowAmount.token)} logo`}
                              />
                            </LogoHelper>
                          </TokenLogo>
                          <TokenTicker>{cleanCurrencySymbol(borrowAmount.token)}</TokenTicker>
                        </LogoTicker>
                      </TokenInfo>
                    </BorrowedAssetDescription>
                    <BorrowedAssetAmount>
                      <AmountInAsset>
                        <AmountWithFullTooltip amount={borrowAmount.amountTokenWei} ignoreTicker={true} />
                      </AmountInAsset>
                    </BorrowedAssetAmount>
                  </BorrowedAsset>
                ))}
              </Right>
            </Amounts>
          </PositionData>
        ) : (
          <ClosedTitle>Closed Position</ClosedTitle>
        )}
        <ColumnTitles modal>
          <Column width={25} textAlign={'left'}>
            {t('action')}
          </Column>
          <Column width={55} textAlign={'left'}>
            {t('amount')}
          </Column>
          <Column width={20} textAlign={'right'}>
            {t('date')}
          </Column>
        </ColumnTitles>
        <ModalRows>
          {actions.length === 0 || actions.length === 0 ? (
            <ModalLoaderWrapper>
              <TableLoader rows={4} height={12} spacing={25} marginTop={10} widths={widths} />
            </ModalLoaderWrapper>
          ) : (
            <>
              {actions.map((action, i) => {
                if (isLiquidationOrExpiration(action)) {
                  return (
                    <LiqRow key={`${positionId}-action-${i}`}>
                      <Column width={25} textAlign={'left'}>
                        <ExternalLink
                          href={getEtherscanLink(chainId, action.transaction.transactionHash, 'transaction')}
                          style={{ fontWeight: 400 }}
                        >
                          {action.actionType}
                        </ExternalLink>
                      </Column>
                      <Column width={55} textAlign={'left'}>
                        <AmountWithFullTooltip amount={action.amount1} />{' '}
                        <DollarValue>({formatAmount(action.amount1USD, 2, true, '$0.00', true)})</DollarValue>
                      </Column>
                      <StyledTooltip
                        title={`${toDate(action.transaction.timestamp, true)}`}
                        placement={'right'}
                        style={{ cursor: 'pointer' }}
                      >
                        <Column width={20} textAlign={'right'}>
                          {toDate(action.transaction.timestamp).replace('a few seconds ago', '< 1 min ago')}
                        </Column>
                      </StyledTooltip>
                      <TradeRow>
                        <Column width={100} textAlign={'left'}>
                          <LiqArrow>
                            <KeyboardReturnIcon />
                          </LiqArrow>
                          Repaid <AmountWithFullTooltip amount={action.amount2} />{' '}
                          <DollarValue>({formatAmount(action.amount2USD, 2, true, '$0.00', true)})</DollarValue>
                        </Column>
                      </TradeRow>
                    </LiqRow>
                  )
                } else if (action.actionType === 'Trade') {
                  const [title, description] = action.label.split(':')
                  return (
                    <LiqRow key={`${positionId}-action-${i}`}>
                      <Column width={25} textAlign={'left'}>
                        <ExternalLink
                          href={getEtherscanLink(chainId, action.transaction.transactionHash, 'transaction')}
                          style={{ fontWeight: 400 }}
                        >
                          {title.includes('Collateral') ? (
                            <>
                              {t('collateral')}
                              <ArrowWrapper up={title.includes('up')}>
                                {title.includes('up') ? <ArrowUpwardIcon /> : <ArrowDownwardIcon />}
                              </ArrowWrapper>
                            </>
                          ) : title.includes('Swap') ? (
                            <>{t('swap')}</>
                          ) : (
                            title
                          )}
                        </ExternalLink>
                      </Column>
                      <Column width={55} textAlign={'left'}>
                        <TokenLogo>
                          <LogoHelper>
                            <img
                              src={getLogoOrDefault(cleanCurrencySymbol(action.amount1.currency) ?? '')}
                              alt={`${cleanCurrencySymbol(action.amount1.currency)} logo`}
                            />
                          </LogoHelper>
                        </TokenLogo>
                        <AmountWithFullTooltip amount={action.amount1} />{' '}
                        <DollarValue>({formatAmount(action.amount2USD, 2, true, '$0.00', true)})</DollarValue>
                      </Column>
                      <StyledTooltip
                        title={`${toDate(action.transaction.timestamp, true)}`}
                        placement={'right'}
                        style={{ cursor: 'pointer' }}
                      >
                        <Column width={20} textAlign={'right'}>
                          {toDate(action.transaction.timestamp).replace('a few seconds ago', '< 1 min ago')}
                        </Column>
                      </StyledTooltip>
                      <TradeRow>
                        <Column width={100} textAlign={'left'}>
                          <LiqArrow>
                            <KeyboardReturnIcon />
                          </LiqArrow>
                          {action.label.includes('Swap collateral')
                            ? 'Swapped into '
                            : action.label.includes('Swap debt')
                            ? 'Borrowed and swapped to repay '
                            : action.label.includes('Repay') || action.label.includes('Collateral up')
                            ? 'Traded from'
                            : 'Traded into'}{' '}
                          <AmountWithFullTooltip amount={action.amount2} />{' '}
                          <DollarValue>({formatAmount(action.amount2USD, 2, true, '$0.00', true)})</DollarValue>
                          {description}
                        </Column>
                      </TradeRow>
                    </LiqRow>
                  )
                } else {
                  return (
                    <ModalRow key={`${positionId}-action-${i}`}>
                      <Column width={25} textAlign={'left'}>
                        <ExternalLink
                          href={getEtherscanLink(chainId, action.transaction.transactionHash, 'transaction')}
                          style={{ fontWeight: 400 }}
                        >
                          {action.label.includes('Collateral') ? (
                            <>
                              {t('collateral')}
                              <ArrowWrapper up={action.label.includes('up')}>
                                {action.label.includes('up') ? <ArrowUpwardIcon /> : <ArrowDownwardIcon />}
                              </ArrowWrapper>
                            </>
                          ) : (
                            action.label
                          )}
                        </ExternalLink>
                      </Column>
                      <Column width={55} textAlign={'left'}>
                        <TokenLogo>
                          <LogoHelper>
                            <img
                              src={getLogoOrDefault(cleanCurrencySymbol(action.amount1.currency) ?? '')}
                              alt={`${cleanCurrencySymbol(action.amount1.currency)} logo`}
                            />
                          </LogoHelper>
                        </TokenLogo>
                        <AmountWithFullTooltip amount={action.amount1} />{' '}
                        <DollarValue>({formatAmount(action.amount1USD, 2, true, '$0.00', true)})</DollarValue>
                      </Column>
                      <StyledTooltip
                        title={`${toDate(action.transaction.timestamp, true)}`}
                        placement={'right'}
                        style={{ cursor: 'pointer' }}
                      >
                        <Column width={20} textAlign={'right'}>
                          {toDate(action.transaction.timestamp).replace('a few seconds ago', '< 1 min ago')}
                        </Column>
                      </StyledTooltip>
                    </ModalRow>
                  )
                }
              })}
            </>
          )}
        </ModalRows>
      </ModalContent>
    </Modal>
  )
}, BorrowModalComparator)

interface TokensProps {
  tokens: Token[]
  chainId: ChainId
}

const ClosedTokens = ({ tokens }: TokensProps) => {
  return (
    <>
      {tokens.map(token => {
        return (
          <div key={token.address}>
            <TokenLogo>
              <LogoHelper>
                <img
                  src={getLogoOrDefault(cleanCurrencySymbol(token ?? '') ?? '')}
                  alt={`${cleanCurrencySymbol(token)} logo`}
                />
              </LogoHelper>
            </TokenLogo>
            <ClosedTicker>{cleanCurrencySymbol(token)}</ClosedTicker>
          </div>
        )
      })}
    </>
  )
}

interface BorrowRowProps {
  position: BorrowPosition
  openModal: () => void
  isMobile: boolean
  isTablet: boolean
}

function BorrowRowComparator(prevProps: BorrowRowProps, nextProps: BorrowRowProps) {
  return (
    prevProps.isMobile === nextProps.isMobile &&
    prevProps.isTablet === nextProps.isTablet &&
    prevProps.position.status === nextProps.position.status &&
    prevProps.position.totalBorrowUSD === nextProps.position.totalBorrowUSD &&
    prevProps.position.totalSupplyUSD === nextProps.position.totalSupplyUSD
  )
}

const BorrowRow = React.memo(function BorrowRowInner({ position, openModal, isMobile, isTablet }: BorrowRowProps) {
  const { chainId } = useActiveWeb3React()
  const supplyText =
    position.status === BorrowPositionStatus.Open ? (
      `${formatAmount(position.totalSupplyUSD, 2, true, '$0.00', true)}`
    ) : (
      <ClosedTokens tokens={position.effectiveSupplyTokens} chainId={chainId} />
    )
  const borrowText =
    position.status === BorrowPositionStatus.Open ? (
      `${formatAmount(position.totalBorrowUSD, 2, true, '$0.00', true)}`
    ) : (
      <ClosedTokens tokens={position.effectiveBorrowTokens} chainId={chainId} />
    )
  return (
    <div onClick={openModal}>
      <DataRow
        columns={[
          {
            width: 20,
            mobileWidth: 30,
            textAlign: 'left',
            type: 'action',
            data: position.status.substring(0, 1) + position.status.substring(1).toLowerCase(),
            date: isMobile && !isTablet ? position.openTimestamp : undefined,
          },
          {
            width: 30,
            mobileWidth: 35,
            textAlign: 'left',
            type: 'text',
            data: supplyText,
          },
          {
            width: 30,
            mobileWidth: 35,
            textAlign: isMobile && !isTablet ? 'right' : 'left',
            type: 'text',
            data: borrowText,
          },
          {
            width: 20,
            textAlign: 'right',
            type: 'text',
            data: `${toDate(position.openTimestamp)}, ${position.openTimestamp.toLocaleString([], {
              hour: 'numeric',
              minute: '2-digit',
            })}`,
            hideOnMobile: true,
          },
        ]}
        contentHeight={36}
        history
      />
    </div>
  )
}, BorrowRowComparator)

export default function Borrow() {
  const { t } = useTranslation()
  const { account, chainId } = useActiveWeb3React()
  const { data: positions, loading: isBorrowLoading } = useAllBorrowPositions(account ?? ZERO_ADDRESS)
  const [isLoading, setIsLoading] = useState(true)
  const [selectedPosition, setSelectedPosition] = useState<BorrowPosition | undefined>(undefined)
  const [modalOpen, setModalOpen] = useState(false)
  const openModal = useCallback(
    (position: BorrowPosition) => {
      setSelectedPosition(position)
      setModalOpen(true)
    },
    [setSelectedPosition, setModalOpen],
  )
  const closeModal = useCallback(() => {
    setModalOpen(false)
  }, [setModalOpen])

  useEffect(() => {
    isLoading && !isBorrowLoading && setIsLoading(false)
  }, [isBorrowLoading, isLoading])

  const widths = {
    widths: [10, 15, 15, 15],
    starts: [0, 20, 50, 85],
    tabletWidths: [10, 15, 15, 15],
    tabletStarts: [0, 20, 50, 85],
    mobileWidths: [15, 20, 20],
    mobileStarts: [0, 30, 80],
  }
  const { x } = useContext(PageSizeContext)
  const isMobile = x < 960
  const isTablet = 660 < x && x < 960

  return (
    <BorrowWrapper>
      {modalOpen ? (
        <ModalWrapper>
          <BorrowModal
            position={selectedPosition}
            open={modalOpen}
            close={closeModal}
            chainId={chainId}
            isMobile={isMobile}
            isTablet={isTablet}
          />
        </ModalWrapper>
      ) : null}
      <ColumnTitles>
        <Column width={20} mobileWidth={30} textAlign={'left'}>
          {t('status')}
        </Column>
        <Column width={30} mobileWidth={35} textAlign={'left'}>
          {t('supply')} {t('amount')}
        </Column>
        <Column width={30} mobileWidth={35} textAlign={isMobile && !isTablet ? 'right' : 'left'}>
          {t('borrow')} {t('amount')}
        </Column>
        <Column width={20} mobileWidth={20} hideOnMobile={true} textAlign={'right'}>
          {t('openTime')}
        </Column>
      </ColumnTitles>
      <DataRows>
        {account ? (
          isLoading ? (
            <LoaderWrapper>
              <TableLoader
                rows={isMobile ? 5 : isTablet ? 4 : 3}
                height={30}
                spacing={55}
                marginTop={20}
                isMobile={isMobile}
                isTablet={isTablet}
                widths={widths}
              />
            </LoaderWrapper>
          ) : positions.length === 0 ? (
            <NoTrades>{t('noBorrows')}</NoTrades>
          ) : (
            positions.map(position => (
              <BorrowRow
                key={position.id}
                position={position}
                openModal={() => openModal(position)}
                isMobile={isMobile}
                isTablet={isTablet}
              />
            ))
          )
        ) : (
          <ConnectWalletButton>
            <ConnectBtn>
              <Web3Status />
            </ConnectBtn>
          </ConnectWalletButton>
        )}
      </DataRows>
    </BorrowWrapper>
  )
}
