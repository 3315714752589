import { useActiveWeb3React } from '../../hooks'
import styled from 'styled-components/macro'
import React, { useCallback, useEffect, useState } from 'react'
import { keyframes } from 'styled-components'
import AccountBalanceWalletOutlinedIcon from '@mui/icons-material/AccountBalanceWalletOutlined'
import DolomiteLogo from '../../assets/dolomite-logo-white.svg'
import WalletGraphic from '../../assets/svg/wallet-graphic.svg'
import useCloseBalancesInfo from '../../hooks/useApprovedToS'
import PageTitle from '../common/PageTitle'
import { ChainId } from '../../constants'
import FirstVisitPopoverBalances from '../FirstVisitPopoverBalances/FirstVisitPopoverBalances'
import BeraBearImg from '../../assets/images/Bera-Bear.svg'
import BalancesTotal from '../Balances/BalancesTotal'
import BalancesPanel from '../Balances/BalancesPanel'
import { styled as muiStyled } from '@mui/material/styles'
import KeyboardArrowLeftIcon from '@mui/icons-material/KeyboardArrowLeft'
import KeyboardArrowRightIcon from '@mui/icons-material/KeyboardArrowRight'
import LoopIcon from '@mui/icons-material/Loop'
import { theme } from '../../theme'
import ETHLogo from '../../assets/images/ethereum-logo.png'
import USDCLogo from '../../assets/images/usdc-logo.svg'
import BTCLogo from '../../assets/images/bitcoin-logo.svg'
import DAILogo from '../../assets/images/dai-logo.svg'
import BorrowGraphic from '../../assets/images/borrow-page-graphic.svg'
import StrategiesGraphic from '../../assets/images/strategies-hub-graphic.svg'
import { X } from 'react-feather'
import BoltIcon from '@mui/icons-material/Bolt'
import { StyledTooltip } from '../common/StyledTooltip'
import { formatAmount } from '../../utils/formatAmount'
import Modal from '../Modal'

const InfoWrapper = styled.div`
  background-color: #292938;
  -webkit-border-radius: 8px;
  -moz-border-radius: 8px;
  border-radius: 8px;
  display: inline-block;
  margin: 0;
  padding: 53px 35px 20px;
  vertical-align: top;
  width: 100%;
  max-width: 700px;
  height: 240px;
  font-family: 'Open Sans', sans-serif;
  /*box-shadow: 0 5px 5px -3px rgb(0 0 0 / 20%), 0 8px 10px 1px rgb(0 0 0 / 14%), 0 3px 14px 2px rgb(0 0 0 / 12%);*/
  text-align: left;
  position: relative;

  h3 {
    margin: 0;
  }

  @media screen and (max-width: 1250px) {
    width: 100%;
    margin: 20px auto 0;
  }

  @media screen and (max-width: 600px) {
    height: 300px;
    padding: 23px 35px 20px;
  }
`

const ContentWrapper = styled.div`
  width: 100%;
  height: 100%;
`

const Close = styled(X)`
  cursor: pointer;
  height: 22px;
`

const CloseWrapper = styled.div`
  position: absolute;
  top: 15px;
  right: 15px;
`

const PageSelect = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 10px;
  position: relative;
  bottom: 12px;
`

const Dot = styled.div<{ active: boolean }>`
  display: inline-block;
  cursor: pointer;
  height: 6px;
  width: 6px;
  background: ${({ theme, active }) => (active ? theme.text1 : theme.text3)};
  margin: 4px;
  vertical-align: middle;
  border-radius: 6px;
`
//
// const TokenAnimation = keyframes`
//   from {
//     left: 15px;
//     top: 12px;
//   }
//
//   50% {
//     left: 80px;
//     top: 90px;
//   }
//
//   to {
//     left: 15px;
//     top: 10px;
//   }
// `

const animateMoveX = keyframes`
  0% {
    transform: translateX(95px);
  }
  40% {
    transform: translateX(0);
  }
  50% {
    transform: translateX(0);
  }
  90% {
    transform: translateX(95px);
  }
  100% {
    transform: translateX(95px);
  }
`

const animateMoveY = keyframes`
  0% {
    transform: translateY(90px);
  }
  40% {
    transform: translateY(0);
  }
  50% {
    transform: translateY(0);
  }
  90% {
    transform: translateY(90px);
  }
  100% {
    transform: translateY(90px);
  }
`

const InterestAnimationOne = keyframes`
  0% {
    opacity: 1;
  }
  75% {
    transform: translateY(0px);
  }
  95% {
    opacity: 1;
  }
  100% {
    transform: translateY(-35px);
    opacity: 0;
  }
`

const InterestAnimationTwo = keyframes`
  0% {
    opacity: 1;
  }
  50% {
    transform: translateY(0px);
  }
  70% {
    opacity: 1;
  }
  75% {
    transform: translateY(-35px);
    opacity: 0;
  }
  100% {
    opacity: 0;
  }
`

const InterestAnimationThree = keyframes`
  0% {
    opacity: 1;
    transform: translateY(0px);
  }
  20% {
    opacity: 1;  
  }
  25% {
    transform: translateY(-35px);
    opacity: 0;
  }
  100% {
    opacity: 0;
  }
`

const InterestAnimationFour = keyframes`
  0% {
    opacity: 1;
    transform: translateY(0px);
  }
  25% {
    opacity: 1;
    transform: translateY(0px);
  }
  45% {
    opacity: 1;  
  }
  50% {
    transform: translateY(-35px);
    opacity: 0;
  }
  100% {
    opacity: 0;
  }
`

const InterestValue = styled.div`
  position: absolute;
  animation: ${InterestAnimationOne} 5s ease-out infinite;
  z-index: 1;
  top: 2px;
  left: 12px;
  font-size: 18px;
  font-weight: 500;
`

const InterestValueTwo = styled(InterestValue)`
  animation: ${InterestAnimationTwo} 5s ease-out infinite;
  left: 40px;
`

const InterestValueThree = styled(InterestValue)`
  animation: ${InterestAnimationThree} 5s ease-out infinite;
  left: 50px;
`

const InterestValueFour = styled(InterestValue)`
  animation: ${InterestAnimationFour} 5s ease-out infinite;
  left: 0;
`

const InterestColors = styled.div`
  height: 100%;
  width: 80px;
  left: 50%;
  transform: translateX(-50%);
  position: absolute;
`

const GreenAnimation = keyframes`
  0% {
    transform: translateY(-3px);
  }
  25% {
    transform: translateY(0);
  }
  75% {
    transform: translateY(-6px);
  }
  100% {
    transform: translateY(-3px);
  }
`

const BlueAnimation = keyframes`
  0% {
    transform: translateY(0);
  }
  50% {
    transform: translateY(-6px);
  }
  100% {
    transform: translateY(0);
  }
`

const InterestGreen = styled.div`
  position: absolute;
  animation: ${GreenAnimation} 4s ease-out infinite;
  z-index: 1;
  top: 15px;
  font-size: 24px;
  font-weight: 600;
  color: ${({ theme }) => theme.green1};
  width: 100%;
  text-align: center;
`

const InterestBlue = styled.div`
  position: absolute;
  animation: ${BlueAnimation} 4s ease-out infinite;
  z-index: 1;
  top: 72px;
  font-size: 24px;
  font-weight: 600;
  color: ${({ theme }) => theme.blue1};
  width: 100%;
  text-align: center;
`

const Green = styled.span`
  color: ${({ theme }) => theme.green1};
`

const Blue = styled.span`
  color: ${({ theme }) => theme.blue1};
`

const TokenWrapper = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  animation: ${animateMoveY} 5s ease-in infinite;
  animation-iteration-count: infinite;
`

const Token = styled.div`
  position: relative;
  animation: ${animateMoveX} 5s ease-out infinite;
  animation-iteration-count: infinite;
  img {
    width: 30px;
    height: 30px;
    border-radius: 50%;
  }
`

const InterestToken = styled.div<{ x: number; y: number; z: number }>`
  position: absolute;
  top: ${({ y }) => y}px;
  left: ${({ x }) => x}px;
  z-index: ${({ z }) => z};
  img {
    width: 33px;
    height: 33px;
    border-radius: 50%;
  }
`

const AnimationSection = styled.div`
  width: 38.2%;
  height: 120px;
  top: 0;
  left: 0;
  display: inline-block;
  position: relative;
  vertical-align: top;

  @media screen and (max-width: 600px) {
    width: 100%;
  }
`

const AnimationInner = styled.div`
  width: 130px;
  height: 120px;
  left: calc((100% - 130px) / 2);
  position: relative;
`

const TextSection = styled.div`
  width: 61.8%;
  height: 120px;
  display: inline-block;
  vertical-align: top;
  padding: 5px 30px 10px 0;
  position: relative;

  @media screen and (max-width: 600px) {
    width: 100%;
    padding: 10px;
  }
`

const TextInner = styled.div`
  position: relative;
  top: 50%;
  transform: translateY(-50%);
`

const Wallet = styled.span`
  top: -6px;
  left: -18px;
  position: absolute;
  z-index: 2;
  // background-color: ${({ theme }) => theme.bg6};
  width: 75px;
  height: auto;
  transform: rotate(12deg);
  //border-radius: 8px;
  bottom: 5px;

  svg {
    width: 50px;
    height: 50px;
    color: ${({ theme }) => theme.text1};
  }
`

const DoloLogoBackgroundWrapper = styled.div`
  bottom: 0;
  right: 0;
  position: absolute;
  z-index: 2;
`

const DoloLogoBackground = styled.div`
  background-color: ${({ theme }) => theme.bg6};
  width: 56px;
  height: 56px;
  border-radius: 8px;

  img {
    width: 40px;
    height: 40px;
    margin: 8px;
    color: ${({ theme }) => theme.text1};
  }
`

const InterestAnimationBackground = styled.div`
  top: 30px;
  height: 100%;
  left: 50%;
  transform: translateX(-50%);
  position: absolute;

  @media screen and (max-width: 600px) {
    top: 5px;
  }
`

const DoloLogoBackgroundInterest = styled(DoloLogoBackground)`
  width: 86px;
  height: 86px;
  position: relative;
  top: 20px;
  z-index: 5;

  img {
    width: 66px;
    height: 66px;
    margin: 10px;
    color: ${({ theme }) => theme.text1};
  }
`

const Welcome = styled.div`
  text-align: center;
  font-size: 18px;
  font-weight: 300;
`

const ArrowWrapper = styled.div`
  cursor: pointer;
  transition: transform 0.2s ease-in-out;
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  svg {
    color: ${({ theme }) => theme.text3};
    transform: scaleY(2.2) scaleX(1.4);
  }
`

const ArrowRight = styled(ArrowWrapper)`
  right: 15px;

  :hover {
    right: 12px;
  }
  transition: right 0.2s ease-in-out;
`

const ArrowLeft = styled(ArrowWrapper)`
  left: 15px;
  :hover {
    left: 12px;
  }
  transition: left 0.2s ease-in-out;
`

const Title = styled.div`
  font-size: 16px;
  text-align: left;
  margin-bottom: 10px;
  font-weight: 400;
  @media screen and (max-width: 600px) {
    text-align: center;
  }
`

const Explanation = styled.div`
  font-size: 16px;
  margin-bottom: 10px;
  text-align: left;
  font-weight: 300;

  @media screen and (max-width: 600px) {
    text-align: center;
  }

  @media screen and (max-width: 500px) {
    font-size: 14px;
  }
`

const MobileText = styled.span`
  @media screen and (min-width: 500px) {
    display: none;
  }
`

const DesktopText = styled.span`
  @media screen and (max-width: 500px) {
    display: none;
  }
`

const SwapButton = styled.div`
  width: fit-content;
  text-align: center;
  margin-top: 4px;
  padding: 7px 12px;
  height: 33px;
  border-radius: 5px;
  color: ${({ theme }) => theme.text1};
  background-color: ${({ theme }) => theme.blue1};
  font-weight: 500;
  font-size: 14px;
  display: inline-block;
  text-decoration: none;
  svg {
    color: ${({ theme }) => theme.text1};
    margin-right: -5px;
    margin-left: -5px;
    margin-top: -2px;
  }
`

const ButtonText = styled.div`
  display: inline-block;
  vertical-align: top;
`

const SwapDoloLogoBackgroundWrapper = styled.div`
  top: 0;
  left: 0;
  position: absolute;
  z-index: 2;
`

const AnimationInnerSwap = styled.div`
  width: 160px;
  height: 120px;
  left: calc((100% - 160px) / 2);
  position: relative;
`

const animateMoveXOne = keyframes`
  0% {
    transform: translateX(-110px);
  }
  20% {
    transform: translateX(0);
  }
  25% {
    transform: translateX(0);
  }
  75% {
    transform: translateX(0);
  }
  95% {
    transform: translateX(-110px);
  }
  100% {
    transform: translateX(-110px);
  }
`

const animateMoveYOne = keyframes`
  0% {
    transform: translateY(-88px);
  }
  20% {
    transform: translateY(0);
  }
  25% {
    transform: translateY(0);
  }
  75% {
    transform: translateX(0);
  }
  95% {
    transform: translateY(-88px);
  }
  100% {
    transform: translateY(-88px);
  }
`

const animateMoveXTwo = keyframes`
  0% {
    transform: translateX(0);
  }
  25% {
    transform: translateX(0);
  }
  45% {
    transform: translateX(-110px);
  }
  50% {
    transform: translateX(-110px);
  }
  70% {
    transform: translateX(0);
  }
  75% {
    transform: translateX(0);
  }
  100% {
    transform: translateX(0);
  }
`

const animateMoveYTwo = keyframes`
  0% {
    transform: translateY(0);
  }
  25% {
    transform: translateY(0);
  }
  45% {
    transform: translateY(-88px);
  }
  50% {
    transform: translateY(-88px);
  }
  70% {
    transform: translateY(0);
  }
  75% {
    transform: translateY(0);
  }
  100% {
    transform: translateY(0);
  }
`

const TokenWrapperOne = styled.div`
  position: absolute;
  top: 88px;
  left: 110px;
  animation: ${animateMoveYOne} 7s ease-in infinite;
  animation-iteration-count: infinite;
`

const TokenWrapperTwo = styled.div`
  position: absolute;
  top: 88px;
  left: 110px;
  animation: ${animateMoveYTwo} 7s ease-in infinite;
  animation-iteration-count: infinite;
`

const TokenOne = styled.div`
  position: relative;
  animation: ${animateMoveXOne} 7s ease-out infinite;
  animation-iteration-count: infinite;
  img {
    width: 30px;
    height: 30px;
    border-radius: 50%;
  }
`

const TokenTwo = styled(TokenOne)`
  animation: ${animateMoveXTwo} 7s ease-out infinite;
`

const TooltipText = styled.div`
  font-family: 'Open Sans', sans-serif !important;
  font-size: 13px;
  color: #f9f9f9;
  padding: 12px 16px;
  width: 175px;
  animation: ${BlueAnimation} 2s ease-out infinite;
  background-color: #3d3e54 !important;
  border-radius: 5px;
  margin: 0 auto;
  box-shadow: 0 5px 5px -3px rgb(0 0 0 / 20%), 0 8px 10px 1px rgb(0 0 0 / 14%), 0 3px 14px 2px rgb(0 0 0 / 12%);
`

const TooltipLine = styled.div`
  margin: 2px 0;
  width: 100%;
`

const TooltipLineTitle = styled.span`
  font-weight: 400;
`

const TooltipLineValue = styled.span`
  font-weight: 600;
  color: ${({ theme }) => theme.green1};
  float: right;
  margin-left: 10px;
`

const Tooltip = styled.div`
  background-color: #3d3e54 !important;
  box-shadow: 0 5px 5px -3px rgb(0 0 0 / 20%), 0 8px 10px 1px rgb(0 0 0 / 14%), 0 3px 14px 2px rgb(0 0 0 / 12%);
  animation: ${BlueAnimation} 3s ease-out infinite;
  border-radius: 5px;
  width: 170px;
  margin: 0 auto;
  padding: 0 10px;
`
const InterestTooltip = styled.div`
  height: 30px;
  width: 70px;
  left: 50%;
  transform: translateX(-50%);
  top: 90px;
  position: absolute;
`

const InterestBlueTooltip = styled(InterestBlue)`
  animation: none;
  /*animation: ${BlueAnimation} 2s ease-out infinite;*/
  top: 0;
`

const InterestTooltipValueAnimationOne = keyframes`
  0% {
    transform: translateY(0);
  }
  50% {
    transform: translateY(2px);
  }
  100% {
    transform: translateY(0);
  }
`

const InterestTooltipValueAnimationTwo = keyframes`
  0% {
    transform: translateY(1px);
  }
  25% {
    transform: translateY(0);
  }
  75% {
    transform: translateY(2px);
  }
  100% {
    transform: translateY(1px);
  }
`

const InterestTooltipValue = styled.div`
  /*animation: ${InterestTooltipValueAnimationOne} 2s ease-out infinite;*/
  top: 17px;
  left: 120px;
  z-index: 1;
  position: absolute;
  animation-iteration-count: infinite;
`

const InterestTooltipTwoValue = styled(InterestTooltipValue)`
  /*animation: ${InterestTooltipValueAnimationTwo} 2s ease-out infinite;*/
  top: 34px;
  left: 120px;
  z-index: 1;
  position: absolute;
  animation-iteration-count: infinite;
`

const ServiceDoloLogoBackgroundWrapper = styled.div`
  top: 40px;
  left: 0;
  position: absolute;
  z-index: 2;
`

const BorrowBackgroundWrapper = styled.div`
  top: 0;
  right: 0;
  position: absolute;
  z-index: 2;
`

const StrategiesBackgroundWrapper = styled.div`
  bottom: 0;
  right: 0;
  position: absolute;
  z-index: 2;
`

const AnimationInnerServices = styled(AnimationInnerSwap)`
  width: 180px;
  height: 140px;
  top: -5px;
  left: calc((100% - 180px) / 2);
  position: relative;
`

const animateXServiceOne = keyframes`
    0% {
        transform: translateX(0);
    }
    20% {
        transform: translateX(110px);
    }
    25% {
        transform: translateX(110px);
    }
    45% {
        transform: translateX(0);
    }
    50% {
        transform: translateX(0);
    }
    100% {
        transform: translateX(0);
  }
`

const animateYServiceOne = keyframes`
    0% {
        transform: translateY(0);
    }
    20% {
        transform: translateY(-40px);
    }
    25% {
        transform: translateY(-40px);
    }
    45% {
        transform: translateY(0);
    }
    50% {
        transform: translateY(0);
    }
    100% {
        transform: translateY(0);
    }
`

const animateXServiceTwo = keyframes`
  0% {
    transform: translateX(0);
  }
  25% {
    transform: translateX(0);
  }
  45% {
    transform: translateX(110px);
  }
  50% {
    transform: translateX(110px);
  }
  70% {
    transform: translateX(0);
  }
  75% {
    transform: translateX(0);
  }
  100% {
    transform: translateX(0);
  }
`

const animateYServiceTwo = keyframes`
  0% {
    transform: translateY(0);
  }
  25%{
    transform: translateY(0);
  }
  45% {
    transform: translateY(40px);
  }
  50% {
    transform: translateY(40px);
  }
  70% {
    transform: translateY(0);
  }
  75% {
    transform: translateY(0);
  }
  100% {
    transform: translateY(0);
  }
`
const animateXServiceThree = keyframes`
    0% {
        transform: translateX(0);
    }
    50% {
        transform: translateX(0);
    }
    70% {
        transform: translateX(110px);
    }
    75% {
        transform: translateX(110px);
    }
    95% {
        transform: translateX(0);
    }
    100% {
        transform: translateX(0);
  }
`

const animateYServiceThree = keyframes`
    0% {
        transform: translateY(0);
    }
    50% {
        transform: translateY(0);
    }
    70% {
        transform: translateY(-40px);
    }
    75% {
        transform: translateY(-40px);
    }
    95% {
        transform: translateY(0);
    }
    100% {
        transform: translateY(0);
    }
`

const animateXServiceFour = keyframes`
    0% {
        transform: translateX(110px);
    }
    20% {
        transform: translateX(0);
    }
    25% {
        transform: translateX(0);
    }
    75% {
        transform: translateX(0);
    }
    95% {
        transform: translateX(110px);
    }
    100% {
        transform: translateX(110px);
    }
`

const animateYServiceFour = keyframes`
    0% {
        transform: translateY(40px);
    }
    20% {
        transform: translateY(0);
    }
    25% {
        transform: translateY(0);
    }
    75% {
        transform: translateY(0);
    }
    95% {
        transform: translateY(40px);
    }
    100% {
        transform: translateY(40px);
  }
`

const TokenServiceWrapperOne = styled.div`
  position: absolute;
  top: 45px;
  left: 15px;
  animation: ${animateXServiceOne} 6s ease-in infinite;
`

const TokenServiceWrapperTwo = styled(TokenServiceWrapperOne)`
  top: 60px;
  animation: ${animateXServiceTwo} 6s ease-in infinite;
  animation-delay: 2s;
`

const TokenServiceWrapperThree = styled(TokenServiceWrapperOne)`
  top: 45px;
  animation: ${animateXServiceThree} 6s ease-in infinite;
`

const TokenServiceWrapperFour = styled(TokenServiceWrapperTwo)`
  animation: ${animateXServiceFour} 6s ease-in infinite;
  animation-delay: 2s;
`

const TokenServiceOne = styled.div`
  position: relative;
  animation: ${animateYServiceOne} 6s ease-out infinite;
  animation-iteration-count: infinite;
  img {
    width: 30px;
    height: 30px;
    border-radius: 50%;
  }
`

const TokenServiceTwo = styled(TokenServiceOne)`
  animation: ${animateYServiceTwo} 6s ease-out infinite;
  animation-delay: 2s;
`

const TokenServiceThree = styled(TokenServiceOne)`
  animation: ${animateYServiceThree} 6s ease-out infinite;
`

const TokenServiceFour = styled(TokenServiceOne)`
  animation: ${animateYServiceFour} 6s ease-out infinite;
  animation-delay: 2s;
`
const ServiceWrapper = styled.div`
  height: 56px;

  img {
    height: 56px;
  }
`

function Balances() {
  return (
    <ContentWrapper>
      <AnimationSection>
        <AnimationInner>
          <Wallet>
            <img alt={'Wallet Temp'} src={WalletGraphic} />
          </Wallet>
          <TokenWrapper>
            <Token>
              <img alt={'ETH logo'} src={ETHLogo} />
            </Token>
          </TokenWrapper>
          <DoloLogoBackgroundWrapper>
            <DoloLogoBackground>
              <img alt={'Dolomite Logo'} src={DolomiteLogo} />
            </DoloLogoBackground>
          </DoloLogoBackgroundWrapper>
        </AnimationInner>
      </AnimationSection>
      <TextSection>
        <TextInner>
          <Title>Welcome to the Balances Page!</Title>
          <Explanation>Here you can deposit to and withdraw from your Dolomite Balance</Explanation>
        </TextInner>
      </TextSection>
    </ContentWrapper>
  )
}

function EarnInterest() {
  return (
    <ContentWrapper>
      <AnimationSection>
        <InterestAnimationBackground>
          <InterestValue>
            <Green>+2%</Green>
          </InterestValue>
          <InterestValueTwo>
            <Blue>+5%</Blue>
          </InterestValueTwo>
          <InterestValueThree>
            <Blue>+8%</Blue>
          </InterestValueThree>
          <InterestValueFour>
            <Green>+3%</Green>
          </InterestValueFour>
          <InterestToken x={0} y={1} z={1}>
            <img alt={'USDC logo'} src={USDCLogo} />
          </InterestToken>
          <InterestToken x={18} y={7} z={2}>
            <img alt={'BTC logo'} src={DAILogo} />
          </InterestToken>
          <InterestToken x={36} y={-3} z={2}>
            <img alt={'ETH logo'} src={ETHLogo} />
          </InterestToken>
          <InterestToken x={52} y={3} z={1}>
            <img alt={'DAI logo'} src={BTCLogo} />
          </InterestToken>
          <DoloLogoBackgroundInterest>
            <img alt={'Dolomite Logo'} src={DolomiteLogo} />
          </DoloLogoBackgroundInterest>
        </InterestAnimationBackground>
      </AnimationSection>
      <TextSection>
        <TextInner>
          <Explanation>Assets in your Dolomite Balance earn interest</Explanation>
          <Explanation>
            Most interest will increase your balance, but some assets may earn interest by appreciating in value
          </Explanation>
        </TextInner>
      </TextSection>
    </ContentWrapper>
  )
}

function InterestRateColor() {
  return (
    <ContentWrapper>
      <AnimationSection>
        <InterestColors>
          <InterestGreen>5.67%</InterestGreen>
          <InterestBlue>14.73%</InterestBlue>
        </InterestColors>
      </AnimationSection>
      <TextSection>
        <TextInner>
          <Explanation>Rates shown in green earn interest from lending</Explanation>
          <Explanation>Rates in blue earn outside yield and rewards, and may earn lending yield as well</Explanation>
        </TextInner>
      </TextSection>
    </ContentWrapper>
  )
}

function InterestRateTooltip() {
  return (
    <ContentWrapper>
      <AnimationSection>
        <TooltipText>
          <TooltipLine>
            <TooltipLineTitle>Protocol Yield:</TooltipLineTitle>
            <TooltipLineValue>
              <InterestTooltipValue>6.54%</InterestTooltipValue>
            </TooltipLineValue>
          </TooltipLine>
          <TooltipLine>
            <TooltipLineTitle>Lending Yield:</TooltipLineTitle>
            <TooltipLineValue>
              <InterestTooltipTwoValue>8.19%</InterestTooltipTwoValue>
            </TooltipLineValue>
          </TooltipLine>
          <TooltipLine>
            <TooltipLineTitle>+Points</TooltipLineTitle>
          </TooltipLine>
        </TooltipText>
        <InterestTooltip>
          <InterestBlueTooltip>14.73%</InterestBlueTooltip>
        </InterestTooltip>
      </AnimationSection>
      <TextSection>
        <TextInner>
          <Explanation>
            <MobileText>Hold your finger on</MobileText>
            <DesktopText>Hover your mouse over</DesktopText> a blue rate to see a breakdown of yield sources and other
            rewards
          </Explanation>
        </TextInner>
      </TextSection>
    </ContentWrapper>
  )
}

function Swap() {
  return (
    <ContentWrapper>
      <AnimationSection>
        <AnimationInnerSwap>
          <SwapDoloLogoBackgroundWrapper>
            <DoloLogoBackground>
              <img alt={'Dolomite Logo'} src={DolomiteLogo} />
            </DoloLogoBackground>
          </SwapDoloLogoBackgroundWrapper>
          <TokenWrapperOne>
            <TokenOne>
              <img alt={'ETH logo'} src={ETHLogo} />
            </TokenOne>
          </TokenWrapperOne>
          <TokenWrapperTwo>
            <TokenTwo>
              <img alt={'BTC logo'} src={BTCLogo} />
            </TokenTwo>
          </TokenWrapperTwo>
          <DoloLogoBackgroundWrapper>
            <SwapButton>
              <ButtonText>Swap</ButtonText> <BoltIcon />
            </SwapButton>
          </DoloLogoBackgroundWrapper>
        </AnimationInnerSwap>
      </AnimationSection>
      <TextSection>
        <TextInner>
          <Explanation>
            You can use Zap - Dolomite&apos;s meta-aggregator - to swap between assets in your Dolomite Balance
          </Explanation>
        </TextInner>
      </TextSection>
    </ContentWrapper>
  )
}

function Services() {
  return (
    <ContentWrapper>
      <AnimationSection>
        <AnimationInnerServices>
          <ServiceDoloLogoBackgroundWrapper>
            <DoloLogoBackground>
              <img alt={'Dolomite Logo'} src={DolomiteLogo} />
            </DoloLogoBackground>
          </ServiceDoloLogoBackgroundWrapper>
          <TokenServiceWrapperOne>
            <TokenServiceOne>
              <img alt={'ETH logo'} src={ETHLogo} />
            </TokenServiceOne>
          </TokenServiceWrapperOne>
          <TokenServiceWrapperTwo>
            <TokenServiceTwo>
              <img alt={'BTC logo'} src={BTCLogo} />
            </TokenServiceTwo>
          </TokenServiceWrapperTwo>
          <TokenServiceWrapperThree>
            <TokenServiceThree>
              <img alt={'DAI logo'} src={DAILogo} />
            </TokenServiceThree>
          </TokenServiceWrapperThree>
          <TokenServiceWrapperFour>
            <TokenServiceFour>
              <img alt={'USDC logo'} src={USDCLogo} />
            </TokenServiceFour>
          </TokenServiceWrapperFour>
          <BorrowBackgroundWrapper>
            <ServiceWrapper>
              <img alt={'Borrow Logo'} src={BorrowGraphic} />
            </ServiceWrapper>
          </BorrowBackgroundWrapper>
          <StrategiesBackgroundWrapper>
            <ServiceWrapper>
              <img alt={'Strategies Logo'} src={StrategiesGraphic} />
            </ServiceWrapper>
          </StrategiesBackgroundWrapper>
        </AnimationInnerServices>
      </AnimationSection>
      <TextSection>
        <TextInner>
          <Explanation>
            Use assets in your Dolomite balance for other Dolomite services such as Borrowing or Strategies
          </Explanation>
        </TextInner>
      </TextSection>
    </ContentWrapper>
  )
}
const pages = [
  <Balances key={'balances-info'} />,
  <EarnInterest key={'earn-info'} />,
  <InterestRateColor key={'color-info'} />,
  <InterestRateTooltip key={'tooltip-info'} />,
  <Swap key={'swap-info'} />,
  <Services key={'services-info'} />,
]

export default function Info({ setHasClosedInfo }: { setHasClosedInfo: () => void }) {
  const [page, setPage] = useState(0)
  const end = 5

  return (
    <Modal isOpen={true} onDismiss={setHasClosedInfo} minWidthPx={300} maxWidthPx={600}>
      <InfoWrapper>
        {pages[page]}
        {page > 0 && (
          <ArrowLeft onClick={() => setPage(page - 1)}>
            <KeyboardArrowLeftIcon />
          </ArrowLeft>
        )}
        {page < end && (
          <ArrowRight onClick={() => setPage(page + 1)}>
            <KeyboardArrowRightIcon />
          </ArrowRight>
        )}
        <PageSelect>
          {[...Array(Object.keys(pages).length)].map((_, i) => (
            <Dot key={i} active={i === page} onClick={() => setPage(i)} />
          ))}
        </PageSelect>
        <CloseWrapper>
          <Close onClick={() => setHasClosedInfo()} />
        </CloseWrapper>
      </InfoWrapper>
    </Modal>
  )
}
