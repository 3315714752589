import React from 'react'
import styled from 'styled-components/macro'
import { useActiveWeb3React } from '../../hooks'
import DolomiteLogo from '../../assets/dolomite-logo-white.svg'
import CircularProgress from '@material-ui/core/CircularProgress'
import Web3StatusConnectButton from '../../components/Web3StatusConnectButton'
import { ChainId } from '../../constants'
import { formatAmount } from '../../utils/formatAmount'
import HourglassEmptyIcon from '@mui/icons-material/HourglassEmpty'
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined'
import AutoAwesomeIcon from '@mui/icons-material/AutoAwesome'
import Twitter from '@mui/icons-material/Twitter'
import Discord from '@mui/icons-material/Forum'

const PageWrapper = styled.div`
  width: 100%;
  max-width: 1200px;
  margin: 0 auto;
  padding: 32px 20px 60px;
  text-align: center;

  @media (max-width: 900px) {
    padding-bottom: 120px;
  }
`

const Title = styled.h1`
  font-size: 42px;
  font-weight: 500;
  margin-bottom: 16px;
  color: ${({ theme }) => theme.text1};
`

const Subtitle = styled.div`
  font-size: 17px;
  color: ${({ theme }) => theme.text2};
  max-width: 720px;
  margin: 0 auto 32px;
  line-height: 1.5;
`

const SocialLinks = styled.div`
  display: flex;
  justify-content: center;
  gap: 16px;
  margin-bottom: 48px;

  @media (max-width: 600px) {
    flex-direction: column;
    padding: 0 20px;
    gap: 12px;
  }
`

const SocialLink = styled.a`
  display: flex;
  align-items: center;
  gap: 8px;
  color: ${({ theme }) => theme.text1};
  text-decoration: none;
  font-size: 15px;
  padding: 10px 20px;
  border-radius: 8px;
  background: ${({ theme }) => theme.bg2};
  transition: all 0.2s ease;
  font-weight: 500;
  justify-content: center;

  &:hover {
    background: ${({ theme }) => theme.bg3};
    transform: translateY(-2px);
  }

  svg {
    font-size: 18px;
  }

  @media (max-width: 600px) {
    width: 100%;
  }
`

const InfoCard = styled.div<{ prominent?: boolean }>`
  ${({ prominent, theme }) =>
    prominent
      ? `
    background: ${theme.bg1};
    text-align: left;
    border-radius: 8px;
    padding: 36px 40px;
    margin: 0 0 48px;
    box-shadow: 0 5px 5px -3px rgb(0 0 0 / 20%), 0 8px 10px 1px rgb(0 0 0 / 14%), 0 3px 14px 2px rgb(0 0 0 / 12%);
    border: 1px solid ${theme.blue1};

    @media (max-width: 600px) {
      padding: 24px 20px;
      margin: 0 0 32px;
    }
  `
      : ''}
`

const InfoCardTitle = styled.div<{ prominent?: boolean }>`
  font-size: ${({ prominent }) => (prominent ? '22px' : '20px')};
  font-weight: 500;
  margin-bottom: ${({ prominent }) => (prominent ? '16px' : '12px')};
  display: flex;
  align-items: center;
  gap: 10px;
  color: ${({ theme }) => theme.text1};
  ${({ prominent }) => !prominent && 'justify-content: center;'}

  svg {
    color: ${({ theme }) => theme.blue1};
    font-size: ${({ prominent }) => (prominent ? '24px' : '22px')};
  }
`

const InfoCardContent = styled.div<{ prominent?: boolean }>`
  font-size: ${({ prominent }) => (prominent ? '15px' : '15px')};
  line-height: 1.6;
  color: ${({ theme }) => theme.text2};
  ${({ prominent }) => !prominent && 'text-align: center;'}
  ${({ prominent }) => prominent && 'padding-left: 34px;'}
`

const MainContent = styled.div`
  display: grid;
  grid-template-columns: minmax(380px, 460px) 1fr;
  gap: 48px;
  margin: 40px 0;
  align-items: start;

  @media (max-width: 900px) {
    grid-template-columns: 1fr;
    gap: 32px;
    grid-template-areas:
      'info'
      'claim';
  }
`

const InfoCards = styled.div`
  display: flex;
  flex-direction: column;
  gap: 40px;
  padding: 0 20px;

  @media (max-width: 900px) {
    grid-area: info;
  }
`

const ClaimPanel = styled.div`
  background: ${({ theme }) => theme.bg1};
  border-radius: 8px;
  padding: 36px;
  position: relative;
  box-shadow: 0 5px 5px -3px rgb(0 0 0 / 20%), 0 8px 10px 1px rgb(0 0 0 / 14%), 0 3px 14px 2px rgb(0 0 0 / 12%);
  height: 100%;
  min-height: 320px;
  display: flex;
  flex-direction: column;

  @media (max-width: 900px) {
    grid-area: claim;
  }

  @media (max-width: 600px) {
    padding: 24px 20px;
    min-height: 300px;
  }
`

const DisabledOverlay = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: ${({ theme }) => `${theme.bg1}B3`}; // Slightly more transparent
  border-radius: 8px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 15px;
  font-weight: 500;
  color: ${({ theme }) => theme.text1};
  backdrop-filter: blur(1px);
  z-index: 2;

  svg {
    margin-right: 8px;
    font-size: 18px;
  }

  @media (max-width: 600px) {
    font-size: 14px;
  }
`

const ClaimContent = styled.div`
  position: relative;
  z-index: 1;
  display: flex;
  flex-direction: column;
  height: 100%;
  justify-content: space-between;
`

const ClaimTitle = styled.h3`
  margin: 0 0 24px;
  font-size: 22px;
  font-weight: 500;
  color: ${({ theme }) => theme.text1};
  text-align: center;
`

const ClaimAmount = styled.div`
  font-size: 48px;
  font-weight: 300;
  margin: 16px 0 24px;
  color: ${({ theme }) => theme.text1};
  text-align: center;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 12px;
`

const TokenIcon = styled.img`
  width: 36px;
  height: 36px;
`

const ClaimButton = styled.button`
  width: 100%;
  padding: 14px;
  border-radius: 8px;
  background: ${({ theme }) => theme.bg4};
  color: ${({ theme }) => theme.text1};
  border: none;
  font-size: 15px;
  font-weight: 600;
  cursor: not-allowed;
  opacity: 0.7;
  margin-top: auto;
`

const ClaimExplainer = styled.div`
  text-align: center;
  color: ${({ theme }) => theme.text2};
  font-size: 14px;
  line-height: 1.5;
  margin: 0 auto 24px;
  max-width: 280px;
`

const VestingLink = styled.span`
  color: ${({ theme }) => theme.blue1};
  cursor: not-allowed;
`

export default function Boyco() {
  const { account, chainId } = useActiveWeb3React()
  const isOnWrongNetwork = chainId !== ChainId.BERACHAIN

  return (
    <PageWrapper>
      <Title>Boyco veDOLO Distribution</Title>
      <Subtitle>
        {`We're currently processing data from Royco to determine veDOLO allocations for Dolomite Boyco market
        participants. Check back soon to see your allocation!`}
      </Subtitle>

      <SocialLinks>
        <SocialLink href='https://x.com/dolomite_io' target='_blank' rel='noopener noreferrer'>
          <Twitter /> Follow for Updates
        </SocialLink>
        <SocialLink href='https://discord.gg/uDRzrB2YgP' target='_blank' rel='noopener noreferrer'>
          <Discord /> Join Discord
        </SocialLink>
      </SocialLinks>

      <InfoCard prominent>
        <InfoCardTitle prominent>
          <InfoOutlinedIcon /> External Vault Participants
        </InfoCardTitle>
        <InfoCardContent prominent>
          If you participated in Boyco through an external vault (such as EtherFi, StakeStone, Lombard, Concrete, etc),
          your veDOLO will be calculated and distributed differently. At TGE, veDOLO will be sent to these vaults, and
          each vault will determine their own distribution method for their participants.
          <br />
          <br />
          {`Stay tuned to your vault's communication channels for specific details about how they will handle the
          distribution of veDOLO to their users.`}
        </InfoCardContent>
      </InfoCard>

      <MainContent>
        <ClaimPanel>
          <DisabledOverlay>
            <HourglassEmptyIcon /> Allocation Data Coming Soon
          </DisabledOverlay>
          <ClaimContent>
            <div>
              <ClaimTitle>Your veDOLO Allocation</ClaimTitle>
              <ClaimAmount>
                <TokenIcon src={DolomiteLogo} alt='veDOLO' />
                {formatAmount(undefined, 2, true, '-')}
              </ClaimAmount>
              <ClaimExplainer>
                After claiming, your veDOLO will appear on the <VestingLink>Vesting Page</VestingLink> as a 2 year lock
              </ClaimExplainer>
            </div>
            {account ? <ClaimButton disabled>Claim veDOLO</ClaimButton> : <Web3StatusConnectButton />}
          </ClaimContent>
        </ClaimPanel>

        <InfoCards>
          <InfoCard>
            <InfoCardTitle>
              <AutoAwesomeIcon /> {`What's Happening Now`}
            </InfoCardTitle>
            <InfoCardContent>
              {`We're carefully analyzing participation data from the Dolomite Boyco markets on Royco to ensure accurate
              veDOLO allocations. Once complete, you'll be able to see your earned veDOLO amount right here on this page.`}
            </InfoCardContent>
          </InfoCard>

          <InfoCard>
            <InfoCardTitle>
              <InfoOutlinedIcon /> Important Timeline
            </InfoCardTitle>
            <InfoCardContent>
              {`While you'll soon be able to view your veDOLO allocation, claiming will only become available after the
              Token Generation Event (TGE). We'll announce the exact date through our official channels.`}
            </InfoCardContent>
          </InfoCard>
        </InfoCards>
      </MainContent>
    </PageWrapper>
  )
}
