import { CurrencyAmount, Token } from '@dolomite-exchange/v2-sdk'
import { address } from '@dolomite-exchange/dolomite-margin/dist/src/types'
import { ChainId } from '../constants'
import { useStoredTokenTvlData } from '../state/data/hooks'
import { useActiveWeb3React } from '../hooks'

export interface TokenTvl {
  address: string
  supplyLiquidity: CurrencyAmount<Token>
  borrowLiquidity: CurrencyAmount<Token>
}

// const DOLOMITE_MARGIN_TOKENS_TVL_GQL = gql`
//   query tokenTvls($blockNumber: Int!) {
//     tokens(block: { number_gte: $blockNumber }) {
//       id
//       supplyLiquidity
//       borrowLiquidity
//     }
//   }
// `
//
// interface TokenTvlGql {
//   id: string
//   supplyLiquidity: string
//   borrowLiquidity: string
// }
//
// interface TokenTvlResponse {
//   tokens: TokenTvlGql[]
// }
//
// export function useDolomiteMarginTokenTvlData(
//   urlChain?: ChainId,
// ): {
//   loading: boolean
//   error: boolean
//   data: Record<address, TokenTvl | undefined>
// } {
//   const queryState = useGraphqlResult<TokenTvlResponse>(
//     GraphqlClientType.Dolomite,
//     DOLOMITE_MARGIN_TOKENS_TVL_GQL.loc!.source.body,
//     NO_VARIABLES,
//     RefreshFrequency.Slow,
//     urlChain,
//   )
//   const tokenMap = useAllTokens()
//
//   return useMemo(() => {
//     const { loading, error, result } = queryState
//     const anyLoading = Boolean(loading)
//     const anyError = Boolean(error)
//
//     const tokens = (result?.tokens ?? []).reduce<Record<string, TokenTvl>>((memo, gql) => {
//       const tokenAddress = toChecksumAddress(gql.id)
//       const token = tokenMap[tokenAddress]
//       if (token) {
//         memo[tokenAddress] = {
//           address: tokenAddress,
//           supplyLiquidity: reqParseAmount(gql.supplyLiquidity, token),
//           borrowLiquidity: reqParseAmount(gql.borrowLiquidity, token),
//         }
//       }
//       return memo
//     }, {})
//
//     return {
//       loading: anyLoading,
//       error: anyError,
//       data: tokens,
//     }
//   }, [queryState, tokenMap])
// }

export function useDolomiteMarginTokenTvlData(urlChain?: ChainId): Record<address, TokenTvl | undefined> {
  const { chainId } = useActiveWeb3React()
  return useStoredTokenTvlData()[urlChain ?? chainId]
}
