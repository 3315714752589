import { useMemo } from 'react'
import { NO_VARIABLES, useGraphqlResult } from '../state/graphql/hooks'
import { GraphqlClientType } from '../state/graphql/actions'
import { START_BLOCK_MAP } from '../apollo/client'
import { useActiveWeb3React } from '../hooks'
import { RefreshFrequency, useBlockNumberForSubgraph } from '../state/chain/hooks'
import { DOLOMITE_API_SERVER_URL } from '@dolomite-exchange/zap-sdk'

interface BlocksGql {
  id: string
  number: string
  timestamp: string
}

interface BlockResponse {
  blocks: BlocksGql[]

  [key: string]: BlocksGql[]
}

export interface EthereumBlock {
  blockNumber: number
  timestamp: number
}

export function useBlockNumberByTimestampLowerBound(): {
  loading: boolean
  error: boolean
  data: EthereumBlock | undefined
} {
  const { chainId } = useActiveWeb3React()
  const queryState = useGraphqlResult<BlockResponse>(
    GraphqlClientType.Fetch,
    `${DOLOMITE_API_SERVER_URL}/blocks/${chainId}/yesterday-block-number`,
    NO_VARIABLES,
    RefreshFrequency.Medium,
  )

  const block = useMemo(() => {
    return (queryState.result?.blocks ?? []).map<EthereumBlock>(block => {
      return {
        blockNumber: parseInt(block.number),
        timestamp: parseInt(block.timestamp),
      }
    })[0]
  }, [queryState.result])

  return useMemo(() => {
    return {
      loading: queryState.loading,
      error: queryState.error,
      data: block,
    }
  }, [queryState.loading, queryState.error, block])
}

const daysBackward = 90
const days = Array.from(Array(daysBackward).keys())

export function useDailyBlockNumbersByTimestamps(): {
  loading: boolean
  error: boolean
  data: EthereumBlock[]
} {
  const { chainId } = useActiveWeb3React()

  const queryState = useGraphqlResult<BlockResponse>(
    GraphqlClientType.Fetch,
    `${DOLOMITE_API_SERVER_URL}/blocks/${chainId}/daily-block-numbers`,
    NO_VARIABLES,
    RefreshFrequency.Medium,
  )

  const blockNumber = useBlockNumberForSubgraph()
  const defaultResponse = useMemo(() => {
    return days.map(() => ({
      blockNumber: 0,
      timestamp: 0,
    }))
  }, [])

  const blocks = useMemo(() => {
    if (!queryState.result) {
      return defaultResponse
    }

    const blocks = days.reduce<EthereumBlock[]>((acc1, _, i) => {
      const block = (queryState.result?.[`day_${i}`] ?? []).reduce<EthereumBlock[]>((acc2, block) => {
        if (block.number && block.timestamp && parseInt(block.number) >= START_BLOCK_MAP[chainId]) {
          acc2.push({
            blockNumber: parseInt(block.number),
            timestamp: parseInt(block.timestamp),
          })
        }
        return acc2
      }, [])[0]
      if (block) {
        acc1.push(block)
      }
      return acc1
    }, [])
    blocks.push({
      blockNumber,
      timestamp: Math.floor(Date.now() / 1000),
    })
    return blocks
  }, [chainId, blockNumber, defaultResponse, queryState.result])

  return useMemo(() => {
    return {
      loading: queryState.loading,
      error: queryState.error,
      data: blocks,
    }
  }, [queryState.loading, queryState.error, blocks])
}

const hoursBackward: number = 24
const hours = Array.from(Array(hoursBackward).keys())

export function useHourlyBlockNumbersByTimestamps(
  pushLatestBlock: boolean,
): {
  loading: boolean
  error: boolean
  data: EthereumBlock[]
} {
  const { chainId } = useActiveWeb3React()

  const queryState = useGraphqlResult<BlockResponse>(
    GraphqlClientType.Fetch,
    `${DOLOMITE_API_SERVER_URL}/blocks/${chainId}/hourly-block-numbers`,
    NO_VARIABLES,
    RefreshFrequency.Slow,
  )

  const blockNumber = useBlockNumberForSubgraph()
  const defaultResponse = useMemo(() => {
    return hours.map(() => ({
      blockNumber: 0,
      timestamp: 0,
    }))
  }, [])

  const blocks = useMemo(() => {
    if (!queryState.result) {
      return defaultResponse
    }

    const blocks = hours.reduce<EthereumBlock[]>((acc1, _, i) => {
      const block = (queryState.result?.[`hour_${i}`] ?? []).reduce<EthereumBlock[]>((acc2, block) => {
        if (block.number && block.timestamp && parseInt(block.number) >= START_BLOCK_MAP[chainId]) {
          acc2.push({
            blockNumber: parseInt(block.number),
            timestamp: parseInt(block.timestamp),
          })
        }
        return acc2
      }, [])[0]
      if (block) {
        acc1.push(block)
      }
      return acc1
    }, [])

    if (pushLatestBlock) {
      blocks.push({
        blockNumber,
        timestamp: Math.floor(Date.now() / 1000),
      })
    }
    return blocks
  }, [pushLatestBlock, chainId, blockNumber, defaultResponse, queryState.result])

  return useMemo(() => {
    return {
      loading: queryState.loading,
      error: queryState.error,
      data: blocks,
    }
  }, [queryState.loading, queryState.error, blocks])
}
