import { useState, useEffect, useRef } from 'react'

function useThrottle<T>(value: T, delay: number, forceUpdate?: any): T {
  const [throttledValue, setThrottledValue] = useState(value)
  const lastExecuted = useRef(Date.now())
  const lastForceUpdate = useRef(forceUpdate)

  useEffect(() => {
    const now = Date.now()
    const timeSinceLastExecution = now - lastExecuted.current

    if (forceUpdate !== lastForceUpdate.current) {
      // Immediate update when forceUpdate changes
      setThrottledValue(value)
      lastExecuted.current = now
      lastForceUpdate.current = forceUpdate
      return
    }

    if (throttledValue === undefined && value !== undefined) {
      // Immediate update if the current throttled value is undefined and a new real value is provided
      setThrottledValue(value)
      lastExecuted.current = now
      return
    }

    if (timeSinceLastExecution >= delay) {
      // Update only if delay has passed
      setThrottledValue(value)
      lastExecuted.current = now
      return
    }
  }, [value, delay, forceUpdate, throttledValue])

  return throttledValue
}

export default useThrottle
