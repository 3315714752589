import React, { useEffect, useState } from 'react'
import { useActiveWeb3React } from '../../hooks'
import { useTranslation } from 'react-i18next'
import { useInterestRateData } from '../../types/interestRateData'
import { useAllActiveTokensArray } from '../../hooks/Tokens'
import { useDolomiteBalancesWithLoadingIndicator } from '../../state/wallet/hooks'
import { useFiatValuesWithLoadingIndicator } from '../../hooks/useFiatValue'
import styled from 'styled-components/macro'
import { FORMATTER, ZERO_FRACTION, ZERO_PERCENT } from '../../constants'
import { Percent } from '@dolomite-exchange/sdk-core'
import { formatAmount } from '../../utils/formatAmount'
import { useShowYieldAsApr } from '../../state/user/hooks'
import Web3StatusConnectButton from '../Web3StatusConnectButton'
import { StyledTooltip } from '../common/StyledTooltip'

const TotalWrapper = styled.div<{ noWallet: boolean }>`
  /*width: 38.2%;*/
  width: fit-content;
  flex: 0 auto;
  min-width: 250px;
  vertical-align: top;
  text-align: left;
  display: inline-block;
  padding-right: 5%;

  ${({ noWallet }) =>
    noWallet &&
    `
    /*height: 200px;*/
    margin-top: 15px;
  `};
  @media screen and (max-width: 919px) {
    margin-bottom: 20px;
  }

  @media screen and (max-width: 900px) {
    margin: 0 auto 20px;
    padding-right: 0;
  }

  @media screen and (max-width: 650px) {
    margin-bottom: 10px;
  }

  @media screen and (max-width: 500px) {
    margin-bottom: 0;
  }

  @media screen and (max-width: 700px) {
    text-align: center;
    width: 100%;
  }
`

const TotalInner = styled.div<{ hasAccount?: boolean }>`
  margin: 4px auto;
  width: fit-content;

  ${({ hasAccount }) =>
    hasAccount &&
    `
    pointer-events: none;
    display: none;
  `}
`

const TotalText = styled.div`
  font-size: 21px;
  font-weight: 100;
  letter-spacing: 0.1px;
  width: fit-content;

  @media screen and (max-width: 900px) {
    text-align: center;
    margin: 0 auto;
    font-size: 20px;
  }

  @media screen and (max-width: 700px) {
    font-size: 18px;
  }
`

const LearnMoreLink = styled.div`
  display: block;
  vertical-align: top;
  margin-top: -5px;
  width: fit-content;

  a {
    font-weight: 600;
    font-size: 14px;
    text-decoration: none !important;
    color: #606375 !important;
  }

  @media screen and (max-width: 900px) {
    margin: -5px auto 0;
  }

  @media screen and (max-width: 600px) {
    a {
      font-size: 12px;
    }
  }
`

const ConnectWalletButton = styled.div`
  margin-top: 15px;
  color: white !important;
`

const ConnectBtn = styled.div`
  display: inline-block;
`

const AmountWrapper = styled.div`
  display: inline-block;
  vertical-align: top;
  width: fit-content;
  margin: 15px 0;
  text-align: left;

  @media screen and (max-width: 900px) {
    margin-top: 0;
  }
`

const AmountInner = styled.div`
  width: fit-content;
  display: inline-block;
  vertical-align: top;
`

const TotalDeposited = styled.div`
  font-size: 54px;
  letter-spacing: 0.4px;

  div {
    display: inline-block;
    vertical-align: top;
    font-weight: 300;
    font-size: 48px;
    margin-top: 6px;
    margin-right: 2px;
  }

  @media screen and (max-width: 1200px) {
    font-size: 48px;

    div {
      font-size: 38px;
      margin-top: 4px;
    }
  }

  @media screen and (max-width: 600px) {
    font-size: 42px;

    div {
      font-size: 34px;
      margin-top: 3px;
    }
  }

  @media screen and (max-width: 500px) {
    font-size: 38px;

    div {
      font-size: 32px;
      margin-top: 2px;
      margin-right: 1px;
    }
  }

  @media screen and (max-width: 450px) {
    font-size: 34px;

    div {
      font-size: 28px;
      margin-top: 1px;
      margin-right: 0;
    }
  }
`

const InterestRateWrapper = styled.div`
  font-size: 16px;
  font-weight: 100;
  float: right;
  margin-top: -12px;
  margin-right: 4px;

  @media screen and (max-width: 600px) {
    font-size: 14px;
    margin-top: -10px;
  }

  @media screen and (max-width: 500px) {
    font-size: 13px;
    margin-top: -8px;
  }
`

const InterestAmount = styled.span<{ interestAmount: boolean }>`
  ${({ interestAmount, theme }) =>
    interestAmount &&
    `
    color: ${theme.green1};
    font-weight: 600;
    span {
      color: #8fc942;
      font-weight: 600;
    }
  `}
`

const HelperText = styled.div`
  color: #d5d6e1;
  font-size: 15px;
  font-weight: 100;
  margin: 16px 0 0;
  width: 80%;

  @media screen and (max-width: 700px) {
    margin: 20px auto 0;
  }

  a {
    font-weight: 600;
    line-height: 30px;
    text-decoration: none !important;
    color: #606375 !important;
  }
`

export default function BalancesTotal() {
  const { t } = useTranslation()
  const { account } = useActiveWeb3React()
  const [totalDeposited, setTotalDeposited] = useState(ZERO_FRACTION)
  const [netInterest, setNetInterest] = useState(ZERO_PERCENT)
  const { data: interestRateMap } = useInterestRateData()
  const tokenList = useAllActiveTokensArray()
  const [balanceData] = useDolomiteBalancesWithLoadingIndicator(account, tokenList)
  const [fiatData] = useFiatValuesWithLoadingIndicator(balanceData, tokenList)
  const [showYieldAsApr] = useShowYieldAsApr()
  const yieldText = showYieldAsApr ? t('APR') : t('APY')

  useEffect(() => {
    let totalDeposited = ZERO_FRACTION
    let netInterest = ZERO_FRACTION
    Object.keys(fiatData).forEach(tokenAddress => {
      const apr = interestRateMap[tokenAddress]?.totalSupplyInterestRate ?? ZERO_PERCENT
      const fiatValueDef = fiatData[tokenAddress] ?? ZERO_FRACTION
      if (fiatValueDef.greaterThan(ZERO_FRACTION)) {
        // exclude any debt (which is negative)
        netInterest = netInterest.add(fiatValueDef.multiply(apr))
      }
      totalDeposited = totalDeposited.add(fiatValueDef)
    })
    setTotalDeposited(totalDeposited)
    setNetInterest(
      totalDeposited.greaterThan('0')
        ? Percent.fromFraction(netInterest.asFraction.divide(totalDeposited.asFraction))
        : ZERO_PERCENT,
    )
  }, [fiatData, interestRateMap])
  return (
    <TotalWrapper noWallet={!account}>
      {account ? (
        <TotalInner>
          <TotalText>My Available Balance</TotalText>
          <LearnMoreLink>
            <StyledTooltip
              title={`Your available balance consists of assets you have deposited to Dolomite that you are not using in a borrow position, strategy, or margin position. Available balances earn the interest displayed in their row below and can be freely withdrawn.\n\nClick Learn More to read more in our docs.`}
              placement={'top'}
            >
              <a href={'https://docs.dolomite.io/dolomite-balances'} target={'_blank'} rel={'noopener noreferrer'}>
                {t('learnMore')}
              </a>
            </StyledTooltip>
          </LearnMoreLink>
          <AmountWrapper>
            <AmountInner>
              <TotalDeposited>
                <div>$</div>
                {totalDeposited.toFixed(2, FORMATTER)}
              </TotalDeposited>
              <InterestRateWrapper>
                <InterestAmount interestAmount={netInterest.greaterThan(ZERO_PERCENT)}>
                  {formatAmount(netInterest, 2, true, '0%')}
                </InterestAmount>
                {` Avg. ${yieldText}`}
              </InterestRateWrapper>
            </AmountInner>
          </AmountWrapper>
        </TotalInner>
      ) : (
        <TotalInner>
          <TotalText>{t('connectWalletText')}</TotalText>
          <ConnectWalletButton>
            <ConnectBtn key={'accountItem-StartTrading'}>
              <Web3StatusConnectButton />
            </ConnectBtn>
          </ConnectWalletButton>
          <HelperText>
            {t('earnInterest')}
            <br />
            <a href='https://docs.dolomite.io/dolomite-balances' target={'_blank'} rel={'noopener noreferrer'}>
              {t('learnMore')}
            </a>
          </HelperText>
        </TotalInner>
      )}
    </TotalWrapper>
  )
}
