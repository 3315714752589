import { createReducer } from '@reduxjs/toolkit'
import {
  updateAllTokenData,
  updateAllTokenTvlData,
  updateDolomiteBalancesWithLoadingIndicator,
  updateFiatPricesWithLoadingIndicator,
  updateInterestRateData,
  updateMarketRiskData,
} from './actions'
import { Fraction } from '@dolomite-exchange/sdk-core'
import { MarketRiskInfo } from '../../types/marketRiskInfoData'
import { InterestRate } from '../../types/interestRateData'
import { TokenBalancesWithLoadingIndicator } from '../wallet/hooks'
import { ChainIdMap, initializeGenericObjectChainIdMap, initializeObjectChainIdMap } from '../../constants/chainId'
import { Token } from '@dolomite-exchange/v2-sdk'
import { TokenTvl } from '../../types/dolomiteMarginTokenTvlData'

export type MarketRiskDataResponse = ChainIdMap<{
  data: Record<string, MarketRiskInfo | undefined>
  loading: boolean
  error: boolean
}>

export interface InterestRateDataResponse {
  loading: boolean
  error: boolean
  data: Record<string, InterestRate | undefined>
}

export interface DataState {
  readonly fiatPricesWithLoadingIndicator: ChainIdMap<
    | {
        fiatPriceMap: Record<string, Fraction | undefined>
        isLoading: boolean
      }
    | undefined
  >
  readonly marketRiskData: ChainIdMap<{
    data: Record<string, MarketRiskInfo | undefined>
    loading: boolean
    error: boolean
  }>
  readonly interestRateData: ChainIdMap<InterestRateDataResponse | undefined>
  readonly dolomiteBalancesWithLoadingIndicator: TokenBalancesWithLoadingIndicator
  readonly allTokenData: ChainIdMap<Record<string, Token | undefined>>
  readonly tokenTvlData: ChainIdMap<Record<string, TokenTvl | undefined>>
}

const initialState: DataState = {
  fiatPricesWithLoadingIndicator: initializeGenericObjectChainIdMap({
    fiatPriceMap: {},
    isLoading: true,
  }),
  marketRiskData: initializeGenericObjectChainIdMap({
    loading: true,
    error: false,
    data: {},
  }),
  interestRateData: initializeGenericObjectChainIdMap({
    loading: true,
    error: false,
    data: {},
  }),
  dolomiteBalancesWithLoadingIndicator: [{}, true],
  allTokenData: initializeObjectChainIdMap() as ChainIdMap<Record<string, Token | undefined>>,
  tokenTvlData: initializeObjectChainIdMap() as ChainIdMap<Record<string, TokenTvl | undefined>>,
}

export default createReducer(initialState, builder =>
  builder
    .addCase(updateFiatPricesWithLoadingIndicator, (state, { payload: { chainId, mapData } }) => {
      state.fiatPricesWithLoadingIndicator[chainId] = mapData
    })
    .addCase(updateMarketRiskData, (state, { payload }) => {
      state.marketRiskData = payload
    })
    .addCase(updateInterestRateData, (state, { payload: { chainId, interestData } }) => {
      state.interestRateData[chainId] = interestData
    })
    .addCase(
      updateDolomiteBalancesWithLoadingIndicator,
      (state, { payload: dolomiteBalancesWithLoadingIndicatorData }) => {
        state.dolomiteBalancesWithLoadingIndicator = dolomiteBalancesWithLoadingIndicatorData
      },
    )
    .addCase(updateAllTokenData, (state, { payload: tokenData }) => {
      state.allTokenData = tokenData
    })
    .addCase(updateAllTokenTvlData, (state, { payload: tokenTvlData }) => {
      state.tokenTvlData = tokenTvlData
    }),
)
