import React, { useCallback, useEffect, useState } from 'react'
import styled from 'styled-components/macro'
import InvestorClaim from './InvestorClaim'
import { Fraction } from '@dolomite-exchange/sdk-core'

const InvestorSectionWrapper = styled.div`
  margin-top: 50px;
  margin-bottom: 50px;
  width: 100%;

  @media screen and (max-width: 900px) {
    display: inline-block;
    margin-bottom: 100px;
    max-width: 600px;
  }
`

const TitleWrapper = styled.div`
  text-align: center;
`

const TitleText = styled.div`
  font-size: 24px;
  font-weight: 200;
  margin-top: -40px;
  margin-bottom: 10px;
  padding: 0 20px;
  width: fit-content;
  display: inline-block;
  vertical-align: top;
  background: #1a1a25;
`

const StyleBar = styled.div`
  width: 100%;
  height: 1px;
  background-color: ${({ theme }) => theme.text3};
  display: inline-block;
  vertical-align: top;
`

export default function InvestorSection() {
  return (
    <InvestorSectionWrapper>
      <TitleWrapper>
        <StyleBar />
        <TitleText>Investor Section</TitleText>
      </TitleWrapper>
      <InvestorClaim
        startDate={new Date('2024-01-01')}
        cliffDate={new Date('2024-7-01')}
        endDate={new Date('2026-01-01')}
        totalAllocation={new Fraction(1000000, 1)}
        totalClaimed={new Fraction(100000, 1)}
        roundInfo={'For investment in the Dolomite Seed Round'}
      />
    </InvestorSectionWrapper>
  )
}
