import React, { useCallback } from 'react'
import styled from 'styled-components/macro'
import DelegateVotes from './DelegateVotes'
import ConvertArb from './ConvertArb'

// Removing this breaks the other styled components and the styles no longer apply. Couldn't figure out why, so I'm just leaving this in here
// const TestDiv = styled.div`
//   font-size: 20px;
// `

export const AssetRewardsWrapper = styled.div`
  padding: 7px 35px 30px;
  width: 100%;
  overflow: auto;
`

export const AssetRewardsBody = styled.div`
  font-size: 13px;
  font-weight: 500;
`

const RewardsButtonsWrapper = styled.div`
  height: 100%;

  @media screen and (max-width: 625px) {
    text-align: center;
    max-width: 290px;
    margin: 0 auto;
  }
`

const RewardsButton = styled.div<{ supplemental?: boolean }>`
  width: fit-content;
  padding: 8px 16px;
  border-radius: 5px;
  background: ${({ theme, supplemental }) => (supplemental ? theme.bg4 : theme.blue1)};
  cursor: pointer;
  color: white;
  font-weight: 500;
  font-size: 12px;
  display: inline-block;
  margin-top: 10px;
  margin-left: 5px;

  :hover {
    background: ${({ theme, supplemental }) => (supplemental ? theme.bg5 : theme.blue2)};
  }

  svg {
    font-size: 15px;
    margin-bottom: -3px;
    margin-left: 2px;
  }

  @media screen and (max-width: 625px) {
    margin: 4px;
  }
`

export default function VArbBottomSection({
  setPopoverComponent,
}: {
  setPopoverComponent?: React.Dispatch<React.SetStateAction<JSX.Element | undefined>>
  setCustomPopoverWidth?: (width: number) => void
}) {
  const onDismiss = useCallback(() => setPopoverComponent?.(undefined), [setPopoverComponent])
  return (
    <RewardsButtonsWrapper>
      <RewardsButton
        onClick={e => {
          e.stopPropagation()
          setPopoverComponent?.(<ConvertArb onDismiss={onDismiss} />)
        }}
        supplemental
      >
        Convert ARB/vARB
      </RewardsButton>
      <RewardsButton
        onClick={e => {
          e.stopPropagation()
          setPopoverComponent?.(<DelegateVotes onDismiss={onDismiss} />)
        }}
        supplemental
      >
        Delegate Votes
      </RewardsButton>
    </RewardsButtonsWrapper>
  )
}
