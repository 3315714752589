import { Token } from '@dolomite-exchange/sdk-core'
import { ChainId, ChainIdMap, initializeSingleValueChainIdMap } from '../chainId'

const decimals = 18
const symbol = 'srUSD'
const name = 'Savings rUSD'

function getToken(chainId: ChainId, address: string) {
  return new Token(chainId, address, decimals, symbol, name)
}

export const SR_USD: ChainIdMap<Token | undefined> = initializeSingleValueChainIdMap(
  ChainId.BERACHAIN,
  getToken(ChainId.BERACHAIN, '0x5475611Dffb8ef4d697Ae39df9395513b6E947d7'),
)
