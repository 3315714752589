import { Currency, Fraction } from '@dolomite-exchange/v2-sdk'
import React, { useCallback, useMemo } from 'react'
import TransactionConfirmationModal, {
  ConfirmationModalContent,
  TransactionErrorContent,
} from '../TransactionConfirmationModal'
import { CurrencyAmount } from '@dolomite-exchange/sdk-core'
import { ChainId, FORMATTER } from '../../constants'
import cleanCurrencySymbol from '../../utils/cleanCurrencySymbol'
import { useActiveWeb3React } from '../../hooks'
import styled from 'styled-components/macro'
import { ButtonError } from '../Button'
import { getSpecialAsset } from '../../constants/isolation/special-assets'
import { AutoRow } from '../Row'
import CircularProgress from '@material-ui/core/CircularProgress'
import { Text } from 'rebass'
import checkFractionOptEquality from '../../utils/checkFractionOptEquality'
import Checkmark from '@material-ui/icons/Check'
import { formatAmount } from '../../utils/formatAmount'

export enum RepayWalletStage {
  APPROVAL = 0,
  REPAY = 1,
  COMPLETE = 2,
}

const SubmitButton = styled(ButtonError)<{ isLoading: boolean; isRepay: boolean }>`
  background-color: ${({ theme, isRepay }) => (isRepay ? theme.blue1 : theme.green2)} !important;

  :hover {
    background-color: ${({ theme, isLoading, isRepay }) => (isRepay ? theme.blue2 : theme.green1)} !important;
  }

  :focus {
    background-color: ${({ theme, isLoading, isRepay }) => (isRepay ? theme.blue1 : theme.green2)} !important;
  }

  :active {
    background-color: ${({ theme, isLoading, isRepay }) => (isRepay ? theme.blue1 : theme.green2)} !important;
  }

  > div {
    line-height: 30px;
  }
`

const IsolationDescription = styled.div`
  font-size: 12px;
  font-weight: 100;
  pointer-events: auto;
  color: ${({ theme }) => theme.text2};
  width: 100%;
  background: #2f2f40;
  border-radius: 5px;

  div > svg {
    margin-right: 4px;
    margin-bottom: -1px;
    font-size: 30px;
    color: ${({ theme }) => theme.text3};
  }

  a {
    color: ${({ theme }) => theme.text2};
    font-weight: 600;
    margin-left: 3px;
  }

  div {
    display: inline-block;
  }

  div:nth-child(1) {
    vertical-align: top;
    margin-top: 10px;
  }

  div:nth-child(2) {
    width: calc(100% - 34px);
  }

  > div > b {
    color: ${({ theme }) => theme.yellow2};
  }
`

const LoadingWrapper = styled.div`
  display: inline-block;
  vertical-align: top;
  height: 30px;

  > svg,
  > div {
    color: ${({ theme }) => theme.text1} !important;
    font-size: 30px;
    height: 30px !important;
    width: 30px !important;
  }
`
const TopWrapper = styled.div<{ isCompleted: boolean }>`
  margin-top: 20px;
  margin-bottom: ${({ isCompleted }) => (isCompleted ? 0 : -20)}px;
  transition: margin-bottom 0.3s ease-in-out;
`

const RepayStep = styled.div<{ expanded: boolean }>`
  display: block;
  height: ${({ expanded }) => (expanded ? 84 : 42)}px;
  transition: height 0.3s ease-in-out;
`

const RepayProgress = styled.div`
  display: inline-block;
  vertical-align: top;
  position: relative;
  height: 100%;
`

const Circle = styled.div<{ complete: boolean }>`
  width: 30px;
  height: 30px;
  border-radius: 50%;
  border: 2px solid ${({ complete, theme }) => (complete ? theme.green2 : theme.white)};
  background-color: ${({ complete, theme }) => (complete ? theme.green2 : 'none')};
  font-size: 16px;
  font-weight: 600;
  padding-left: ${({ complete }) => (complete ? 1 : 8.5)}px;
  padding-top: ${({ complete }) => (complete ? 1.4 : 2)}px;
`

const Line = styled.div<{ expanded: boolean; complete: boolean }>`
  width: 2px;
  background-color: ${({ complete, theme }) => (complete ? theme.green2 : theme.white)};
  transition: background-color 0.3s ease-in-out;
  margin-left: 14px;
  height: calc(100% - 30px);
`

const RepayText = styled.div`
  display: inline-block;
  width: calc(100% - 50px);
  margin-left: 18px;
`

const Title = styled.div`
  font-size: 21px;
`

const Subtitle = styled.div<{ visible: boolean }>`
  font-size: 12px;
  font-weight: 400;
  color: ${({ theme }) => theme.text3};
  margin-top: 4px;
  padding-right: 8%;
  opacity: ${({ visible }) => (visible ? 1 : 0)};
  transition: opacity 0.3s ease-in-out;
`

const Complete = styled.div<{ visible: boolean }>`
  font-size: 15px;
  color: ${({ theme }) => theme.green2};
  opacity: ${({ visible }) => (visible ? 1 : 0)};
  transition: opacity 0.3s ease-in-out;
`

interface Props {
  amount: CurrencyAmount<Currency> | undefined
  fiatAmount: Fraction | undefined
  repayStage: RepayWalletStage
}

function RepayModalHeaderComparator(prevProps: Props, nextProps: Props) {
  return (
    checkFractionOptEquality(prevProps.amount, nextProps.amount) &&
    checkFractionOptEquality(prevProps.fiatAmount, nextProps.fiatAmount) &&
    prevProps.repayStage === nextProps.repayStage
  )
}

function RepayModalHeader({ amount, fiatAmount, repayStage }: Props) {
  const symbol = useMemo(() => cleanCurrencySymbol(amount?.currency, false), [amount])
  return (
    <TopWrapper isCompleted={repayStage === RepayWalletStage.COMPLETE}>
      <RepayStep expanded={repayStage === RepayWalletStage.APPROVAL}>
        <RepayProgress>
          <Circle complete={repayStage > RepayWalletStage.APPROVAL}>
            {repayStage === RepayWalletStage.APPROVAL ? '1' : <Checkmark />}
          </Circle>
          <Line
            expanded={repayStage === RepayWalletStage.APPROVAL}
            complete={!(repayStage === RepayWalletStage.APPROVAL)}
          />
        </RepayProgress>
        <RepayText>
          <Title>{'Approve'}</Title>
          <Subtitle visible={repayStage === RepayWalletStage.APPROVAL}>
            {`Approving allows Dolomite to access the ${symbol} in your wallet to repay the borrowed amount.`}
          </Subtitle>
        </RepayText>
      </RepayStep>
      <RepayStep expanded={repayStage === RepayWalletStage.REPAY}>
        <RepayProgress>
          <Circle complete={repayStage > RepayWalletStage.REPAY}>
            {repayStage < RepayWalletStage.COMPLETE ? '2' : <Checkmark />}
          </Circle>
        </RepayProgress>
        <RepayText>
          <Title>Repay</Title>
          <Subtitle visible={repayStage === RepayWalletStage.REPAY}>
            {`Complete your repay of ${formatAmount(amount, 6, true, '0.00')}
            ${symbol} (${formatAmount(fiatAmount, 2, true, '0.00', true)}) from your wallet to the borrow position.`}
          </Subtitle>
        </RepayText>
      </RepayStep>
      <Complete visible={repayStage === RepayWalletStage.COMPLETE}>Deposit Complete</Complete>
    </TopWrapper>
  )
}

function RepayModalFooterComparator(prevProps: RepayModalFooterProps, nextProps: RepayModalFooterProps) {
  return (
    prevProps.amount === nextProps.amount &&
    prevProps.repayStage === nextProps.repayStage &&
    prevProps.onConfirm === nextProps.onConfirm &&
    prevProps.chainId === nextProps.chainId &&
    prevProps.attemptingTxn === nextProps.attemptingTxn &&
    prevProps.isLoading === nextProps.isLoading
  )
}

interface RepayModalFooterProps {
  amount: CurrencyAmount<Currency> | undefined
  repayStage: RepayWalletStage
  onConfirm: () => void
  chainId: ChainId
  attemptingTxn: boolean
  isLoading: boolean
}

function RepayModalFooter({ amount, repayStage, onConfirm, chainId, attemptingTxn, isLoading }: RepayModalFooterProps) {
  return (
    <>
      <AutoRow>
        <SubmitButton
          onClick={() => !isLoading && !attemptingTxn && onConfirm()}
          isLoading={isLoading || attemptingTxn}
          style={{ margin: '10px 0 0 0' }}
          isRepay={repayStage === RepayWalletStage.APPROVAL}
        >
          {attemptingTxn || isLoading ? (
            <LoadingWrapper>
              <CircularProgress />
            </LoadingWrapper>
          ) : (
            <Text fontSize={20} fontWeight={500}>
              {`${repayStage < RepayWalletStage.COMPLETE ? 'Confirm' : ''} ${
                repayStage === RepayWalletStage.APPROVAL
                  ? 'Approve'
                  : repayStage === RepayWalletStage.REPAY
                  ? 'Repay'
                  : 'Close'
              }`}
            </Text>
          )}
        </SubmitButton>
      </AutoRow>
    </>
  )
}

export default function ConfirmRepayModal({
  amount,
  fiatAmount,
  onConfirm,
  onDismiss,
  repayStage,
  loading,
  errorMessage,
  isOpen,
  attemptingTxn,
  txHash,
}: {
  amount: CurrencyAmount<Currency> | undefined
  fiatAmount: Fraction | undefined
  attemptingTxn: boolean
  txHash: string | undefined
  repayStage: RepayWalletStage
  loading: boolean
  isOpen: boolean
  onConfirm: () => void
  errorMessage: string | undefined
  onDismiss: () => void
}) {
  const { chainId } = useActiveWeb3React()
  const pendingText = useMemo(() => {
    if (!amount) {
      return ''
    }
    const symbol = cleanCurrencySymbol(amount.currency, false)
    if (repayStage === RepayWalletStage.APPROVAL) {
      return 'Approval'
    }
    return `Repaying ${amount.toSignificant(6, FORMATTER)} ${symbol}`
  }, [amount, repayStage])

  const modalHeader = useCallback(() => {
    return <RepayModalHeader amount={amount} fiatAmount={fiatAmount} repayStage={repayStage} />
  }, [amount, repayStage, fiatAmount])

  const modalBottom = useCallback(() => {
    return (
      <RepayModalFooter
        amount={amount}
        repayStage={repayStage}
        onConfirm={onConfirm}
        chainId={chainId}
        attemptingTxn={attemptingTxn}
        isLoading={loading}
      />
    )
  }, [amount, repayStage, onConfirm, chainId, attemptingTxn, loading])

  const symbol = useMemo(() => cleanCurrencySymbol(amount?.currency, false), [amount])
  const confirmationContent = useCallback(
    () =>
      errorMessage ? (
        <TransactionErrorContent onDismiss={onDismiss} message={errorMessage} />
      ) : (
        <ConfirmationModalContent
          title={`Token Approval for ${symbol}`}
          onDismiss={onDismiss}
          topContent={modalHeader}
          bottomContent={modalBottom}
        />
      ),
    [errorMessage, onDismiss, modalHeader, modalBottom, symbol],
  )

  return (
    <TransactionConfirmationModal
      isOpen={isOpen}
      memoizedOnDismiss={onDismiss}
      attemptingTxn={false}
      hash={txHash}
      memoizedContentCreator={confirmationContent}
      pendingText={pendingText}
      currencyToAdd={amount?.currency}
      disableSubmit={true}
      maxWidthPx={400}
    />
  )
}
