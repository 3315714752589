import { useMemo } from 'react'
import { BorrowPosition, BorrowPositionAmount } from '../types/borrowPositionData'
import { ONE_FRACTION, ZERO_FRACTION } from '../constants'
import { CurrencyAmount, Token } from '@dolomite-exchange/v2-sdk'
import calculateBorrowPositionHealth from '../utils/calculateBorrowPositionHealth'
import { CollateralActionType, LoanActionType, ManageType } from 'pages/Borrow/types'
import { MarketRiskInfo } from '../types/marketRiskInfoData'
import { Fraction } from '@dolomite-exchange/sdk-core'
import { DolomiteMarginData } from '../types/dolomiteMarginData'
import { STABLE_TOKENS } from '../constants/tokenLists/FilterTokens'
import { reqParseFraction } from '../state/trade/hooks'
import calculateLiquidationPrice from '../utils/calculateLiquidationPrice'
import { getBorrowPositionEMode, PositionOverrideSetting } from '../utils/emode'

function transformAmounts(
  position: BorrowPosition,
  inputValue: CurrencyAmount<Token> | undefined,
  zapOutputValue: CurrencyAmount<Token> | undefined,
  manageType: ManageType,
  actionType: CollateralActionType | LoanActionType,
  fiatValueMap: Record<string, Fraction | undefined>,
  isZapActivated: boolean,
): [BorrowPositionAmount[], BorrowPositionAmount[]] {
  const supplyValue = !isZapActivated
    ? inputValue
    : manageType === ManageType.COLLATERAL
    ? actionType === CollateralActionType.DEPOSIT
      ? zapOutputValue
      : inputValue
    : actionType === LoanActionType.BORROW || actionType === LoanActionType.SWAP
    ? zapOutputValue
    : inputValue
  const borrowValue =
    isZapActivated && manageType === ManageType.COLLATERAL
      ? undefined
      : !isZapActivated || manageType === ManageType.COLLATERAL
      ? inputValue
      : actionType === LoanActionType.BORROW || actionType === LoanActionType.SWAP
      ? inputValue
      : zapOutputValue
  const swapValue =
    actionType === LoanActionType.SWAP || actionType === CollateralActionType.SWAP ? zapOutputValue : undefined

  const supplyFiatValue = fiatValueMap[supplyValue?.currency.address ?? '']
  const supplySwapFiatValue = fiatValueMap[swapValue?.currency.address ?? '']
  const supplyMultiplier = actionType === CollateralActionType.DEPOSIT ? 1 : -1
  const swapMultiplier = manageType === ManageType.LOAN && actionType === LoanActionType.SWAP ? -1 : 1

  let supplyFound = false
  let supplySwapFound = false

  const supplyAmounts = position.supplyAmounts.map<BorrowPositionAmount>(amount => {
    if (amount.token.address === supplyValue?.currency.address) {
      supplyFound = true
      const price = amount.amountUSD.divide(amount.amountTokenWei.asFraction)
      const inputAmountWithMultiplier = supplyValue.multiply(supplyMultiplier)
      return inputAmountWithMultiplier.asFraction.abs.greaterThanOrEqual(amount.amountTokenWei.asFraction) &&
        inputAmountWithMultiplier.asFraction.lessThanOrEqual(ZERO_FRACTION)
        ? {
            ...amount,
            amountUSD: ZERO_FRACTION,
            amountTokenWei: CurrencyAmount.fromRawAmount(supplyValue.currency, '0'),
          }
        : {
            ...amount,
            amountUSD: amount.amountUSD.add(price.multiply(inputAmountWithMultiplier.asFraction)),
            amountTokenWei: amount.amountTokenWei.add(inputAmountWithMultiplier),
          }
    } else if (amount.token.address === swapValue?.currency.address) {
      supplySwapFound = true
      const price = amount.amountUSD.divide(amount.amountTokenWei.asFraction)
      const inputAmountWithMultiplier = swapValue.multiply(swapMultiplier)
      return {
        ...amount,
        amountUSD: amount.amountUSD.add(price.multiply(inputAmountWithMultiplier.asFraction)),
        amountTokenWei: amount.amountTokenWei.add(inputAmountWithMultiplier),
      }
    } else {
      return amount
    }
  })

  if (
    !supplyFound &&
    supplyValue &&
    supplyFiatValue &&
    (manageType === ManageType.COLLATERAL || (isZapActivated && actionType !== LoanActionType.SWAP))
  ) {
    supplyAmounts.push({
      isPositive: true,
      token: supplyValue.currency,
      amountTokenPar: CurrencyAmount.fromRawAmount(supplyValue.currency, '0'),
      amountTokenWei: supplyValue,
      amountUSD: supplyValue.asFraction.multiply(supplyFiatValue),
      interestAccruedToken: CurrencyAmount.fromRawAmount(supplyValue.currency, '0'),
      interestAccruedUSD: ZERO_FRACTION,
    } as BorrowPositionAmount)
  }
  if (
    !supplySwapFound &&
    swapValue &&
    supplySwapFiatValue &&
    manageType === ManageType.COLLATERAL &&
    actionType === LoanActionType.SWAP
  ) {
    supplyAmounts.push({
      isPositive: true,
      token: swapValue.currency,
      amountTokenPar: CurrencyAmount.fromRawAmount(swapValue.currency, '0'),
      amountTokenWei: zapOutputValue,
      amountUSD: swapValue.asFraction.multiply(supplySwapFiatValue),
      interestAccruedToken: CurrencyAmount.fromRawAmount(swapValue.currency, '0'),
      interestAccruedUSD: ZERO_FRACTION,
    } as BorrowPositionAmount)
  }

  const borrowFiatValue = fiatValueMap[borrowValue?.currency.address ?? '']
  const borrowMultiplier =
    actionType === LoanActionType.BORROW || (manageType === ManageType.LOAN && actionType === LoanActionType.SWAP)
      ? 1
      : -1
  let borrowFound = false
  let swapBorrowDifference = undefined as CurrencyAmount<Token> | undefined
  let swapBorrowAmount = undefined as BorrowPositionAmount | undefined
  let swapPrice = undefined as Fraction | undefined

  const borrowAmounts = position.borrowAmounts.map<BorrowPositionAmount>(amount => {
    if (amount.token.address === borrowValue?.currency.address) {
      borrowFound = true
      const price = amount.amountUSD.divide(amount.amountTokenWei.asFraction)
      const inputAmountWithMultiplier = borrowValue.multiply(borrowMultiplier)
      return inputAmountWithMultiplier.asFraction.abs.greaterThanOrEqual(amount.amountTokenWei.asFraction) &&
        inputAmountWithMultiplier.asFraction.lessThanOrEqual(ZERO_FRACTION)
        ? {
            ...amount,
            amountUSD: ZERO_FRACTION,
            amountTokenWei: CurrencyAmount.fromRawAmount(borrowValue.currency, '0'),
          }
        : {
            ...amount,
            amountUSD: amount.amountUSD.add(price.multiply(inputAmountWithMultiplier.asFraction)),
            amountTokenWei: amount.amountTokenWei.add(inputAmountWithMultiplier),
          }
    } else if (amount.token.address === swapValue?.currency.address) {
      const price = amount.amountUSD.divide(amount.amountTokenWei.asFraction)
      if (manageType === ManageType.LOAN && actionType === LoanActionType.SWAP && supplyValue) {
        swapBorrowDifference = amount.amountTokenWei.subtract(supplyValue)
        swapBorrowAmount = amount
        swapPrice = price
      }
      const inputAmountWithMultiplier = swapValue.multiply(swapMultiplier)
      return inputAmountWithMultiplier.asFraction.abs.greaterThanOrEqual(amount.amountTokenWei.asFraction) &&
        inputAmountWithMultiplier.asFraction.lessThanOrEqual(ZERO_FRACTION)
        ? {
            ...amount,
            amountUSD: ZERO_FRACTION,
            amountTokenWei: CurrencyAmount.fromRawAmount(swapValue.currency, '0'),
          }
        : {
            ...amount,
            amountUSD: amount.amountUSD.add(price.multiply(inputAmountWithMultiplier.asFraction)),
            amountTokenWei: amount.amountTokenWei.add(inputAmountWithMultiplier),
          }
    } else {
      return amount
    }
  })

  if (swapBorrowDifference?.lessThan(ZERO_FRACTION) && swapBorrowAmount && swapPrice) {
    supplyAmounts.push({
      isPositive: true,
      token: swapBorrowAmount.token,
      amountTokenPar: CurrencyAmount.fromRawAmount(swapBorrowAmount.token, '0'),
      amountTokenWei: swapBorrowDifference.multiply(-1),
      amountUSD: swapPrice.multiply(swapBorrowDifference.asFraction.abs),
      interestAccruedToken: CurrencyAmount.fromRawAmount(swapBorrowAmount.token, '0'),
      interestAccruedUSD: ZERO_FRACTION,
      expirationTimestamp: swapBorrowAmount.expirationTimestamp,
    })
  }
  if (!borrowFound && manageType === ManageType.LOAN) {
    if (borrowValue && borrowFiatValue) {
      borrowAmounts.push({
        isPositive: false,
        token: borrowValue.currency,
        amountTokenPar: CurrencyAmount.fromRawAmount(borrowValue.currency, '0'),
        amountTokenWei: borrowValue,
        amountUSD: borrowValue.asFraction.multiply(borrowFiatValue),
        interestAccruedToken: CurrencyAmount.fromRawAmount(borrowValue.currency, '0'),
        interestAccruedUSD: ZERO_FRACTION,
      } as BorrowPositionAmount)
    }
  }

  return [
    supplyAmounts.filter(s => s.amountTokenWei.asFraction.greaterThan(ZERO_FRACTION)),
    borrowAmounts.filter(s => s.amountTokenWei.asFraction.greaterThan(ZERO_FRACTION)),
  ]
}

export default function useNewBorrowPositionHealth(
  position: BorrowPosition,
  inputValue: CurrencyAmount<Token> | undefined,
  zapOutputValue: CurrencyAmount<Token> | undefined,
  manageType: ManageType,
  actionType: CollateralActionType | LoanActionType,
  fiatValueMap: Record<string, Fraction | undefined>,
  marketRiskInfoMap: Record<string, MarketRiskInfo | undefined>,
  minCollateralization: Fraction,
  isZapActivated: boolean,
  dolomiteMargin: DolomiteMarginData | undefined,
): [Fraction | undefined, Fraction | undefined, PositionOverrideSetting | undefined] {
  return useMemo(() => {
    const [supplyAmounts, borrowAmounts] = transformAmounts(
      position,
      inputValue,
      zapOutputValue,
      manageType,
      actionType,
      fiatValueMap,
      isZapActivated,
    )

    const newEMode = getBorrowPositionEMode(
      supplyAmounts.map(a => a.token),
      borrowAmounts.map(a => a.token),
      marketRiskInfoMap,
    )

    // Calculate new liquidation price
    let liquidationPrice: Fraction | undefined
    const nonStableSupplyAmounts = supplyAmounts.filter(
      a => !(STABLE_TOKENS as Record<string, boolean>)[a.token.symbol ?? ''],
    )
    const nonStableBorrowAmounts = borrowAmounts.filter(
      a => !(STABLE_TOKENS as Record<string, boolean>)[a.token.symbol ?? ''],
    )
    const filteredSupplyAmounts = supplyAmounts.filter(a => !a.amountTokenWei.equalTo(ZERO_FRACTION))
    const filteredBorrowAmounts = borrowAmounts.filter(a => !a.amountTokenWei.equalTo(ZERO_FRACTION))
    if (
      (nonStableBorrowAmounts.length === 1 && nonStableBorrowAmounts.length < filteredBorrowAmounts.length) ||
      (nonStableSupplyAmounts.length === 1 && nonStableSupplyAmounts.length < filteredSupplyAmounts.length)
    ) {
      liquidationPrice = undefined
    } else if (
      nonStableSupplyAmounts.length + nonStableBorrowAmounts.length === 1 &&
      filteredSupplyAmounts.length > 0 &&
      filteredBorrowAmounts.length > 0
    ) {
      const defaultMinCollateralization = dolomiteMargin?.minCollateralization
      if (nonStableSupplyAmounts.length === 1) {
        const heldAmountWei = nonStableSupplyAmounts[0].amountTokenWei.asFraction
        const owedAmountUSD = filteredBorrowAmounts.reduce((total, borrowAmount) => {
          const marginPremium = !newEMode
            ? marketRiskInfoMap[borrowAmount.token.address]?.marginPremium ?? ZERO_FRACTION
            : ZERO_FRACTION
          const appliedMarginPremium = marginPremium.add(ONE_FRACTION)
          const owedAmountUSD = borrowAmount.amountUSD.multiply(appliedMarginPremium)
          return total.add(reqParseFraction(owedAmountUSD.toFixed(36), 36))
        }, ZERO_FRACTION)
        liquidationPrice = calculateLiquidationPrice(
          heldAmountWei,
          owedAmountUSD,
          defaultMinCollateralization,
          marketRiskInfoMap[nonStableSupplyAmounts[0].token.address],
          undefined,
          newEMode,
        )
        liquidationPrice = liquidationPrice.greaterThan(ZERO_FRACTION) ? liquidationPrice : undefined
      } else {
        const heldAmountUSD = filteredSupplyAmounts.reduce((total, supplyAmount) => {
          const marginPremium = !newEMode
            ? marketRiskInfoMap[supplyAmount.token.address]?.marginPremium ?? ZERO_FRACTION
            : ZERO_FRACTION
          const appliedMarginPremium = ONE_FRACTION.add(marginPremium)
          const supplyAmountUSD = supplyAmount.amountUSD.divide(appliedMarginPremium)
          return total.add(reqParseFraction(supplyAmountUSD.toFixed(36), 36))
        }, ZERO_FRACTION)

        const owedAmount = nonStableBorrowAmounts[0].amountTokenWei.asFraction
        const liquidationPriceInverted = calculateLiquidationPrice(
          heldAmountUSD,
          owedAmount,
          defaultMinCollateralization,
          undefined,
          marketRiskInfoMap[nonStableBorrowAmounts[0].token.address],
          newEMode,
        )
        liquidationPrice = liquidationPriceInverted.greaterThan(ZERO_FRACTION)
          ? ONE_FRACTION.divide(liquidationPriceInverted)
          : undefined
      }
    }

    return [
      calculateBorrowPositionHealth(supplyAmounts, borrowAmounts, marketRiskInfoMap, minCollateralization),
      liquidationPrice,
      newEMode,
    ]
  }, [
    position,
    inputValue,
    zapOutputValue,
    manageType,
    actionType,
    fiatValueMap,
    isZapActivated,
    marketRiskInfoMap,
    minCollateralization,
    dolomiteMargin?.minCollateralization,
  ])
}
