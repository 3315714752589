import { gql } from '@apollo/client'
import { createFractionUSD, DolomiteDayData as DolomiteDayDataGql } from './gqlTypeHelpers'
import { dolomiteDayDataGql } from './queryObjects'
import { Fraction } from '@dolomite-exchange/sdk-core'
import { useMemo } from 'react'
import { useGraphqlResult } from '../state/graphql/hooks'
import { GraphqlClientType } from '../state/graphql/actions'
import { RefreshFrequency, useBlockTimestamp } from '../state/chain/hooks'
import { ChainId } from '../constants'
import { useActiveWeb3React } from '../hooks'

const DOLOMITE_DAY_DATA_GQL = gql`
    query dolomiteDayDataForPastYear($blockNumber: Int!, $endTimestamp: Int!, $startTimestamp: Int!) {
        dolomiteDayDatas(
            orderBy: dayStartUnix,
            orderDirection: desc,
            block: { number_gte: $blockNumber },
            where: {
                dayStartUnix_lte: $endTimestamp,
                dayStartUnix_gt: $startTimestamp
            }
        ) {
            ${dolomiteDayDataGql()}
        }
    }
`

interface DolomiteDayDataResponse {
  dolomiteDayDatas: DolomiteDayDataGql[] | undefined
}

export interface DolomiteDayData {
  id: string
  timestamp: number
  borrowLiquidityUSD: Fraction
  supplyLiquidityUSD: Fraction
  tradeVolumeUSD: Fraction
  ammLiquidityUSD: Fraction
}

const BERACHAIN_START_TIMESTAMP = 1738713600

export function useCurrentYearDolomiteDayDataData(): {
  loading: boolean
  error: boolean
  data: DolomiteDayData[] | undefined
} {
  const { chainId } = useActiveWeb3React()
  const timestamp = useBlockTimestamp()

  const startTimestamp = useMemo(() => {
    let startTimestamp = timestamp
      .div('86400')
      .sub('365')
      .mul('86400')
      .toNumber()
    if (chainId == ChainId.BERACHAIN && startTimestamp < BERACHAIN_START_TIMESTAMP) {
      startTimestamp = BERACHAIN_START_TIMESTAMP
    }

    return startTimestamp
  }, [timestamp, chainId])

  const endTimestamp = useMemo(() => {
    return timestamp
      .div('86400')
      .mul('86400')
      .toNumber()
  }, [timestamp])

  const variables = useMemo(() => {
    return {
      startTimestamp,
      endTimestamp,
    }
  }, [endTimestamp, startTimestamp])

  const queryState = useGraphqlResult<DolomiteDayDataResponse>(
    GraphqlClientType.Dolomite,
    DOLOMITE_DAY_DATA_GQL.loc!.source.body,
    variables,
    RefreshFrequency.Slow,
  )

  return useMemo(() => {
    const { loading, error, result } = queryState
    const anyLoading = Boolean(loading)
    const anyError = Boolean(error)
    if (!result?.dolomiteDayDatas) {
      return {
        loading: anyLoading,
        error: anyError,
        data: undefined,
      }
    }

    const dayDatas = (result.dolomiteDayDatas ?? [])
      .map<DolomiteDayData>(value => {
        return {
          id: value.id,
          timestamp: value.dayStartUnix,
          tradeVolumeUSD: createFractionUSD(value.dailyTradeVolumeUSD),
          borrowLiquidityUSD: createFractionUSD(value.borrowLiquidityUSD),
          supplyLiquidityUSD: createFractionUSD(value.supplyLiquidityUSD),
          ammLiquidityUSD: createFractionUSD(value.ammLiquidityUSD),
        }
      })
      .sort((a, b) => a.timestamp - b.timestamp)

    return {
      loading: anyLoading,
      error: anyError,
      data: dayDatas,
    }
  }, [queryState])
}
