import React, { useCallback, useEffect, useMemo, useState } from 'react'
import { Text } from 'rebass'
import { NavLink } from 'react-router-dom'
import { darken } from 'polished'
import { useTranslation } from 'react-i18next'

import styled from 'styled-components/macro'

import Logo from '../../assets/DolomiteLogoCircle.png'
import { useActiveWeb3React } from '../../hooks'
import { useETHBalances } from '../../state/wallet/hooks'
import Menu from '../Menu'

import Row, { RowFixed } from '../Row'
import Web3Status from '../Web3Status'
import LevelButton from '../LevelButton'
import { USDC, WETH } from '../../constants'
import { useUrlPathParams } from '../../state/trade/hooks'
import cleanCurrencySymbol from '../../utils/cleanCurrencySymbol'
import MenuIcon from '@material-ui/icons/Menu'
import Settings from '../Settings'
import NetworkSelector from '../NetworkSelector/NetworkSelector'
import { useGalxeAddressData } from '../../types/galxeAddressData'
import { WHITELIST } from '../../pages/App'
import { isBerachain } from '../../constants/chainId'
import MoreMenu from '../MoreMenu'

const HeaderFrame = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  justify-content: space-between;
  align-items: center;
  flex-direction: row;
  width: 100%;
  top: 0;
  position: relative;
  padding: 1rem;
  z-index: 2;

  @media screen and (max-width: 1300px) {
    grid-template-columns: 1fr 2fr;
  }

  @media screen and (max-width: 900px) {
    grid-template-columns: 1fr 3fr;
  }

  @media screen and (max-width: 800px) {
    grid-template-columns: 1fr 4fr;
  }

  @media screen and (max-width: 750px) {
    grid-template-columns: 1fr 6fr;
  }

  @media screen and (max-width: 700px) {
    grid-template-columns: 1fr 9fr;
  }

  ${({ theme }) => theme.mediaWidth.upToSmall`
    grid-template-columns: 1fr;
    padding: 0 1rem;
    width: calc(100%);
    position: relative;
  `};

  ${({ theme }) => theme.mediaWidth.upToExtraSmall`
        padding: 0.5rem 1rem;
  `}
`

const HeaderControls = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-self: flex-end;

  @media screen and (max-width: 800px) {
    margin-right: 48px;
    display: block;
  }

  ${({ theme }) => theme.mediaWidth.upToSmall`
    flex-direction: row;
    justify-content: space-between;
    justify-self: center;
    width: 100%;
    max-width: 960px;
    padding: 1rem;
    position: fixed;
    bottom: 0px;
    left: 0px;
    width: 100%;
    z-index: 99;
    height: 72px;
    border-radius: 5px 5px 0 0;
    background-color: ${({ theme }) => theme.bg1};
    box-shadow: 0px 5px 5px -3px rgba(0, 0, 0, 0.2), 0 8px 10px 1px rgba(0, 0, 0, 0.14),
    0px 3px 14px 2px rgba(0, 0, 0, 0.12);
    margin: 0;
  `};

  @media screen and (max-width: 366px) {
    padding: 1rem 0.6rem;
  }
`

const HeaderElement = styled.div<{ walletConnected: boolean }>`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-right: 95px;

  /* addresses safari's lack of support for "gap" */

  & > *:not(:first-child) {
    margin-left: 8px;
  }

  @media screen and (max-width: 1300px) {
    margin-right: 143px;
  }

  @media screen and (max-width: 800px) {
    margin-right: 95px;
  }

  ${({ theme }) => theme.mediaWidth.upToSmall`
    justify-content: flex-end;
    flex-direction: row-reverse;
    align-items: center;
    max-width: 100%;
    margin: 0;
    
    & > * {
      margin: 0 0 0 8px;
      
      :first-child {
        margin-left: auto;
      }
    }
  `};

  @media screen and (max-width: 390px) {
    justify-content: ${({ walletConnected }) => (walletConnected ? 'space-between' : 'flex-end')};

    & > * {
      margin: ${({ walletConnected }) => (walletConnected ? '0 !important' : '0 0 0 8px')};
    }
  }
`

const HeaderRow = styled(RowFixed)`
  ${({ theme }) => theme.mediaWidth.upToSmall`
   width: 100%;
  `};
`

const activeClassName = 'ACTIVE'

const StyledNavLink = styled(NavLink).attrs({
  activeClassName,
})<{ hideOnDesktop?: boolean; hideOnMobile?: boolean }>`
  ${({ theme }) => theme.flexRowNoWrap}
  align-items: left;
  border-radius: 3rem;
  outline: none;
  cursor: pointer;
  text-decoration: none;
  color: ${({ theme }) => theme.text2};
  font-size: 1rem;
  width: fit-content;
  margin: 0 12px;
  font-weight: 500;
  ${({ hideOnDesktop }) => hideOnDesktop && 'display: none;'}

  &.${activeClassName} {
    border-radius: 5px;
    font-weight: 600;
    color: ${({ theme }) => theme.text1};
  }

  :hover,
  :focus {
    color: ${({ theme }) => darken(0.1, theme.text1)};
  }

  @media screen and (max-width: 1300px) {
    margin: 0 8px;
    ${({ hideOnDesktop }) => hideOnDesktop && 'display: block;'}
    ${({ hideOnMobile }) => hideOnMobile && 'display: none;'}
  }
`

const HeaderLinks = styled(Row)<{ expanded?: boolean }>`
  justify-content: center;

  svg {
    display: none;
  }

  @media screen and (max-width: 1300px) {
    transition: all 0.3s ease-in-out;
    flex-direction: column;
    position: absolute;
    top: 0;
    right: 0;
    width: ${({ expanded }) => (expanded ? '160' : '48')}px;
    background: ${({ theme }) => theme.bg2};
    height: ${({ expanded }) => (expanded ? '390' : '40')}px;
    justify-content: flex-start;
    font-size: 40px;
    box-shadow: 0 5px 5px -3px rgb(0 0 0 / 20%), 0 8px 10px 1px rgb(0 0 0 / 14%), 0 3px 14px 2px rgb(0 0 0 / 12%);
    align-items: flex-end;
    border-radius: ${({ expanded }) => (expanded ? '12px' : '5px')};
    margin: ${({ expanded }) => (expanded ? '65px 8px' : '16px 8px')};
    padding: ${({ expanded }) => (expanded ? '24px 24px 16px 36px' : '8px 12px 8px 12px')};
    overflow: hidden;
    cursor: ${({ expanded }) => (expanded ? 'auto' : 'pointer')};
    z-index: 10;

    ${StyledNavLink} {
      pointer-events: ${({ expanded }) => (expanded ? 'auto' : 'none')};
    }

    a {
      font-size: 18px;
      margin-bottom: 12px;
      opacity: ${({ expanded }) => (expanded ? 1 : 0)};
      transition: ${({ expanded }) => (expanded ? 'opacity 0.3s ease-in-out 0.2s' : 'opacity 0.3s ease-in-out')};
    }

    svg {
      display: inline-block;
      opacity: ${({ expanded }) => (expanded ? 0 : 1)};
      margin-bottom: ${({ expanded }) => (expanded ? '-28px' : '0')};
      transition: ${({ expanded }) => (expanded ? 'all 0.3s ease-in-out' : 'all 0.3s ease-in-out 0.2s')};
    }
  }

  ${({ theme, expanded }) => theme.mediaWidth.upToSmall`
    margin: ${expanded ? '8px' : '8px'};
    z-index: 100;
  `};
`

const Web3StatusWrapper = styled.div`
  box-shadow: 0 5px 5px -3px rgb(0 0 0 / 20%), 0 8px 10px 1px rgb(0 0 0 / 14%), 0 3px 14px 2px rgb(0 0 0 / 12%);
  border-radius: 5px;

  ${({ theme }) => theme.mediaWidth.upToSmall`
    box-shadow: none;
    max-width: 173px;
    margin-left: 0 !important;
  `};
`

const BalanceText = styled(Text)`
  margin: 0 !important;

  ${({ theme }) => theme.mediaWidth.upToExtraSmall`
    display: none;
  `};
`

const Title = styled.a`
  display: flex;
  align-items: center;
  pointer-events: auto;
  justify-self: flex-start;
  margin-right: 12px;
  ${({ theme }) => theme.mediaWidth.upToSmall`
    justify-self: center;
  `};

  :hover {
    cursor: pointer;
  }
`

const NewTag = styled.div`
  color: ${({ theme }) => theme.blue2};
  font-weight: 700;
  font-size: 10px;
  margin-top: -6px;
  margin-left: -7px;
  margin-right: -15px;
`

const DoloIcon = styled.div`
  transition: transform 0.3s ease;

  :hover {
    transform: rotate(-5deg);
  }
`

export default function Header() {
  const { account, chainId } = useActiveWeb3React()
  const { t } = useTranslation()

  const whitelisted = account ? WHITELIST.includes(account.toUpperCase()) : undefined
  const addresses = useMemo(() => (account ? [account] : []), [account])
  const userEthBalance = useETHBalances(addresses)[account ?? '']
  const [expanded, setExpanded] = useState(false)
  const wrapperRef = React.useRef<HTMLDivElement>(null)
  const [, setSize] = useState({
    x: window.innerWidth,
    y: window.innerHeight,
  })
  const updateSize = () =>
    setSize({
      x: window.innerWidth,
      y: window.innerHeight,
    })
  useEffect(() => {
    window.onresize = updateSize
    return () => {
      window.onresize = null
    }
  }, [])
  const handleClickOutside = useCallback(
    (event: MouseEvent) => {
      if (wrapperRef?.current && !wrapperRef.current.contains(event.target as Node)) {
        expanded && setExpanded(false)
      }
    },
    [expanded],
  )

  useEffect(() => {
    const listener = handleClickOutside
    document.addEventListener('mouseup', listener)
    return () => document.removeEventListener('mouseup', listener)
  }, [handleClickOutside])

  const { inputCurrency, outputCurrency } = useUrlPathParams()
  const tradePath =
    inputCurrency && outputCurrency
      ? `/trade/${inputCurrency}/${outputCurrency}`
      : `/trade/${cleanCurrencySymbol(USDC[chainId])}/${cleanCurrencySymbol(WETH[chainId])}`

  //remove soon
  const { levelData } = useGalxeAddressData(account)
  const { level } = levelData
  const isBeraMode = false // TODO - Add as a toggleable option in settings on Berachain

  return (
    <HeaderFrame>
      <HeaderRow>
        <Title href='.'>
          <DoloIcon>
            <img width={'35px'} src={Logo} alt='logo' />
          </DoloIcon>
        </Title>
        <HeaderLinks expanded={expanded} onClick={() => setExpanded(true)} ref={wrapperRef}>
          <MenuIcon onClick={() => setExpanded(true)} />
          <StyledNavLink
            id={`balances-nav-link`}
            to={'/balances'}
            onClick={e => {
              e.stopPropagation()
              setExpanded(false)
            }}
          >
            {isBerachain(chainId) && isBeraMode ? t('earn').replace(/^./, 'B') : t('earn')}
          </StyledNavLink>
          <StyledNavLink
            id={`borrow-nav-link`}
            to={'/borrow'}
            onClick={e => {
              e.stopPropagation()
              setExpanded(false)
            }}
          >
            {isBerachain(chainId) && isBeraMode ? t('borrow').replace(/^./, 'B') : t('borrow')}
          </StyledNavLink>
          <StyledNavLink
            id={`strategies-nav-link`}
            to={'/strategies'}
            onClick={e => {
              e.stopPropagation()
              setExpanded(false)
            }}
          >
            {isBerachain(chainId) && isBeraMode ? t('brategies') : t('strategies')}
          </StyledNavLink>
          <StyledNavLink
            id={`history-nav-link`}
            to={'/history/borrows'}
            onClick={e => {
              e.stopPropagation()
              setExpanded(false)
            }}
          >
            {isBerachain(chainId) && isBeraMode ? t('history').replace(/^./, 'B') : t('history')}
          </StyledNavLink>
          <StyledNavLink
            id={`stats-nav-link`}
            to={'/stats'}
            onClick={e => {
              e.stopPropagation()
              setExpanded(false)
            }}
          >
            {isBerachain(chainId) && isBeraMode ? t('stats').replace(/^./, 'B') : t('stats')}
          </StyledNavLink>
          <StyledNavLink
            id={`strategies-nav-link`}
            to={'/airdrop'}
            onClick={e => {
              e.stopPropagation()
              setExpanded(false)
            }}
          >
            {isBerachain(chainId) && isBeraMode ? t('beardrop') : t('airdrop')}
            <NewTag>NEW</NewTag>
          </StyledNavLink>
          <StyledNavLink
            id={`boyco-nav-link`}
            to={'/boyco'}
            onClick={e => {
              e.stopPropagation()
              setExpanded(false)
            }}
          >
            {isBerachain(chainId) && isBeraMode ? t('boyco') : t('boyco')}
          </StyledNavLink>
          <MoreMenu isBeraMode={isBeraMode} />
          <StyledNavLink
            id={`swap-nav-link`}
            to={tradePath}
            hideOnDesktop
            onClick={e => {
              e.stopPropagation()
              setExpanded(false)
            }}
          >
            {isBerachain(chainId) && isBeraMode ? t('trade').replace(/^./, 'B') : t('trade')}
          </StyledNavLink>
          <StyledNavLink
            hideOnMobile={false}
            hideOnDesktop
            id='rewards-nav-link'
            to={'/rewards'}
            onClick={e => {
              e.stopPropagation()
              setExpanded(false)
            }}
          >
            {isBerachain(chainId) && isBeraMode ? t('rewards').replace(/^./, 'B') : t('rewards')}
          </StyledNavLink>
          <StyledNavLink
            hideOnMobile={false}
            hideOnDesktop
            id='minerals-nav-link'
            to={'/minerals'}
            onClick={e => {
              e.stopPropagation()
              setExpanded(false)
            }}
          >
            {isBerachain(chainId) && isBeraMode ? t('minerals').replace(/^./, 'B') : t('minerals')}
          </StyledNavLink>
          <StyledNavLink
            hideOnMobile={false}
            hideOnDesktop
            id='pool-nav-link'
            to={'/pool'}
            onClick={e => {
              e.stopPropagation()
              setExpanded(false)
            }}
          >
            {isBerachain(chainId) && isBeraMode ? t('pool').replace(/^./, 'B') : t('pool')}
          </StyledNavLink>
        </HeaderLinks>
      </HeaderRow>
      <HeaderControls>
        <HeaderElement walletConnected={!!account}>
          <Menu />
          <Settings />
          {account && userEthBalance ? (
            <BalanceText style={{ flexShrink: 0 }} pl='0.75rem' pr='0.5rem' fontWeight={500}>
              {userEthBalance.toSignificant(4)} {userEthBalance.currency.symbol}
            </BalanceText>
          ) : null}
          {account && <LevelButton />}
          <NetworkSelector />
          <Web3StatusWrapper>
            <Web3Status />
          </Web3StatusWrapper>
        </HeaderElement>
      </HeaderControls>
    </HeaderFrame>
  )
}
